import { ServerIdentity } from '../../../schemas/identity.ts'
import { getPriorityColor } from '../../../tables/helpers.ts'
import { IssueIcon } from '../../../components/common/IssueIcon.tsx'
import { IssueNameMap } from '../../../schemas/issue.ts'
import { Link, useSearch } from '@tanstack/react-router'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { filterOpenIssues, issuePrioritySorter } from '../../../utils/issueUtils.ts'
import cx from 'classnames'
import { RoutePaths } from '../../RoutePaths.tsx'
import { useMemo } from 'react'

export const SidePanelIssues = ({ identity }: { identity: ServerIdentity }) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	const { issueId: currentIssueId } = useSearch({ from: RoutePaths.Identity })
	const sortedOpenIssues = useMemo(() => filterOpenIssues(identity.issues).toSorted(issuePrioritySorter), [identity])
	if (!sortedOpenIssues.length) {
		return 'Identity has no open issues.'
	}

	return (
		<div
			className="flex flex-col gap-3"
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			{sortedOpenIssues.map((issue) => {
				const issueClassName = cx('py-2 px-4 rounded flex items-center gap-2 transition-colors duration-500', {
					'bg-white': issue.id !== currentIssueId,
					'bg-amber-50': issue.id === currentIssueId,
				})

				return (
					<div
						key={issue.id}
						className={issueClassName}
						style={{ boxShadow: `4px 0px 0px 0px ${getPriorityColor(issue?.priority)} inset` }}
						onMouseEnter={() => {
							setHighlightedNodes([{ type: 'issue', id: issue.id }])
						}}
					>
						<IssueIcon color="#3f3f46" name={issue.issue_name} />
						<span className="font-medium grow">
							{issue.issue_name ? IssueNameMap[issue.issue_name] : 'N/A'}
						</span>
						<Link
							className="text-neutral-500 underline"
							from={RoutePaths.Identity}
							search={(prev) => ({ ...prev, issueId: issue.id })}
						>
							Investigate issue
						</Link>
					</div>
				)
			})}
		</div>
	)
}
