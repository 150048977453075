import { z } from 'zod'
import { DateSchema } from './common'
import { IdentitySource, IdentityType, ServerIdentitySchema } from './identity'
import { omit } from 'lodash'
import { ServerIntegrationTicketInTokenDbSchema } from '../jiraIntegration/schemas/common.ts'
import { ImpactType, ProbabilityType } from './tags.ts'
import { EnvironmentType } from './envType.ts'
import { TableFilterEntrySchema } from './tableFilter.ts'

export enum IssueName {
	InactiveIdentity = 'InactiveIdentity',
	UnFederatedIdentity = 'UnFederatedIdentity',
	AccessKeyNotRotated = 'AccessKeyNotRotated',
	EmptyGroups = 'EmptyGroups',
	PartiallyOffBoardedUsers = 'PartiallyOffBoardedUsers',
	SecondaryEmailsPossiblyCompromised = 'SecondaryEmailsPossiblyCompromised',
	PrimaryEmailsPossiblyCompromised = 'PrimaryEmailsPossiblyCompromised',
	MissingMFA = 'MissingMFA',
	RootUserMissingMFA = 'RootUserMissingMFA',
	MisconfiguredIdentity = 'MisconfiguredIdentity',
	MultipleAccessKeys = 'MultipleAccessKeys',
	WeakFactor = 'WeakFactor',
	PasswordRotation = 'PasswordRotation',
	ExpiredPassword = 'ExpiredPassword',
	RootUserHasAccessKeys = 'RootUserHasAccessKeys',
	HibpPrimaryEmailBreach = 'HibpPrimaryEmailBreach',
	HibpSecondaryEmailBreach = 'HibpSecondaryEmailBreach',
	RootUserIsActive = 'RootUserIsActive',
	PasswordInactiveIdentity = 'PasswordInactiveIdentity',
	InactiveAccessKey = 'InactiveAccessKey',
	CloudshellFullAccess = 'CloudshellFullAccess',
	EC2NoRoleAttached = 'EC2NoRoleAttached',
	EC2IMDSv2NotRequired = 'EC2IMDSv2NotRequired',
	EC2KeyPairNotRotated = 'EC2KeyPairNotRotated',
	CloudtrailIamUserUsedFromEndpoint = 'CloudtrailIamUserUsedFromEndpoint',
	EKSUnFederatedPod = 'EKSUnFederatedPod',
	EKSUnassociatedServiceAccount = 'EKSUnassociatedServiceAccount',
	EKSAccessKeyReferencedInPod = 'EKSAccessKeyReferencedInPod',
	EKSInactiveServiceAccount = 'EKSInactiveServiceAccount',
	EKSClusterAdminRoleUsed = 'EKSClusterAdminRoleUsed',
	CrossAccountAccess = 'CrossAccountAccess',
	CloudtrailResourceCreatedByOffBoardedEmployee = 'CloudtrailResourceCreatedByOffBoardedEmployee',
	AccessKeyCreatedByOffboardedEmployee = 'AccessKeyCreatedByOffboardedEmployee',
	Ec2InstanceCreatedByOffboardedEmployee = 'Ec2InstanceCreatedByOffboardedEmployee',
	KeyPairCreatedByOffboardedEmployee = 'KeyPairCreatedByOffboardedEmployee',
	MissingNetworkPolicy = 'MissingNetworkPolicy',
	EKSSystemMastersBindingDetected = 'EKSSystemMastersBindingDetected',
	EKSPossiblePermissionEscalationDetection = 'EKSPossiblePermissionEscalationDetection',
	EKSImdsAccessible = 'EKSImdsAccessible',
	IdentityUsingWeakAuthentication = 'IdentityUsingWeakAuthentication',
	IDPBypass = 'IDPBypass',
	SharedAccount = 'SharedAccount',
	SharedRole = 'SharedRole',
	OverPrivilegedIdentity = 'OverPrivilegedIdentity',
	SSHKeyUsedFromMultipleEnvironments = 'SSHKeyUsedFromMultipleEnvironments',
	LongTermIAMCredentialsUsage = 'LongTermIAMCredentialsUsage',
	OffBoardedEmployee = 'OffBoardedEmployee',
	ServiceAccountWithoutNetworkPolicy = 'ServiceAccountWithoutNetworkPolicy',
	InactivePersonalAccessTokens = 'InactivePersonalAccessTokens',
	InactiveOutsideCollaborators = 'InactiveOutsideCollaborators',
	PersonalAccessKeyUsedFromEndpoint = 'PersonalAccessKeyUsedFromEndpoint',
	ServicePrincipalCreatedByOffboardedEmployee = 'ServicePrincipalCreatedByOffboardedEmployee',
	PermanentToken = 'PermanentToken',
	ClientSecretReferencedInPod = 'ClientSecretReferencedInPod',
	InactiveClientSecret = 'InactiveClientSecret',
	ClientSecretNotRotated = 'ClientSecretNotRotated',
	ClientSecretUsedFromEndpoint = 'ClientSecretUsedFromEndpoint',
	MultipleAccessTokens = 'MultipleAccessTokens',
	AccessTokenEmbeddedInCode = 'AccessTokenEmbeddedInCode',
	ServicePrincipalUsedFromEmployeesEndpoint = 'ServicePrincipalUsedFromEmployeesEndpoint',
	AzureCrossAccountAccess = 'AzureCrossAccountAccess',
	OverextendedSecret = 'OverextendedSecret',
	CredentialCreatedByOffboardedEmployee = 'CredentialCreatedByOffboardedEmployee',
	AzureKVSecretUnRotated = 'AzureKVSecretUnRotated',
	AzureKVSecretPermanent = 'AzureKVSecretPermanent',
	GCPServiceAccountUsedFromEndpoint = 'GCPServiceAccountUsedFromEndpoint',
	ServiceAccountKeyReferencedInPod = 'ServiceAccountKeyReferencedInPod',
	InactiveCredential = 'InactiveCredential',
	ShadowAdmin = 'ShadowAdmin',
	ServiceAccountSuspiciousBehavior = 'ServiceAccountSuspiciousBehavior',
	HighRiskSignInForCloudAdmin = 'HighRiskSignInForCloudAdmin',
	UserUsedFromAttackOrPentestingFramework = 'UserUsedFromAttackOrPentestingFramework',
	SalesforceUserMultipleConnectedApps = 'SalesforceUserMultipleConnectedApps',
	PermanentPassword = 'PermanentPassword',
	OverExtendedPAT = 'OverextendedPat',
	UnrotatedPAT = 'UnrotatedPat',
	UnrotatedPassword = 'UnrotatedPassword',
}

export const IssueNameMap: Record<IssueName, string> = {
	[IssueName.CredentialCreatedByOffboardedEmployee]: 'Credential Created by Off-boarded Employee',
	[IssueName.EKSImdsAccessible]: 'IMDS Accessible in EKS',
	[IssueName.EKSPossiblePermissionEscalationDetection]: 'Role With Possible Permission Escalations Detected',
	[IssueName.EKSSystemMastersBindingDetected]: 'System-Masters Binding Detected',
	[IssueName.EKSClusterAdminRoleUsed]: 'Cluster-Admin Role Binding Detected',
	[IssueName.InactiveIdentity]: 'Inactive Identity',
	[IssueName.MissingNetworkPolicy]: 'Missing Network Policy',
	[IssueName.UnFederatedIdentity]: 'Unfederated Identity',
	[IssueName.MissingMFA]: 'Missing MFA',
	[IssueName.RootUserMissingMFA]: 'Root User Missing MFA',
	[IssueName.AccessKeyNotRotated]: 'Access Key Not Rotated',
	[IssueName.RootUserHasAccessKeys]: 'Root User Has Access Keys',
	[IssueName.RootUserIsActive]: 'Active root user',
	[IssueName.EmptyGroups]: 'Empty Groups',
	[IssueName.PartiallyOffBoardedUsers]: 'Partially Offboarded Users',
	[IssueName.SecondaryEmailsPossiblyCompromised]: 'Secondary Emails Possibly Compromised',
	[IssueName.PrimaryEmailsPossiblyCompromised]: 'Primary Emails Possibly Compromised',
	[IssueName.MisconfiguredIdentity]: 'Misconfigured Identity',
	[IssueName.MultipleAccessKeys]: 'Multiple Access Keys',
	[IssueName.WeakFactor]: 'Weak Factor',
	[IssueName.PasswordRotation]: 'Password Rotation',
	[IssueName.ExpiredPassword]: 'Expired Password',
	[IssueName.HibpPrimaryEmailBreach]: 'HIBP Primary Email Breach',
	[IssueName.HibpSecondaryEmailBreach]: 'HIBP Secondary Email Breach',
	[IssueName.PasswordInactiveIdentity]: 'Password Inactive Identity',
	[IssueName.InactiveAccessKey]: 'Inactive Access Key',
	[IssueName.CloudshellFullAccess]: 'Cloudshell Full Access',
	[IssueName.EC2NoRoleAttached]: 'EC2 No Role Attached',
	[IssueName.EC2IMDSv2NotRequired]: 'Instance Profile With IMDSv1',
	[IssueName.EC2KeyPairNotRotated]: 'EC2 Key Pair Not Rotated',
	[IssueName.CloudtrailIamUserUsedFromEndpoint]: 'IAM User Used From Endpoint',
	[IssueName.EKSUnFederatedPod]: 'Unfederated Pod',
	[IssueName.EKSUnassociatedServiceAccount]: 'Unassociated Service Account',
	[IssueName.EKSAccessKeyReferencedInPod]: 'Access Key Referenced in Pod',
	[IssueName.EKSInactiveServiceAccount]: 'Inactive Service Account',
	[IssueName.CrossAccountAccess]: 'Privilege Escalation',
	[IssueName.CloudtrailResourceCreatedByOffBoardedEmployee]: 'Resource Created by Off-boarded Employee',
	[IssueName.AccessKeyCreatedByOffboardedEmployee]: 'Access Key Created by Off-boarded Employee',
	[IssueName.Ec2InstanceCreatedByOffboardedEmployee]: 'EC2 Instance Created by Off-boarded Employee',
	[IssueName.KeyPairCreatedByOffboardedEmployee]: 'KeyPair Created by Off-boarded Employee',
	[IssueName.IdentityUsingWeakAuthentication]: 'Identity Using Weak Authentication',
	[IssueName.IDPBypass]: 'IDP Bypass',
	[IssueName.SharedAccount]: 'Shared Account',
	[IssueName.SharedRole]: 'Shared Role',
	[IssueName.OverPrivilegedIdentity]: 'Over Privileged Identity',
	[IssueName.SSHKeyUsedFromMultipleEnvironments]: 'SSH Key Used From Multiple Environments',
	[IssueName.LongTermIAMCredentialsUsage]: 'Long Term IAM Credentials Usage',
	[IssueName.OffBoardedEmployee]: 'Offboarded Employee',
	[IssueName.ServiceAccountWithoutNetworkPolicy]: 'Missing Network Policy',
	[IssueName.InactivePersonalAccessTokens]: 'Inactive Personal Access Tokens',
	[IssueName.InactiveOutsideCollaborators]: 'Inactive Outside Collaborators',
	[IssueName.PersonalAccessKeyUsedFromEndpoint]: 'Personal Access Key Used From Endpoint',
	[IssueName.ServicePrincipalCreatedByOffboardedEmployee]: 'Service Principal Created by Off-boarded Employee',
	[IssueName.PermanentToken]: 'Permanent Token',
	[IssueName.ClientSecretReferencedInPod]: 'Client Secret Referenced in Pod',
	[IssueName.InactiveClientSecret]: 'Inactive Client Secret',
	[IssueName.ClientSecretNotRotated]: 'Client Secret Not Rotated',
	[IssueName.ClientSecretUsedFromEndpoint]: 'Client Secret Used From Endpoint',
	[IssueName.MultipleAccessTokens]: 'Multiple Access Tokens ',
	[IssueName.AccessTokenEmbeddedInCode]: 'Access Token Embedded in Code',
	[IssueName.ServicePrincipalUsedFromEmployeesEndpoint]: 'Service Principal Used From Employees Endpoint',
	[IssueName.AzureCrossAccountAccess]: 'Cross Account Access',
	[IssueName.OverextendedSecret]: 'Over Extended Secret',
	[IssueName.AzureKVSecretUnRotated]: 'Azure KV Secret Not Rotated',
	[IssueName.AzureKVSecretPermanent]: 'Azure KV Permanent Secret',
	[IssueName.GCPServiceAccountUsedFromEndpoint]: 'GCP Service Account Used From Endpoint',
	[IssueName.ServiceAccountKeyReferencedInPod]: 'Service Account Key Referenced in Pod',
	[IssueName.InactiveCredential]: 'Inactive Credential',
	[IssueName.ShadowAdmin]: 'Shadow Admin',
	[IssueName.ServiceAccountSuspiciousBehavior]: 'Service Account Suspicious Behavior',
	[IssueName.HighRiskSignInForCloudAdmin]: 'High Risk Sign-In for Cloud Admin',
	[IssueName.UserUsedFromAttackOrPentestingFramework]: 'User Used From Attack or Pentesting Framework',
	[IssueName.SalesforceUserMultipleConnectedApps]: 'Salesforce User Multiple Connected Apps',
	[IssueName.OverExtendedPAT]: 'Over Extended Personal Access Token',
	[IssueName.UnrotatedPAT]: 'Un-rotated Personal Access Token',
	[IssueName.PermanentPassword]: 'Permanent Password',
	[IssueName.UnrotatedPassword]: 'Unrotated Password',
}

export enum ResourceType {
	OKTA = 'OKTA',
	GOOGLE_WORKSPACE = 'GOOGLE_WORKSPACE',
	AWS = 'AWS',
	AZURE = 'AZURE',
	HIBP = 'HIBP',
	LINKEDIN = 'LINKEDIN',
	GCP = 'GCP',
	CLOUDTRAIL = 'CLOUDTRAIL',
	JUMPCLOUD = 'JUMPCLOUD',
	ENTRA_ID = 'ENTRA_ID',
	RDS_POSTGRES = 'RDS_POSTGRES',
	SNOWFLAKE = 'SNOWFLAKE',
	GITHUB = 'GITHUB',
	KUBERNETES = 'KUBERNETES',
	DATABRICKS = 'DATABRICKS',
	AZURE_VM = 'AZURE_VM',
	AZURE_RDS_POSTGRES = 'AZURE_RDS_POSTGRES',
	AZURE_DEVOPS = 'AZURE_DEVOPS',
	SALESFORCE = 'SALESFORCE',
	ACTIVE_DIRECTORY = 'ACTIVE_DIRECTORY',
}

export const isResourceType = (resource?: string | null): resource is ResourceType =>
	!!resource && Object.values(ResourceType).includes(resource as ResourceType)

export const ResourceTypeMap: Record<ResourceType, string> = {
	[ResourceType.OKTA]: 'Okta',
	[ResourceType.GOOGLE_WORKSPACE]: 'Google Workspace',
	[ResourceType.AWS]: 'AWS',
	[ResourceType.AZURE]: 'Azure',
	[ResourceType.HIBP]: 'Have I Been Pwned',
	[ResourceType.LINKEDIN]: 'LinkedIn',
	[ResourceType.GCP]: 'GCP',
	[ResourceType.CLOUDTRAIL]: 'AWS',
	[ResourceType.JUMPCLOUD]: 'Jumpcloud',
	[ResourceType.ENTRA_ID]: 'Entra ID',
	[ResourceType.RDS_POSTGRES]: 'RDS Postgres',
	[ResourceType.SNOWFLAKE]: 'Snowflake',
	[ResourceType.GITHUB]: 'GitHub',
	[ResourceType.KUBERNETES]: 'Kubernetes',
	[ResourceType.DATABRICKS]: 'Databricks',
	[ResourceType.AZURE_VM]: 'Azure VM',
	[ResourceType.AZURE_RDS_POSTGRES]: 'Azure RDS Postgres',
	[ResourceType.SALESFORCE]: 'Salesforce',
	[ResourceType.AZURE_DEVOPS]: 'Azure DevOps',
	[ResourceType.ACTIVE_DIRECTORY]: 'Active Directory',
}

export enum PriorityType {
	NOT_CALCULATED = -1,
	LOW = 1,
	MEDIUM = 2,
	HIGH = 3,
	CRITICAL = 4,
}

export enum PriorityTypeEnum {
	NOT_CALCULATED = 'NOT_CALCULATED',
	LOW = 'LOW',
	MEDIUM = 'MEDIUM',
	HIGH = 'HIGH',
	CRITICAL = 'CRITICAL',
}

export enum PriorityTypeNameMap {
	NOT_CALCULATED = 'Not Calculated',
	LOW = 'Low',
	MEDIUM = 'Medium',
	HIGH = 'High',
	CRITICAL = 'Critical',
}

export const PriorityTypeMap: { [key in PriorityType]: string } = {
	[PriorityType.NOT_CALCULATED]: 'Not Calculated',
	[PriorityType.LOW]: 'Low',
	[PriorityType.MEDIUM]: 'Medium',
	[PriorityType.HIGH]: 'High',
	[PriorityType.CRITICAL]: 'Critical',
}

export const PriorityTypeNameToNumber: { [key in PriorityTypeEnum]: PriorityType } = {
	[PriorityTypeEnum.NOT_CALCULATED]: PriorityType.NOT_CALCULATED,
	[PriorityTypeEnum.LOW]: PriorityType.LOW,
	[PriorityTypeEnum.MEDIUM]: PriorityType.MEDIUM,
	[PriorityTypeEnum.HIGH]: PriorityType.HIGH,
	[PriorityTypeEnum.CRITICAL]: PriorityType.CRITICAL,
}

export enum IssueStatus {
	OPEN = 'OPEN',
	IN_PROGRESS = 'IN_PROGRESS',
	RESOLVED = 'RESOLVED',
	IGNORED = 'IGNORED',
	FALSE_POSITIVE = 'FALSE_POSITIVE',
}

export const OpenIssueStatuses = [IssueStatus.OPEN, IssueStatus.IN_PROGRESS]

export const IssueStatusMap: Record<IssueStatus, string> = {
	[IssueStatus.OPEN]: 'Open',
	[IssueStatus.IN_PROGRESS]: 'In Progress',
	[IssueStatus.RESOLVED]: 'Resolved',
	[IssueStatus.IGNORED]: 'Ignored',
	[IssueStatus.FALSE_POSITIVE]: 'False-positive',
}

export const IssueStatusesWithIgnoreReason = [IssueStatus.IGNORED, IssueStatus.FALSE_POSITIVE]

/** Statuses that can be changed by a user */
export const UserChangeableIssueStatuses: IssueStatus[] = [
	IssueStatus.OPEN,
	IssueStatus.IN_PROGRESS,
	IssueStatus.IGNORED,
	IssueStatus.FALSE_POSITIVE,
]

export enum IssueStatGroup {
	Findings = 'Findings',
	AllCount = 'AllCount',
	nonHuman = 'nonHuman',
	mostWanted = 'mostWanted',
	human = 'human',
	tag = 'tag',
	cloudProvider = 'cloudProvider',
	identityProvider = 'identityProvider',
	MyIssues = 'MyIssues',
	RelatedTickets = 'RelatedTickets',
}

export const BaseServerIssueSchema = z.object({
	id: z.string(),
	issue_name: z.nativeEnum(IssueName).nullish(),
	issue_source: z.nativeEnum(ResourceType).nullish(),
	last_seen: DateSchema.nullish(),
	created_at: DateSchema.nullish(),
	description: z.string().nullish(),
	priority: z.nativeEnum(PriorityType).nullish(),
	status: z.nativeEnum(IssueStatus),
	assignee: z.string().nullish(),
	ignore_reason: z.string().nullish(),
	integration_tickets: ServerIntegrationTicketInTokenDbSchema.array().nullish(),
})

export type BaseServerIssue = z.infer<typeof BaseServerIssueSchema>

export const ServerIssueSchema = BaseServerIssueSchema.extend({
	identity: ServerIdentitySchema.nullish(),
})

export type ServerIssue = z.infer<typeof ServerIssueSchema>

export const IssueTablePaginatedRowSchema = z.object({
	id: z.string(),
	issue_name: z.nativeEnum(IssueName),
	issue_source: z.nativeEnum(ResourceType),
	last_seen: DateSchema,
	created_at: DateSchema,
	description: z.string().nullish(),
	priority: z.nativeEnum(PriorityType),
	status: z.nativeEnum(IssueStatus),
	assignee: z.string().nullish(),
	ignore_reason: z.string().nullish(),
	integration_tickets: ServerIntegrationTicketInTokenDbSchema.array().nullish(),
	identity_id: z.string(),
	identity_literal: z.string(),
	identity_literal_friendly_name: z.string().nullish(),
	identity_account_literal: z.string(),
	identity_account_literal_friendly_name: z.string().nullish(),
	identity_type: z.nativeEnum(IdentityType),
	identity_source: z.nativeEnum(IdentitySource),
	identity_last_activity: DateSchema.nullish(),
	identity_env_type: z.nativeEnum(EnvironmentType),
})
export type IssueTablePaginatedRow = z.infer<typeof IssueTablePaginatedRowSchema>

export type ServerIssueStats = z.infer<typeof ServerIssuesStatsSchema>

export const findingsUrlQuickSearchFilterParameter = 'quick_search'

export const filterObj = z
	.object({
		filterType: z.string().optional(),
		type: z.string().optional(),
		filter: z.string().optional(),
		values: z.array(z.string()).optional(),
	})
	.passthrough()
	.optional()

// Non-paginated filter
export const IssueFilterSchema = z
	.object({
		priority: filterObj,
		'identity.account_literal': filterObj,
	})
	.passthrough() // This allows for additional, unspecified properties
	.optional()

export type IssueFilter = z.infer<typeof IssueFilterSchema>

// Paginated filter
export const PaginatedIssueFilterSchema = z.record(
	z.union([IssueTablePaginatedRowSchema.keyof(), z.literal(findingsUrlQuickSearchFilterParameter)]),
	TableFilterEntrySchema.optional(),
)

export type PaginatedIssueFilter = z.infer<typeof PaginatedIssueFilterSchema>

export const ClientIssueSearchSchema = z.object({
	issueId: z.string().uuid().catch('').optional(),
	issueName: z.string().catch('').optional(),
	issueStatuses: z.string().array().catch([]).optional(),
	issueFilter: IssueFilterSchema,
	mostWanted: z.boolean().optional(),
	environment: z.string().catch('').optional(),
	nonHuman: z.boolean().optional(),
	issueTag: z.string().catch('').optional(),
	human: z.boolean().optional(),
	cloudProvider: z.string().catch('').optional(),
	identityProvider: z.string().catch('').optional(),
	myIssues: z.boolean().optional(),
	relatedTickets: z.boolean().optional(),
})

export type ClientIssueSearch = z.infer<typeof ClientIssueSearchSchema>

export enum IssuesTabName {
	OPEN = 'Open',
	RESOLVED = 'Resolved',
	SUPPRESSED = 'Suppressed',
	ALL = 'All',
}

export const ClientIssuesSearchUrlSchema = z.object({
	...omit(ClientIssueSearchSchema.shape, 'issueStatuses'),
	tabName: z.nativeEnum(IssuesTabName).catch(IssuesTabName.OPEN).optional(),
})

export type ClientIssuesSearchUrl = z.infer<typeof ClientIssuesSearchUrlSchema>

export enum IssuesPageLens {
	ALL = 'All',
	ASSIGNED_TO_ME = 'My Issues',
	JIRA = 'JIRA',
	ADMIN = 'ADMIN',
	DATA_ACCESS = 'DATA_ACCESS',
	CREDENTIAL_EXPOSURE = 'CREDENTIAL_EXPOSURE',
	RESOURCE_EXPOSURE = 'RESOURCE_EXPOSURE',
	POSSIBLE_PRIVILEGE_ESCALATION = 'POSSIBLE_PRIVILEGE_ESCALATION',
	AWS = 'AWS',
	AZURE = 'AZURE',
	GCP = 'GCP',
	OKTA = 'OKTA',
	JUMPCLOUD = 'JUMPCLOUD',
	ENTRA_ID = 'ENTRA_ID',
	GOOGLE_WORKSPACE = 'GOOGLE_WORKSPACE',
	ISSUES = 'ISSUES',
}

const IssuesPaginationSchema = z.object({
	page: z.number().nullish(),
	pageSize: z.number(),
})

export type IssuesPagination = z.infer<typeof IssuesPaginationSchema>

export const PaginatedFindingsPageSearchSchema = z.object({
	tab: z.nativeEnum(IssuesTabName).catch(IssuesTabName.OPEN).optional(),
	lens: z.union([z.nativeEnum(IssuesPageLens), z.nativeEnum(IssueName)]).optional(),
	pagination: IssuesPaginationSchema.optional(),
	issueFilter: PaginatedIssueFilterSchema.optional(),
	issueId: z.string().uuid().catch('').optional(),
})

export type PaginatedFindingsPageSearch = z.infer<typeof PaginatedFindingsPageSearchSchema>

export const FindingsPageSearchSchema = z.union([PaginatedFindingsPageSearchSchema, ClientIssuesSearchUrlSchema])

export type FindingsPageSearch = z.infer<typeof FindingsPageSearchSchema>

export const IssueImpactProbabilityWithCountSchema = z.object({
	id: z.string(),
	customer_id: z.string(),
	issue_name: z.nativeEnum(IssueName),
	issue_source: z.nativeEnum(ResourceType),
	impact: z.nativeEnum(ImpactType).nullish(),
	probability: z.nativeEnum(ProbabilityType).nullish(),
	priority: z.nativeEnum(PriorityType).nullish(),
	last_run: DateSchema.nullish(),
	status_update_enabled: z.boolean(),
	ignore_issue: z.boolean(),
	issue_count: z.number(),
})

export type IssueImpactProbabilityWithCount = z.infer<typeof IssueImpactProbabilityWithCountSchema>

export const ServerIssuesQueryResponseSchema = z.object({
	issues: z.array(IssueTablePaginatedRowSchema),
	total_count: z.number(),
})

export type ServerIssuesQueryResponse = z.infer<typeof ServerIssuesQueryResponseSchema>

export const ServerIssueIdentityEnvironmentSchema = z.object({
	identity_env_type: z.nativeEnum(EnvironmentType).nullish(),
	identity_account_literal: z.string(),
	identity_account_literal_friendly_name: z.string().nullish(),
})

export const ServerIssuesStatsSchema = z.object({
	issue_name: z.union([z.nativeEnum(IssueName), z.nativeEnum(IssuesPageLens)]),
	count: z.number(),
	group: z.nativeEnum(IssueStatGroup),
})

export type ServerIssueIdentityEnvironment = z.infer<typeof ServerIssueIdentityEnvironmentSchema>

export const ServerPaginatedIssuesStatsSchema = z.object({
	count_by_priority: z.record(z.coerce.number(), z.number()),
	count_by_env: z.record(z.nativeEnum(EnvironmentType), z.number()),
	total_count: z.number(),
})
export type ServerPaginatedIssuesStats = z.infer<typeof ServerPaginatedIssuesStatsSchema>

export const EnvWithLiteralSchema = z.object({
	identity_env_type: z.nativeEnum(EnvironmentType),
	identity_account_literal: z.string(),
})
export type EnvWithLiteral = z.infer<typeof EnvWithLiteralSchema>
