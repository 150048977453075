import { Collapse } from 'antd'
import { ServerAwsIamUserPermission } from '../../../schemas/identities/awsIamUserXcSchema.ts'
import { IssueSourceIcon } from '../../common/IssueSourceIcon'
import { ResourceType } from '../../../schemas/issue'
import { ServerAwsIamRoleXc } from '../../../schemas/identities/awsIamRoleXcSchema.ts'
import { IamPermissionsTable } from '../../../tables/IamPermissionsTable.tsx'
import React from 'react'
import { getCombinedAwsIamRolePolicy } from '../../../utils/awsIdentityUtils.ts'

export const AwsIamPermissionContext: React.FunctionComponent<{
	awsIamPermissionsXc?: ServerAwsIamUserPermission | null
	awsIamRolesXc?: ServerAwsIamRoleXc | null
}> = ({ awsIamPermissionsXc, awsIamRolesXc }) => {
	const policyList = getCombinedAwsIamRolePolicy(awsIamPermissionsXc, awsIamRolesXc)

	return (
		<Collapse
			expandIconPosition={'end'}
			items={[
				{
					key: 'policies',
					label: (
						<div className="flex items-center">
							<IssueSourceIcon source={ResourceType.AWS} />
							<div>Policies</div>
						</div>
					),
					children: <IamPermissionsTable data={policyList} />,
				},
			]}
		/>
	)
}
