import { Node, NodeProps } from '@xyflow/react'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { ServerSnowflakeRoleXc } from '../../../../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { FileTextOutlined } from '@ant-design/icons'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'

export type SnowflakeRolesNodeType = Node<{ roles: ServerSnowflakeRoleXc[] }, 'snowflakeRoles'>
type SnowflakeRolesNodeProps = NodeProps<SnowflakeRolesNodeType>

export const SnowflakeRolesNode = ({ data: { roles } }: SnowflakeRolesNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} transition-colors duration-300 w-[50px] h-[50px] text-white rounded-full flex justify-center items-center`,
	)

	const badgeTooltipContent = roles.map((role) => <span key={role.name}>{role.name}</span>)
	return (
		<BaseNode label="Snowflake Roles">
			<NodeGroupBadge items={roles} nodeColorType="permission" tooltipContent={badgeTooltipContent} />
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
