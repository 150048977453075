import { z } from 'zod'
import { DateSchema } from './common'

export enum NotificationChannel {
	SLACK = 'SLACK',
}

export enum NotificationStatus {
	ENABLED = 'ENABLED',
	FAILED = 'FAILED',
	DISABLED = 'DISABLED',
}

export enum NotificationActionType {
	SEND_MESSAGE = 'SEND_MESSAGE',
}

export const NotificationChannelMap: Record<NotificationChannel, string> = {
	[NotificationChannel.SLACK]: 'Slack',
}

export const ServerNotificationSchema = z.object({
	/*
	Note that we don't actually have a notification model in the backend.
	Notifications are created and managed by the playbook service and integrations service.
	These fields are used to configure the notification representation in the UI.
	These are the fields that are returned from the notifications endpoint, and are a combination of the playbook and integration models.
	*/
	playbook_id: z.string(),
	name: z.string(),
	description: z.string(),
	trigger_json: z.record(z.any()),
	action_type: z.nativeEnum(NotificationActionType),
	status: z.nativeEnum(NotificationStatus),
	integration_id: z.string(),
	integration_type: z.nativeEnum(NotificationChannel),
	integration_name: z.string(),
	integration_data: z.record(z.any()),
	system_created_at: DateSchema,
	system_updated_at: DateSchema,
})

export type ServerNotification = z.infer<typeof ServerNotificationSchema>
