import Collapse from 'antd/es/collapse'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import type { RemediationStep } from './remediationStepsData'

const getCollapseItems = (index: number, step: RemediationStep) => [
	{
		key: `${index + 1}-${step.title}`,
		label: `Step ${index + 1}: ${step.title}`,
		children: <Markdown remarkPlugins={[remarkGfm]} children={step.content}></Markdown>,
	},
]

export const RemediationSteps: React.FC<{ remediationSteps: RemediationStep[] }> = ({ remediationSteps }) => {
	return (
		<div className="remediation-steps">
			{remediationSteps.map((step, index) => (
				<Collapse
					key={`${step.title}${step.content}`}
					className="mb-2"
					expandIconPosition="end"
					items={getCollapseItems(index, step)}
				/>
			))}
		</div>
	)
}
