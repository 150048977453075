import { IssueStatGroup } from './issue.ts'

export interface sideBarFolder {
	folder: IssueStatGroup
	file: string
}

export const createSideBarFolder = (folder: IssueStatGroup, file: string) => {
	return JSON.stringify({ folder, file })
}
