import { Segmented, Tooltip } from 'antd'
import { FindingsDistributionSegment, MetricsDescription } from './helpers.tsx'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { SegmentedValue } from 'rc-segmented'

export const FindingsDistributionHeader: React.FC<{
	onDistributionSegmentChange: (value: FindingsDistributionSegment) => void
	currentDistribution: FindingsDistributionSegment
}> = ({ onDistributionSegmentChange, currentDistribution }) => {
	const options = [
		{
			label: (
				<div className="p-1">
					<div>By Issue Type</div>
				</div>
			),
			value: FindingsDistributionSegment.IssueType,
		},
		{
			label: (
				<div className="p-1">
					<div>By Environment</div>
				</div>
			),
			value: FindingsDistributionSegment.Environment,
		},
	]
	const onChange = (value: SegmentedValue) => {
		onDistributionSegmentChange(value as FindingsDistributionSegment)
	}

	return (
		<>
			<div className="flex flex-row gap-2">
				<span>Findings Distribution</span>
				<Tooltip title={MetricsDescription.FindingsDistributionTreeMap}>
					<QuestionCircleOutlined />
				</Tooltip>
			</div>
			<div>
				<Segmented size="small" onChange={onChange} value={currentDistribution} options={options} />
			</div>
		</>
	)
}
