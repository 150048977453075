export const generateUUID = (): string => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0
		const v = c === 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

export const getRecentlyCreatedDate = (): string | null => {
	const now = new Date()
	const twentyThreeHoursAgo = new Date(now.getTime() - 23 * 60 * 60 * 1000)

	// Format date as 'YYYY-MM-DDTHH:MM:SSZ'
	const formattedNow = now.toISOString().split('.')[0] + 'Z'

	return now >= twentyThreeHoursAgo ? formattedNow : null
}
