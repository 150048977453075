import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

export const BaseIdentityDetail: React.FunctionComponent<{
	label: string
	value?: string | null | undefined
	span?: number
	tooltipTitle?: string
	children?: React.ReactNode
	helperToolTip?: string
}> = ({ label, value, span, tooltipTitle, children, helperToolTip }) => {
	return (
		<div className={`col-span-${span || 1}  grid grid-rows-1 h-12`}>
			<div className="text-xs text-gray-500 flex items-center space-x-2">
				<div className="font-semibold">{label}</div>
				{helperToolTip ? (
					<div>
						<Tooltip title={helperToolTip}>
							<QuestionCircleOutlined />
						</Tooltip>
					</div>
				) : null}
			</div>
			{value && (
				<Tooltip placement="topLeft" title={tooltipTitle ?? value}>
					<div className="font-normal text-gray-600 items-end truncate ">{value}</div>
				</Tooltip>
			)}
			{children && <div className="mt-1 items-end truncate">{children}</div>}
		</div>
	)
}
