import { Server } from 'miragejs/server'
import {
	IdentitiesPageLens,
	IdentitiesPageSearchSchema,
	IdentitySource,
	IdentityTabName,
	ServerIdentitiesQueryResponse,
	ServerIdentitiesTableRow,
	ServerIdentity,
	ServerIdentityEnvironment,
	ServerIdentityRelatedIssues,
	ServerIdentityUsageLogs,
	ServerIdentitiesStats,
	IdentityType,
} from '../../schemas/identity.ts'
import { getIdentityWithIssues } from './utils.ts'
import { AppSchema } from '../schemas.ts'
import {
	getAllIdentitiesFromIssues,
	getAllIssues,
	getAllStandAloneIdentities,
	getIdentityById,
	getIdentityByIdFromIssues,
	getUsageLogsByIdentityId,
} from '../mirageORM.ts'
import { filterIdentities } from './identitiesUtils.ts'
import { QueryParameters } from '../../schemas/query.ts'
import { applyPagination, applySorting, applyFiltering, applyLensAndTabFiltering } from './identitiesUtils.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { Tag } from '../../schemas/tags.ts'

export function mirageGetRelatedIssues(server: Server) {
	server.get('/identity/:id/related-issues', (schema: AppSchema, request): ServerIdentityRelatedIssues => {
		return getIdentityWithIssues(getAllIssues(schema), request.params.id)
	})
}

export function mirageIdentitiesSearch(server: Server) {
	server.post('/identity/search', (schema, request): ServerIdentitiesTableRow[] => {
		return filterIdentities(
			[...getAllIdentitiesFromIssues(schema), ...getAllStandAloneIdentities(schema)],
			IdentitiesPageSearchSchema.parse(JSON.parse(request.requestBody)),
		)
	})
}

export function mirageIdentity(server: Server) {
	server.get('/identity/:id', (schema, request): ServerIdentity => {
		return getIdentityById(schema, request.params.id) ?? getIdentityByIdFromIssues(schema, request.params.id)
	})
}

export function mirageUsageLogs(server: Server) {
	server.get('/identity/:id/usage-logs', (schema, request): ServerIdentityUsageLogs | null => {
		return getUsageLogsByIdentityId(schema, request.params.id)
	})
}

export function mirageIdentitiesQuery(server: Server) {
	server.post('/identity/query', (schema, request): ServerIdentitiesQueryResponse => {
		const requestBody = JSON.parse(request.requestBody) as {
			query: QueryParameters
			lens?: IdentitiesPageLens
			tab?: IdentityTabName
		}
		const { pagination, sort, filter } = requestBody.query
		const { lens, tab } = requestBody
		// Get all identities
		let identities = [...getAllIdentitiesFromIssues(schema), ...getAllStandAloneIdentities(schema)]

		// Apply lens and tab filtering
		identities = applyLensAndTabFiltering(identities, lens, tab)

		// Apply query filtering if present
		if (filter) {
			identities = applyFiltering(identities, filter)
		}

		// Apply sorting if present
		if (sort && sort.length > 0) {
			identities = applySorting(identities, sort)
		}

		// Get total count after filtering but before pagination
		// eslint-disable-next-line camelcase
		const total_count = identities.length

		// Apply pagination if present
		if (pagination) {
			identities = applyPagination(identities, pagination.offset, pagination.limit)
		}

		return {
			identities,
			// eslint-disable-next-line camelcase
			total_count,
		}
	})
}

export function mirageIdentityFilters(server: Server) {
	// Identity Sources filter
	server.post('/identity/sources', (schema, request): IdentitySource[] => {
		const requestBody = JSON.parse(request.requestBody) as {
			query: QueryParameters
			lens?: IdentitiesPageLens
			tab?: IdentityTabName
		}
		const { filter } = requestBody.query
		const { lens, tab } = requestBody

		let identities = [...getAllIdentitiesFromIssues(schema), ...getAllStandAloneIdentities(schema)]

		// Apply lens and tab filtering
		identities = applyLensAndTabFiltering(identities, lens, tab)

		// Apply query filtering
		if (filter) {
			identities = applyFiltering(identities, filter)
		}

		return [...new Set(identities.map((identity) => identity.source))]
	})

	// Source Environment filter
	server.post('/identity/environments', (schema, request): ServerIdentityEnvironment[] => {
		const requestBody = JSON.parse(request.requestBody) as {
			query: QueryParameters
			lens?: IdentitiesPageLens
			tab?: IdentityTabName
		}
		const { filter } = requestBody.query
		const { lens, tab } = requestBody

		let identities = [...getAllIdentitiesFromIssues(schema), ...getAllStandAloneIdentities(schema)]

		// Apply lens and tab filtering
		identities = applyLensAndTabFiltering(identities, lens, tab)

		// Apply query filtering
		if (filter) {
			identities = applyFiltering(identities, filter)
		}

		const result: ServerIdentityEnvironment[] = []

		identities.forEach((identity) => {
			if (identity.env_type) {
				const key = `${identity.env_type}-${identity.account_literal}-${identity.account_literal_friendly_name}`
				if (
					!result.some(
						(env) => `${env.env_type}-${env.account_literal}-${env.account_literal_friendly_name}` === key,
					)
				) {
					result.push({
						/* eslint-disable camelcase */
						env_type: identity.env_type as EnvironmentType,
						account_literal: identity.account_literal || '',
						account_literal_friendly_name: identity.account_literal_friendly_name || '',
						/* eslint-enable camelcase */
					})
				}
			}
		})
		return result
	})

	// Affected Environments filter
	server.post('/identity/affected-environments', (schema, request): ServerIdentityEnvironment[] => {
		const requestBody = JSON.parse(request.requestBody) as {
			query: QueryParameters
			lens?: IdentitiesPageLens
			tab?: IdentityTabName
		}
		const { filter } = requestBody.query
		const { lens, tab } = requestBody

		let identities = [...getAllIdentitiesFromIssues(schema), ...getAllStandAloneIdentities(schema)]

		// Apply lens and tab filtering
		identities = applyLensAndTabFiltering(identities, lens, tab)

		// Apply query filtering
		if (filter) {
			identities = applyFiltering(identities, filter)
		}

		const affectedEnvs = new Set<string>() // Use Set to deduplicate
		const result: ServerIdentityEnvironment[] = []

		identities.forEach((identity) => {
			if (identity.affected_environments) {
				Object.entries(identity.affected_environments).forEach(([envType, environments]) => {
					environments.forEach((env) => {
						const key = `${envType}-${env.account_id}-${env.account_name}`
						if (!affectedEnvs.has(key)) {
							affectedEnvs.add(key)
							result.push({
								...env,
								/* eslint-disable camelcase */
								env_type: envType as EnvironmentType,
								account_literal: env.account_id || '',
								account_literal_friendly_name: env.account_name,
								/* eslint-enable camelcase */
							})
						}
					})
				})
			}
		})
		return result
	})

	// Tags with Environment filter
	server.post('/identity/tags-with-environment', (schema, request): string[] => {
		const requestBody = JSON.parse(request.requestBody) as {
			query: QueryParameters
			lens?: IdentitiesPageLens
			tab?: IdentityTabName
		}
		const { filter } = requestBody.query
		const { lens, tab } = requestBody

		let identities = [...getAllIdentitiesFromIssues(schema), ...getAllStandAloneIdentities(schema)]

		// Apply lens and tab filtering
		identities = applyLensAndTabFiltering(identities, lens, tab)

		// Apply query filtering
		if (filter) {
			identities = applyFiltering(identities, filter)
		}

		return [...new Set(identities.flatMap((identity) => identity.tags_with_env ?? []))]
	})
}

export function mirageIdentityQueryStats(server: Server) {
	server.post('/identity/query-stats', (schema, request): ServerIdentitiesStats => {
		const requestBody = JSON.parse(request.requestBody) as {
			query: QueryParameters
			lens?: IdentitiesPageLens
			tab?: IdentityTabName
		}
		const { filter } = requestBody.query
		const { lens, tab } = requestBody

		let identities = [...getAllIdentitiesFromIssues(schema), ...getAllStandAloneIdentities(schema)]

		// Apply lens and tab filtering
		identities = applyLensAndTabFiltering(identities, lens, tab)

		// Apply query filtering
		if (filter) {
			identities = applyFiltering(identities, filter)
		}

		// Calculate stats from filtered identities
		// Initialize counts for each category
		const countByPriority: Record<number, number> = { 1: 0, 2: 0, 3: 0, 4: 0 }
		const countByRisk: Record<Tag, number> = Object.values(Tag).reduce(
			(acc, tag) => ({ ...acc, [tag]: 0 }),
			{} as Record<Tag, number>,
		)
		const countByType: Record<IdentityType, number> = Object.values(IdentityType).reduce(
			(acc, type) => ({ ...acc, [type]: 0 }),
			{} as Record<IdentityType, number>,
		)

		identities.forEach((identity) => {
			// Count by priority
			const priority = identity.max_priority ?? 4
			countByPriority[priority] = (countByPriority[priority] || 0) + 1

			// Count by type
			if (identity.type) {
				countByType[identity.type] = (countByType[identity.type] || 0) + 1
			}
		})

		// Count unique identities for each risk tag
		Object.values(Tag).forEach((tag) => {
			countByRisk[tag] = identities.filter((identity) => identity.tags_names?.includes(tag)).length
		})

		// Calculate total number of identities that have any risk tag
		const riskyCount = identities.filter((identity) =>
			identity.tags_names?.some((tag) => Object.values(Tag).includes(tag as Tag)),
		).length

		return {
			/* eslint-disable camelcase */
			total_count: identities.length,
			count_by_priority: countByPriority,
			risky_count: riskyCount,
			count_by_risk: countByRisk,
			count_by_type: countByType,
			/* eslint-enable camelcase */
		}
	})
}

export function mirageIdentityExportLimit(server: Server) {
	server.get('/identities/export-limit', (): { limit: number } => {
		// Return a reasonable limit for demo/testing purposes
		return { limit: 5000 }
	})
}
