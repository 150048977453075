import { ServerIdentity } from '../../../schemas/identity'
import { ServerKubernetesResourceXc } from '../../../schemas/identities/kubernetes/kubernetesResourceXcSchema'
import { ServerAwsEc2InstanceXc } from '../../../schemas/identities/awsEc2InstanceXcSchema'
import { useHighlightedNodesContext } from '../HighlightedNodesContext'
import { KubernetesResourcesTable } from '../../../tables/KubernetesResourcesTable'
import { Collapse, CollapseProps } from 'antd'
import { Ec2InstancesTable } from '../../../tables/Ec2InstancesTable'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import KubernetesIcon from '../../../assets/kubernetes_icon_20.svg?react'
import MachineIcon from '../../../assets/machine_icon_20.svg?react'
import AwsIcon from '../../../assets/aws_icon_16.svg?react'
import AzureVmIcon from '../../../assets/azure_virtual_machine.svg?react'
import { DemoAzureVirtualMachineTable } from '../../../components/drawers/identities/azure/demo/DemoAzureVirtualMachineInstance'
import { ServerEndpointAccessXc } from '../../../schemas/identities/endpointAccessXcSchema'
import { EndpointAccessContextTable } from '../../../tables/EndpointAccessTable'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel'
import { DemoAdEndpointsTable } from '../../../tables/activeDirectory/DemoAdEndpointsTable'

export type DependenciesCollapseItemKeys = 'kubernetes' | 'ec2' | 'azureVms' | 'endpointAccess' | 'demoAdEndpoints'
type DependenciesCollapseItem = Required<CollapseProps>['items'][number] & {
	key: DependenciesCollapseItemKeys
}

type SidePanelDependenciesProps = {
	identity: ServerIdentity
	activeDependenciesKeys: DependenciesCollapseItemKeys[]
	setActiveDependenciesKeys: Dispatch<SetStateAction<DependenciesCollapseItemKeys[]>>
}

export const SidePanelDependencies = ({
	identity,
	activeDependenciesKeys,
	setActiveDependenciesKeys,
}: SidePanelDependenciesProps) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	const onCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList =
				typeof key === 'string'
					? [key as DependenciesCollapseItemKeys]
					: (key as DependenciesCollapseItemKeys[])
			setActiveDependenciesKeys(keyList)
		},
		[setActiveDependenciesKeys],
	)

	const kubernetesResourcesXc: ServerKubernetesResourceXc[] =
		identity.aws_iam_user?.kubernetes_resources_xc ||
		identity.aws_iam_role?.kubernetes_resources_xc ||
		identity.databricks_service_principal?.kubernetes_resources_xc ||
		identity.entra_id_service_principal?.kubernetes_resources_xc ||
		identity.gcp_service_account?.kubernetes_resources_xc ||
		identity.postgres_role?.kubernetes_resources_xc ||
		[]
	const awsEc2InstancesXc: ServerAwsEc2InstanceXc[] =
		identity.aws_iam_role?.aws_ec2_instances_xc ||
		identity.aws_key_pair?.aws_ec2_instances_xc ||
		identity.github_user?.aws_ec2_instances_xc ||
		identity.postgres_role?.aws_ec2_instances_xc ||
		[]
	const endpointAccessXc: ServerEndpointAccessXc[] =
		identity.gcp_service_account?.endpoint_access_xc || identity.github_user?.endpoint_access_xc || []
	const azureVms = identity.entra_id_service_principal?.virtual_machines || []
	const adEndpoints = identity.active_directory_user?.demo_endpoints_dependencies || []

	const items: DependenciesCollapseItem[] = useMemo(() => {
		const ret: DependenciesCollapseItem[] = []
		if (kubernetesResourcesXc.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Kubernetes'} icon={KubernetesIcon} />,
				headerClass: '!items-center',
				key: 'kubernetes',
				children: <KubernetesResourcesTable kubernetesResourcesXc={kubernetesResourcesXc} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'kubernetesResources', id: '' }])
				},
			})
		}

		if (awsEc2InstancesXc.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'EC2 Instances'} icon={AwsIcon} />,
				key: 'ec2',
				headerClass: '!items-center',
				children: <Ec2InstancesTable awsEc2InstancesXc={awsEc2InstancesXc} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'ec2Instances', id: '' }])
				},
			})
		}

		if (endpointAccessXc.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Endpoint Access'} icon={MachineIcon} />,
				key: 'endpointAccess',
				headerClass: '!items-center',
				children: <EndpointAccessContextTable endpointAccessXc={endpointAccessXc} />,
			})
		}

		if (azureVms.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Virtual Machines'} icon={AzureVmIcon} />,
				key: 'azureVms',
				headerClass: '!items-center',
				children: <DemoAzureVirtualMachineTable azureVirtualMachines={azureVms} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'azureVirtualMachines', id: '' }])
				},
			})
		}

		if (adEndpoints.length) {
			ret.push({
				label: <CollapsibleItemLabel label={'Endpoints'} icon={MachineIcon} />,
				key: 'demoAdEndpoints',
				headerClass: '!items-center',
				children: <DemoAdEndpointsTable data={adEndpoints} />,
				onMouseEnter: () => {
					setHighlightedNodes([{ type: 'demoAdEndpoints', id: '' }])
				},
			})
		}

		return ret
	}, [
		identity,
		kubernetesResourcesXc,
		awsEc2InstancesXc,
		endpointAccessXc,
		azureVms,
		adEndpoints,
		setHighlightedNodes,
	])

	if (
		!kubernetesResourcesXc.length &&
		!awsEc2InstancesXc.length &&
		!azureVms.length &&
		!endpointAccessXc.length &&
		!adEndpoints.length
	) {
		return 'No dependencies data'
	}

	return (
		<div
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			<Collapse
				onChange={onCollapseChange}
				activeKey={activeDependenciesKeys}
				style={{ backgroundColor: 'white' }}
				expandIconPosition={'end'}
				items={items}
			/>
		</div>
	)
}
