import { Node, NodeProps } from '@xyflow/react'
import { GroupOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { Tooltip } from 'antd'
import { ServerAzureManagementGroup } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { BaseNode } from '../../common/baseNodes/BaseNode.tsx'

export type AzureManagementGroupNodeType = Node<{ managementGroup: ServerAzureManagementGroup }, 'azureManagementGroup'>
type AzureManagementGroupNodeProps = NodeProps<AzureManagementGroupNodeType>

export const AzureManagementGroupNode = ({ data: { managementGroup } }: AzureManagementGroupNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)

	const managementGroupName = managementGroup.display_name || 'Unnamed Management Group'

	return (
		<BaseNode label="Azure Management Group" name={{ text: managementGroupName }}>
			<Tooltip title={`ID: ${managementGroup.id || 'N/A'}`}>
				<div className={iconWrapperClassName}>
					<GroupOutlined />
				</div>
			</Tooltip>
		</BaseNode>
	)
}
