import { IdentitySource, ServerIdentity } from '../../../schemas/identity.ts'
import { AwsUsageLogsContent } from '../../../components/drawers/identities/AwsUsageLogsContent.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { isDemo } from '../../../utils/demoUtils.ts'
import { AzureUsageLogsContent } from '../../../components/drawers/identities/azure/AzureUsageLogsContent.tsx'
import { useIdentityUsageLogs } from '../../../api/identities.ts'
import { Skeleton } from 'antd'
import { SalesforceSetupAuditTrailLogsTable } from '../../../tables/salesforce/SalesforceSetupAuditTrailLogsTable.tsx'
import { SalesforceApiTotalUsageLogsTable } from '../../../tables/salesforce/SalesforceApiTotalUsageLogsTable.tsx'
import { GithubUsageLogsContent } from '../../../tables/github/GithubUsageLogsContent.tsx'

export const SidePanelUsage = ({ identity }: { identity: ServerIdentity }) => {
	const {
		data: usageLogs,
		isLoading: isLoadingUsageLogs,
		isError: isErrorUsageLogs,
	} = useIdentityUsageLogs(identity.id)

	if (isLoadingUsageLogs) {
		return <Skeleton active />
	}

	if (isErrorUsageLogs && !isDemo) {
		return 'Could not fetch usage data'
	}
	if (identity.source === IdentitySource.AWS_IAM_ROLE) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={identity.aws_iam_role?.aws_account?.id ?? ''}
				identityArn={identity.aws_iam_role?.arn ?? ''}
				usageLogs={
					usageLogs?.aws_iam_role_usage_logs?.length
						? usageLogs.aws_iam_role_usage_logs
						: identity.aws_iam_role?.usage_logs?.length
							? identity.aws_iam_role.usage_logs
							: []
				}
			/>
		)
	}
	if (identity.env_type === EnvironmentType.AWS) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={identity.aws_iam_user?.aws_account?.id ?? ''}
				identityArn={identity.aws_iam_user?.arn ?? ''}
				usageLogs={
					usageLogs?.aws_iam_user_usage_logs?.length
						? usageLogs.aws_iam_user_usage_logs
						: identity.aws_iam_user?.usage_logs?.length
							? identity.aws_iam_user.usage_logs
							: []
				}
			/>
		)
	}

	if (identity.env_type === EnvironmentType.GITHUB && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.github_user?.usage_logs ?? []}
			/>
		)
	}

	if (identity.env_type === EnvironmentType.GITHUB) {
		return <GithubUsageLogsContent auditLogs={usageLogs?.github_user_audit_logs || []} identity={identity} />
	}

	if (identity.source === IdentitySource.GCP_SERVICE_ACCOUNT && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.gcp_service_account?.usage_logs ?? []}
			/>
		)
	}

	if (identity.source === IdentitySource.ENTRA_ID_USER && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.entra_id_user?.demo_usage_logs ?? []}
			/>
		)
	}

	if (identity.source === IdentitySource.ENTRA_ID_USER && !isDemo) {
		return (
			<AzureUsageLogsContent
				identity={identity}
				usageLogs={
					usageLogs?.entra_id_user_usage_logs?.length
						? usageLogs.entra_id_user_usage_logs
						: identity.entra_id_user?.usage_logs?.length
							? identity.entra_id_user.usage_logs
							: []
				}
			></AzureUsageLogsContent>
		)
	}

	if (identity.source === IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL) {
		return (
			<AzureUsageLogsContent
				identity={identity}
				usageLogs={
					usageLogs?.entra_id_service_principal_usage_logs?.length
						? usageLogs.entra_id_service_principal_usage_logs
						: identity.entra_id_service_principal?.usage_logs?.length
							? identity.entra_id_service_principal.usage_logs
							: []
				}
			/>
		)
	}

	if (identity.source === IdentitySource.OKTA && isDemo) {
		return (
			<AwsUsageLogsContent
				environmentId={identity.environment_id ?? ''}
				subEnvironmentId={''}
				identityArn={''}
				usageLogs={identity.okta_user?.demo_usage_logs ?? []}
			/>
		)
	}

	if (identity.source === IdentitySource.SALESFORCE_USER) {
		return <SalesforceSetupAuditTrailLogsTable identity={identity} />
	}

	if (identity.source === IdentitySource.SALESFORCE_CONNECTED_APPLICATION) {
		return <SalesforceApiTotalUsageLogsTable identity={identity} />
	}

	return 'No usage data'
}
