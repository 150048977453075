import { z } from 'zod'
import { DateSchema } from '../../common.ts'

export const BaseAdObjectSchema = z.object({
	object_identifier: z.string(),
	properties: z.object({
		domain: z.string(),
		domain_sid: z.string(),
		name: z.string(),
		distinguished_name: z.string(),
		description: z.string().nullable(),
		when_created: DateSchema,
		sam_account_name: z.string(),
	}),
	is_deleted: z.boolean(),
	is_acl_protected: z.boolean(),
})

export type BaseAdObject = z.infer<typeof BaseAdObjectSchema>
