import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent, IRowNode } from '@ag-grid-community/core'
import { JumpcloudUserXc } from '../schemas/identities/jumpcloud/jumpcloudUserXcSchema.ts'
import { Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../utils.ts'

export const JumpcloudUserXcTable: React.FunctionComponent<{ data: JumpcloudUserXc[] }> = ({ data }) => {
	const gridRef = useRef<AgGridReact<JumpcloudUserXc>>(null)
	const onGridReady = useCallback((_params: GridReadyEvent<JumpcloudUserXc>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<JumpcloudUserXc>[]>(
		() => [
			{
				field: 'displayname',
				headerName: 'Display Name',
			},
			{ field: 'email' },
			{
				field: 'created',
				headerName: 'Created At',
				cellRenderer: (params: { data?: JumpcloudUserXc; node: IRowNode }) => {
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.data?.created)}>
							<div className="text-gray-400">{formatRelativeDateText(params.data?.created, true)}</div>
						</Tooltip>
					)
				},
			},
			{ field: 'suspended', cellRenderer: (params: { value: boolean }) => (params.value ? 'Yes' : 'No') },
			{ field: 'accountLocked', cellRenderer: (params: { value: boolean }) => (params.value ? 'Yes' : 'No') },
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
