import { PriorityType } from '../../../schemas/issue.ts'
import cx from 'classnames'
import InsightIcon from '../../../assets/insight_icon_20.svg?react'
import { PriorityNameTag } from '../../../components/common/PriorityNameTag.tsx'

export const systemGeneratedText = 'System Generated'

export const PrioritySelectOption = ({
	priority,
	withBorder,
}: {
	priority: PriorityType | 0
	withBorder?: boolean
}) => {
	const className = cx('flex items-center w-max', {
		'text-gray-500 gap-1 text-sm': priority === 0,
		'border p-1 pr-2 rounded': priority === 0 && withBorder,
	})

	return (
		<div className={className}>
			{priority === 0 ? (
				<>
					<InsightIcon />
					{systemGeneratedText}
				</>
			) : (
				<PriorityNameTag priority={priority} />
			)}
		</div>
	)
}
