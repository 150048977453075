import { z } from 'zod'
import { BaseAdObjectSchema } from './adBaseSchema'
import { ServerAdAceSchema } from './adAceSchema'
import { ServerAdGroupMemberSchema } from './adGroupMemberSchema'
import { TagSchema } from '../../tags.ts'

export const ServerAdGroupSchema = BaseAdObjectSchema.extend({
	properties: BaseAdObjectSchema.shape.properties.extend({
		high_value: z.boolean(),
		admin_count: z.boolean(),
	}),
	members: z.array(ServerAdGroupMemberSchema),
	aces: z.array(ServerAdAceSchema),
	tags: z.array(TagSchema).nullish(),
})

export type ServerAdGroup = z.infer<typeof ServerAdGroupSchema>
