import { IssueName, IssueNameMap } from '../../schemas/issue'
import { IssueIcon } from './IssueIcon.tsx'
import React from 'react'

export const IssueNameTagFilter: React.FunctionComponent<{
	name: string
}> = ({ name }) => {
	const issueName: string = IssueNameMap[name as IssueName]

	return (
		<div className="flex items-center">
			{issueName && (
				<span className="shrink-0">
					<IssueIcon name={name} />
				</span>
			)}
			<div className="pl-1 text-gray-800 text text-sm">{issueName ? issueName : name}</div>
		</div>
	)
}
