import { ServerIssue } from '../../schemas/issue.ts'
import { DateType } from '../../schemas/common.ts'

function getLatestIssuesLastSeen(issues: ServerIssue[] | undefined): DateType | undefined {
	if (issues === undefined) {
		return undefined
	}

	let latestIssuesLastSeen: DateType | undefined = undefined
	issues.forEach((issue) => {
		if (issue.last_seen) {
			if (latestIssuesLastSeen === undefined || issue.last_seen > latestIssuesLastSeen) {
				latestIssuesLastSeen = issue.last_seen
			}
		}
	})
	return latestIssuesLastSeen
}

export { getLatestIssuesLastSeen }
