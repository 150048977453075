import { tableFilterToQueryFilter } from '../../utils/tableQueryUtils.ts'
import {
	inventoryUrlQuickSearchFilterParameter,
	PaginatedTableContext,
	ServerIdentitiesTableRow,
} from '../../schemas/identity.ts'
import { TableFilterEntry } from '../../schemas/tableFilter.ts'
import { GridApi, ISetFilter, FilterModel, IServerSideGetRowsParams } from '@ag-grid-community/core'
import { fetchIdentitiesQueryForExport } from '../../api/identities.ts'
import { getCsvFileName } from '../../utils.ts'

const tableFieldToQueryFields = {
	// eslint-disable-next-line camelcase
	literal_friendly_name: ['literal_friendly_name', 'literal'],
	[inventoryUrlQuickSearchFilterParameter]: [
		'literal_friendly_name',
		'literal',
		'identities_has_owner',
		'identities_has_credentials',
	],
}

const tableArrayFieldsSet = new Set(['tags_names', 'tags_with_env'])
const tableSetToTextContainsFields = new Set(['affected_environments'])

export const getQueryFilterFromTableFilterModel = (filterModel: Record<string, TableFilterEntry>) =>
	tableFilterToQueryFilter(filterModel, {
		tableFieldToQueryFields,
		tableArrayFieldsSet,
		tableSetToTextContainsFields,
	})

const columnsWithDynamicFilterValues = ['source', 'account_literal', 'affected_environments', 'tags_with_env']

export function refreshDynamicFilterValues(gridApi?: GridApi<ServerIdentitiesTableRow>, columnToIgnore?: string) {
	if (!gridApi) {
		return
	}

	columnsWithDynamicFilterValues.forEach((columnId) => {
		if (columnId === columnToIgnore) {
			return
		}

		gridApi.getColumnFilterInstance(columnId).then((setFilter) => {
			// eslint-disable-next-line no-extra-semi
			;(setFilter as ISetFilter).refreshFilterValues()
		})
	})
}

export function handleExportingPaginatedDataToCsv(gridApi?: GridApi<ServerIdentitiesTableRow>) {
	if (!gridApi) {
		return
	}

	const originalDataSource = gridApi.getGridOption('serverSideDatasource')

	const tempDataSource = {
		getRows: (params: IServerSideGetRowsParams<ServerIdentitiesTableRow, PaginatedTableContext>) => {
			fetchIdentitiesQueryForExport(
				{
					sort: params.request.sortModel.map((col) => ({
						field: col.colId,
						direction: col.sort,
					})),
					filter: getQueryFilterFromTableFilterModel(params.request.filterModel as FilterModel),
				},
				params.context.lens,
				params.context.tab,
			)
				.then((result) => {
					params.success({ rowData: result.identities, rowCount: result.total_count })
					gridApi.exportDataAsCsv({ fileName: getCsvFileName('identities') })
				})
				.finally(() => {
					gridApi.setGridOption('serverSideDatasource', originalDataSource)
				})
		},
	}

	gridApi.setGridOption('serverSideDatasource', tempDataSource)
}
