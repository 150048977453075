import React, { useMemo } from 'react'
import cx from 'classnames'
import { getPriorityColor } from '../../../tables/helpers.ts'
import { PriorityType, PriorityTypeNameMap } from '../../../schemas/issue.ts'
import { ServerFindingByPriority } from '../../../schemas/metrics/findingsByPriority.ts'
import { ChartLegendItem } from '../../../components/common/ChartLegendItem.tsx'

export const PriorityBar: React.FunctionComponent<{
	findingsByPriority?: ServerFindingByPriority[]
	onPriorityNameTagClick?: (priority: string) => void
	keepZeroValues?: boolean
}> = ({ findingsByPriority, onPriorityNameTagClick, keepZeroValues }) => {
	const priorities = useMemo(() => {
		if (!findingsByPriority) {
			return []
		}

		const mappedPriorities = findingsByPriority
			.map((data) => ({
				name: PriorityTypeNameMap[data.priority],
				value: data.count,
				bgColor: getPriorityColor(PriorityType[data.priority]),
				key: data.priority,
				disabled: data.disabled,
			}))
			.sort((a, b) => PriorityType[b.key] - PriorityType[a.key])

		if (keepZeroValues) {
			return mappedPriorities
		}

		return mappedPriorities.filter((item) => item.value > 0)
	}, [findingsByPriority])

	const total = useMemo(() => priorities.reduce((total, item) => total + item.value, 0), [priorities])

	return (
		<div className="space-y-1">
			<div className="flex flex-row items-center gap-4">
				{priorities.map((priority) => (
					<ChartLegendItem
						key={priority.key}
						label={priority.name}
						count={priority.value}
						color={priority.bgColor}
						labelKey={priority.key}
						onClick={onPriorityNameTagClick}
						disabled={!!priority.disabled}
					/>
				))}
			</div>
			<div
				style={{
					boxShadow: '0px 1px 3px 0px #0000000F',
				}}
				className="flex w-full bg-white p-0.5 gap-0.5 border-2 rounded-md min-w-[100px] 2xl:min-w-[220px] max-w-[500px]"
			>
				{priorities.map((priority, index) => {
					const className = cx('transition-opacity', {
						'rounded-l': index === 0,
						'rounded-r': index === priorities.length - 1,
						'cursor-pointer': !!onPriorityNameTagClick,
						'hover:opacity-50': !!onPriorityNameTagClick,
					})

					if (priority.value === 0) {
						return null
					}

					return (
						<div
							style={{
								width: `${(priority.value / total) * 100}%`,
								height: '6px',
								backgroundColor: priority.bgColor,
								minWidth: '10px',
							}}
							className={className}
							key={priority.key}
							onClick={onPriorityNameTagClick ? () => onPriorityNameTagClick(priority.key) : undefined}
						/>
					)
				})}
			</div>
		</div>
	)
}
