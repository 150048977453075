import { Collapse } from 'antd'
import { ServerOktaUserXc } from '../../../schemas/identities/oktaUserXcSchema.ts'
import { OktaUserXcTable } from '../../../tables/OktaUserXcTable.tsx'
import { ContextPanelHeader } from './ContextPanelHeader.tsx'
import { IdentitySource } from '../../../schemas/identity.ts'

export const OktaUserContext: React.FunctionComponent<{
	oktaUserXc?: ServerOktaUserXc[] | null
}> = ({ oktaUserXc }) => {
	return (
		<>
			<Collapse expandIconPosition={'end'}>
				<Collapse.Panel header={<ContextPanelHeader source={IdentitySource.OKTA} />} key={IdentitySource.OKTA}>
					<OktaUserXcTable data={oktaUserXc!} />
				</Collapse.Panel>
			</Collapse>
		</>
	)
}
