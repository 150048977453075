import { ServerIdentity } from '../../../schemas/identity'
import { AwsIamUserChangeLogsContent } from '../../../components/drawers/identities/AwsIamUserChangeLogsContent'
import { EntraIdChangeLogsContent } from '../../../components/drawers/identities/entraId/EntraIdChangeLogsContent'
import { useHighlightedNodesContext } from '../HighlightedNodesContext'
import { SalesforceOwnershipTable } from '../../../tables/salesforce/SalesforceOwnershipTable'
import { GithubOwnershipTable } from '../../../tables/github/GithubOwnershipTable'
import { DemoAdOwnershipTable } from '../../../tables/activeDirectory/DemoAdOwnershipTable.tsx'

export const SidePanelOwnership = ({ identity }: { identity: ServerIdentity }) => {
	const { setHighlightedNodes } = useHighlightedNodesContext()
	return (
		<div
			onMouseEnter={() => {
				setHighlightedNodes([{ type: 'ownership', id: '' }])
			}}
			onMouseLeave={() => {
				setHighlightedNodes([])
			}}
		>
			{(identity.aws_iam_user || identity.aws_iam_role) && (
				<AwsIamUserChangeLogsContent
					environmentId={identity.environment_id ?? ''}
					subEnvironmentId={
						(identity.aws_iam_user?.aws_account?.id || identity.aws_iam_role?.aws_account?.id) ?? ''
					}
					identityArn={(identity.aws_iam_user?.arn || identity.aws_iam_role?.arn) ?? ''}
					changeLogs={(identity.aws_iam_user?.change_logs || identity.aws_iam_role?.change_logs) ?? []}
				/>
			)}
			{identity.databricks_service_principal && (
				<AwsIamUserChangeLogsContent
					environmentId={''}
					subEnvironmentId={''}
					identityArn={''}
					changeLogs={identity.databricks_service_principal?.change_logs ?? []}
				/>
			)}
			{identity.gcp_service_account && (
				<AwsIamUserChangeLogsContent
					environmentId={identity.environment_id ?? ''}
					subEnvironmentId={''}
					identityArn={''}
					changeLogs={identity.gcp_service_account.change_logs ?? []}
				/>
			)}
			{identity.entra_id_service_principal && (
				<EntraIdChangeLogsContent
					changeLogs={identity.entra_id_service_principal.change_logs}
					identity={identity}
				/>
			)}
			{identity.entra_id_user?.change_logs && (
				<EntraIdChangeLogsContent changeLogs={identity.entra_id_user.change_logs} identity={identity} />
			)}
			{identity.azure_kv_certificate?.change_logs && (
				<EntraIdChangeLogsContent changeLogs={identity.azure_kv_certificate.change_logs} identity={identity} />
			)}
			{identity.azure_kv_key?.change_logs && (
				<EntraIdChangeLogsContent changeLogs={identity.azure_kv_key.change_logs} identity={identity} />
			)}
			{identity.azure_kv_secret?.change_logs && (
				<EntraIdChangeLogsContent changeLogs={identity.azure_kv_secret.change_logs} identity={identity} />
			)}
			{identity.active_directory_user?.demo_ownership &&
				identity.active_directory_user.demo_ownership.length > 0 && (
					<DemoAdOwnershipTable data={identity.active_directory_user.demo_ownership} />
				)}
			{identity.salesforce_user?.ownership_records && (
				<SalesforceOwnershipTable data={identity.salesforce_user.ownership_records} />
			)}
			{identity.salesforce_connected_application?.ownership_records && (
				<SalesforceOwnershipTable data={identity.salesforce_connected_application.ownership_records} />
			)}
			{identity.github_app_installation?.owner_id && (
				<GithubOwnershipTable data={[identity.github_app_installation]} />
			)}
		</div>
	)
}
