import React from 'react'
import { tagsIconsMap } from './common.tsx'
import { Tag } from '../../../schemas/tags.ts'

export const IdentityTagFilterIcon: React.FunctionComponent<{
	name: Tag
}> = ({ name }) => {
	const TagIcon = tagsIconsMap[name]?.icon
	const title = tagsIconsMap[name]?.title || name

	if (!TagIcon) {
		return <div>{title}</div>
	}

	return (
		<div className="flex items-center gap-x-1">
			<div className="text-zinc-500">
				<TagIcon className="w-4 h-4" />
			</div>
			<div className="mr-2">{title}</div>
		</div>
	)
}
