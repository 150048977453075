import { Collapse } from 'antd'
import React from 'react'
import { ServerAwsUsageLog } from '../../../schemas/identities/awsIamUserSchema.ts'
import UserIcon from '../../../assets/user_icon_20.svg?react'
import { AwsUsageLogsContent } from './AwsUsageLogsContent.tsx'

export const AwsUsageLogsContext: React.FunctionComponent<{
	environmentId: string
	subEnvironmentId: string
	identityArn: string
	usageLogs: ServerAwsUsageLog[]
}> = ({ usageLogs, environmentId, subEnvironmentId, identityArn }) => (
	<Collapse
		expandIconPosition={'end'}
		items={[
			{
				key: 'AWS Usage logs',
				label: (
					<div className="flex items-center">
						<UserIcon className="h-7 mr-2" />
						Usage
					</div>
				),
				children: (
					<AwsUsageLogsContent
						usageLogs={usageLogs}
						environmentId={environmentId}
						identityArn={identityArn}
						subEnvironmentId={subEnvironmentId}
					/>
				),
			},
		]}
	/>
)
