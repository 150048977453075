import { formatDate, formatRelativeDateText } from '../../../utils'
import React from 'react'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import { IdentityTypeTag } from '../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../common/IssueSourceIcon.tsx'
import { ServerIdentity } from '../../../schemas/identity'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { Col, Divider, Row, Space } from 'antd'
import { GcpProjectsContext } from './gcp/GcpProjectsContext.tsx'
import { AccountPriorityNameTag } from '../../common/AccountPriorityNameTag.tsx'
import { GcpSaAccessKey } from './gcp/GcpSaAccessKey.tsx'

export const GcpServiceAccountIdentity: React.FunctionComponent<{
	identity?: ServerIdentity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	if (!identity || !identity.gcp_service_account) {
		return null
	}

	const gcpServiceAccount = identity.gcp_service_account
	const accountPriority = gcpServiceAccount?.gcp_project?.priority
	const serviceAccountProjectsRolesXc = gcpServiceAccount.service_account_projects_roles_xc ?? []
	const contextAmount = serviceAccountProjectsRolesXc.length ?? 0
	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Name" value={gcpServiceAccount?.name} span={1} />
				<BaseIdentityDetail label="Email" value={gcpServiceAccount?.email} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.last_activity, true)}
					tooltipTitle={formatDate(identity?.last_activity)}
					span={1}
				/>
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail
					label="Status"
					value={identity?.gcp_service_account?.disabled ? 'Disabled' : 'Active'}
					span={1}
				/>

				<BaseIdentityDetail
					label="GCP Project"
					value={identity?.account_literal_friendly_name ?? identity?.account_literal}
					tooltipTitle={identity?.account_literal}
					span={1}
				/>

				{accountPriority && (
					<BaseIdentityDetail label="Account Priority" span={1}>
						<AccountPriorityNameTag priority={accountPriority} />
					</BaseIdentityDetail>
				)}

				<BaseIdentityDetail label="Environment" span={1}>
					<IssueSourceIcon source={envType} />
				</BaseIdentityDetail>
				{gcpServiceAccount?.service_account_keys_xc?.map((saKey, index) => {
					return (
						<BaseIdentityDetail key={saKey.name} label={`Key ${index + 1}`} span={1}>
							<GcpSaAccessKey accessKey={saKey} />
						</BaseIdentityDetail>
					)
				})}
			</div>

			<div className="w-full">
				{contextAmount > 0 && (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{serviceAccountProjectsRolesXc.length > 0 && (
									<GcpProjectsContext
										gcpProjectsXc={gcpServiceAccount.service_account_projects_roles_xc}
									/>
								)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
