import { NodeProps, Node } from '@xyflow/react'
import { ExclamationOutlined, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import {
	BaseServerIssue,
	IssueName,
	IssueNameMap,
	IssueStatus,
	IssueStatusMap,
	OpenIssueStatuses,
	PriorityType,
} from '../../../../schemas/issue.ts'
import { IssueIcon } from '../../../../components/common/IssueIcon.tsx'
import { BaseNode } from './baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { NodeBadge } from './NodeBadge.tsx'
import { NodeLabel } from './NodeLabel.tsx'

export type IssueNodeType = Node<{ issue: BaseServerIssue }, 'issue'>
type IssueNodeProps = NodeProps<IssueNodeType>

const IssueType = ({ issue }: { issue: BaseServerIssue }) => {
	if (OpenIssueStatuses.includes(issue.status)) {
		return <NodeLabel label="Issue" />
	}

	return (
		<NodeLabel
			label="Issue"
			iconNode={
				issue.status === IssueStatus.RESOLVED ? (
					<CheckCircleOutlined className="text-green-600" />
				) : (
					<MinusCircleOutlined className="text-red-600" />
				)
			}
			iconTooltipTitle={`${IssueStatusMap[issue.status]} Issue`}
		/>
	)
}

export const IssueNode = ({ data: { issue } }: IssueNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'issue' && highlightedNode.id === issue.id,
	)

	const badgeClassName = cx('text-lg', {
		'bg-red-200 border-red-900 text-red-900': issue.priority === PriorityType.CRITICAL,
		'bg-red-100 border-red-700 text-red-700': issue.priority === PriorityType.HIGH,
		'bg-orange-100 border-orange-600 text-orange-600': issue.priority === PriorityType.MEDIUM,
		'bg-blue-100 border-blue-600 text-blue-600': issue.priority === PriorityType.LOW,
		'bg-gray-100 border-gray-600 text-gray-600': issue.priority === PriorityType.NOT_CALCULATED || !issue.priority,
	})

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.issue.bgHover} transition-colors duration-300 w-[50px] h-[50px] text-white rounded-full flex justify-center items-center`,
		{ [nodeColorClassNames.issue.bg]: !isHighlighted, [nodeColorClassNames.issue.bgHighlighted]: isHighlighted },
	)

	return (
		<BaseNode
			label={<IssueType issue={issue} />}
			name={{ text: IssueNameMap[issue.issue_name as IssueName] || 'Unknown Issue', tooltipText: '' }}
		>
			<NodeBadge className={badgeClassName}>
				<ExclamationOutlined />
			</NodeBadge>
			<div className={iconWrapperClassName}>
				<IssueIcon color="white" size={24} name={issue.issue_name} />
			</div>
		</BaseNode>
	)
}
