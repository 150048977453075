import { z } from 'zod'
import { BaseEntraIdServicePrincipalSchema } from '../../entraId/entraIdServicePrincipalSchema.ts'

export const BaseAzureDevopsServicePrincipalSchema = z.object({
	directory_alias: z.string().nullish(),
	domain: z.string(),
	principal_name: z.string(),
	mail_address: z.string().nullish(),
	origin: z.string(),
	origin_id: z.string(),
	display_name: z.string(),
	descriptor: z.string(),
	application_id: z.string(),
	meta_type: z.string().nullish(),
})

export const ServerAzureDevopsServicePrincipalSchema = BaseAzureDevopsServicePrincipalSchema.extend({
	entra_id_service_principal: z.lazy(() => BaseEntraIdServicePrincipalSchema.nullish()),
})
