import LogRocket from 'logrocket'
import { settings } from '../../env-settings'
import { User } from '@frontegg/redux-store/auth/interfaces'
import { ITenantsResponseV2 } from '@frontegg/rest-api/tenants/interfaces'
import { isUserFromTokenDomain } from '../../utils.ts'
import { isDemo } from '../../utils/demoUtils.ts'

export const trackEvent = (eventName: string, eventProperties?: Parameters<typeof LogRocket.track>[1]) => {
	LogRocket.track(eventName, eventProperties)
}

export const InitLogRocket = (user: User, activeTenant: ITenantsResponseV2, isImpersonating: boolean) => {
	if (isUserFromTokenDomain(user) || !settings.viteLogRocketAppId || isDemo || isImpersonating) {
		return
	}
	LogRocket.init(settings.viteLogRocketAppId)
	LogRocket.identify(user.id, {
		name: user.name,
		email: user.email,
		env: settings.mode,
		activeCustomerId: activeTenant.tenantId,
		activeCustomerName: activeTenant.name,
	})
}

export enum LogRocketTrackEvent {
	IssueNameInSideBarClicked = 'Issue Name In SideBar Clicked',
	IssueDrawerOpenedFromFindingsTable = 'Issue Drawer Opened From Findings Table',
	IssueDrawerOpenedFromRelatedFindingsTable = 'Issue Drawer Opened From Related Findings Table',
	IssueDrawerClosed = 'Issue Drawer Closed',
	DescriptionShowMoreClicked = 'Description Show More Clicked',
	DescriptionShowLessClicked = 'Description Show Less Clicked',
	OpenJsonClicked = 'Open Json Clicked',
	OpenJsonClosed = 'Open Json Closed',
	IssuesTextSearchChanged = 'Issues Text Search Changed',
	MostWantedIdentityFromMetricsClicked = 'Most Wanted Identity From Metrics Clicked',
	SideBarMenuClicked = 'Side Bar Menu Clicked',
}
