import { ServerIdentity } from '../../../schemas/identity'
import { EnvironmentType } from '../../../schemas/envType.ts'
import React, { useEffect, useState } from 'react'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import { ServerPostgresRole } from '../../../schemas/identities/postgresRoleSchema.ts'
import { IdentityTypeTag } from '../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../common/IssueSourceIcon.tsx'
import { formatDate } from '../../../utils.ts'
import PostgresIcon from '../../../assets/postgres_icon_20.svg?react'
import { Col, Divider, Row, Space, Tooltip } from 'antd'
import { AwsIamRoleContext } from './AwsIamRoleContext.tsx'
import { AwsEc2InstanceContext } from './AwsEc2InstanceContext.tsx'
import { AwsIamUserChangeLogsContext } from './AwsIamUserChangeLogsContext.tsx'
import { KubernetesResourceContext } from './kubernetes/KubernetesResourceContext.tsx'

export const PostgresRoleIdentity: React.FunctionComponent<{
	identity?: ServerIdentity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContentAmount] = useState(0)
	const postgresRole: ServerPostgresRole | null | undefined = identity?.postgres_role
	useEffect(() => {
		calcAmountOfContext()
	})

	const calcAmountOfContext = () => {
		let amount = 0
		if (postgresRole?.aws_iam_roles_xc?.length) amount++
		if (postgresRole?.aws_ec2_instances_xc?.length ?? 0 > 0) amount++
		if (postgresRole?.change_logs?.length ?? 0 > 0) amount++
		if (postgresRole?.kubernetes_resources_xc?.length ?? 0 > 0) amount++
		setContentAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Role Name" value={postgresRole?.role_name} span={1} />

				<BaseIdentityDetail label="DB Name" value={postgresRole?.rds_instance?.db_name} span={1} />

				<BaseIdentityDetail label="Instance ID" value={postgresRole?.rds_instance?.instance_id} span={1} />

				<BaseIdentityDetail label="DB Host" value={postgresRole?.rds_instance?.host} span={1} />

				<BaseIdentityDetail label="DB Region" value={postgresRole?.rds_instance?.region} span={1} />

				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Account" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<div className="flex -space-x-4 rtl:space-x-reverse">
							<IssueSourceIcon source={envType} />
							<Tooltip title="Postgres">
								<PostgresIcon />
							</Tooltip>
						</div>
						<Tooltip placement="topLeft" title={identity?.account_literal}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.account_literal_friendly_name ?? identity?.account_literal}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>

				<BaseIdentityDetail
					label="Role Valid Until"
					value={formatDate(postgresRole?.role_valid_until)}
					span={1}
				/>
				<BaseIdentityDetail label="Superuser" span={1}>
					{postgresRole?.is_role_superuser ? 'TRUE' : 'FALSE'}
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Can Create Role" span={1}>
					{postgresRole?.can_role_create_role ? 'TRUE' : 'FALSE'}
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Can Login" span={1}>
					{postgresRole?.can_role_login ? 'TRUE' : 'FALSE'}
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Bypass RLS" span={1}>
					{postgresRole?.can_role_bypass_rls ? 'TRUE' : 'FALSE'}
				</BaseIdentityDetail>

				<BaseIdentityDetail
					label="Connections Limit"
					value={
						postgresRole?.role_connections_limit === -1
							? 'Unlimited'
							: postgresRole?.role_connections_limit?.toString()
					}
					span={1}
				/>

				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
				<BaseIdentityDetail
					label="Role Last Activity"
					value={formatDate(postgresRole?.role_last_activity_date)}
					span={1}
				/>
			</div>
			<div className="w-full">
				{contextAmount > 0 && (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{postgresRole?.aws_iam_roles_xc && postgresRole?.aws_iam_roles_xc.length > 0 && (
									<AwsIamRoleContext awsIamRolesXc={postgresRole?.aws_iam_roles_xc} />
								)}
								{postgresRole?.aws_ec2_instances_xc && postgresRole.aws_ec2_instances_xc.length > 0 ? (
									<Row className="mt-2">
										<Space className="w-full" direction="vertical">
											<AwsEc2InstanceContext
												awsEc2InstancesXc={postgresRole.aws_ec2_instances_xc}
											/>
										</Space>
									</Row>
								) : null}
								<AwsIamUserChangeLogsContext
									environmentId={identity?.environment_id ?? ''}
									subEnvironmentId={identity?.aws_iam_user?.aws_account?.id ?? ''}
									identityArn={''}
									changeLogs={postgresRole?.change_logs ?? []}
								/>
								{postgresRole?.kubernetes_resources_xc &&
									postgresRole?.kubernetes_resources_xc.length > 0 && (
										<KubernetesResourceContext
											kubernetesResourcesXc={postgresRole?.kubernetes_resources_xc}
										/>
									)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
