import { endpointAccessXc } from './crossContextData.ts'
import { generateUUID } from '../utils.ts'

export const githubUsageLogs = [
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0dc',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		events: ['dashboard.git.fetch', 'workflows.rerun_workflow_run', 'demo-repository.git.fetch'],
		events_count: 400,
		day: '2024-07-02T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0dc',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '18.211.39.125',
		association: 'AWS.ec2: i-08f665a7994f33b40',
		events: ['dashboard.git.fetch', 'workflows.rerun_workflow_run', 'demo-repository.git.fetch'],
		events_count: 31,
		day: '2024-07-20T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0db',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'prisma.gabrielabeard',
		events: ['dashboard.pull_request.merge', 'dashboard.git.push'],
		events_count: 2,
		day: '2024-01-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0db',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.168.1.10',
		association: 'prisma.gabrielabeard',
		services: ['dashboard.pull_request.merge', 'dashboard.git.push'],
		events: ['pull_request.merge', 'git.push'],
		events_count: 1,
		day: '2024-04-23T11:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
	{
		id: 'bcb83761-0e3b-4344-8cde-aa4e42ebc0da',
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		created_at: '2024-05-07T15:00:09.546241Z',
		updated_at: '2024-05-07T15:00:09.546241Z',
		arn: '123456123456',
		ip: '192.75.19.123',
		association: 'prisma.gabrielabeard',
		services: ['dashboard.pull_request.merge'],
		events: ['pull_request.merge'],
		events_count: 1,
		day: '2024-03-15T21:05:53Z',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		global_ip_association: null,
		human_ip_associations: null,
		customer_ip_associations: null,
	},
]

export const githubEndpointAccessXc = [
	{
		device_name: "Gabriela's iPhone",
		device_type: 'Mobile - iOS',
		status: 'Active',
		endpoint_protection: false,
		ip_address: '192.75.19.123',
		first_seen: '2024-03-11 14:42:39+00:00',
		last_seen: '2024-06-29 21:04:33+00:00',
	},
	{
		device_name: "Gabriela's Dell",
		device_type: 'Desktop - Windows',
		status: 'Active',
		endpoint_protection: true,
		ip_address: '192.168.1.10',
		first_seen: '2024-02-23 08:40:08+00:00',
		last_seen: '2024-07-23 11:13:52+00:00',
	},
]

export const githubUserData = {
	'40000': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6400',
		related_sub_envs: [],
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
		sub_env_id: null,
		literal: 'synergistic-sciences/gabrielabeard',
		literal_friendly_name: null,
		created_at: '2024-06-20T15:28:32.698488Z',
		updated_at: '2024-06-20T15:28:32.698488Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty123',
		account_literal_friendly_name: 'synergistic-sciences',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'Gabriela Beard',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'gabrielabeard',
			email: '',
			role: 'ADMIN',
			personal_access_tokens: [
				{
					id: '1',
					token_expired: false,
				},
			],
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
			usage_logs: githubUsageLogs,
			endpoint_access_xc: githubEndpointAccessXc,
			aws_ec2_instances_xc: [
				{
					AmiLaunchIndex: 0,
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-08f665a7994f33b40',
					InstanceType: 't4g.small',
					KernelId: null,
					KeyName: 'eu-west-1-analytics-keypair',
					LaunchTime: '2024-02-06T12:58:53Z',
					Monitoring: {
						state: null,
						State: 'disabled',
					},
					Placement: {
						Tenancy: 'default',
						GroupName: '',
						AvailabilityZone: 'eu-west-1a',
					},
					Platform: null,
					PrivateDnsName: 'ip-172-38-65-219.eu-west-1.compute.internal',
					PrivateIpAddress: '172.38.71.113',
					PublicIpAddress: '18.21.139.125',
					RamdiskId: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					StateTransitionReason: '',
					SubnetId: 'subnet-0f38db516ce8c108a',
					VpcId: 'vpc-083aec6bb077af147',
					Architecture: 'arm64',
					BlockDeviceMappings: [
						{
							Ebs: {
								Status: 'attached',
								VolumeId: 'vol-052352f2b1b4f5e83',
								AttachTime: '2024-02-06 12:58:54+00:00',
								DeleteOnTermination: true,
							},
							DeviceName: '/dev/sda1',
						},
					],
					ClientToken: 'terraform-20240206125852861700000001',
					EbsOptimized: false,
					EnaSupport: true,
					Hypervisor: 'xen',
					IamInstanceProfile: {
						Arn: 'arn:aws:iam::871744574088:instance-profile/SynergisticApplications',
						Id: 'AIPAKSVTQSEFQP02LHR9',
					},
					InstanceLifecycle: null,
					ElasticGpuAssociations: null,
					ElasticInferenceAcceleratorAssociations: null,
					NetworkInterfaces: [
						{
							VpcId: 'vpc-083aec6bb077af147',
							Groups: [
								{
									GroupId: 'sg-0ce21f40c50fe1f40',
									GroupName: 'default',
								},
								{
									GroupId: 'sg-095d9d6c928ae88ad',
									GroupName: 'eu-west-1-app_sa-office-vpn',
								},
								{
									GroupId: 'sg-0e31a93107a895a5c',
									GroupName: 'eu-west-1-app_analytics-dashboard',
								},
							],
							Status: 'in-use',
							OwnerId: '871744574088',
							SubnetId: 'subnet-0f38db516ce8c108a',
							Attachment: {
								Status: 'attached',
								AttachTime: '2024-02-06 12:58:53+00:00',
								DeviceIndex: 0,
								AttachmentId: 'eni-attach-0271a26ebc54569b7',
								NetworkCardIndex: 0,
								DeleteOnTermination: true,
							},
							MacAddress: '06:24:5a:bb:a5:e9',
							Description: '',
							InterfaceType: 'interface',
							Ipv6Addresses: [],
							PrivateDnsName: 'ip-172-38-65-219.eu-west-1.compute.internal',
							SourceDestCheck: true,
							PrivateIpAddress: '172.38.65.219',
							NetworkInterfaceId: 'eni-049b86b693d6ecf41',
							PrivateIpAddresses: [
								{
									Primary: true,
									PrivateDnsName: 'ip-172-38-65-219.eu-west-1.compute.internal',
									PrivateIpAddress: '172.38.65.219',
								},
							],
						},
					],
					OutpostArn: null,
					RootDeviceName: '/dev/sda1',
					RootDeviceType: 'ebs',
					SecurityGroups: [
						{
							GroupName: 'default',
							GroupId: 'sg-0ce21f40c50fe1f40',
						},
						{
							GroupName: 'eu-west-1-app_sa-office-vpn',
							GroupId: 'sg-095d9d6c928ae88ad',
						},
						{
							GroupName: 'eu-west-1-app_analytics-dashboard',
							GroupId: 'sg-0e31a93107a895a5c',
						},
					],
					SourceDestCheck: true,
					SpotInstanceRequestId: null,
					SriovNetSupport: null,
					StateReason: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Staging',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-staging-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					VirtualizationType: 'hvm',
					CpuOptions: {
						CoreCount: 2,
						ThreadsPerCore: 1,
					},
					CapacityReservationId: null,
					CapacityReservationSpecification: {
						CapacityReservationPreference: 'open',
					},
					HibernationOptions: {
						Configured: false,
					},
					Licenses: null,
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
						InstanceMetadataTags: 'disabled',
					},
					EnclaveOptions: {
						Enabled: false,
					},
					BootMode: 'uefi',
					PlatformDetails: 'Linux/UNIX',
					UsageOperation: 'RunInstances',
					UsageOperationUpdateTime: '2024-02-06T12:58:53Z',
					PrivateDnsNameOptions: {
						HostnameType: 'ip-name',
						EnableResourceNameDnsARecord: false,
						EnableResourceNameDnsAAAARecord: false,
					},
					Ipv6Address: null,
					TpmSupport: null,
					MaintenanceOptions: {
						AutoRecovery: 'default',
					},
					CurrentInstanceBootMode: 'uefi',
					ProductCodes: [],
					PublicDnsName: '',
				},
				{
					AmiLaunchIndex: 0,
					ImageId: 'ami-0262f92736782e84b',
					InstanceId: 'i-0cb6586b166591a28',
					InstanceType: 't4g.micro',
					KernelId: null,
					KeyName: 'eu-west-1-analytics-keypair',
					LaunchTime: '2024-02-23T19:37:06Z',
					Monitoring: {
						state: null,
						State: 'disabled',
					},
					Placement: {
						Tenancy: 'default',
						GroupName: '',
						AvailabilityZone: 'eu-west-1a',
					},
					Platform: null,
					PrivateDnsName: 'ip-172-38-71-113.eu-west-1.compute.internal',
					PrivateIpAddress: '172.31.81.123',
					PublicIpAddress: '18.15.137.15',
					RamdiskId: null,
					State: {
						Code: 16,
						Name: 'running',
					},
					StateTransitionReason: '',
					SubnetId: 'subnet-0f38db516ce8c108a',
					VpcId: 'vpc-083aec6bb077af147',
					Architecture: 'arm64',
					BlockDeviceMappings: [
						{
							Ebs: {
								Status: 'attached',
								VolumeId: 'vol-08511716a339215f7',
								AttachTime: '2024-02-23 19:37:07+00:00',
								DeleteOnTermination: true,
							},
							DeviceName: '/dev/sda1',
						},
					],
					ClientToken: 'terraform-20240223193705671800000001',
					EbsOptimized: false,
					EnaSupport: true,
					Hypervisor: 'xen',
					IamInstanceProfile: {
						Arn: 'arn:aws:iam::871744574088:instance-profile/SynergisticApplications',
						Id: 'AIPAKSVTQSEFQP02LHR9',
					},
					InstanceLifecycle: null,
					ElasticGpuAssociations: null,
					ElasticInferenceAcceleratorAssociations: null,
					NetworkInterfaces: [
						{
							VpcId: 'vpc-083aec6bb077af147',
							Groups: [
								{
									GroupId: 'sg-0ce21f40c50fe1f40',
									GroupName: 'default',
								},
								{
									GroupId: 'sg-095d9d6c928ae88ad',
									GroupName: 'eu-west-1-app_sa-office-vpn',
								},
								{
									GroupId: 'sg-0e31a93107a895a5c',
									GroupName: 'eu-west-1-app_analytics-dashboard',
								},
							],
							Status: 'in-use',
							OwnerId: '871744574088',
							SubnetId: 'subnet-0f38db516ce8c108a',
							Attachment: {
								Status: 'attached',
								AttachTime: '2024-02-23 19:37:06+00:00',
								DeviceIndex: 0,
								AttachmentId: 'eni-attach-09f4de3d4a1bae542',
								NetworkCardIndex: 0,
								DeleteOnTermination: true,
							},
							MacAddress: '06:43:91:72:0b:f9',
							Description: '',
							InterfaceType: 'interface',
							Ipv6Addresses: [],
							PrivateDnsName: 'ip-172-38-71-113.eu-west-1.compute.internal',
							SourceDestCheck: true,
							PrivateIpAddress: '172.38.71.113',
							NetworkInterfaceId: 'eni-0a9ddda73bc21f38d',
							PrivateIpAddresses: [
								{
									Primary: true,
									PrivateDnsName: 'ip-172-38-71-113.eu-west-1.compute.internal',
									PrivateIpAddress: '172.38.71.113',
								},
							],
						},
					],
					OutpostArn: null,
					RootDeviceName: '/dev/sda1',
					RootDeviceType: 'ebs',
					SecurityGroups: [
						{
							GroupName: 'default',
							GroupId: 'sg-0ce21f40c50fe1f40',
						},
						{
							GroupName: 'eu-west-1-app_sa-office-vpn',
							GroupId: 'sg-095d9d6c928ae88ad',
						},
						{
							GroupName: 'eu-west-1-app_analytics-dashboard',
							GroupId: 'sg-0e31a93107a895a5c',
						},
					],
					SourceDestCheck: true,
					SpotInstanceRequestId: null,
					SriovNetSupport: null,
					StateReason: null,
					Tags: [
						{
							Key: 'Environment',
							Value: 'Dev',
						},
						{
							Key: 'ComponentId',
							Value: '100314',
						},
						{
							Key: 'CreatedBy',
							Value: 'sm-analytics-eng@synergisticapplications.com',
						},
						{
							Key: 'Name',
							Value: 'events-management-app-api-mob1-eu-1',
						},
						{
							Key: 'Component',
							Value: 'events-management',
						},
						{
							Key: 'Project',
							Value: 'events-management',
						},
						{
							Key: 'application',
							Value: 'events-management-app-api',
						},
						{
							Key: 'Team',
							Value: 'Analytics',
						},
						{
							Key: 'Exporter',
							Value: 'node',
						},
					],
					VirtualizationType: 'hvm',
					CpuOptions: {
						CoreCount: 2,
						ThreadsPerCore: 1,
					},
					CapacityReservationId: null,
					CapacityReservationSpecification: {
						CapacityReservationPreference: 'open',
					},
					HibernationOptions: {
						Configured: false,
					},
					Licenses: null,
					MetadataOptions: {
						State: 'applied',
						HttpTokens: 'optional',
						HttpPutResponseHopLimit: 1,
						HttpEndpoint: 'enabled',
						HttpProtocolIpv6: 'disabled',
						InstanceMetadataTags: 'disabled',
					},
					EnclaveOptions: {
						Enabled: false,
					},
					BootMode: 'uefi',
					PlatformDetails: 'Linux/UNIX',
					UsageOperation: 'RunInstances',
					UsageOperationUpdateTime: '2024-02-23T19:37:06Z',
					PrivateDnsNameOptions: {
						HostnameType: 'ip-name',
						EnableResourceNameDnsARecord: false,
						EnableResourceNameDnsAAAARecord: false,
					},
					Ipv6Address: null,
					TpmSupport: null,
					MaintenanceOptions: {
						AutoRecovery: 'default',
					},
					CurrentInstanceBootMode: 'uefi',
					ProductCodes: [],
					PublicDnsName: '',
				},
			],
		},
	},
	'40001': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6401',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergistic-sciences/David-Brown',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty123',
		account_literal_friendly_name: 'synergistic-sciences',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'david brown',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'David-Brown',
			email: 'david.brown@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40002': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6402',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergistic-sciences/eliSampars',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty123',
		account_literal_friendly_name: 'synergistic-sciences',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'eli Sampars',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'eliSampars',
			email: 'eli.sampars@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40003': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6403',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/David-Brown',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'david brown',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'David-Brown',
			email: 'david.brown@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40004': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6404',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/elizabeth_coleman',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'Elizabeth Coleman',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'elizabeth_coleman',
			email: 'elizabeth.coleman@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40005': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6405',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/michaeljones',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'michaeljones',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'michaeljones',
			email: 'michael.jones@synergy.com',
			role: 'MEMBER',
			personal_access_tokens: [
				{
					id: '1',
					token_expired: false,
				},
			],
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'synergy-charts',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergy-charts',
							source_type: 'Repository',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
			entra_id_user_xc: {
				mail: 'michael.jones@synergy.com',
				name: 'michael.jones@synergy.com',
				surname: 'Jones',
				job_title: 'DevOps',
				created_at: '2023-01-09 07:59:06+00:00',
				given_name: 'Michel',
				sub_env_id: null,
				updated_at: null,
				customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
				mobile_phone: '0541111947',
				entra_user_id: generateUUID(),
				last_activity: '2024-07-09 11:16:04+00:00',
				environment_id: '5cc78c89-5b7b-4b17-a5f9-2daf1e5c896b',
				account_enabled: false,
				office_location: 'USA',
				preferred_language: null,
				user_principal_name: 'michael.jones@synergy.com',
				password_last_changed: '2023-10-22 18:03:03+00:00',
			},
		},
	},
	'40006': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6406',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/jane-smith',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: '',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'jane-smith',
			email: '',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40007': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6407',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/shelby',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-02-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'shelby',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'shelby',
			email: 'ms.shelby@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40008': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6408',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'synergy/wvargas',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-01-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'William Vargas',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'wvargas',
			email: 'william.vargas@synergy.com',
			role: 'MEMBER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
			endpoint_access_xc: endpointAccessXc,
		},
	},
	'40009': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6409',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: 'AlexJohnson',
		literal_friendly_name: null,
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-01-20T15:28:32.698488Z',
		env_type: 'GITHUB',
		type: 'HUMAN',
		source: 'GITHUB_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_qwerty456',
		account_literal_friendly_name: 'synergy',
		affected_environments: null,
		github_user_id: '81b4ba66-ec2e-42fe-ac11-8a3074a77479',
		github_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '81b4ba15-ec3e-42fe-ac11-8a3074a77479',
			name: 'Alex Johnson',
			created_at: '2024-06-20T12:04:57.328045Z',
			updated_at: '2024-06-20T12:04:57.328045Z',
			last_activity: '2024-06-20T12:04:57.328045Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			login: 'AlexJohnson',
			email: 'johnsonalex@gmail.com',
			role: 'OUTSIDER',
			github_teams_xc: [
				{
					name: 'devops',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'synergy-charts',
							source_type: 'repository',
						},
					],
				},
				{
					name: 'frontend-team',
					permissions_on_repos: [
						{
							permission: 'WRITE',
							source_name: 'dashboard',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'synergy-common',
							source_type: 'repository',
						},
						{
							permission: 'READ',
							source_name: 'api',
							source_type: 'repository',
						},
					],
				},
			],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergy',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'40010': {
		id: '85dd18ab-32ec-43f4-a4f1-6b3861b86e56',
		related_sub_envs: [],
		customer_id: '052aab09-f731-4a79-9f12-99a8d8f3c0dd',
		environment_id: '35efe7ff-95df-4f54-be2c-5e4b52ed3724',
		sub_env_id: null,
		literal: 'synergy-ci-cd',
		literal_friendly_name: null,
		created_at: '2023-04-10T13:43:01Z',
		updated_at: '2024-08-28T09:08:43Z',
		system_update_at: '2024-11-11T05:47:20.343035Z',
		last_activity: null,
		activity_status: 'ACTIVE',
		env_type: 'GITHUB',
		type: 'MACHINE',
		source: 'GITHUB_APP_INSTALLATION',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_kgDOAM-9uQ',
		account_literal_friendly_name: 'synergy-hq',
		affected_environments: null,
		okta_api_key: null,
		okta_api_key_id: null,
		okta_user: null,
		okta_user_id: null,
		google_workspace_user: null,
		google_workspace_user_id: null,
		aws_iam_user: null,
		aws_iam_user_id: null,
		aws_iam_role: null,
		aws_iam_role_id: null,
		aws_iam_group: null,
		aws_iam_group_id: null,
		aws_ec2_instance: null,
		aws_ec2_instance_id: null,
		aws_key_pair: null,
		aws_key_pair_id: null,
		jumpcloud_user: null,
		jumpcloud_user_id: null,
		entra_id_user_id: null,
		entra_id_user: null,
		entra_id_service_principal_id: null,
		entra_id_service_principal: null,
		snowflake_user_id: null,
		snowflake_user: null,
		kubernetes_resource_id: null,
		kubernetes_resource: null,
		gcp_service_account_id: null,
		gcp_service_account: null,
		postgres_role_id: null,
		postgres_role: null,
		github_user_id: null,
		github_user: null,
		azure_kv_secret_id: null,
		azure_kv_secret: null,
		azure_kv_key_id: null,
		azure_kv_key: null,
		azure_kv_certificate_id: null,
		azure_kv_certificate: null,
		github_app_installation_id: 'a252be03-281c-419f-9001-ac9ab429d2ce',
		github_app_installation: {
			customer_id: '052aab09-f731-4a79-9f12-99a8d8f3c0dd',
			id: 'a252be03-281c-419f-9001-ac9ab429d2ce',
			name: 'synergy-ci-cd',
			created_at: '2023-04-10T13:43:01Z',
			updated_at: '2024-08-28T09:08:43Z',
			last_activity: null,
			environment_id: '35efe7ff-95df-4f54-be2c-5e4b52ed3724',
			sub_env_id: null,
			system_updated_at: '2024-09-10T08:02:29.004294Z',
			installation_id: 36276588,
			app_id: 315796,
			is_public: false,
			owner_id: null,
			owner_name: null,
			permissions: [
				{
					scope: 'checks',
					permission: 'write',
				},
				{
					scope: 'contents',
					permission: 'write',
				},
				{
					scope: 'metadata',
					permission: 'read',
				},
				{
					scope: 'statuses',
					permission: 'write',
				},
				{
					scope: 'administration',
					permission: 'write',
				},
				{
					scope: 'repository_hooks',
					permission: 'write',
				},
			],
			repositories: ['synergy', 'ios', 'android'],
			system_created_at: '2024-09-10T08:02:29.005930Z',
		},
		salesforce_user_id: null,
		salesforce_user: null,
		salesforce_connected_application_id: null,
		salesforce_connected_application: null,
	},
	'40011': {
		id: 'cef0a878-e5c4-4238-b3cd-6c48301079ce',
		related_sub_envs: [],
		customer_id: '052aab09-f731-4a79-9f12-99a8d8f3c0dd',
		environment_id: '35efe7ff-95df-4f54-be2c-5e4b52ed3724',
		sub_env_id: null,
		literal: 'slack',
		literal_friendly_name: null,
		created_at: '2019-11-24T09:06:43Z',
		updated_at: '2024-08-27T11:19:22Z',
		system_update_at: '2024-11-11T05:47:20.342876Z',
		last_activity: null,
		activity_status: 'ACTIVE',
		env_type: 'GITHUB',
		type: 'MACHINE',
		source: 'GITHUB_APP_INSTALLATION',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'O_kgDOAM-9uQ',
		account_literal_friendly_name: 'synergy-hq',
		affected_environments: null,
		okta_api_key: null,
		okta_api_key_id: null,
		okta_user: null,
		okta_user_id: null,
		google_workspace_user: null,
		google_workspace_user_id: null,
		aws_iam_user: null,
		aws_iam_user_id: null,
		aws_iam_role: null,
		aws_iam_role_id: null,
		aws_iam_group: null,
		aws_iam_group_id: null,
		aws_ec2_instance: null,
		aws_ec2_instance_id: null,
		aws_key_pair: null,
		aws_key_pair_id: null,
		jumpcloud_user: null,
		jumpcloud_user_id: null,
		entra_id_user_id: null,
		entra_id_user: null,
		entra_id_service_principal_id: null,
		entra_id_service_principal: null,
		snowflake_user_id: null,
		snowflake_user: null,
		kubernetes_resource_id: null,
		kubernetes_resource: null,
		gcp_service_account_id: null,
		gcp_service_account: null,
		postgres_role_id: null,
		postgres_role: null,
		github_user_id: null,
		github_user: null,
		azure_kv_secret_id: null,
		azure_kv_secret: null,
		azure_kv_key_id: null,
		azure_kv_key: null,
		azure_kv_certificate_id: null,
		azure_kv_certificate: null,
		github_app_installation_id: '80eb0664-255c-4a9c-9ab2-bf1ad5653759',
		github_app_installation: {
			customer_id: '052aab09-f731-4a79-9f12-99a8d8f3c0dd',
			id: '80eb0664-255c-4a9c-9ab2-bf1ad5653759',
			name: 'slack',
			created_at: '2019-11-24T09:06:43Z',
			updated_at: '2024-08-27T11:19:22Z',
			last_activity: null,
			environment_id: '35efe7ff-95df-4f54-be2c-5e4b52ed3724',
			sub_env_id: null,
			system_updated_at: '2024-09-10T08:02:29.004172Z',
			installation_id: 5366888,
			app_id: 7100,
			is_public: true,
			owner_id: 9919,
			owner_name: 'github',
			permissions: [
				{
					scope: 'checks',
					permission: 'read',
				},
				{
					scope: 'issues',
					permission: 'write',
				},
				{
					scope: 'actions',
					permission: 'write',
				},
				{
					scope: 'contents',
					permission: 'read',
				},
				{
					scope: 'metadata',
					permission: 'read',
				},
				{
					scope: 'statuses',
					permission: 'read',
				},
				{
					scope: 'deployments',
					permission: 'write',
				},
				{
					scope: 'discussions',
					permission: 'read',
				},
				{
					scope: 'pull_requests',
					permission: 'write',
				},
				{
					scope: 'repository_projects',
					permission: 'read',
				},
			],
			repositories: ['synergy', 'ios', 'android'],
			system_created_at: '2024-09-10T08:02:29.005930Z',
		},
		salesforce_user_id: null,
		salesforce_user: null,
		salesforce_connected_application_id: null,
		salesforce_connected_application: null,
	},
}
