import { useState } from 'react'
import { SegmentedValue } from 'rc-segmented'
import { Segmented } from 'antd'
import { KeysRotationChart } from '../../charts/KeyRotationChart.tsx'
import { IdentitiesLifetimeChart } from '../../charts/IdentitiesLifetimeChart.tsx'
import { MetricsChartContainer } from './MetricsChartContainer.tsx'

type SegmentOption = 'Keys' | 'Identities'

export const LifecycleDistribution = () => {
	const [selectedSegment, setSelectedSegment] = useState<SegmentOption>('Keys')
	const onChange = (value: SegmentedValue) => {
		setSelectedSegment(value as SegmentOption)
	}
	const options = [
		{
			label: (
				<div className="p-1">
					<div>Key Rotation</div>
				</div>
			),
			value: 'Keys',
		},
		{
			label: (
				<div className="p-1">
					<div>Identity Age</div>
				</div>
			),
			value: 'Identities',
		},
	]

	return (
		<MetricsChartContainer
			className="col-span-6"
			header={
				<>
					<span>Lifecycle Distribution</span>
					<Segmented size="small" onChange={onChange} value={selectedSegment} options={options} />
				</>
			}
		>
			{selectedSegment === 'Keys' && <KeysRotationChart />}
			{selectedSegment === 'Identities' && <IdentitiesLifetimeChart />}
		</MetricsChartContainer>
	)
}
