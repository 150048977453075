import { z } from 'zod'
import { DateSchema } from '../../common.ts'

export enum EntraIdCredentialType {
	ClientSecret = 'Client Secret',
	Certificate = 'Certificate',
}
export const ServerEntraIdSignInActivitySchema = z.object({
	signin_time: DateSchema.nullish(),
	ip_address: z.string().nullish(),
})

export const ServerEntraIdKeyCredentialSchema = z.object({
	custom_key_identifier: z.string().nullish(),
	display_name: z.string().nullish(),
	key: z.string().nullish(),
	key_id: z.string().nullish(),
	odata_type: z.string().nullish(),
	start_date_time: DateSchema.nullish(),
	end_date_time: DateSchema.nullish(),
	type: z.string().nullish(),
	usage: z.string().nullish(),
	last_sign_in: ServerEntraIdSignInActivitySchema.nullish(),
})

export type ServerEntraIdKeyCredential = z.infer<typeof ServerEntraIdKeyCredentialSchema>

export const ServerEntraIdPasswordCredentialSchema = z.object({
	customer_key_identifier: z.string().nullish(),
	display_name: z.string().nullish(),
	hint: z.string().nullish(),
	key_id: z.string().nullish(),
	odata_type: z.string().nullish(),
	secret_text: z.string().nullish(),
	end_date_time: DateSchema.nullish(),
	start_date_time: DateSchema.nullish(),
	last_sign_in: ServerEntraIdSignInActivitySchema.nullish(),
})

export type ServerEntraIdPasswordCredential = z.infer<typeof ServerEntraIdPasswordCredentialSchema>

// Common authentication credential schema
export const EntraIdCommonAuthenticationCredentialSchema = z.object({
	displayName: z.string(),
	keyId: z.string(),
	type: z.nativeEnum(EntraIdCredentialType),
	startDateTime: DateSchema,
	endDateTime: DateSchema,
	lastSignIn: DateSchema.nullish(),
})

export type EntraIdCommonAuthenticationCredential = z.infer<typeof EntraIdCommonAuthenticationCredentialSchema>

export const ServerEntraIdApplicationXcSchema = z
	.object({
		app_id: z.string().nullish(),
		created_date_time: DateSchema.nullish(),
		description: z.string().nullish(),
		display_name: z.string().nullish(),
		key_credentials: z.array(ServerEntraIdKeyCredentialSchema).nullish(),
		password_credentials: z.array(ServerEntraIdPasswordCredentialSchema).nullish(),
	})
	.nullish()

export const normalizeCredentials = (
	keyCredentials: ServerEntraIdKeyCredential[],
	passwordCredentials: ServerEntraIdPasswordCredential[],
): EntraIdCommonAuthenticationCredential[] => {
	// Normalize key credentials
	const normalizedKeyCredentials = keyCredentials.map((credential) => ({
		displayName: credential.display_name || '',
		keyId: credential.key_id || '',
		type: EntraIdCredentialType.Certificate,
		startDateTime: credential.start_date_time || new Date(),
		endDateTime: credential.end_date_time || new Date(),
		lastSignIn: credential.last_sign_in?.signin_time,
	}))

	// Normalize password credentials
	const normalizedPasswordCredentials = passwordCredentials.map((credential) => ({
		displayName: credential.display_name || '',
		keyId: credential.key_id || '',
		type: EntraIdCredentialType.ClientSecret,
		startDateTime: credential.start_date_time || new Date(),
		endDateTime: credential.end_date_time || new Date(),
		lastSignIn: credential.last_sign_in?.signin_time,
	}))

	// Combine and return both normalized credential arrays
	return [...normalizedKeyCredentials, ...normalizedPasswordCredentials]
}
