import React, { useMemo, useState } from 'react'
import { message, Modal } from 'antd'
import { integrations, integrationsByKey } from '../../registry'
import { useCreateEnvironment, useEnvironments } from '../../../../api/environments'
import { useTestConnection } from '../../../../api/integrations'
import { IntegrationTestConnectionRequest } from '../../../../schemas/integrations/integrations'
import { IntegrationConfig, IntegrationFormData } from '../../types/integrationConfig.ts'
import { IntegrationsState, IntegrationStatus } from '../../types/integrationState.ts'
import { IntegrationsError, IntegrationsSkeleton } from '../IntegrationStates/IntegrationStates.tsx'
import { IntegrationCard } from '../IntegrationCard/IntegrationCard.tsx'
import { IntegrationForm } from '../IntegrationForm/IntegrationForm.tsx'

interface EnvironmentIntegrationsProps {
	isModalVisible: boolean
	setIsModalVisible: (visible: boolean) => void
}

export const EnvironmentIntegrations: React.FC<EnvironmentIntegrationsProps> = ({
	isModalVisible,
	setIsModalVisible,
}) => {
	const { data: environments, isLoading, error } = useEnvironments()
	const { mutate: createEnvironment, isLoading: isCreatingEnvironment } = useCreateEnvironment()
	const testConnection = useTestConnection()
	const [selectedIntegration, setSelectedIntegration] = useState<IntegrationConfig | null>(null)

	const integrationsState: IntegrationsState = useMemo(() => {
		if (!environments) return {}

		return integrations.reduce((integrationsState, integration) => {
			const envs = environments[integration.key as keyof typeof environments] || []
			integrationsState[integration.key] = {
				status: envs.length > 0 ? IntegrationStatus.ACTIVE : IntegrationStatus.INACTIVE,
				environmentCount: envs.length,
			}
			return integrationsState
		}, {} as IntegrationsState)
	}, [environments])

	const handleConnect = (integrationKey: string) => {
		const integration = integrationsByKey[integrationKey]
		if (integration) {
			setSelectedIntegration(integration)
			setIsModalVisible(true)
		}
	}

	const handleCloseModal = () => {
		setIsModalVisible(false)
		setSelectedIntegration(null)
	}

	const handleConnectSubmit = (formData: IntegrationFormData) => {
		if (!selectedIntegration || !selectedIntegration.createEnvironmentPayload) return

		const environmentData = selectedIntegration.createEnvironmentPayload(formData)
		createEnvironment(environmentData, {
			onSuccess: () => {
				message.success(`${selectedIntegration.name} environment created successfully`)
				handleCloseModal()
			},
			onError: (error) => {
				console.error('Error creating environment:', error)
				message.error(`Failed to create ${selectedIntegration.name} environment`)
			},
		})
	}

	const handleTestConnection = async (request: IntegrationTestConnectionRequest) => {
		return await testConnection.mutateAsync(request)
	}

	const handleEdit = () => {
		// Empty handler
	}

	if (isLoading) {
		return <IntegrationsSkeleton />
	}

	if (error) {
		return <IntegrationsError error={error instanceof Error ? error : new Error('An unknown error occurred')} />
	}

	return (
		<>
			<div className="flex flex-wrap gap-5">
				{integrations.map((integration) => (
					<IntegrationCard
						key={integration.key}
						logo={integration.logo}
						name={integration.name}
						description={integration.description}
						status={integrationsState[integration.key]?.status || IntegrationStatus.INACTIVE}
						environmentCount={integrationsState[integration.key]?.environmentCount || 0}
						onConnect={() => handleConnect(integration.key)}
						onEdit={handleEdit}
					/>
				))}
			</div>

			<Modal
				open={isModalVisible}
				onCancel={handleCloseModal}
				footer={null}
				width={530}
				className="integrationModal"
				destroyOnClose={true}
			>
				{selectedIntegration && (
					<IntegrationForm
						key={selectedIntegration.key}
						integration={selectedIntegration}
						onSubmit={handleConnectSubmit}
						onTestConnection={handleTestConnection}
						onClose={handleCloseModal}
						isConnecting={isCreatingEnvironment}
						isTestingConnection={testConnection.isLoading}
					/>
				)}
			</Modal>
		</>
	)
}
