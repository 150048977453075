import { ReactNode } from 'react'
import { Tooltip } from 'antd'

type NodeLabelProps = {
	label: string
	iconNode?: ReactNode
	iconTooltipTitle?: string
}

export const NodeLabel = ({ label, iconNode, iconTooltipTitle }: NodeLabelProps) => {
	if (!iconNode) {
		return label
	}

	return (
		<div className="flex gap-1 items-center">
			{iconTooltipTitle ? <Tooltip title={iconTooltipTitle}>{iconNode}</Tooltip> : iconNode}
			{label}
		</div>
	)
}
