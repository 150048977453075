import { useMemo } from 'react'
import { ColDef } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { ConfigProvider, Switch } from 'antd'
import {
	IssueImpactProbabilityWithCount,
	IssueNameMap,
	PriorityType,
	PriorityTypeMap,
	ResourceTypeMap,
} from '../../../schemas/issue.ts'
import { IssueIcon } from '../../../components/common/IssueIcon.tsx'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { SelectPriority } from './SelectPriority.tsx'
import { systemGeneratedText } from './PrioritySelectOption.tsx'

type IssueTypesTableProps = {
	isEditing: boolean
	isFetching: boolean
	issueTypes: IssueImpactProbabilityWithCount[]
	searchText: string
	onToggleIssueType: (issueType: IssueImpactProbabilityWithCount) => void
	onChangeIssueTypePriority: (issueType: IssueImpactProbabilityWithCount, newPriority: PriorityType | null) => void
}

export const IssueTypesTable = ({
	isEditing,
	isFetching,
	issueTypes,
	searchText,
	onToggleIssueType,
	onChangeIssueTypePriority,
}: IssueTypesTableProps) => {
	const columns = useMemo(
		(): ColDef<IssueImpactProbabilityWithCount>[] => [
			{
				headerName: 'Issue Type',
				flex: 0.7,
				sort: 'asc',
				sortIndex: 0,
				valueGetter: ({ data }) => (data ? IssueNameMap[data.issue_name] : ''),
				cellRenderer: ({ data, value }: { value: string; data: IssueImpactProbabilityWithCount }) => (
					<div className="flex items-center gap-2 h-full">
						<IssueIcon name={data.issue_name} style={{ flexShrink: 0 }} />
						<span className="text-zinc-500">{value}</span>
					</div>
				),
			},
			{
				headerName: 'Source Environment',
				flex: 0.5,
				sort: 'asc',
				sortIndex: 1,
				valueGetter: ({ data }) => (data ? ResourceTypeMap[data.issue_source] : ''),
				cellRenderer: ({ data, value }: { value: string; data: IssueImpactProbabilityWithCount }) => (
					<div className="flex items-center gap-2 h-full">
						<IssueSourceIcon source={data.issue_source} size={20} />
						<span className="text-zinc-500">{value}</span>
					</div>
				),
			},
			{
				field: 'issue_count',
				headerName: 'Open Issue Count',
				flex: 0.4,
				cellRenderer: ({ value }: { value: IssueImpactProbabilityWithCount['issue_count'] }) => (
					<span className="text-zinc-500">{value}</span>
				),
			},
			{
				field: 'priority',
				headerName: 'Priority',
				flex: 0.7,
				filterValueGetter: ({ data }) =>
					data?.priority ? PriorityTypeMap[data.priority] : systemGeneratedText,
				valueGetter: ({ data }) => data?.priority || 0,
				cellRenderer: ({ data }: { data: IssueImpactProbabilityWithCount }) => (
					<SelectPriority
						priority={data.priority || 0}
						onChange={(newPriority: PriorityType | null) => {
							onChangeIssueTypePriority(data, newPriority)
						}}
						loading={isFetching}
						disabled={!isEditing}
					/>
				),
			},
			{
				headerName: 'Enabled',
				type: 'rightAligned',
				flex: 0.4,
				valueGetter: ({ data }) => !data!.ignore_issue,
				cellRenderer: ({ value, data }: { value: boolean; data: IssueImpactProbabilityWithCount }) => (
					<ConfigProvider
						theme={{
							token: {
								colorPrimary: '#7C987D',
							},
						}}
					>
						<Switch
							checked={value}
							onChange={() => {
								onToggleIssueType(data)
							}}
							loading={isFetching}
							disabled={!isEditing}
						/>
					</ConfigProvider>
				),
			},
		],
		[isEditing, isFetching],
	)

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowData={issueTypes}
			columnDefs={columns}
			quickFilterText={searchText}
			getRowId={({ data }) => data.id}
			rowHeight={50}
		/>
	)
}
