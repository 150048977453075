import { ServerIdentity } from '../../../../../schemas/identity.ts'
import { EnvironmentType } from '../../../../../schemas/envType.ts'
import { ServerAzureKVSecret } from '../../../../../schemas/identities/azure/azureKeyVault/azureKVSecret.ts'
import { BaseIdentityDetail } from '../../baseIdentities/BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../../../common/IssueSourceIcon.tsx'
import { Tag, Tooltip } from 'antd'
import { formatDate } from '../../../../../utils.ts'

export const AzureKVSecretIdentity: React.FunctionComponent<{
	identity?: ServerIdentity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const azureKVSecret: ServerAzureKVSecret | null | undefined = identity?.azure_kv_secret

	return (
		<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
			<BaseIdentityDetail label="Secret Name" value={azureKVSecret?.name} span={1} />
			<BaseIdentityDetail label="Secret ID" value={azureKVSecret?.secret_id} span={1} />
			<BaseIdentityDetail label="Identity Type" span={1}>
				<IdentityTypeTag type={identity?.type} />
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Environment" span={1}>
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Vault Name" value={azureKVSecret?.vault_name} span={1} />
			<BaseIdentityDetail label="Vault ID" value={azureKVSecret?.vault_id} span={1} />
			<BaseIdentityDetail label="Subscription ID" value={azureKVSecret?.subscription_id} span={1} />
			<BaseIdentityDetail label="Status" span={1}>
				<Tag color={azureKVSecret?.is_enabled ? 'green' : 'red'}>
					{azureKVSecret?.is_enabled ? 'Enabled' : 'Disabled'}
				</Tag>
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Managed" span={1}>
				<Tag color={azureKVSecret?.is_managed ? 'blue' : 'default'}>
					{azureKVSecret?.is_managed ? 'Managed' : 'Not Managed'}
				</Tag>
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Created At" value={formatDate(azureKVSecret?.created_at)} span={1} />
			<BaseIdentityDetail label="Updated At" value={formatDate(azureKVSecret?.updated_at)} span={1} />
			<BaseIdentityDetail label="Start Date" value={formatDate(azureKVSecret?.start_date)} span={1} />
			<BaseIdentityDetail label="Expiration Date" value={formatDate(azureKVSecret?.expiration_date)} span={1} />
		</div>
	)
}
