import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { ServerDatabricksRole } from '../../../../schemas/identities/databricks/ServerDatabricksRoleSchema.ts'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons.tsx'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'

export type DatabricksRoleNodeType = Node<{ databricksRole: ServerDatabricksRole }, 'databricksRole'>
type DatabricksRoleNodeProps = NodeProps<DatabricksRoleNodeType>

export const DatabricksRoleNode = ({ data: { databricksRole } }: DatabricksRoleNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)

	return (
		<BaseNode label="Role" name={databricksRole?.role_name ? { text: databricksRole.role_name } : null}>
			<RiskNodeTagIcons tags={databricksRole?.tags} />
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
