import { FilterChipValue } from './FilterChipValue.tsx'
import { TableDateFilterEntry, TableDateFilterType } from '../../../schemas/tableFilter.ts'

const formatDate = (date: string) => new Date(date).toLocaleDateString()

const TableDateFilterToChipValue: Partial<
	Record<TableDateFilterType, (dateFrom: string, dateTo: string | null | undefined) => string>
> = {
	[TableDateFilterType.GREATER_THAN]: (dateFrom) => `After ${formatDate(dateFrom)}`,
	[TableDateFilterType.LESS_THAN]: (dateFrom) => `Before ${formatDate(dateFrom)}`,
	[TableDateFilterType.IN_RANGE]: (dateFrom, dateTo) => `${formatDate(dateFrom)}-${dateTo ? formatDate(dateTo) : ''}`,
}

type DateFilterChipProps = {
	filter: TableDateFilterEntry
}

export const DateFilterChip = ({ filter }: DateFilterChipProps) => {
	if (filter.type in TableDateFilterToChipValue) {
		if (filter.dateFrom) {
			return <FilterChipValue value={TableDateFilterToChipValue[filter.type]!(filter.dateFrom, filter.dateTo)} />
		}
	}

	if (filter.type === TableDateFilterType.BLANK) {
		return <FilterChipValue value="Blank" />
	}

	console.error(`Unsupported date filter type: ${filter.type}`)
	if (!filter.dateFrom) {
		console.error('Date filter has no dateFrom')
		return null
	}

	return <FilterChipValue value={formatDate(filter.dateFrom)} />
}
