import { AgChartOptions } from 'ag-charts-enterprise'
import { AgChartsReact } from 'ag-charts-react'
import { useIdentitiesLifetimeDistribution } from '../api/metrics.ts'
import { useMemo } from 'react'
import { formatNumber } from '../utils/numberUtils.ts'
import { themeColors } from '../utils/colorUtils.ts'
import { ServerDistributionData } from '../schemas/metrics/distributionData.ts'
import { Skeleton } from 'antd'

enum Labels {
	LESS_THAN_MONTH = '< 1 month',
	ONE_TO_THREE_MONTHS = '1-3 months',
	THREE_TO_SIX_MONTHS = '3-6 months',
	SIX_TO_TWELVE_MONTHS = '6-12 months',
	MORE_THAN_YEAR = '> 1 year',
}

const labelToFillColor: Record<Labels, string> = {
	[Labels.LESS_THAN_MONTH]: '#EAF4F2',
	[Labels.ONE_TO_THREE_MONTHS]: '#D4EBE5',
	[Labels.THREE_TO_SIX_MONTHS]: '#AAD8CC',
	[Labels.SIX_TO_TWELVE_MONTHS]: '#6EBAB0',
	[Labels.MORE_THAN_YEAR]: '#39867C',
}

const labelToStrokeColor: Record<Labels, string> = {
	[Labels.LESS_THAN_MONTH]: '#A5D0C7',
	[Labels.ONE_TO_THREE_MONTHS]: '#53AC93',
	[Labels.THREE_TO_SIX_MONTHS]: '#3D8572',
	[Labels.SIX_TO_TWELVE_MONTHS]: '#397E73',
	[Labels.MORE_THAN_YEAR]: '#26554F',
}

type RecordType = {
	label: Labels
	count: number
}

const formatData = (data: ServerDistributionData): RecordType[] => {
	return [
		{ label: Labels.LESS_THAN_MONTH, count: data.less_than_one_month },
		{ label: Labels.ONE_TO_THREE_MONTHS, count: data.one_to_three_months },
		{ label: Labels.THREE_TO_SIX_MONTHS, count: data.three_to_six_months },
		{ label: Labels.SIX_TO_TWELVE_MONTHS, count: data.six_to_twelve_months },
		{ label: Labels.MORE_THAN_YEAR, count: data.more_than_twelve_months },
	]
}

export const IdentitiesLifetimeChart = () => {
	const { data: identitiesLifetimeDistribution, isLoading } = useIdentitiesLifetimeDistribution()
	const formattedData = useMemo(() => {
		if (identitiesLifetimeDistribution) return formatData(identitiesLifetimeDistribution)
		return []
	}, [identitiesLifetimeDistribution])

	if (isLoading) {
		return <Skeleton active />
	}

	const totalIdentities = formattedData.reduce((acc, datum) => acc + datum.count, 0)

	const options: AgChartOptions = {
		data: formattedData,
		background: {
			fill: themeColors.neutral[100],
		},
		series: [
			{
				type: 'bar',
				xKey: 'label',
				yKey: 'count',
				tooltip: {
					renderer: ({ datum }: { datum: RecordType }) => {
						return {
							content: `${formatNumber(datum.count)} identities (${((datum.count / totalIdentities) * 100).toFixed(2)}%) have been created in ${datum.label}`,
						}
					},
				},
				formatter: ({ datum }: { datum: RecordType }) => {
					return {
						fill: labelToFillColor[datum.label],
						stroke: labelToStrokeColor[datum.label],
						strokeWidth: 2,
					}
				},
			},
		],
		axes: [
			{
				type: 'category',
				position: 'bottom',
				title: {
					text: 'Identity Age',
				},
			},
			{
				type: 'log',
				position: 'left',
				title: {
					text: 'Amount of Identities',
				},
				label: {
					formatter: ({ value }) => formatNumber(value as number),
				},
			},
		],
	}

	return <AgChartsReact options={options} />
}
