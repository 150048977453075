import { MockDemoTag, Tag } from '../../schemas/tags.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { IdentitySource, IdentityType } from '../../schemas/identity.ts'
import { generateUUID } from '../utils.ts'
import { kubernetesResourcesXc5 } from './crossContextData.ts'
import { azureVirtualMachinesData } from './azureIdentitiesData.ts'
import { ResourceType } from '../../schemas/issue.ts'

function getChangeLogs(displayName: string, id: string) {
	return [
		{
			event_time: '2022-02-20T07:50:51Z',
			actor_principal_name: 'brian.espinoza@synergy.onmicrosoft.com',
			actor_principal_id: '1',
			target_resources: [
				{
					id: id,
					displayName: displayName,
					type: 'ServicePrincipal',
					modifiedProperties: [
						{
							displayName: 'Action',
							oldValue: null,
							newValue: 'microsoft.directory/servicePrincipals/create',
						},
					],
				},
			],
			source_ip_address: '207.232.13.65',
			operation_name: 'microsoft.directory/servicePrincipals/create',
		},
		{
			event_time: '2023-10-17T11:56:57Z',
			actor_principal_name: 'brian.espinoza@synergy.onmicrosoft.com',
			actor_principal_id: '1',
			target_resources: [
				{
					id: id,
					displayName: displayName,
					type: 'ServicePrincipal',
					modifiedProperties: [
						{
							displayName: 'Action',
							oldValue: null,
							newValue: 'microsoft.directory/servicePrincipals/authentication/update',
						},
					],
				},
			],
			source_ip_address: '207.232.13.65',
			operation_name: 'microsoft.directory/servicePrincipals/authentication/update',
		},
		{
			event_time: '2023-12-31T15:07:01Z',
			actor_principal_name: 'elizabeth.thomas@synergy.onmicrosoft.com',
			actor_principal_id: '2',
			target_resources: [
				{
					id: id,
					displayName: displayName,
					type: 'ServicePrincipal',
					modifiedProperties: [
						{
							displayName: 'Action',
							oldValue: null,
							newValue: 'Microsoft.Authorization/roleAssignments/write',
						},
					],
				},
			],
			source_ip_address: '5.182.59.83',
			operation_name: 'Microsoft.Authorization/roleAssignments/write',
		},
		{
			event_time: '2023-12-31T15:08:09Z',
			actor_principal_name: 'elizabeth.thomas@synergy.onmicrosoft.com',
			actor_principal_id: '2',
			target_resources: [
				{
					id: id,
					displayName: displayName,
					type: 'ServicePrincipal',
					modifiedProperties: [
						{
							displayName: 'Action',
							oldValue: null,
							newValue: 'Microsoft.Authorization/roleAssignments/delete',
						},
					],
				},
			],
			source_ip_address: '5.182.59.83',
			operation_name: 'Microsoft.Authorization/roleAssignments/delete',
		},
	]
}
export const azureRolesData = {
	Owner: {
		id: '8e3af657-a8ff-443c-a75c-2fe8c4bcb635',
		name: 'Owner',
		type: 'Microsoft.Authorization/roleDefinitions',
		role_name: 'Owner',
		description: 'Grants full access to manage all resources',
		role_type: 'BuiltInRole',
		permissions: [
			{
				actions: ['*'],
				not_actions: [],
				data_actions: [],
				not_data_actions: [],
			},
		],
		assignable_scopes: ['/'],
		created_on: '2018-12-20T07:21:30Z',
		updated_on: '2024-01-18T07:03:32.834455Z',
		tags: [
			{
				id: generateUUID(),
				name: Tag.ADMIN,
				related_resource_type: ResourceType.AZURE,
			},
			{
				id: generateUUID(),
				name: Tag.CREDENTIAL_EXPOSURE,
				related_resource_type: ResourceType.AZURE,
			},
			{
				id: generateUUID(),
				name: Tag.DATA_ACCESS,
				related_resource_type: ResourceType.AZURE,
			},
			{
				id: generateUUID(),
				name: Tag.POSSIBLE_PRIVILEGE_ESCALATION,
				related_resource_type: ResourceType.AZURE,
			},
			{
				id: generateUUID(),
				name: Tag.RESOURCE_EXPOSURE,
				related_resource_type: ResourceType.AZURE,
			},
		],
	},
	Contributor: {
		id: '9f53f0ea-8145-4f85-ae6d-29d3a958aa07',
		name: 'Contributor',
		type: 'Microsoft.Authorization/roleDefinitions',
		role_name: 'Contributor',
		description:
			'Grants full access to manage all resources, but does not allow you to assign roles in Azure RBAC, manage assignments in Azure Blueprints, or share image galleries.',
		role_type: 'BuiltInRole',
		permissions: [
			{
				actions: ['*'],
				not_actions: [],
				data_actions: [],
				not_data_actions: [],
			},
		],
		assignable_scopes: ['/'],
		created_on: '2018-12-20T07:21:30Z',
		updated_on: '2024-01-18T07:03:32.834455Z',
		tags: [
			{
				id: generateUUID(),
				name: Tag.ADMIN,
				related_resource_type: ResourceType.AZURE,
			},
		],
	},
}

export const azureSubscriptionData = {
	Synergy: {
		additional_properties: null,
		id: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		subscription_id: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		display_name: 'Synergy',
		state: 'Enabled',
		subscription_policies: {
			location_placement_id: 'Public_2014-09-01',
			quota_id: 'PayAsYouGo_2014-09-01',
			spending_limit: 'Off',
		},
		authorization_source: 'RoleBased',
		priority: 5,
	},
	SandboxSynergy: {
		additional_properties: null,
		id: '72b2299b-e5c3-4951-af7e-11f8dd618772',
		subscription_id: '72b2299b-e5c3-4951-af7e-11f8dd618772',
		display_name: 'sandboxsynergy',
		state: 'Enabled',
		subscription_policies: {
			location_placement_id: 'Public_2014-09-01',
			quota_id: 'PayAsYouGo_2014-09-01',
			spending_limit: 'Off',
		},
		authorization_source: 'RoleBased',
		priority: 3,
	},
}

export const lucasUsageLogs = [
	{
		caller: "lucas's iphone",
		association: null,
		ip: '127.17.154.214',
		day: '2024-06-08',
		operations: ['MobileAccess'],
		operations_count: 1,
	},
	{
		caller: "lucas's dell",
		association: null,
		ip: '127.25.42.145',
		day: '2024-06-09',
		operations: ['DesktopAccess'],
		operations_count: 1,
	},
	{
		caller: 'tau library',
		association: null,
		ip: '127.31.14.241',
		day: '2024-05-12',
		operations: ['DesktopAccess'],
		operations_count: 1,
	},
]
export const entraIdServicePrincipalsData = {
	'90000': {
		id: '90000',
		literal: '28c74bc5-1c5c-4d20-ab6a-5c79e9bc901b',
		literal_friendly_name: 'prod-data-access',
		account_literal: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		account_literal_friendly_name: 'Synergy',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-01-08T12:26:19Z',
		tags: azureRolesData.Owner.tags,
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Machine,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: '28c74bc5-1c5c-4d20-ab6a-5c79e9bc901b',
			app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
			account_enabled: true,
			description: 'Production data access service principal',
			display_name: 'prod-data-access',
			service_principal_type: 'Application',
			last_sign_in_date_time: '2024-01-08T12:26:19Z',
			entra_id_application_xc: {
				app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
				created_date_time: '2018-12-20T07:21:30Z',
				description: 'Application for production data access',
				display_name: 'data-collector',
				key_credentials: [
					{
						custom_key_identifier: 'secret1',
						display_name: 'secret1',
						key: null,
						key_id: 'b5257986-d560-4dec-a6b1-a03641de5246',
						start_date_time: '2023-03-11T00:00:00Z',
						end_date_time: '2025-07-01T00:00:00Z',
						type: 'AsymmetricX509Cert',
						usage: 'Verify',
						last_sign_in: null,
					},
					{
						custom_key_identifier: 'new secret',
						display_name: 'new secret',
						key: null,
						key_id: 'fa0aca31-a9812-5dd2-fafa-98adf2baa8a4',
						start_date_time: '2023-09-12T00:00:00Z',
						end_date_time: '2024-07-01T00:00:00Z',
						type: 'AsymmetricX509Cert',
						usage: 'Verify',
						last_sign_in: null,
					},
				],
				password_credentials: [
					{
						customer_key_identifier: null,
						display_name: null,
						hint: null,
						key_id: 'key-1234-abcd-5678-efgh',
						secret_text: null,
						start_date_time: '2023-07-01T00:00:00Z',
						end_date_time: '2024-07-01T00:00:00Z',
						last_sign_in: null,
					},
				],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: '9f53f0ea-8145-4f85-ae6d-29d3a958aa07',
						name: 'Owner Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/72b2299b-e5c3-4951-af7e-11f8dd618777',
						role_definition_id: azureRolesData.Owner.id,
						principal_id: '28c74bc5-1c5c-4d20-ab6a-5c79e9bc901b',
						principal_type: 'ServicePrincipal',
						created_on: '2018-12-20T07:21:30Z',
						updated_on: '2024-01-18T07:03:32.834455Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Owner.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: getChangeLogs('prod-data-access', '28c74bc5-1c5c-4d20-ab6a-5c79e9bc901b'),
			usage_logs: [],
			kubernetes_resources_xc: kubernetesResourcesXc5,
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.Synergy.subscription_id,
					account_name: azureSubscriptionData.Synergy.display_name,
				},
			],
		},
	},
	'90001': {
		id: '90001',
		literal: '5afb03fd-a3df-4c41-8acb-1174e514dd2c',
		literal_friendly_name: 'production-aks-user',
		account_literal: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		account_literal_friendly_name: 'Synergy',
		created_at: '2022-02-20T07:50:51Z',
		updated_at: '2024-04-21T11:46:25.926083Z',
		last_activity: '2024-04-21T11:46:25.926083Z',
		tags: azureRolesData.Owner.tags,
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Machine,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: '5afb03fd-a3df-4c41-8acb-1174e514dd2c',
			app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
			account_enabled: true,
			description: 'Production AKS service principal',
			display_name: 'production-aks-principal',
			service_principal_type: 'Application',
			last_sign_in_date_time: null,
			entra_id_application_xc: {
				app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
				created_date_time: '2024-02-20T07:50:51Z',
				description: 'Application for production AKS',
				display_name: 'prod-aks-app',
				key_credentials: [
					{
						custom_key_identifier: 'prodKey1',
						display_name: 'prodKey1',
						key: null,
						key_id: '13411ade-4d71-b654-a5ba-9912fabfa8d1',
						start_date_time: '2023-09-12T00:00:00Z',
						end_date_time: '2025-07-01T00:00:00Z',
						type: 'AsymmetricX509Cert',
						usage: 'Verify',
						last_sign_in: { signin_time: '2024-04-21T11:46:25.926083Z' },
					},
				],
				password_credentials: [
					{
						customer_key_identifier: null,
						display_name: null,
						hint: null,
						key_id: 'key-1234-abcd-5678-efgh',
						secret_text: null,
						start_date_time: '2023-07-01T00:00:00Z',
						end_date_time: '2024-07-01T00:00:00Z',
						last_sign_in: null,
					},
				],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: generateUUID(),
						name: 'Owner Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/72b2299b-e5c3-4951-af7e-11f8dd618777',
						role_definition_id: azureRolesData.Owner.id,
						principal_id: '5afb03fd-a3df-4c41-8acb-1174e514dd2c',
						principal_type: 'ServicePrincipal',
						created_on: '2022-02-20T07:50:51Z',
						updated_on: '2024-04-21T11:46:25.926083Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Owner.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: getChangeLogs('production-aks-principal', '5afb03fd-a3df-4c41-8acb-1174e514dd2c'),
			usage_logs: [],
			kubernetes_resources_xc: kubernetesResourcesXc5,
			virtual_machines: azureVirtualMachinesData,
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.Synergy.subscription_id,
					account_name: azureSubscriptionData.Synergy.display_name,
				},
			],
		},
	},
	'90002': {
		id: '90002',
		literal: 'b1664583-1c2e-4401-894e-b11fe0993e98',
		literal_friendly_name: 'qa-ci-deployer',
		account_literal: 'e2b45ca1-aaeb-4811-88ae-812481b38d79',
		account_literal_friendly_name: 'sandboxsynergy',
		created_at: '2019-03-09T16:06:13Z',
		updated_at: '2024-04-25T06:52:24.615829Z',
		last_activity: '2024-04-16T09:46:05Z',
		tags: [...azureRolesData.Contributor.tags, ...azureRolesData.Owner.tags],
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Machine,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: 'b1664583-1c2e-4401-894e-b11fe0993e98',
			app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
			account_enabled: true,
			description: 'QA CI Deployer service principal',
			display_name: 'qa-ci-deployer',
			service_principal_type: 'Application',
			last_sign_in_date_time: '2024-04-16T09:46:05Z',
			entra_id_application_xc: {
				app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
				created_date_time: '2019-03-09T16:06:13Z',
				description: 'Application for Synergy sandbox',
				display_name: 'synergy-sandbox-app',
				key_credentials: [],
				password_credentials: [],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: generateUUID(),
						name: 'Contributor Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/e2b45ca1-aaeb-4811-88ae-812481b38d79',
						role_definition_id: 'b24988ac-6180-42a0-ab88-20f7382dd24c',
						principal_id: 'b1664583-1c2e-4401-894e-b11fe0993e98',
						principal_type: 'ServicePrincipal',
						created_on: '2019-03-09T16:06:13Z',
						updated_on: '2024-04-25T06:52:24.615829Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Contributor,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.SandboxSynergy,
					tags: azureRolesData.Contributor.tags,
				},
				{
					role_assignment: {
						id: generateUUID(),
						name: 'Owner Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/e2b45ca1-aaeb-4811-88ae-812481b38d79',
						role_definition_id: '8e3af657-a8ff-443c-a75c-2fe8c4bcb635',
						principal_id: 'b1664583-1c2e-4401-894e-b11fe0993e98',
						principal_type: 'ServicePrincipal',
						created_on: '2019-03-09T16:06:13Z',
						updated_on: '2024-04-25T06:52:24.615829Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.SandboxSynergy,
					tags: azureRolesData.Owner.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: getChangeLogs('qa-ci-deployer', 'b1664583-1c2e-4401-894e-b11fe0993e98'),
			usage_logs: [],
			kubernetes_resources_xc: [],
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.SandboxSynergy.subscription_id,
					account_name: azureSubscriptionData.SandboxSynergy.display_name,
				},
			],
		},
	},
	'90003': {
		id: '90003',
		literal: 'ff8595c0-1cd2-45f6-8b0a-8c2e1e2f2805',
		literal_friendly_name: 'lucasgrant',
		account_literal: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		account_literal_friendly_name: 'Synergy',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-04-08T12:26:19Z',
		tags: azureRolesData.Owner.tags,
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Hybrid,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: 'ff8595c0-1cd2-45f6-8b0a-8c2e1e2f2805',
			app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
			account_enabled: true,
			description: 'User-assigned service principal for Lucas Grant',
			display_name: 'lucasgrant',
			service_principal_type: 'Application',
			last_sign_in_date_time: '2024-04-08T12:26:19Z',
			entra_id_application_xc: {
				app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
				created_date_time: '2024-04-20T07:50:51Z',
				description: 'Application for production AKS',
				display_name: 'prod-aks-app',
				key_credentials: [
					{
						custom_key_identifier: 'Password uploaded on Tue Mar 12 2023',
						display_name: 'Password uploaded on Tue Mar 12 2023',
						key: null,
						key_id: '4a075312-b654-4d71-a2be-86acf8bfa8a4',
						start_date_time: '2023-09-12T00:00:00Z',
						end_date_time: '2025-09-12T00:00:00Z',
						type: 'AsymmetricX509Cert',
						usage: 'Verify',
						last_sign_in: null,
					},
				],
				password_credentials: [
					{
						customer_key_identifier: null,
						display_name: null,
						hint: null,
						key_id: 'key-1234-abcd-5678-efgh',
						secret_text: null,
						start_date_time: '2023-07-01T00:00:00Z',
						end_date_time: '2024-07-01T00:00:00Z',
						last_sign_in: null,
					},
				],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: generateUUID(),
						name: 'Owner Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/72b2299b-e5c3-4951-af7e-11f8dd618777',
						role_definition_id: '8e3af657-a8ff-443c-a75c-2fe8c4bcb635',
						principal_id: 'ff8595c0-1cd2-45f6-8b0a-8c2e1e2f2805',
						principal_type: 'ServicePrincipal',
						created_on: '2018-12-20T07:21:30Z',
						updated_on: '2024-01-18T07:03:32.834455Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Owner.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: getChangeLogs('lucasgrant', 'ff8595c0-1cd2-45f6-8b0a-8c2e1e2f2805'),
			kubernetes_resources_xc: kubernetesResourcesXc5,
			usage_logs: lucasUsageLogs,
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.Synergy.subscription_id,
					account_name: azureSubscriptionData.Synergy.display_name,
				},
			],
		},
	},
	'90004': {
		id: '90004',
		literal: generateUUID(),
		literal_friendly_name: 'synappid',
		account_literal: azureSubscriptionData.Synergy.subscription_id,
		account_literal_friendly_name: azureSubscriptionData.Synergy.display_name,
		created_at: '2015-05-14T19:32:45Z',
		updated_at: '2024-04-07T07:58:15.870478Z',
		last_activity: '2024-04-07T07:39:49Z',
		tags: [...azureRolesData.Owner.tags, ...azureRolesData.Contributor.tags],
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Machine,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: generateUUID(),
			app_id: generateUUID(),
			account_enabled: true,
			description: 'User-assigned service principal for IT operations',
			display_name: 'synappid',
			service_principal_type: 'Application',
			last_sign_in_date_time: '2024-04-07T07:39:49Z',
			entra_id_application_xc: {
				app_id: generateUUID(),
				created_date_time: '2015-05-14T19:32:45Z',
				description: 'Application for IT operations',
				display_name: 'it-app',
				key_credentials: [],
				password_credentials: [],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: generateUUID(),
						name: 'IT Ops Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: `/subscriptions/${azureSubscriptionData.Synergy.subscription_id}`,
						role_definition_id: azureRolesData.Owner.id,
						principal_id: generateUUID(),
						principal_type: 'ServicePrincipal',
						created_on: '2015-05-14T19:32:45Z',
						updated_on: '2024-04-07T07:58:15.870478Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Owner.tags,
				},
				{
					role_assignment: {
						id: generateUUID(),
						name: 'IT Ops Contributor Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: `/subscriptions/${azureSubscriptionData.Synergy.subscription_id}`,
						role_definition_id: azureRolesData.Contributor.id,
						principal_id: generateUUID(),
						principal_type: 'ServicePrincipal',
						created_on: '2015-05-14T19:32:45Z',
						updated_on: '2024-04-07T07:58:15.870478Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Contributor,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Contributor.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: getChangeLogs('synappid', 'synappid'),
			usage_logs: [],
			kubernetes_resources_xc: [],
			virtual_machines: azureVirtualMachinesData,
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.Synergy.subscription_id,
					account_name: azureSubscriptionData.Synergy.display_name,
				},
			],
		},
	},
	'10000': {
		id: '10000',
		literal: '010f64c0-6569-495b-8a11-fdff3e872ada',
		literal_friendly_name: 'skyhawk-security',
		account_literal: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		account_literal_friendly_name: 'Synergy',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-01-08T12:26:19Z',
		tags: azureRolesData.Owner.tags,
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Machine,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: '010f64c0-6569-495b-8a11-fdff3e872ada',
			app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
			account_enabled: true,
			description: 'System-assigned service principal for Skyhawk Security',
			display_name: 'skyhawk-security',
			service_principal_type: 'Application',
			last_sign_in_date_time: '2024-01-08T12:26:19Z',
			entra_id_application_xc: {
				app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
				created_date_time: '2018-12-20T07:21:30Z',
				description: 'Application for data collection',
				display_name: 'data-collector',
				key_credentials: [],
				password_credentials: [],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: '9f53f0ea-8145-4f85-ae6d-29d3a958aa07',
						name: 'Owner Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/72b2299b-e5c3-4951-af7e-11f8dd618777',
						role_definition_id: azureRolesData.Owner.id,
						principal_id: '28c74bc5-1c5c-4d20-ab6a-5c79e9bc901b',
						principal_type: 'ServicePrincipal',
						created_on: '2018-12-20T07:21:30Z',
						updated_on: '2024-01-18T07:03:32.834455Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Owner.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: [],
			kubernetes_resources_xc: [],
			usage_logs: [],
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.Synergy.subscription_id,
					account_name: azureSubscriptionData.Synergy.display_name,
				},
			],
		},
	},
	'10001': {
		id: '10001',
		literal: 'bcf28447-1ca3-4330-9766-c9675de0c1a9',
		literal_friendly_name: 'sailpoint-azure',
		account_literal: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		account_literal_friendly_name: 'Synergy',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-01-08T12:26:19Z',
		tags: azureRolesData.Owner.tags,
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Machine,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: 'bcf28447-1ca3-4330-9766-c9675de0c1a9',
			app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
			account_enabled: true,
			description: 'System-assigned service principal for Sailpoint Azure',
			display_name: 'sailpoint-azure',
			service_principal_type: 'Application',
			last_sign_in_date_time: '2024-01-08T12:26:19Z',
			entra_id_application_xc: {
				app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
				created_date_time: '2018-12-20T07:21:30Z',
				description: 'Application for data collection',
				display_name: 'data-collector',
				key_credentials: [],
				password_credentials: [],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: '9f53f0ea-8145-4f85-ae6d-29d3a958aa07',
						name: 'Owner Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/72b2299b-e5c3-4951-af7e-11f8dd618777',
						role_definition_id: azureRolesData.Owner.id,
						principal_id: '28c74bc5-1c5c-4d20-ab6a-5c79e9bc901b',
						principal_type: 'ServicePrincipal',
						created_on: '2018-12-20T07:21:30Z',
						updated_on: '2024-01-18T07:03:32.834455Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Owner.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: [],
			usage_logs: [],
			kubernetes_resources_xc: [],
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.Synergy.subscription_id,
					account_name: azureSubscriptionData.Synergy.display_name,
				},
			],
		},
	},
	'10002': {
		id: '10002',
		literal: '8272a968-ac49-418f-a7b8-b3b524424612',
		literal_friendly_name: 'zapier-azure',
		account_literal: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		account_literal_friendly_name: 'Synergy',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-01-08T12:26:19Z',
		tags: azureRolesData.Owner.tags,
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Machine,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: '8272a968-ac49-418f-a7b8-b3b524424612',
			app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
			account_enabled: true,
			description: 'System-assigned service principal for Zapier Azure',
			display_name: 'zapier-azure',
			service_principal_type: 'Application',
			last_sign_in_date_time: '2024-01-08T12:26:19Z',
			entra_id_application_xc: {
				app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
				created_date_time: '2018-12-20T07:21:30Z',
				description: 'Application for data collection',
				display_name: 'data-collector',
				key_credentials: [],
				password_credentials: [],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: '9f53f0ea-8145-4f85-ae6d-29d3a958aa07',
						name: 'Owner Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/72b2299b-e5c3-4951-af7e-11f8dd618777',
						role_definition_id: azureRolesData.Owner.id,
						principal_id: '28c74bc5-1c5c-4d20-ab6a-5c79e9bc901b',
						principal_type: 'ServicePrincipal',
						created_on: '2018-12-20T07:21:30Z',
						updated_on: '2024-01-18T07:03:32.834455Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Owner.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: [],
			usage_logs: [],
			kubernetes_resources_xc: [],
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.Synergy.subscription_id,
					account_name: azureSubscriptionData.Synergy.display_name,
				},
			],
		},
	},
	'10003': {
		id: '10003',
		literal: '31c5f35f-e722-4ea8-86bf-d29237ceee69',
		literal_friendly_name: 'zendesk-azure',
		account_literal: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		account_literal_friendly_name: 'Synergy',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-01-08T12:26:19Z',
		tags: azureRolesData.Owner.tags,
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Machine,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: '31c5f35f-e722-4ea8-86bf-d29237ceee69',
			app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
			account_enabled: true,
			description: 'System-assigned service principal for Zendesk Azure',
			display_name: 'zendesk-azure',
			service_principal_type: 'Application',
			last_sign_in_date_time: '2024-01-08T12:26:19Z',
			entra_id_application_xc: {
				app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
				created_date_time: '2018-12-20T07:21:30Z',
				description: 'Application for data collection',
				display_name: 'data-collector',
				key_credentials: [],
				password_credentials: [],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: '9f53f0ea-8145-4f85-ae6d-29d3a958aa07',
						name: 'Owner Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/72b2299b-e5c3-4951-af7e-11f8dd618777',
						role_definition_id: azureRolesData.Owner.id,
						principal_id: '28c74bc5-1c5c-4d20-ab6a-5c79e9bc901b',
						principal_type: 'ServicePrincipal',
						created_on: '2018-12-20T07:21:30Z',
						updated_on: '2024-01-18T07:03:32.834455Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Owner.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: [],
			usage_logs: [],
			kubernetes_resources_xc: [],
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.Synergy.subscription_id,
					account_name: azureSubscriptionData.Synergy.display_name,
				},
			],
		},
	},
	'10004': {
		id: '10004',
		literal: '992ccd6f-8add-4156-ad73-2f2621b16d77',
		literal_friendly_name: 'splunk-sp',
		account_literal: '72b2299b-e5c3-4951-af7e-11f8dd618777',
		account_literal_friendly_name: 'Synergy',
		created_at: '2018-12-20T07:21:30Z',
		updated_at: '2024-01-18T07:03:32.834455Z',
		last_activity: '2024-01-08T12:26:19Z',
		tags: azureRolesData.Owner.tags,
		mock_demo_tags: [MockDemoTag.IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR],
		env_type: EnvironmentType.ENTRA_ID,
		type: IdentityType.Machine,
		source: IdentitySource.ENTRA_ID_SERVICE_PRINCIPAL,
		entra_id_service_principal: {
			service_principal_id: '992ccd6f-8add-4156-ad73-2f2621b16d77',
			app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
			account_enabled: true,
			description: 'System-assigned service principal for Splunk',
			display_name: 'splunk-sp',
			service_principal_type: 'Application',
			last_sign_in_date_time: '2024-01-08T12:26:19Z',
			entra_id_application_xc: {
				app_id: '26caeb84-4126-4ba4-8f93-660e0249f6c6',
				created_date_time: '2018-12-20T07:21:30Z',
				description: 'Application for data collection',
				display_name: 'data-collector',
				key_credentials: [],
				password_credentials: [],
			},
			azure_role_assignments_xc: [
				{
					role_assignment: {
						id: '9f53f0ea-8145-4f85-ae6d-29d3a958aa07',
						name: 'Owner Assignment',
						type: 'Microsoft.Authorization/roleAssignments',
						scope: '/subscriptions/72b2299b-e5c3-4951-af7e-11f8dd618777',
						role_definition_id: azureRolesData.Owner.id,
						principal_id: '28c74bc5-1c5c-4d20-ab6a-5c79e9bc901b',
						principal_type: 'ServicePrincipal',
						created_on: '2018-12-20T07:21:30Z',
						updated_on: '2024-01-18T07:03:32.834455Z',
						created_by: '00000000-0000-0000-0000-000000000000',
						updated_by: '00000000-0000-0000-0000-000000000000',
					},
					role_definition: azureRolesData.Owner,
					scope_type: 'Subscription',
					subscription: azureSubscriptionData.Synergy,
					tags: azureRolesData.Owner.tags,
				},
			],
			entra_id_role_assignments: [],
			change_logs: [],
			usage_logs: [],
			kubernetes_resources_xc: [],
		},
		affected_environments: {
			AZURE: [
				{
					account_db_id: generateUUID(),
					account_id: azureSubscriptionData.Synergy.subscription_id,
					account_name: azureSubscriptionData.Synergy.display_name,
				},
			],
		},
	},
}
