import { DateSchema } from '../common.ts'
import { z } from 'zod'

export const ServerResolvedVsUnresolvedSchema = z.object({
	date: DateSchema,
	resolved: z.number(),
	unresolved: z.number(),
})

export type ServerResolvedVsUnresolved = z.infer<typeof ServerResolvedVsUnresolvedSchema>
