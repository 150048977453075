import React, { useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef } from '@ag-grid-community/core'
import { Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../utils'
import { DemoServerAdOwnership } from '../../schemas/identities/activeDirectory/demoAdOwnershipSchema.ts'

export const DemoAdOwnershipTable: React.FunctionComponent<{
	data: DemoServerAdOwnership[]
}> = ({ data }) => {
	const columnDefs = useMemo<ColDef<DemoServerAdOwnership>[]>(() => {
		return [
			{
				field: 'sam_account_name',
				headerName: 'Username',
				flex: 1,
			},
			{
				field: 'object_identifier',
				headerName: 'Object ID',
				flex: 1,
			},
			{
				field: 'domain',
				headerName: 'Domain',
				flex: 1,
			},
			{
				field: 'action',
				headerName: 'Action',
				flex: 1,
			},
			{
				field: 'timestamp',
				headerName: 'Timestamp',
				flex: 1,
				cellRenderer: (params: { value: string }) => (
					<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
						<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
					</Tooltip>
				),
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				rowData={data}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				enableCellTextSelection
				className="ag-theme-alpine w-full h-full overflow-x-auto"
				rowHeight={54}
			/>
		</div>
	)
}
