import { Skeleton } from 'antd'
import { Link, LinkProps } from '@tanstack/react-router'
import { getPriorityColor } from '../../tables/helpers.ts'
import React from 'react'
import { PriorityType } from '../../schemas/issue.ts'

interface EntitiesListProps<TEntity> {
	entities?: TEntity[]
	linkResolver: (entity: TEntity) => Pick<LinkProps, 'to' | 'search' | 'params'>
	children: (entity: TEntity) => React.ReactNode
	priorityTypeResolver: (entity: TEntity) => PriorityType | null
	keyResolver: (entity: TEntity) => string
	onClickEntity?: (entity: TEntity) => void
}

export const EntitiesList = <TEntity,>({
	entities,
	linkResolver,
	children,
	priorityTypeResolver,
	keyResolver,
	onClickEntity,
}: EntitiesListProps<TEntity>) => {
	return (
		<>
			{!entities && <Skeleton className="w-full h-full" active />}
			{entities?.length === 0 && <div>No data available</div>}
			{entities?.map((entity) => {
				return (
					<Link
						style={{
							boxShadow: `4px 0px 0px 0px ${getPriorityColor(priorityTypeResolver(entity))} inset`,
							borderRadius: '4px',
							border: '1px solid #E7E7EC',
						}}
						key={keyResolver(entity)}
						className="p-2 pl-4 flex flex-row space-x-4 bg-white justify-between"
						onClick={() => onClickEntity?.(entity)}
						{...linkResolver(entity)}
					>
						{children(entity)}
					</Link>
				)
			})}
		</>
	)
}
