import { useEffect, useState } from 'react'
import { Button, Input, notification, Skeleton } from 'antd'
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import { AccountConfigTable } from './AccountConfigTable.tsx'
import { useAccounts, useUpdateAccounts } from '../../../api/environments.ts'
import { Account } from '../../../schemas/environments/accounts.ts'
import { keyBy } from 'lodash'
import { AccountConfigConfirmationModal } from './AccountConfigConfirmationModal.tsx'
import { AccountPriorityEnum } from '../../../schemas/environments/accountPriorityEnum.ts'

export const AccountConfig = () => {
	const [searchText, setSearchText] = useState('')
	const [isEditing, setIsEditing] = useState(false)
	const [accounts, setAccounts] = useState<Account[]>([])
	const [changedAccountsIds, setChangedAccountsIds] = useState(new Set<string>())
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [notificationApi, contextHolder] = notification.useNotification()
	const {
		data: serverAccountList,
		isLoading: isLoadingAccountList,
		isError: isErrorAccountList,
		isFetching: isFetchingAccountList,
	} = useAccounts()
	const { mutate: mutateAccountConfig, isLoading: isUpdatingAccountList } = useUpdateAccounts()

	useEffect(() => {
		if (serverAccountList) {
			setAccounts(serverAccountList)
		}
	}, [serverAccountList])

	useEffect(() => {
		if (!serverAccountList) {
			return
		}

		const serverAccountsById = keyBy(serverAccountList, 'id')
		const newChangedAccountsIds = new Set<string>()
		accounts.forEach((account) => {
			if (account.priority !== serverAccountsById[account.id].priority) {
				newChangedAccountsIds.add(account.id)
			}
		})

		setChangedAccountsIds(newChangedAccountsIds)
	}, [accounts])

	if (isLoadingAccountList) {
		return <Skeleton active />
	}

	if (isErrorAccountList) {
		return <div className="flex justify-center items-center h-screen">Could not load data, please try again</div>
	}

	const onClickEditButton = () => {
		if (!isEditing) {
			setIsEditing(true)
		} else {
			setIsModalOpen(true)
		}
	}
	const onChangeAccountPriority = (accountToUpdate: Account, newPriority: AccountPriorityEnum) => {
		setAccounts((currentAccounts) =>
			currentAccounts.map((currentAccount) => {
				if (currentAccount.id === accountToUpdate.id) {
					return { ...currentAccount, priority: newPriority }
				}

				return currentAccount
			}),
		)
	}

	const onClickCancel = () => {
		setIsEditing(false)
		if (serverAccountList) {
			setAccounts(serverAccountList)
		}
	}

	const onSuccess = () => {
		notificationApi.success({ message: 'Successfully updated account configuration' })
		setIsModalOpen(false)
	}

	const onError = () => {
		notificationApi.error({
			message: 'Error',
			description: 'Could not update account configuration. Please try again.',
			duration: 0,
		})
		setIsModalOpen(false)
	}

	const onSaveConfirm = () => {
		void mutateAccountConfig(
			accounts.filter((account) => changedAccountsIds.has(account.id)),
			{
				onSuccess,
				onError,
			},
		)
	}

	return (
		<div className="flex flex-col gap-2 h-full">
			{contextHolder}
			<div className="flex justify-between items-center">
				<span className="text-lg text-neutral-800">Account Configuration</span>
				<div className="flex gap-2">
					<Input
						value={searchText}
						size="small"
						onChange={(e) => {
							setSearchText(e.target.value)
						}}
						allowClear={true}
						placeholder="Search"
						className="mr-4"
						prefix={<SearchOutlined />}
					/>
					{isEditing && (
						<Button onClick={onClickCancel} loading={isUpdatingAccountList || isFetchingAccountList}>
							Cancel
						</Button>
					)}
					<Button
						disabled={isEditing && !changedAccountsIds.size}
						onClick={onClickEditButton}
						loading={isUpdatingAccountList || isFetchingAccountList}
						type="primary"
						className="bg-black"
						icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
					>
						{isEditing ? 'Apply' : 'Edit'}
					</Button>
				</div>
			</div>
			<AccountConfigTable
				isEditing={isEditing}
				isFetching={isFetchingAccountList}
				accountList={accounts}
				searchText={searchText}
				onChangeAccountPriority={onChangeAccountPriority}
			/>
			<AccountConfigConfirmationModal
				isOpen={isModalOpen}
				accounts={accounts}
				changedAccountIds={changedAccountsIds}
				onCancel={() => setIsModalOpen(false)}
				isUpdating={isUpdatingAccountList}
				onConfirm={onSaveConfirm}
				serverAccounts={serverAccountList}
			/>
		</div>
	)
}
