import { ServerNotification } from '../schemas/notifications'
import { api } from './api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

const notificationsApi = {
	async getAll(): Promise<ServerNotification[]> {
		const { data: notifications } = await api.get<ServerNotification[]>('/api/notifications')
		return notifications
	},

	async updateNotifications(notifications: ServerNotification[]): Promise<void> {
		await api.put('/api/notifications', notifications)
	},
}

const notificationKeys = {
	all: () => ['notifications'] as const,
	updates: () => ['notifications', 'update'] as const,
}

export function useNotifications() {
	return useQuery({
		queryKey: notificationKeys.all(),
		queryFn: () => notificationsApi.getAll(),
		staleTime: Infinity,
		cacheTime: Infinity,
	})
}

export function useUpdateNotifications() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: notificationKeys.updates(),
		mutationFn: async (notifications: ServerNotification[]) =>
			await notificationsApi.updateNotifications(notifications),
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: notificationKeys.all(),
				refetchType: 'active',
			})
		},
	})
}
