import React from 'react'
import { Avatar, Image } from 'antd'
import { User } from '@frontegg/redux-store/auth/interfaces'
import { UserOutlined } from '@ant-design/icons'

export const UserLogo: React.FC<{ user: User | null | undefined }> = ({ user }) => {
	return (
		<div className="flex w-full items-center justify-center">
			<Avatar
				icon={
					user?.profilePictureUrl ? (
						<Image preview={false} src={user.profilePictureUrl} referrerPolicy="no-referrer" />
					) : (
						<UserOutlined />
					)
				}
				alt={user?.name}
			/>
		</div>
	)
}
