import { z } from 'zod'
import { IdentityType } from '../identity.ts'

export const ServerIdentitiesDistByPrioritySchema = z.object({
	identity_type: z.nativeEnum(IdentityType),
	critical: z.number().nullish(),
	high: z.number().nullish(),
	medium: z.number().nullish(),
	low: z.number().nullish(),
	not_calculated: z.number().nullish(),
})

export type ServerIdentitiesDistByPriority = z.infer<typeof ServerIdentitiesDistByPrioritySchema>

export const ServerIdentitiesDistByPriorityAndDateSchema = z.object({
	date: z.string(),
	critical: z.number(),
	high: z.number(),
	medium: z.number(),
	low: z.number(),
	not_calculated: z.number(),
})
export type ServerIdentitiesDistByPriorityAndDate = z.infer<typeof ServerIdentitiesDistByPriorityAndDateSchema>
