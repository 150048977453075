import { Tooltip } from 'antd'
import { ReactNode } from 'react'
import cx from 'classnames'
import { nodeColorClassNames, NodeColorTypes } from '../graphUtils/nodeColors.ts'
import { NodeBadge } from './NodeBadge.tsx'

type NodeGroupBadgeProps = {
	items: unknown[]
	nodeColorType: NodeColorTypes
	tooltipContent?: ReactNode
	positionOnLeft?: boolean
}

export const NodeGroupBadge = ({ items, nodeColorType, tooltipContent, positionOnLeft }: NodeGroupBadgeProps) => {
	const badgeNodeClassName = cx(
		'px-1',
		nodeColorClassNames[nodeColorType].badgeBg,
		nodeColorClassNames[nodeColorType].badgeBorder,
		nodeColorClassNames[nodeColorType].badgeText,
	)

	const badgeNode = (
		<NodeBadge className={badgeNodeClassName} positionOnLeft={positionOnLeft}>
			{items.length}
		</NodeBadge>
	)

	if (!tooltipContent) {
		return badgeNode
	}

	return (
		<Tooltip
			overlayClassName="w-fit"
			overlayInnerStyle={{ width: 'fit-content' }}
			title={<div className="flex flex-col gap-1 max-h-[40vh] overflow-y-auto">{tooltipContent}</div>}
		>
			{badgeNode}
		</Tooltip>
	)
}
