import { IdentitySource, IdentityType } from '../../schemas/identity.ts'
import { ServerAzureVirtualMachine } from '../../schemas/identities/azure/demoAzureVirtualMachine.ts'
import { kubernetesResourcesXc5 } from './crossContextData.ts'
import { awsPolicies, getTagsByPolicies } from './awsIamUserData.ts'
import { EnvironmentType } from '../../schemas/envType.ts'
import { MockDemoTag } from '../../schemas/tags.ts'
import { generateUUID } from '../utils.ts'

const synergyTenant = {
	tenant_id: '72b2299b-e5c3-4951-af7e-11f8dd618777',
	tenant_name: 'Synergy',
}

const prodSubscription = {
	subscription_id: '72b2299b-e5c3-4951-af7e-11f8dd618777',
	subscription_name: 'Prod',
}

const setChangeLogs = [
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		id: '2c4f1c35-4eb2-4473-a8a3-db169edf910b',
		event_time: '2022-02-20T07:50:51Z',
		created_at: '2022-02-20T07:50:51.546241Z',
		event_name: 'microsoft.directory/servicePrincipals/create',
		actor_arn:
			'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/brian.espinoza@synergy.onmicrosoft.com',
		affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
		source_ip_address: '207.232.13.65',
		user_agent: 'AWS Internal',
		request_parameters: {
			userName: 'production-eks-user',
		},
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		id: '78972903-598d-4f46-98c0-c1191fb161af',
		event_time: '2023-10-17T11:56:57Z',
		created_at: '2024-05-07T15:00:09.546241Z',
		event_name: 'microsoft.directory/servicePrincipals/authentication/update',
		actor_arn:
			'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/brian.espinoza@synergy.onmicrosoft.com',
		affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
		source_ip_address: '207.232.13.65',
		user_agent: 'AWS Internal',
		request_parameters: {
			userName: 'production-eks-user',
			policyArn: 'arn:aws:iam::710369796047:policy/production-eks-user-service-account-policy-for-pods',
		},
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		id: '04ac177d-a465-4053-a75d-928ff037652b',
		event_time: '2023-12-31T15:07:01Z',
		created_at: '2024-05-07T15:00:09.546241Z',
		event_name: 'Microsoft.Authorization/roleAssignments/write',
		actor_arn:
			'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.onmicrosoft.com',
		affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
		source_ip_address: '5.182.59.83',
		user_agent: 'AWS Internal',
		request_parameters: {
			status: 'Inactive',
			userName: 'production-eks-user',
			accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
		},
	},
	{
		customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
		environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
		sub_env_id: '7fd67a82-23a2-432b-8b48-d66315cb62c7',
		id: '98bd256f-7bf3-4b61-81b3-229c8dc24916',
		event_time: '2023-12-31T15:07:09Z',
		created_at: '2024-05-07T15:00:09.546241Z',
		event_name: 'Microsoft.Authorization/roleAssignments/delete',
		actor_arn:
			'arn:aws:sts::710369796047:assumed-role/AWSReservedSSO_AdministratorAccess_7ae7185c9a1e9e00/elizabeth.thomas@synergy.onmicrosoft.com',
		affected_arn: 'arn:aws:iam::710369796047:user/production-eks-user',
		source_ip_address: '5.182.59.83',
		user_agent: 'AWS Internal',
		request_parameters: {
			userName: 'production-eks-user',
			accessKeyId: 'AKIAUYYGLV4YIVHJSCD2',
		},
	},
]

export const azureVirtualMachinesData: ServerAzureVirtualMachine[] = [
	{
		name: 'ProdSQLServer01',
		status: 'Running',
		resource_group: 'ProdWFA',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '13.95.224.117',
	},
	{
		name: 'OLLS01',
		status: 'Running',
		resource_group: 'ProdWFA',
		location: 'East US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '40.112.72.205',
	},
	{
		name: 'lpesvr01',
		status: 'Stopped',
		resource_group: 'ProdWFA',
		location: 'West Europe',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: null,
	},
	{
		name: 'SVR01',
		status: 'Running',
		resource_group: 'ProdWFA',
		location: 'Central US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '13.89.183.35',
	},
	{
		name: 'SVR02',
		status: 'Running',
		resource_group: 'GAIC-RG',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '52.163.79.128',
	},
	{
		name: 'SVR03',
		status: 'Stopped',
		resource_group: 'datarg',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: null,
	},
	{
		name: 'CRPRJ01',
		status: 'Running',
		resource_group: 'datarg',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '191.233.85.76',
	},
	{
		name: 'CRPRJ02',
		status: 'Running',
		resource_group: 'datarg',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '52.237.193.99',
	},
	{
		name: 'CRPRJ03',
		status: 'Stopped',
		resource_group: 'datarg',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: null,
	},
	{
		name: 'TLCO-Linux-VM2',
		status: 'Running',
		resource_group: 'Prod1',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '104.211.231.76',
	},
	{
		name: 'TLCO-Linux-VM3',
		status: 'Running',
		resource_group: 'Prod1',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '51.140.123.26',
	},
	{
		name: 'TLCO-Linux-VM6',
		status: 'Stopped',
		resource_group: 'Prod1',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: null,
	},
	{
		name: 'TLCO-Linux-VM29',
		status: 'Running',
		resource_group: 'GAIC-RG',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '20.194.79.30',
	},
	{
		name: 'RnDStageWorkerRole_0',
		status: 'Running',
		resource_group: 'GAIC-RG',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '20.29.36.65',
	},
	{
		name: 'RnDStageWorkerRole_1',
		status: 'Stopped',
		resource_group: 'GAIC-RG',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: null,
	},
	{
		name: 'RnDStageWorkerRole_2',
		status: 'Running',
		resource_group: 'GAIC-RG',
		location: 'West US',
		tenant: {
			tenant_id: synergyTenant.tenant_id,
			tenant_name: synergyTenant.tenant_name,
		},
		subscription: {
			subscription_id: prodSubscription.subscription_id,
			subscription_name: prodSubscription.subscription_name,
		},
		public_ip: '20.97.78.55',
	},
]

export const azureIdentitiesData = {
	'90005': {
		id: '90005',
		literal: 'azure_analytics_role',
		literal_friendly_name: 'azure_analytics_role',
		created_at: '2015-05-14T19:32:45Z',
		updated_at: '2024-04-11T12:23:48.710533Z',
		last_activity: '2024-04-15T07:39:49Z',
		env_type: EnvironmentType.AZURE,
		type: IdentityType.Hybrid,
		source: IdentitySource.AZURE_POSTGRES_ROLE,
		tags: getTagsByPolicies([awsPolicies.AdministratorAccess]),
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		account_literal: synergyTenant.tenant_id,
		account_literal_friendly_name: synergyTenant.tenant_name,
		postgres_role_id: generateUUID(),
		postgres_role: {
			rds_instance: {
				host: 'prod-app-server.ctivvaejrlxg.postgres.database.azure.com',
				db_name: 'postgres',
				region: 'East US',
				instance_id:
					'/subscriptions/72b2299b-e5c3-4951-af7e-11f8dd618777/resourceGroups/datarg/providers/Microsoft.DBforPostgreSQL/servers/prod-app-server',
			},
			role_name: 'azure_analytics_role',
			is_role_superuser: false,
			can_role_create_role: false,
			can_role_create_db: false,
			can_role_login: true,
			can_role_bypass_rls: false,
			role_auto_inherits_roles: true,
			role_connections_limit: -1,
			subscription: {
				subscription_name: prodSubscription.subscription_name,
				subscription_id: prodSubscription.subscription_id,
			},
			change_logs: setChangeLogs,
			virtual_machines: azureVirtualMachinesData,
			kubernetes_resources_xc: kubernetesResourcesXc5,
		},
	},
}
