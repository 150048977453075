import cx from 'classnames'
import { BaseNode } from './BaseNode.tsx'
import { ComponentType, ReactNode } from 'react'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { NodeGroupBadge } from '../NodeGroupBadge.tsx'

type BaseResourcesNodeProps = {
	resources: unknown[]
	isHighlighted?: boolean
	nodeLabel: ReactNode
	tooltipTitle: string
	Icon: ComponentType
}

export const BaseDependenciesNode = ({
	resources,
	isHighlighted,
	nodeLabel,
	tooltipTitle,
	Icon,
}: BaseResourcesNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.dependencies.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl`,
		{
			[nodeColorClassNames.dependencies.bg]: !isHighlighted,
			[nodeColorClassNames.dependencies.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode label={nodeLabel}>
			<NodeGroupBadge items={resources} nodeColorType="dependencies" tooltipContent={tooltipTitle} />
			<div className={iconWrapperClassName}>
				<Icon />
			</div>
		</BaseNode>
	)
}
