import { OpenIssueStatuses, PriorityType, ServerIssue } from '../schemas/issue.ts'

export function getIssuesMaxPriority(issues?: ServerIssue[] | null): PriorityType {
	if (!issues || issues.length === 0) {
		return PriorityType.LOW
	}

	let maxPriority = PriorityType.NOT_CALCULATED
	issues.forEach((issue) => {
		if (issue.priority) {
			if (issue.priority > maxPriority) {
				maxPriority = issue.priority
			}
		}
	})

	return maxPriority
}

/** A sorter function that can be passed to `Array.sort`, sorts issues from high->low priority */
export function issuePrioritySorter(issueA: ServerIssue, issueB: ServerIssue): number {
	if (issueA.priority && issueB.priority) {
		return issueB.priority - issueA.priority
	} else if (!issueA.priority && issueB.priority) {
		return 1
	} else if (issueA.priority && !issueB.priority) {
		return -1
	} else {
		return 0
	}
}

export function filterOpenIssues(issues?: ServerIssue[] | null): ServerIssue[] {
	if (!issues?.length) {
		return []
	}

	return issues.filter((issue) => OpenIssueStatuses.includes(issue.status))
}

export function filterClosedIssues(issues?: ServerIssue[] | null): ServerIssue[] {
	if (!issues?.length) {
		return []
	}

	return issues.filter((issue) => !OpenIssueStatuses.includes(issue.status))
}
