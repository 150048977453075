import { Node, NodeProps } from '@xyflow/react'
import { AccountPriorityEnum } from '../../../../schemas/environments/accountPriorityEnum.ts'
import { BaseEnvironmentNode } from '../common/baseNodes/BaseEnvironmentNode.tsx'

export type AwsAccountType = { name?: string | null; priority?: AccountPriorityEnum | null; accountId: string }

export type AwsAccountNodeType = Node<{ awsAccount: AwsAccountType }, 'awsAccount'>
type AwsAccountNodeProps = NodeProps<AwsAccountNodeType>

export const AwsAccountNode = ({ data: { awsAccount } }: AwsAccountNodeProps) => {
	return (
		<BaseEnvironmentNode
			label="AWS Account"
			name={{ text: awsAccount.name || '', tooltipText: awsAccount.accountId }}
			priority={awsAccount.priority ?? undefined}
		/>
	)
}
