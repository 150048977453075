import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { type z } from 'zod'
import { BaseAdUserSchema } from '../../schemas/identities/activeDirectory/adUserSchema.ts'

type AdUser = z.infer<typeof BaseAdUserSchema>

const ADUsersTable: React.FC<{ data: AdUser[] }> = ({ data }) => {
	const gridRef = useRef<AgGridReact<AdUser>>(null)

	const onGridReady = useCallback((_params: GridReadyEvent<AdUser>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<AdUser>[]>(
		() => [
			{
				field: 'properties.display_name',
				headerName: 'Display Name',
				flex: 2,
			},
			{
				field: 'properties.sam_account_name',
				headerName: 'SAM Account Name',
				flex: 2,
			},
			{
				field: 'properties.email',
				headerName: 'Email',
				flex: 2,
			},
			{
				field: 'properties.enabled',
				headerName: 'Status',
				flex: 1,
				cellRenderer: (params: { value: boolean }) => (
					<div className={params.value ? 'text-green-600' : 'text-red-600'}>
						{params.value ? 'Enabled' : 'Disabled'}
					</div>
				),
			},
		],
		[],
	)

	const defaultColDef = useMemo<ColDef<AdUser>>(
		() => ({
			sortable: true,
			filter: true,
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
			},
			flex: 1,
		}),
		[],
	)

	return (
		<div className="w-full">
			<AgGridReact<AdUser>
				ref={gridRef}
				className="ag-theme-alpine"
				rowData={data}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				rowHeight={40}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}

export default ADUsersTable
