import React from 'react'
import { IssueNameTag } from '../../components/common/IssueNameTag.tsx'
import { EntitiesList } from './EntitiesList.tsx'
import { MetricsChartContainer } from './MetricsChartContainer.tsx'
import { useTopIssuesAndInsights } from '../../api/metrics.ts'
import { RoutePaths } from '../RoutePaths.tsx'
import { IssuesTabName } from '../../schemas/issue.ts'

export const TopFindings: React.FunctionComponent = () => {
	const { data: topIssuesAndInsights } = useTopIssuesAndInsights()
	return (
		<MetricsChartContainer className="col-span-3" header="Top Findings" childrenAreList>
			<EntitiesList
				entities={topIssuesAndInsights?.issues}
				linkResolver={(issue) => ({
					to: RoutePaths.Findings,
					search: { tabName: IssuesTabName.OPEN, issueName: issue.issue_name },
				})}
				priorityTypeResolver={(issue) => issue.max_priority}
				keyResolver={(issue) => issue.issue_name}
			>
				{(issue) => (
					<div className="flex w-full flex-row truncate justify-between">
						<IssueNameTag name={issue.issue_name} truncate />
						<div className="text-lg text-black flex items-center space-x-1">
							<div>{issue.count}</div>
						</div>
					</div>
				)}
			</EntitiesList>
		</MetricsChartContainer>
	)
}
