import { z } from 'zod'
import { ServerAwsEnvironmentSchema } from './awsEnvironment.ts'
import { ServerGcpEnvironmentSchema } from './gcpEnvironment.ts'
import { ServerOktaEnvironmentSchema } from './oktaEnvironment.ts'
import { ServerJumpcloudEnvironmentSchema } from './jumpcloudEnvironment.ts'
import { ServerEntraIdEnvironmentSchema } from './entraIdEnvironment.ts'
import { ServerGoogleWorkspaceEnvironmentSchema } from './googleWorkspaceEnvironment.ts'
import { ServerSalesforceEnvironmentSchema } from './salesforceEnvironment.ts'
import { EnvironmentType } from '../envType.ts'
import { ServerAzureDevopsEnvironmentSchema } from './azureDevopsEnvironment.ts'

const AzureDevOpsEnvironmentConfigSchema = z.object({
	organization_name: z.string(),
	log_analytics_workspace_id: z.string(),
	personal_access_token: z.string(),
})
const SalesforceEnvironmentConfigSchema = z.object({
	domain: z.string(),
	consumer_key: z.string(),
	consumer_secret: z.string(),
})

export const ServerEnvironmentsSchema = z.object({
	aws: z.array(ServerAwsEnvironmentSchema),
	gcp: z.array(ServerGcpEnvironmentSchema),
	okta: z.array(ServerOktaEnvironmentSchema),
	jumpcloud: z.array(ServerJumpcloudEnvironmentSchema),
	entra_id: z.array(ServerEntraIdEnvironmentSchema),
	azure_devops: z.array(ServerAzureDevopsEnvironmentSchema),
	salesforce: z.array(ServerSalesforceEnvironmentSchema),
	google_workspace: z.array(ServerGoogleWorkspaceEnvironmentSchema),
})

export type ServerEnvironments = z.infer<typeof ServerEnvironmentsSchema>

export const EnvironmentCreateRequestSchema = z.object({
	name: z.string(),
	environment_type: z.nativeEnum(EnvironmentType),
	config: z.union([AzureDevOpsEnvironmentConfigSchema, SalesforceEnvironmentConfigSchema]),
})

export type EnvironmentCreateRequest = z.infer<typeof EnvironmentCreateRequestSchema>

export const EnvironmentCreateResponseSchema = z.object({
	message: z.string(),
	environment_id: z.string(),
})

export type EnvironmentCreateResponse = z.infer<typeof EnvironmentCreateResponseSchema>
