import { Collapse } from 'antd'
import React from 'react'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { ResourceType } from '../../../../schemas/issue.ts'
import { ServerDatabricksRole } from '../../../../schemas/identities/databricks/ServerDatabricksRoleSchema.ts'
import { DatabricksRolesTable } from '../../../../tables/DatabricksRolesTable.tsx'

export const DatabricksRolesContext: React.FunctionComponent<{
	databricksRoles?: ServerDatabricksRole[] | null
}> = ({ databricksRoles }) => {
	return (
		<Collapse
			expandIconPosition={'end'}
			items={[
				{
					key: 'Roles',
					label: (
						<div className="flex items-center">
							<IssueSourceIcon source={ResourceType.DATABRICKS} />
							<div>Roles</div>
						</div>
					),
					children: <DatabricksRolesTable data={databricksRoles ?? []} />,
				},
			]}
		/>
	)
}
