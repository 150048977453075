import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import { Button, Modal } from 'antd'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket.ts'

export const JsonViewer: React.FunctionComponent<{
	data: object
	title: string
}> = ({ data, title }) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const showModal = () => {
		trackEvent(LogRocketTrackEvent.OpenJsonClicked, {})
		setIsModalOpen(true)
	}

	const handleOk = () => {
		trackEvent(LogRocketTrackEvent.OpenJsonClosed, {})
		setIsModalOpen(false)
	}

	const handleCancel = () => {
		trackEvent(LogRocketTrackEvent.OpenJsonClosed, {})
		setIsModalOpen(false)
	}
	return (
		<>
			<div className="underline cursor-pointer text-blue-600" onClick={showModal}>
				Open JSON
			</div>
			<Modal
				width={1024}
				title={title}
				open={isModalOpen}
				onCancel={handleCancel}
				onOk={handleOk}
				footer={[
					<Button key="done-button" className="bg-black text-white" onClick={handleCancel}>
						Done
					</Button>,
				]}
			>
				<div className="max-h-[500px] overflow-y-scroll text-xs">
					<ReactJson
						quotesOnKeys={false}
						src={data}
						style={{ backgroundColor: '#EDEDF2' }}
						enableClipboard={true}
						displayDataTypes={false}
						displayObjectSize={false}
						iconStyle="square"
						collapseStringsAfterLength={100}
					/>
				</div>
			</Modal>
		</>
	)
}
