import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState, ReactNode } from 'react'

import { IdentityGraphNodeType } from './IdentityGraph/identityGraphTypes.ts'

type HighlightedNode = { type: IdentityGraphNodeType['type']; id: string }

type HighlightedNodesContextValue = {
	highlightedNodes: HighlightedNode[]
	setHighlightedNodes: Dispatch<SetStateAction<HighlightedNode[]>>
}

const HighlightedNodesContext = createContext<HighlightedNodesContextValue | null>(null)

export const HighlightedNodesProvider = ({ children }: { children: ReactNode }) => {
	const [highlightedNodes, setHighlightedNodes] = useState<HighlightedNode[]>([])
	const value = useMemo(
		() => ({
			highlightedNodes,
			setHighlightedNodes,
		}),
		[highlightedNodes, setHighlightedNodes],
	)
	return <HighlightedNodesContext.Provider value={value}>{children}</HighlightedNodesContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export const useHighlightedNodesContext = () => {
	const contextValue = useContext(HighlightedNodesContext)
	if (!contextValue) {
		throw new Error(
			'Attempted to use useHighlightedNodesContext but no context value was found. You probably forgot to wrap your code with HighlightedNodesProvider',
		)
	}

	return contextValue
}
