import React from 'react'
import { Button, Tooltip } from 'antd'
import { CheckCircleOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { IntegrationStatus } from '../../types/integrationState.ts'

export interface IntegrationCardProps {
	logo: React.ComponentType<React.SVGProps<SVGSVGElement>>
	name: string
	description: string
	status: IntegrationStatus
	environmentCount: number
	onConnect: () => void
	onEdit: () => void
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
	logo: Logo,
	name,
	description,
	status,
	environmentCount,
	onConnect,
	onEdit,
}) => {
	return (
		<div className="w-[270px] h-[168px] p-4 bg-white rounded-lg border border-zinc-200 flex flex-col gap-4">
			<div className="flex justify-between items-center">
				<div className="flex items-center gap-2">
					<Logo className="w-6 h-6" />
					<span className="text-base font-medium text-gray-900">{name}</span>
				</div>
				<Tooltip title="If you wish to make changes, please contact the Token team.">
					<Button
						icon={<EditOutlined />}
						onClick={onEdit}
						className="text-gray-500 p-1"
						type="text"
						disabled={true}
					/>
				</Tooltip>
			</div>
			<div className="grow">
				{status === IntegrationStatus.ACTIVE ? (
					<div className="flex flex-col gap-1">
						<div className="flex justify-between text-xs text-gray-500">
							<span>Status</span>
							<span>Environments</span>
						</div>
						<div className="flex justify-between text-sm font-medium">
							<span className="font-medium text-green-700">Active</span>
							<span className="text-gray-900">{environmentCount}</span>
						</div>
					</div>
				) : (
					<p className="text-xs text-gray-500 line-clamp-3">{description}</p>
				)}
			</div>
			{status === IntegrationStatus.INACTIVE ? (
				<Button
					onClick={onConnect}
					className="w-full h-8 flex items-center justify-center gap-2 rounded bg-zinc-100 text-zinc-800 border border-zinc-100 text-sm font-medium"
					icon={<PlusOutlined />}
				>
					Connect
				</Button>
			) : (
				<div className="w-full h-8 flex items-center justify-center gap-2 rounded bg-green-100 text-green-500 text-sm font-medium">
					<CheckCircleOutlined /> Connected
				</div>
			)}
		</div>
	)
}
