import { Collapse } from 'antd'
import React from 'react'
import { ServerAzureVirtualMachine } from '../../../../../schemas/identities/azure/demoAzureVirtualMachine.ts'
import { IssueSourceIcon } from '../../../../common/IssueSourceIcon.tsx'
import { ResourceType } from '../../../../../schemas/issue.ts'
import { DemoAzureVirtualMachineTable } from './DemoAzureVirtualMachineInstance.tsx'

export const DemoAzureVirtualMachinesContext: React.FunctionComponent<{
	azureVirtualMachine: ServerAzureVirtualMachine[]
}> = ({ azureVirtualMachine }) => {
	return (
		<>
			<Collapse expandIconPosition={'end'}>
				<Collapse.Panel
					header={
						<div className="flex items-center">
							<IssueSourceIcon source={ResourceType.AZURE_VM} />
							<div>Virtual Machines</div>
						</div>
					}
					key="virtualMachines"
				>
					<DemoAzureVirtualMachineTable azureVirtualMachines={azureVirtualMachine} />
				</Collapse.Panel>
			</Collapse>
		</>
	)
}
