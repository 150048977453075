import { z } from 'zod'

export const ServerAwsIamChangeLogSchema = z.object({
	customer_id: z.string(),
	environment_id: z.string(),
	sub_env_id: z.string(),
	id: z.string(),
	event_time: z.string(),
	created_at: z.string(),
	event_name: z.string(),
	actor_arn: z.string(),
	affected_arn: z.string(),
	source_ip_address: z.string(),
	user_agent: z.string(),
	request_parameters: z.record(z.any()),
})

export type ServerAwsIamChangeLog = z.infer<typeof ServerAwsIamChangeLogSchema>

export interface AwsIamChangeLogsAggregated {
	actorArn: string
	firstEventTime: string
	lastEventTime: string
	eventNames: string[]
}
