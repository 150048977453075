import { EnvironmentType } from '../../../../schemas/envType'
import { ServerIdentity } from '../../../../schemas/identity'
import React, { useMemo } from 'react'
import { ServerSnowflakeUser } from '../../../../schemas/identities/snowflake/snowflakeUserSchema.ts'
import { BaseIdentityDetail } from '../baseIdentities/BaseIdentityDetail.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import SuccessIcon from '../../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../../assets/warning_icon_20.svg?react'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'
import { Col, Divider, Row, Space, Tooltip } from 'antd'
import { SnowflakeRolesContext } from './SnowflakeRolesContext.tsx'
import { OktaUserContext } from '../OktaUserContext.tsx'
import { EntraIdUserContext } from '../entraId/EntraIdUserContext.tsx'

export const SnowflakeUserIdentity: React.FunctionComponent<{
	identity?: ServerIdentity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const snowflakeUser: ServerSnowflakeUser | null = identity?.snowflake_user ?? null
	const contextAmount = useMemo<number>(() => {
		let count = 0
		if (snowflakeUser?.snowflake_roles_xc?.length ?? 0 > 0) count++
		if (snowflakeUser?.okta_user_xc) count++
		return count
	}, [snowflakeUser])

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Name" value={snowflakeUser?.name} span={1} />
				<BaseIdentityDetail label="Snowflake User ID" value={snowflakeUser?.user_id} span={1} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Environment" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.account_literal}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.account_literal_friendly_name ?? identity?.account_literal}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{snowflakeUser?.email && <BaseIdentityDetail label="Email" value={snowflakeUser?.email} span={1} />}

				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.last_activity, true)}
					tooltipTitle={formatDate(identity?.last_activity)}
					span={1}
				/>
				{snowflakeUser?.deleted_on && (
					<BaseIdentityDetail label="Deleted On" value={formatDate(snowflakeUser?.deleted_on)} span={1} />
				)}
				<BaseIdentityDetail label="Disabled" span={1}>
					{snowflakeUser?.disabled ? 'True' : 'False'}
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Password Enabled" span={1}>
					{snowflakeUser?.has_password ? 'True' : 'False'}
				</BaseIdentityDetail>
				{snowflakeUser?.has_password && (
					<BaseIdentityDetail
						label="Last Password changed"
						value={formatRelativeDateText(snowflakeUser?.password_last_set_time, true)}
						tooltipTitle={formatDate(snowflakeUser?.password_last_set_time)}
						span={1}
					/>
				)}
				{snowflakeUser?.has_password && (
					<BaseIdentityDetail label="MFA" span={1}>
						{snowflakeUser?.ext_authn_duo ? (
							<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						) : (
							<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						)}
					</BaseIdentityDetail>
				)}
				<BaseIdentityDetail label="RSA Public Key" span={1}>
					{snowflakeUser?.has_rsa_public_key ? 'True' : 'False'}
				</BaseIdentityDetail>
				{snowflakeUser?.deleted_on && (
					<BaseIdentityDetail
						label="Deleted On"
						value={formatRelativeDateText(snowflakeUser?.deleted_on, true)}
						tooltipTitle={formatDate(snowflakeUser?.deleted_on)}
						span={1}
					/>
				)}
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						{snowflakeUser?.snowflake_roles_xc && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<SnowflakeRolesContext snowflakeRoleDetailsXc={snowflakeUser?.snowflake_roles_xc} />
								</Space>
							</Row>
						)}
						{snowflakeUser?.okta_user_xc && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<OktaUserContext oktaUserXc={[snowflakeUser?.okta_user_xc]} />
								</Space>
							</Row>
						)}
						{snowflakeUser?.entra_id_user_xc && (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<EntraIdUserContext entraIdUserXc={[snowflakeUser?.entra_id_user_xc]} />
								</Space>
							</Row>
						)}
					</div>
				)}
			</div>
		</>
	)
}
