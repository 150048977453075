import { z } from 'zod'
import { PriorityTypeEnum } from '../issue.ts'
import { IdentitySource, IdentityType } from '../identity.ts'
import { EnvironmentType } from '../envType.ts'

export const ServerMostWantedIdentitySchema = z.object({
	name: z.string(),
	friendly_name: z.string().nullish(),
	account_literal_friendly_name: z.string().nullish(),
	account_literal: z.string(),
	env_type: z.nativeEnum(EnvironmentType),
	count: z.number(),
	max_priority: z.nativeEnum(PriorityTypeEnum),
	type: z.nativeEnum(IdentityType),
	source: z.nativeEnum(IdentitySource),
	id: z.string(),
})
export const ServerMostWantedIdentitiesSchema = z.array(ServerMostWantedIdentitySchema)

export type ServerMostWantedIdentities = z.infer<typeof ServerMostWantedIdentitiesSchema>
