import { z } from 'zod'
import { ServerAwsIdentityPolicySchema, ServerAwsTagSchema, ServerManagedPolicy } from './awsPoliciesSchema.ts'
import { DateSchema } from '../common.ts'
import { AccountPriorityEnum } from '../environments/accountPriorityEnum.ts'
import { ServerTag } from '../tags.ts'
import { ServerAwsUsageLogSchema } from './awsIamUserSchema.ts'

export const ServerAssumeRolePolicyDocumentSchema = z.object({
	Version: z.string(),
	Statement: z.array(
		z
			.object({
				Sid: z.string().optional(),
				Effect: z.string(),
				Principal: z.any(),
				Action: z.string().or(z.array(z.string())),
			})
			.passthrough(),
	),
})

export type ServerAssumeRolePolicyDocument = z.infer<typeof ServerAssumeRolePolicyDocumentSchema>

const ServerAwsRoleLastUsed = z.object({
	LastUsedDate: DateSchema.optional().nullish(),
	Region: z.string().optional().nullish(),
})

export const ServerAwsIamRoleXcSchema = z.object({
	Path: z.string(),
	RoleName: z.string(),
	RoleId: z.string(),
	Arn: z.string(),
	AccountName: z.string().nullish(),
	CreateDate: z.string(),
	AssumeRolePolicyDocument: ServerAssumeRolePolicyDocumentSchema.nullish(),
	RolePolicyList: z.array(ServerAwsIdentityPolicySchema),
	AttachedManagedPolicies: z.array(ServerManagedPolicy),
	InstanceProfileList: z.array(z.record(z.any())),
	RoleLastUsed: ServerAwsRoleLastUsed.nullish(),
	Tags: z.array(ServerAwsTagSchema),
	PermissionsBoundary: z.record(z.any()).nullish(),
	AccountPriority: z.nativeEnum(AccountPriorityEnum).nullish(),
	AccountId: z.string(),
	usageLogs: z.array(ServerAwsUsageLogSchema).nullish(),
})

export type ServerAwsIamRoleXc = z.infer<typeof ServerAwsIamRoleXcSchema>

export interface CombinedAwsIamRolePolicy {
	GroupName?: string | null
	IsAttached?: boolean | null
	IsPermissionBoundary?: boolean | null
	PolicyName?: string | null
	PolicyArn?: string | null
	PolicyDocument?: object | null
	PolicyInfo?: object | null
	CustomerManaged?: boolean | null
	AccountPriority?: AccountPriorityEnum | null
	RiskTags?: ServerTag[] | null
}
