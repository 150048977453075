import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'

import { BaseNode } from '../../common/baseNodes/BaseNode.tsx'
import { DetailedEntraIdRoleAssignment } from '../../../../../schemas/identities/entraId/entraIdRoleAssignmentsSchemas.ts'
import { RiskNodeTagIcons } from '../../common/RiskNodeTagIcons.tsx'

export type DetailedEntraIdRoleNodeType = Node<{ role: DetailedEntraIdRoleAssignment }, 'detailedEntraIdRole'>
type EntraIdRoleNodeProps = NodeProps<DetailedEntraIdRoleNodeType>

export const DetailedEntraIdRoleNode = ({ data: { role } }: EntraIdRoleNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)
	return (
		<BaseNode label="Entra ID Role" name={{ text: role.roleDefinition.displayName || 'Unknown Role' }}>
			<RiskNodeTagIcons tags={role.tags} />
			<div className={iconWrapperClassName}>
				<TeamOutlined />
			</div>
		</BaseNode>
	)
}
