import { ServerAwsIamGroupDetails, ServerAwsIamUserPermission } from '../schemas/identities/awsIamUserXcSchema.ts'
import { ServerAwsIamRoleXc, CombinedAwsIamRolePolicy } from '../schemas/identities/awsIamRoleXcSchema.ts'
import { AwsIamChangeLogsAggregated, ServerAwsIamChangeLog } from '../schemas/identities/awsIamChangeLogSchema.ts'
import groupBy from 'lodash/groupBy'
import { ServerAwsIdentityPolicy } from '../schemas/identities/awsPoliciesSchema.ts'

export const getCombinedAwsIamRolePolicy = (
	awsIamPermissionsXc?: ServerAwsIamUserPermission | null,
	awsIamRolesXc?: ServerAwsIamRoleXc | ServerAwsIamRoleXc[] | null,
	awsIamRolesPermissionBoundary?: ServerAwsIdentityPolicy | null,
	awsIamUserPermissionBoundary?: ServerAwsIdentityPolicy | null,
): CombinedAwsIamRolePolicy[] => {
	const policyList: CombinedAwsIamRolePolicy[] = []
	// Add user policies
	awsIamPermissionsXc?.AttachedManagedPolicies?.forEach((policy) => {
		policyList.push({ ...policy, IsAttached: true })
	})
	awsIamPermissionsXc?.UserPolicyList?.forEach((policy) => {
		policyList.push({ ...policy, IsAttached: false })
	})

	awsIamPermissionsXc?.GroupListPermissions?.forEach((groupPermission: ServerAwsIamGroupDetails) => {
		groupPermission?.GroupPolicyList.forEach((policy) => {
			policyList.push({ ...policy, IsAttached: false, GroupName: groupPermission.GroupName })
		})
		groupPermission?.AttachedManagedPolicies.forEach((policy) => {
			policyList.push({ ...policy, IsAttached: true, GroupName: groupPermission.GroupName })
		})
	})

	// Add role policies
	if (Array.isArray(awsIamRolesXc)) {
		awsIamRolesXc.forEach((role) => {
			role.AttachedManagedPolicies?.forEach((policy) => {
				policyList.push({ ...policy, IsAttached: true })
			})
		})
	} else {
		awsIamRolesXc?.AttachedManagedPolicies?.forEach((policy) => {
			policyList.push({ ...policy, IsAttached: true })
		})
	}

	if (Array.isArray(awsIamRolesXc)) {
		awsIamRolesXc.forEach((role) => {
			role.RolePolicyList?.forEach((policy) => {
				policyList.push({ ...policy, IsAttached: false })
			})
		})
	} else {
		awsIamRolesXc?.RolePolicyList?.forEach((policy) => {
			policyList.push({ ...policy, IsAttached: false })
		})
	}

	// Add permission boundary
	if (awsIamRolesPermissionBoundary) {
		policyList.push({ ...awsIamRolesPermissionBoundary, IsPermissionBoundary: true })
	}
	if (awsIamUserPermissionBoundary) {
		policyList.push({ ...awsIamUserPermissionBoundary, IsPermissionBoundary: true })
	}

	return policyList
}

export const getPolicyUniqueKey = (policy: CombinedAwsIamRolePolicy) =>
	`${policy.GroupName}-${policy.IsAttached}-${policy.PolicyName}-${policy.PolicyArn}-${policy.CustomerManaged}`

export const aggregateAwsIamChangeLogs = (changelog: ServerAwsIamChangeLog[]): AwsIamChangeLogsAggregated[] => {
	const changeLogsGrouped = groupBy<ServerAwsIamChangeLog>(changelog, 'actor_arn')
	const changeLogsAggregated = Object.entries(changeLogsGrouped).map(([actorArn, logs]) => {
		const logsSorted = logs.sort((a, b) => new Date(a.event_time).getTime() - new Date(b.event_time).getTime())
		return {
			actorArn,
			firstEventTime: logsSorted[0].event_time,
			lastEventTime: logsSorted[logsSorted.length - 1].event_time,
			eventNames: [...new Set(logs.map((log) => log.event_name))],
		}
	})

	return changeLogsAggregated.toSorted(
		(agg1, agg2) => new Date(agg1.lastEventTime).getTime() - new Date(agg2.lastEventTime).getTime(),
	)
}
