import { Collapse } from 'antd'
import { ContextPanelHeader } from './ContextPanelHeader'
import { IdentitySource } from '../../../schemas/identity'
import { ServerAwsIamUserPermission } from '../../../schemas/identities/awsIamUserXcSchema.ts'
import { IamUsersTable } from '../../../tables/IamUsersTable.tsx'

export const AwsIamUserContext: React.FunctionComponent<{ awsIamUsersXc?: ServerAwsIamUserPermission[] | null }> = ({
	awsIamUsersXc,
}) => {
	if (!awsIamUsersXc?.length) {
		return null
	}
	return (
		<Collapse expandIconPosition={'end'}>
			<Collapse.Panel
				header={<ContextPanelHeader source={IdentitySource.AWS_IAM_USER} />}
				key={IdentitySource.AWS_IAM_USER}
			>
				<IamUsersTable awsIamUsersXc={awsIamUsersXc} />
			</Collapse.Panel>
		</Collapse>
	)
}
