import { AgChartsReact } from 'ag-charts-react'
import { AgChartOptions, AgSeriesNodeClickEvent } from 'ag-charts-enterprise'
import { noDataOverlay, noDataOverlayComponent } from './helpers.tsx'
import { useCallback, useMemo } from 'react'
import { IssueName, IssueNameMap, IssuesTabName, PriorityType, PriorityTypeMap } from '../schemas/issue.ts'
import { getPriorityColor, getPriorityColorWithOpacity } from '../tables/helpers.ts'
import { useNavigate } from '@tanstack/react-router'
import { useFindingsDistByName } from '../api/metrics.ts'
import { Skeleton } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'
import { RoutePaths } from '../routes/RoutePaths.tsx'

interface FindingsPriority {
	label: string
	leafSize: number
	amount: number
	issueName: IssueName
	maxPriority: PriorityType
}
interface FindingsGroupedByPriority {
	label: string
	maxPriority: PriorityType
	children: FindingsPriority[]
}

export const FindingsDistributionTreeMapChart = () => {
	const navigate = useNavigate()
	const { data: findingsDistByName, isLoading } = useFindingsDistByName()
	const getLeafSizeFromAmount = useCallback((amount: number) => {
		if (amount < 10) return 0.5
		return Math.min(10, Math.floor(amount / 10))
	}, [])

	const normalizedData: FindingsGroupedByPriority[] = useMemo(() => {
		if (!findingsDistByName) return []
		const findings = findingsDistByName
			?.map((f) => ({
				label: IssueNameMap[f.issue_name],
				leafSize: getLeafSizeFromAmount(f.amount),
				amount: f.amount,
				issueName: f.issue_name,
				maxPriority: PriorityType[f.max_priority],
			}))
			.filter((f) => (f.maxPriority as number) > 0)

		const findingsGroupedByPriority: FindingsGroupedByPriority[] = []

		for (const finding of findings) {
			const maxPriorityExists = findingsGroupedByPriority.find((f) => f.maxPriority === finding.maxPriority)
			if (!maxPriorityExists) {
				findingsGroupedByPriority.push({
					label: PriorityTypeMap[finding.maxPriority],
					maxPriority: finding.maxPriority,
					children: [finding],
				})
			} else {
				maxPriorityExists.children.push(finding)
			}
		}
		return findingsGroupedByPriority
	}, [findingsDistByName])

	const colorRangeNormalized = useMemo(() => {
		if (!normalizedData) return []
		const colors: string[] = []
		const colorNums: number[] = []
		for (const f of normalizedData) {
			for (const c of f.children) {
				if ((c.maxPriority as number) === -1) continue
				const colorNumExists = colorNums.find((num) => num === (c.maxPriority as number))
				if (!colorNumExists) {
					colorNums.push(c.maxPriority)
				}
			}
		}
		for (const colorNum of colorNums.sort((a, b) => a - b)) {
			colors.push(getPriorityColorWithOpacity(colorNum))
		}
		return [...new Set(colors)]
	}, [normalizedData])

	if (isLoading) {
		return <Skeleton active />
	}

	const options: AgChartOptions = {
		data: normalizedData,
		background: {
			fill: themeColors.neutral[100],
		},
		listeners: {
			seriesNodeClick: (event: AgSeriesNodeClickEvent<{ issueName: IssueName }>) => {
				if (!event.datum.issueName) return
				void navigate({
					to: RoutePaths.Findings,
					search: { issueName: event.datum.issueName, tabName: IssuesTabName.OPEN },
				})
			},
		},
		series: [
			{
				type: 'treemap',
				labelKey: 'label',
				group: {
					label: {
						fontSize: 10,
					},
					padding: 4,
					gap: 2,
					fill: themeColors.neutral[100],
					stroke: themeColors.neutral[100],
				},
				tooltip: {
					renderer: (params) => {
						if (params.depth === 0) {
							const datum = params.datum as FindingsGroupedByPriority
							return {
								title: datum.label,
								content: '',
							}
						}
						const datum = params.datum as FindingsPriority

						return {
							title: datum.label,
							content: `Amount: ${datum.amount}<br>Priority: ${PriorityTypeMap[datum.maxPriority]}`,
							backgroundColor: getPriorityColor(datum.maxPriority),
						}
					},
				},
				secondaryLabelKey: 'originalAmount',
				sizeName: 'Amount',
				sizeKey: 'leafSize',
				colorName: 'Priority',
				colorKey: 'maxPriority',
				colorRange: colorRangeNormalized,
				tile: {
					strokeWidth: 1,
					stroke: '#E5E7EB',
					gap: 4,
					label: {
						fontSize: 24,
					},
					secondaryLabel: {
						formatter: (params) => {
							const datum = params.datum as FindingsPriority
							if (!datum || !datum.amount) {
								return 'No data'
							}
							return `${datum?.amount > 100 ? '100+' : datum.amount}`
						},
						fontSize: 16,
					},
				},
			},
		],
		gradientLegend: {
			enabled: false,
		},
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
	}

	return normalizedData ? (
		<AgChartsReact options={options} />
	) : (
		<div className="flex justify-center items-center h-full">{noDataOverlayComponent()}</div>
	)
}
