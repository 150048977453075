import { Collapse } from 'antd'
import React from 'react'
import KubernetesIcon from '../../../../assets/kubernetes_icon_20.svg?react'
import { KubernetesResourcesTable } from '../../../../tables/KubernetesResourcesTable.tsx'
import { ServerKubernetesResourceXc } from '../../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'

export const KubernetesResourceContext: React.FunctionComponent<{
	kubernetesResourcesXc: ServerKubernetesResourceXc[]
}> = ({ kubernetesResourcesXc }) => {
	return (
		<>
			<Collapse expandIconPosition={'end'}>
				<Collapse.Panel
					header={
						<div className="flex items-center space-x-2">
							<KubernetesIcon style={{ width: '35px', height: '35px' }} />
							<div>Kubernetes</div>
						</div>
					}
					key="kubernetesResources"
				>
					<KubernetesResourcesTable kubernetesResourcesXc={kubernetesResourcesXc} />
				</Collapse.Panel>
			</Collapse>
		</>
	)
}
