import React from 'react'
import TrashIcon from '../../../assets/trash_icon_16.svg?react'

export const IsDeletedBody: React.FunctionComponent = () => {
	return (
		<div className={'flex flex-row items-center content-center justify-center gap-1'}>
			<TrashIcon className="text-zinc-600" />
			Yes
		</div>
	)
}
