import { awsPolicies } from './awsIamUserData.ts'

export const awsIamRolesXcData = {
	'1': {
		Path: '/',
		RoleName: 'prodSuperUser',
		RoleId: 'AROAZKR1GQ5RGR8LR84V',
		Arn: 'arn:aws:iam::871744574088:role/prodSuperUser',
		CreateDate: '2015-05-14T19:32:45Z',
		AssumeRolePolicyDocument: {
			Version: '2012-10-17',
			Statement: [
				{
					Sid: '',
					Action: 'sts:AssumeRole',
					Effect: 'Allow',
					Principal: {
						Service: 'ec2.amazonaws.com',
					},
				},
			],
		},
		RolePolicyList: [],
		AttachedManagedPolicies: [awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess],
		InstanceProfileList: [
			{
				Path: '/',
				InstanceProfileName: 'prodSuperUser',
				InstanceProfileId: 'AIPAKSVTQSEFQP02LHR9',
				Arn: 'arn:aws:iam::871744574088:instance-profile/prodSuperUser',
				CreateDate: '2015-05-14T19:32:45Z',
				Roles: [
					{
						Path: '/',
						RoleName: 'prodSuperUser',
						RoleId: 'AROAZKR1GQ5RGR8LR84V',
						Arn: 'arn:aws:iam::871744574088:role/prodSuperUser',
						CreateDate: '2015-05-14T19:32:45Z',
						AssumeRolePolicyDocument: {
							Version: '2012-10-17',
							Statement: [
								{
									Sid: '',
									Action: 'sts:AssumeRole',
									Effect: 'Allow',
									Principal: {
										Service: 'ec2.amazonaws.com',
									},
								},
							],
						},
						RolePolicyList: [],
						AttachedManagedPolicies: [],
						InstanceProfileList: [],
						RoleLastUsed: null,
						Tags: [],
						PermissionsBoundary: null,
						AccountName: null,
					},
				],
			},
		],
		RoleLastUsed: {
			LastUsedDate: '2024-04-07T07:39:49Z',
			Region: 'eu-west-1',
		},
		Tags: [],
		PermissionsBoundary: null,
		AccountName: 'production',
		AccountId: '871744574088',
		AccountPriority: 5,
	},
	'2': {
		Path: '/',
		RoleName: 'prodSuperUser',
		RoleId: 'AROAZKR1GQ5RGR8LR84V',
		Arn: 'arn:aws:iam::871744574088:role/prodSuperUser',
		CreateDate: '2015-05-14T19:32:45Z',
		AssumeRolePolicyDocument: {
			Version: '2012-10-17',
			Statement: [
				{
					Sid: '',
					Action: 'sts:AssumeRole',
					Effect: 'Allow',
					Principal: {
						Service: 'ec2.amazonaws.com',
					},
				},
			],
		},
		RolePolicyList: [],
		AttachedManagedPolicies: [awsPolicies.IAMFullAccess],
		InstanceProfileList: [
			{
				Path: '/',
				InstanceProfileName: 'prodSuperUser',
				InstanceProfileId: 'AIPAKSVTQSEFQP02LHR9',
				Arn: 'arn:aws:iam::871744574088:instance-profile/prodSuperUser',
				CreateDate: '2015-05-14T19:32:45Z',
				Roles: [
					{
						Path: '/',
						RoleName: 'prodSuperUser',
						RoleId: 'AROAZKR1GQ5RGR8LR84V',
						Arn: 'arn:aws:iam::871744574088:role/prodSuperUser',
						CreateDate: '2015-05-14T19:32:45Z',
						AssumeRolePolicyDocument: {
							Version: '2012-10-17',
							Statement: [
								{
									Sid: '',
									Action: 'sts:AssumeRole',
									Effect: 'Allow',
									Principal: {
										Service: 'ec2.amazonaws.com',
									},
								},
							],
						},
						RolePolicyList: [],
						AttachedManagedPolicies: [],
						InstanceProfileList: [],
						RoleLastUsed: null,
						Tags: [],
						PermissionsBoundary: null,
						AccountName: null,
					},
				],
			},
		],
		RoleLastUsed: {
			LastUsedDate: '2024-04-07T07:39:49Z',
			Region: 'eu-west-1',
		},
		Tags: [],
		PermissionsBoundary: null,
		AccountName: 'production',
		AccountId: '871744574088',
		AccountPriority: 5,
	},
	'3': {
		Path: '/',
		RoleName: 'prodSuperUser',
		RoleId: 'AROAZKR1GQ5RGR8LR84V',
		Arn: 'arn:aws:iam::871744574088:role/prodSuperUser',
		CreateDate: '2015-05-14T19:32:45Z',
		AssumeRolePolicyDocument: {
			Version: '2012-10-17',
			Statement: [
				{
					Sid: '',
					Action: 'sts:AssumeRole',
					Effect: 'Allow',
					Principal: { Service: 'ec2.amazonaws.com' },
				},
			],
		},
		RolePolicyList: [],
		AttachedManagedPolicies: [awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess],
		InstanceProfileList: [
			{
				Path: '/',
				InstanceProfileName: 'prodSuperUser',
				InstanceProfileId: 'AIPAKSVTQSEFQP02LHR9',
				Arn: 'arn:aws:iam::871744574088:instance-profile/prodSuperUser',
				CreateDate: '2015-05-14T19:32:45Z',
				Roles: [
					{
						Path: '/',
						RoleName: 'prodSuperUser',
						RoleId: 'AROAZKR1GQ5RGR8LR84V',
						Arn: 'arn:aws:iam::871744574088:role/prodSuperUser',
						CreateDate: '2015-05-14T19:32:45Z',
						AssumeRolePolicyDocument: {
							Version: '2012-10-17',
							Statement: [
								{
									Sid: '',
									Action: 'sts:AssumeRole',
									Effect: 'Allow',
									Principal: { Service: 'ec2.amazonaws.com' },
								},
							],
						},
						RolePolicyList: [],
						AttachedManagedPolicies: [],
						InstanceProfileList: [],
						RoleLastUsed: null,
						Tags: [],
						PermissionsBoundary: null,
						AccountName: null,
					},
				],
			},
		],
		RoleLastUsed: { LastUsedDate: '2024-04-07T07:39:49Z', Region: 'eu-west-1' },
		Tags: [],
		PermissionsBoundary: null,
		AccountName: 'production',
		AccountId: '871744574088',
		AccountPriority: 5,
	},
	'4': {
		Path: '/',
		RoleName: 'prodSuperUser',
		RoleId: 'AROAZKR1GQ5RGR8LR84V',
		Arn: 'arn:aws:iam::871744574088:role/prodSuperUser',
		AccountName: 'production',
		CreateDate: '2015-05-14T19:32:45Z',
		AssumeRolePolicyDocument: {
			Version: '2012-10-17',
			Statement: [
				{
					Sid: '',
					Action: 'sts:AssumeRole',
					Effect: 'Allow',
					Principal: { Service: 'ec2.amazonaws.com' },
				},
			],
		},
		RolePolicyList: [],
		AttachedManagedPolicies: [awsPolicies.PowerUserAccess, awsPolicies.IAMFullAccess],
		InstanceProfileList: [
			{
				Path: '/',
				InstanceProfileName: 'prodSuperUser',
				InstanceProfileId: 'AIPAKSVTQSEFQP02LHR9',
				Arn: 'arn:aws:iam::871744574088:instance-profile/prodSuperUser',
				CreateDate: '2015-05-14T19:32:45Z',
				Roles: [
					{
						Path: '/',
						RoleName: 'prodSuperUser',
						RoleId: 'AROAZKR1GQ5RGR8LR84V',
						Arn: 'arn:aws:iam::871744574088:role/prodSuperUser',
						CreateDate: '2015-05-14T19:32:45Z',
						AssumeRolePolicyDocument: {
							Version: '2012-10-17',
							Statement: [
								{
									Sid: '',
									Action: 'sts:AssumeRole',
									Effect: 'Allow',
									Principal: { Service: 'ec2.amazonaws.com' },
								},
							],
						},
						RolePolicyList: [],
						AttachedManagedPolicies: [],
						InstanceProfileList: [],
						RoleLastUsed: null,
						Tags: [],
						PermissionsBoundary: null,
						AccountName: null,
					},
				],
			},
		],
		RoleLastUsed: { lastUsedDate: '2024-04-07T07:39:49.000Z', region: 'eu-west-1' },
		Tags: [],
		PermissionsBoundary: null,
		AccountId: '871744574088',
		AccountPriority: 5,
	},
}
