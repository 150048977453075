import { NodeProps, Node } from '@xyflow/react'
import { CloudServerOutlined } from '@ant-design/icons'
import { ServerAwsEc2InstanceXc } from '../../../../schemas/identities/awsEc2InstanceXcSchema.ts'
import { BaseDependenciesNode } from '../common/baseNodes/BaseDependenciesNode.tsx'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'

export type Ec2InstancesNodeType = Node<{ instances: ServerAwsEc2InstanceXc[] }, 'ec2Instances'>
type Ec2InstancesNodeProps = NodeProps<Ec2InstancesNodeType>

export const Ec2InstancesNode = ({ data: { instances } }: Ec2InstancesNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'ec2Instances')
	return (
		<BaseDependenciesNode
			resources={instances}
			isHighlighted={isHighlighted}
			nodeLabel="EC2 Instances"
			tooltipTitle={`Identity is used in ${instances.length} EC2 ${
				instances.length === 1 ? 'instance' : 'instances'
			}`}
			Icon={CloudServerOutlined}
		/>
	)
}
