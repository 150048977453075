import { Node, NodeProps } from '@xyflow/react'
import { ServerAzureSubscription } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { BaseEnvironmentNode } from '../../common/baseNodes/BaseEnvironmentNode.tsx'

export type AzureSubscriptionNodeType = Node<{ subscription: ServerAzureSubscription }, 'azureSubscription'>
type AzureSubscriptionNodeProps = NodeProps<AzureSubscriptionNodeType>

export const AzureSubscriptionNode = ({ data: { subscription } }: AzureSubscriptionNodeProps) => {
	const subscriptionName = subscription.display_name || 'Unnamed Subscription'

	return (
		<BaseEnvironmentNode
			label="Azure Subscription"
			name={{ text: subscriptionName }}
			tooltipTitle={`ID: ${subscription.subscription_id || 'N/A'}`}
			priority={subscription.priority ?? undefined}
		/>
	)
}
