import { z } from 'zod'
import { ServerEntraIdApplicationXcSchema } from './entraIdApplicationXc'
import { ServerDetailedAzureRoleAssignmentSchema } from './azureRoleAssignmentsXc'
import { ServerEntraIdChangeLogSchema } from './entraIdChangeLogSchema'
import { DetailedEntraIdRoleAssignmentSchema } from './entraIdRoleAssignmentsSchemas'
import { ServerKubernetesResourceXcSchema } from '../kubernetes/kubernetesResourceXcSchema'
import { ServerAzureVirtualMachineSchema } from '../azure/demoAzureVirtualMachine'
import { ServerAzureUsageLogSchema } from './azureUsageLogSchema'
import { BaseAzureDevopsServicePrincipalSchema } from '../azure/azureDevops/azureDevopsServicePrincipalSchema'

export const ServicePrincipalTypeEnum = z.enum(['Application', 'ManagedIdentity', 'Legacy'])
export type ServicePrincipalType = z.infer<typeof ServicePrincipalTypeEnum>

export const BaseEntraIdServicePrincipalSchema = z.object({
	service_principal_id: z.string(),
	app_id: z.string(),
	account_enabled: z.boolean().nullish(),
	description: z.string().nullish(),
	display_name: z.string().nullish(),
	service_principal_type: ServicePrincipalTypeEnum.nullish(),
	last_sign_in_date_time: z.string().nullish(),
})

export const ServerEntraIdServicePrincipalSchema = BaseEntraIdServicePrincipalSchema.extend({
	entra_id_application_xc: ServerEntraIdApplicationXcSchema.nullish(),
	azure_role_assignments_xc: z.array(ServerDetailedAzureRoleAssignmentSchema).nullish(),
	entra_id_role_assignments: z.array(DetailedEntraIdRoleAssignmentSchema).nullish(),
	change_logs: z.array(ServerEntraIdChangeLogSchema),
	usage_logs: z.array(ServerAzureUsageLogSchema),
	kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(),
	virtual_machines: z.array(ServerAzureVirtualMachineSchema).nullish(),
	azure_devops_service_principal: z.lazy(() => BaseAzureDevopsServicePrincipalSchema.nullish()),
}).nullish()

export type ServerEntraIdServicePrincipal = z.infer<typeof ServerEntraIdServicePrincipalSchema>
