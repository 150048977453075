import { uniq } from 'lodash'
import { Tag } from '../../schemas/tags.ts'
import { IdentityTagIcon } from './IdentityTag/IdentityTagIcon.tsx'

export const TagIcons = ({ tags }: { tags?: string[] | null }) => {
	if (!tags?.length) {
		return null
	}
	const uniqueTags = uniq(tags)

	return (
		<div className="flex items-center gap-1">
			{uniqueTags.sort().map((tag) => (
				<div key={tag}>
					<IdentityTagIcon name={tag as Tag} size={24} />
				</div>
			))}
		</div>
	)
}
