import { useIdentityStats } from '../../../api/identities.ts'
import { IdentityType, IdentityTypeColorMap, IdentityTypeIconMap, IdentityTypeMap } from '../../../schemas/identity.ts'
import { Tooltip } from 'antd'
import { useCallback, useMemo } from 'react'
import { DonutChartWithCustomLegend } from '../../../components/common/DonutChartWithCustomLegend.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { isEmpty } from 'lodash'
import { TableSetFilterEntry } from '../../../schemas/tableFilter.ts'
import { IdentitiesStatsCount } from './IdentitiesStatsCount.tsx'
import { IdentitiesStatsItem } from './IdentitiesStatsItem.tsx'

const seriesColorResolver = (type: IdentityType): string => IdentityTypeColorMap[type]
const seriesTooltipTitleResolver = (type: IdentityType): string => `${IdentityTypeMap[type]} identities`
const legendTitleResolver = (type: IdentityType): React.ReactNode => {
	const Icon = IdentityTypeIconMap[type]
	return (
		<Tooltip title={seriesTooltipTitleResolver(type)}>
			<span>
				<Icon className="text-base h-5 w-5 flex justify-center" />
			</span>
		</Tooltip>
	)
}

type IdentitiesStatsMonitoredIdentitiesProps = {
	statsQuery: ReturnType<typeof useIdentityStats>
}

export const IdentitiesStatsMonitoredIdentities = ({ statsQuery }: IdentitiesStatsMonitoredIdentitiesProps) => {
	const { identityFilter } = useSearch({ from: RoutePaths.Identities })
	const { data: identityStats } = statsQuery

	const navigate = useNavigate({ from: RoutePaths.Identities })

	const activeTypes: IdentityType[] = useMemo(() => {
		if (!identityFilter?.type) {
			return []
		}

		return (identityFilter.type as TableSetFilterEntry).values as IdentityType[]
	}, [identityFilter])

	const data = useMemo(() => {
		if (!identityStats?.count_by_type) {
			return []
		}

		return Object.values(IdentityType).map((identityType) => ({
			key: identityType,
			count: identityStats.count_by_type[identityType] ?? 0,
		}))
	}, [identityStats])

	const setActiveTypes = useCallback(
		(activeTypesParam: (currentActiveTypes: IdentityType[]) => IdentityType[]) => {
			const newActiveTypes = activeTypesParam(activeTypes)
			void navigate({
				search: (prevSearch) => {
					const newIdentityFilter = { ...prevSearch.identityFilter }
					if (isEmpty(newActiveTypes)) {
						delete newIdentityFilter.type
					} else {
						newIdentityFilter.type = { filterType: 'set', values: newActiveTypes }
					}

					const newSearch = { ...prevSearch }
					if (isEmpty(newIdentityFilter)) {
						delete newSearch.identityFilter
					} else {
						newSearch.identityFilter = newIdentityFilter
					}

					return newSearch
				},
			})
		},
		[activeTypes, navigate],
	)

	const totalCount = useMemo(() => {
		if (!identityStats) {
			return 0
		}

		return identityStats.total_count
	}, [identityStats])

	return (
		<IdentitiesStatsItem title="Monitored Identities" statsQuery={statsQuery}>
			<IdentitiesStatsCount count={totalCount} />
			<div className="flex items-center gap-2">
				<DonutChartWithCustomLegend
					activeKeys={activeTypes}
					data={data}
					legendTitleResolver={legendTitleResolver}
					seriesColorResolver={seriesColorResolver}
					seriesTooltipTitleResolver={seriesTooltipTitleResolver}
					setActiveKeys={setActiveTypes}
					dimensions={80}
					legendBorders
				/>
			</div>
		</IdentitiesStatsItem>
	)
}
