import { Collapse } from 'antd'
import React from 'react'
import { ServerAwsIamChangeLog } from '../../../schemas/identities/awsIamChangeLogSchema.ts'
import UserIcon from '../../../assets/user_icon_20.svg?react'
import { AwsIamUserChangeLogsContent } from './AwsIamUserChangeLogsContent.tsx'

export const AwsIamUserChangeLogsContext: React.FunctionComponent<{
	environmentId: string
	subEnvironmentId: string
	identityArn: string
	changeLogs: ServerAwsIamChangeLog[]
}> = ({ changeLogs, environmentId, subEnvironmentId, identityArn }) => (
	<Collapse
		expandIconPosition={'end'}
		items={[
			{
				key: 'AWS IAM user change logs',
				label: (
					<div className="flex items-center">
						<UserIcon className="h-7 mr-2" />
						Ownership - Creator and Maintainers
					</div>
				),
				children: (
					<AwsIamUserChangeLogsContent
						changeLogs={changeLogs}
						environmentId={environmentId}
						identityArn={identityArn}
						subEnvironmentId={subEnvironmentId}
					/>
				),
			},
		]}
	/>
)
