import { formatDistanceStrict, formatDistanceToNowStrict, isToday, isYesterday, startOfDay } from 'date-fns'
import { User } from '@frontegg/redux-store/auth/interfaces'

const TOKEN_EMAIL_DOMAIN: string = 'token.security'

export const formatDate = (date?: Date | string | null | undefined, showTime: boolean = true) => {
	if (!date) return 'N/A'
	const asDate = new Date(date)
	const day = asDate.getDate().toString().padStart(2, '0')
	const month = (asDate.getMonth() + 1).toString().padStart(2, '0') // Months are zero-indexed
	const year = asDate.getFullYear()
	const hours = asDate.getHours().toString().padStart(2, '0')
	const minutes = asDate.getMinutes().toString().padStart(2, '0')

	return `${year}.${month}.${day}${showTime ? ` | ${hours}:${minutes}` : ''}`
}

export const formatRelativeDateText = (date?: Date | string | null | undefined, withSuffix?: boolean): string => {
	if (!date) return 'N/A'
	const asDate: Date = new Date(date)
	return formatDistanceToNowStrict(asDate, { addSuffix: withSuffix }) // suffix adds 'ago' or 'in' to dates before or after NOW, respectively
}

export const formatDaysAgo = (date?: Date | string | null | undefined): string => {
	if (!date) return 'N/A'
	const asDate: Date = new Date(date)
	if (isToday(asDate)) {
		return 'Today'
	}

	if (isYesterday(asDate)) {
		return 'Yesterday'
	}

	return formatDistanceStrict(startOfDay(asDate), startOfDay(new Date()), {
		addSuffix: true,
		unit: 'day',
	})
}

const parseISODuration = (duration: string) => {
	const regex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?/
	const matches = duration.match(regex)
	if (!matches) return { weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0 }

	const years = parseInt(matches[1]) || 0
	const months = parseInt(matches[2]) || 0
	const days = parseInt(matches[3]) || 0
	const hours = parseInt(matches[4]) || 0
	const minutes = parseInt(matches[5]) || 0
	const seconds = parseFloat(matches[6]) || 0

	// Convert to a more human-readable format
	const totalDays = days + years * 365 + months * 30
	const weeks = Math.floor(totalDays / 7)
	const remainingDays = totalDays % 7
	return { weeks, days: remainingDays, hours, minutes, seconds }
}

export const formatDuration = (duration?: string | null) => {
	if (!duration) return 'N/A'
	const { weeks, days, hours } = parseISODuration(duration)
	if (weeks) return `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`
	if (days) return `${days} ${days === 1 ? 'day' : 'days'}`
	if (hours) return `${hours} ${hours === 1 ? 'hour' : 'hours'}`

	return '< 1 hour'
}

export function removeNulls<T>(obj: T): T {
	if (typeof obj !== 'object' || obj === null) {
		// Directly return the value if it's not an object or is null
		return obj
	}

	// Process arrays and objects separately to maintain type safety
	if (Array.isArray(obj)) {
		return obj
			.filter((item) => item !== null) // Remove null items from the array
			.map((item: unknown) => removeNulls(item)) as T // Recurse for each item, cast back to T
	}

	const result: T = Object.entries(obj).reduce((acc, [key, value]) => {
		if (value !== null) {
			// Check if the value is an object for recursive call, else assign directly
			// eslint-disable-next-line no-extra-semi
			;(acc as Record<string, unknown>)[key] = typeof value === 'object' ? removeNulls(value) : value
		}
		return acc
	}, {} as T)

	return result
}

// both decode and encode are taken from here:
// https://tanstack.com/router/v1/docs/framework/react/guide/custom-search-param-serialization#using-base64
export function decodeFromBinary(str: string): string {
	if (!str) return ''
	return decodeURIComponent(
		Array.prototype.map
			.call(atob(str), function (c: string) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join(''),
	)
}

export function encodeToBinary(str: string): string {
	if (!str) return ''
	return btoa(
		encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_match: string, p1: string) {
			return String.fromCharCode(parseInt(p1, 16))
		}),
	)
}

export function capitalizeFirstLetter(str?: string | null): string {
	if (!str) return ''
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export function isUserFromTokenDomain(user: User): boolean {
	return user.email.endsWith(TOKEN_EMAIL_DOMAIN)
}

export function getCsvFileName(resource: string, withTime: boolean = true): string {
	const date = new Date()

	const day = date.getDate().toString().padStart(2, '0')
	const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-indexed
	const year = date.getFullYear()

	let formattedDate: string = `${day}.${month}.${year}`
	if (withTime) {
		const hours = date.getHours().toString().padStart(2, '0')
		const minutes = date.getMinutes().toString().padStart(2, '0')
		const seconds = date.getSeconds().toString().padStart(2, '0')
		formattedDate += `-${hours}.${minutes}.${seconds}`
	}
	return `token-${resource}_${formattedDate}.csv`
}
