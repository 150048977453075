import { useCallback, useEffect, useState } from 'react'
import { RowEvent } from '@ag-grid-community/core'
import { CustomCellRendererProps } from '@ag-grid-community/react'
import ChevronDownIcon from '../../assets/chevron_down_icon_20.svg?react'
import { ServerIdentitiesTableRow } from '../../schemas/identity.ts'

// TODO:
//  1. Extract reusable component from here and FindingsGroupCellRenderer
//  2. Actually render groups properly here
export const IdentitiesGroupCellRender = (props: CustomCellRendererProps<ServerIdentitiesTableRow, string>) => {
	const { node, value } = props
	const [expanded, setExpanded] = useState(node.expanded)

	useEffect(() => {
		const expandListener = (event: RowEvent) => setExpanded(event.node.expanded)

		node.addEventListener('expandedChanged', expandListener)

		return () => {
			node.removeEventListener('expandedChanged', expandListener)
		}
	}, [])

	const onClick = useCallback(() => node.setExpanded(!node.expanded), [node])
	return (
		<div
			className="flex items-center"
			style={{
				paddingLeft: `${node.level * 15}px`,
			}}
		>
			{node.group && (
				<div
					style={{
						cursor: 'pointer',
						transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
						display: 'inline-block',
					}}
					onClick={onClick}
				>
					<ChevronDownIcon />
				</div>
			)}
			&nbsp;
			{value}
		</div>
	)
}
