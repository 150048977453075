import { z } from 'zod'
import { BaseAdObjectSchema } from './adBaseSchema'
import { ServerAdAceSchema } from './adAceSchema'
import { DateSchema } from '../../common.ts'
import { BaseEntraIdUserSchema } from '../entraId/entraIdUserSchema.ts'
import { ServerAdGroupSchema } from './adGroupSchema'
import { DemoServerAdOwnershipSchema } from './demoAdOwnershipSchema.ts'
import { DemoServerAdEndpointsSchema } from './demoAdEndpointsSchema.ts'

const GroupMembershipSchema = z.object({
	group_sid: z.string(),
	group_name: z.string(),
	group_data: ServerAdGroupSchema, // Full group data
})

export const BaseAdUserSchema = BaseAdObjectSchema.extend({
	identity_id: z.string().nullish(),
	allowed_to_delegate: z.array(z.string()),
	primary_group_sid: z.string(),
	properties: BaseAdObjectSchema.shape.properties.extend({
		enabled: z.boolean(),
		last_logon: DateSchema,
		last_logon_timestamp: DateSchema,
		pwd_last_set: DateSchema,
		pwd_never_expires: z.boolean(),
		sensitive: z.boolean(),
		service_principal_names: z.array(z.string()),
		has_spn: z.boolean(),
		display_name: z.string(),
		email: z.string().nullish(),
		title: z.string().nullish(),
		home_directory: z.string().nullish(),
		user_password: z.string().nullish(),
		admin_count: z.boolean(),
		sid_history: z.array(z.string()),
		unicode_password: z.string().nullish(),
		logon_script: z.string().nullish(),
	}),
	aces: z.array(ServerAdAceSchema),
	spn_targets: z.array(z.string()),
	has_sid_history: z.array(z.string()),
	group_memberships: z.array(GroupMembershipSchema).nullish(),
	demo_ownership: z.array(DemoServerAdOwnershipSchema).nullish(),
	demo_endpoints_dependencies: z.array(DemoServerAdEndpointsSchema).nullish(),
})

export const ServerAdUserSchema = BaseAdUserSchema.extend({
	entra_id_user: z.lazy(() => BaseEntraIdUserSchema.nullish()),
})

export type ServerAdUser = z.infer<typeof ServerAdUserSchema>
