import React from 'react'
import { ServerIdentity } from '../../../../../schemas/identity.ts'
import { EnvironmentType } from '../../../../../schemas/envType.ts'
import { BaseIdentityDetail } from '../../baseIdentities/BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../../../common/IssueSourceIcon.tsx'
import { Tag, Tooltip } from 'antd'
import { formatDate } from '../../../../../utils.ts'
import { ServerAzureKVCertificate } from '../../../../../schemas/identities/azure/azureKeyVault/azureKvCertificate.ts'

export const AzureKVCertificateIdentity: React.FunctionComponent<{
	identity?: ServerIdentity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const azureKvCertificate: ServerAzureKVCertificate | null | undefined = identity?.azure_kv_certificate

	return (
		<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
			<BaseIdentityDetail label="Certificate Name" value={azureKvCertificate?.name} span={1} />
			<BaseIdentityDetail label="Certificate ID" value={azureKvCertificate?.certificate_id} span={1} />
			<BaseIdentityDetail label="Identity Type" span={1}>
				<IdentityTypeTag type={identity?.type} />
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Environment" span={1}>
				<div className="flex flex-row items-center space-x-2">
					<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
					<Tooltip placement="topLeft" title={identity?.account_literal}>
						<div className="font-normal text-gray-600 items-end truncate">
							{identity?.account_literal_friendly_name ?? identity?.account_literal}
						</div>
					</Tooltip>
				</div>
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Vault Name" value={azureKvCertificate?.vault_name} span={1} />
			<BaseIdentityDetail label="Vault ID" value={azureKvCertificate?.vault_id} span={1} />
			<BaseIdentityDetail label="Subscription ID" value={azureKvCertificate?.subscription_id} span={1} />
			<BaseIdentityDetail label="Status" span={1}>
				<Tag color={azureKvCertificate?.is_enabled ? 'green' : 'red'}>
					{azureKvCertificate?.is_enabled ? 'Enabled' : 'Disabled'}
				</Tag>
			</BaseIdentityDetail>
			<BaseIdentityDetail label="Created At" value={formatDate(azureKvCertificate?.created_at)} span={1} />
			<BaseIdentityDetail label="Updated At" value={formatDate(azureKvCertificate?.updated_at)} span={1} />
			<BaseIdentityDetail label="Start Date" value={formatDate(azureKvCertificate?.start_date)} span={1} />
			<BaseIdentityDetail
				label="Expiration Date"
				value={formatDate(azureKvCertificate?.expiration_date)}
				span={1}
			/>
		</div>
	)
}
