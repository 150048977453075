import { Tag, Tooltip } from 'antd'
import React from 'react'
import { formatDate } from '../../../../utils'
import { GcpServiceAccountKey } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'

export const GcpSaAccessKey: React.FunctionComponent<{
	accessKey?: GcpServiceAccountKey
	onOpenChange?: (visible: boolean) => void
}> = ({ accessKey, onOpenChange }) => {
	return (
		<div className="flex items-end truncate">
			<Tooltip
				onOpenChange={onOpenChange}
				overlayInnerStyle={{
					width: 'fit-content', // Set a maximum width for the tooltip
				}}
				title={
					<div className="flex flex-col w-full">
						{accessKey?.disabled ? (
							<span className="flex text-sm text-gray-300">
								Status: {accessKey?.disabled ? 'Disabled' : 'Active'}
							</span>
						) : null}
						<span className="flex text-sm text-gray-300">ID: {accessKey?.accessKeyId}</span>
						<span className="flex text-sm text-gray-300">
							Creation date: {formatDate(accessKey?.validAfterTime)}
						</span>
						<span className="flex text-sm text-gray-300">Type: {accessKey?.keyType}</span>
					</div>
				}
			>
				<div className="flex items-center">
					<Tag className={`pl-1 ${accessKey?.disabled ? 'text-gray-700' : 'text-green-700'} text text-sm`}>
						{accessKey?.accessKeyId}
					</Tag>
				</div>
			</Tooltip>
		</div>
	)
}
