import { createContext, Dispatch, SetStateAction, useContext, useMemo, useState, ReactNode } from 'react'
import { SidePanelKey } from './IdentitySidePanel/IdentitySidePanel.tsx'

type ActiveSidePanelKeysContextValue = {
	activeSidePanelKeys: Array<SidePanelKey>
	setActiveSidePanelKeys: Dispatch<SetStateAction<Array<SidePanelKey>>>
}

const ActiveSidePanelKeysContext = createContext<ActiveSidePanelKeysContextValue | null>(null)

export const ActiveSidePanelKeysProvider = ({ children }: { children: ReactNode }) => {
	const [activeSidePanelKeys, setActiveSidePanelKeys] = useState<Array<SidePanelKey>>([])
	const value = useMemo(
		() => ({
			activeSidePanelKeys,
			setActiveSidePanelKeys,
		}),
		[activeSidePanelKeys, setActiveSidePanelKeys],
	)
	return <ActiveSidePanelKeysContext.Provider value={value}>{children}</ActiveSidePanelKeysContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export const useActiveSidePanelKeysContext = () => {
	const contextValue = useContext(ActiveSidePanelKeysContext)
	if (!contextValue) {
		throw new Error(
			'Attempted to use useActiveSidePanelKeysContext but no context value was found. You probably forgot to wrap your code with ActiveSidePanelKeysProvider',
		)
	}

	return contextValue
}
