import React, { useEffect, useMemo, useState } from 'react'
import { useCollaborationIntegrations, useCollaborationTestConnection } from '../../../../api/integrations'
import { IntegrationsState, IntegrationStatus } from '../../types/integrationState.ts'
import { IntegrationsError, IntegrationsSkeleton } from '../IntegrationStates/IntegrationStates.tsx'
import { IntegrationCard } from '../IntegrationCard/IntegrationCard.tsx'
import { collaborationIntegrations, collaborationIntegrationsByKey } from '../../collaborationRegistry'
import { CollaborationsIntegrationModal } from '../CollaborationIntegrationModal/CollaborationIntegrationModal.tsx'
import { CollaborationIntegrationConfig } from '../../types/integrationConfig.ts'
import { IntegrationType } from '../../../../jiraIntegration/schemas/common.ts'
import { notification } from 'antd'

interface Props {
	openSpecificModal?: IntegrationType
	isModalVisible: boolean
	setIsModalVisible: (visible: boolean) => void
}

export const CollaborationIntegrations: React.FC<Props> = ({
	openSpecificModal,
	isModalVisible,
	setIsModalVisible,
}) => {
	const { data: integrations, isLoading, error } = useCollaborationIntegrations()
	const testConnection = useCollaborationTestConnection()
	const [selectedIntegration, setSelectedIntegration] = useState<CollaborationIntegrationConfig | null>(null)
	const [testConnectionStatus, setTestConnectionStatus] = useState<'success' | 'error' | null>(null)

	useEffect(() => {
		if (openSpecificModal) {
			const integrationToOpen = collaborationIntegrationsByKey[openSpecificModal]
			if (integrationToOpen) {
				setSelectedIntegration(integrationToOpen)
			}
		}
	}, [openSpecificModal])

	const integrationsState: IntegrationsState = useMemo(() => {
		if (!integrations) return {}

		return collaborationIntegrations.reduce((integrationsState, integration) => {
			const isActive = integrations.some(
				(i) => i.integration_type.toLowerCase() === integration.key.toLowerCase(),
			)
			integrationsState[integration.key] = {
				status: isActive ? IntegrationStatus.ACTIVE : IntegrationStatus.INACTIVE,
				environmentCount: isActive ? 1 : 0,
			}
			return integrationsState
		}, {} as IntegrationsState)
	}, [integrations])

	const handleConnect = (integrationKey: string) => {
		const integration = collaborationIntegrationsByKey[integrationKey]
		if (integration) {
			setSelectedIntegration(integration)
			setIsModalVisible(true)
		}
	}

	const handleCloseModal = () => {
		setIsModalVisible(false)
		setSelectedIntegration(null)
		setTestConnectionStatus(null)
	}

	const handleTestConnection = async () => {
		if (!selectedIntegration) return

		try {
			const response = await testConnection.mutateAsync({
				// eslint-disable-next-line camelcase
				integration_type: selectedIntegration.key as IntegrationType,
			})

			setTestConnectionStatus(response.success ? 'success' : 'error')
			if (!response.success) {
				notification.error({
					message: 'Test Connection Failed',
					description: response.message,
				})
			}
		} catch (error) {
			setTestConnectionStatus('error')
			notification.error({
				message: 'Test Connection Failed',
				description: error instanceof Error ? error.message : 'An unexpected error occurred',
			})
		}
	}

	const handleEdit = () => {
		// Empty handler
	}

	if (isLoading) {
		return <IntegrationsSkeleton />
	}

	if (error) {
		return <IntegrationsError error={error instanceof Error ? error : new Error('An unknown error occurred')} />
	}

	return (
		<>
			<div className="flex flex-wrap gap-5">
				{collaborationIntegrations.map((integration) => (
					<IntegrationCard
						key={integration.key}
						logo={integration.logo}
						name={integration.name}
						description={integration.description}
						status={integrationsState[integration.key]?.status || IntegrationStatus.INACTIVE}
						environmentCount={integrationsState[integration.key]?.environmentCount || 0}
						onConnect={() => handleConnect(integration.key)}
						onEdit={handleEdit}
					/>
				))}
			</div>
			{selectedIntegration && (
				<CollaborationsIntegrationModal
					key={selectedIntegration.key}
					integration={selectedIntegration}
					integrationType={selectedIntegration.key as IntegrationType}
					isModalVisible={isModalVisible}
					onClose={handleCloseModal}
					onTestConnection={handleTestConnection}
					isConnecting={false}
					isTestingConnection={testConnection.isLoading}
					testConnectionStatus={testConnectionStatus}
				/>
			)}
		</>
	)
}
