import { z } from 'zod'
import { DateSchema } from '../../common.ts'
import { ServerGcpPrincipalInfoXcSchema } from './gcpProjectXcSchema.ts'
import { ServerGcpProjectSchema } from '../../environments/gcpProject.ts'
import { ServerEndpointAccessXcSchema } from '../endpointAccessXcSchema.ts'
import { ServerAwsUsageLogSchema } from '../awsIamUserSchema.ts'
import { ServerAwsIamChangeLogSchema } from '../awsIamChangeLogSchema.ts'
import { ServerKubernetesResourceXcSchema } from '../kubernetes/kubernetesResourceXcSchema.ts'

export const GcpServiceAccountKeySchema = z
	.object({
		disabled: z.boolean(),
		keyAlgorithm: z.string(),
		keyOrigin: z.string(),
		keyType: z.string(),
		name: z.string(),
		validAfterTime: DateSchema,
		validBeforeTime: DateSchema,
	})
	.transform((data) => ({
		...data,
		accessKeyId: data.name.split('/').pop() || '',
	}))

export type GcpServiceAccountKey = z.infer<typeof GcpServiceAccountKeySchema>

export const ServerGcpServiceAccountSchema = z.object({
	name: z.string().nullish(),
	disabled: z.boolean().nullish(),
	environment_id: z.string(),
	unique_id: z.string(),
	email: z.string(),
	service_account_keys_xc: GcpServiceAccountKeySchema.array().nullish(),
	last_activity: DateSchema.nullish(),
	gcp_project: ServerGcpProjectSchema.nullish(),
	service_account_projects_roles_xc: z.array(ServerGcpPrincipalInfoXcSchema).nullish(),
	endpoint_access_xc: z.array(ServerEndpointAccessXcSchema).nullish(),
	usage_logs: z.array(ServerAwsUsageLogSchema).nullish(),
	change_logs: z.array(ServerAwsIamChangeLogSchema).nullish(),
	kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(),
})

export type ServerGcpServiceAccount = z.infer<typeof ServerGcpServiceAccountSchema>
