import React from 'react'
import { Alert, Card, Skeleton } from 'antd'

export const IntegrationsSkeleton: React.FC = () => {
	return (
		<div className="flex flex-wrap gap-5">
			{[1, 2, 3].map((key) => (
				<Card key={key} className="w-[270px] h-[168px]">
					<div className="flex items-center gap-2 mb-4">
						<Skeleton.Avatar active size="small" />
						<Skeleton.Input active size="small" style={{ width: 120 }} />
					</div>
					<Skeleton active paragraph={{ rows: 2 }} />
				</Card>
			))}
		</div>
	)
}

export const IntegrationsError: React.FC<{ error: Error }> = ({ error }) => {
	return (
		<Alert
			type="error"
			message="Error Loading Integrations"
			description={error instanceof Error ? error.message : 'An unknown error occurred'}
		/>
	)
}
