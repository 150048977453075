import { Collapse } from 'antd'
import React from 'react'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { ServerDatabricksWorkspace } from '../../../../schemas/identities/databricks/ServerDatabricksWorkspaceSchema.ts'
import { DatabricksWorkspaceTable } from '../../../../tables/databricks/DataBricksWrokspaceTable.tsx'
import { ResourceType } from '../../../../schemas/issue.ts'

export const DatabricksWorkspaceContext: React.FunctionComponent<{
	databricksWorkspaces: ServerDatabricksWorkspace[]
}> = ({ databricksWorkspaces }) => {
	return (
		<Collapse
			expandIconPosition={'end'}
			items={[
				{
					key: 'Workspaces',
					label: (
						<div className="flex items-center">
							<IssueSourceIcon source={ResourceType.DATABRICKS} />
							<div>Workspaces</div>
						</div>
					),
					children: <DatabricksWorkspaceTable data={databricksWorkspaces ?? {}} />,
				},
			]}
		/>
	)
}
