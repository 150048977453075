import { z } from 'zod'
import { awsEc2InstanceKeyPairTagsSchema } from './awsEc2InstanceKeyPairTagsSchema.ts'

export const awsEc2InstanceKeyPairSchema = z.object({
	KeyPairId: z.string(),
	KeyFingerprint: z.string(),
	KeyName: z.string(),
	KeyType: z.string(),
	Tags: z.array(awsEc2InstanceKeyPairTagsSchema).nullish(),
	PublicKey: z.string().nullable(),
	CreateTime: z.string(),
	Region: z.string(),
	AccountId: z.string(),
})

export type awsEc2InstanceKeyPair = z.infer<typeof awsEc2InstanceKeyPairSchema>
