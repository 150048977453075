import { Modal } from 'antd'
import { ServerNotification, NotificationStatus } from '../../../schemas/notifications'
import { ArrowRightOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { keyBy } from 'lodash'

const EnabledDisabledText = ({ isEnabled }: { isEnabled: boolean }) => {
	const className = cx({ 'text-green-800': isEnabled, 'text-red-800': !isEnabled })
	return <span className={className}>{isEnabled ? 'Enabled' : 'Disabled'}</span>
}

const NotificationConfigChangeRow = ({
	serverNotification,
	notification,
}: {
	serverNotification: ServerNotification
	notification: ServerNotification
}) => {
	const enabledChanged = serverNotification.status !== notification.status

	return (
		<div className="flex flex-col gap-2 py-3">
			<div className="flex gap-3 items-center">
				<div className="flex gap-1 items-center">
					<span className="text-neutral-700">{serverNotification.name}</span>
				</div>
			</div>
			<div className="flex items-center">
				{enabledChanged && (
					<div className="flex items-center gap-1">
						<EnabledDisabledText isEnabled={serverNotification.status === NotificationStatus.ENABLED} />
						<ArrowRightOutlined />
						<EnabledDisabledText isEnabled={notification.status === NotificationStatus.ENABLED} />
					</div>
				)}
			</div>
		</div>
	)
}

type NotificationsConfigConfirmationModalProps = {
	isOpen: boolean
	changedNotificationIds: Set<string>
	onCancel: () => void
	onConfirm: () => void
	isUpdating?: boolean
	serverNotifications?: ServerNotification[]
	notifications?: ServerNotification[]
}

export const NotificationsConfigConfirmationModal = ({
	isOpen,
	changedNotificationIds,
	onConfirm,
	onCancel,
	isUpdating,
	serverNotifications,
	notifications,
}: NotificationsConfigConfirmationModalProps) => {
	const [serverNotificationsById, setServerNotificationsById] = useState<Record<string, ServerNotification>>(
		serverNotifications ? keyBy(serverNotifications, 'playbook_id') : {},
	)

	useEffect(() => {
		if (serverNotifications) {
			setServerNotificationsById(keyBy(serverNotifications, 'playbook_id'))
		}
	}, [serverNotifications])

	return (
		<Modal
			width="42%"
			okButtonProps={{ className: 'bg-black', loading: isUpdating }}
			open={isOpen}
			title="Save Changes"
			okType="primary"
			okText="Save"
			onCancel={onCancel}
			onOk={onConfirm}
		>
			<div>Are you sure you want to modify the following rules?</div>
			<div className="overflow-y-auto max-h-[50vh] divide-y pr-2">
				{notifications
					?.filter((notification) => changedNotificationIds.has(notification.playbook_id))
					.map((notification) => (
						<NotificationConfigChangeRow
							key={notification.playbook_id}
							serverNotification={serverNotificationsById[notification.playbook_id]}
							notification={notification}
						/>
					))}
			</div>
		</Modal>
	)
}
