import { Tag } from 'antd'
import cx from 'classnames'
import { IssueName, IssueNameMap } from '../../schemas/issue'
import { IssueIcon } from './IssueIcon.tsx'

export const IssueNameTag: React.FunctionComponent<{
	name?: string | null
	truncate?: boolean
}> = ({ name, truncate }) => {
	const issueName: string = IssueNameMap[name as IssueName]
	const tagClassName = cx('flex items-center p-1', { truncate })
	const issueNameClassName = cx('pl-1 text-gray-800 text text-sm', { truncate })
	return (
		<Tag className={tagClassName}>
			<IssueIcon name={name} />
			<div className={issueNameClassName}>{issueName}</div>
		</Tag>
	)
}
