import React, { useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef } from '@ag-grid-community/core'
import { Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../utils'
import { DemoServerAdEndpoints } from '../../schemas/identities/activeDirectory/demoAdEndpointsSchema.ts'

export const DemoAdEndpointsTable: React.FunctionComponent<{
	data: DemoServerAdEndpoints[]
}> = ({ data }) => {
	const columnDefs = useMemo<ColDef<DemoServerAdEndpoints>[]>(() => {
		return [
			{
				field: 'host_name',
				headerName: 'Host Name',
				flex: 1,
			},
			{
				field: 'source_ip',
				headerName: 'Source IP',
				flex: 1,
			},
			{
				field: 'destination',
				headerName: 'Destination',
				flex: 1,
			},
			{
				field: 'destination_ip',
				headerName: 'Destination IP',
				flex: 1,
			},
			{
				field: 'last_login',
				headerName: 'Last Login',
				flex: 1,
				cellRenderer: (params: { value: string }) => (
					<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
						<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
					</Tooltip>
				),
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				rowData={data}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				enableCellTextSelection
				className="ag-theme-alpine w-full h-full overflow-x-auto"
				rowHeight={54}
			/>
		</div>
	)
}
