import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ServerAwsIamRoleXc } from '../schemas/identities/awsIamRoleXcSchema.ts'
import { IamRolesPoliciesTable } from './IamRolesPoliciesTable.tsx'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { JsonViewer } from '../components/common/JsonViewer.tsx'
import { AccountPriorityNameTag } from '../components/common/AccountPriorityNameTag.tsx'
import { PriorityType } from '../schemas/issue.ts'
import { AccountPriorityEnum, AccountPriorityTypeMap } from '../schemas/environments/accountPriorityEnum.ts'

export const IamRolesTable: React.FunctionComponent<{
	awsIamRolesXc: ServerAwsIamRoleXc[] | null
}> = ({ awsIamRolesXc }) => {
	const showAccountPriorityDrawer = awsIamRolesXc?.every((role) => role.AccountPriority)
	const gridRef = useRef<AgGridReact<ServerAwsIamRoleXc>>(null)
	const columnDefs = useMemo<ColDef<ServerAwsIamRoleXc>[]>(() => {
		return [
			{ field: 'RoleName', cellRenderer: 'agGroupCellRenderer', tooltipField: 'Arn' },
			{ field: 'CreateDate' },
			{ field: 'AccountName' },
			{
				field: 'AccountPriority',
				hide: !showAccountPriorityDrawer,
				cellRenderer: (params: { data?: ServerAwsIamRoleXc }) => {
					if (params.data?.AccountPriority) {
						return <AccountPriorityNameTag priority={params.data.AccountPriority} />
					} else {
						return <AccountPriorityNameTag priority={AccountPriorityEnum.UNKNOWN} />
					}
				},
				filter: 'agSetColumnFilter',
				filterParams: {
					comparator: (a: string, b: string) => {
						const numA = Object.entries(AccountPriorityTypeMap).find((p) => p[1] === a)![0]
						const numB = Object.entries(AccountPriorityTypeMap)?.find((p) => p[1] === b)![0]
						const valA = parseInt(numA)
						const valB = parseInt(numB)
						if (valA === valB) return 0
						return valA < valB ? 1 : -1
					},
				},
				filterValueGetter: (params: { data?: ServerAwsIamRoleXc }) => {
					return String(AccountPriorityTypeMap[params.data?.AccountPriority ?? AccountPriorityEnum.UNKNOWN])
				},
				comparator: (valueA: string, valueB: string) => {
					if (typeof valueA === 'string' && typeof valueB === 'string') {
						const priorityA = PriorityType[valueA.toUpperCase() as keyof typeof PriorityType]
						const priorityB = PriorityType[valueB.toUpperCase() as keyof typeof PriorityType]
						if (priorityA !== undefined && priorityB !== undefined) {
							return priorityB - priorityA
						}
					}
					return 0
				},
			},
			{
				resizable: true,
				minWidth: 180,
				headerName: 'Trust Relationship',
				cellRenderer: (params: { data?: ServerAwsIamRoleXc }) => {
					if (params.data?.AssumeRolePolicyDocument) {
						return <JsonViewer data={params.data.AssumeRolePolicyDocument} title="Trust Relationship" />
					}
				},
			},
		]
	}, [])

	const detailCellRenderer = useMemo(() => {
		return IamRolesPoliciesTable
	}, [])
	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<ServerAwsIamRoleXc>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine h-full w-full overflow-x-auto'}
				ref={gridRef}
				rowData={awsIamRolesXc}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				detailCellRenderer={detailCellRenderer}
				onGridReady={onGridReady}
				tooltipShowDelay={1}
				tooltipInteraction={true}
				enableCellTextSelection
			/>
		</div>
	)
}
