import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import openapiDemo from '../../mirage/openapi-demo.json'
import openapi from './api.json'
import './SwaggerStyle.css'
import { isDemo } from '../../utils/demoUtils.ts'

import { NavigateToBase } from '../NavigateToBase.tsx'
import { useEntitlementToSwagger } from '../../services/auth/hooks.ts'

export const SwaggerComponent = () => {
	const { isEntitled: isEntitledToSwagger } = useEntitlementToSwagger()

	if (!isDemo && !isEntitledToSwagger) {
		return <NavigateToBase />
	}

	return (
		<div className="swagger-container">
			<SwaggerUI spec={isDemo ? openapiDemo : openapi} />
		</div>
	)
}
