import { z } from 'zod'
import { IssueName, PriorityTypeEnum } from '../issue.ts'

export const ServerFindingDistNameSchema = z.object({
	issue_name: z.nativeEnum(IssueName),
	amount: z.number(),
	max_priority: z.nativeEnum(PriorityTypeEnum),
})

export type ServerFindingDistName = z.infer<typeof ServerFindingDistNameSchema>
