import { z } from 'zod'
import { ServerSalesforceUserSchema } from './salesforceUserSchema.ts'
import { ServerSalesforceIdentityOwnershipRecordSchema } from './salesforceOwnershipRecordSchema.ts'
import { ServerSalesforceApiTotalUsageLogSchema } from './salesforceApiTotalUsageLogSchema.ts'

export const ServerSalesforceOAuthConfigSchema = z.object({
	access_method: z.string().nullable(),
	callback_url: z.string().nullable(),
	consumer_key: z.string().nullable(),
	is_admin_approved: z.boolean().nullable(),
	is_client_credential_enabled: z.boolean().nullable(),
	is_code_credential_enabled: z.boolean().nullable(),
	is_code_credential_post_only: z.boolean().nullable(),
	is_consumer_secret_optional: z.boolean().nullable(),
	is_introspect_all_tokens: z.boolean().nullable(),
	is_named_user_jwt_enabled: z.boolean().nullable(),
	is_pkce_required: z.boolean().nullable(),
	is_refresh_token_rotation_enabled: z.boolean().nullable(),
	is_secret_required_for_refresh_token: z.boolean().nullable(),
	is_secret_required_for_token_exchange: z.boolean().nullable(),
	is_token_exchange_enabled: z.boolean().nullable(),
	oauth_client_credential_user: z.string().nullable(),
	scopes: z.array(z.string()).nullable(),
})

export const ServerSalesforceOAuthPolicySchema = z.object({
	ip_relaxation: z.string().nullable(),
	refresh_token_policy: z.string().nullable(),
})

export const ServerSalesforceConnectedAppMetadataSchema = z.object({
	full_name: z.string(),
	contact_email: z.string(),
	label: z.string(),
	oauth_config: ServerSalesforceOAuthConfigSchema.nullable(),
	oauth_policy: ServerSalesforceOAuthPolicySchema.nullable(),
	description: z.string().nullable(),
	start_url: z.string().nullable(),
})

export type ServerSalesforceConnectedAppMetadata = z.infer<typeof ServerSalesforceConnectedAppMetadataSchema>

export const ServerSalesforceConnectedApplicationSchema = z.object({
	connected_application_id: z.string(),
	name: z.string(),
	created_by_id: z.string(),
	last_modified_by_id: z.string(),
	options_allow_admin_approved_users_only: z.boolean(),
	options_refresh_token_validity_metric: z.boolean(),
	options_has_session_level_policy: z.boolean(),
	options_is_internal: z.boolean(),
	options_full_content_push_notifications: z.boolean(),
	options_code_credential_guest_enabled: z.boolean(),
	options_app_issue_jwt_token_enabled: z.boolean(),
	options_token_exchange_manage_bit_enabled: z.boolean(),
	start_url: z.string().nullable(),
	refresh_token_validity_period: z.number().int().nullable(),
	ownership_records: z.array(ServerSalesforceIdentityOwnershipRecordSchema).nullable(),
	app_metadata: ServerSalesforceConnectedAppMetadataSchema.nullable(),
	bound_salesforce_user: ServerSalesforceUserSchema.nullable(),
	api_total_usage_logs: z.array(ServerSalesforceApiTotalUsageLogSchema).nullable(),
})
