import React, { useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GetRowIdParams } from '@ag-grid-community/core'
import { ServerGithubAppInstallation } from '../../schemas/identities/githubAppInstallationSchema.ts'

export const GithubOwnershipTable: React.FunctionComponent<{
	data: ServerGithubAppInstallation[]
}> = ({ data }) => {
	const columnDefs = useMemo<ColDef<ServerGithubAppInstallation>[]>(() => {
		if (!data.length) {
			return []
		}
		return [
			{ field: 'owner_id', headerName: 'ID', flex: 1 },
			{ field: 'owner_name', headerName: 'Name', flex: 1 },
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}
	}, [])

	return (
		<div className="h-80 overflow-auto">
			<AgGridReact
				rowData={data}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				getRowId={(params: GetRowIdParams<ServerGithubAppInstallation>) =>
					params.data?.owner_id?.toString() ?? ''
				}
				enableCellTextSelection
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				rowHeight={54}
			/>
		</div>
	)
}
