import SlackIcon from '../../../assets/slack_icon.svg?react'
import { CollaborationIntegrationConfig } from '../types/integrationConfig'
import { CollaborationsIntegrationTestConnectionRequest } from '../../../schemas/integrations/integrations'
import { IntegrationType } from '../../../jiraIntegration/schemas/common'

export const slackConfig: CollaborationIntegrationConfig = {
	key: IntegrationType.SLACK,
	name: 'Slack',
	description: 'Connect Token Security to your Slack workspace for real-time notifications and alerts.',
	logo: SlackIcon,
	category: 'Collaboration',
	guideFileName: '', // TODO: Add Slack integration guide
	createTestConnectionPayload: (): CollaborationsIntegrationTestConnectionRequest => ({
		// eslint-disable-next-line camelcase
		integration_type: IntegrationType.SLACK,
	}),
}
