import React from 'react'
import { IssueName, IssueNameMap, ServerIssueStats } from '../../schemas/issue.ts'
import { IssueIcon } from '../../components/common/IssueIcon.tsx'

export const FindingMenuItemLabel: React.FC<{
	item: ServerIssueStats
}> = ({ item }) => {
	const name = IssueNameMap[item.issue_name as IssueName]
	return (
		<div className="flex justify-between gap-1">
			<div className="flex content-start items-center truncate">
				<IssueIcon name={item.issue_name} />
				<div className="ml-2 truncate" title={name}>
					{name}
				</div>
			</div>
			<div>({item.count})</div>
		</div>
	)
}
