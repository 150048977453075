import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import { ServerSalesforceProfile } from '../../../../schemas/identities/salesforce/salesforceProfileSchema.ts'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons.tsx'

export type SalesforceProfileNodeType = Node<{ profile: ServerSalesforceProfile }, 'salesforceProfile'>
type SalesforceProfileNodeProps = NodeProps<SalesforceProfileNodeType>

export const SalesforceProfileNode = ({ data: { profile } }: SalesforceProfileNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'salesforceProfile' && highlightedNode.id === profile.id,
	)

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode label="Salesforce Profile" name={{ text: profile.name }}>
			<div className={iconWrapperClassName}>
				<RiskNodeTagIcons tags={profile.tags} />
				<TeamOutlined />
			</div>
		</BaseNode>
	)
}
