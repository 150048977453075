import React from 'react'
import { Collapse, Divider, Skeleton, Tooltip } from 'antd'
import InsightIcon from '../../assets/insight_icon_20.svg?react'
import { InsightNameMap, ServerInsight } from '../../schemas/insight.ts'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { MetricsChartContainer } from './MetricsChartContainer.tsx'
import { useTopIssuesAndInsights } from '../../api/metrics.ts'

const getPanelItem = (insight: ServerInsight) => ({
	label: (
		<div className="flex items-center space-x-2">
			<InsightIcon className="w-[24px] h-[24px] text-emerald-700" />
			<Tooltip title={insight.account_literal}>
				<div className="w-fit">
					{InsightNameMap[insight.insight_name as keyof typeof InsightNameMap]} -{' '}
					{insight.account_literal_friendly_name
						? insight.account_literal_friendly_name
						: insight.account_literal}
				</div>
			</Tooltip>
		</div>
	),
	key: 'insights',
	children: (
		<div className="flex flex-col space-y-2">
			<div>
				{insight.account_literal}{' '}
				{insight.account_literal_friendly_name ? '- ' + insight.account_literal_friendly_name : ''}
			</div>
			<Divider />
			<div className="overflow-y-auto">
				<Markdown remarkPlugins={[remarkGfm]} children={insight.description}></Markdown>
			</div>
		</div>
	),
})

export const TopInsights: React.FunctionComponent<{
	growContainer?: boolean
}> = ({ growContainer }) => {
	const { data: topIssuesAndInsights } = useTopIssuesAndInsights()
	return (
		<MetricsChartContainer
			className={growContainer ? undefined : 'col-span-3'}
			heightSize={growContainer ? 'full' : 'large'}
			childrenAreList
			header="Top Insights"
		>
			{topIssuesAndInsights?.insights?.length === 0 && <div>No data available</div>}
			{!topIssuesAndInsights?.insights && <Skeleton className="w-full h-full" active />}
			{topIssuesAndInsights?.insights?.map((insight: ServerInsight) => (
				<Collapse
					key={insight.id}
					style={{
						backgroundColor: '#FFFFFF',
						border: '1px solid #F0F0F5',
						boxShadow: '0px 2px 7px 0px #534F580F',
					}}
					expandIconPosition={'end'}
					items={[getPanelItem(insight)]}
				/>
			))}
		</MetricsChartContainer>
	)
}
