import {
	EntraIdChangeLogsAggregated,
	ServerEntraIdChangeLog,
} from '../schemas/identities/entraId/entraIdChangeLogSchema'

export const aggregateEntraIdChangeLogs = (changeLogs: ServerEntraIdChangeLog[]): EntraIdChangeLogsAggregated[] => {
	const aggregatedMap = changeLogs.reduce(
		(acc, entry) => {
			const key = `${entry.actor_principal_id}`
			const existing = acc[key]
			if (existing) {
				existing.firstEventTime =
					existing.firstEventTime < new Date(entry.event_time)
						? existing.firstEventTime
						: new Date(entry.event_time)
				existing.lastEventTime =
					existing.lastEventTime > new Date(entry.event_time)
						? existing.lastEventTime
						: new Date(entry.event_time)
				existing.operationCount += 1
				if (!existing.sourceIpAddresses.includes(entry.source_ip_address || '')) {
					existing.sourceIpAddresses.push(entry.source_ip_address || '')
				}
				if (!existing.operations.includes(entry.operation_name)) {
					existing.operations.push(entry.operation_name)
				}
			} else {
				acc[key] = {
					actorPrincipalId: key,
					actorPrincipalName: entry.actor_principal_name || '',
					operations: [entry.operation_name],
					sourceIpAddresses: [entry.source_ip_address || ''],
					firstEventTime: new Date(entry.event_time),
					lastEventTime: new Date(entry.event_time),
					operationCount: 1,
				}
			}
			return acc
		},
		{} as Record<string, EntraIdChangeLogsAggregated>,
	)

	return Object.values(aggregatedMap)
}
