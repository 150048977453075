import { z } from 'zod'
import { DateSchema } from '../common'

export const ServerOktaUserXcSchema = z.object({
	activated: DateSchema.nullish(),
	profile: z
		.object({
			displayName: z.string().nullish(),
			email: z.string().nullish(),
			title: z.string().nullish(),
			secondEmail: z.string().nullish(),
			firstName: z.string().nullish(),
			lastName: z.string().nullish(),
		})
		.nullish(),
	status: z.string().nullish(),
	lastLogin: DateSchema.nullish(),
	lastUpdated: DateSchema.nullish(),
})

export type ServerOktaUserXc = z.infer<typeof ServerOktaUserXcSchema>
