import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent, IRowNode } from '@ag-grid-community/core'
import { ServerKubernetesResourceXc } from '../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { removeNulls } from '../utils.ts'
import { JsonViewer } from '../components/common/JsonViewer.tsx'
import { Tooltip } from 'antd'
import { AccessKeyTooltip } from '../components/drawers/identities/utils/AccessKeyTooltip.tsx'

export const KubernetesResourcesTable: React.FunctionComponent<{
	kubernetesResourcesXc: ServerKubernetesResourceXc[] | null
}> = ({ kubernetesResourcesXc }) => {
	const gridRef = useRef<AgGridReact<ServerKubernetesResourceXc>>(null)
	const columnDefs = useMemo<ColDef<ServerKubernetesResourceXc>[]>(() => {
		return [
			{
				field: 'cluster_literal',
				headerName: 'Cluster',
				cellRenderer: (params: { data?: ServerKubernetesResourceXc; node: IRowNode }) => {
					return (
						<Tooltip title={params?.data?.cluster_literal}>
							<div className="truncate">{params?.data?.cluster_literal}</div>
						</Tooltip>
					)
				},
			},
			{
				field: 'metadata.name',
				headerName: 'Name',
			},
			{
				field: 'kind',
				flex: 0.7,
			},
			{
				field: 'metadata.namespace',
				headerName: 'Namespace',
				flex: 0.7,
			},
			{
				headerName: 'Access Key',
				valueFormatter: (params) => JSON.stringify(params.data?.pod_to_iam_access_keys),
				cellRenderer: (params: { data?: ServerKubernetesResourceXc; node: IRowNode }) => {
					return params?.data?.pod_to_iam_access_keys?.map((accessKey) => {
						return (
							<Tooltip
								key={accessKey?.access_key_id}
								title={<AccessKeyTooltip accessKey={accessKey} />}
							/>
						)
					})
				},
			},
			{
				field: 'metadata',
				valueFormatter: (params) => JSON.stringify(params.data?.metadata),
				cellRenderer: (params: { data?: ServerKubernetesResourceXc; node: IRowNode }) => {
					return (
						<JsonViewer
							data={params.data?.metadata ? removeNulls(params.data?.metadata) : {}}
							title="Metadata"
						/>
					)
				},
				flex: 0.7,
			},
			{
				field: 'spec',
				valueFormatter: (params) => JSON.stringify(params.data?.spec),
				cellRenderer: (params: { data?: ServerKubernetesResourceXc; node: IRowNode }) => {
					return <JsonViewer data={params.data?.spec ? removeNulls(params.data.spec) : {}} title="Spec" />
				},
				flex: 0.7,
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<ServerKubernetesResourceXc>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine overflow-x-auto'}
				ref={gridRef}
				rowData={kubernetesResourcesXc}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
