import { z } from 'zod'
import { EnvironmentType } from '../envType.ts'
import { IntegrationType } from '../../jiraIntegration/schemas/common.ts'
import { DateSchema } from '../common.ts'

const AzureDevOpsTestConnectionConfigSchema = z.object({
	organization_name: z.string(),
	log_analytics_workspace_id: z.string(),
	personal_access_token: z.string(),
})
const SalesforceTestConnectionConfigSchema = z.object({
	domain: z.string(),
	consumer_key: z.string(),
	consumer_secret: z.string(),
})

export const IntegrationTestConnectionRequestSchema = z.object({
	environment_type: z.nativeEnum(EnvironmentType),
	config: z.union([AzureDevOpsTestConnectionConfigSchema, SalesforceTestConnectionConfigSchema]),
})

export type IntegrationTestConnectionRequest = z.infer<typeof IntegrationTestConnectionRequestSchema>

export const CollaborationsIntegrationTestConnectionRequestSchema = z.object({
	integration_type: z.nativeEnum(IntegrationType),
})

export type CollaborationsIntegrationTestConnectionRequest = z.infer<
	typeof CollaborationsIntegrationTestConnectionRequestSchema
>

export const IntegrationTestConnectionResponseSchema = z.object({
	success: z.boolean(),
	message: z.string(),
})

export type IntegrationTestConnectionResponse = z.infer<typeof IntegrationTestConnectionResponseSchema>

export const BaseIntegrationResponseSchema = z.object({
	id: z.string().uuid(),
	customer_id: z.string().uuid(),
	name: z.string(),
	enabled: z.boolean(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
})

export type BaseIntegrationResponse = z.infer<typeof BaseIntegrationResponseSchema>

export const SlackIntegrationResponseSchema = BaseIntegrationResponseSchema.extend({
	team_id: z.string(),
	team_name: z.string(),
	scope: z.string(),
	app_id: z.string(),
	bot_user_id: z.string(),
	webhook_channel: z.string().nullable(),
	webhook_channel_id: z.string().nullable(),
	webhook_configuration_url: z.string().nullable(),
	authed_user_id: z.string().nullable(),
	token_type: z.string().nullable(),
	enterprise: z.boolean().nullable(),
	is_enterprise_install: z.boolean().nullable(),
})

export type SlackIntegrationResponse = z.infer<typeof SlackIntegrationResponseSchema>

// TODO: Add more integrations, then this will be a union
export const ServerCollaborationIntegrationSchema = z.object({
	integration_type: z.nativeEnum(IntegrationType),
	integration: z.union([SlackIntegrationResponseSchema, BaseIntegrationResponseSchema]),
})

export type ServerCollaborationsIntegration = z.infer<typeof ServerCollaborationIntegrationSchema>

export const SlackIntegrationsSearchSchema = z.object({
	integrationStatus: z.string().optional(),
})

export type SlackIntegrationsSearch = z.infer<typeof SlackIntegrationsSearchSchema>

export enum IntegrationResultStatus {
	SUCCESS = 'success',
	FAIL = 'fail',
	EXISTS = 'exists',
}

export const IntegrationsSearchSchema = z.object({
	integrationType: z.string().optional(),
	integrationStatus: z.nativeEnum(IntegrationResultStatus).optional(),
	openModal: z.string().optional(),
})

export type IntegrationsSearch = z.infer<typeof IntegrationsSearchSchema>
