import { JiraField, JiraFieldComponent } from '../schemas/jiraSchemas.ts'
import { JiraTextAreaField } from './JiraTextAreaField.tsx'
import { JiraSelectField } from './JiraSelectField.tsx'
import { JiraInputField } from './JiraInputField.tsx'

const JiraMultiLineString: string[] = [
	'description',
	'environment',
	'com.atlassian.jira.plugin.system.customfieldtypes:textarea',
]

export const JiraStringField: JiraFieldComponent = (props) => {
	const field: JiraField | undefined = props.field
	if (!field) {
		return null
	}
	if (JiraMultiLineString.includes(field.schema.system ?? '')) {
		return <JiraTextAreaField {...props} />
	}
	if (field.schema.custom) {
		return <JiraSelectField {...props} field={field} />
	}
	return <JiraInputField {...props} field={field} />
}
