import { NodeProps, Node } from '@xyflow/react'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'
import { awsEc2InstanceKeyPair } from '../../../../schemas/identities/awsEc2InstanceKeyPairSchema.ts'

export type Ec2InstanceKeyPairNodeType = Node<{ keyPair: awsEc2InstanceKeyPair }, 'keyPair'>
type Ec2InstanceKeyPairNodeProps = NodeProps<Ec2InstanceKeyPairNodeType>

export const Ec2InstanceKeyPairNode = ({ data: { keyPair } }: Ec2InstanceKeyPairNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'keyPair' && highlightedNode.id === keyPair?.KeyPairId,
	)

	return (
		<BaseCredentialsNode
			isHighlighted={isHighlighted}
			label="Key Pair"
			name={{ text: keyPair?.KeyPairId || 'Unknown key pair' }}
		/>
	)
}
