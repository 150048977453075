import {
	EntraIdChangeLogsAggregated,
	ServerEntraIdChangeLog,
} from '../../../../schemas/identities/entraId/entraIdChangeLogSchema.ts'
import { AgGridReact } from '@ag-grid-community/react'
import React, { useMemo } from 'react'
import { ColDef } from '@ag-grid-community/core'
import { Tooltip } from 'antd'
import { aggregateEntraIdChangeLogs } from '../../../../utils/entraIdentityUtils.ts'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'

export const EntraIdChangeLogsAggregationsTable: React.FunctionComponent<{
	changeLogs: ServerEntraIdChangeLog[]
}> = ({ changeLogs }) => {
	const defaultColDef: ColDef = {
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	}

	const columnDefs: ColDef<EntraIdChangeLogsAggregated>[] = [
		{
			field: 'actorPrincipalName',
			headerName: 'Actor',
			cellRenderer: (params: { data: EntraIdChangeLogsAggregated }) => {
				return (
					<Tooltip placement="topLeft" title={params.data.actorPrincipalId}>
						{params.data.actorPrincipalName}
					</Tooltip>
				)
			},
		},
		{
			field: 'operations',
			headerName: 'Operations',
			valueFormatter: ({ value }: { value: string[] }) => value.join(','),
		},
		{
			field: 'operationCount',
			headerName: 'Count',
			sort: 'desc',
			flex: 0.8,
		},
		{
			field: 'sourceIpAddresses',
			headerName: 'Source IP Addresses',
			valueFormatter: ({ value }: { value: string[] }) => value.join(','),
		},
		{
			field: 'firstEventTime',
			headerName: 'First Event',
			cellRenderer: ({ value }: { value: Date }) => (
				<Tooltip placement="bottomLeft" title={formatDate(value)}>
					<div className="text-gray-400">{formatRelativeDateText(value, true)}</div>
				</Tooltip>
			),
		},
		{
			field: 'lastEventTime',
			headerName: 'Last Event',
			cellRenderer: ({ value }: { value: Date }) => (
				<Tooltip placement="bottomLeft" title={formatDate(value)}>
					<div className="text-gray-400">{formatRelativeDateText(value, true)}</div>
				</Tooltip>
			),
		},
	]

	const aggregatedEntries = useMemo(() => aggregateEntraIdChangeLogs(changeLogs), [changeLogs])

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			rowData={aggregatedEntries}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			enableCellTextSelection
			suppressHorizontalScroll={false}
		/>
	)
}
