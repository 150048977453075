import { z } from 'zod'
import { DateSchema } from '../../common.ts'

export const ServerRawSalesforceUser = z.object({
	id: z.string(),
	username: z.string(),
	email: z.string(),
	first_name: z.string().nullish(),
	last_name: z.string().nullish(),
	is_active: z.boolean(),
	user_type: z.string(),
	profile_id: z.string(),
	created_by_id: z.string(),
	last_modified_by_id: z.string(),
	created_at: DateSchema,
	last_modified_date: DateSchema,
	last_login_date: DateSchema.nullish(),
})

export const ServerSalesforceIdentityOwnershipRecordSchema = z.object({
	owner: ServerRawSalesforceUser,
	action_type: z.string(),
	last_modified_date: DateSchema,
})

export type ServerSalesforceIdentityOwnershipRecordSchema = z.infer<
	typeof ServerSalesforceIdentityOwnershipRecordSchema
>
