import React from 'react'
import cx from 'classnames'

export const StatItem: React.FunctionComponent<{
	title?: string | React.ReactNode
	children: React.ReactNode
	compact?: boolean
	className?: string
}> = ({ title, children, compact, className }) => {
	const containerClassName = cx(
		'h-full flex flex-col',
		{
			'gap-8': !compact,
			'gap-3': compact,
		},
		className,
	)

	return (
		<div className={containerClassName}>
			<h3 className="w-full text-sm text-neutral-500">{title}</h3>
			<div className="text-xl text-neutral-900 grow pb-1 items-end">{children}</div>
		</div>
	)
}
