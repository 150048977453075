import { policyTags } from './awsIamUserData.ts'
import { MockDemoTag } from '../../schemas/tags.ts'

const ADMIN_ROLE = {
	name: 'ACCOUNTADMIN',
	owner: '',
	comment: 'Account administrator can manage all aspects of the account.',
	created_on: '2021-06-19 04:12:31.222000-07:00',
	is_current: 'N',
	is_default: 'N',
	is_inherited: 'N',
	granted_roles: 3,
	granted_to_roles: 0,
	assigned_to_users: 0,
	direct_user_assignment: true,
}

const READ_ONLY_ROLE = {
	name: 'AG-SNOWFLAKE-READ-ONLY',
	owner: 'ACCOUNTADMIN',
	comment: 'Account administrator can manage all aspects of the account.',
	created_on: '2021-06-19 04:12:31.222000-07:00',
	is_current: 'N',
	is_default: 'N',
	is_inherited: 'N',
	granted_roles: 3,
	granted_to_roles: 0,
	assigned_to_users: 0,
	direct_user_assignment: true,
}

const READ_WRITE_ROLE = {
	name: 'AG-SNOWFLAKE-READ-WRITE',
	owner: 'ACCOUNTADMIN',
	comment: 'Account administrator can manage all aspects of the account.',
	created_on: '2021-06-19 04:12:31.222000-07:00',
	is_current: 'N',
	is_default: 'N',
	is_inherited: 'N',
	granted_roles: 3,
	granted_to_roles: 0,
	assigned_to_users: 0,
	direct_user_assignment: true,
}

export const snowflakeUserData = {
	'60000': {
		id: '116f30ed-e39f-41c3-bf14-bb2b5b7e5396',
		related_sub_envs: [],
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
		literal: '3415',
		literal_friendly_name: 'eli.sampars@synergy.com',
		created_at: '2024-02-02T06:05:32.949000Z',
		updated_at: '2024-06-17T13:57:43.884507Z',
		last_activity: '2024-01-02T10:51:41.037000Z',
		env_type: 'SNOWFLAKE',
		type: 'HUMAN',
		source: 'SNOWFLAKE_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'uas74660.us-east-1',
		account_literal_friendly_name: null,
		affected_environments: null,
		snowflake_user_id: '8d46c3ab-ff68-4ca4-ac5b-946fce48da9a',
		snowflake_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '8d46c3ab-ff68-4ca4-ac5b-946fce48da9a',
			name: 'eli.sampars@synergy.com',
			created_at: '2024-06-02T06:05:32.949000Z',
			updated_at: null,
			last_activity: '2024-06-02T10:51:41.037000Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			user_id: '51',
			deleted_on: null,
			display_name: 'Eli Sampars',
			first_name: null,
			last_name: null,
			email: 'eli.sampars@synergy.com',
			must_change_password: false,
			has_password: true,
			comment: null,
			disabled: false,
			snowflake_lock: false,
			default_role: 'ACCOUNTADMIN',
			ext_authn_duo: false,
			bypass_mfa_until: null,
			expires_at: null,
			has_rsa_public_key: false,
			password_last_set_time: '2023-06-02T10:51:41.037000Z',
			owner: 'ACCOUNTADMIN',
			snowflake_roles_xc: [READ_ONLY_ROLE],
		},
	},
	'60001': {
		id: '7dgf30ed-e39f-41c3-bf14-bb2b5b7e5396',
		related_sub_envs: [],
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
		literal: '1003',
		literal_friendly_name: 'dev-app-testing',
		created_at: '2023-02-04T02:05:32.949000Z',
		updated_at: '2024-06-17T13:57:43.884507Z',
		last_activity: '2023-12-02T11:50:12.037000Z',
		env_type: 'SNOWFLAKE',
		type: 'MACHINE',
		source: 'SNOWFLAKE_USER',
		issues: [],
		tags: [policyTags.ADMIN],
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		identity_tag_list: [],
		account_literal: 'uas74660.us-east-1',
		account_literal_friendly_name: null,
		affected_environments: null,
		snowflake_user_id: '8d46c3ab-ff68-4ca4-ac5b-946fce48da9a',
		snowflake_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: '8d46c3ab-ff68-4ca4-ac5b-946fce48da2f',
			name: 'dev-app-testing',
			created_at: '2024-06-02T06:05:32.949000Z',
			updated_at: null,
			last_activity: '2023-12-02T11:50:12.037000Z',
			environment_id: '201b95a4-6069-40e9-9f6a-eb51214df1bf',
			sub_env_id: null,
			user_id: '51',
			deleted_on: null,
			display_name: '',
			first_name: null,
			last_name: null,
			email: null,
			must_change_password: false,
			has_password: true,
			comment: null,
			disabled: false,
			snowflake_lock: false,
			default_role: 'ACCOUNTADMIN',
			ext_authn_duo: false,
			bypass_mfa_until: null,
			expires_at: null,
			has_rsa_public_key: false,
			password_last_set_time: null,
			owner: 'ACCOUNTADMIN',
			snowflake_roles_xc: [ADMIN_ROLE],
		},
	},
	'60003': {
		id: '260003de-133f-41c3-bf14-bb2b5b7e5397',
		related_sub_envs: [],
		customer_id: '6836f9b9-37a2-4ab4-8d13-d7c4b310629f',
		environment_id: '301b95a4-6069-40e9-9f6a-eb51214df1c0',
		literal: '4526',
		literal_friendly_name: 'john.doe@synergy.com',
		created_at: '2024-03-03T07:05:32.949000Z',
		updated_at: '2024-06-17T14:57:43.884507Z',
		last_activity: '2024-02-03T11:51:41.037000Z',
		env_type: 'SNOWFLAKE',
		type: 'HUMAN',
		source: 'SNOWFLAKE_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'uas74660.us-east-1',
		account_literal_friendly_name: null,
		affected_environments: null,
		snowflake_user_id: '9d56d4bc-ff68-4ca4-ac5b-946fce48da9b',
		snowflake_user: {
			customer_id: '6836f9b9-37a2-4ab4-8d13-d7c4b310629f',
			id: '9d56d4bc-ff68-4ca4-ac5b-946fce48da9b',
			name: 'john.doe@synergy.com',
			created_at: '2024-07-03T07:05:32.949000Z',
			updated_at: null,
			last_activity: '2024-07-03T11:51:41.037000Z',
			environment_id: '301b95a4-6069-40e9-9f6a-eb51214df1c0',
			sub_env_id: null,
			user_id: '52',
			deleted_on: null,
			display_name: 'John Doe',
			first_name: null,
			last_name: null,
			email: 'john.doe@synergy.com',
			must_change_password: false,
			has_password: true,
			comment: null,
			disabled: false,
			snowflake_lock: false,
			default_role: 'ACCOUNTADMIN',
			ext_authn_duo: false,
			bypass_mfa_until: null,
			expires_at: null,
			has_rsa_public_key: false,
			password_last_set_time: null,
			owner: 'ACCOUNTADMIN',
			snowflake_roles_xc: [READ_ONLY_ROLE, READ_WRITE_ROLE],
		},
	},
	'60004': {
		id: '360004fg-e39f-41c3-bf14-bb2b5b7e5398',
		related_sub_envs: [],
		customer_id: '7836f9b9-37a2-4ab4-8d13-d7c4b310629g',
		environment_id: '401b95a4-6069-40e9-9f6a-eb51214df1d1',
		literal: '5637',
		literal_friendly_name: 'jane.smith@synergy.com',
		created_at: '2024-04-04T08:05:32.949000Z',
		updated_at: '2024-06-17T15:57:43.884507Z',
		last_activity: '2024-03-04T12:51:41.037000Z',
		env_type: 'SNOWFLAKE',
		type: 'HUMAN',
		source: 'SNOWFLAKE_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'uas74660.us-east-1',
		account_literal_friendly_name: null,
		affected_environments: null,
		snowflake_user_id: 'ad67e5cd-ff68-4ca4-ac5b-946fce48da9c',
		snowflake_user: {
			customer_id: '7836f9b9-37a2-4ab4-8d13-d7c4b310629g',
			id: 'ad67e5cd-ff68-4ca4-ac5b-946fce48da9c',
			name: 'jane.smith@synergy.com',
			created_at: '2024-08-04T08:05:32.949000Z',
			updated_at: null,
			last_activity: '2024-08-04T12:51:41.037000Z',
			environment_id: '401b95a4-6069-40e9-9f6a-eb51214df1d1',
			sub_env_id: null,
			user_id: '53',
			deleted_on: null,
			display_name: 'Jane Smith',
			first_name: null,
			last_name: null,
			email: 'jane.smith@synergy.com',
			must_change_password: false,
			has_password: true,
			comment: null,
			disabled: false,
			snowflake_lock: false,
			default_role: 'ACCOUNTADMIN',
			ext_authn_duo: false,
			bypass_mfa_until: null,
			expires_at: null,
			has_rsa_public_key: false,
			password_last_set_time: null,
			owner: 'ACCOUNTADMIN',
			snowflake_roles_xc: [READ_ONLY_ROLE],
		},
	},
	'60005': {
		id: '460005hi-e39f-41c3-bf14-bb2b5b7e5399',
		related_sub_envs: [],
		customer_id: '8836f9b9-37a2-4ab4-8d13-d7c4b310629h',
		environment_id: '501b95a4-6069-40e9-9f6a-eb51214df1e2',
		literal: '6748',
		literal_friendly_name: 'michael.jones@synergy.com',
		created_at: '2024-05-05T09:05:32.949000Z',
		updated_at: '2024-06-17T16:57:43.884507Z',
		last_activity: '2024-04-05T13:51:41.037000Z',
		env_type: 'SNOWFLAKE',
		type: 'HUMAN',
		source: 'SNOWFLAKE_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'uas74660.us-east-1',
		account_literal_friendly_name: null,
		affected_environments: null,
		snowflake_user_id: 'bd78f6de-ff68-4ca4-ac5b-946fce48da9d',
		snowflake_user: {
			customer_id: '8836f9b9-37a2-4ab4-8d13-d7c4b310629h',
			id: 'bd78f6de-ff68-4ca4-ac5b-946fce48da9d',
			name: 'michael.jones@synergy.com',
			created_at: '2024-09-05T09:05:32.949000Z',
			updated_at: null,
			last_activity: '2024-09-05T13:51:41.037000Z',
			environment_id: '501b95a4-6069-40e9-9f6a-eb51214df1e2',
			sub_env_id: null,
			user_id: '54',
			deleted_on: null,
			display_name: 'Michael Jones',
			first_name: null,
			last_name: null,
			email: 'michael.jones@synergy.com',
			must_change_password: false,
			has_password: true,
			comment: null,
			disabled: false,
			snowflake_lock: false,
			default_role: 'ACCOUNTADMIN',
			ext_authn_duo: false,
			bypass_mfa_until: null,
			expires_at: null,
			has_rsa_public_key: false,
			password_last_set_time: null,
			owner: 'ACCOUNTADMIN',
			snowflake_roles_xc: [READ_ONLY_ROLE],
		},
	},
	'60007': {
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e5401',
		related_sub_envs: [],
		customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
		environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
		literal: '8960',
		literal_friendly_name: 'david.brown@synergy.com',
		created_at: '2024-07-07T11:05:32.949000Z',
		updated_at: '2024-06-17T18:57:43.884507Z',
		last_activity: '2024-06-07T15:51:41.037000Z',
		env_type: 'SNOWFLAKE',
		type: 'HUMAN',
		source: 'SNOWFLAKE_USER',
		issues: [],
		tags: [],
		identity_tag_list: [],
		account_literal: 'uas74660.us-east-1',
		account_literal_friendly_name: null,
		affected_environments: null,
		snowflake_user_id: 'cd89g7ef-ff68-4ca4-ac5b-946fce48da9e',
		snowflake_user: {
			customer_id: 'a836f9b9-37a2-4ab4-8d13-d7c4b310629j',
			id: 'cd89g7ef-ff68-4ca4-ac5b-946fce48da9e',
			name: 'david.brown@synergy.com',
			created_at: '2024-10-07T11:05:32.949000Z',
			updated_at: null,
			last_activity: '2024-10-07T15:51:41.037000Z',
			environment_id: '701b95a4-6069-40e9-9f6a-eb51214df1g4',
			sub_env_id: null,
			user_id: '55',
			deleted_on: null,
			display_name: 'David Brown',
			first_name: null,
			last_name: null,
			email: 'david.brown@synergy.com',
			must_change_password: false,
			has_password: true,
			comment: null,
			disabled: false,
			snowflake_lock: false,
			default_role: 'ACCOUNTADMIN',
			ext_authn_duo: false,
			bypass_mfa_until: null,
			expires_at: null,
			has_rsa_public_key: false,
			password_last_set_time: null,
			owner: 'ACCOUNTADMIN',
			snowflake_roles_xc: [READ_ONLY_ROLE, READ_WRITE_ROLE],
		},
	},
}
