export enum AccountPriorityEnum {
	NONE = 1,
	LOW = 2,
	UNKNOWN = 2.5,
	MEDIUM = 3,
	HIGH = 4,
	CRITICAL = 5,
}

export const AccountPriorityTypeMap: { [key in AccountPriorityEnum]: string } = {
	[AccountPriorityEnum.NONE]: 'N/A',
	[AccountPriorityEnum.LOW]: 'Low',
	[AccountPriorityEnum.MEDIUM]: 'Medium',
	[AccountPriorityEnum.UNKNOWN]: 'Unknown',
	[AccountPriorityEnum.HIGH]: 'High',
	[AccountPriorityEnum.CRITICAL]: 'Critical',
}
