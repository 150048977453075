import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { Edge } from '@xyflow/react'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { SalesforceProfileNodeType } from './SalesforceProfileNode.tsx'
import { SalesforcePermissionSetNodeType } from './SalesforcePermissionSetNode.tsx'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import { SalesforceConnectedAppMetadataNodeType } from './SalesforceConnectedAppOAuthNode.tsx'
import { SalesforceConsumerKeyNodeType } from './SalesforceCosumerKeyNode.tsx'
import { uniqBy } from 'lodash'
import { OktaUserNodeType } from '../okta/OktaUserNode.tsx'
import { EntraIDUserNodeType } from '../entraId/EntraIDUserNode.tsx'
import { IssueName } from '../../../../schemas/issue.ts'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	credentialIssue: 1,
	consumerKey: 2,
	identity: 3,
	profileAndPermissions: 4,
	connectedAppMetadata: 5,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const oktaUserNodeRowIndex = 1
const entraIdUserNodeRowIndex = 2
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`
const consumerKeyNodeId = `${nodeLogicalTypeToColumnId.consumerKey}-0`

const credentialIssueNames: IssueName[] = [IssueName.AccessKeyNotRotated]

export const getSalesforceNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []

	// Identity Node
	const identityNodes: Array<
		| BareNodeType<IdentityNodeType>
		| BareNodeType<OktaUserNodeType>
		| BareNodeType<EntraIDUserNodeType>
		| BareNodeType<OwnershipNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const ownershipRecords =
		identity.salesforce_user?.ownership_records ||
		identity.salesforce_connected_application?.ownership_records ||
		[]
	if (ownershipRecords.length > 0) {
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: uniqBy(ownershipRecords, 'owner.id').map((ownershipRecord) => ({
					id: ownershipRecord.owner.id,
					name: ownershipRecord.owner.username,
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	if (identity.salesforce_user?.okta_user_xc) {
		const details = identity.salesforce_user.okta_user_xc
		const displayName = details.profile?.displayName || details.profile?.email
		const nodeId = `${nodeLogicalTypeToColumnId.identity}-${oktaUserNodeRowIndex}`
		identityNodes.push({
			type: 'oktaUser',
			data: { oktaUser: { type: 'Okta User', displayName } },
			id: nodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: nodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	if (identity.salesforce_user?.entra_id_user_xc) {
		const details = identity.salesforce_user?.entra_id_user_xc
		const nodeId = `${nodeLogicalTypeToColumnId.identity}-${entraIdUserNodeRowIndex}`
		identityNodes.push({
			type: 'entraIDUser',
			data: { user: { principalName: details.user_principal_name, objectId: details.entra_user_id } },
			id: nodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: nodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	// Separate general issues and credential issues
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	const credentialIssueNodes: BareNodeType<IssueNodeType>[] = []

	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issue_name && credentialIssueNames.includes(issue.issue_name)) {
			credentialIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.credentialIssue}-${credentialIssueNodes.length}`,
			})
		} else {
			generalIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
			})
		}
	})

	// Initialize arrays for profile/permission set nodes and connected app metadata node
	const profileAndPermissionSetNodes: Array<
		BareNodeType<SalesforceProfileNodeType> | BareNodeType<SalesforcePermissionSetNodeType>
	> = []
	const connectedAppMetadataNodes: Array<BareNodeType<SalesforceConnectedAppMetadataNodeType>> = []

	if (identity.salesforce_user) {
		// Handle Salesforce User case
		if (identity.salesforce_user.profile) {
			const profileNode: BareNodeType<SalesforceProfileNodeType> = {
				type: 'salesforceProfile',
				data: { profile: identity.salesforce_user.profile },
				id: `${nodeLogicalTypeToColumnId.profileAndPermissions}-0`,
			}
			profileAndPermissionSetNodes.push(profileNode)
			edges.push(getEdge({ source: identityNodeId, target: profileNode.id }))
		}

		const permissionSets = identity.salesforce_user.permission_sets || []
		permissionSets.forEach((permissionSet, index) => {
			const node: BareNodeType<SalesforcePermissionSetNodeType> = {
				type: 'salesforcePermissionSet',
				data: { permissionSet },
				id: `${nodeLogicalTypeToColumnId.profileAndPermissions}-${index + 1}`,
			}
			profileAndPermissionSetNodes.push(node)
			edges.push(getEdge({ source: identityNodeId, target: node.id }))
		})
	} else if (identity.salesforce_connected_application) {
		// Handle Salesforce Connected Application case
		if (identity.salesforce_connected_application.app_metadata) {
			const metadataNode: BareNodeType<SalesforceConnectedAppMetadataNodeType> = {
				type: 'salesforceConnectedAppMetadata',
				data: { metadata: identity.salesforce_connected_application.app_metadata },
				id: `${nodeLogicalTypeToColumnId.connectedAppMetadata}-0`,
			}
			connectedAppMetadataNodes.push(metadataNode)
			edges.push(getEdge({ source: identityNodeId, target: metadataNode.id }))
		}
	}

	// Consumer Key Node
	const consumerKeyNodes: Array<BareNodeType<SalesforceConsumerKeyNodeType>> = []
	if (identity.salesforce_connected_application?.app_metadata?.oauth_config?.consumer_key) {
		consumerKeyNodes.push({
			type: 'salesforceConsumerKey',
			data: {
				consumerKey: {
					id: identity.salesforce_connected_application.app_metadata.oauth_config.consumer_key,
				},
			},
			id: consumerKeyNodeId,
		})
		edges.push(getEdge({ source: consumerKeyNodeId, target: identityNodeId }))

		// Connect credential issues to the consumer key
		credentialIssueNodes.forEach((issueNode) => {
			edges.push(getEdge({ source: issueNode.id, target: consumerKeyNodeId }))
		})
	}

	// Add edges for general issues
	generalIssueNodes.forEach((node) => {
		edges.push(getEdge({ source: node.id, target: identityNodeId }))
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'top', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: credentialIssueNodes },
		{ yPosition: 'center', nodes: consumerKeyNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: profileAndPermissionSetNodes },
		{ yPosition: 'center', nodes: connectedAppMetadataNodes },
	]

	return [nodes, edges]
}
