import { useMemo } from 'react'
import { AgChartsReact } from 'ag-charts-react'
import { ServerResolvedVsUnresolved } from '../schemas/metrics/resolvedVsUnresolved.ts'
import { noDataOverlay } from './helpers.tsx'
import { formatDate } from '../utils.ts'
import { circleStyle } from '../routes/Metrics/helpers.tsx'
import { AgChartOptions } from 'ag-charts-enterprise'
import { useResolvedVsUnresolvedMetrics } from '../api/metrics.ts'

export const ResolvedVsUnresolvedFindingsChart = () => {
	const { data: resolvedVsUnresolvedMetrics } = useResolvedVsUnresolvedMetrics()
	const normalizedData = useMemo(() => {
		return resolvedVsUnresolvedMetrics?.map((f) => ({
			...f,
			date: formatDate(f.date, false),
		}))
	}, [resolvedVsUnresolvedMetrics])
	const latestData = useMemo(() => {
		if (!normalizedData || normalizedData.length === 0) return
		return normalizedData?.[normalizedData.length - 1]
	}, [normalizedData])
	const options: AgChartOptions = {
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
		legend: {
			enabled: false,
			position: 'left',
			orientation: 'horizontal',
		},
		height: 90,
		width: 420,
		data: normalizedData,
		autoSize: true,
		axes: [
			{
				type: 'category',
				position: 'bottom',
				label: {
					enabled: false,
				},
			},
			{
				type: 'number',
				position: 'left',
			},
		],
		series: [
			{
				type: 'line',
				xKey: 'date',
				yKey: 'resolved',
				yName: 'Resolved',
				stroke: '#80B3FF',
				marker: {
					fill: '#80B3FF',
					size: 1,
					stroke: '#80B3FF',
					strokeWidth: 3,
				},
				tooltip: {
					renderer: (params) => {
						const data = params.datum as ServerResolvedVsUnresolved
						return {
							title: data.date.toString(),
							content: `Resolved: ${data.resolved}`,
						}
					},
				},
			},
			{
				type: 'line',
				xKey: 'date',
				yKey: 'unresolved',
				yName: 'Unresolved',
				stroke: '#FF80AD',
				marker: {
					fill: '#FF80AD',
					size: 1,
					stroke: '#FF80AD',
					strokeWidth: 3,
				},
				tooltip: {
					renderer: (params) => {
						const data = params.datum as ServerResolvedVsUnresolved
						return {
							title: data.date.toString(),
							content: `Unresolved: ${data.unresolved}`,
						}
					},
				},
			},
		],
	}
	return (
		<div className="flex flex-row items-center">
			<div className="flex flex-col font-normal space-y-2">
				<div className="flex flex-row items-center space-x-2 text-sm">
					<div style={{ ...circleStyle, backgroundColor: '#80B3FF' }} />
					<div className="text-gray-600">Resolved</div>
					<div>{latestData?.resolved}</div>
				</div>
				<div className="flex flex-row items-center space-x-2 justify-between text-sm">
					<div style={{ ...circleStyle, backgroundColor: '#FF80AD' }} />
					<div className="text-gray-600">Unresolved</div>
					<div>{latestData?.unresolved}</div>
				</div>
			</div>
			<div>
				<AgChartsReact options={options} />
			</div>
		</div>
	)
}
