import { z } from 'zod'
import { ServerAwsIdentityPolicySchema, ServerAwsTagSchema, ServerManagedPolicy } from './awsPoliciesSchema.ts'

export const ServerAwsIamGroupDetailsSchema = z.object({
	Path: z.string(),
	GroupName: z.string(),
	GroupId: z.string(),
	Arn: z.string(),
	CreateDate: z.string(),
	GroupPolicyList: z.array(ServerAwsIdentityPolicySchema),
	AttachedManagedPolicies: z.array(ServerManagedPolicy),
	CustomerManaged: z.boolean().optional().nullish(),
})

export type ServerAwsIamGroupDetails = z.infer<typeof ServerAwsIamGroupDetailsSchema>

export const ServerAwsIamUserPermissionXcSchema = z.object({
	Path: z.string().nullish(),
	UserName: z.string().nullish(),
	UserId: z.string().nullish(),
	Arn: z.string().nullish(),
	CreateDate: z.string().nullish(),
	UserPolicyList: z.array(ServerAwsIdentityPolicySchema).optional().default([]),
	GroupList: z.array(z.string()).optional().default([]),
	AttachedManagedPolicies: z.array(ServerManagedPolicy).optional().default([]),
	Tags: z.array(ServerAwsTagSchema).optional().default([]),
	GroupListPermissions: z.array(ServerAwsIamGroupDetailsSchema).optional().default([]),
})

export type ServerAwsIamUserPermission = z.infer<typeof ServerAwsIamUserPermissionXcSchema>
