import { useIdentitiesContext } from '../context/IdentitiesProvider.tsx'
import { RoutePaths } from '../../RoutePaths.tsx'
import { ServerIdentitiesTableRow } from '../../../schemas/identity.ts'
import { FilterChip } from '../../../components/common/FilterChip/FilterChip.tsx'
import { FilterChipValue } from '../../../components/common/FilterChip/FilterChipValue.tsx'
import { TableFilterEntry } from '../../../schemas/tableFilter.ts'
import { useNavigate } from '@tanstack/react-router'
import { isEmpty } from 'lodash'
import { NumericFilterChip } from '../../../components/common/FilterChip/NumericFilterChip.tsx'
import { ReactNode } from 'react'
import { DateFilterChip } from '../../../components/common/FilterChip/DateFilterChip.tsx'
import { TextFilterChip } from '../../../components/common/FilterChip/TextFilterChip.tsx'

type InventoryFilterChipProps = {
	field: string
	filter: TableFilterEntry
}

export const InventoryFilterChip = ({ field, filter }: InventoryFilterChipProps) => {
	const { gridRef } = useIdentitiesContext()
	const navigate = useNavigate({ from: RoutePaths.Identities })
	if (!gridRef.current) {
		return null
	}

	const column = gridRef.current.api.getColumn<ServerIdentitiesTableRow>(field)
	if (!column) {
		return null
	}

	const removeFilter = (fieldToRemove: string) => {
		void navigate({
			search: (prevSearch) => {
				const newIdentityFilter = { ...prevSearch.identityFilter }
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete newIdentityFilter[fieldToRemove]
				const newSearch = { ...prevSearch }
				if (isEmpty(newIdentityFilter)) {
					delete newSearch.identityFilter
				} else {
					newSearch.identityFilter = newIdentityFilter
				}

				return newSearch
			},
		})
	}

	// TODO: Swap these for real display components for every type
	let component: ReactNode
	if (filter.filterType === 'text') {
		component = <TextFilterChip filter={filter} />
	} else if (filter.filterType === 'number') {
		component = <NumericFilterChip filter={filter} />
	} else if (filter.filterType === 'date') {
		component = <DateFilterChip filter={filter} />
	} else {
		// This is a "set" filter type
		component = <FilterChipValue value={filter.values.join(', ')} />
	}

	const filterName = column.getColDef().headerName
	return (
		<FilterChip label={filterName ?? field} labelKey={field} onRemove={removeFilter}>
			{component}
		</FilterChip>
	)
}
