import React, { useEffect, useMemo, useState } from 'react'
import { Menu, MenuProps, Skeleton } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import { IssueStatGroup } from '../../schemas/issue.ts'
import { createSideBarFolder } from '../../schemas/sideBarMenu'
import { Tag, TagsDisplayNameMap } from '../../schemas/tags.ts'
import { useIssueSearch } from '../../api/issues.ts'
import { useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../routes/RoutePaths.tsx'
import { FindingMenuItemLabel } from '../../routes/Findings/FindingMenuItemLabel.tsx'
import { countIssueStats } from '../endpoints/utils.ts'

import { itdrFindingsNames } from './idtrFindings.ts'
import { IssuesPageLensMap } from '../../routes/Findings/IssuesPageLensMap.tsx'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
	label: React.ReactNode,
	key: React.Key,
	icon?: React.ReactNode,
	children?: MenuItem[],
	type?: 'group',
): MenuItem {
	return {
		key,
		icon,
		children,
		label,
		type,
	} as MenuItem
}

export const DemoItdrFindingsSideBarMenu: React.FC<{
	onFindingsSideBarMenuClick: (e: MenuInfo) => void
}> = ({ onFindingsSideBarMenuClick }) => {
	const onClick = (e: MenuInfo) => {
		onFindingsSideBarMenuClick(e)
	}
	const {
		issueName: searchIssueName,
		mostWanted: searchMostWanted,
		nonHuman: searchNonHuman,
		issueTag: searchIssueTag,
		human: searchHuman,
		cloudProvider: searchCloudProvider,
		identityProvider: searchIdentityProvider,
		myIssues: searchMyIssues,
		relatedTickets: searchRelatedTickets,
	} = useSearch({ from: RoutePaths.Itdr })
	const [items, setItems] = useState([] as MenuItem[])
	const { data: issuesData, isLoading: isIssuesStatsLoading } = useIssueSearch({
		issueName: 'All',
	})
	const issuesStatsData = useMemo(() => {
		if (!issuesData) return []
		return countIssueStats(
			issuesData.filter((issue) => {
				return issue.issue_name && itdrFindingsNames.includes(issue.issue_name)
			}),
		)
	}, [issuesData])
	useEffect(() => {
		if (!issuesStatsData) {
			return
		}
		const allIssuesFindingsFolder = issuesStatsData.filter(
			(issueStat) => issueStat.group === IssueStatGroup.Findings,
		)
		const AllNonHumanIssuesFolder = issuesStatsData.filter(
			(issueStat) => issueStat.group === IssueStatGroup.nonHuman,
		)
		const AllHumanIssuesFolder = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.human)
		const AllSideBar = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.AllCount)
		const AllCloudProviders = issuesStatsData.filter(
			(issueStat) => issueStat.group === IssueStatGroup.cloudProvider,
		)
		const AllIdentityProviders = issuesStatsData.filter(
			(issueStat) => issueStat.group === IssueStatGroup.identityProvider,
		)

		const MyIssuesSideBar = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.MyIssues)
		const relatedTickets = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.RelatedTickets)

		const allItems = []

		allItems.push({
			label: (
				<div className="flex flex-row justify-between">
					<div className="truncate">{AllSideBar[0]?.issue_name || 'All'}</div>
					<div>({AllSideBar[0]?.count || 0})</div>
				</div>
			),
			key: createSideBarFolder(IssueStatGroup.AllCount, ''),
		})
		allItems.push({ type: 'divider' })
		allItems.push({
			label: (
				<div className="flex flex-row justify-between">
					<div className="truncate">Most Wanted</div>
				</div>
			),
			key: createSideBarFolder(IssueStatGroup.mostWanted, ''),
		})
		allItems.push({
			label: (
				<div className="flex flex-row justify-between">
					<div className="truncate">My Issues</div>
					<div>({MyIssuesSideBar[0]?.count || 0})</div>
				</div>
			),
			key: createSideBarFolder(IssueStatGroup.MyIssues, ''),
		})

		relatedTickets.forEach((relatedTicket) => {
			allItems.push({
				label: (
					<div className="flex flex-row justify-between">
						<div className="truncate">Has {relatedTicket.issue_name} tickets</div>
					</div>
				),
				key: createSideBarFolder(IssueStatGroup.RelatedTickets, ''),
			})
		})
		allItems.push({ type: 'divider' })
		allItems.push(
			getItem(
				<div className="flex justify-between">
					<div>Tags</div>
				</div>,
				IssueStatGroup.tag,
				null,
				Object.keys(TagsDisplayNameMap).map((tag) => ({
					key: createSideBarFolder(IssueStatGroup.tag, tag),
					label: (
						<div className="flex justify-between">
							<div>{TagsDisplayNameMap[tag as Tag]}</div>
						</div>
					),
					style: {
						paddingLeft: '32px',
					},
				})),
			),
		)
		if (AllCloudProviders.length > 0) {
			allItems.push({ type: 'divider' })
			allItems.push(
				getItem(
					<div className="flex justify-between">
						<div>Cloud Providers</div>
					</div>,
					IssueStatGroup.cloudProvider,
					null,
					AllCloudProviders.map((item) => ({
						key: createSideBarFolder(IssueStatGroup.cloudProvider, item.issue_name),
						label: (
							<div className="flex justify-between">
								<div>{IssuesPageLensMap[item.issue_name as keyof typeof IssuesPageLensMap].title}</div>
								<div>({item.count})</div>
							</div>
						),
						style: {
							paddingLeft: '32px',
						},
					})),
				),
			)
		}
		if (AllIdentityProviders.length > 0) {
			allItems.push({ type: 'divider' })
			allItems.push(
				getItem(
					<div className="flex justify-between">
						<div>Identity Providers</div>
					</div>,
					IssueStatGroup.identityProvider,
					null,
					AllIdentityProviders.map((item) => ({
						key: createSideBarFolder(IssueStatGroup.identityProvider, item.issue_name),
						label: (
							<div className="flex justify-between">
								<div>{IssuesPageLensMap[item.issue_name as keyof typeof IssuesPageLensMap].title}</div>
								<div>({item.count})</div>
							</div>
						),
						style: {
							paddingLeft: '32px',
						},
					})),
				),
			)
		}
		if (AllNonHumanIssuesFolder.length > 0) {
			allItems.push({ type: 'divider' })
			allItems.push(
				getItem(
					<div className="flex justify-between">
						<div>Non-Human Findings</div>
					</div>,
					IssueStatGroup.nonHuman,
					null,
					AllNonHumanIssuesFolder.map((item) => ({
						key: createSideBarFolder(IssueStatGroup.nonHuman, item.issue_name),
						label: <FindingMenuItemLabel item={item} />,
						style: {
							paddingLeft: '4px',
						},
					})),
				),
			)
		}
		if (AllHumanIssuesFolder.length > 0) {
			allItems.push({ type: 'divider' })
			allItems.push(
				getItem(
					<div className="flex justify-between">
						<div>Human Findings</div>
					</div>,
					IssueStatGroup.human,
					null,
					AllHumanIssuesFolder.map((item) => ({
						key: createSideBarFolder(IssueStatGroup.human, item.issue_name),
						label: <FindingMenuItemLabel item={item} />,
						style: {
							paddingLeft: '4px',
						},
					})),
				),
			)
		}
		allItems.push({ type: 'divider' })
		allItems.push(
			getItem(
				<div className="flex justify-between">
					<div>All Findings</div>
				</div>,
				IssueStatGroup.Findings,
				null,
				allIssuesFindingsFolder.map((item) => ({
					key: createSideBarFolder(IssueStatGroup.Findings, item.issue_name),
					label: <FindingMenuItemLabel item={item} />,
					style: {
						paddingLeft: '4px',
					},
				})),
			),
		)

		setItems(allItems as MenuItem[])
	}, [issuesStatsData])

	const menuStyle: React.CSSProperties = {
		backgroundColor: '#353535',
		color: '#BABBBF',
		border: '1px 0px 1px 1px solid #6A6A73',
		boxShadow: '0px 0px 10px 0px #0000001A',
	}
	const defaultSelectedKeys = () => {
		if (searchMostWanted) {
			return createSideBarFolder(IssueStatGroup.mostWanted, '')
		}
		if (searchMyIssues) {
			return createSideBarFolder(IssueStatGroup.MyIssues, '')
		}
		if (searchRelatedTickets) {
			return createSideBarFolder(IssueStatGroup.RelatedTickets, '')
		}
		if (searchNonHuman) {
			return createSideBarFolder(IssueStatGroup.nonHuman, searchIssueName ?? '')
		}
		if (searchHuman) {
			return createSideBarFolder(IssueStatGroup.human, searchIssueName ?? '')
		}
		if (searchIssueTag) {
			return createSideBarFolder(IssueStatGroup.tag, searchIssueTag)
		}
		if (searchCloudProvider) {
			return createSideBarFolder(IssueStatGroup.cloudProvider, searchCloudProvider ?? '')
		}
		if (searchIdentityProvider) {
			return createSideBarFolder(IssueStatGroup.identityProvider, searchIdentityProvider ?? '')
		}
		if (searchIssueName) {
			if (searchIssueName === 'All') {
				return createSideBarFolder(IssueStatGroup.AllCount, '')
			}
		}
		return createSideBarFolder(IssueStatGroup.Findings, searchIssueName ?? '')
	}

	return isIssuesStatsLoading ? (
		<Skeleton active />
	) : (
		<Menu
			onClick={onClick}
			style={menuStyle}
			defaultSelectedKeys={[defaultSelectedKeys().toString()]}
			mode="inline"
			theme={'dark'}
			items={items}
		/>
	)
}
