import { BareIdentityGraphNodeType, BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { AdGroupNodeType } from './AdGroupNode'
import { Edge } from '@xyflow/react'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { IdentitySource, ServerIdentity } from '../../../../schemas/identity.ts'
import { ResourceType } from '../../../../schemas/issue.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import EntraIdIcon from '../../../../assets/entra_id_icon_16.svg?react'
import { uniqBy } from 'lodash'
import { DemoAdEndpointsNodeType } from './DemoAdEndpointsNode.tsx'

const nodeLogicalTypeToColumnId = {
	endpoints: 0,
	generalIssue: 1,
	identity: 2,
	federation: 3,
	adGroup: 4,
}

const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`
const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const federationNodeRowIndex = 0
const federationNodeId = `${nodeLogicalTypeToColumnId.federation}-${federationNodeRowIndex}`

export const getActiveDirectoryNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<BareIdentityGraphNodeType | BareNodeType<OwnershipNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	// Add ownership node if ownership records exist
	const ownershipRecords = identity.active_directory_user?.demo_ownership || []
	if (ownershipRecords.length > 0) {
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: uniqBy(ownershipRecords, 'object_identifier').map((record) => ({
					id: record.object_identifier,
					name: record.sam_account_name,
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	// Add Federation relationship if Entra ID user exists
	if (identity.active_directory_user?.entra_id_user) {
		const entraIdUser = identity.active_directory_user.entra_id_user
		identityNodes.push({
			type: 'federation',
			data: {
				identityId: entraIdUser.identity_id ?? '',
				name: entraIdUser.user_principal_name ?? 'Unknown User',
				Icon: EntraIdIcon,
				resourceType: ResourceType.ENTRA_ID,
				identitySource: IdentitySource.ENTRA_ID_USER,
			},
			id: federationNodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: federationNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []

	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})

	// Create AD Group nodes from group memberships
	const adGroupNodes: Array<BareNodeType<AdGroupNodeType>> = []
	identity.active_directory_user?.group_memberships?.forEach((membership, index) => {
		adGroupNodes.push({
			type: 'adGroup',
			data: { group: membership.group_data },
			id: `${nodeLogicalTypeToColumnId.adGroup}-${index}`,
		})
	})

	// Create Endpoints nodes
	const endpointsNodes: Array<BareNodeType<DemoAdEndpointsNodeType>> = []
	const endpoints = identity.active_directory_user?.demo_endpoints_dependencies || []
	if (endpoints.length > 0) {
		endpointsNodes.push({
			type: 'demoAdEndpoints',
			data: { endpoints },
			id: `${nodeLogicalTypeToColumnId.endpoints}-0`,
		})
	}

	// Add edges for endpoints
	endpointsNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.endpoints}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	// Add edges for general issues
	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	// Add edges for AD groups
	adGroupNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.adGroup}-${index}`
		edges.push(
			getEdge({
				source: identityNodeId,
				target,
				sourceHandle: 'right',
				targetHandle: 'left',
			}),
		)
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'top', nodes: endpointsNodes },
		{ yPosition: 'center', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: adGroupNodes },
	]

	return [nodes, edges]
}
