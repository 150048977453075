export const circleStyle = {
	border: '1px solid #FFFFFF',
	boxShadow: '0px 2px 4px 0px #0000001A',
	height: '10px',
	width: '10px',
	borderRadius: '50%',
}

export const numberWithCommas = (x?: number | null) => {
	if (!x) {
		return 0
	}
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export enum FindingsDistributionSegment {
	IssueType = 'Issue Type',
	Environment = 'Environment',
}

export enum MetricsDescription {
	FindingsDistributionTreeMap = `The tree map chart displays findings count by name with each rectangle's size indicating the number of findings. Colors show the highest priority among findings of that name.`,
	ResolvedVsUnresolvedChart = `The chart displays the number of resolved (Open, In progress) and unresolved issues (Resolved, Ignored) over time`,
}
