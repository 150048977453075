import React from 'react'
import { Tag, TagsDisplayNameMap } from '../../../schemas/tags.ts'
import AdminTagIcon from '../../../assets/identityTagIcons/admin_tag_icon_16.svg?react'
import CredentialsExposureTagIcon from '../../../assets/identityTagIcons/credentials_exposure_tag_icon_16.svg?react'
import ResourceExposureTagIcon from '../../../assets/identityTagIcons/resource_exposure_tag_icon_16.svg?react'
import DataAccessTagIcon from '../../../assets/identityTagIcons/data_access_tag_icon_16.svg?react'
import PrivilegeEscalationTagIcon from '../../../assets/identityTagIcons/privilege_escalation_tag_icon_16.svg?react'

export const tagsIconsMap: Record<Tag, { icon: React.ComponentType<React.SVGProps<SVGSVGElement>>; title: string }> = {
	[Tag.ADMIN]: { icon: AdminTagIcon, title: TagsDisplayNameMap.ADMIN },
	[Tag.CREDENTIAL_EXPOSURE]: { icon: CredentialsExposureTagIcon, title: TagsDisplayNameMap.CREDENTIAL_EXPOSURE },
	[Tag.RESOURCE_EXPOSURE]: { icon: ResourceExposureTagIcon, title: TagsDisplayNameMap.RESOURCE_EXPOSURE },
	[Tag.DATA_ACCESS]: { icon: DataAccessTagIcon, title: TagsDisplayNameMap.DATA_ACCESS },
	[Tag.POSSIBLE_PRIVILEGE_ESCALATION]: {
		icon: PrivilegeEscalationTagIcon,
		title: TagsDisplayNameMap.POSSIBLE_PRIVILEGE_ESCALATION,
	},
}
