import { z } from 'zod'
import { DateSchema } from '../common'
import { ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { ServerAwsAccountSchema } from '../environments/awsAccountSchema.ts'
import { awsEc2InstanceKeyPairSchema } from './awsEc2InstanceKeyPairSchema.ts'

export const ServerAwsEc2InstanceSchema = z.object({
	name: z.string(),
	created_at: DateSchema,
	updated_at: DateSchema.nullish(),
	last_activity: DateSchema.nullish(),
	instance_id: z.string(),
	aws_iam_role_details_xc: ServerAwsIamRoleXcSchema.nullish(),
	aws_account: ServerAwsAccountSchema.nullish(),
	region: z.string(),
	state: z.string().nullish(),
	instance_type: z.string().nullish(),
	key_pair: awsEc2InstanceKeyPairSchema.nullish(),
})

export type ServerAwsEc2Instance = z.infer<typeof ServerAwsEc2InstanceSchema>
