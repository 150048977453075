import { Tooltip } from 'antd'
import { IdentitySource, IdentitySourceMap } from '../../../schemas/identity'
import GcpIcon from '../../../assets/issueSourcesLogos/gcp_source_logo_28.svg?react'
import AwsIcon from '../../../assets/aws_border_icon.svg?react'
import OktaIcon from '../../../assets/okta_border_icon.svg?react'
import EntraIdIcon from '../../../assets/entra_id_icon_20_with_circul.svg?react'
import GoogleWorkspaceIcon from '../../../assets/google_workspace_border_icon.svg?react'

export const ContextPanelHeader: React.FunctionComponent<{ source: IdentitySource }> = ({ source }) => {
	let icon
	switch (source) {
		case IdentitySource.GCP_SERVICE_ACCOUNT:
			icon = (
				<Tooltip placement="topLeft" title="GCP">
					<GcpIcon style={{ minWidth: 40, minHeight: 40 }} />
				</Tooltip>
			)
			break
		case IdentitySource.AWS_IAM_USER:
		case IdentitySource.AWS_IAM_ROLE:
			icon = (
				<Tooltip placement="topLeft" title="AWS">
					<AwsIcon style={{ minWidth: 40, minHeight: 40 }} />
				</Tooltip>
			)
			break
		case IdentitySource.OKTA:
			icon = (
				<Tooltip placement="topLeft" title="OKTA">
					<OktaIcon style={{ minWidth: 40, minHeight: 40 }} />
				</Tooltip>
			)
			break
		case IdentitySource.ENTRA_ID_USER:
			icon = (
				<Tooltip placement="topLeft" title="Entra ID">
					<EntraIdIcon style={{ minWidth: 40, minHeight: 40 }} />
				</Tooltip>
			)
			break
		case IdentitySource.GOOGLE_WORKSPACE:
			icon = (
				<Tooltip placement="topLeft" title="GOOGLE WORKSPACE">
					<GoogleWorkspaceIcon style={{ minWidth: 40, minHeight: 40 }} />
				</Tooltip>
			)
			break
	}
	const sourceName: string = IdentitySourceMap[source]
	return (
		<div className="flex items-center">
			{icon}
			<div className="ml-2 font-medium">{sourceName}</div>
		</div>
	)
}
