import React, { useState } from 'react'
import { Segmented } from 'antd'
import { SegmentedValue } from 'rc-segmented'
import { IdentitiesByRiskChart } from '../../charts/IdentitiesByRiskChart.tsx'
import { IdentitiesByEnvironmentChartV2 } from '../../charts/IdentitiesByEnvironmentChartV2.tsx'
import { MetricsChartContainer } from './MetricsChartContainer.tsx'

type SegmentOption = 'Environment' | 'Risk'

export const MonitoredIdentitiesChartV2: React.FunctionComponent = () => {
	const [selectedSegment, setSelectedSegment] = useState<SegmentOption>('Environment')
	const onChange = (value: SegmentedValue) => {
		setSelectedSegment(value as SegmentOption)
	}
	const options = [
		{
			label: (
				<div className="p-1">
					<div>By Environment</div>
				</div>
			),
			value: 'Environment',
		},
		{
			label: (
				<div className="p-1">
					<div>By Risk</div>
				</div>
			),
			value: 'Risk',
		},
	]
	return (
		<MetricsChartContainer
			className="col-span-6"
			header={
				<>
					<span>Monitored Identities</span>
					<Segmented size="small" onChange={onChange} value={selectedSegment} options={options} />
				</>
			}
		>
			{selectedSegment === 'Environment' && <IdentitiesByEnvironmentChartV2 />}
			{selectedSegment === 'Risk' && <IdentitiesByRiskChart />}
		</MetricsChartContainer>
	)
}
