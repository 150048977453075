import React, { useCallback, useEffect, useState } from 'react'
import { Badge } from 'antd'
import { AgGridReact } from '@ag-grid-community/react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { ValueGetterFunc, ValueGetterParams } from '@ag-grid-community/core'
import { ServerIssue } from '../../schemas/issue.ts'
import { RoutePaths } from '../../routes/RoutePaths.tsx'
import { IssueSourceIcon } from '../../components/common/IssueSourceIcon.tsx'

export const DemoItdrIssueSources: React.FunctionComponent<{
	issuesData?: ServerIssue[]
	gridRef: React.RefObject<AgGridReact | null>
	filterChanged: { value: boolean }
	gridReady: boolean
}> = ({ issuesData, gridRef, filterChanged, gridReady }) => {
	const navigate = useNavigate({ from: RoutePaths.Itdr })
	const { issueFilter: searchIssueFilter, environment: searchEnvironment } = useSearch({ from: RoutePaths.Itdr })
	const [issueSources, setIssueSources] = useState<Record<string, number>>({})

	useEffect(() => {
		// this useEffect is to update the issueSources count when the filter is changed
		if (!gridReady || !filterChanged?.value || !gridRef?.current?.api) return // Early return if grid is not ready
		const acc: Record<string, number> = {}
		issuesData?.forEach((issue) => {
			const source = issue?.identity?.env_type
			if (!source) return
			if (acc[source] === undefined) {
				acc[source] = 0
			}
		})
		for (let i = 0; i < gridRef.current.api.getDisplayedRowCount(); i++) {
			const issue = gridRef?.current.api.getDisplayedRowAtIndex(i)?.data as ServerIssue
			const source = issue?.identity?.env_type
			if (source) {
				acc[source] = (acc[source] ?? 0) + 1
			}
		}
		setIssueSources(acc)
	}, [filterChanged])

	const handleClick = useCallback(
		// this function is to handle the click event on the issue source icon
		(key: string) => {
			// check if the selected environment is the same as the current searchEnvironment
			if (searchEnvironment === key) {
				// if it is the same, then remove the environment from the search
				const currentFilter = searchIssueFilter ?? {}
				delete currentFilter['identity.account_literal']
				gridRef?.current?.api?.setFilterModel(currentFilter)
				navigate({
					search: (prev) => ({ ...prev, environment: undefined, issueFilter: currentFilter }),
				})
				return
			} else {
				navigate({
					search: (prev) => ({ ...prev, environment: key, issueFilter: undefined }),
				})
			}
		},
		[searchEnvironment],
	)
	useEffect(() => {
		// this useEffect is to navigate to the selected environment when the searchEnvironment changes
		if (!gridReady) return
		if (searchEnvironment) {
			// go through all the issues data to find the first issue with the selected environment
			const valueGetter: ValueGetterFunc | undefined = gridRef?.current?.api?.getColumnDef(
				'identity.account_literal',
			)?.valueGetter as ValueGetterFunc | undefined
			if (!valueGetter) return // Early return if valueGetter is undefined
			const displayedValues: Set<string> = new Set<string>()
			issuesData?.forEach((issue) => {
				const env = issue?.identity?.env_type?.toString()
				if (env === searchEnvironment) {
					displayedValues.add(valueGetter({ data: issue } as ValueGetterParams) as string)
				}
			}, [])
			// navigate and update the issueFilter to the selected environment
			const currentFilter = searchIssueFilter ?? {}
			currentFilter['identity.account_literal'] = {
				filterType: 'set',
				values: Array.from(displayedValues.values()),
			}
			gridRef?.current?.api?.setFilterModel(currentFilter)
			navigate({
				search: (prev) => ({
					...prev,
					issueFilter: currentFilter,
				}),
			})
		}
	}, [searchEnvironment])
	return (
		<>
			<div className="m-4 text-base text-gray-400">Environments</div>
			{issuesData && issuesData.length > 0 ? (
				<div className="h-18 items-start flex space-x-1">
					{Object.entries(issueSources ?? {}).map(([key, value]) => (
						<div className="cursor-pointer" key={key} onClick={() => handleClick(key)}>
							<Badge
								offset={[-10, 10]}
								style={{
									color: '#85858C',
									background:
										'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7))',
								}}
								showZero={true}
								count={value}
								key={key}
							>
								<div
									className="rounded-full cursor-pointer"
									style={{
										border: `2px solid ${searchEnvironment === key ? '#74A690' : 'transparent'}`,
									}}
								>
									<IssueSourceIcon source={key} size={24} />
								</div>
							</Badge>
						</div>
					))}
				</div>
			) : (
				<div className="m-4 text-base text-gray-400">N/A</div>
			)}
		</>
	)
}
