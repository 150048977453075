import { IssueImpactProbabilityWithCount, PriorityType } from '../../../schemas/issue.ts'
import { Button, Input, notification, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import { IssueTypesTable } from './IssueTypesTable.tsx'
import { IssueConfigConfirmationModal } from './IssueConfigConfirmationModal.tsx'
import { useIssueTypes, useUpdateIssueTypes } from '../../../api/issues.ts'
import { keyBy } from 'lodash'

export const IssueConfig = () => {
	const [searchText, setSearchText] = useState('')
	const [isEditing, setIsEditing] = useState(false)
	const [issueTypes, setIssueTypes] = useState<IssueImpactProbabilityWithCount[]>([])
	const [changedIssueTypeIds, setChangedIssueTypeIds] = useState(new Set<string>())
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [notificationApi, contextHolder] = notification.useNotification()
	const {
		data: serverIssueTypes,
		isLoading: isLoadingIssueTypes,
		isError: isErrorIssueTypes,
		isFetching: isFetchingIssueTypes,
	} = useIssueTypes()
	const { mutate: mutateIssueTypes, isLoading: isUpdatingIssueTypes } = useUpdateIssueTypes()

	useEffect(() => {
		if (serverIssueTypes) {
			setIssueTypes([...serverIssueTypes])
		}
	}, [serverIssueTypes])

	useEffect(() => {
		if (!serverIssueTypes) {
			return
		}

		const serverIssueTypesById = keyBy(serverIssueTypes, 'id')
		const newChangedIssueTypeIds = new Set<string>()
		issueTypes.forEach((issueType) => {
			if (
				issueType.ignore_issue !== serverIssueTypesById[issueType.id].ignore_issue ||
				issueType.priority !== serverIssueTypesById[issueType.id].priority
			) {
				newChangedIssueTypeIds.add(issueType.id)
			}
		})

		setChangedIssueTypeIds(newChangedIssueTypeIds)
	}, [issueTypes])

	if (isLoadingIssueTypes) {
		return <Skeleton active />
	}

	if (isErrorIssueTypes) {
		return <div className="flex justify-center items-center h-screen">Could not load data, please try again</div>
	}

	const onClickEditButton = () => {
		if (!isEditing) {
			setIsEditing(true)
		} else {
			setIsModalOpen(true)
		}
	}

	const onToggleIssueType = (issueType: IssueImpactProbabilityWithCount) => {
		setIssueTypes((currentIssueTypes) =>
			currentIssueTypes.map((currentIssueType) => {
				if (currentIssueType.id === issueType.id) {
					// eslint-disable-next-line camelcase
					return { ...currentIssueType, ignore_issue: !currentIssueType.ignore_issue }
				}

				return currentIssueType
			}),
		)
	}

	const onChangeIssueTypePriority = (
		issueType: IssueImpactProbabilityWithCount,
		newPriority: PriorityType | null,
	) => {
		setIssueTypes((currentIssueTypes) =>
			currentIssueTypes.map((currentIssueType) => {
				if (currentIssueType.id === issueType.id) {
					return { ...currentIssueType, priority: newPriority }
				}

				return currentIssueType
			}),
		)
	}

	const onClickCancel = () => {
		setIsEditing(false)
		if (serverIssueTypes) {
			setIssueTypes(serverIssueTypes)
		}
	}

	const onSuccess = () => {
		notificationApi.success({ message: 'Successfully updated issue configuration' })
		setIsModalOpen(false)
	}

	const onError = () => {
		notificationApi.error({
			message: 'Error',
			description: 'Could not update issue configuration. Please try again.',
			duration: 0,
		})
		setIsModalOpen(false)
	}

	const onSaveConfirm = () => {
		void mutateIssueTypes(
			issueTypes.filter((issueType) => changedIssueTypeIds.has(issueType.id)),
			{
				onSuccess,
				onError,
			},
		)
	}

	return (
		<div className="flex flex-col gap-2 h-full">
			{contextHolder}
			<div className="flex justify-between items-center">
				<span className="text-lg text-neutral-800">Issue Configuration</span>
				<div className="flex gap-2">
					<Input
						value={searchText}
						size="small"
						onChange={(e) => {
							setSearchText(e.target.value)
						}}
						allowClear={true}
						placeholder="Search"
						className="mr-4"
						prefix={<SearchOutlined />}
					/>
					{isEditing && (
						<Button onClick={onClickCancel} loading={isUpdatingIssueTypes || isFetchingIssueTypes}>
							Cancel
						</Button>
					)}
					<Button
						disabled={isEditing && !changedIssueTypeIds.size}
						onClick={onClickEditButton}
						loading={isUpdatingIssueTypes || isFetchingIssueTypes}
						type="primary"
						className="bg-black"
						icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
					>
						{isEditing ? 'Apply' : 'Edit'}
					</Button>
				</div>
			</div>
			<span className="text-md text-neutral-600">
				Configure which issue types are enabled per source environment.
			</span>
			<IssueTypesTable
				isEditing={isEditing}
				isFetching={isFetchingIssueTypes}
				issueTypes={issueTypes}
				searchText={searchText}
				onToggleIssueType={onToggleIssueType}
				onChangeIssueTypePriority={onChangeIssueTypePriority}
			/>
			<IssueConfigConfirmationModal
				isOpen={isModalOpen}
				issueTypes={issueTypes}
				changedIssueTypeIds={changedIssueTypeIds}
				onCancel={() => setIsModalOpen(false)}
				isUpdating={isUpdatingIssueTypes}
				onConfirm={onSaveConfirm}
				serverIssueTypes={serverIssueTypes}
			/>
		</div>
	)
}
