import { PriorityBar } from '../../Findings/stats/PriorityBar.tsx'
import { useCallback, useMemo } from 'react'
import { PriorityType, PriorityTypeEnum, PriorityTypeNameToNumber } from '../../../schemas/issue.ts'
import { useIdentityStats } from '../../../api/identities.ts'
import { RoutePaths } from '../../RoutePaths.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { isEmpty } from 'lodash'
import { TableSetFilterEntry } from '../../../schemas/tableFilter.ts'
import { IdentitiesStatsItem } from './IdentitiesStatsItem.tsx'

type IdentitiesStatsPostureProps = {
	statsQuery: ReturnType<typeof useIdentityStats>
}

export const IdentitiesStatsPosture = ({ statsQuery }: IdentitiesStatsPostureProps) => {
	const { data: identityStats } = statsQuery
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const { identityFilter } = useSearch({ from: RoutePaths.Identities })

	const findingsByPriority = useMemo(() => {
		if (!identityStats) return []
		return [PriorityType.CRITICAL, PriorityType.HIGH, PriorityType.MEDIUM, PriorityType.LOW].map((priority) => {
			let disabled = false
			if (
				identityFilter?.max_priority &&
				(identityFilter.max_priority as TableSetFilterEntry).values.length &&
				!(identityFilter.max_priority as TableSetFilterEntry).values.includes(priority.toString())
			) {
				disabled = true
			}

			return {
				priorityNumber: priority,
				priority: PriorityType[priority] as PriorityTypeEnum,
				count: identityStats.count_by_priority[priority] ?? 0,
				disabled,
			}
		})
	}, [identityStats])

	const filterByPostureScore = useCallback((priority: string) => {
		const priorityString = `${PriorityTypeNameToNumber[priority as PriorityTypeEnum]}`
		void navigate({
			search: (prevSearch) => {
				const newIdentityFilter = { ...prevSearch.identityFilter }
				let currentMaxPriorities =
					(newIdentityFilter?.max_priority as TableSetFilterEntry | undefined)?.values ?? []
				if (currentMaxPriorities.length === 0) {
					currentMaxPriorities.push(priorityString)
				} else if (currentMaxPriorities.length === 1 && currentMaxPriorities.includes(priorityString)) {
					currentMaxPriorities = []
				} else if (currentMaxPriorities.includes(priorityString)) {
					currentMaxPriorities = currentMaxPriorities.filter(
						(currentMaxPriority) => currentMaxPriority !== priorityString,
					)
				} else {
					currentMaxPriorities = [...currentMaxPriorities, priorityString]
				}

				if (isEmpty(currentMaxPriorities)) {
					delete newIdentityFilter.max_priority
				} else {
					// eslint-disable-next-line camelcase
					newIdentityFilter.max_priority = { filterType: 'set', values: currentMaxPriorities }
				}

				const newSearch = { ...prevSearch }
				if (isEmpty(newIdentityFilter)) {
					delete newSearch.identityFilter
				} else {
					newSearch.identityFilter = newIdentityFilter
				}

				return newSearch
			},
		})
	}, [])

	return (
		<IdentitiesStatsItem title="Posture" statsQuery={statsQuery}>
			<PriorityBar
				findingsByPriority={findingsByPriority}
				onPriorityNameTagClick={filterByPostureScore}
				keepZeroValues
			/>
		</IdentitiesStatsItem>
	)
}
