import { Node, NodeProps } from '@xyflow/react'
import { LaptopOutlined } from '@ant-design/icons'
import { BaseDependenciesNode } from '../common/baseNodes/BaseDependenciesNode'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { DemoServerAdEndpoints } from '../../../../schemas/identities/activeDirectory/demoAdEndpointsSchema.ts'

export type DemoAdEndpointsNodeType = Node<{ endpoints: DemoServerAdEndpoints[] }, 'demoAdEndpoints'>
type AdEndpointsNodeProps = NodeProps<DemoAdEndpointsNodeType>

export const DemoAdEndpointsNode = ({ data: { endpoints } }: AdEndpointsNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'demoAdEndpoints')

	return (
		<BaseDependenciesNode
			resources={endpoints}
			isHighlighted={isHighlighted}
			nodeLabel="Endpoints"
			tooltipTitle={`Identity has access to ${endpoints.length} ${endpoints.length === 1 ? 'endpoint' : 'endpoints'}`}
			Icon={LaptopOutlined}
		/>
	)
}
