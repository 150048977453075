import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import { ServerSalesforcePermissionSet } from '../../../../schemas/identities/salesforce/salesforcePermissionSetSchema.ts'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons.tsx'

export type SalesforcePermissionSetNodeType = Node<
	{ permissionSet: ServerSalesforcePermissionSet },
	'salesforcePermissionSet'
>
type SalesforcePermissionSetNodeProps = NodeProps<SalesforcePermissionSetNodeType>

export const SalesforcePermissionSetNode = ({ data: { permissionSet } }: SalesforcePermissionSetNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) =>
			highlightedNode.type === 'salesforcePermissionSet' && highlightedNode.id === permissionSet?.id,
	)

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	return (
		<BaseNode label="Permission Set" name={{ text: permissionSet?.name }}>
			<div className={iconWrapperClassName}>
				<RiskNodeTagIcons tags={permissionSet.tags} />
				<FileTextOutlined className="text-2xl" />
			</div>
		</BaseNode>
	)
}
