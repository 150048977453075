import { z } from 'zod'
import { DateSchema } from '../../common.ts'

export const DemoServerAdOwnershipSchema = z.object({
	object_identifier: z.string(),
	sam_account_name: z.string(),
	domain: z.string(),
	action: z.string(),
	timestamp: DateSchema,
})

export type DemoServerAdOwnership = z.infer<typeof DemoServerAdOwnershipSchema>
