import {
	JiraFetchFieldsResponse,
	JiraFetchIssueTypeResponse,
	JiraFetchLabelsResponse,
	JiraFetchProjectsResponse,
} from '../../jiraIntegration/schemas/jiraApi.ts'
import { JiraIssuePicker, JiraUser } from '../../jiraIntegration/schemas/jiraSchemas.ts'
import {
	IntegrationType,
	ServerIntegration,
	ServerIntegrationTicketInTokenDb,
} from '../../jiraIntegration/schemas/common.ts'

export const integrations: ServerIntegration[] = [
	{
		id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		name: 'my_integration',
		enabled: true,
		integration_type: IntegrationType.JIRA,
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		created_at: '2024-06-02T15:23:45.939000Z',
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		updated_at: '2024-06-02T15:23:51.101000Z',
		data: {
			base_url: 'https://token-demo.atlassian.net',
		},
	},
]

export const projects: JiraFetchProjectsResponse = {
	self: 'https://token-demo.atlassian.net/rest/api/3/project/search?maxResults=50&action=create&startAt=0',
	maxResults: 50,
	startAt: 0,
	total: 9,
	isLast: true,
	values: [
		{
			expand: 'description,lead,issueTypes,url,projectKeys,permissions,insight',
			self: 'https://token-demo.atlassian.net/rest/api/3/project/10003',
			id: '10003',
			key: 'KAN',
			name: 'Kanban',
			avatarUrls: {
				'48x48': 'https://token-demo.atlassian.net/rest/api/3/universal_avatar/view/type/project/avatar/10424',
				'24x24':
					'https://token-demo.atlassian.net/rest/api/3/universal_avatar/view/type/project/avatar/10424?size=small',
				'16x16':
					'https://token-demo.atlassian.net/rest/api/3/universal_avatar/view/type/project/avatar/10424?size=xsmall',
				'32x32':
					'https://token-demo.atlassian.net/rest/api/3/universal_avatar/view/type/project/avatar/10424?size=medium',
			},
			projectTypeKey: 'software',
			simplified: true,
			style: 'next-gen',
			isPrivate: false,
			properties: {},
			entityId: '47b13274-c542-4fbb-98de-c60e5df06330',
			uuid: '47b13274-c542-4fbb-98de-c60e5df06330',
		},
	],
}

export const labels: JiraFetchLabelsResponse = {
	maxResults: 1000,
	startAt: 0,
	total: 12,
	isLast: true,
	values: [
		'content',
		'customer-success',
		'customer-support',
		'design',
		'marketing',
		'product',
		'research',
		'sales',
		'ux',
	],
}

export const assignees: JiraUser[] = [
	{
		self: 'https://token-demo.atlassian.net/rest/api/3/',
		accountId: '712020:167fe1dd-28ec-494a-949f-bbbcba66033a',
		accountType: 'atlassian',
		emailAddress: 'developer@token.security',
		avatarUrls: {
			'48x48':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
			'24x24':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
			'16x16':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
			'32x32':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
		},
		displayName: 'Elizabeth Thomas',
		active: true,
		timeZone: 'Asia/Jerusalem',
		locale: 'en_US',
	},
	{
		self: 'https://token-demo.atlassian.net/rest/api/3/',
		accountId: '712020:167fe1dd-28ec-494a-949f-bbbcba66033b',
		accountType: 'atlassian',
		emailAddress: 'developer@token.security',
		avatarUrls: {
			'48x48':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
			'24x24':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
			'16x16':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
			'32x32':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
		},
		displayName: 'Joseph Rogers',
		active: true,
		timeZone: 'Asia/Jerusalem',
		locale: 'en_US',
	},
	{
		self: 'https://token-demo.atlassian.net/rest/api/3/',
		accountId: '712020:167fe1dd-28ec-494a-949f-bbbcba66033c',
		accountType: 'atlassian',
		emailAddress: 'developer@token.security',
		avatarUrls: {
			'48x48':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
			'24x24':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
			'16x16':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
			'32x32':
				'https://secure.gravatar.com/avatar/af23360299766310126247ec65536f47?d=https%3A%2F%2Favatar-management--avatars.us-west-2.prod.public.atl-paas.net%2Finitials%2FIL-5.png',
		},
		displayName: 'Gabriela Beard',
		active: true,
		timeZone: 'Asia/Jerusalem',
		locale: 'en_US',
	},
]

export const issueTypes: JiraFetchIssueTypeResponse = {
	startAt: 0,
	maxResults: 50,
	total: 5,
	issueTypes: [
		{
			self: 'https://token-demo.atlassian.net/rest/api/3/issuetype/10000',
			id: '10000',
			description:
				'A big user story that needs to be broken down. Created by Jira Software - do not edit or delete.',
			iconUrl: 'https://token-demo.atlassian.net/images/icons/issuetypes/epic.svg',
			name: 'Epic',
			untranslatedName: 'Epic',
			subtask: false,
			hierarchyLevel: 1,
		},
		{
			self: 'https://token-demo.atlassian.net/rest/api/3/issuetype/10002',
			id: '10002',
			description: 'Stories track functionality or features expressed as user goals.',
			iconUrl:
				'https://token-demo.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10300?size=medium',
			name: 'Story',
			untranslatedName: 'Story',
			subtask: false,
			hierarchyLevel: 0,
		},
		{
			self: 'https://token-demo.atlassian.net/rest/api/3/issuetype/10011',
			id: '10011',
			description: 'A small, distinct piece of work.',
			iconUrl:
				'https://token-demo.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
			name: 'Task',
			untranslatedName: 'Task',
			subtask: false,
			hierarchyLevel: 0,
		},
		{
			self: 'https://token-demo.atlassian.net/rest/api/3/issuetype/10012',
			id: '10012',
			description: "A small piece of work that's part of a larger task.",
			iconUrl:
				'https://token-demo.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10316?size=medium',
			name: 'Sub-task',
			untranslatedName: 'Sub-task',
			subtask: true,
			hierarchyLevel: -1,
		},
		{
			self: 'https://token-demo.atlassian.net/rest/api/3/issuetype/10014',
			id: '10014',
			description: 'A problem or error.',
			iconUrl:
				'https://token-demo.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
			name: 'Bug',
			untranslatedName: 'Bug',
			subtask: false,
			hierarchyLevel: 0,
		},
	],
}

export const jiraFields: JiraFetchFieldsResponse = {
	startAt: 0,
	maxResults: 50,
	total: 12,
	fields: [
		{
			required: false,
			schema: {
				type: 'string',
				system: 'description',
			},
			name: 'Description',
			key: 'description',
			hasDefaultValue: false,
			operations: ['set'],
			fieldId: 'description',
		},
		{
			required: true,
			schema: {
				type: 'issuetype',
				system: 'issuetype',
			},
			name: 'Issue Type',
			key: 'issuetype',
			hasDefaultValue: false,
			operations: [],
			allowedValues: [
				{
					self: 'https://token-team-g5y030df.atlassian.net/rest/api/3/issuetype/10011',
					id: '10011',
					description: 'A small, distinct piece of work.',
					iconUrl:
						'https://token-team-g5y030df.atlassian.net/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
					name: 'Task',
					subtask: false,
					avatarId: 10318,
					hierarchyLevel: 0,
				},
			],
			fieldId: 'issuetype',
		},
		{
			required: true,
			schema: {
				type: 'array',
				items: 'string',
				system: 'labels',
			},
			name: 'Labels',
			key: 'labels',
			autoCompleteUrl: 'https://token-team-g5y030df.atlassian.net/rest/api/1.0/labels/suggest?query=',
			hasDefaultValue: false,
			operations: ['add', 'set', 'remove'],
			fieldId: 'labels',
		},
		{
			required: true,
			schema: {
				type: 'project',
				system: 'project',
			},
			name: 'Project',
			key: 'project',
			hasDefaultValue: false,
			operations: ['set'],
			allowedValues: [
				{
					self: 'https://token-team-g5y030df.atlassian.net/rest/api/3/project/10011',
					id: '10011',
					key: 'TPL',
					name: 'test-project-lp',
					projectTypeKey: 'software',
					simplified: false,
					avatarUrls: {
						'48x48':
							'https://token-team-g5y030df.atlassian.net/rest/api/3/universal_avatar/view/type/project/avatar/10414',
						'24x24':
							'https://token-team-g5y030df.atlassian.net/rest/api/3/universal_avatar/view/type/project/avatar/10414?size=small',
						'16x16':
							'https://token-team-g5y030df.atlassian.net/rest/api/3/universal_avatar/view/type/project/avatar/10414?size=xsmall',
						'32x32':
							'https://token-team-g5y030df.atlassian.net/rest/api/3/universal_avatar/view/type/project/avatar/10414?size=medium',
					},
				},
			],
			fieldId: 'project',
		},
		{
			required: true,
			schema: {
				type: 'string',
				system: 'summary',
			},
			name: 'Summary',
			key: 'summary',
			hasDefaultValue: false,
			operations: ['set'],
			fieldId: 'summary',
		},
	],
}

export const createTicketInTokenDbResponse: ServerIntegrationTicketInTokenDb = {
	id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
	integration_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
	customer_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
	issue_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
	// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
	created_at: '2024-06-02T15:23:45.939000Z',
	// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
	updated_at: '2024-06-02T15:23:51.101000Z',
	data: { id: '10003', key: 'KAN-6', self: 'https://token-demo.atlassian.net/rest/api/3/issue/10003' },
}

export const gabrielaBeardPartiallyOffBoardedUsersJiraTicket: ServerIntegrationTicketInTokenDb[] = [
	{
		id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		customer_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		integration_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		issue_id: '66f6d8ca-f7e3-4f20-9700-0e496338ed1c',
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		created_at: '2024-06-02T15:23:45.939000Z',
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		updated_at: '2024-06-02T15:23:51.101000Z',
		data: { id: '10004', key: 'SCRUM-10', self: 'https://token-demo.atlassian.net/rest/api/3/issue/10004' },
	},
]

export const gabrielaBeardAccessKeyNotRotated: ServerIntegrationTicketInTokenDb[] = [
	{
		id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		customer_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		integration_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		issue_id: '2bd66f71-2c42-4e14-985b-34218a1f9c3d',
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		created_at: '2024-06-02T15:23:45.939000Z',
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		updated_at: '2024-06-02T15:23:51.101000Z',
		data: { id: '10003', key: 'KAN-6', self: 'https://token-demo.atlassian.net/rest/api/3/issue/10003' },
	},
]

export const devAppTestingMissingNetworkPolicyJiraTicket: ServerIntegrationTicketInTokenDb[] = [
	{
		id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		customer_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		integration_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		issue_id: '3a4832ce-e00e-4f34-b54a-e9e1a88e1a81',
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		created_at: '2024-06-02T15:23:45.939000Z',
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		updated_at: '2024-06-02T15:23:51.101000Z',
		data: { id: '10005', key: 'KAN-8', self: 'https://token-demo.atlassian.net/rest/api/3/issue/10005' },
	},
]

export const productionEksUserAccessKeyReferencedInPodJiraTicket: ServerIntegrationTicketInTokenDb[] = [
	{
		id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		customer_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		integration_id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
		issue_id: '6d2d68c3-fd0e-47bd-b73d-103ff19e2a66',
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		created_at: '2024-06-02T15:23:45.939000Z',
		// @ts-expect-error - The ServerAwsAccountSchema expects a DateType but its convert string to DateType
		updated_at: '2024-06-02T15:23:51.101000Z',
		data: { id: '10006', key: 'KAN-11', self: 'https://token-demo.atlassian.net/rest/api/3/issue/10006' },
	},
]

export const jiraIssuesPerProject: JiraIssuePicker = {
	sections: [
		{
			id: 'e9a44444-2aa6-4e87-a0a8-0bd702faff13',
			label: 'History Search',
			issues: [
				{
					id: 1,
					key: 'KAN-1',
					img: 'demo',
					keyHtml: 'demo',
					summary: 'Remediate identity issue',
					summaryText: 'Remediate identity issue',
				},
				{
					id: 2,
					key: 'KAN-2',
					img: 'demo',
					keyHtml: 'demo',
					summary: 'Fix broken machine identity',
					summaryText: 'Fix broken machine identity',
				},
			],
		},
	],
}
