import { Select } from 'antd'
import React from 'react'
import { AccountPriorityEnum } from '../../../schemas/environments/accountPriorityEnum.ts'
import { AccountPrioritySelectOption } from './AccountPrioritySelectOption.tsx'

type AccountPriorityOptionType = { value: AccountPriorityEnum; label: React.ReactNode; disabled?: boolean }
const selectAccountPriorityOptions: AccountPriorityOptionType[] = [
	{
		value: AccountPriorityEnum.CRITICAL,
		label: <AccountPrioritySelectOption priority={AccountPriorityEnum.CRITICAL} />,
	},
	{
		value: AccountPriorityEnum.HIGH,
		label: <AccountPrioritySelectOption priority={AccountPriorityEnum.HIGH} />,
	},
	{
		value: AccountPriorityEnum.MEDIUM,
		label: <AccountPrioritySelectOption priority={AccountPriorityEnum.MEDIUM} />,
	},
	{
		value: AccountPriorityEnum.UNKNOWN,
		label: <AccountPrioritySelectOption priority={AccountPriorityEnum.UNKNOWN} />,
	},
	{
		value: AccountPriorityEnum.LOW,
		label: <AccountPrioritySelectOption priority={AccountPriorityEnum.LOW} />,
	},
]

const selectAccountPriorityOptionsWithNone: AccountPriorityOptionType[] = [
	...selectAccountPriorityOptions,
	{
		value: AccountPriorityEnum.NONE,
		label: <AccountPrioritySelectOption priority={AccountPriorityEnum.NONE} />,
		disabled: true,
	},
]

type SelectAccountPriorityProps = {
	priority: AccountPriorityEnum
	onChange: (newPriority: AccountPriorityEnum) => void
	disabled: boolean
	loading: boolean
}

export const SelectAccountPriority = ({ priority, onChange, disabled, loading }: SelectAccountPriorityProps) => (
	<div className="flex items-center h-full">
		{disabled ? (
			<AccountPrioritySelectOption priority={priority} />
		) : (
			<Select
				className="w-[200px]"
				loading={loading}
				onChange={(newPriority: AccountPriorityEnum) => {
					onChange(newPriority)
				}}
				value={priority}
				options={
					priority === AccountPriorityEnum.NONE
						? selectAccountPriorityOptionsWithNone
						: selectAccountPriorityOptions
				}
			/>
		)}
	</div>
)
