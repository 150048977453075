import { api } from './api'
import {
	EnvironmentCreateRequest,
	EnvironmentCreateResponse,
	ServerEnvironmentsSchema,
	ServerEnvironments,
} from '../schemas/environments/environments'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Account, AccountSchema } from '../schemas/environments/accounts'

const environmentsApi = {
	async getAll(): Promise<ServerEnvironments> {
		const { data: serverEnvironments } = await api.get<ServerEnvironments>(`/api/environments`)
		return ServerEnvironmentsSchema.parse(serverEnvironments)
	},
	async create(request: EnvironmentCreateRequest): Promise<EnvironmentCreateResponse> {
		const { data } = await api.post<EnvironmentCreateResponse>('/api/environments', request)
		return data
	},
}

const accountsApi = {
	async getAll(): Promise<Account[]> {
		const { data: serverAccounts } = await api.get<Account[]>(`/api/accounts`)
		return serverAccounts.map((serverAccount) => AccountSchema.parse(serverAccount))
	},
	async updateAccounts(accounts: Account[]): Promise<void> {
		await api.put(`/api/accounts`, accounts)
	},
}

export function useEnvironments() {
	return useQuery({
		queryKey: ['environments'],
		cacheTime: Infinity,
		staleTime: Infinity,
		queryFn: async () => {
			return await environmentsApi.getAll()
		},
	})
}

export function useAccounts() {
	return useQuery({
		queryKey: ['accounts'],
		cacheTime: Infinity,
		staleTime: Infinity,
		queryFn: async () => {
			return await accountsApi.getAll()
		},
	})
}

export function useUpdateAccounts() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: ['updateAccounts'],
		mutationFn: async (accounts: Account[]) => {
			await accountsApi.updateAccounts(accounts)
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: ['accounts'], refetchType: 'active' })
		},
	})
}

export function useCreateEnvironment() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: async (request: EnvironmentCreateRequest) => {
			return await environmentsApi.create(request)
		},
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: ['environments'] })
		},
	})
}
