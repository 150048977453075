import { CSSProperties, forwardRef, HTMLAttributes } from 'react'
import cx from 'classnames'

type NodeBadgeProps = HTMLAttributes<HTMLDivElement> & {
	positionOnLeft?: boolean
}

export const NodeBadge = forwardRef<HTMLDivElement, NodeBadgeProps>(
	({ className, style, children, positionOnLeft, ...rest }, ref) => {
		const badgeNodeClassName = cx(
			'absolute h-[25px] min-w-[25px] w-fit top-[-10px] flex items-center justify-center rounded-full border-2 z-10',
			className,
		)

		const badgeStyle: CSSProperties = {
			left: positionOnLeft ? -10 : 30,
			...style,
		}

		return (
			<div className={badgeNodeClassName} style={badgeStyle} ref={ref} {...rest}>
				{children}
			</div>
		)
	},
)
