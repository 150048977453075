import { Button, Popover } from 'antd'

type OverflowingFiltersProps = {
	children: Array<JSX.Element>
}

export const OverflowingFilters = ({ children }: OverflowingFiltersProps) => {
	if (!children.length) {
		return null
	}

	const popoverContent = (
		<div className="flex flex-col gap-1 max-h-96 max-w-96 overflow-y-auto overflow-x-auto">{children}</div>
	)

	return (
		<Popover placement="bottomRight" content={popoverContent}>
			<Button color="default" variant="filled" icon={`+${children.length}`} />
		</Popover>
	)
}
