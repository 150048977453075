import { EnvironmentType } from '../../schemas/envType.ts'
import { PriorityTypeEnum } from '../../schemas/issue.ts'
import { subHours } from 'date-fns'

export const summaryData = {
	total_identities: 20644,
	total_environments: 9,
	last_scan: subHours(new Date(), 3).toISOString(),
	time_to_resolve: 'P6DT51690.232296S',
}

export const resolvedVsUnresolvedFindingsArrayData = [
	{
		resolved: 13,
		unresolved: 231,
		date: '2024-03-05T00:00:00',
	},
	{
		resolved: 15,
		unresolved: 231,
		date: '2024-03-06T00:00:00',
	},
	{
		resolved: 17,
		unresolved: 229,
		date: '2024-03-07T00:00:00',
	},
	{
		resolved: 17,
		unresolved: 229,
		date: '2024-03-08T00:00:00',
	},
	{
		resolved: 24,
		unresolved: 225,
		date: '2024-03-09T00:00:00',
	},
	{
		resolved: 24,
		unresolved: 225,
		date: '2024-03-10T00:00:00',
	},
	{
		resolved: 30,
		unresolved: 220,
		date: '2024-03-11T00:00:00',
	},
	{
		resolved: 31,
		unresolved: 219,
		date: '2024-03-12T00:00:00',
	},
	{
		resolved: 31,
		unresolved: 219,
		date: '2024-03-13T00:00:00',
	},
	{
		resolved: 31,
		unresolved: 219,
		date: '2024-03-14T00:00:00',
	},
	{
		resolved: 45,
		unresolved: 215,
		date: '2024-03-17T00:00:00',
	},
	{
		resolved: 45,
		unresolved: 215,
		date: '2024-03-18T00:00:00',
	},
	{
		resolved: 45,
		unresolved: 215,
		date: '2024-03-19T00:00:00',
	},
	{
		resolved: 45,
		unresolved: 215,
		date: '2024-03-20T00:00:00',
	},
	{
		resolved: 47,
		unresolved: 214,
		date: '2024-03-21T00:00:00',
	},
	{
		resolved: 48,
		unresolved: 213,
		date: '2024-03-22T00:00:00',
	},
	{
		resolved: 49,
		unresolved: 212,
		date: '2024-03-23T00:00:00',
	},
	{
		resolved: 49,
		unresolved: 212,
		date: '2024-03-24T00:00:00',
	},
	{
		resolved: 52,
		unresolved: 209,
		date: '2024-03-25T00:00:00',
	},
	{
		resolved: 52,
		unresolved: 209,
		date: '2024-03-26T00:00:00',
	},
	{
		resolved: 53,
		unresolved: 208,
		date: '2024-03-27T00:00:00',
	},
	{
		resolved: 54,
		unresolved: 207,
		date: '2024-03-28T00:00:00',
	},
	{
		resolved: 54,
		unresolved: 207,
		date: '2024-03-29T00:00:00',
	},
	{
		resolved: 54,
		unresolved: 207,
		date: '2024-03-30T00:00:00',
	},
	{
		resolved: 60,
		unresolved: 205,
		date: '2024-03-31T00:00:00',
	},
	{
		resolved: 60,
		unresolved: 205,
		date: '2024-04-01T00:00:00',
	},
	{
		resolved: 61,
		unresolved: 230,
		date: '2024-04-02T00:00:00',
	},
	{
		resolved: 61,
		unresolved: 275,
		date: '2024-04-03T00:00:00',
	},
]

export const topIssuesAndInsightsData = {
	issues: [
		{
			issue_name: 'MissingMFA',
			count: 76,
			max_priority: 4,
		},
		{
			issue_name: 'PartiallyOffBoardedUsers',
			count: 4,
			max_priority: 4,
		},
		{
			issue_name: 'RootUserMissingMFA',
			count: 2,
			max_priority: 4,
		},
		{
			issue_name: 'RootUserIsActive',
			count: 1,
			max_priority: 4,
		},
		{
			issue_name: 'AccessKeyNotRotated',
			count: 107,
			max_priority: 3,
		},
		{
			issue_name: 'EC2KeyPairNotRotated',
			count: 70,
			max_priority: 3,
		},
		{
			issue_name: 'InactiveIdentity',
			count: 78,
			max_priority: 3,
		},
		{
			issue_name: 'InactiveAccessKey',
			count: 15,
			max_priority: 3,
		},
		{
			issue_name: 'MultipleAccessKeys',
			count: 8,
			max_priority: 3,
		},
		{
			issue_name: 'PasswordRotation',
			count: 5,
			max_priority: 3,
		},
	],
	insights: [
		{
			id: '2bfc303f-2385-4e16-ae27-ee1b75c65a71',
			environment_id: '71664a4f-12c8-445b-a0f1-7a83415b8c91',
			customer_id: '69073497-fc29-4c9f-8104-3b686ce8df40',
			created_at: '2024-04-09T07:31:19.633202Z',
			last_seen: '2024-04-09T08:12:06.812308Z',
			status: 'OPEN',
			insight_name: 'GuestUserAccess',
			insight_source: 'AZURE',
			description:
				'Guest user access is overly permissive in subscription **Production Subscription**. Review and restrict guest access permissions.',
			account_literal: '5fe64707-80a1-4b78-850d-ab049039d213',
			account_literal_friendly_name: 'Production Subscription',
			unique_identifier: '328041475888-azure-guest-user-access',
		},
		{
			id: '80ac8f07-a68c-4cdc-8ca1-b5317a58a8bd',
			environment_id: '8eed3774-cbf4-4620-be16-52b62af43635',
			customer_id: 'f5e8db32-ee9f-497e-84ae-f68fbc8ea100',
			created_at: '2024-02-20T18:32:27.576501Z',
			last_seen: '2024-04-04T05:10:51.289877Z',
			status: 'OPEN',
			insight_name: 'PasswordPolicy',
			insight_source: 'AZURE',
			description:
				'Password policy issue in subscription **Development Subscription**. The minimum password length is set to 8 and should be at least 12.',
			account_literal: '09626fc3-f5c7-4fd6-8c07-f9842687a2c0',
			account_literal_friendly_name: 'Development Subscription',
			unique_identifier: '',
		},
		{
			id: 'ed073032-e9e2-4233-b140-e819b6e4dc13',
			environment_id: '8eed3774-cbf4-4620-be16-52b62af43635',
			customer_id: 'f5e8db32-ee9f-497e-84ae-f68fbc8ea100',
			created_at: '2024-04-03T13:06:12.308772Z',
			last_seen: '2024-04-04T05:10:51.350155Z',
			status: 'OPEN',
			insight_name: 'MFAEnforcement',
			insight_source: 'AZURE',
			description:
				'MFA is not enforced for all users in subscription **Staging Subscription**. Enable MFA for better security.',
			account_literal: 'd6c1d96c-f024-4d77-9658-db93e79da1ac',
			account_literal_friendly_name: 'Staging Subscription',
			unique_identifier: '',
		},
		{
			id: '46f191fa-fbca-493d-8fe3-fcef7fea569f',
			customer_id: 'f5e8db32-ee9f-497e-84ae-f68fbc8ea100',
			created_at: '2024-04-03T13:06:12.338112Z',
			last_seen: '2024-04-04T05:10:51.306601Z',
			status: 'OPEN',
			insight_name: 'ConditionalAccess',
			insight_source: 'AZURE',
			description:
				'Conditional access policies are not configured correctly in subscription **IT Subscription**. Review and update policies to enforce restrictions based on risk level.',
			account_literal: '40dd57db-e65c-4620-aebd-63851f6e345b',
			account_literal_friendly_name: 'IT Subscription',
			unique_identifier: '',
		},
		{
			id: '9fbc303f-2385-4e16-ae27-ee1b75c65f71',
			environment_id: '61664a4f-12c8-445b-a0f1-7a83415b8b91',
			customer_id: '59073497-fc29-4c9f-8104-3b686ce8df30',
			created_at: '2024-04-09T07:31:19.633202Z',
			last_seen: '2024-04-09T08:12:06.812308Z',
			status: 'OPEN',
			insight_name: 'CrossAccountAccess',
			insight_source: 'AWS',
			description:
				'The Role arn:aws:iam::877686312397:role/prod-user trusts roles in other lower privileged accounts :\n- 871744574088',
			account_literal: '237266300479',
			account_literal_friendly_name: 'Sandbox',
			unique_identifier: '328041475888-arn:aws:iam::237266300479:role/DEMO_ROLE_NO_PERMISSIONS',
		},
		{
			id: '70ac8f07-a68c-4cdc-8ca1-b5317a58a7bd',
			environment_id: '7eed3774-cbf4-4620-be16-52b62af43625',
			customer_id: 'e5e8db32-ee9f-497e-84ae-f68fbc8ea090',
			created_at: '2024-02-20T18:32:27.576501Z',
			last_seen: '2024-04-04T05:10:51.289877Z',
			status: 'OPEN',
			insight_name: 'PasswordPolicy',
			insight_source: 'AWS',
			description:
				'Password policy issue for account 137405404517.\nThe following password policy should be set:\n - Password reuse prevention is set to 10 and should be at least 12.',
			account_literal: '137405404517',
			account_literal_friendly_name: 'Unsecure AWS',
			unique_identifier: '',
		},
		{
			id: 'cd073032-e9e2-4233-b140-e819b6e4dc12',
			environment_id: '7eed3774-cbf4-4620-be16-52b62af43625',
			customer_id: 'e5e8db32-ee9f-497e-84ae-f68fbc8ea090',
			created_at: '2024-04-03T13:06:12.308772Z',
			last_seen: '2024-04-04T05:10:51.350155Z',
			status: 'OPEN',
			insight_name: 'PasswordPolicy',
			insight_source: 'AWS',
			description:
				'Password policy issue for account 106007500522.\nThe following password policies should be set:\n - Password reuse prevention is set to None and should be at least 12.\n - Password max age is set to None and should be at least 90 days.\n - Password should contain at least one symbol.',
			account_literal: '106007500522',
			account_literal_friendly_name: 'Unsecure EngOps',
			unique_identifier: '',
		},
		{
			id: '36f191fa-fbca-493d-8fe3-fcef7fea568f',
			environment_id: '7eed3774-cbf4-4620-be16-52b62af43625',
			customer_id: 'e5e8db32-ee9f-497e-84ae-f68fbc8ea090',
			created_at: '2024-04-03T13:06:12.338112Z',
			last_seen: '2024-04-04T05:10:51.306601Z',
			status: 'OPEN',
			insight_name: 'PasswordPolicy',
			insight_source: 'AWS',
			description:
				'Password policy issue for account 245810067965.\nThe following password policy should be set:\n - Password minimum length is set to 8 and should be at least 12.',
			account_literal: '245810067965',
			account_literal_friendly_name: 'Unsecure IT',
			unique_identifier: '',
		},
	],
}

export const findingsByPriorityArrayData = [
	{
		priority: PriorityTypeEnum.CRITICAL,
		count: 10,
	},
	{
		priority: PriorityTypeEnum.HIGH,
		count: 156,
	},
	{
		priority: PriorityTypeEnum.MEDIUM,
		count: 125,
	},
	{
		priority: PriorityTypeEnum.LOW,
		count: 158,
	},
]

export const findingsDistByNameData = [
	{
		issue_name: 'InactiveIdentity',
		amount: 78,
		max_priority: 'HIGH',
	},
	{
		issue_name: 'AccessKeyNotRotated',
		amount: 107,
		max_priority: 'HIGH',
	},
	{
		issue_name: 'MissingMFA',
		amount: 76,
		max_priority: 'CRITICAL',
	},
	{
		issue_name: 'EKSAccessKeyReferencedInPod',
		amount: 54,
		max_priority: 'CRITICAL',
	},
	{
		issue_name: 'MultipleAccessKeys',
		amount: 8,
		max_priority: 'HIGH',
	},
	{
		issue_name: 'PasswordInactiveIdentity',
		amount: 5,
		max_priority: 'HIGH',
	},
	{
		issue_name: 'UnFederatedIdentity',
		amount: 6,
		max_priority: 'HIGH',
	},
	{
		issue_name: 'PasswordRotation',
		amount: 5,
		max_priority: 'HIGH',
	},
	{
		issue_name: 'InactiveAccessKey',
		amount: 15,
		max_priority: 'HIGH',
	},
	{
		issue_name: 'EKSUnassociatedServiceAccount',
		amount: 50,
		max_priority: 'MEDIUM',
	},
	{
		issue_name: 'EC2KeyPairNotRotated',
		amount: 70,
		max_priority: 'HIGH',
	},
	{
		issue_name: 'CloudtrailIamUserUsedFromEndpoint',
		amount: 4,
		max_priority: 'HIGH',
	},
]

export const identitiesByPriorityDistData = [
	{
		high: 4,
		critical: 10,
		medium: 70,
		low: 37,
		not_calculated: 0,
		identity_type: 'HUMAN',
	},
	{
		high: 124,
		critical: 0,
		medium: 27,
		low: 81,
		not_calculated: 0,
		identity_type: 'MACHINE',
	},
	{
		high: 28,
		critical: 0,
		medium: 0,
		low: 0,
		not_calculated: 0,
		identity_type: 'HYBRID',
	},
	{
		high: 0,
		critical: 0,
		medium: 28,
		low: 40,
		not_calculated: 0,
		identity_type: 'UNKNOWN',
	},
]

export const monitoredIdentitiesByEnvData = [
	{
		date: '2024-03-05T00:00:00',
		aws: 4808,
		okta: 3000,
		google_workspace: 1500,
		jumpcloud: 1104,
		entra_id: 500,
		gcp: 1501,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		azure: 0,
		databricks: 0,
		salesforce: 10,
	},
	{
		date: '2024-03-06T00:00:00',
		aws: 6274,
		okta: 3048,
		google_workspace: 1495,
		jumpcloud: 1104,
		entra_id: 495,
		gcp: 1496,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 25,
	},
	{
		date: '2024-03-07T00:00:00',
		aws: 6874,
		okta: 3003,
		google_workspace: 1512,
		jumpcloud: 1104,
		entra_id: 507,
		gcp: 1511,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 50,
	},
	{
		date: '2024-03-08T00:00:00',
		aws: 6613,
		okta: 2977,
		google_workspace: 1507,
		jumpcloud: 1105,
		entra_id: 492,
		gcp: 1508,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 50,
	},
	{
		date: '2024-03-09T00:00:00',
		aws: 5369,
		okta: 3022,
		google_workspace: 1509,
		jumpcloud: 1105,
		entra_id: 503,
		gcp: 1510,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 50,
	},
	{
		date: '2024-03-10T00:00:00',
		aws: 6613,
		okta: 3056,
		google_workspace: 1488,
		jumpcloud: 1105,
		entra_id: 488,
		gcp: 1487,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 70,
	},
	{
		date: '2024-03-11T00:00:00',
		aws: 4575,
		okta: 3011,
		google_workspace: 1499,
		jumpcloud: 1105,
		entra_id: 499,
		gcp: 1500,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 77,
	},
	{
		date: '2024-03-12T00:00:00',
		aws: 6374,
		okta: 2989,
		google_workspace: 1504,
		jumpcloud: 1106,
		entra_id: 504,
		gcp: 1505,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 80,
	},
	{
		date: '2024-03-13T00:00:00',
		aws: 6070,
		okta: 3033,
		google_workspace: 1511,
		jumpcloud: 1105,
		entra_id: 511,
		gcp: 1512,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 100,
	},
	{
		date: '2024-03-14T00:00:00',
		aws: 7165,
		okta: 2994,
		google_workspace: 1492,
		jumpcloud: 1104,
		entra_id: 492,
		gcp: 1491,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 101,
	},
	{
		date: '2024-03-17T00:00:00',
		aws: 6916,
		okta: 3045,
		google_workspace: 1508,
		jumpcloud: 1102,
		entra_id: 508,
		gcp: 1509,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-18T00:00:00',
		aws: 5807,
		okta: 3007,
		google_workspace: 1497,
		jumpcloud: 1102,
		entra_id: 497,
		gcp: 1498,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 0,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-19T00:00:00',
		aws: 6062,
		okta: 2972,
		google_workspace: 1515,
		jumpcloud: 1103,
		entra_id: 515,
		gcp: 1516,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 101,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-20T00:00:00',
		aws: 5525,
		okta: 3025,
		google_workspace: 1501,
		jumpcloud: 1103,
		entra_id: 501,
		gcp: 1502,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 101,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-21T00:00:00',
		aws: 5822,
		okta: 2981,
		google_workspace: 1493,
		jumpcloud: 1103,
		entra_id: 493,
		gcp: 1494,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 101,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-22T00:00:00',
		aws: 5980,
		okta: 3038,
		google_workspace: 1506,
		jumpcloud: 1101,
		entra_id: 506,
		gcp: 1507,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 121,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-23T00:00:00',
		aws: 5827,
		okta: 2996,
		google_workspace: 1489,
		jumpcloud: 1101,
		entra_id: 489,
		gcp: 1488,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 121,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-24T00:00:00',
		aws: 5889,
		okta: 3041,
		google_workspace: 1518,
		jumpcloud: 1101,
		entra_id: 518,
		gcp: 1519,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 121,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-25T00:00:00',
		aws: 5630,
		okta: 3002,
		google_workspace: 1502,
		jumpcloud: 1101,
		entra_id: 502,
		gcp: 1503,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 121,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-26T00:00:00',
		aws: 5853,
		okta: 2978,
		google_workspace: 1496,
		jumpcloud: 1107,
		entra_id: 496,
		gcp: 1495,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 121,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-27T00:00:00',
		aws: 5916,
		okta: 3031,
		google_workspace: 1503,
		jumpcloud: 1105,
		entra_id: 503,
		gcp: 1504,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 198,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-28T00:00:00',
		aws: 6660,
		okta: 2986,
		google_workspace: 1490,
		jumpcloud: 1105,
		entra_id: 490,
		gcp: 1489,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 198,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-29T00:00:00',
		aws: 6695,
		okta: 3028,
		google_workspace: 1517,
		jumpcloud: 1103,
		entra_id: 517,
		gcp: 1518,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 198,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-30T00:00:00',
		aws: 5009,
		okta: 2983,
		google_workspace: 1494,
		jumpcloud: 1103,
		entra_id: 494,
		gcp: 1493,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 198,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-03-31T00:00:00',
		aws: 5047,
		okta: 3036,
		google_workspace: 1500,
		jumpcloud: 1103,
		entra_id: 500,
		gcp: 1501,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 198,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-04-01T00:00:00',
		aws: 4816,
		okta: 2991,
		google_workspace: 1487,
		jumpcloud: 1103,
		entra_id: 487,
		gcp: 1486,
		postgres: 0,
		kubernetes: 0,
		snowflake: 0,
		github: 198,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-04-02T00:00:00',
		aws: 5887,
		okta: 3043,
		google_workspace: 1510,
		jumpcloud: 1103,
		entra_id: 510,
		gcp: 1511,
		postgres: 354,
		kubernetes: 741,
		snowflake: 1021,
		github: 198,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-04-03T00:00:00',
		aws: 7174,
		okta: 3000,
		google_workspace: 1498,
		jumpcloud: 1102,
		entra_id: 498,
		gcp: 1497,
		postgres: 354,
		kubernetes: 741,
		snowflake: 1021,
		github: 198,
		azure: 0,
		databricks: 0,
		salesforce: 150,
	},
	{
		date: '2024-04-04T00:00:00',
		aws: 5635,
		okta: 2975,
		google_workspace: 1513,
		jumpcloud: 1101,
		entra_id: 513,
		gcp: 1514,
		postgres: 354,
		kubernetes: 741,
		snowflake: 823,
		github: 198,
		azure: 4653,
		databricks: 624,
	},
]

export const findingsDistByEnvData = [
	{
		env_type: 'AWS',
		amount: 238,
		max_priority: 'CRITICAL',
	},
	{
		env_type: 'JUMPCLOUD',
		amount: 35,
		max_priority: 'HIGH',
	},
	{
		env_type: 'OKTA',
		amount: 79,
		max_priority: 'CRITICAL',
	},
	{
		env_type: 'GCP',
		amount: 36,
		max_priority: 'MEDIUM',
	},
	{
		env_type: 'GOOGLE_WORKSPACE',
		amount: 38,
		max_priority: 'MEDIUM',
	},
	{
		env_type: 'ENTRA_ID',
		amount: 23,
		max_priority: 'MEDIUM',
	},
	{
		env_type: 'RDS_POSTGRES',
		amount: 1,
		max_priority: 'CRITICAL',
	},
	{
		env_type: 'KUBERNETES',
		amount: 17,
		max_priority: 'HIGH',
	},
	{
		env_type: 'SNOWFLAKE',
		amount: 5,
		max_priority: 'MEDIUM',
	},
	{
		env_type: 'GITHUB',
		amount: 2,
		max_priority: 'HIGH',
	},
	{
		env_type: 'SALESFORCE',
		amount: 150,
		max_priority: 'HIGH',
	},
]

export const findingsDistByPriorityAndDate = [
	{
		date: '2024-03-05T00:00:00',
		not_calculated: 10000,
		low: 6207,
		medium: 3724,
		high: 1862,
		critical: 620,
	},
	{
		date: '2024-03-06T00:00:00',
		not_calculated: 10000,
		low: 6956,
		medium: 4174,
		high: 2087,
		critical: 695,
	},
	{
		date: '2024-03-07T00:00:00',
		not_calculated: 10000,
		low: 7256,
		medium: 4353,
		high: 2177,
		critical: 725,
	},
	{
		date: '2024-03-08T00:00:00',
		not_calculated: 10000,
		low: 7101,
		medium: 4261,
		high: 2130,
		critical: 710,
	},
	{
		date: '2024-03-09T00:00:00',
		not_calculated: 10000,
		low: 6509,
		medium: 3905,
		high: 1953,
		critical: 651,
	},
	{
		date: '2024-03-10T00:00:00',
		not_calculated: 10000,
		low: 7119,
		medium: 4271,
		high: 2136,
		critical: 711,
	},
	{
		date: '2024-03-11T00:00:00',
		not_calculated: 10000,
		low: 6095,
		medium: 3657,
		high: 1828,
		critical: 609,
	},
	{
		date: '2024-03-12T00:00:00',
		not_calculated: 10000,
		low: 6991,
		medium: 4195,
		high: 2097,
		critical: 699,
	},
	{
		date: '2024-03-13T00:00:00',
		not_calculated: 10000,
		low: 6871,
		medium: 4123,
		high: 2061,
		critical: 687,
	},
	{
		date: '2024-03-14T00:00:00',
		not_calculated: 10000,
		low: 7369,
		medium: 4421,
		high: 2211,
		critical: 737,
	},
	{
		date: '2024-03-17T00:00:00',
		not_calculated: 10000,
		low: 7294,
		medium: 4376,
		high: 2188,
		critical: 730,
	},
	{
		date: '2024-03-18T00:00:00',
		not_calculated: 10000,
		low: 6704,
		medium: 4022,
		high: 2011,
		critical: 671,
	},
	{
		date: '2024-03-19T00:00:00',
		not_calculated: 10000,
		low: 6892,
		medium: 4135,
		high: 2068,
		critical: 689,
	},
	{
		date: '2024-03-20T00:00:00',
		not_calculated: 10000,
		low: 6629,
		medium: 3977,
		high: 1989,
		critical: 663,
	},
	{
		date: '2024-03-21T00:00:00',
		not_calculated: 10000,
		low: 6744,
		medium: 4046,
		high: 2023,
		critical: 674,
	},
	{
		date: '2024-03-22T00:00:00',
		not_calculated: 10000,
		low: 6880,
		medium: 4128,
		high: 2064,
		critical: 687,
	},
	{
		date: '2024-03-23T00:00:00',
		not_calculated: 10000,
		low: 6756,
		medium: 4053,
		high: 2027,
		critical: 675,
	},
	{
		date: '2024-03-24T00:00:00',
		not_calculated: 10000,
		low: 6854,
		medium: 4112,
		high: 2056,
		critical: 685,
	},
	{
		date: '2024-03-25T00:00:00',
		not_calculated: 10000,
		low: 6681,
		medium: 4008,
		high: 2004,
		critical: 668,
	},
	{
		date: '2024-03-26T00:00:00',
		not_calculated: 10000,
		low: 6773,
		medium: 4064,
		high: 2032,
		critical: 677,
	},
	{
		date: '2024-03-27T00:00:00',
		not_calculated: 10000,
		low: 6880,
		medium: 4128,
		high: 2064,
		critical: 688,
	},
	{
		date: '2024-03-28T00:00:00',
		not_calculated: 10000,
		low: 7209,
		medium: 4325,
		high: 2163,
		critical: 721,
	},
	{
		date: '2024-03-29T00:00:00',
		not_calculated: 10000,
		low: 7288,
		medium: 4373,
		high: 2186,
		critical: 729,
	},
	{
		date: '2024-03-30T00:00:00',
		not_calculated: 10000,
		low: 6387,
		medium: 3832,
		high: 1916,
		critical: 639,
	},
	{
		date: '2024-03-31T00:00:00',
		not_calculated: 10000,
		low: 6443,
		medium: 3866,
		high: 1933,
		critical: 643,
	},
	{
		date: '2024-04-01T00:00:00',
		not_calculated: 10000,
		low: 6284,
		medium: 3770,
		high: 1885,
		critical: 629,
	},
	{
		date: '2024-04-02T00:00:00',
		not_calculated: 10000,
		low: 7939,
		medium: 4763,
		high: 2382,
		critical: 794,
	},
	{
		date: '2024-04-03T00:00:00',
		not_calculated: 10000,
		low: 8542,
		medium: 5125,
		high: 2562,
		critical: 854,
	},
	{
		date: '2024-04-04T00:00:00',
		not_calculated: 10000,
		low: 10184,
		medium: 6110,
		high: 3305,
		critical: 1045,
	},
]

export const mostWantedIdentitiesData = [
	{
		name: 'arn:aws:iam::871744574088:role/SynergisticApplications',
		friendly_name: 'SynergisticApplications',
		account_literal_friendly_name: 'SA OpsAccount',
		account_literal: '871744574088',
		env_type: 'AWS',
		source: 'AWS_IAM_USER',
		count: 2,
		max_priority: 'CRITICAL',
		type: 'MACHINE',
		id: '2',
	},
	{
		name: 'analytics_role',
		friendly_name: null,
		account_literal: '279434620138',
		account_literal_friendly_name: 'prod',
		env_type: 'AWS',
		source: 'POSTGRES_ROLE',
		count: 1,
		max_priority: 'CRITICAL',
		type: 'HYBRID',
		id: '54',
	},
	{
		name: 'arn:aws:iam::782968362597:role/OrganizationAccountAccessRole',
		friendly_name: 'OrganizationAccountAccessRole',
		account_literal: '782968362597',
		account_literal_friendly_name: 'code-stream-prod',
		env_type: 'AWS',
		source: 'AWS_IAM_ROLE',
		count: 1,
		max_priority: 'CRITICAL',
		type: 'MACHINE',
		id: '171',
	},
	{
		name: 'arn:aws:iam::877686312397:user/gabrielabeard',
		friendly_name: 'gabrielabeard',
		account_literal: '877686312397',
		account_literal_friendly_name: 'AWS dev',
		env_type: 'AWS',
		source: 'AWS_IAM_USER',
		count: 7,
		max_priority: 'HIGH',
		type: 'HYBRID',
		id: '1',
	},
	{
		name: 'arn:aws:iam::862947542951:user/ci-user',
		friendly_name: 'ci-user',
		account_literal: '862947542951',
		account_literal_friendly_name: 'briks-prod',
		env_type: 'AWS',
		source: 'AWS_IAM_USER',
		count: 5,
		max_priority: 'HIGH',
		type: 'HYBRID',
		id: '65',
	},
	{
		name: 'arn:aws:iam::296957980053:user/terraform-prod',
		friendly_name: 'terraform-prod',
		account_literal: '296957980053',
		account_literal_friendly_name: 'Nexovate',
		env_type: 'AWS',
		source: 'AWS_IAM_USER',
		count: 3,
		max_priority: 'HIGH',
		type: 'MACHINE',
		id: '66',
	},
	{
		name: 'elizabeth.coleman@synergy.com',
		friendly_name: 'elizabeth.coleman@synergy.com',
		account_literal: 'https://synergy.okta.com/',
		env_type: 'OKTA',
		source: 'OKTA',
		count: 2,
		max_priority: 'HIGH',
		type: 'HUMAN',
		id: '97',
	},
	{
		name: '6bfe2755-a8f5-46ac-833b-ed6124602715',
		friendly_name: 'dwh-user',
		account_literal: '296957980053',
		account_literal_friendly_name: 'prod',
		env_type: 'AWS',
		source: 'KUBERNETES_RESOURCE',
		count: 3,
		max_priority: 'HIGH',
		type: 'MACHINE',
		id: '79',
	},
	{
		name: 'arn:aws:iam::818837914182:user/prod-eks',
		friendly_name: 'prod-eks',
		account_literal: '818837914182',
		account_literal_friendly_name: 'prod',
		env_type: 'AWS',
		source: 'AWS_IAM_USER',
		count: 2,
		max_priority: 'HIGH',
		type: 'MACHINE',
		id: '83',
	},
	{
		name: 'dashboard@synergy.com',
		friendly_name: 'dashboard@synergy.com',
		account_literal: '6110ef9a92b04899e1536eb9',
		env_type: 'JUMPCLOUD',
		source: 'JUMPCLOUD_USER',
		count: 1,
		max_priority: 'HIGH',
		type: 'HUMAN',
		id: '98',
	},
	{
		name: 'elizabeth.thomas@synergy.com',
		friendly_name: 'elizabeth.thomas@synergy.com',
		env_type: 'ENTRA_ID',
		source: 'ENTRA_ID_USER',
		account_literal: 'f5f42f8d-2b1b-45c7-a877-7f37e45391c3',
		count: 2,
		max_priority: 'HIGH',
		type: 'HUMAN',
		id: '141',
	},
	{
		name: 'ms.shelby@synergy.com',
		friendly_name: 'ms.shelby@synergy.com',
		env_type: 'GOOGLE_WORKSPACE',
		source: 'GOOGLE_WORKSPACE',
		account_literal: 'C03o59ber',
		count: 2,
		max_priority: 'HIGH',
		type: 'HUMAN',
		id: '169',
	},
	{
		name: 'arn:aws:iam::710369796047:user/production-eks-user',
		friendly_name: 'production-eks-user',
		account_literal: '710369796047',
		account_literal_friendly_name: 'production',
		env_type: 'AWS',
		source: 'AWS_IAM_USER',
		count: 3,
		max_priority: 'HIGH',
		type: 'MACHINE',
		id: '170',
	},
	{
		name: 'arn:aws:iam::623356180012:user/automation_serverless',
		friendly_name: 'automation_serverless',
		account_literal: '623356180012',
		account_literal_friendly_name: 'AWS Prod',
		env_type: 'AWS',
		source: 'AWS_IAM_USER',
		count: 3,
		max_priority: 'HIGH',
		type: 'MACHINE',
		id: '172',
	},
	{
		name: 'synergistic-sciences/gabrielabeard',
		account_literal: 'O_qwerty123',
		account_literal_friendly_name: 'synergistic-sciences',
		env_type: 'GITHUB',
		source: 'GITHUB_USER',
		count: 3,
		max_priority: 'HIGH',
		type: 'HUMAN',
		id: '660007lm-e39f-41c3-bf14-bb2b5b7e6400',
	},
	{
		name: 'synergy-app-server-001',
		account_literal: 'projects/987654321098',
		account_literal_friendly_name: 'synergy-prod-app',
		env_type: 'GCP',
		source: 'GCP_SERVICE_ACCOUNT',
		count: 4,
		max_priority: 'HIGH',
		type: 'MACHINE',
		id: 'd7e3f9a1-6b2c-4e8a-9f3d-1c2b3a4d5e6f',
	},
	{
		name: 'synergy-app-server-002',
		account_literal: 'projects/987654321098',
		account_literal_friendly_name: 'synergy-prod-app',
		env_type: 'GCP',
		source: 'GCP_SERVICE_ACCOUNT',
		count: 4,
		max_priority: 'HIGH',
		type: 'MACHINE',
		id: 'd7e3f9a1-6d3c-4e8h-9f3d-1c2b3a4d5e8f',
	},
	{
		name: 'Terraform SA',
		account_literal: 'projects/397452691483',
		account_literal_friendly_name: 'prj-terraform-gwwsa',
		env_type: 'GCP',
		source: 'GCP_SERVICE_ACCOUNT',
		count: 4,
		max_priority: 'HIGH',
		type: 'MACHINE',
		id: 'c91b3b50-0166-40d6-a785-39d64f1ccc9d',
	},
]

export const keyRotationData = {
	less_than_one_month: 64,
	one_to_three_months: 39,
	three_to_six_months: 541,
	six_to_twelve_months: 5612,
	more_than_twelve_months: 2409,
}

export const identitiesLifetimeData = {
	less_than_one_month: 1652,
	one_to_three_months: 2477,
	three_to_six_months: 2064,
	six_to_twelve_months: 2890,
	more_than_twelve_months: 11561,
}

export const topBarData = {
	identity_posture_score: 5.2,
	total_identities: 20664,
	admin_count: 543,
	admin_high_issue_count: 72,
	admin_critical_issue_count: 15,
	high_count_latest: 3097,
	critical_count_latest: 1032,
	total_identities_x_days_ago: 12413,
	high_count_x_days_ago: 1862,
	critical_count_x_days_ago: 620,
}

export const identitiesWithIssueEnvTypeDistributionData = {
	UnFederatedIdentity: [
		{ env_type: EnvironmentType.AWS, total_count: 123, admin_count: 5 },
		{ env_type: EnvironmentType.AZURE, total_count: 312, admin_count: 13 },
		{ env_type: EnvironmentType.SNOWFLAKE, total_count: 55, admin_count: 1 },
		{ env_type: EnvironmentType.GCP, total_count: 218, admin_count: 7 },
		{ env_type: EnvironmentType.SALESFORCE, total_count: 25, admin_count: 7 },
	],
	InactiveIdentity: [
		{ env_type: EnvironmentType.AWS, total_count: 1948, admin_count: 20 },
		{ env_type: EnvironmentType.AZURE, total_count: 1233, admin_count: 14 },
		{ env_type: EnvironmentType.GCP, total_count: 1212, admin_count: 10 },
		{ env_type: EnvironmentType.DATABRICKS, total_count: 50, admin_count: 2 },
		{ env_type: EnvironmentType.ENTRA_ID, total_count: 50, admin_count: 8 },
		{ env_type: EnvironmentType.GITHUB, total_count: 300, admin_count: 0 },
		{ env_type: EnvironmentType.GOOGLE_WORKSPACE, total_count: 500, admin_count: 0 },
		{ env_type: EnvironmentType.OKTA, total_count: 500, admin_count: 0 },
		{ env_type: EnvironmentType.SNOWFLAKE, total_count: 500, admin_count: 0 },
		{ env_type: EnvironmentType.SALESFORCE, total_count: 70, admin_count: 1 },
	],
}
