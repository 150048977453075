import { Node, NodeProps } from '@xyflow/react'
import { ServerDatabricksPersonalAccessToken } from '../../../../schemas/identities/databricks/databricksUserSchema.ts'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'

export type DatabricksOAuthNodeType = Node<{ oauthToken: ServerDatabricksPersonalAccessToken }, 'oauthToken'>
type DatabricksOAuthToken = NodeProps<DatabricksOAuthNodeType>

export const DatabricksOAuthNode = ({ data: { oauthToken } }: DatabricksOAuthToken) => (
	<BaseCredentialsNode label="OAuth Token" name={{ text: oauthToken?.token_id || 'Unknown OAuth token' }} />
)
