import { z } from 'zod'
import { ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { DateSchema } from '../common'
import { ServerKubernetesResourceXcSchema } from './kubernetes/kubernetesResourceXcSchema.ts'
import { ServerAwsEc2InstanceXcSchema } from './awsEc2InstanceXcSchema.ts'
import { ServerAwsIamChangeLogSchema } from './awsIamChangeLogSchema.ts'
import { ServerAzureSubscriptionSchema, ServerAzureVirtualMachineSchema } from './azure/demoAzureVirtualMachine.ts'

const ServerRdsInstanceSchema = z.object({
	db_name: z.string().nullish(),
	host: z.string().nullish(),
	region: z.string().nullish(),
	instance_id: z.string().nullish(),
})

export const ServerPostgresRoleSchema = z.object({
	role_name: z.string().nullish(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	rds_instance: ServerRdsInstanceSchema.nullish(),
	role_valid_until: DateSchema.nullish(),
	is_role_superuser: z.boolean().nullish(),
	can_role_create_role: z.boolean().nullish(),
	can_role_create_db: z.boolean().nullish(),
	can_role_login: z.boolean().nullish(),
	can_role_bypass_rls: z.boolean().nullish(),
	role_auto_inherits_roles: z.boolean().nullish(),
	role_connections_limit: z.number().nullish(),
	aws_iam_roles_xc: z.array(ServerAwsIamRoleXcSchema).nullish(),
	role_last_activity_date: DateSchema.nullish(),
	kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(),
	aws_ec2_instances_xc: z.array(ServerAwsEc2InstanceXcSchema).nullish(),
	change_logs: z.array(ServerAwsIamChangeLogSchema).nullish(),
	subscription: ServerAzureSubscriptionSchema.nullish(),
	virtual_machines: z.array(ServerAzureVirtualMachineSchema).nullish(),
})

export type ServerPostgresRole = z.infer<typeof ServerPostgresRoleSchema>
