import { noDataOverlay } from '../../charts/helpers.tsx'
import { AgChartsReact } from 'ag-charts-react'
import { AgChartOptions } from 'ag-charts-enterprise'
import { formatNumber } from '../../utils/numberUtils.ts'
import { ChartLegendItem } from './ChartLegendItem.tsx'
import { useMemo } from 'react'

type DonutChartWithCustomLegendDatum<TKey extends string> = {
	count: number
	key: TKey
}

type CustomLegendProps<TKey extends string, TDatum extends DonutChartWithCustomLegendDatum<TKey>> = {
	data: TDatum[]
	activeKeys: TKey[]
	setActiveKeys: (setter: (currentActiveKeys: TKey[]) => TKey[]) => void
	seriesColorResolver: (key: TKey) => string
	legendTitleResolver: (key: TKey) => React.ReactNode
	legendBorders?: boolean
}

const CustomLegend = <TKey extends string, TDatum extends DonutChartWithCustomLegendDatum<TKey>>({
	data,
	activeKeys,
	setActiveKeys,
	seriesColorResolver,
	legendTitleResolver,
	legendBorders,
}: CustomLegendProps<TKey, TDatum>) => {
	const allKeys = data.map((datum) => datum.key)
	const onClick = (activeKey: TKey) => {
		setActiveKeys((currentActiveKeys) => {
			// If all legend items are enabled and one is clicked, we'll set it as the only active item
			if (allKeys.length === currentActiveKeys.length) {
				return [activeKey]
			}

			if (currentActiveKeys.includes(activeKey)) {
				return currentActiveKeys.filter((currentActiveKey) => currentActiveKey !== activeKey)
			} else {
				return [...currentActiveKeys, activeKey]
			}
		})
	}

	return (
		<div className="flex items-center flex-wrap gap-y-2 max-w-60 gap-x-2 content-center">
			{data.map((datum) => (
				<ChartLegendItem
					key={datum.key}
					label={legendTitleResolver(datum.key)}
					labelKey={datum.key}
					color={seriesColorResolver(datum.key)}
					count={datum.count}
					disabled={activeKeys.length !== 0 && !activeKeys.includes(datum.key)}
					onClick={(newActiveKey: string) => {
						onClick(newActiveKey as TKey)
					}}
					withBorder={legendBorders}
				/>
			))}
		</div>
	)
}

type DonutChartWithCustomLegendProps<TKey extends string, TDatum extends DonutChartWithCustomLegendDatum<TKey>> = {
	data: TDatum[]
	legendData?: TDatum[]
	backgroundColor?: string
	totalCount?: number
	seriesColorResolver: (key: TKey) => string
	legendTitleResolver: (key: TKey) => React.ReactNode
	seriesTooltipTitleResolver: (key: TKey) => string
	activeKeys: TKey[]
	setActiveKeys: (a: (currentActiveKeys: TKey[]) => TKey[]) => void
	dimensions: number
	legendBorders?: boolean
}

export const DonutChartWithCustomLegend = <TKey extends string, TDatum extends DonutChartWithCustomLegendDatum<TKey>>({
	data,
	legendData,
	backgroundColor,
	totalCount,
	seriesColorResolver,
	legendTitleResolver,
	seriesTooltipTitleResolver,
	activeKeys,
	setActiveKeys,
	dimensions,
	legendBorders,
}: DonutChartWithCustomLegendProps<TKey, TDatum>) => {
	const options: AgChartOptions = useMemo(
		() => ({
			overlays: {
				noData: {
					renderer: noDataOverlay,
				},
			},
			data: data,
			background: backgroundColor
				? {
						fill: backgroundColor,
					}
				: undefined,
			legend: {
				enabled: false,
			},
			padding: {
				top: 4,
				left: 8,
				bottom: 4,
				right: 8,
			},
			series: [
				{
					type: 'donut',
					angleKey: 'count',
					legendItemKey: 'key',
					innerRadiusRatio: 0.8,
					strokeWidth: 2,
					innerLabels: totalCount
						? [
								{
									text: formatNumber(totalCount),
									fontSize:
										totalCount < 10000
											? 24
											: totalCount < 100000
												? 18
												: totalCount < 1000000
													? 14
													: 10,
								},
							]
						: [],
					shadow: {
						color: 'rgba(15, 5, 82, 0.08)',
						xOffset: 3,
						yOffset: 3,
						blur: 1,
					},
					fills: data.map((datum) => seriesColorResolver(datum.key)),
					tooltip: {
						renderer: ({ datum }: { datum: TDatum }) => ({
							title: seriesTooltipTitleResolver(datum.key),
							content: formatNumber(datum.count),
							// Remove opacity for the legend title if needed
							backgroundColor:
								seriesColorResolver(datum.key).length === 9
									? seriesColorResolver(datum.key).slice(0, -2)
									: seriesColorResolver(datum.key),
						}),
					},
				},
			],
		}),
		[backgroundColor, data, totalCount, seriesColorResolver, seriesTooltipTitleResolver],
	)

	return (
		<>
			<div className="shrink-0" style={{ width: dimensions, height: dimensions }}>
				<AgChartsReact options={options} />
			</div>
			{!!data?.length && (
				<CustomLegend
					data={legendData ?? data}
					legendTitleResolver={legendTitleResolver}
					seriesColorResolver={seriesColorResolver}
					activeKeys={activeKeys}
					setActiveKeys={setActiveKeys}
					legendBorders={legendBorders}
				/>
			)}
		</>
	)
}
