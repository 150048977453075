import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { Button, ConfigProvider, Layout, Menu, Skeleton } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import {
	IssuesPageLens,
	IssueStatGroup,
	IssueTablePaginatedRow,
	PaginatedFindingsPageSearch,
} from '../../schemas/issue.ts'
import { useIssuesStats } from '../../api/issues.ts'
import { RoutePaths } from '../RoutePaths.tsx'
import { useSearch } from '@tanstack/react-router'
import OpenTicketIcon from '../../assets/open_ticket_icon.svg?react'
import RisksIcon from '../../assets/risks_icon.svg?react'
import { AgGridReact } from '@ag-grid-community/react'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { FindingMenuItemLabel } from './FindingMenuItemLabel.tsx'
import { IssuesPageLensMap } from './IssuesPageLensMap.tsx'
import { Tag } from '../../schemas/tags.ts'

const MenuItemLabel = ({ children, countElement }: { children: React.ReactNode; countElement?: React.ReactNode }) => (
	<div className="flex justify-between gap-1">
		<span>{children}</span>
		{countElement && <span className="text-gray-400">({countElement})</span>}
	</div>
)

const MenuItemIcon = ({ Icon, ...rest }: { Icon: React.ComponentType<{ className?: string }> }) => (
	<span {...rest}>
		<Icon className="w-5 h-5 !text-base" />
	</span>
)
const siderStyle: React.CSSProperties = {
	lineHeight: '120px',
	backgroundColor: '#353535',
	boxShadow: '0px 0px 10px 0px #0000001A',
}

export const FindingsSideBarPaginatedMenu: React.FC<{
	onFindingsSideBarMenuClick: (e: MenuInfo) => void
	gridRef: MutableRefObject<AgGridReact<IssueTablePaginatedRow> | null>
}> = ({ onFindingsSideBarMenuClick, gridRef }) => {
	const { data: issuesStatsData, isLoading: isIssuesStatsLoading } = useIssuesStats()
	const [isCollapsed, setIsCollapsed] = useState(false)

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { lens } = useSearch({ from: RoutePaths.Findings }) as PaginatedFindingsPageSearch

	const onClick = (e: MenuInfo) => {
		onFindingsSideBarMenuClick(e)
	}
	useEffect(() => {
		const columnFitTimeout = setTimeout(() => {
			gridRef.current?.api?.sizeColumnsToFit()
		}, 200)

		return () => {
			clearTimeout(columnFitTimeout)
		}
	}, [isCollapsed])
	const siderHeaderClassName = cx('h-14 px-2 mx-2 text-white flex items-center border-b border-gray-500 mb-2', {
		'justify-between': !isCollapsed,
		'justify-center': isCollapsed,
	})
	const toggleSiderCollapse = useCallback(() => {
		setIsCollapsed((currentIsCollapsed) => !currentIsCollapsed)
	}, [setIsCollapsed])

	const items = useMemo(() => {
		if (!issuesStatsData) {
			return []
		}
		const totalIssues = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.AllCount)
		const allIssuesFindingsFolder = issuesStatsData.filter(
			(issueStat) => issueStat.group === IssueStatGroup.Findings,
		)

		const myIssuesSideBar = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.MyIssues)
		const relatedTickets = issuesStatsData.filter((issueStat) => issueStat.group === IssueStatGroup.RelatedTickets)

		const allItems = []

		allItems.push({
			key: IssuesPageLens.ALL,
			label: <MenuItemLabel countElement={totalIssues[0].count}>{IssuesPageLensMap.All.title}</MenuItemLabel>,
			icon: <MenuItemIcon Icon={IssuesPageLensMap.All.icon} />,
		})
		if (myIssuesSideBar.length > 0) {
			allItems.push({
				key: IssuesPageLens.ASSIGNED_TO_ME,
				label: (
					<MenuItemLabel countElement={myIssuesSideBar[0]?.count || 0}>
						{IssuesPageLensMap['My Issues'].title}
					</MenuItemLabel>
				),
				icon: <MenuItemIcon Icon={IssuesPageLensMap['My Issues'].icon} />,
			})
		}
		if (relatedTickets.length > 0) {
			allItems.push({
				key: 'sub-menu-opened-tickets',
				label: <MenuItemLabel>Open Tickets</MenuItemLabel>,
				icon: <MenuItemIcon Icon={OpenTicketIcon}></MenuItemIcon>,
				children: relatedTickets.map((relatedTicket) => ({
					key: relatedTicket.issue_name,
					label: (
						<MenuItemLabel countElement={relatedTicket.count}>
							{IssuesPageLensMap[relatedTicket.issue_name as IssuesPageLens].title}
						</MenuItemLabel>
					),
					icon: (
						<MenuItemIcon
							Icon={IssuesPageLensMap[relatedTicket.issue_name as IssuesPageLens].icon}
						></MenuItemIcon>
					),
				})),
			})
		}
		allItems.push({
			key: 'sub-menu-risks',
			label: <MenuItemLabel>Risks</MenuItemLabel>,
			icon: <MenuItemIcon Icon={RisksIcon}></MenuItemIcon>,
			children: Object.values(Tag).map((risk) => ({
				key: risk,
				label: <MenuItemLabel>{IssuesPageLensMap[risk].title}</MenuItemLabel>,
				icon: <MenuItemIcon Icon={IssuesPageLensMap[risk].icon}></MenuItemIcon>,
			})),
		})
		allItems.push({
			key: IssuesPageLens.ISSUES,
			label: <MenuItemLabel>{IssuesPageLensMap.ISSUES.title}</MenuItemLabel>,
			icon: <MenuItemIcon Icon={IssuesPageLensMap.ISSUES.icon}></MenuItemIcon>,
			children: allIssuesFindingsFolder
				.filter((item) => item.count > 0)
				.map((item) => ({
					key: item.issue_name,
					label: <FindingMenuItemLabel item={item} />,
				})),
		})
		return allItems
	}, [issuesStatsData])

	const defaultOpenKeys = useMemo(() => {
		if (!lens) {
			return []
		}

		const openSubMenu = items.find((item) => {
			if (!item || !('children' in item)) {
				return false
			}

			return item.children!.find((subItem) => subItem?.key === lens)
		})

		if (!openSubMenu) {
			return []
		}

		return [openSubMenu.key]
	}, [lens, items])

	return (
		<Layout.Sider
			collapsed={isCollapsed}
			collapsedWidth={64}
			className="overflow-y-auto"
			width="20%"
			style={siderStyle}
		>
			<div className={siderHeaderClassName}>
				{!isCollapsed && 'Findings view'}
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: '#7C987D',
						},
					}}
				>
					<Button
						ghost
						icon={isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
						onClick={toggleSiderCollapse}
					/>
				</ConfigProvider>
			</div>
			{isIssuesStatsLoading ? (
				<Skeleton active />
			) : (
				<Menu
					onClick={onClick}
					className="bg-[#353535] text-[#BABBBF]"
					mode="inline"
					theme={'dark'}
					items={items}
					selectedKeys={lens ? [lens] : [IssuesPageLens.ALL]}
					defaultOpenKeys={defaultOpenKeys}
				/>
			)}
		</Layout.Sider>
	)
}
