import { Server } from 'miragejs/server'
import { ServerIntegration } from '../../jiraIntegration/schemas/common.ts'
import {
	assignees,
	createTicketInTokenDbResponse,
	devAppTestingMissingNetworkPolicyJiraTicket,
	gabrielaBeardAccessKeyNotRotated,
	gabrielaBeardPartiallyOffBoardedUsersJiraTicket,
	integrations,
	issueTypes,
	jiraFields,
	jiraIssuesPerProject,
	labels,
	productionEksUserAccessKeyReferencedInPodJiraTicket,
	projects,
} from '../data/jira.ts'
import {
	JiraFetchFieldsResponse,
	JiraFetchIssueTypeResponse,
	JiraFetchLabelsResponse,
	JiraFetchProjectsResponse,
} from '../../jiraIntegration/schemas/jiraApi.ts'
import { proxyObject } from '../schemas.ts'
import { JiraIssuePicker, JiraTicket, JiraUser } from '../../jiraIntegration/schemas/jiraSchemas.ts'

export function mirageGetAll(server: Server) {
	server.get('/integrations', (): ServerIntegration[] => {
		return integrations
	})
}

export function mirageCreateIntegrationTicketInTokenDb(server: Server) {
	server.post('/integrations/ticket', () => {
		return createTicketInTokenDbResponse
	})
}

export function mirageGetTickets(server: Server) {
	server.get('/integrations/:integrationId/:issueId', (_, request) => {
		const issueId = request.params.issueId
		if (issueId === '2bd66f71-2c42-4e14-985b-34218a1f9c3d') {
			return gabrielaBeardPartiallyOffBoardedUsersJiraTicket
		}
		if (issueId === '66f6d8ca-f7e3-4f20-9700-0e496338ed1c') {
			return gabrielaBeardAccessKeyNotRotated
		}
		if (issueId === '3a4832ce-e00e-4f34-b54a-e9e1a88e1a81') {
			return devAppTestingMissingNetworkPolicyJiraTicket
		}
		if (issueId === '6d2d68c3-fd0e-47bd-b73d-103ff19e2a66') {
			return productionEksUserAccessKeyReferencedInPodJiraTicket
		}
		return []
	})
}

export function mirageCallJiraProxy(server: Server) {
	server.post(
		'/integrations/proxy/jira',
		(
			_,
			request,
		):
			| JiraFetchProjectsResponse
			| JiraFetchLabelsResponse
			| JiraUser[]
			| JiraFetchIssueTypeResponse
			| JiraFetchFieldsResponse
			| JiraTicket
			| Record<string, Record<string, Record<string, string>>>
			| JiraIssuePicker => {
			const proxyRequest = proxyObject.parse(JSON.parse(request.requestBody))
			if (proxyRequest.endpoint === '/rest/api/3/project/search') {
				return projects
			}
			if (proxyRequest.endpoint === '/rest/api/3/label') {
				return labels
			}
			if (proxyRequest.endpoint === '/rest/api/3/user/assignable/search') {
				return assignees
			}
			if (proxyRequest.endpoint === '/rest/api/3/issue/createmeta/10003/issuetypes') {
				return issueTypes
			}
			if (proxyRequest.endpoint.startsWith('/rest/api/3/issue/createmeta/10003/issuetype')) {
				return jiraFields
			}
			if (proxyRequest.endpoint.startsWith('/rest/api/2/issue/SCRUM')) {
				return { fields: { status: { name: 'Open' } } }
			}
			if (proxyRequest.endpoint.startsWith('/rest/api/2/issue/KAN')) {
				return { fields: { status: { name: 'In Progress' } } }
			}
			if (proxyRequest.endpoint === '/rest/api/3/issue/picker') {
				return jiraIssuesPerProject
			}
			return {
				id: '10003',
				key: 'KAN',
				self: 'https://token-demo.atlassian.net/rest/api/3/issue/10003',
			}
		},
	)
}
