/* eslint-disable camelcase */
import { Server } from 'miragejs/server'
import { ServerMetricsSummary, ServerMetricsSummarySchema } from '../../schemas/metrics/metricsSummary.ts'
import { ServerFindingByPriority, ServerFindingByPrioritySchema } from '../../schemas/metrics/findingsByPriority.ts'
import {
	ServerResolvedVsUnresolved,
	ServerResolvedVsUnresolvedSchema,
} from '../../schemas/metrics/resolvedVsUnresolved.ts'
import { ServerTopIssuesAndInsights, ServerTopIssueSchema } from '../../schemas/metrics/topIssuesAndInsights.ts'
import { ServerInsightSchema } from '../../schemas/insight.ts'
import { ServerFindingDistName, ServerFindingDistNameSchema } from '../../schemas/metrics/findingsDistByName.ts'
import {
	findingsByPriorityArrayData,
	findingsDistByEnvData,
	findingsDistByNameData,
	findingsDistByPriorityAndDate,
	identitiesByPriorityDistData,
	identitiesLifetimeData,
	identitiesWithIssueEnvTypeDistributionData,
	keyRotationData,
	monitoredIdentitiesByEnvData,
	mostWantedIdentitiesData,
	resolvedVsUnresolvedFindingsArrayData,
	summaryData,
	topBarData,
	topIssuesAndInsightsData,
} from '../data/metricsData.ts'
import {
	ServerMostWantedIdentities,
	ServerMostWantedIdentitySchema,
} from '../../schemas/metrics/mostWantedIdentities.ts'
import {
	ServerIdentitiesDistByPriority,
	ServerIdentitiesDistByPriorityAndDate,
	ServerIdentitiesDistByPriorityAndDateSchema,
	ServerIdentitiesDistByPrioritySchema,
} from '../../schemas/metrics/identitiesDistByPriority.ts'
import { ServerIdentitiesByEnv, ServerIdentitiesByEnvSchema } from '../../schemas/metrics/identitiesByEnv.ts'
import { ServerFindingDistEnv, ServerFindingDistEnvSchema } from '../../schemas/metrics/findingsDistByEnv.ts'
import { ServerDistributionData, ServerDistributionDataSchema } from '../../schemas/metrics/distributionData.ts'
import { ServerMetricsTopBar, ServerMetricsTopBarSchema } from '../../schemas/metrics/metricsTopBar.ts'
import {
	ServerEnvTypeIdentitiesCount,
	ServerEnvTypeIdentitiesCountSchema,
} from '../../schemas/metrics/envTypeIdentitiesCount.ts'

export function mirageSummary(server: Server) {
	server.get('/metrics/summary', (): ServerMetricsSummary => {
		return ServerMetricsSummarySchema.parse(summaryData)
	})
}

export function mirageFindingsByPriority(server: Server) {
	server.get('/metrics/findings-by-priority', (): ServerFindingByPriority[] => {
		const findingsByPriority: ServerFindingByPriority[] = []
		findingsByPriorityArrayData.forEach((finding) => {
			findingsByPriority.push(ServerFindingByPrioritySchema.parse(finding))
		})
		return findingsByPriority
	})
}

export function mirageResolvedVsUnresolvedFindings(server: Server) {
	server.get('/metrics/resolved-vs-unresolved-findings', (): ServerResolvedVsUnresolved[] => {
		const serverResolvedVsUnresolvedArray: ServerResolvedVsUnresolved[] = []
		resolvedVsUnresolvedFindingsArrayData.forEach((dataPoint) => {
			serverResolvedVsUnresolvedArray.push(ServerResolvedVsUnresolvedSchema.parse(dataPoint))
		})
		return serverResolvedVsUnresolvedArray
	})
}

export function mirageTopIssuesAndInsights(server: Server) {
	server.get('/metrics/top-issues-and-insights', (): ServerTopIssuesAndInsights => {
		const topIssuesAndInsightsArray: ServerTopIssuesAndInsights = { issues: [], insights: [] }
		topIssuesAndInsightsData.issues.forEach((issue) => {
			topIssuesAndInsightsArray.issues.push(ServerTopIssueSchema.parse(issue))
		})
		topIssuesAndInsightsData.insights.forEach((insight) => {
			topIssuesAndInsightsArray.insights.push(ServerInsightSchema.parse(insight))
		})
		return topIssuesAndInsightsArray
	})
}

export function mirageFindingsDistByName(server: Server) {
	server.get('/metrics/findings-dist-by-name', (): ServerFindingDistName[] => {
		const findingsDistByName: ServerFindingDistName[] = []
		findingsDistByNameData.forEach((finding) => {
			findingsDistByName.push(ServerFindingDistNameSchema.parse(finding))
		})
		return findingsDistByName
	})
}

export function mirageMostWantedIdentities(server: Server) {
	server.get('/metrics/most-wanted-identities', (_, request): ServerMostWantedIdentities => {
		const mostWantedIdentities: ServerMostWantedIdentities = []
		const { limit } = request.queryParams
		let mostWantedIdentitiesDataSliced = mostWantedIdentitiesData
		if (typeof limit === 'string') {
			mostWantedIdentitiesDataSliced = mostWantedIdentitiesData.slice(0, parseInt(limit))
		}

		mostWantedIdentitiesDataSliced.forEach((identity) => {
			mostWantedIdentities.push(ServerMostWantedIdentitySchema.parse(identity))
		})
		return mostWantedIdentities
	})
}

export function mirageIdentitiesDistByPriority(server: Server) {
	server.get('/metrics/identities-dist-by-priority', (): ServerIdentitiesDistByPriority[] => {
		const findingsByPriorityArray: ServerIdentitiesDistByPriority[] = []
		identitiesByPriorityDistData.forEach((finding) => {
			findingsByPriorityArray.push(ServerIdentitiesDistByPrioritySchema.parse(finding))
		})
		return findingsByPriorityArray
	})
}

export function mirageMonitoredIdentitiesByEnv(server: Server) {
	server.get('/metrics/monitored-identities-by-env', (): ServerIdentitiesByEnv[] => {
		const monitoredIdentitiesByEnvArray: ServerIdentitiesByEnv[] = []
		monitoredIdentitiesByEnvData.forEach((finding) => {
			monitoredIdentitiesByEnvArray.push(ServerIdentitiesByEnvSchema.parse(finding))
		})
		return monitoredIdentitiesByEnvArray
	})
}

export function mirageFindingsDistByEnv(server: Server) {
	server.get('/metrics/findings-dist-by-env', (): ServerFindingDistEnv[] => {
		const findingsDistByEnv: ServerFindingDistEnv[] = []
		findingsDistByEnvData.forEach((finding) => {
			findingsDistByEnv.push(ServerFindingDistEnvSchema.parse(finding))
		})
		return findingsDistByEnv
	})
}

export function mirageKeyRotation(server: Server) {
	server.get('/metrics/key-rotation', (): ServerDistributionData => {
		return ServerDistributionDataSchema.parse(keyRotationData)
	})
}

export function mirageIdentitiesLifetimeDistribution(server: Server) {
	server.get('/metrics/identities-lifetime-distribution', (): ServerDistributionData => {
		return ServerDistributionDataSchema.parse(identitiesLifetimeData)
	})
}

export function mirageIdentitiesDistByPriorityAndDate(server: Server) {
	server.get('/metrics/identities-dist-by-priority-and-date', (): ServerIdentitiesDistByPriorityAndDate[] => {
		return findingsDistByPriorityAndDate.map((item) => ServerIdentitiesDistByPriorityAndDateSchema.parse(item))
	})
}

export function mirageTopBar(server: Server) {
	server.get('/metrics/top-bar', (): ServerMetricsTopBar => {
		return ServerMetricsTopBarSchema.parse(topBarData)
	})
}

export function mirageIdentitiesWithIssueEnvTypeDistribution(server: Server) {
	server.get('/metrics/identities-with-issue-env-type-distribution', (_, request): ServerEnvTypeIdentitiesCount[] => {
		const { issue_name } = request.queryParams
		let data: ServerEnvTypeIdentitiesCount[] = []
		if (issue_name === 'InactiveIdentity') {
			data = identitiesWithIssueEnvTypeDistributionData.InactiveIdentity
		} else if (issue_name === 'UnFederatedIdentity') {
			data = identitiesWithIssueEnvTypeDistributionData.UnFederatedIdentity
		}

		return data.map((datum) => ServerEnvTypeIdentitiesCountSchema.parse(datum))
	})
}
