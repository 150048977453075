import React from 'react'
import { AgChartsReact } from 'ag-charts-react'
import {
	AgChartOptions,
	AgLineSeriesOptions,
	AgLineSeriesTooltipRendererParams,
	AgTooltipRendererResult,
} from 'ag-charts-enterprise'
import {
	EnvironmentsColors,
	ServerIdentitiesByEnv,
	IdentityEnvSource,
	IdentityEnvSourceName,
} from '../schemas/metrics/identitiesByEnv.ts'
import { noDataOverlay } from './helpers.tsx'
import { formatDate } from '../utils.ts'

const chartRenderer =
	(title: string, source: IdentityEnvSource) =>
	(params: AgLineSeriesTooltipRendererParams): string | AgTooltipRendererResult => {
		const data = params.datum as ServerIdentitiesByEnv
		if (!data) return { title: '', content: '' }
		return {
			title,
			content: `${formatDate(data.date, false)} - ${data[source]}`,
		}
	}

const getChartSeries = (yKey: IdentityEnvSource): AgLineSeriesOptions => {
	const sourceName = IdentityEnvSourceName[yKey]
	const envColor = EnvironmentsColors[yKey]
	return {
		type: 'line',
		xKey: 'date',
		yKey: yKey,
		yName: sourceName,
		stroke: envColor,
		marker: {
			fill: envColor,
			size: 1,
			stroke: envColor,
			strokeWidth: 3,
		},
		tooltip: {
			renderer: chartRenderer(sourceName, yKey),
		},
	}
}

export const IdentitiesByEnvironmentChart: React.FunctionComponent<{ identitiesByEnv?: ServerIdentitiesByEnv[] }> = ({
	identitiesByEnv,
}) => {
	const options: AgChartOptions = {
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
		legend: {
			enabled: false,
		},
		data: identitiesByEnv,
		autoSize: true,
		axes: [
			{
				type: 'time',
				position: 'bottom',
				label: {
					enabled: true,
					format: '%d-%m-%Y',
				},
			},
			{
				type: 'number',
				position: 'left',
			},
		],
		series: [
			getChartSeries(IdentityEnvSource.AWS),
			getChartSeries(IdentityEnvSource.OKTA),
			getChartSeries(IdentityEnvSource.GOOGLE_WORKSPACE),
			getChartSeries(IdentityEnvSource.JUMPCLOUD),
			getChartSeries(IdentityEnvSource.ENTRA_ID),
			getChartSeries(IdentityEnvSource.GCP),
			getChartSeries(IdentityEnvSource.GITHUB),
			getChartSeries(IdentityEnvSource.AZURE),
			getChartSeries(IdentityEnvSource.SNOWFLAKE),
		],
	}

	return (
		<div className="border-2 border-gray-200 rounded grow">
			<AgChartsReact options={options} />
		</div>
	)
}
