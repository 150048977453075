import { z } from 'zod'
import { DateSchema } from '../../common.ts'
import { TagSchema } from '../../tags.ts'

export const ServerSalesforceProfileSchema = z.object({
	id: z.string(),
	name: z.string(),
	permissions: z.record(z.string(), z.boolean()).default({}),
	user_license_id: z.string().nullish(),
	user_type: z.string(),
	created_date: DateSchema,
	created_by_id: z.string(),
	last_modified_date: DateSchema,
	last_modified_by_id: z.string(),
	last_viewed_date: DateSchema.nullish(),
	last_referenced_date: DateSchema.nullish(),
	description: z.string().nullish(),
	tags: z.array(TagSchema).nullish(),
})

export type ServerSalesforceProfile = z.infer<typeof ServerSalesforceProfileSchema>
