import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent, IRowNode } from '@ag-grid-community/core'
import { removeNulls } from '../utils.ts'
import { JsonViewer } from '../components/common/JsonViewer.tsx'
import { ServerKubernetesRole, ServerKubernetesRoleBinding } from '../schemas/identities/kubernetesResourceSchema.ts'

export const KubernetesRolesTable: React.FunctionComponent<{
	data: ServerKubernetesRoleBinding
	roles: ServerKubernetesRole[]
}> = ({ data: roleBinding, roles }) => {
	const gridRef = useRef<AgGridReact<ServerKubernetesRole>>(null)
	const columnDefs = useMemo<ColDef<ServerKubernetesRole>[]>(() => {
		return [
			{
				field: 'metadata.name',
				headerName: 'Role name',
				flex: 0.7,
			},
			{
				field: 'kind',
				flex: 0.7,
			},
			{
				field: 'metadata.namespace',
				headerName: 'Namespace',
				flex: 0.7,
			},
			{
				field: 'metadata',
				cellRenderer: (params: { data?: ServerKubernetesRole; node: IRowNode }) => {
					return (
						<JsonViewer
							data={params.data?.metadata ? removeNulls(params.data.metadata) : {}}
							title="Metadata"
						/>
					)
				},
				flex: 0.7,
			},
			{
				field: 'rules',
				flex: 0.7,
				cellRenderer: (params: { data?: ServerKubernetesRole; node: IRowNode }) => {
					return <JsonViewer data={params.data?.rules ? removeNulls(params.data.rules) : []} title="Rules" />
				},
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback(
		(_params: GridReadyEvent<ServerKubernetesRole>) => {
			gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
		},
		[roleBinding],
	)
	const rowData = useMemo(() => {
		const roleSet =
			roleBinding.role_ref.kind === 'ClusterRole'
				? roles.filter((role) => role.kind === 'ClusterRole')
				: roles.filter((role) => role.kind === 'Role')
		const matchedRole = roleSet.find((role) => role.metadata.name === roleBinding.role_ref.name)
		return matchedRole ? [matchedRole] : []
	}, [roles, roleBinding])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={rowData}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
