import { Collapse, CollapseProps } from 'antd'
import React from 'react'
import KubernetesIcon from '../../../../assets/kubernetes_icon_20.svg?react'
import { KubernetesAwsAuthTable } from '../../../../tables/KubernetesAwsAuthTable.tsx'

export const KubernetesAwsAuthContext: React.FunctionComponent<{
	awsAuthConfigMapData: { mapRoles: string; mapUsers: string }
}> = ({ awsAuthConfigMapData }) => {
	const items: CollapseProps['items'] = [
		{
			key: 'kubernetesResources',
			label: (
				<div className="flex items-center space-x-2">
					<KubernetesIcon style={{ width: '35px', height: '35px' }} />
					<div>Kubernetes aws-auth data</div>
				</div>
			),
			children: <KubernetesAwsAuthTable awsAuthConfigMapData={awsAuthConfigMapData} />,
		},
	]

	return <Collapse items={items} expandIconPosition={'end'} />
}
