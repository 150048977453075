import { z } from 'zod'

export const DateSchema = z.string().pipe(z.coerce.date())
export type DateType = z.infer<typeof DateSchema>

export const SeveritySchema = z.enum(['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'])
export type SeverityEnum = z.infer<typeof SeveritySchema>

const severityWeight: string[] = [
	SeveritySchema.enum.LOW,
	SeveritySchema.enum.MEDIUM,
	SeveritySchema.enum.HIGH,
	SeveritySchema.enum.CRITICAL,
]

export const compareSeverity = (a: SeverityEnum, b: SeverityEnum): number => {
	return severityWeight.indexOf(a) - severityWeight.indexOf(b)
}
