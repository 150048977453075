import { Node, NodeProps } from '@xyflow/react'
import {
	EntraIdCommonAuthenticationCredential,
	EntraIdCredentialType,
} from '../../../../schemas/identities/entraId/entraIdApplicationXc.ts'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'

export type EntraIdSpAuthCredentialNodeType = Node<
	{ credential: EntraIdCommonAuthenticationCredential },
	'entraIdCredential'
>
type EntraIdSpAuthCredentialNodeProps = NodeProps<EntraIdSpAuthCredentialNodeType>

export const EntraIdSpAuthCredentialNode = ({ data: { credential } }: EntraIdSpAuthCredentialNodeProps) => {
	const credentialId = credential?.keyId
	const credentialName = credential?.displayName
	const credentialTypeName = credential?.type === EntraIdCredentialType.ClientSecret ? 'Client Secret' : 'Certificate'

	return (
		<BaseCredentialsNode
			label={credentialTypeName}
			name={credentialId ? { text: credentialName || credentialId, tooltipText: credentialId } : null}
		/>
	)
}
