import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { ServerAwsIamRoleXc } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'

export type AwsRoleNodeType = Node<{ awsIamRoleXc: ServerAwsIamRoleXc }, 'awsIamRole'>
type AwsRoleNodeProps = NodeProps<AwsRoleNodeType>

export const AwsRoleNode = ({ data: { awsIamRoleXc } }: AwsRoleNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'awsIamRole' && highlightedNode.id === awsIamRoleXc.RoleId,
	)

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)
	return (
		<BaseNode label="AWS Role" name={{ text: awsIamRoleXc.RoleName }}>
			<div className={iconWrapperClassName}>
				<TeamOutlined className="text-2xl" />
			</div>
		</BaseNode>
	)
}
