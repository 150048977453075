import { CloudOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { Tooltip } from 'antd'
import { BaseNode, BaseNodeProps } from './BaseNode.tsx'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { AccountPriorityEnum } from '../../../../../schemas/environments/accountPriorityEnum.ts'
import { ReactNode } from 'react'

type BaseEnvironmentNodeProps = {
	label: ReactNode
	priority?: AccountPriorityEnum
	tooltipTitle?: string
	name?: BaseNodeProps['name']
	children?: ReactNode
	icon?: ReactNode
}

export const BaseEnvironmentNode = ({
	label,
	priority,
	name,
	tooltipTitle,
	children,
	icon,
}: BaseEnvironmentNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.environment.bgHover} ${nodeColorClassNames.environment.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			'outline outline-red-600 outline-4 outline-offset-2 p-6': priority === AccountPriorityEnum.CRITICAL,
			'outline outline-orange-400 outline-4 outline-offset-2 p-6': priority === AccountPriorityEnum.HIGH,
		},
	)

	const iconNode = <div className={iconWrapperClassName}>{icon ? icon : <CloudOutlined />}</div>

	return (
		<BaseNode label={label} name={name}>
			{children}
			{tooltipTitle ? <Tooltip title={tooltipTitle}>{iconNode}</Tooltip> : iconNode}
		</BaseNode>
	)
}
