import React, { useCallback, useRef, useState } from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { formatDate, getCsvFileName } from '../../../../utils.ts'
import { ColDef } from '@ag-grid-community/core'
import { JsonViewer } from '../../../common/JsonViewer.tsx'
import ExportIcon from '../../../../assets/export_icon_20.svg?react'
import { ServerEntraIdChangeLog } from '../../../../schemas/identities/entraId/entraIdChangeLogSchema.ts'
import { AgGridReact } from '@ag-grid-community/react'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { EntraIdChangeLogsAggregationsTable } from './EntraIdServicePrincipalChangeLogsAggregationTable.tsx'

export const EntraIdChangeLogsContent = ({
	changeLogs,
	identity,
}: {
	changeLogs: ServerEntraIdChangeLog[]
	identity: ServerIdentity
}) => {
	const gridRef: React.RefObject<AgGridReact> = useRef<AgGridReact>(null)
	const [defaultColDef] = useState({
		sortable: true,
		resizable: true,
		filter: true,
		cellStyle: () => ({
			display: 'flex',
			alignItems: 'center',
		}),
		flex: 1,
	})
	const [isModalOpen, setIsModalOpen] = useState(false)
	const exportToCsv = useCallback(() => {
		gridRef?.current!.api.exportDataAsCsv({
			fileName: getCsvFileName(`ownership-${identity.entra_id_service_principal?.display_name}`, false),
		})
	}, [])

	const [changeLogColumnDefs] = useState<ColDef<ServerEntraIdChangeLog>[]>([
		{
			field: 'event_time',
			headerName: 'Event Time',
			valueGetter: (params: { data?: ServerEntraIdChangeLog }) => formatDate(params.data?.event_time),
			sort: 'desc',
		},
		{
			field: 'actor_principal_name',
			headerName: 'Actor',
			cellRenderer: (params: { data: ServerEntraIdChangeLog }) => {
				return <Tooltip title={params.data.actor_principal_id}>{params.data.actor_principal_name}</Tooltip>
			},
		},
		{
			field: 'operation_name',
			headerName: 'Operation Name',
		},
		{
			field: 'target_resources',
			headerName: 'Target Resources',
			valueFormatter: ({ value }) => JSON.stringify(value),
			cellRenderer: (params: { data: ServerEntraIdChangeLog }) => {
				return params.data.target_resources.length ? (
					<JsonViewer data={params.data?.target_resources} title="Target Resources" />
				) : (
					'Empty'
				)
			},
		},
		{
			field: 'source_ip_address',
			headerName: 'Source IP Address',
		},
	])
	return (
		<>
			{changeLogs.length > 0 ? (
				<>
					<div className="h-64 overflow-auto">
						<EntraIdChangeLogsAggregationsTable changeLogs={changeLogs} />
					</div>

					<div className="flex justify-between">
						<button
							className="mt-4 underline cursor-pointer text-blue-600"
							onClick={() => setIsModalOpen(true)}
						>
							See all events
						</button>
					</div>
				</>
			) : (
				<div className={'text-center'}>No ownership data available</div>
			)}
			<Modal
				centered={true}
				width={1024}
				title={'Ownership'}
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				onOk={() => setIsModalOpen(false)}
				footer={[
					<div key="footer" className="flex justify-between items-center">
						<div className="flex">
							<Button
								icon={<ExportIcon />}
								onClick={exportToCsv}
								className="bg-white text-gray-500 px-2 flex items-start mr-2"
							>
								Export CSV
							</Button>
							<Button className="bg-black text-white" onClick={() => setIsModalOpen(false)}>
								Done
							</Button>
						</div>
					</div>,
				]}
			>
				<div className="flex overflow-y-auto h-[70vh]">
					<AgGridReact
						className={'ag-theme-alpine w-full'}
						rowData={changeLogs}
						rowHeight={54}
						columnDefs={changeLogColumnDefs}
						defaultColDef={defaultColDef}
						enableCellTextSelection
					/>
				</div>
			</Modal>
		</>
	)
}
