import { z } from 'zod'

export const ServerAzureDevopsPatSchema = z.object({
	client_id: z.string(),
	access_id: z.string(),
	authorization_id: z.string(),
	host_authorization_id: z.string(),
	user_id: z.string(),
	valid_from: z.string(),
	valid_to: z.string(),
	display_name: z.string(),
	scope: z.string(),
	target_accounts: z.array(z.string()),
	is_valid: z.boolean(),
	is_public: z.boolean(),
	public_data: z.string().nullable(),
	source: z.string().nullable(),
	claims: z.record(z.any()).nullable(),
})

export type ServerAzureDevopsPat = z.infer<typeof ServerAzureDevopsPatSchema>
