import { NodeProps, Node } from '@xyflow/react'
import { ServerGithubAppPermission } from '../../../../schemas/identities/githubAppInstallationSchema.ts'
import { FileTextOutlined } from '@ant-design/icons'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'

export type GithubAppPermissionNodeType = Node<{ permissions: ServerGithubAppPermission[] }, 'githubAppPermission'>
type GithubAppPermissionNodeProps = NodeProps<GithubAppPermissionNodeType>

export const GithubAppPermissionsNode = ({ data: { permissions } }: GithubAppPermissionNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)

	return (
		<BaseNode label="GitHub App Permissions">
			{permissions.length > 1 && (
				<NodeGroupBadge
					items={permissions}
					nodeColorType="permission"
					tooltipContent={`Has permissions for ${permissions.length} scopes`}
				/>
			)}
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
