import { z } from 'zod'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'
import { ServerGoogleWorkspaceEnvironmentSchema } from './googleWorkspaceEnvironment.ts'
import { ServerGcpProjectSchema } from './gcpProject.ts'

export const ServerGcpEnvironmentSchema = ServerAbstractEnvironmentModelSchema.extend({
	org_id: z.string().nullish(),
	gcp_projects: z.array(ServerGcpProjectSchema),
	google_workspace_envs: z.array(ServerGoogleWorkspaceEnvironmentSchema),
})

export type ServerGcpEnvironment = z.infer<typeof ServerGcpEnvironmentSchema>
