import { MutableRefObject, useCallback, useEffect, useMemo } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import {
	ColDef,
	FilterChangedEvent,
	FilterModel,
	IRowNode,
	IServerSideDatasource,
	IServerSideGetRowsParams,
	PaginationChangedEvent,
	RowClickedEvent,
	SetFilterValuesFuncParams,
	SideBarDef,
} from '@ag-grid-community/core'
import { Tag, Tooltip } from 'antd'
import {
	findingsUrlQuickSearchFilterParameter,
	IssueName,
	IssuesPageLens,
	IssuesTabName,
	IssueStatus,
	IssueStatusMap,
	IssueTablePaginatedRow,
	PaginatedFindingsPageSearch,
	PriorityType,
	PriorityTypeMap,
	ServerIssueIdentityEnvironment,
} from '../../schemas/issue.ts'
import { IssueNameTag } from '../../components/common/IssueNameTag.tsx'
import { IssueSourceIcon } from '../../components/common/IssueSourceIcon.tsx'
import { formatDate, formatRelativeDateText } from '../../utils.ts'
import { IdentityTypeTag } from '../../components/common/IdentityTypeTag.tsx'
import { PriorityNameTag } from '../../components/common/PriorityNameTag.tsx'
import { IssueStatusTag } from '../../components/common/IssueStatusTag.tsx'
import { getIssuesTableRowStyle } from '../../tables/helpers.ts'
import {
	IdentitySource,
	IdentitySourceMap,
	IdentityType,
	isIdentitySource,
	SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES,
} from '../../schemas/identity.ts'
import KubernetesIcon from '../../assets/kubernetes_icon_20.svg?react'
import PostgresIcon from '../../assets/postgres_icon_20.svg?react'
import {
	fetchIssueNames,
	fetchIssuesAssignees,
	fetchIssuesIdentityAccounts,
	fetchIssuesQuery,
} from '../../api/issues.ts'
import { RoutePaths } from '../RoutePaths.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket.ts'
import { getQueryFilterFromTableFilterModel, refreshDynamicFilterValues } from './findingsTableUtils.ts'
import { IssueNameTagFilter } from '../../components/common/IssueNameTagFilter.tsx'
import { supportedTableDateFilterTypes, supportedTableTextFilterTypes } from '../../utils/tableQueryUtils.ts'
import { IssueSourceFilterIcon } from '../../components/common/IssueSourceIconFilter.tsx'
import { IdentityTypeWithIcon } from '../../components/common/IdentityTypeWithIcon.tsx'
import { isEmpty, isEqual, omit } from 'lodash'
import { EnvironmentType, isEnvironmentType } from '../../schemas/envType.ts'
import { FindingsAssigneeCell } from './FindingsAssigneeCell.tsx'

const defaultPageSize = 100

type TableContext = { tab?: IssuesTabName; lens?: IssuesPageLens | IssueName }

const dataSource: IServerSideDatasource = {
	getRows: (params: IServerSideGetRowsParams<IssueTablePaginatedRow, TableContext>) => {
		fetchIssuesQuery(
			{
				pagination: {
					offset: params.request.startRow!,
					limit: params.request.endRow! - params.request.startRow!,
				},
				sort: params.request.sortModel.map((col) => ({
					field: col.colId,
					direction: col.sort,
				})),
				filter: getQueryFilterFromTableFilterModel(params.request.filterModel as FilterModel),
			},
			params.context.lens,
			params.context.tab,
		).then((result) => {
			params.success({ rowData: result.issues, rowCount: result.total_count })
		})
	},
}

export const FindingsTablePaginated: React.FC<{
	gridRef: MutableRefObject<AgGridReact<IssueTablePaginatedRow> | null>
}> = ({ gridRef }) => {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { tab, lens, pagination, issueId, issueFilter } = useSearch({
		from: RoutePaths.Findings,
	}) as PaginatedFindingsPageSearch
	const navigate = useNavigate({ from: RoutePaths.Findings })

	useEffect(() => {
		gridRef.current?.api?.refreshServerSide({ purge: true })
		refreshDynamicFilterValues(gridRef?.current?.api)
	}, [tab, lens])

	// An effect to match the internal table pagination to the one in the URL
	useEffect(() => {
		const currentPage = gridRef?.current?.api?.paginationGetCurrentPage() ?? 0
		const currentPageSize = gridRef?.current?.api?.getGridOption('paginationPageSize') ?? defaultPageSize
		const currentSearchPage = pagination?.page ?? 0
		const currentSearchPageSize = pagination?.pageSize ?? defaultPageSize
		// When the internal table pagination changes we set the URL to match it, causing this effect to re-run. To
		//  avoid a loop, we only set the internal pagination to the URL if it is different from the one that is already
		//  set in the table
		if (currentPageSize !== currentSearchPageSize) {
			gridRef?.current?.api?.setGridOption('paginationPageSize', currentSearchPageSize)
		}
		if (currentPage !== currentSearchPage) {
			gridRef?.current?.api?.paginationGoToPage(pagination?.page ?? 0)
		}
	}, [pagination?.page, pagination?.pageSize])

	const onIssueClicked = (row: RowClickedEvent<IssueTablePaginatedRow>) => {
		if (row.data?.identity_source && SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES.includes(row.data.identity_source)) {
			void navigate({
				to: RoutePaths.Identity,
				params: { identityId: row.data.identity_id },
				search: () => ({ issueId: row.data?.id }),
			})
			return
		}

		void navigate({
			search: (prev) => ({ ...prev, issueId: row.data?.id ?? '' }),
		})
	}

	const onFirstDataRendered = useCallback(() => {
		if (issueId) {
			gridRef?.current?.api?.forEachNode((node: IRowNode<IssueTablePaginatedRow>) => {
				if (node.data?.id === issueId) {
					node.setSelected(true)
					gridRef?.current?.api?.ensureNodeVisible(node)
				}
			})
		}

		gridRef?.current?.api?.sizeColumnsToFit()

		if (pagination) {
			gridRef?.current?.api?.setGridOption('paginationPageSize', pagination.pageSize)
			gridRef?.current?.api?.paginationGoToPage(pagination.page ?? 0)
		}

		// Pre-fetch dynamic filter values
		refreshDynamicFilterValues(gridRef?.current?.api)
	}, [issueId, pagination])

	const onPaginationChanged = useCallback((event: PaginationChangedEvent<IssueTablePaginatedRow>) => {
		if (!event.newPage && !event.newPageSize) {
			return
		}

		const page = event.api.paginationGetCurrentPage()
		const pageSize = event.api.paginationGetPageSize()
		void navigate({
			search: (prev) => {
				if (page === 0 && pageSize === defaultPageSize) {
					return omit(prev, 'pagination') as PaginatedFindingsPageSearch
				} else {
					return { ...prev, pagination: { page, pageSize } } as PaginatedFindingsPageSearch
				}
			},
		})
	}, [])

	const onIssuesTableFilterChanged = useCallback((e: FilterChangedEvent<IssueTablePaginatedRow>) => {
		const changedColumnId = e.columns.length === 1 ? e.columns[0].getId() : undefined
		refreshDynamicFilterValues(gridRef?.current?.api, changedColumnId)

		const issueFilter = gridRef?.current?.api?.getFilterModel()
		void navigate({
			search: (prev) => {
				const newSearch = { ...prev } as PaginatedFindingsPageSearch
				if (isEmpty(issueFilter)) {
					delete newSearch.issueFilter
				} else {
					newSearch.issueFilter = issueFilter
				}

				// When a filter change we need to reset the page to the first page (0)
				if (newSearch.pagination) {
					if (newSearch.pagination.pageSize === defaultPageSize) {
						delete newSearch.pagination
					} else {
						newSearch.pagination = { pageSize: newSearch.pagination.pageSize }
					}
				}

				return newSearch
			},
		})
	}, [])

	// An effect to match the internal table filter to the one in the URL
	useEffect(() => {
		const currentFilterModel = gridRef?.current?.api?.getFilterModel()
		// When the internal table filter changes we set the URL to match it, causing this effect to re-run. To avoid a
		//  loop, we only set the internal filter to the URL if it is different from the one that is already set in the
		//  table
		if (isEqual(currentFilterModel, issueFilter)) {
			return
		}

		gridRef?.current?.api?.setFilterModel(issueFilter ?? null)
	}, [issueFilter])

	const onGridReady = useCallback(() => {
		gridRef?.current?.api?.setFilterModel(issueFilter ?? null)
	}, [issueFilter])

	const onRowClicked = (row: RowClickedEvent<IssueTablePaginatedRow>) => {
		const selection = window.getSelection()?.toString()
		if (selection?.length && selection.length > 0) {
			return
		}
		const issueName: string | undefined = row.data?.issue_name ?? undefined
		trackEvent(LogRocketTrackEvent.IssueDrawerOpenedFromFindingsTable, { issueName })
		onIssueClicked(row)
	}

	const columnDefs: ColDef<IssueTablePaginatedRow>[] = useMemo(() => {
		return [
			{
				headerName: 'Issue Name',
				field: 'issue_name',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params: SetFilterValuesFuncParams<IssueTablePaginatedRow, IssueName>) => {
						const { lens, tab } = params.api.getGridOption('context') as TableContext
						const filter: FilterModel = { ...params.api.getFilterModel() }
						if (filter.issue_name) {
							delete filter.issue_name
						}
						fetchIssueNames({ filter: getQueryFilterFromTableFilterModel(filter) }, tab, lens).then(
							(issueNames) => {
								params.success(issueNames)
							},
						)
					},
					cellRenderer: (params: { value: IssueName }) => {
						return <IssueNameTagFilter name={params.value} />
					},
				},
				minWidth: 265,
				flex: 1.9,
				valueGetter: (params: { data?: IssueTablePaginatedRow }) => params.data?.issue_name || '',
				cellRenderer: (params: { data?: IssueTablePaginatedRow; node: IRowNode }) => {
					if (params.node.group) return
					return <IssueNameTag name={params.data?.issue_name} />
				},
			},
			{
				headerName: 'Identity Name',
				field: 'identity_literal_friendly_name',
				filter: 'agTextColumnFilter',
				filterParams: {
					filterOptions: supportedTableTextFilterTypes,
				},
				minWidth: 260,
				flex: 2,
				valueGetter: (params: { data?: IssueTablePaginatedRow }) =>
					params.data?.identity_literal_friendly_name ?? params.data?.identity_literal ?? '',
				cellRenderer: (params: { data?: IssueTablePaginatedRow; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<Tooltip placement="bottomLeft" title={params.data?.identity_literal}>
							{params.data?.identity_literal_friendly_name ?? params.data?.identity_literal}
						</Tooltip>
					)
				},
			},
			{
				headerName: 'Identity Literal',
				suppressColumnsToolPanel: true,
				suppressNavigable: true,
				suppressMovable: true,
				sortable: false,
				resizable: false,
				filter: false,
				width: 1,
				valueGetter: (params: { data?: IssueTablePaginatedRow }) => params.data?.identity_literal ?? '',
				cellRenderer: () => null,
			},
			{
				headerName: 'Status',
				field: 'status',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: Object.values(IssueStatus),
					cellRenderer: (params: { value: IssueStatus }) => {
						return IssueStatusMap[params.value] ?? params.value
					},
				},
				minWidth: 110,
				flex: 1,
				valueGetter: (params: { data?: IssueTablePaginatedRow }) => params.data?.status,
				cellRenderer: (params: { data?: IssueTablePaginatedRow; node: IRowNode }) => {
					if (params.node.group) return
					return <IssueStatusTag issue={params.data} />
				},
			},
			{
				headerName: 'Source Environment',
				field: 'identity_account_literal',
				filter: 'agSetColumnFilter',
				minWidth: 200,
				flex: 1.2,
				valueGetter: (params: { data?: ServerIssueIdentityEnvironment }) => {
					if (params.data?.identity_account_literal_friendly_name) {
						return `${params.data?.identity_env_type} - ${params.data?.identity_account_literal_friendly_name} (${params.data?.identity_account_literal})`
					} else {
						// Concatenate the issueSource and account_literal to get the full value
						return `${params.data?.identity_env_type} - ${params.data?.identity_account_literal}`
					}
				},
				filterParams: {
					treeList: true,
					treeListPathGetter: (value: ServerIssueIdentityEnvironment) => {
						const name = value.identity_account_literal_friendly_name
							? `${value.identity_account_literal_friendly_name} (${value.identity_account_literal})`
							: value.identity_account_literal
						return [value.identity_env_type, name]
					},
					cellRenderer: (params: { value: string | EnvironmentType }) => {
						if (isEnvironmentType(params.value)) {
							return <IssueSourceFilterIcon source={params.value} />
						}

						return params.value
					},
					keyCreator: (params: { value: ServerIssueIdentityEnvironment }) => {
						return params.value.identity_account_literal
					},
					values: (
						params: SetFilterValuesFuncParams<IssueTablePaginatedRow, ServerIssueIdentityEnvironment>,
					) => {
						const { lens, tab } = params.api.getGridOption('context') as TableContext
						const filter: FilterModel = { ...params.api.getFilterModel() }
						if (filter.identity_account_literal) {
							delete filter.identity_account_literal
						}
						fetchIssuesIdentityAccounts(
							{ filter: getQueryFilterFromTableFilterModel(filter) },
							tab,
							lens,
						).then((identityAccountLiteral) => {
							params.success(identityAccountLiteral)
						})
					},
				},
				cellRenderer: (params: { data?: IssueTablePaginatedRow }) => {
					return (
						<>
							{params.data?.identity_source === IdentitySource.KUBERNETES_RESOURCE ? (
								<div className="flex -space-x-4 rtl:space-x-reverse">
									<IssueSourceIcon source={params.data?.identity_env_type} />
									<Tooltip title="Kubernetes">
										<span>
											<KubernetesIcon />
										</span>
									</Tooltip>
								</div>
							) : params.data?.identity_source === IdentitySource.POSTGRES_ROLE ||
							  params.data?.identity_source === IdentitySource.AZURE_POSTGRES_ROLE ? (
								<div className="flex -space-x-4 rtl:space-x-reverse">
									<IssueSourceIcon source={params.data?.identity_env_type} />
									<Tooltip title="Postgres">
										<span>
											<PostgresIcon />
										</span>
									</Tooltip>
								</div>
							) : (
								<IssueSourceIcon source={params.data?.identity_env_type} />
							)}

							<Tooltip
								placement="bottomLeft"
								title={
									params.data?.identity_account_literal_friendly_name
										? `${params.data?.identity_account_literal_friendly_name} - (${params.data?.identity_account_literal})`
										: params.data?.identity_account_literal
								}
							>
								<div className="ml-2 truncate">
									{params.data?.identity_account_literal_friendly_name ??
										params.data?.identity_account_literal}
								</div>
							</Tooltip>
						</>
					)
				},
			},

			{
				headerName: 'Identity Type',
				field: 'identity_type',
				filter: 'agSetColumnFilter',
				minWidth: 150,
				flex: 1,
				filterParams: {
					values: Object.values(IdentityType),
					cellRenderer: (params: { value: string }) => {
						return <IdentityTypeWithIcon type={params.value} />
					},
				},
				valueGetter: (params: { data?: IssueTablePaginatedRow }) => params.data?.identity_type,
				cellRenderer: (params: { data?: IssueTablePaginatedRow; node: IRowNode }) => {
					if (params.node.group) return
					return <IdentityTypeTag type={params.data?.identity_type} />
				},
			},
			{
				headerName: 'Priority',
				field: 'priority',
				filter: 'agSetColumnFilter',
				flex: 0.9,
				minWidth: 150,
				filterParams: {
					values: Object.values(PriorityType).filter((priority) => typeof priority === 'number'),
					cellRenderer: (params: { value: PriorityType | string }) => {
						return typeof params.value === 'string' ? params.value : PriorityTypeMap[params.value]
					},
				},
				valueGetter: (params: { data?: IssueTablePaginatedRow }) => {
					return PriorityTypeMap[params.data?.priority ?? PriorityType.NOT_CALCULATED]
				},
				cellRenderer: (params: { data?: IssueTablePaginatedRow; node: IRowNode }) => {
					if (params.node.group) return
					return <PriorityNameTag priority={params.data?.priority} />
				},
				sort: 'desc',
				sortIndex: 0,
			},
			{
				headerName: 'Assignee',
				field: 'assignee',
				filter: 'agSetColumnFilter',
				// We don't want to sort by assignee because we only have the assignee uuid in the db, making it impossible to sort at the server side.
				sortable: false,
				minWidth: 160,
				filterParams: {
					values: (params: SetFilterValuesFuncParams<IssueTablePaginatedRow, string>) => {
						const { lens, tab } = params.api.getGridOption('context') as TableContext
						const filter: FilterModel = { ...params.api.getFilterModel() }
						if (filter.assignee) {
							delete filter.assignee
						}
						fetchIssuesAssignees({ filter: getQueryFilterFromTableFilterModel(filter) }, tab, lens).then(
							(assignees) => {
								params.success(assignees)
							},
						)
					},
					cellRenderer: (params: { value: string }) => {
						return <FindingsAssigneeCell assigneeUuid={params.value} compact={true} />
					},
				},
				valueGetter: (params: { data?: IssueTablePaginatedRow }) => params.data?.assignee,
				cellRenderer: (params: { value: string; node: IRowNode }) => {
					if (params.node.group || !params.value) return
					return <FindingsAssigneeCell assigneeUuid={params.value} />
				},
			},
			{
				headerName: 'Issue Source',
				field: 'identity_source',
				filter: 'agSetColumnFilter',
				flex: 1,
				minWidth: 180,
				filterParams: {
					values: Object.values(IdentitySource),
					cellRenderer: (params: { value: string }) => {
						return isIdentitySource(params.value) ? IdentitySourceMap[params.value] : params.value
					},
				},
				valueGetter: (params: { data?: IssueTablePaginatedRow }) => {
					return params.data?.identity_source ? IdentitySourceMap[params.data?.identity_source] : 'N/A'
				},
				cellRenderer: (params: { value: string; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<Tag>
							<div className="flex items-center">
								<div className="pl-1 text-gray-800 text text-sm">{params.value}</div>
							</div>
						</Tag>
					)
				},
			},
			{
				headerName: 'Identity Last Activity',
				field: 'identity_last_activity',
				filter: 'agDateColumnFilter',
				flex: 1,
				minWidth: 180,
				sortIndex: 1,
				sort: 'desc',
				filterParams: {
					filterOptions: supportedTableDateFilterTypes,
				},
				cellRenderer: (params: { value?: Date | null; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
							<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
						</Tooltip>
					)
				},
			},
			{
				headerName: 'Issue Last Seen',
				field: 'last_seen',
				filter: 'agDateColumnFilter',
				minWidth: 150,
				flex: 1,
				filterParams: {
					filterOptions: supportedTableDateFilterTypes,
				},
				cellRenderer: (params: { value?: IssueTablePaginatedRow['last_seen']; node: IRowNode }) => {
					if (params.node.group) return
					return (
						<Tooltip placement="bottomLeft" title={formatDate(params.value)}>
							<div className="text-gray-400">{formatRelativeDateText(params.value, true)}</div>
						</Tooltip>
					)
				},
			},
			{
				headerName: 'Issue Created At',
				field: 'created_at',
				filter: 'agDateColumnFilter',
				minWidth: 150,
				flex: 1,
				filterParams: {
					filterOptions: supportedTableDateFilterTypes,
				},
				cellRenderer: (params: { value?: IssueTablePaginatedRow['created_at']; node: IRowNode }) => {
					if (params.node.group) return
					return <div className="text-gray-400">{formatDate(params.value)}</div>
				},
			},
			{
				headerName: '# Tickets',
				field: 'integration_tickets',
				filter: false,
				sortable: false,
				hide: true,
				minWidth: 80,
				flex: 1,
				valueGetter: (params: { data?: IssueTablePaginatedRow }) => {
					return params.data?.integration_tickets?.length
				},
				cellRenderer: (params: { value: string; node: IRowNode }) => {
					if (params.node.group) return
					return <div className="text-gray-400">{params.value}</div>
				},
			},
			{
				colId: findingsUrlQuickSearchFilterParameter,
				hide: true,
				suppressColumnsToolPanel: true,
				filter: 'agTextColumnFilter',
			},
		]
	}, [])
	const defaultColDef = useMemo<ColDef<IssueTablePaginatedRow>>(
		() => ({
			resizable: true,
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			filterParams: {
				defaultToNothingSelected: true,
				maxNumConditions: 1,
			},
		}),
		[],
	)

	const sideBar = useMemo<SideBarDef>(
		() => ({
			position: 'left',
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					minWidth: 225,
					maxWidth: 225,
					width: 225,
					toolPanelParams: {
						suppressPivotMode: true,
						suppressRowGroups: true,
						suppressValues: true,
					},
				},
				{
					id: 'filters',
					labelDefault: 'Filters',
					labelKey: 'filters',
					iconKey: 'filter',
					toolPanel: 'agFiltersToolPanel',
					minWidth: 300,
					maxWidth: 400,
					width: 300,
				},
			],
		}),
		[],
	)
	const context: TableContext = useMemo(() => ({ tab, lens }), [tab, lens])
	return (
		<AgGridReact
			ref={gridRef}
			getRowStyle={getIssuesTableRowStyle}
			getRowId={({ data }) => data.id}
			onRowClicked={onRowClicked}
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowHeight={54}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			onFilterChanged={onIssuesTableFilterChanged}
			suppressDragLeaveHidesColumns={true}
			overlayNoRowsTemplate={'No data'}
			rowSelection={'single'}
			suppressHorizontalScroll={false}
			sideBar={sideBar}
			rowGroupPanelShow="never"
			enableCellTextSelection
			rowModelType="serverSide"
			serverSideDatasource={dataSource}
			pagination
			context={context}
			onGridReady={onGridReady}
			onPaginationChanged={onPaginationChanged}
			onFirstDataRendered={onFirstDataRendered}
		/>
	)
}
