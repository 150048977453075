import { Devtools } from './components/Devtools.tsx'
import { QueryClientProvider } from '@tanstack/react-query'
import { Outlet } from '@tanstack/react-router'
import { AuthProvider } from './services/auth'
import { settings } from './env-settings.ts'
import { MainLayout } from './components/layout/MainLayout.tsx'
import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'
import './styles/App.css'
import { ConfigProvider } from 'antd'
import { isDev } from './utils/devUtils.ts'
import { queryClient } from './api/queryClient.ts'

export function App() {
	return (
		<div className="app">
			<AuthProvider clientId={settings.viteAuthClientId} baseUrl={settings.viteAuthServerBaseUrl}>
				<QueryClientProvider client={queryClient}>
					<ConfigProvider
						theme={{
							components: {
								Tabs: {
									itemSelectedColor: '#505059',
									itemColor: '#A1A2A6',
									inkBarColor: '#2D4037',
									itemHoverColor: '#A1A2A6',
								},
								Segmented: {
									itemColor: 'black',
									itemSelectedBg: '#2D4037',
									itemSelectedColor: 'white',
								},
							},
						}}
					>
						<MainLayout>
							<main className="main-layout overflow-y-auto pt-2 pb-2 pr-2 h-full rounded-md">
								<Outlet />
							</main>
						</MainLayout>

						{isDev ? <Devtools /> : null}
					</ConfigProvider>
				</QueryClientProvider>
			</AuthProvider>
		</div>
	)
}
