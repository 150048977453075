import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { Layout, Menu, MenuProps, Button, ConfigProvider } from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import { IdentitiesPageLens } from '../../../schemas/identity.ts'
import { isDemo } from '../../../utils/demoUtils.ts'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons'
import { useIdentitiesContext } from '../context/IdentitiesProvider.tsx'
import ResourcesIcon from '../../../assets/resources.svg?react'
import CloudIcon from '../../../assets/cloud_icon_20.svg?react'
import AwsIcon from '../../../assets/aws_icon.svg?react'
import GcpIcon from '../../../assets/gcp_icon_20.svg?react'
import AzureIcon from '../../../assets/azure_icon.svg?react'
import OktaIcon from '../../../assets/okta_icon_no_color.svg?react'
import JumpcloudIcon from '../../../assets/jumpcloud_icon_16.svg?react'
import EntraIdIcon from '../../../assets/entra_id_icon_16.svg?react'
import GoogleWorkspaceIcon from '../../../assets/google_workspace_logo_16.svg?react'
import DatastoresIcon from '../../../assets/hard_drives.svg?react'
import PostgresIcon from '../../../assets/postgres_icon_20.svg?react'
import SnowflakeIcon from '../../../assets/snowflake_icon.svg?react'
import DatabricksIcon from '../../../assets/databricks_icon.svg?react'
import WorkloadsIcon from '../../../assets/workloads.svg?react'
import KubernetesIcon from '../../../assets/kubernetes_icon_20.svg?react'
import OffBoardedIcon from '../../../assets/off_boarded_icon_20.svg?react'
import AccessKeysIcon from '../../../assets/access_keys_icon_20.svg?react'
import UnfederatedIcon from '../../../assets/unfederated_identity_16.svg?react'
import RecentlyCreatedIcon from '../../../assets/recently_created.svg?react'
import MostWantedIcon from '../../../assets/most_wanted.svg?react'
import OverPrivilegedIcon from '../../../assets/over_privileged_identities.svg?react'
import ThirdPartyIcon from '../../../assets/third_party.svg?react'

type MenuItem = Required<MenuProps>['items'][number]

const MenuItemLabel = ({ children }: { children: React.ReactNode }) => <span className="truncate">{children}</span>

const MenuItemIcon = ({ Icon, ...rest }: { Icon: React.ComponentType<{ className?: string }> }) => (
	<span {...rest}>
		<Icon className="w-5 h-5 !text-base" />
	</span>
)

const siderStyle: React.CSSProperties = {
	lineHeight: '120px',
	backgroundColor: '#353535',
	boxShadow: '0px 0px 10px 0px #0000001A',
}

export const IdentitiesSideBar = () => {
	const { lens, tab } = useSearch({ from: RoutePaths.Identities })
	const { gridRef } = useIdentitiesContext()
	const [isCollapsed, setIsCollapsed] = useState(false)
	const navigate = useNavigate({ from: RoutePaths.Identities })

	useEffect(() => {
		const columnFitTimeout = setTimeout(() => {
			gridRef.current?.api?.sizeColumnsToFit()
		}, 200)

		return () => {
			clearTimeout(columnFitTimeout)
		}
	}, [isCollapsed])

	const onClick: MenuClickEventHandler = (menuEntry) => {
		const newLens: IdentitiesPageLens | undefined = Object.values(IdentitiesPageLens).includes(
			menuEntry.key as IdentitiesPageLens,
		)
			? (menuEntry.key as IdentitiesPageLens)
			: undefined

		void navigate({
			search: {
				tab,
				...(newLens && { lens: newLens }),
			},
		})
	}

	const [items] = useState<Array<MenuItem>>(() => {
		const allItems: MenuItem[] = []
		allItems.push({
			key: IdentitiesPageLens.ALL,
			label: <MenuItemLabel>All</MenuItemLabel>,
			icon: <MenuItemIcon Icon={ResourcesIcon} />,
		})
		allItems.push({
			key: 'sub-menu-cloud-providers',
			label: <MenuItemLabel>Cloud Providers</MenuItemLabel>,
			icon: <MenuItemIcon Icon={CloudIcon} />,
			children: [
				{
					key: IdentitiesPageLens.AWS,
					label: <MenuItemLabel>AWS</MenuItemLabel>,
					icon: <MenuItemIcon Icon={AwsIcon} />,
				},
				{
					key: IdentitiesPageLens.GCP,
					label: <MenuItemLabel>GCP</MenuItemLabel>,
					icon: <MenuItemIcon Icon={GcpIcon} />,
				},
				{
					key: IdentitiesPageLens.AZURE,
					label: <MenuItemLabel>Azure</MenuItemLabel>,
					icon: <MenuItemIcon Icon={AzureIcon} />,
				},
			],
		})
		allItems.push({
			key: 'sub-menu-identity-providers',
			label: <MenuItemLabel>Identity Providers</MenuItemLabel>,
			icon: <MenuItemIcon Icon={TeamOutlined} />,
			children: [
				{
					key: IdentitiesPageLens.OKTA,
					label: <MenuItemLabel>Okta</MenuItemLabel>,
					icon: <MenuItemIcon Icon={OktaIcon} />,
				},
				{
					key: IdentitiesPageLens.JUMPCLOUD,
					label: <MenuItemLabel>Jumpcloud</MenuItemLabel>,
					icon: <MenuItemIcon Icon={JumpcloudIcon} />,
				},
				{
					key: IdentitiesPageLens.ENTRA_ID,
					label: <MenuItemLabel>Entra ID</MenuItemLabel>,
					icon: <MenuItemIcon Icon={EntraIdIcon} />,
				},
				{
					key: IdentitiesPageLens.GOOGLE_WORKSPACE,
					label: <MenuItemLabel>Google Workspace</MenuItemLabel>,
					icon: <MenuItemIcon Icon={GoogleWorkspaceIcon} />,
				},
			],
		})
		const databasesChildren = [
			{
				key: IdentitiesPageLens.POSTGRES,
				label: <MenuItemLabel>Postgres</MenuItemLabel>,
				icon: <MenuItemIcon Icon={PostgresIcon} />,
			},
			{
				key: IdentitiesPageLens.SNOWFLAKE,
				label: <MenuItemLabel>Snowflake</MenuItemLabel>,
				icon: <MenuItemIcon Icon={SnowflakeIcon} />,
			},
		]
		if (isDemo) {
			databasesChildren.push({
				key: IdentitiesPageLens.DEMO_DATABRICKS,
				label: <MenuItemLabel>Databricks</MenuItemLabel>,
				icon: <MenuItemIcon Icon={DatabricksIcon} />,
			})
		}
		allItems.push({
			key: 'sub-menu-databases',
			label: <MenuItemLabel>Databases</MenuItemLabel>,
			icon: <MenuItemIcon Icon={DatastoresIcon} />,
			children: databasesChildren,
		})
		allItems.push({
			key: 'sub-menu-workloads',
			label: <MenuItemLabel>Workloads</MenuItemLabel>,
			icon: <MenuItemIcon Icon={WorkloadsIcon} />,
			children: [
				{
					key: IdentitiesPageLens.KUBERNETES,
					label: <MenuItemLabel>Kubernetes</MenuItemLabel>,
					icon: <MenuItemIcon Icon={KubernetesIcon} />,
				},
			],
		})
		allItems.push({
			key: IdentitiesPageLens.OFF_BOARDED_EMPLOYEES,
			label: <MenuItemLabel>Off-Boarded Employees</MenuItemLabel>,
			icon: <MenuItemIcon Icon={OffBoardedIcon} />,
		})
		allItems.push({
			key: IdentitiesPageLens.MULTIPLE_ACCESS_KEYS,
			label: <MenuItemLabel>Multiple Access Keys</MenuItemLabel>,
			icon: <MenuItemIcon Icon={AccessKeysIcon} />,
		})
		allItems.push({
			key: IdentitiesPageLens.UNFEDERATED_IDENTITIES,
			label: <MenuItemLabel>Unfederated Identities</MenuItemLabel>,
			icon: <MenuItemIcon Icon={UnfederatedIcon} />,
		})
		allItems.push({
			key: IdentitiesPageLens.RECENTLY_CREATED,
			label: <MenuItemLabel>Recently Created Identities</MenuItemLabel>,
			icon: <MenuItemIcon Icon={RecentlyCreatedIcon} />,
		})
		allItems.push({
			key: IdentitiesPageLens.MOST_WANTED,
			label: <MenuItemLabel>Most Wanted Identities</MenuItemLabel>,
			icon: <MenuItemIcon Icon={MostWantedIcon} />,
		})
		if (isDemo) {
			allItems.push({
				key: IdentitiesPageLens.DEMO_OVER_PRIVILEGED_IDENTITY,
				label: <MenuItemLabel>Over-Privileged Identities</MenuItemLabel>,
				icon: <MenuItemIcon Icon={OverPrivilegedIcon} />,
			})
			allItems.push({
				key: IdentitiesPageLens.DEMO_IDENTITY_CONNECTED_TO_3RD_PARTY_VENDOR,
				label: <MenuItemLabel>3rd Party Identities</MenuItemLabel>,
				icon: <MenuItemIcon Icon={ThirdPartyIcon} />,
			})
		}

		return allItems
	})

	const defaultOpenKeys = useMemo(() => {
		if (!lens) {
			return []
		}

		const openSubMenu = items.find((item) => {
			if (!item || !('children' in item)) {
				return false
			}

			return item.children!.find((subItem) => subItem?.key === lens)
		})

		if (!openSubMenu) {
			return []
		}

		return [openSubMenu.key as string]
	}, [lens, items])

	const siderHeaderClassName = cx('h-14 px-2 mx-2 text-white flex items-center border-b border-gray-500 mb-2', {
		'justify-between': !isCollapsed,
		'justify-center': isCollapsed,
	})

	const toggleSiderCollapse = useCallback(() => {
		setIsCollapsed((currentIsCollapsed) => !currentIsCollapsed)
	}, [setIsCollapsed])
	return (
		<Layout.Sider
			collapsed={isCollapsed}
			collapsedWidth={64}
			className="overflow-y-auto"
			width="15%"
			style={siderStyle}
		>
			<div className={siderHeaderClassName}>
				{!isCollapsed && 'Inventory view'}
				<ConfigProvider
					theme={{
						token: {
							colorPrimary: '#7C987D',
						},
					}}
				>
					<Button
						ghost
						icon={isCollapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
						onClick={toggleSiderCollapse}
					/>
				</ConfigProvider>
			</div>
			<Menu
				onClick={onClick}
				className="bg-[#353535] text-[#BABBBF]"
				selectedKeys={lens ? [lens] : [IdentitiesPageLens.ALL]}
				defaultOpenKeys={defaultOpenKeys}
				mode="inline"
				theme={'dark'}
				items={items}
			/>
		</Layout.Sider>
	)
}
