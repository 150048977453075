import { z } from 'zod'
import { DateSchema } from '../common'
import { ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { ServerOktaUserXcSchema } from './oktaUserXcSchema.ts'

export const ServerGoogleWorkspaceUserEmailsSchema = z.object({
	address: z.string().nullish(),
	type: z.string().nullish(),
	primary: z.boolean().nullish(),
})

export const ServerGoogleWorkspaceUserSchema = z.object({
	id: z.string(),
	primary_email: z.string().nullish(),
	is_admin: z.boolean().nullish(),
	suspended: z.boolean().nullish(),
	archived: z.boolean().nullish(),
	emails: z.array(z.string()).nullish(),
	google_workspace_customer_id: z.string().nullish(),
	mfa_enabled: z.boolean().nullish(),
	recovery_email: z.string().nullish(),
	recovery_phone: z.string().nullish(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	last_activity: DateSchema.nullish(),
	deleted_time: DateSchema.nullish(),
	name: z.string().nullish(),
	aws_iam_roles_xc: z.array(ServerAwsIamRoleXcSchema).nullish(),
	aws_iam_users_xc: z.any().nullish(),
	okta_user_xc: z.array(ServerOktaUserXcSchema).nullish(),
	linkedin_xc: z.any().nullish(),
	hibp_xc: z.any().nullish(),
})

export type ServerGoogleWorkspaceUser = z.infer<typeof ServerGoogleWorkspaceUserSchema>
