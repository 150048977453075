import { Edge } from '@xyflow/react'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { GithubRepositoryNodeType } from './GithubRepositoryNode.tsx'
import { GithubUserTokenNodeType } from './GithubUserTokenNode.tsx'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { Ec2InstancesNodeType } from '../aws/Ec2InstancesNode.tsx'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { GithubAppPermissionNodeType } from './GithubAppPermissionNode.tsx'
import { EntraIDUserNodeType } from '../entraId/EntraIDUserNode.tsx'
import { IssueName } from '../../../../schemas/issue.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	tokensIssue: 1,
	identity: 2,
	accessToken: 3,
	permissions: 4,
	repository: 5,
	ec2: 6,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`
const EntraIdNodeRowIndex = 2

const tokenIssueNames: IssueName[] = [IssueName.InactiveAccessKey, IssueName.OverextendedSecret]

export const getGithubNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<
		BareNodeType<IdentityNodeType> | BareNodeType<EntraIDUserNodeType> | BareNodeType<OwnershipNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	if (identity.github_user?.entra_id_user_xc) {
		// TODO: We currently use only the first entra user as a node, fix if need rises, requires proper UI design
		const details = identity.github_user?.entra_id_user_xc
		const nodeId = `${nodeLogicalTypeToColumnId.identity}-${EntraIdNodeRowIndex}`
		identityNodes.push({
			type: 'entraIDUser',
			data: { user: { principalName: details.user_principal_name, objectId: details.entra_user_id } },
			id: nodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: nodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	if (identity.github_app_installation?.owner_id && identity.github_app_installation?.owner_name) {
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: [
					{
						id: identity.github_app_installation?.owner_id.toString(),
						name: identity.github_app_installation?.owner_name,
					},
				],
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const tokenIssueNodes: BareNodeType<IssueNodeType>[] = []
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issue_name && tokenIssueNames.includes(issue.issue_name)) {
			tokenIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.tokensIssue}-${tokenIssueNodes.length}`,
			})
		} else {
			generalIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
			})
		}
	})

	const repositoryNodes: Array<BareNodeType<GithubRepositoryNodeType>> = []
	const githubRepos = identity.github_user?.github_repos_xc
	githubRepos?.forEach((repository, index) => {
		repositoryNodes.push({
			type: 'githubRepository',
			data: { repository },
			id: `${nodeLogicalTypeToColumnId.repository}-${index}`,
		})
	})

	const githubAppRepos = identity.github_app_installation?.repositories
	githubAppRepos?.forEach((repoName, index) => {
		repositoryNodes.push({
			type: 'githubRepository',
			data: { repository: { name: repoName } },
			id: `${nodeLogicalTypeToColumnId.repository}-${index}`,
		})
	})

	const accessTokenNodes: BareNodeType<GithubUserTokenNodeType>[] = []
	identity.github_user?.tokens?.forEach((accessToken, index) => {
		accessTokenNodes.push({
			type: 'githubUserToken',
			data: { accessToken },
			id: `${nodeLogicalTypeToColumnId.accessToken}-${index}`,
		})
	})

	const ec2Nodes: Array<BareNodeType<Ec2InstancesNodeType>> = []
	if (identity.github_user?.aws_ec2_instances_xc) {
		if (identity.github_user?.aws_ec2_instances_xc?.length) {
			ec2Nodes.push({
				type: 'ec2Instances',
				data: { instances: identity.github_user.aws_ec2_instances_xc },
				id: `${nodeLogicalTypeToColumnId.ec2}-${ec2Nodes.length}`,
			})
		}
	}

	const permissionNodes: Array<BareNodeType<GithubAppPermissionNodeType>> = []
	if (identity.github_app_installation?.permissions?.length) {
		permissionNodes.push({
			type: 'githubAppPermission',
			data: { permissions: identity.github_app_installation.permissions },
			id: `${nodeLogicalTypeToColumnId.permissions}-${permissionNodes.length}`,
		})
	}

	// Connect token issue nodes to:
	//  - Access token nodes, if the issue is for specific tokens.
	//  - The identity node if there is no relevant access token node.
	tokenIssueNodes.forEach((issueNode, issueIndex) => {
		const source = `${nodeLogicalTypeToColumnId.tokensIssue}-${issueIndex}`
		// There are no access token nodes - connect the issue node to the identity node.
		if (!accessTokenNodes.length) {
			edges.push(getEdge({ source, target: identityNodeId }))
			return
		}

		const accessTokenNodesIndices: number[] = []
		// Find every relevant access token node for this specific issue.
		accessTokenNodes.forEach((tokenNode, tokenIndex) => {
			if (issueNode.data.issue.description?.includes(tokenNode.data.accessToken?.id.toString())) {
				accessTokenNodesIndices.push(tokenIndex)
			}
		})

		// If we found relevant access token nodes - connect the issue to each of them.
		if (accessTokenNodesIndices.length) {
			accessTokenNodesIndices.forEach((accessTokenNodesIndex) => {
				const target = `${nodeLogicalTypeToColumnId.accessToken}-${accessTokenNodesIndex}`
				edges.push(getEdge({ source, target }))
			})
		} else {
			// If no relevant access tokens are found for this issue - connect it to the identity node.
			edges.push(getEdge({ source, target: identityNodeId }))
		}
	})

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	repositoryNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.repository}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	accessTokenNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessToken}-${index}`
		edges.push(getEdge({ source: source, target: identityNodeId }))
	})

	ec2Nodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.ec2}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	permissionNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.permissions}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'top', nodes: ec2Nodes },
		{ yPosition: 'center', nodes: [...tokenIssueNodes, ...generalIssueNodes] },
		{ yPosition: 'top', nodes: accessTokenNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'top', nodes: permissionNodes },
		{ yPosition: 'center', nodes: repositoryNodes },
	]
	return [nodes, edges]
}
