import { z } from 'zod'
import { ServerAwsIamRoleXcSchema } from '../awsIamRoleXcSchema.ts'
import { ServerGcpPrincipalInfoXcSchema } from '../gcp/gcpProjectXcSchema.ts'
import { ServerDetailedAzureRoleAssignmentSchema } from './azureRoleAssignmentsXc.ts'
import { ServerEntraIdChangeLogSchema } from './entraIdChangeLogSchema.ts'
import { DetailedEntraIdRoleAssignmentSchema } from './entraIdRoleAssignmentsSchemas.ts'
import { ServerAwsUsageLogSchema } from '../awsIamUserSchema.ts'
import { ServerAzureUsageLogSchema } from './azureUsageLogSchema.ts'
import { BaseAzureDevopsUserSchema } from '../azure/azureDevops/azureDevopsUserSchema.ts'
import { BaseAdUserSchema } from '../activeDirectory/adUserSchema.ts'

export const BaseEntraIdUserSchema = z.object({
	identity_id: z.string().nullish(), // Abomination for demo purposes only
	mail: z.string().nullish(),
	given_name: z.string().nullish(),
	job_title: z.string().nullish(),
	user_principal_name: z.string().nullish(),
	entra_user_id: z.string().nullish(),
	last_activity: z.string().nullish(),
	password_last_changed: z.string().nullish(),
	account_enabled: z.boolean().nullish(),
})

export const ServerEntraIdUserSchema = BaseEntraIdUserSchema.extend({
	aws_iam_roles_xc: z.array(ServerAwsIamRoleXcSchema).nullish(),
	gcp_projects_xc: z.array(ServerGcpPrincipalInfoXcSchema).nullish(),
	azure_role_assignments_xc: z.array(ServerDetailedAzureRoleAssignmentSchema).nullish(),
	entra_id_role_assignments: z.array(DetailedEntraIdRoleAssignmentSchema).nullish(),
	change_logs: z.array(ServerEntraIdChangeLogSchema).nullish(),
	usage_logs: z.array(ServerAzureUsageLogSchema).nullish(),
	demo_usage_logs: z.array(ServerAwsUsageLogSchema).nullish(),
	azure_devops_user: z.lazy(() => BaseAzureDevopsUserSchema.nullish()),
	active_directory_user: z.lazy(() => BaseAdUserSchema.nullish()),
}).nullish()

export type ServerEntraIdUser = z.infer<typeof ServerEntraIdUserSchema>
