import { JiraField, JiraFieldComponent } from '../schemas/jiraSchemas.ts'
import { Select } from 'antd'

const JiraMultiSelect: string[] = [
	'components',
	'fixVersions',
	'labels',
	'com.atlassian.jira.plugin.system.customfieldtypes:labels',
	'versions',
	'issuelinks',
	'subtasks',
	'com.atlassian.jira.plugin.system.customfieldtypes:multiselect',
	'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker',
	'com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker',
	'com.atlassian.jira.plugin.system.customfieldtypes:people',
	'com.atlassian.servicedesk:sd-request-participants',
]

const JiraCreateableSelect: string[] = [
	'components',
	'fixVersions',
	'labels',
	'com.atlassian.jira.plugin.system.customfieldtypes:labels',
	'versions',
	'subtasks',
	'com.atlassian.jira.plugin.system.customfieldtypes:url',
]
const getSelectMode = (field: JiraField): 'tags' | 'multiple' | undefined => {
	if (field.schema.type === 'array') {
		if (JiraCreateableSelect.includes(field.schema.system ?? '')) {
			return 'tags'
		}
		if (JiraMultiSelect.includes(field.schema.custom ?? '')) {
			return 'multiple'
		}
		return undefined
	}
	if (field.schema.type === 'string') {
		if (JiraCreateableSelect.includes(field.schema.custom ?? '')) {
			return 'tags'
		}
		if (JiraMultiSelect.includes(field.schema.custom ?? '')) {
			return 'multiple'
		}
		if (field.schema.custom) {
			return undefined
		}
	}
}

export const JiraSelectField: JiraFieldComponent = (props) => {
	const field: JiraField | undefined = props.field
	if (!field) {
		return null
	}
	return (
		<Select
			{...props}
			placeholder={`Select ${field.name.toLowerCase()}`}
			mode={getSelectMode(field)}
			filterOption={(inputValue, option) =>
				(option?.label as unknown as string).toLowerCase().includes(inputValue.toLowerCase())
			}
			showSearch
			options={field.allowedValues?.map((option) => {
				return {
					label: option.name ? option.name : option.value,
					value: option.id,
				}
			})}
		/>
	)
}
