import { Collapse, CollapseProps } from 'antd'
import React from 'react'
import KubernetesIcon from '../../../../assets/kubernetes_icon_20.svg?react'
import { ServerKubernetesServiceAccount } from '../../../../schemas/identities/kubernetesResourceSchema.ts'
import { KubernetesServiceAccountsTable } from '../../../../tables/KubernetesServiceAccountsTable.tsx'

export const KubernetesServiceAccountsContext: React.FunctionComponent<{
	serviceAccounts: ServerKubernetesServiceAccount[]
}> = ({ serviceAccounts }) => {
	const items: CollapseProps['items'] = [
		{
			key: 'kubernetesResources',
			label: (
				<div className="flex items-center space-x-2">
					<KubernetesIcon style={{ width: '35px', height: '35px' }} />
					<div>Kubernetes Service Accounts</div>
				</div>
			),
			children: <KubernetesServiceAccountsTable serviceAccounts={serviceAccounts} />,
		},
	]

	return <Collapse items={items} expandIconPosition={'end'} />
}
