import { getRecentlyCreatedDate } from '../utils.ts'
import { StandardAces, StandardGroupSids } from './adConstants'
import { adGroupsData } from './adGroupsData'

export const adUsersData = {
	JASON_NICKELSON: {
		identity_id: '312',
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1001',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'JASON.NICKELSON@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=JASON.NICKELSON,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'jason.nickelson',
			description: null,
			when_created: '2022-07-31T00:33:27.721732Z',
			enabled: true,
			last_logon: '2022-07-31T00:33:27.721732Z',
			last_logon_timestamp: '2022-07-31T00:33:27.721732Z',
			pwd_last_set: '2022-07-31T00:33:27.721732Z',
			pwd_never_expires: true,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Jason Nickelson',
			email: 'jason.nickelson@synergy.com',
			title: 'IT Administrator',
			home_directory: null,
			user_password: null,
			admin_count: true,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [...StandardAces.DOMAIN_ADMIN_RIGHTS, StandardAces.FORCE_CHANGE_PASSWORD],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_ADMINS,
				group_name: adGroupsData.DOMAIN_ADMINS.properties.sam_account_name,
				group_data: adGroupsData.DOMAIN_ADMINS,
			},
			{
				group_sid: StandardGroupSids.DNS_ADMINS,
				group_name: adGroupsData.DNS_ADMINS.properties.sam_account_name,
				group_data: adGroupsData.DNS_ADMINS,
			},
			{
				group_sid: StandardGroupSids.SQL_WRITE_ACCESS,
				group_name: adGroupsData.SQL_WRITE_ACCESS.properties.sam_account_name,
				group_data: adGroupsData.SQL_WRITE_ACCESS,
			},
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: adGroupsData.DOMAIN_USERS.properties.sam_account_name,
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	DANTE_WALKER: {
		identity_id: '313',
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1002',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'DANTE.WALKER@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=DANTE.WALKER,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'dante.walker',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: Date.now(),
			last_logon_timestamp: Date.now(),
			pwd_last_set: Date.now(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Dante Walker',
			email: 'dante.walker@synergy.com',
			title: 'DNS Administrator',
			home_directory: null,
			user_password: null,
			admin_count: true,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [StandardAces.FORCE_CHANGE_PASSWORD],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DNS_ADMINS,
				group_name: 'DNS Admins',
				group_data: adGroupsData.DNS_ADMINS,
			},
		],
	},

	ALEX_SIGDAG: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1003',
		identity_id: '311',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'ALEX.SIGDAG@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=ALEX.SIGDAG,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'alex.sigdag',
			description: null,
			when_created: '2024-01-21T12:32:36.000000Z',
			enabled: true,
			last_logon: '2024-02-22T12:32:36.000000Z',
			last_logon_timestamp: '2024-02-22T12:32:36.000000Z',
			pwd_last_set: '2024-01-21T12:32:36.000000Z',
			pwd_never_expires: true,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Alex Sigdag',
			email: 'alex.sigdag@synergy.com',
			title: 'Database Developer',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [StandardAces.FORCE_CHANGE_PASSWORD],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.SQL_WRITE_ACCESS,
				group_name: adGroupsData.DOMAIN_USERS.properties.sam_account_name,
				group_data: adGroupsData.SQL_WRITE_ACCESS,
			},
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: adGroupsData.DOMAIN_USERS.properties.sam_account_name,
				group_data: adGroupsData.DOMAIN_USERS,
			},
			{
				group_sid: StandardGroupSids.DOMAIN_ADMINS,
				group_name: adGroupsData.DOMAIN_ADMINS.properties.sam_account_name,
				group_data: adGroupsData.DOMAIN_ADMINS,
			},
			{
				group_sid: StandardGroupSids.DNS_ADMINS,
				group_name: adGroupsData.DNS_ADMINS.properties.sam_account_name,
				group_data: adGroupsData.DNS_ADMINS,
			},
		],
		demo_endpoints_dependencies: [
			{
				host_name: 'SYNPC34',
				source_ip: '10.0.1.56',
				destination: 'SPLUNKPRD2$',
				destination_ip: '10.0.4.63',
				last_login: '2024-02-20T15:30:00.000Z',
			},
			{
				host_name: 'ElasticIT7',
				source_ip: '10.0.1.21',
				destination: 'SQLDEVTEST$',
				destination_ip: '10.0.2.10',
				last_login: '2024-02-20T15:30:00.000Z',
			},
		],
		demo_ownership: [
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1013',
				sam_account_name: 'george.brown',
				domain: 'SYNERGY.COM',
				action: 'Enable Account',
				timestamp: '2024-01-23T12:32:36.000000Z',
			},
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1013',
				sam_account_name: 'george.brown',
				domain: 'SYNERGY.COM',
				action: 'Reset Password',
				timestamp: '2024-01-23T12:32:36.000000Z',
			},
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1013',
				sam_account_name: 'george.brown',
				domain: 'SYNERGY.COM',
				action: 'Add to group',
				timestamp: '2024-01-24T12:32:36.000000Z',
			},
		],
	},

	GEORGE_BROWN: {
		identity_id: '316',
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1013',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'GEORGE.BROWN@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=GEORGE.BROWN,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'george.brown',
			description: null,
			when_created: '2024-02-21T12:32:36.000000Z',
			enabled: true,
			last_logon: '2024-02-23T12:32:36.000000Z',
			last_logon_timestamp: '2024-02-23T12:32:36.000000Z',
			pwd_last_set: '2024-02-21T12:32:36.000000Z',
			pwd_never_expires: true,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'George Brown',
			email: 'george.brown@synergy.com',
			title: 'Business Analyst',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: adGroupsData.DOMAIN_USERS.properties.sam_account_name,
				group_data: adGroupsData.DOMAIN_USERS,
			},
			{
				group_sid: StandardGroupSids.DOMAIN_ADMINS,
				group_name: adGroupsData.DOMAIN_ADMINS.properties.sam_account_name,
				group_data: adGroupsData.DOMAIN_ADMINS,
			},
			{
				group_sid: StandardGroupSids.SQL_WRITE_ACCESS,
				group_name: adGroupsData.SQL_WRITE_ACCESS.properties.sam_account_name,
				group_data: adGroupsData.SQL_WRITE_ACCESS,
			},
		],
		demo_ownership: [
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1014',
				sam_account_name: 'joseph.villanueva',
				domain: 'SYNERGY.COM',
				action: 'Enable Account',
				timestamp: '2024-02-22T12:32:36.000000Z',
			},
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1014',
				sam_account_name: 'joseph.villanueva',
				domain: 'SYNERGY.COM',
				action: 'Reset Password',
				timestamp: '2024-02-22T12:32:36.000000Z',
			},
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1014',
				sam_account_name: 'joseph.villanueva',
				domain: 'SYNERGY.COM',
				action: 'Add to group',
				timestamp: '2024-02-22T12:32:36.000000Z',
			},
		],
	},

	JOSEPH_VILLANUEVA: {
		identity_id: '312',
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1014',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'JOSEPH.VILLANUEVA@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=JOSEPH.VILLANUEVA,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'joseph.villanueva',
			description: null,
			when_created: '2022-07-31T00:33:27.721732Z',
			enabled: true,
			last_logon: '2022-08-31T00:33:27.721732Z',
			last_logon_timestamp: '2022-08-31T00:33:27.721732Z',
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Joseph Villanueva',
			email: 'joseph.villanueva@synergy.com',
			title: 'Marketing Director',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: adGroupsData.DOMAIN_USERS.properties.sam_account_name,
				group_data: adGroupsData.DOMAIN_USERS,
			},
			{
				group_sid: StandardGroupSids.DNS_ADMINS,
				group_name: adGroupsData.DNS_ADMINS.properties.sam_account_name,
				group_data: adGroupsData.DNS_ADMINS,
			},
			{
				group_sid: StandardGroupSids.SQL_WRITE_ACCESS,
				group_name: adGroupsData.SQL_WRITE_ACCESS.properties.sam_account_name,
				group_data: adGroupsData.SQL_WRITE_ACCESS,
			},
		],
	},

	AMY_ALEXANDER: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1004',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'AMY.ALEXANDER@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=AMY.ALEXANDER,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'amy.alexander',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: getRecentlyCreatedDate(),
			last_logon_timestamp: getRecentlyCreatedDate(),
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Amy Alexander',
			email: 'amy.alexander@synergy.com',
			title: 'Marketing Specialist',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: 'Domain Users',
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	LAURA_ZAMORA: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1005',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'LAURA.ZAMORA@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=LAURA.ZAMORA,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'laura.zamora',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: getRecentlyCreatedDate(),
			last_logon_timestamp: getRecentlyCreatedDate(),
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Laura Zamora',
			email: 'laura.zamora@synergy.com',
			title: 'Sales Representative',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: 'Domain Users',
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	MARY_CHAPMAN: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1006',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'MARY.CHAPMAN@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=MARY.CHAPMAN,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'mary.chapman',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: getRecentlyCreatedDate(),
			last_logon_timestamp: getRecentlyCreatedDate(),
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Mary Chapman',
			email: 'mary.chapman@synergy.com',
			title: 'Human Resources',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: 'Domain Users',
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	PRISCILLA_DAVIS: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1007',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'PRISCILLA.DAVIS@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=PRISCILLA.DAVIS,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'priscilla.davis',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: getRecentlyCreatedDate(),
			last_logon_timestamp: getRecentlyCreatedDate(),
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Priscilla Davis',
			email: 'priscilla.davis@synergy.com',
			title: 'Financial Analyst',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: 'Domain Users',
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	ELIZABETH_TRUJILLO: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1008',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'ELIZABETH.TRUJILLO@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=ELIZABETH.TRUJILLO,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'elizabeth.trujillo',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: getRecentlyCreatedDate(),
			last_logon_timestamp: getRecentlyCreatedDate(),
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Elizabeth Trujillo',
			email: 'elizabeth.trujillo@synergy.com',
			title: 'Customer Support',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: 'Domain Users',
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	BRIAN_ESPINOZA: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1009',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'BRIAN.ESPINOZA@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=BRIAN.ESPINOZA,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'brian.espinoza',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: getRecentlyCreatedDate(),
			last_logon_timestamp: getRecentlyCreatedDate(),
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Brian Espinoza',
			email: 'brian.espinoza@synergy.com',
			title: 'Product Manager',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: 'Domain Users',
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	MARIA_GREEN: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1010',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'MARIA.GREEN@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=MARIA.GREEN,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'maria.green',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: getRecentlyCreatedDate(),
			last_logon_timestamp: getRecentlyCreatedDate(),
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Maria Green',
			email: 'maria.green@synergy.com',
			title: 'Research Analyst',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: 'Domain Users',
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	CHARLES_CARROLL: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1011',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'CHARLES.CARROLL@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=CHARLES.CARROLL,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'charles.carroll',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: getRecentlyCreatedDate(),
			last_logon_timestamp: getRecentlyCreatedDate(),
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'Charles Carroll',
			email: 'charles.carroll@synergy.com',
			title: 'Project Coordinator',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: 'Domain Users',
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	JOHN_BEARD: {
		object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1012',
		allowed_to_delegate: [],
		primary_group_sid: StandardGroupSids.DOMAIN_USERS,
		is_deleted: false,
		is_acl_protected: false,
		properties: {
			name: 'JOHN.BEARD@SYNERGY.COM',
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			distinguished_name: 'CN=JOHN.BEARD,CN=USERS,DC=SYNERGY,DC=COM',
			sam_account_name: 'john.beard',
			description: null,
			when_created: getRecentlyCreatedDate(),
			enabled: true,
			last_logon: getRecentlyCreatedDate(),
			last_logon_timestamp: getRecentlyCreatedDate(),
			pwd_last_set: getRecentlyCreatedDate(),
			pwd_never_expires: false,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'John Beard',
			email: 'john.beard@synergy.com',
			title: 'Sales Manager',
			home_directory: null,
			user_password: null,
			admin_count: false,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.DOMAIN_USERS,
				group_name: 'Domain Users',
				group_data: adGroupsData.DOMAIN_USERS,
			},
		],
	},

	// Service accounts:
	ELASTIC_BACKUP_SVC: {
		object_identifier: 'S-1-5-21-4283586694-1852547664-606598350-1109',
		allowed_to_delegate: [],
		primary_group_sid: 'S-1-5-21-4283586694-1852547664-606598350-515',
		is_deleted: false,
		is_acl_protected: true,
		properties: {
			name: 'elastic-backup-svc$@TOKEN.COM',
			domain: 'TOKEN.COM',
			domain_sid: 'S-1-5-21-4283586694-1852547664-606598350',
			distinguished_name: 'CN=elastic-backup-svc,CN=USERS,DC=TOKEN,DC=COM',
			sam_account_name: 'elastic-backup-svc$',
			description: null,
			when_created: '2023-10-26T00:33:27.721Z',
			enabled: true,
			last_logon: '2023-10-26T00:33:27.721Z',
			last_logon_timestamp: '2023-10-26T00:33:27.721Z',
			pwd_last_set: '2023-10-26T00:33:27.721Z',
			pwd_never_expires: true,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'elastic-backup-svc$',
			email: null,
			title: null,
			home_directory: null,
			user_password: null,
			admin_count: true,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [
			{
				right_name: 'Owns',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1113',
				principal_type: 'Group',
			},
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1113',
				principal_type: 'Group',
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1113',
				principal_type: 'Group',
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1113',
				principal_type: 'Group',
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1113',
				principal_type: 'Group',
			},
		],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [
			{
				group_sid: StandardGroupSids.PRM_LOG_ADMINS,
				group_name: adGroupsData.PRM_LOG_ADMINS.properties.sam_account_name,
				group_data: adGroupsData.PRM_LOG_ADMINS,
			},
		],
		demo_endpoints_dependencies: [
			{
				host_name: 'SYNPC53',
				source_ip: '10.0.1.86',
				destination: 'ElasticDEVSYN$',
				destination_ip: '10.0.4.76',
				last_login: '2024-02-20T15:30:00.000Z',
			},
		],
		demo_ownership: [
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1014',
				sam_account_name: 'joseph.villanueva',
				domain: 'SYNERGY.COM',
				action: 'Reset Password',
				timestamp: '2024-01-23T12:32:36.000000Z',
			},
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1014',
				sam_account_name: 'joseph.villanueva',
				domain: 'SYNERGY.COM',
				action: 'Add to group',
				timestamp: '2024-01-24T12:32:36.000000Z',
			},
		],
	},

	SVC_MAILBOXSCANNER3: {
		object_identifier: 'S-1-5-21-4283586694-1852547664-606598350-1132',
		allowed_to_delegate: [],
		primary_group_sid: 'S-1-5-21-4283586694-1852547664-606598350-1125',
		is_deleted: false,
		is_acl_protected: true,
		properties: {
			name: 'svc-mailboxscanner3$@TOKEN.COM',
			domain: 'TOKEN.COM',
			domain_sid: 'S-1-5-21-4283586694-1852547664-606598350',
			distinguished_name: 'CN=svc-mailboxscanner3,CN=USERS,DC=TOKEN,DC=COM',
			sam_account_name: 'svc-mailboxscanner3$',
			description: null,
			when_created: '2023-11-26T00:33:27.721Z',
			enabled: true,
			last_logon: '2023-11-26T00:33:27.721Z',
			last_logon_timestamp: '2023-11-26T00:33:27.721Z',
			pwd_last_set: '2023-11-26T00:33:27.721Z',
			pwd_never_expires: true,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'svc-mailboxscanner3$',
			email: null,
			title: null,
			home_directory: null,
			user_password: null,
			admin_count: true,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [
			{
				right_name: 'Owns',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1113',
				principal_type: 'Group',
			},
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1113',
				principal_type: 'Group',
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1113',
				principal_type: 'Group',
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1113',
				principal_type: 'Group',
			},
		],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [],
	},

	AUDIT_LOG_UPLOAD: {
		object_identifier: 'S-1-5-21-4283586694-1852547664-606598350-1194',
		allowed_to_delegate: [],
		primary_group_sid: 'S-1-5-21-4283586694-1852547664-606598350-1127',
		is_deleted: false,
		is_acl_protected: true,
		properties: {
			name: 'AUDIT-LOG-UPLOAD$@TOKEN.COM',
			domain: 'TOKEN.COM',
			domain_sid: 'S-1-5-21-4283586694-1852547664-606598350',
			distinguished_name: 'CN=AUDIT-LOG-UPLOAD,CN=USERS,DC=TOKEN,DC=COM',
			sam_account_name: 'AUDIT-LOG-UPLOAD$',
			description: null,
			when_created: '2022-11-26T00:33:27.721Z',
			enabled: true,
			last_logon: '2022-11-26T00:33:27.721Z',
			last_logon_timestamp: '2022-11-26T00:33:27.721Z',
			pwd_last_set: '2022-11-26T00:33:27.721Z',
			pwd_never_expires: true,
			sensitive: false,
			service_principal_names: [],
			has_spn: false,
			display_name: 'AUDIT-LOG-UPLOAD',
			email: null,
			title: null,
			home_directory: null,
			user_password: null,
			admin_count: true,
			sid_history: [],
			unicode_password: null,
			logon_script: null,
		},
		aces: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1155',
				principal_type: 'Group',
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1155',
				principal_type: 'Group',
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1155',
				principal_type: 'Group',
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1155',
				principal_type: 'Group',
			},
		],
		spn_targets: [],
		has_sid_history: [],
		group_memberships: [],
	},
}
