import { identities } from './identities.ts'
import { snowflakeUserData } from './snowflakeUsersData.ts'
import { gcpServiceAccountsData } from './gcpServiceAccountsData.ts'
import { githubUserData } from './githubUsersData.ts'
import { databricksUserData } from './databricksUsersData.ts'
import { salesforceUserData, salesforceUserUuids } from './salesforcePrincipalData.ts'
import { generateUUID, getRecentlyCreatedDate } from '../utils.ts'
import { databricksServicePrincipalData } from './databricksServicePrincipalData.ts'
import { azureIdentitiesData } from './azureIdentitiesData.ts'
import { IssueName, ResourceType } from '../../schemas/issue.ts'
import { awsIamUserData } from './awsIamUserData.ts'
import { entraIdServicePrincipalsData } from './entraIdServicePrincipalsData.ts'

export const issues = [
	{
		priority: 2,
		id: 'abf538d0-bdcc-42be-9635-1540c2c3b981',
		last_seen: '2024-01-18T07:03:32.866131Z',
		identity: identities['1'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'UnFederatedIdentity',
		issue_source: 'AWS',
		description:
			"\nThe AWS IAM User **gabrielabeard** has console access.\nIt's recommended to avoid creating AWS IAM users with console access and instead use federated users. \nFederated access enhances security by enabling centralized management of credentials and adhering to your organization's existing security protocols.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '4c688b8f-dde3-495a-bbcf-15747c71cafc',
		last_seen: '2024-01-18T07:03:32.866131Z',
		identity: identities['1'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'CloudtrailIamUserUsedFromEndpoint',
		issue_source: 'AWS',
		description:
			'\nThe AWS IAM user **arn:aws:iam::877686312397:user/gabrielabeard** access key has been used from employee endpoint.\nThis is a potential security risk, as the access key is saved on the endpoint and if the endpoint is compromised,\nthe access key can be used to access the AWS account.\nlast event time: 2024-04-08T12:26:19Z (UTC).\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '2bd66f71-2c42-4e14-985b-34218a1f9c3d',
		last_seen: '2024-04-08T07:03:32.866131Z',
		identity: identities['1'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'PartiallyOffBoardedUsers',
		issue_source: 'AWS',
		description:
			'\nThe user **gabrielabeard** is partially off-boarded. A partially off-boarded employee refers to an individual who has begun, but not completed, the exit process from an organization. This can pose security and operational risks due to potential access to sensitive information or systems. Check the LinkedIn profile: www.linkedin.com/in/gabriela-beard\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '66f6d8ca-f7e3-4f20-9700-0e496338ed1c',
		last_seen: '2024-01-18T07:03:30.531547Z',
		identity: identities['1'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- Access key **AKIA9J6THLSDLW9MOP8K** was not rotated for **826+** days.\n- Access key **AKIA9WO5KDIBU669YJIR** was not rotated for **744+** days.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '63815114-fd6c-4d8e-8a40-ba7c74248893',
		last_seen: '2024-01-18T07:03:29.398747Z',
		identity: identities['1'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **gabrielabeard** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '0e971a8b-ff2a-46de-9e2a-e1c3d1fca446',
		last_seen: '2024-01-18T07:03:32.866131Z',
		identity: identities['1'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'PasswordRotation',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **gabrielabeard** has not rotated its password more then **90** days.\nAn AWS IAM User with an Unrotated Password poses a security risk, as long-standing, unchanged passwords are vulnerable to compromise. Regular password rotation is essential to reduce the risk of unauthorized access and keep the AWS environment secure.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '007af413-3b7c-4ac3-a88e-3871b9ce8957',
		last_seen: '2024-01-18T07:03:29.469748Z',
		identity: identities['1'],
		issue_name: 'MultipleAccessKeys',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **gabrielabeard** has multiple active Access Keys.\nHaving Multiple Active AWS IAM User Access Keys refers to the practice where a single IAM (Identity and Access Management) user has more than one active access key at the same time. This scenario can pose security risks and complicate key management. Multiple active keys per user increase the chances of keys being mishandled, lost, or forgotten, leading to a higher risk of unauthorized access if a key is compromised. It also makes tracking which key is used for what purpose more difficult, potentially leading to confusion and security gaps. Best practices recommend limiting users to a single active access key and rotating it regularly. This approach simplifies management, enhances security by reducing the attack surface, and ensures accountability and traceability of actions performed with each key.\n',
		status: 'OPEN',
	},
	{
		priority: 4,
		id: 'ad639963-f04c-470e-b347-4b2f8b2d9183',
		last_seen: '2024-04-07T07:58:07.273036Z',
		identity: identities['2'],
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_name: 'SharedRole',
		issue_source: 'AWS',
		description:
			'\nThe role **arn:aws:iam::871744574088:role/SynergisticApplications** seems to be a shared role. It contains high privileges, including **IAMFullAccess** and **PowerUserAccess** managed policies, and is used by a large amount of EC2 instances which perform different tasks. Furthermore, the role is over privileged, due to the fact that only the **following services** are used via the role: Lambda, Dynamodb, S3.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: 'e2d50aac-7b91-4664-93ef-27fa5b2f4d58',
		last_seen: '2024-03-31T16:14:09.659276',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		identity: identities['2'],
		issue_name: 'EC2KeyPairNotRotated',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::871744574088:role/SynergisticApplications** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: 'b950a3ae-4eeb-4980-8b77-a2f7f9d822a4',
		last_seen: '2024-04-04T11:41:42.774796',
		identity: identities['13'],
		issue_name: 'MissingMFA',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **Amy Weber** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '9939cf27-4081-4451-9493-8be006cd1472',
		last_seen: '2024-04-08T11:41:42.864857',
		identity: identities['4'],
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		description:
			'\nThe IAM User **Michael Quinn** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '285ae597-8fad-4c38-92d8-1cc51af65ff1',
		last_seen: '2024-03-13T11:41:42.952852',
		identity: identities['5'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **Danielle Graham** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'a1dc1651-2214-4290-944e-0f64e320eb88',
		last_seen: '2024-03-14T11:41:43.043881',
		identity: identities['6'],
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **Thomas Pearson** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'e21c8977-238b-472c-98a3-002c6032f2f8',
		last_seen: '2024-04-09T11:41:43.132706',
		identity: identities['7'],
		issue_name: 'MissingMFA',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **Alicia Nash** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '72bf855e-1985-4547-a243-56f5c0c9461b',
		last_seen: '2024-04-04T11:41:43.220685',
		identity: identities['8'],
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **Wanda Rhodes** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '4efb67ad-76c1-46ec-9918-29a44ddcbef0',
		last_seen: '2024-04-10T11:41:43.310074',
		identity: identities['9'],
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		description:
			'\nThe IAM User **Kara Boyer** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '26ac473e-686a-43d7-bd6b-5938fcbe0f32',
		last_seen: '2024-04-08T11:41:43.400548',
		identity: identities['10'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **Kathleen Pena** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: 'f2484739-ce2e-474b-afd3-4205f778a83b',
		last_seen: '2024-04-01T11:41:43.488566',
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		identity: identities['11'],
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **Alex White** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: 'a7266d5b-5530-4766-9862-21c07ebf4d63',
		last_seen: '2024-04-06T11:41:43.574986',
		identity: identities['12'],
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		description:
			'\nThe IAM User **Denise Gomez** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'c55154fd-8b10-4afc-a22e-1a65c095499a',
		last_seen: '2024-04-07T14:38:50.127371',
		identity: identities['14'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIAQXA81QB8PY792KBN**\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '9d37db14-a963-4dc7-937c-43096c76ca75',
		last_seen: '2024-03-25T14:38:50.127622',
		identity: identities['15'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIAUKTIAKQT1K42675V**\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'a3474413-db6b-48f6-8a72-96ecf77ad767',
		last_seen: '2024-03-26T14:38:50.127796',
		identity: identities['16'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIAXECCV3MRNI5WMWW9**\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'a904606b-a60b-48fd-9707-2de4a253c0e7',
		last_seen: '2024-04-03T14:38:50.127959',
		identity: identities['17'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIAMEZZDDM2M2U922OX**\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '58b6d859-b887-4573-b6e9-aec45f17c5ec',
		last_seen: '2024-03-29T14:38:50.128123',
		identity: identities['18'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIAKZGW04V9Z9AILUHM**\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'aaf5ae1b-96ca-4119-97a9-054b3db9e607',
		last_seen: '2024-04-09T14:38:50.128289',
		identity: identities['19'],
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIAUBMHBM8F62ERBCRO**\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'dd6dd192-0e81-46d9-b97e-2e3178f18fe8',
		last_seen: '2024-03-23T14:38:50.128448',
		identity: identities['20'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIA8PAO7DAMADG8TJY7**\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'e154cb79-a865-45a8-a2f6-5b261433c419',
		last_seen: '2024-04-04T14:38:50.128614',
		identity: identities['21'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIAOZOFMYZI3YO697HL**\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '5db3543b-6875-4b9a-b3ac-6da75fe37288',
		last_seen: '2024-04-12T14:38:50.128780',
		identity: identities['22'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIAYT609R22VC63R658**\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'fac239f7-df00-41ae-ab94-238496b536ab',
		last_seen: '2024-04-03T14:38:50.128940',
		identity: identities['23'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- **AKIANPL70647KHQOQ9WK**\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '1d19b902-cae3-49dc-bd1f-54e572f73dde',
		last_seen: '2024-03-31T16:14:09.659276',
		identity: identities['24'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'EC2KeyPairNotRotated',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/coach_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '8103cc4f-9412-486e-9ba3-dacc6689e20e',
		last_seen: '2024-03-12T16:14:09.659730',
		identity: identities['25'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'EC2KeyPairNotRotated',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/star_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '6ba51ac2-8a97-4675-a72c-fdb60db0fdfb',
		last_seen: '2024-04-01T16:14:09.660086',
		identity: identities['26'],
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_name: 'EC2KeyPairNotRotated',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/huge_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: 'f537abfe-e8ad-4da5-9ec6-df5c2bfab53f',
		last_seen: '2024-03-19T16:14:09.660429',
		identity: identities['27'],
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_name: 'EC2KeyPairNotRotated',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/western_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '0be4da49-a5a6-4df9-93aa-35880b14e4bf',
		last_seen: '2024-03-31T16:14:09.660794',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		identity: identities['28'],
		issue_name: 'EC2KeyPairNotRotated',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/enjoy_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '8bcd18f4-6e5a-4cdb-8c8c-e26b67c05ff4',
		last_seen: '2024-03-17T16:14:09.661191',
		identity: identities['29'],
		issue_name: 'EC2KeyPairNotRotated',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/investment_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'beee2fa4-ebe0-4937-acd8-cc73740ed941',
		last_seen: '2024-03-17T16:14:09.661543',
		identity: identities['30'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'EC2KeyPairNotRotated',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/think_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: 'd9fd2d41-be63-40da-97dc-35fa77d36720',
		last_seen: '2024-04-07T16:14:09.661889',
		identity: identities['31'],
		issue_name: 'EC2KeyPairNotRotated',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/able_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '73015b54-6b96-4432-8acb-4b7a6c39de4f',
		last_seen: '2024-04-02T16:14:09.662234',
		identity: identities['32'],
		issue_name: 'EC2KeyPairNotRotated',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/trip_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '306a3d53-baa1-4d35-98cc-ecc0427df653',
		last_seen: '2024-04-10T16:14:09.662582',
		identity: identities['33'],
		issue_name: 'EC2KeyPairNotRotated',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'AWS',
		description:
			'\nThe key pair **us-east-1-collector-prod-keypair** in region **us-east-1** has not been rotated in over than a year.\nThe instance profile role **arn:aws:iam::467791917130:role/ten_EC2_Role** associated with the EC2 instances that uses the keypair could be exposed.\nRegular key rotation reduces the likelihood of unauthorized access to AWS EC2 instances, helping to protect against data breaches and security incidents.\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'a161b0e6-df15-45e2-9478-194be4857f5d',
		last_seen: '2024-03-27T16:57:38.006713',
		identity: identities['34'],
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **yt-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'e78efe53-3eab-4389-8777-9f19499e17d1',
		last_seen: '2024-03-23T16:57:38.007019',
		identity: identities['35'],
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **uo-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'd21cf1da-9f16-4802-a79c-efa455ab9768',
		last_seen: '2024-04-02T16:57:38.007255',
		identity: identities['36'],
		issue_name: 'InactiveIdentity',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **gx-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '3c14a4c1-eb2c-4e9f-8ed9-a2213abc442b',
		last_seen: '2024-03-28T16:57:38.007495',
		identity: identities['37'],
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **zm-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'dc965eac-cc5b-4717-96d2-7f21743c9db0',
		last_seen: '2024-03-13T16:57:38.007743',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		identity: identities['38'],
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **zm-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '1fe03b5b-b727-4a1e-9be0-bb6b5c8ad935',
		last_seen: '2024-04-03T16:57:38.007963',
		identity: identities['39'],
		issue_name: 'InactiveIdentity',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **mf-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '219e9e4b-89b6-4325-ade9-be74719bf966',
		last_seen: '2024-03-28T16:57:38.008177',
		identity: identities['40'],
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		description:
			"\nThe IAM user **ft-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '73fd18bb-d7d4-496d-92d6-1f9df77494f6',
		last_seen: '2024-03-12T16:57:38.008390',
		identity: identities['41'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **ry-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'c2e1af93-35bc-4c40-b67a-c58f24accf38',
		last_seen: '2024-04-02T16:57:38.008601',
		identity: identities['42'],
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **vp-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '2abe69c5-9a07-4af2-8c0f-29a491f7bd1b',
		last_seen: '2024-04-09T16:57:38.008811',
		identity: identities['43'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **ba-it-aws** has not been active in the last year.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '0b68480e-11c0-4c46-b06e-c5189aa468ce',
		last_seen: '2024-03-15T17:27:16.239463',
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		identity: identities['44'],
		issue_name: 'EC2IMDSv2NotRequired',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **AdminAccessRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '4155d7c0-7335-44ca-9dba-5a3507d72018',
		last_seen: '2024-03-13T17:27:16.239898',
		identity: identities['45'],
		issue_name: 'EC2IMDSv2NotRequired',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **ReadOnlyAccessRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '62097cf3-d82a-41b9-ac44-96fe65d93764',
		last_seen: '2024-04-05T17:27:16.240296',
		identity: identities['46'],
		issue_name: 'EC2IMDSv2NotRequired',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **LambdaExecutionRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '24acb4bc-4ff4-47ca-bafb-bf9c25682734',
		last_seen: '2024-03-27T17:27:16.240626',
		identity: identities['47'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'EC2IMDSv2NotRequired',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **S3DataManagementRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'ee45b014-031b-4c48-929c-b3db94ea3ee8',
		last_seen: '2024-03-23T17:27:16.240977',
		identity: identities['48'],
		issue_name: 'EC2IMDSv2NotRequired',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **EC2InstanceRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'd81610cd-20dd-4064-b632-526ecd087d1c',
		last_seen: '2024-04-03T17:27:16.241300',
		identity: identities['49'],
		issue_name: 'EC2IMDSv2NotRequired',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **DynamoDBAccessRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'b4df427f-52cc-47fc-8863-cf4d3e7589bf',
		last_seen: '2024-04-04T17:27:16.241657',
		identity: identities['50'],
		issue_name: 'EC2IMDSv2NotRequired',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **CloudWatchLogsRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '8aacce2e-b0c0-4172-a340-8205c738fcc8',
		last_seen: '2024-03-21T17:27:16.241975',
		identity: identities['51'],
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_name: 'EC2IMDSv2NotRequired',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **APIGatewayInvokeRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '587dd9af-d217-46a7-86c8-35abb4195e3b',
		last_seen: '2024-03-26T17:27:16.242310',
		identity: identities['52'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'EC2IMDSv2NotRequired',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **RDSBackupRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '826bfa32-c5e0-499e-abb3-5fc65ca4ed91',
		last_seen: '2024-04-01T17:27:16.242626',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		identity: identities['53'],
		issue_name: 'EC2IMDSv2NotRequired',
		issue_source: 'AWS',
		description:
			'\nThe IAM Role **ECSExecutionRole** is used as an instance profile in EC2 instances that has IMDSv2 optional and not required.\nIMDSv1 does not require session-oriented requests, making it more susceptible to server-side request forgery (SSRF) attacks.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 4,
		id: 'f68bedaa-871a-4838-b3e9-acaf8118ec96',
		last_seen: '2024-04-11T12:23:48.316278Z',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		identity: identities['54'],
		issue_name: 'UnFederatedIdentity',
		issue_source: 'RDS_POSTGRES',
		description:
			'\nAn unfederated Postgres role (**analytics_role**) detected in the RDS instance, which\n does not inherit permissions from the "rds_iam" role.\nThis approach, relying on traditional username and password authentication, diverges from AWS security best practices.\nUsing roles that are not federated with IAM increases the risk of credential leakage and\n makes access management more challenging.\nIt is recommended to utilize IAM roles for more secure and manageable access control.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '17f80cf1-39a2-4c59-bf5d-b3c53a5efb90',
		last_seen: '2024-04-17T07:01:18.625636Z',
		created_at: '2024-03-14T11:21:25.170607Z',
		identity: identities['55'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'AWS',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **DaemonSet**, Name: **my-daemonset**.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '87517298-abb2-4d40-8d21-94620770017a',
		last_seen: '2024-04-17T07:01:18.625636Z',
		created_at: '2024-03-14T11:21:25.170607Z',
		identity: identities['56'],
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'AWS',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **StatefulSet**, Name: **my-statefulset**.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: 'aeadf7f1-b12d-4e51-ae5c-701d076ac827',
		last_seen: '2024-04-17T07:01:18.625636Z',
		created_at: '2024-03-14T11:21:25.170607Z',
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		identity: identities['57'],
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'GCP',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **Deployment**, Name: **ubuntu-deployment-helm**.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '67c1cf3f-7e8f-4b7f-9a5e-870c8e6d77ff',
		last_seen: '2024-04-17T07:01:18.625636Z',
		created_at: '2024-03-14T11:21:25.170607Z',
		identity: identities['58'],
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'GCP',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **Deployment**, Name: **ubuntu-deployment-helm**.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '41507757-fc90-40c9-9a6e-c0a76f3d8413',
		last_seen: '2024-04-17T07:01:18.625636Z',
		created_at: '2024-03-14T11:21:25.170607Z',
		identity: identities['59'],
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'AWS',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **Deployment**, Name: **ubuntu-deployment-helm**.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '45978535-50c0-49d7-9aa6-d4ad323782bf',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		last_seen: '2024-04-17T07:01:18.625636Z',
		created_at: '2024-03-14T11:21:25.170607Z',
		identity: identities['60'],
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'GCP',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **Deployment**, Name: **ubuntu-deployment-helm**.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'da6ae07e-ee47-494f-9168-773364d2fa5b',
		last_seen: '2024-04-17T07:01:18.625636Z',
		created_at: '2024-03-14T11:21:25.170607Z',
		identity: identities['61'],
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'AWS',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **Deployment**, Name: **ubuntu-deployment-helm**.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '2839092a-8960-459f-bdd6-54427661c3c4',
		last_seen: '2024-04-17T07:01:18.625636Z',
		created_at: '2024-03-14T11:21:25.170607Z',
		identity: identities['62'],
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'AWS',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **Deployment**, Name: **ubuntu-deployment-helm**.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '665635ac-aea8-409f-9809-4b93b011cc1b',
		last_seen: '2024-04-17T07:01:18.625636Z',
		created_at: '2024-03-14T11:21:25.170607Z',
		identity: identities['63'],
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'AWS',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **Deployment**, Name: **ubuntu-deployment-helm**.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '8ea26448-6833-46cf-a034-7fa5853f876c',
		last_seen: '2024-04-17T07:01:18.625636Z',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		created_at: '2024-03-14T11:21:25.170607Z',
		identity: identities['64'],
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'GCP',
		description:
			'\nAWS access key (**AKIAY5FH5K8HMSAVMHPT**) referenced in a pod in an EKS cluster via environment variables. \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\nCluster: **arn:aws:eks:us-east-1:328041475888:cluster/ariel-test**, Namespace: **ariel-test**, Kind: **Deployment**, Name: **ubuntu-deployment-helm**.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '509cff89-1f39-4da4-84a8-64ca6e58fae4',
		last_seen: '2024-04-17T07:01:18.623774Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: identities['65'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS access key rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.\nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise.\nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- Access key **AKIA8664T8TT477PMKGR** was not rotated for **131+** days.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: 'f088a029-fe57-420f-b76b-212066d9f58c',
		last_seen: '2024-01-30T13:16:28Z',
		created_at: '2023-10-11T11:57:44Z',
		identity: identities['65'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'CloudtrailIamUserUsedFromEndpoint',
		issue_source: 'AWS',
		description:
			'\nThe AWS IAM user **arn:aws:iam::862947542951:user/ci-user** access key has been used from employee endpoint.\nthis is a potential security risk, as the access key is saved on the endpoint and if the endpoint is compromised,\nthe access key can be used to access the AWS account.\nlast event time: 2024-01-30 13:16:28 (UTC)\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: 'af5b401a-1c76-4703-b6e0-fbe08476821a',
		last_seen: '2024-04-17T07:01:18.576695Z',
		created_at: '2024-03-26T10:52:59.165393Z',
		identity: identities['65'],
		issue_name: 'PasswordInactiveIdentity',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **ci-user** hasn't logged in for a long time with a password to the web console, which indicates a potential security concern.\nThis situation often means the user account is inactive or no longer in use. Such accounts can become security vulnerabilities, as their credentials might be outdated or compromised without detection.\nRegularly monitoring and managing these accounts, including updating or deactivating them, is crucial to maintain strong security practices in AWS. This ensures that only active, authenticated users have access to the AWS console and resources.\n",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '03e1951d-b05a-4af1-920f-d4404443382d',
		last_seen: '2024-04-17T07:01:18.315456Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: identities['65'],
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		issue_name: 'PasswordRotation',
		issue_source: 'AWS',
		description:
			'\nThe IAM user **ci-user** has not rotated its password more then **90** days.\nIAM users with unrotated passwords pose a security risk, as long-standing, unchanged human-picked passwords are more vulnerable to compromise. Regular password rotation is essential to reduce the risk of unauthorized access and keep the AWS environment secure.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'fbbf2fb9-f4cd-422c-bebe-51d4c079b851',
		last_seen: '2024-04-17T07:01:18.576565Z',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: identities['65'],
		issue_name: 'UnFederatedIdentity',
		issue_source: 'AWS',
		description:
			"\nThe AWS IAM user **ci-user** has console access.\nIt's recommended to avoid creating AWS IAM users with console access and instead use federated users.\nFederated access enhances security by enabling centralized management of credentials and adhering to your organization's existing security protocols.\n",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '17f763aa-025d-44c0-91fe-1f3c91b079e9',
		last_seen: '2024-04-17T07:01:18.623774Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: identities['66'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS access key rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.\nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise.\nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- Access key **AKIAN0EETWMVEF2V4ZHY** was not rotated for **177+** days.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '57534e97-84ff-43ca-859a-57a5cc34bb19',
		last_seen: '2024-04-17T07:01:18.462463Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: identities['66'],
		issue_name: 'InactiveAccessKey',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'AWS',
		description:
			'\nUnused access keys refer to the access key IDs that are associated with an AWS account but have not been used for a certain period.\nIf an access key has not been used for an extended period, it might be an indication that it is no longer needed.\nKeeping unused keys can pose a security risk, as they could potentially be exploited if they fall into the wrong hands.\n\n- Access key **AKIAN0EETWMVEF2V4ZHY** was not used for **177+** days.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '0d0d6913-0f2a-4d6a-a863-e3cddbe5f46f',
		last_seen: '2024-04-17T07:01:18.428664Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: identities['66'],
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		description:
			"\nThe IAM user **terraform-prod** has not been active in the last **176**+ days.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '48a14039-ced6-40ea-83a2-e324de4f96d8',
		last_seen: '2024-04-17T07:01:18.583369Z',
		identity: identities['67'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'EKSUnassociatedServiceAccount',
		issue_source: 'AWS',
		description:
			'\nThe service account **synergy-security-prd-connector** in the namespace **synergy-security-prd** (**arn:aws:eks:us-east-1:296957980053:cluster/dave-prod**) is not associated \nwith any workload resource (Pods, Deployments, ReplicaSets, CronJobs, Jobs, StatefulSets, DaemonSets).\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: 'b02fde8f-fdcd-44c8-a7f9-a04a42e97fd4',
		last_seen: '2024-04-17T07:01:27.026475Z',
		identity: identities['92'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'MissingMFA',
		issue_source: 'OKTA',
		description:
			'\nA user account is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'ade172e8-749b-4181-851e-d9b482679080',
		last_seen: '2024-04-17T12:47:48.383Z',
		identity: identities['91'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'MissingMFA',
		issue_source: 'OKTA',
		description:
			'\nA user account is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '65831f96-7b24-4b84-a432-20052cca1052',
		last_seen: '2024-04-17T12:47:48.383Z',
		identity: identities['93'],
		issue_name: 'MissingMFA',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'OKTA',
		description:
			'\nA user account is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '4028a258-6845-4ea4-9361-32bf8ca733fa',
		last_seen: '2024-04-17T12:47:48.383Z',
		identity: identities['75'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'MissingMFA',
		issue_source: 'OKTA',
		description:
			'\nA user account is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'a76b3593-6867-457b-a514-19de91c39896',
		last_seen: '2024-04-17T12:47:48.383Z',
		identity: identities['76'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'MissingMFA',
		issue_source: 'OKTA',
		description:
			'\nA user account is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '6a2ef3ca-eadc-498c-a96b-e1ac887b08d7',
		last_seen: '2024-04-17T12:47:48.383Z',
		identity: identities['77'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'MissingMFA',
		issue_source: 'OKTA',
		description:
			'\nA user account is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '4528ef94-7e66-4942-8dcf-a9b94671a5c1',
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		last_seen: '2024-04-17T12:47:48.383Z',
		identity: identities['78'],
		issue_name: 'MissingMFA',
		issue_source: 'OKTA',
		description:
			'\nA user account is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'a1b2d26a-237c-4d7f-bf78-078fe34b3979',
		last_seen: '2024-04-17T07:01:18.583369Z',
		identity: identities['79'],
		issue_name: 'EKSUnassociatedServiceAccount',
		issue_source: 'AWS',
		description:
			'\nThe service account **dwh-user** in the namespace **dwh-user-prd** (**arn:aws:eks:us-east-1:296957980053:cluster/prod**) is not associated \nwith any workload resource (Pods, Deployments, ReplicaSets, CronJobs, Jobs, StatefulSets, DaemonSets).\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '6497e3cd-4146-4b41-bb33-934e66bec02b',
		last_seen: '2024-04-17T07:01:18.583369Z',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		identity: identities['80'],
		issue_name: 'EKSUnassociatedServiceAccount',
		issue_source: 'AWS',
		description:
			'\nThe service account **synergy-security-connector** in the namespace **synergy-security-connector-prd** (**arn:aws:eks:us-east-1:296957980053:cluster/prod**) is not associated \nwith any workload resource (Pods, Deployments, ReplicaSets, CronJobs, Jobs, StatefulSets, DaemonSets).\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '54c3edaa-c471-4135-921d-b4933fcec63c',
		last_seen: '2024-04-17T07:01:18.583369Z',
		identity: identities['81'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'EKSUnassociatedServiceAccount',
		issue_source: 'AWS',
		description:
			'\nThe service account **synergy-security-reader** in the namespace **synergy-security-reader-prd** (**arn:aws:eks:us-east-1:296957980053:cluster/prod**) is not associated \nwith any workload resource (Pods, Deployments, ReplicaSets, CronJobs, Jobs, StatefulSets, DaemonSets).\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: 'fc40c953-fb7a-4338-8284-944de296bce1',
		last_seen: '2024-04-17T07:01:18.583369Z',
		identity: identities['82'],
		issue_name: 'EKSUnassociatedServiceAccount',
		issue_source: 'AWS',
		description:
			'\nThe service account **synergy-security-prd-reader** in the namespace **synergy-security-prd-reader** (**arn:aws:eks:us-east-1:296957980053:cluster/prod**) is not associated \nwith any workload resource (Pods, Deployments, ReplicaSets, CronJobs, Jobs, StatefulSets, DaemonSets).\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '834b6d42-6463-4e17-99fe-c4aad9d6b1a5',
		last_seen: '2024-04-17T07:01:18.315208Z',
		created_at: '2024-03-28T14:29:43.902401Z',
		identity: identities['83'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS access key rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.\nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise.\nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- Access key **AKIAPBNIDIR2Q3XMW36D** was not rotated for **107+** days.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '3fe52e8d-39cc-4dbd-803d-dcbf68077a74',
		last_seen: '2024-04-11T06:52:54Z',
		created_at: '2023-12-31T15:07:46Z',
		identity: identities['83'],
		issue_name: 'CloudtrailIamUserUsedFromEndpoint',
		issue_source: 'AWS',
		description:
			'\nThe AWS IAM user **arn:aws:iam::818837914182:user/prod-eks** access key has been used from employee endpoint.\nthis is a potential security risk, as the access key is saved on the endpoint and if the endpoint is compromised,\nthe access key can be used to access the AWS account.\nlast event time: 2024-04-11 06:52:54 (UTC)\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'f8a7b6c5-d4e3-4a2b-9c1d-0f2e3a4b5c6d',
		last_seen: '2024-08-27T23:59:59Z',
		created_at: '2024-08-27T00:00:00Z',
		identity: identities['83'],
		issue_name: 'ServiceAccountSuspiciousBehavior',
		issue_source: 'AWS',
		description:
			"The AWS IAM user **arn:aws:iam::818837914182:user/prod-eks** was detected making anomalous calls to the IAM service during a 7 hour period, between 2024-08-27 15:08:04Z and 2024-08-27 22:11:56Z, from the IP 3.80.123.45. This behavior could indicate possible compromise of the user's access key: **AKIAPBNIDIR2Q3XMW36D**.\n\nAnomalous behavior properties:\n* New IAM API calls not seen in last 6 months for this user (ListAttachedGroupPolicies, ListAttachedRolePolicies, ListAttachedUserPolicies, ListGroupPolicies, ListGroups, ListGroupsForUsers, ListPolicies, ListRolePolicies, ListRoles, ListUserPolicies, ListUsers)\n* High number of failed IAM calls compared to user's daily baseline: 57\n* New IP not seen in last 6 months for user: 3.80.123.45\n* New IP not seen in last 6 months for organization: 3.80.123.45",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-08-27T23:59:59Z',
		created_at: '2024-08-27T00:00:00Z',
		identity: identities['172'],
		issue_name: 'ServiceAccountSuspiciousBehavior',
		issue_source: 'AWS',
		description:
			"The AWS IAM user **arn:aws:iam::623356180012:user/automation_serverless** was detected making anomalous calls to the IAM service during a 7 hour period, between 2024-08-27 15:08:04Z and 2024-08-27 22:11:56Z, from the IP 3.80.123.45. This behavior could indicate possible compromise of the user's access key: **AKIA2ODYTOW1MU6FF1P4**.\n\nAnomalous behavior properties:\n* New IAM API calls not seen in last 6 months for this user (ListAttachedGroupPolicies, ListAttachedRolePolicies, ListAttachedUserPolicies, ListGroupPolicies, ListGroups, ListGroupsForUsers, ListPolicies, ListRolePolicies, ListRoles, ListUserPolicies, ListUsers)\n* New IP not seen in last 6 months for user: 3.80.123.45\n* New IP not seen in last 6 months for organization: 3.80.123.45",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-07-10T23:59:59Z',
		created_at: '2024-07-10T00:00:00Z',
		identity: identities['177'],
		issue_name: 'ServiceAccountSuspiciousBehavior',
		issue_source: 'AWS',
		description:
			"The AWS IAM user **arn:aws:iam::818837914182:user/Splunk_Access** was detected making anomalous calls to the IAM service during a 1 hour period, between 2024-07-10 03:54:38Z and 2024-07-10 04:16:05Z, from the IP 64.155.128.247. This behavior could indicate possible compromise of the user's access key: **AKIAZTLFMVR9X3PQRSTU**.\n\nAnomalous behavior properties:\n* New IAM API calls not seen in last 6 months for this user (GenerateCredentialReport, GetCredentialReport, ListGroups, ListInstanceProfiles, ListRoles, ListUsers)\n* New IP not seen in last 6 months for user: 64.155.128.247\n* New IP not seen in last 6 months for organization: 64.155.128.247",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-07-10T23:59:59Z',
		created_at: '2024-07-10T00:00:00Z',
		identity: identities['178'],
		issue_name: 'ServiceAccountSuspiciousBehavior',
		issue_source: 'AWS',
		description:
			"The AWS IAM user **arn:aws:iam::818837914182:user/tableau-bkup-rstr** was detected making anomalous calls to the IAM service during a 1 hour period, between 2024-07-10 03:54:38Z and 2024-07-10 04:16:05Z, from the IP 64.155.128.247. This behavior could indicate possible compromise of the user's access key: **AKIAZTLFMVR9X3PQRSTF**.\n\nAnomalous behavior properties:\n* New IAM API calls not seen in last 6 months for this user (GenerateCredentialReport, GetCredentialReport, ListGroups, ListInstanceProfiles, ListRoles, ListUsers)\n* New IP not seen in last 6 months for user: 64.155.128.247\n* New IP not seen in last 6 months for organization: 64.155.128.247",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'f8c3a9b1-6d24-4e17-8f5a-92c6b3d4e0a7',
		last_seen: '2024-04-17T07:01:18.315208Z',
		created_at: '2024-03-28T14:29:43.902401Z',
		identity: identities['84'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS access key rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.\nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise.\nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- Access key **AKIAZTLFMVR7H9QJGX5N** was not rotated for **107+** days.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '3e7d2c5f-9a18-4b6d-80c1-5f4e2d1a9b3c',
		last_seen: '2024-04-11T06:52:54Z',
		created_at: '2023-12-31T15:07:46Z',
		identity: identities['84'],
		issue_name: 'CloudtrailIamUserUsedFromEndpoint',
		issue_source: 'AWS',
		description:
			'\nThe AWS IAM user **arn:aws:iam::818837914182:user/st-notifier-us** access key has been used from employee endpoint.\nthis is a potential security risk, as the access key is saved on the endpoint and if the endpoint is compromised,\nthe access key can be used to access the AWS account.\nlast event time: 2024-04-11 06:52:54 (UTC)\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'e2f8a6c4-9b3d-4f17-85e0-1d6c2a7b9f3e',
		last_seen: '2024-04-11T06:52:54Z',
		created_at: '2023-12-31T15:07:46Z',
		identity: identities['84'],
		issue_name: 'ShadowAdmin',
		issue_source: 'AWS',
		description:
			'\nThe IAM user **arn:aws:iam::818837914182:user/st-notifier-us** has the following permissions, which can possibly allow escalating privileges to admin permissions:\n* `iam:PassRole`\n* `lambda:CreateFunction`\n* `lambda:InvokeFunction`\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-04-11T06:52:54Z',
		created_at: '2023-12-31T15:07:46Z',
		identity: identities['34'],
		issue_name: 'ShadowAdmin',
		issue_source: 'AWS',
		description:
			'\nThe IAM user **arn:aws:iam::467791917130:user/yt-it-aws** has the following permissions, which can possibly allow escalating privileges to admin permissions:\n* `iam:PassRole`\n* `lambda:CreateFunction`\n* `lambda:InvokeFunction`\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-04-11T06:52:54Z',
		created_at: '2023-12-31T15:07:46Z',
		identity: identities['176'],
		issue_name: 'ShadowAdmin',
		issue_source: 'AWS',
		description:
			'\nThe IAM user **arn:aws:iam::467791917130:user/dean-davis** has the following permissions, which can possibly allow escalating privileges to admin permissions:\n* `iam:PassRole`\n* `lambda:CreateFunction`\n* `lambda:InvokeFunction`\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-04-11T06:52:54Z',
		created_at: '2023-12-31T15:07:46Z',
		identity: identities['42'],
		issue_name: 'ShadowAdmin',
		issue_source: 'AWS',
		description:
			'\nThe IAM user **arn:aws:iam::467791917130:user/vp-it-aws** has the following permissions, which can possibly allow escalating privileges to admin permissions:\n* `iam:PassRole`\n* `lambda:CreateFunction`\n* `lambda:InvokeFunction`\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-04-11T06:52:54Z',
		created_at: '2023-12-31T15:07:46Z',
		identity: identities['4'],
		issue_name: 'ShadowAdmin',
		issue_source: 'AWS',
		description:
			'\nThe IAM user **arn:aws:iam::467791917130:user/michael-quinn** has the following permissions, which can possibly allow escalating privileges to admin permissions:\n* `iam:PassRole`\n* `lambda:CreateFunction`\n* `lambda:InvokeFunction`\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-04-11T06:52:54Z',
		created_at: '2023-12-31T15:07:46Z',
		identity: identities['11'],
		issue_name: 'ShadowAdmin',
		issue_source: 'AWS',
		description:
			'\nThe IAM user **arn:aws:iam::467791917130:user/alex-white** has the following permissions, which can possibly allow escalating privileges to admin permissions:\n* `iam:PassRole`\n* `lambda:CreateFunction`\n* `lambda:InvokeFunction`\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'd2ae19d7-106c-4744-8afc-4b17013a7256',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['85'],
		issue_name: 'InactiveIdentity',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-08-24**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '89ef0b4b-fc9e-4970-af56-1004c22482a7',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['85'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'PasswordRotation',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-08-24**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '3678f350-b357-48b3-b130-5e8782105ea8',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['86'],
		issue_name: 'InactiveIdentity',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-08-17**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '324b1f1e-3dc9-47a7-865f-46375896a2d0',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['86'],
		issue_name: 'PasswordRotation',
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-06-25**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '8ac978b5-54ab-455c-9d8b-d37ea969b52c',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['87'],
		issue_name: 'InactiveIdentity',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-08-14**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'efc1b9ae-e2ed-4334-b922-0dd146c2b84a',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['87'],
		issue_name: 'PasswordRotation',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-06-29**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'e2dc9302-6cbc-4f2a-9cda-c6924d7b241d',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['88'],
		issue_name: 'InactiveIdentity',
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-08-04**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '0448f120-07a8-43bc-a895-9694552db1b8',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['88'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'PasswordRotation',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-06-15**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '04cc1ce4-0a9c-4fc4-b0e8-f837e5249982',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['89'],
		issue_name: 'InactiveIdentity',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-08-01**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'be592006-0036-4629-8625-0c124ff7fe1e',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		identity: identities['89'],
		issue_name: 'PasswordRotation',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-08-18**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '16aed751-7f2e-4398-959a-981b8ecd3fb0',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['90'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'InactiveIdentity',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-06-04**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '4cba2612-a4bf-49ab-a568-a2788046473a',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['90'],
		issue_name: 'PasswordRotation',
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-07-02**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '82e9d82b-240a-46f0-87f2-9b85c4e7ee68',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['91'],
		issue_name: 'InactiveIdentity',
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-08-23**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '99b4db3e-db8a-4fc8-9c52-b83bc5dc713d',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['91'],
		issue_name: 'PasswordRotation',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-06-26**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '75f5b48d-56fd-4d7d-9146-322a759acc2f',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['92'],
		issue_name: 'InactiveIdentity',
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-08-05**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '000723a7-6f13-476f-aa4c-c8a8986b8b84',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['92'],
		issue_name: 'PasswordRotation',
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-08-17**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '49b1afca-3e01-4f42-9cd2-b25f8c662776',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		identity: identities['93'],
		issue_name: 'InactiveIdentity',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-08-16**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '107319cb-84b7-40d9-9970-99257fac5bdc',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['93'],
		issue_name: 'PasswordRotation',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-07-29**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'a5a36b2f-c9ba-4f13-bae1-e3dbde094f20',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['94'],
		issue_name: 'InactiveIdentity',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-06-01**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '0d1aa8ab-ee6b-4f52-87b8-ffddfcc4b629',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['94'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'PasswordRotation',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-08-12**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'a6177f14-4d72-47a6-be00-17f9d72e861b',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371960Z',
		identity: identities['95'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'InactiveIdentity',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-06-23**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'd7a0f9fa-5d92-4d4d-ab29-d65a22689494',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380123Z',
		identity: identities['95'],
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_name: 'PasswordRotation',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-07-16**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '4b727bce-a561-4f66-aa94-56de4fe8acbd',
		last_seen: '2024-04-16T13:36:33.546193Z',
		created_at: '2023-12-28T10:59:36.371998Z',
		identity: identities['96'],
		issue_name: 'InactiveIdentity',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		issue_source: 'OKTA',
		description:
			"\nThe user has not logged in since **2023-09-03**.\nInactive users in Okta are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '40f21c62-649e-4696-a56d-780f5f847d8b',
		last_seen: '2024-04-16T13:36:33.560380Z',
		created_at: '2023-12-28T10:59:36.380160Z',
		identity: identities['96'],
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_name: 'PasswordRotation',
		issue_source: 'OKTA',
		description:
			"\nThe user didn't change their password since **2023-09-03**.\nUnrotated passwords in Okta can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '18ca5d3b-4a03-4d03-aa00-929d081dbd35',
		last_seen: '2024-04-16T13:36:33.560786Z',
		created_at: '2023-12-28T10:59:36.345287Z',
		identity: identities['96'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'OKTA',
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		description:
			'\nUnrotated API Keys in Okta represent a security risk, as long-standing keys are more susceptible to compromise. \nRegular rotation of these keys is crucial for maintaining robust security.\n\n- API Key: Synergy Security created 226 days ago\n\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '270252ae-cc43-4694-9443-126b51545ebe',
		last_seen: '2024-04-16T13:36:33.560786Z',
		created_at: '2023-12-28T10:59:36.345215Z',
		identity: identities['87'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'OKTA',
		description:
			'\nUnrotated API Keys in Okta represent a security risk, as long-standing keys are more susceptible to compromise. \nRegular rotation of these keys is crucial for maintaining robust security.\n\n- API Key: Firefly created 581 days ago\n\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '1e2d4ca3-4fbf-4db8-847b-bcabfda6231d',
		last_seen: '2024-04-10T06:44:23.877251Z',
		created_at: '2024-02-07T16:22:20.206287Z',
		identity: identities['97'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'HibpSecondaryEmailBreach',
		issue_source: 'HIBP',
		description:
			"\nThis user has at least 1 email attributed to them in the 'Have I Been Pwned' database, which indicates it was compromised in a data breach.\nImmediate action, like changing passwords and monitoring for suspicious activity, is essential for security.\n\nThe following emails were found in the 'Have I Been Pwned' database:\n- elizabeth.coleman@gmail.com\n     - 000webhost (2015-03-01): Email addresses, IP addresses, Names, Passwords)\n     - 2844Breaches (2018-02-19): Email addresses, Passwords)\n     - 8tracks (2017-06-27): Email addresses, Passwords)\n     - Animoto (2018-07-10): Dates of birth, Email addresses, Geographic locations, Names, Passwords)\n     - BTCE (2014-10-01): Account balances, Email addresses, IP addresses, Passwords, Usernames, Website activity)\n     - Canva (2019-05-24): Email addresses, Geographic locations, Names, Passwords, Usernames)\n     - Chess (2023-11-08): Email addresses, Geographic locations, Names, Usernames)\n     - Collection1 (2019-01-07): Email addresses, Passwords)\n     - db8151dd (2020-02-20): Email addresses, Job titles, Names, Phone numbers, Physical addresses, Social media profiles)\n     - Dropbox (2012-07-01): Email addresses, Passwords)\n     - ExploitIn (2016-10-13): Email addresses, Passwords)\n     - gPotato (2007-07-12): Dates of birth, Email addresses, Genders, IP addresses, Names, Passwords, Physical addresses, Security questions and answers, Usernames, Website activity)\n     - KayoMoe (2018-09-11): Email addresses, Passwords)\n     - MailRu (2014-09-10): Email addresses, Passwords)\n     - MySpace (2008-07-01): Email addresses, Passwords, Usernames)\n     - NazApi (2023-09-20): Email addresses, Passwords)\n     - Trello (2024-01-16): Email addresses, Names, Usernames)\n     - Twitter200M (2021-01-01): Email addresses, Names, Social media profiles, Usernames)\n     - WildStar (2015-07-11): Dates of birth, Email addresses, IP addresses, Passwords, Usernames)\n\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '65831f96-7b24-4b84-a432-20052cca1052',
		last_seen: '2024-04-17T12:47:48.383Z',
		identity: identities['97'],
		issue_name: 'MissingMFA',
		issue_source: 'OKTA',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		description:
			'\nA user account is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '12bed746-e1bb-4fe3-864b-f7c34aec5231',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2024-03-05T16:17:58.856486Z',
		identity: identities['98'],
		issue_name: 'MissingMFA',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		issue_source: 'JUMPCLOUD',
		description:
			'\nThe user **Dashboard User** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'ca6cf404-df51-4527-b7f6-871170ed4700',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-03-29T17:00:38.448409Z',
		identity: identities['99'],
		issue_name: 'MissingMFA',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		issue_source: 'JUMPCLOUD',
		description:
			'\nThe user **Robert Perez** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '51cd7f75-2b57-43bf-86a0-b5eb133a0666',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-06-01T13:38:08.118339Z',
		identity: identities['100'],
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		issue_name: 'MissingMFA',
		issue_source: 'JUMPCLOUD',
		description:
			'\nThe user **Brandon Huff** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '45e48a83-19f1-4bc4-8cd4-a35454e7280f',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-04-08T20:00:27.677930Z',
		identity: identities['101'],
		issue_name: 'MissingMFA',
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_source: 'JUMPCLOUD',
		description:
			'\nThe user **Autumn Beard** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '3afd576d-c232-49e6-91f6-4450a90cd5fb',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-09-11T02:11:49.621086Z',
		identity: identities['102'],
		assignee: '7db5b1d8-5c14-487b-a5bb-13824578ef19',
		issue_name: 'MissingMFA',
		issue_source: 'JUMPCLOUD',
		description:
			'\nThe user **Matthew Jones** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'ce2dc096-a1d0-4388-a252-e29fb8c60844',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-08-23T11:53:22.302623Z',
		identity: identities['103'],
		issue_name: 'MissingMFA',
		issue_source: 'JUMPCLOUD',
		description:
			'\nThe user **Mr. Albert Burke MD** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: 'a7b5d687-61a9-48d2-be92-6b4ce8b5fb90',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-03-14T03:49:26.188869Z',
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		identity: identities['104'],
		issue_name: 'MissingMFA',
		issue_source: 'JUMPCLOUD',
		description:
			'\nThe user **Michael Morris** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '22db072b-ff0b-4cf9-9002-b65771c79a0d',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-07-22T17:41:14.815837Z',
		identity: identities['105'],
		issue_name: 'MissingMFA',
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_source: 'JUMPCLOUD',
		description:
			'\nThe user **Brian Mitchell** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '6a422a38-9bd6-4231-9506-309401d4a0db',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-06-02T04:34:20.513868Z',
		identity: identities['106'],
		issue_name: 'MissingMFA',
		issue_source: 'JUMPCLOUD',
		description:
			'\nThe user **Alec Adams** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '29617d93-b0ed-436d-bfee-c0d83fd78a0f',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-06-10T16:12:56.623585Z',
		identity: identities['107'],
		issue_name: 'MissingMFA',
		issue_source: 'JUMPCLOUD',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		description:
			'\nThe user **Sean Barber** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'fec83d43-465e-441f-b2df-3d2d67e9db0e',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-06-04T00:25:13.792931Z',
		identity: identities['108'],
		issue_name: 'MissingMFA',
		issue_source: 'JUMPCLOUD',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		description:
			'\nThe user **Ian Summers** is set up without Multi-Factor Authentication (MFA), meaning it only relies on a password for access and\nlacks additional verification steps.\nThis configuration increases the risk of unauthorized access due to the absence\nof extra security layers like codes from a mobile device or biometric verification.\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '56ebb132-4027-4e46-ba6c-b87ecbb3b25c',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-09-10T22:42:56.253863Z',
		identity: identities['109'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '0501c20b-865a-4e1f-95f3-a7cf1c2628b5',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-04-19T22:48:23.506221Z',
		identity: identities['110'],
		issue_name: 'AccessKeyNotRotated',
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '6b707677-b4f0-41a5-8e70-5ca0b397fd9a',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-04-21T10:03:25.022938Z',
		assignee: 'ec731ed9-c271-4dc2-b857-42b59ef88582',
		identity: identities['111'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '8d65c44e-ea1c-4255-a4e4-f994636bba15',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-09-18T23:53:21.475333Z',
		identity: identities['112'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '20a83cd2-7094-4fff-b4a6-203878639eff',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-08-05T01:51:01.473965Z',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		identity: identities['113'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '5d23e22e-a113-4ea6-bfe2-5a2d73c5cb56',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-09-03T06:03:19.657750Z',
		identity: identities['114'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '83a6a740-f779-4c28-9fd4-7dc7e69ba9db',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-07-18T14:17:30.629345Z',
		identity: identities['115'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'd4c96b79-9162-44e4-b301-14b7ca87af2f',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-06-02T00:13:46.092414Z',
		identity: identities['116'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'e7f67a21-2b04-4305-87c7-eb1d8fcf9fba',
		last_seen: '2024-04-18T05:17:30.361288Z',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		created_at: '2023-09-14T09:44:42.751838Z',
		identity: identities['117'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '4ad81e99-e334-4a91-b153-cfd333f388db',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-05-17T22:18:57.219587Z',
		identity: identities['118'],
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'JUMPCLOUD',
		description:
			'\nUnrotated API Keys in Jumpcloud represent a security risk, as long-standing keys are more susceptible to compromise. Regular rotation of these keys is crucial for maintaining robust security. key was last rotated 287 days ago\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '7cc9697c-4e2b-486f-8d04-1016dc4d573c',
		last_seen: '2024-04-20T14:14:17.000000Z',
		created_at: '2024-03-14T21:20:44.000000Z',
		identity: identities['119'],
		issue_name: 'PasswordRotation',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **1805** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '86377568-7ea4-4ae9-a22c-f1dd1a57e8b4',
		last_seen: '2024-04-20T21:59:12.000000Z',
		created_at: '2024-03-25T22:34:15.000000Z',
		identity: identities['120'],
		issue_name: 'PasswordRotation',
		issue_source: 'ENTRA_ID',
		assignee: '344142e8-a0e3-482c-bfcc-74f440a16e51',
		description:
			"\nThe user didn't change their password for **1831** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '0918f210-b5ff-4f1e-8b46-14bd78a5f659',
		last_seen: '2024-04-20T11:43:34.000000Z',
		created_at: '2024-03-13T17:13:53.000000Z',
		identity: identities['121'],
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_name: 'PasswordRotation',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **1733** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '5bd0ca3e-3c74-43b6-9fdc-37a0446ad4fd',
		last_seen: '2024-04-20T02:15:23.000000Z',
		created_at: '2024-04-08T19:22:04.000000Z',
		identity: identities['122'],
		issue_name: 'PasswordRotation',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **1892** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'ace2e5ab-975f-4639-b1cd-165eed100e19',
		last_seen: '2024-04-20T12:20:28.000000Z',
		created_at: '2024-03-29T07:13:19.000000Z',
		identity: identities['123'],
		issue_name: 'PasswordRotation',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **1798** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'f4480058-8d5f-49ad-9c34-2dc1b237ea5e',
		last_seen: '2024-04-20T06:17:48.000000Z',
		created_at: '2024-03-15T10:51:13.000000Z',
		identity: identities['124'],
		issue_name: 'PasswordRotation',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **1797** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '0d279f1f-cc68-4452-8e3a-a8ed1a1e4d2a',
		last_seen: '2024-04-20T17:04:12.000000Z',
		created_at: '2024-03-13T08:36:13.000000Z',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		identity: identities['125'],
		issue_name: 'PasswordRotation',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **1802** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'a0f06170-cba4-4372-b66a-1cc125cb31d3',
		last_seen: '2024-04-20T14:22:06.000000Z',
		created_at: '2024-03-22T22:16:21.000000Z',
		identity: identities['126'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'PasswordRotation',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **1889** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '97baf12f-95e3-4e43-80e4-1445ce86d664',
		last_seen: '2024-04-20T10:19:55.000000Z',
		created_at: '2024-04-08T14:29:44.000000Z',
		identity: identities['127'],
		issue_name: 'PasswordRotation',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **1812** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '5f853d06-9b25-49ed-bb5a-d0f63db65d65',
		last_seen: '2024-04-20T20:13:53.000000Z',
		created_at: '2024-04-07T18:05:24.000000Z',
		identity: identities['128'],
		issue_name: 'PasswordRotation',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **1781** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 4,
		id: 'a8559441-786d-4c5f-b4f3-101de6c79dba',
		last_seen: '2024-04-20T05:20:16.000000Z',
		created_at: '2024-03-16T10:07:45.000000Z',
		identity: identities['139'],
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_name: 'InactiveIdentity',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user associated with **george.brown@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'OPEN',
	},
	{
		priority: 4,
		id: '6e2bcfa1-6d3a-4804-b30f-56f20362d1d0',
		last_seen: '2024-04-20T05:08:24.000000Z',
		created_at: '2024-03-16T10:07:45.000000Z',
		identity: identities['139'],
		issue_name: 'PasswordRotation',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **391** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '83c8cea9-f679-4488-9370-c03e4201c51a',
		last_seen: '2024-04-20T20:16:29.000000Z',
		created_at: '2024-03-16T10:07:45.000000Z',
		identity: identities['140'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'InactiveIdentity',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user associated with **joseph.villanueva@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'b4ea5469-1858-4f3c-a7ae-bb9ee71fb091',
		last_seen: '2024-04-20T04:18:16.000000Z',
		created_at: '2024-03-16T10:07:45.000000Z',
		identity: identities['140'],
		issue_name: 'PasswordRotation',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **122** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '8d1be3ef-0795-4120-bde9-8455459b8604',
		last_seen: '2024-04-20T04:51:34.000000Z',
		created_at: '2024-03-27T23:31:05.000000Z',
		identity: identities['141'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'MissingMFA',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user **elizabeth.thomas@synergy.com** does not have MFA enabled. A user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '2824d4d5-ebf7-4245-97ba-9614dab87afc',
		last_seen: '2024-04-20T17:30:45.000000Z',
		created_at: '2024-03-21T01:59:39.000000Z',
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		identity: identities['142'],
		issue_name: 'MissingMFA',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user **derrick.morris@synergy.com** does not have MFA enabled. A user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '90b6c40d-6afc-4ca3-b1b9-972b2a8a151a',
		last_seen: '2024-04-20T05:12:12.000000Z',
		created_at: '2024-03-30T03:17:04.000000Z',
		identity: identities['143'],
		issue_name: 'MissingMFA',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user **melissa.nguyen@synergy.com** does not have MFA enabled. A user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '5868978d-c7c7-46fd-9e7e-7c6067163ead',
		last_seen: '2024-04-20T05:26:48.000000Z',
		created_at: '2024-03-23T11:57:50.000000Z',
		identity: identities['144'],
		assignee: '856c859a-a552-4913-a08f-5bd03c29befb',
		issue_name: 'MissingMFA',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user **brett.jackson@synergy.com** does not have MFA enabled. A user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '584a5727-879f-4652-89d6-1c752dfa2db7',
		last_seen: '2024-04-20T12:54:25.000000Z',
		created_at: '2024-03-27T07:19:37.000000Z',
		identity: identities['145'],
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_name: 'MissingMFA',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user **aaron.hubbard@synergy.com** does not have MFA enabled. A user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '9d7020b5-9e57-4bd2-b29a-c2a7796a389f',
		last_seen: '2024-04-20T06:10:17.000000Z',
		created_at: '2024-03-20T05:43:43.000000Z',
		identity: identities['146'],
		issue_name: 'MissingMFA',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user **raymond.marks@synergy.com** does not have MFA enabled. A user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '3cdf4e99-3f9d-4b6d-89eb-133b083a87a2',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-09-29T00:33:27.721732Z',
		identity: identities['147'],
		issue_name: 'PasswordRotation',
		issue_source: 'JUMPCLOUD',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		description:
			"\nThe user **Jason Underwood PhD** didn't change their password for over days 90. Unrotated passwords in Jumpcloud can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '4582d7bf-f2a8-4948-9b14-491c91f78694',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-08-15T00:57:49.416026Z',
		identity: identities['148'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'PasswordRotation',
		issue_source: 'JUMPCLOUD',
		description:
			"\nThe user **Jonathan Kaiser** didn't change their password for over days 90. Unrotated passwords in Jumpcloud can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'a2de71fd-7c9d-4078-b9ff-03070878d829',
		last_seen: '2024-04-18T05:17:30.361288Z',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		created_at: '2023-05-09T04:56:20.442047Z',
		identity: identities['149'],
		issue_name: 'PasswordRotation',
		issue_source: 'JUMPCLOUD',
		description:
			"\nThe user **Tanner Taylor** didn't change their password for over days 90. Unrotated passwords in Jumpcloud can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '426cebde-318e-4458-a585-023b261ad482',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-05-31T20:28:21.988278Z',
		identity: identities['150'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'PasswordRotation',
		issue_source: 'JUMPCLOUD',
		description:
			"\nThe user **Andrew Mcdonald** didn't change their password for over days 90. Unrotated passwords in Jumpcloud can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'd9c15ae1-123a-4e64-99b3-e8fec63092bf',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-05-16T04:12:23.895217Z',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		identity: identities['151'],
		issue_name: 'PasswordRotation',
		issue_source: 'JUMPCLOUD',
		description:
			"\nThe user **Michael Casey** didn't change their password for over days 90. Unrotated passwords in Jumpcloud can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '04e59051-f9e0-4ecd-b3f9-b0d87d5cbaa1',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-05-28T00:13:53.745918Z',
		identity: identities['152'],
		issue_name: 'PasswordRotation',
		issue_source: 'JUMPCLOUD',
		description:
			"\nThe user **Ethan Jacobs** didn't change their password for over days 90. Unrotated passwords in Jumpcloud can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '735cc9d1-9cf4-47c7-b67c-e4c9ac3f907a',
		last_seen: '2024-04-18T05:17:30.361288Z',
		created_at: '2023-06-30T17:49:52.140231Z',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		identity: identities['153'],
		issue_name: 'PasswordRotation',
		issue_source: 'JUMPCLOUD',
		description:
			"\nThe user **Mrs. Megan Richards MD** didn't change their password for over days 90. Unrotated passwords in Jumpcloud can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.",
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'dd9c0417-744d-4223-b577-6ba37a00ce6f',
		last_seen: '2024-04-20T19:43:39.000000Z',
		created_at: '2024-03-24T01:18:38.000000Z',
		identity: identities['154'],
		issue_name: 'InactiveIdentity',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **travis.williams@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '290add46-ccff-4ab4-8ecd-43566e1765d9',
		last_seen: '2024-04-17T07:05:50.000000Z',
		created_at: '2024-03-23T11:14:02.000000Z',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		identity: identities['155'],
		issue_name: 'InactiveIdentity',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **dean.jones@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'd9ab8731-d842-4ee5-9365-8015d85d87f1',
		last_seen: '2024-04-17T07:21:47.000000Z',
		created_at: '2024-03-24T08:40:58.000000Z',
		identity: identities['156'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'InactiveIdentity',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **martha.avery@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: 'ad1e8b2a-cc81-402b-aef5-fe3c144cade2',
		last_seen: '2024-04-19T17:32:24.000000Z',
		created_at: '2024-03-31T23:52:29.000000Z',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		identity: identities['157'],
		issue_name: 'InactiveIdentity',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **anthony.dalton@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '4a06c2b5-db8f-4b6f-89a8-d4b2bf094480',
		last_seen: '2024-04-19T09:22:14.000000Z',
		created_at: '2024-03-15T05:01:01.000000Z',
		identity: identities['158'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'InactiveIdentity',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **dr.chelsea@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: 'b2697f75-c96e-4c91-b1fa-4501299c886d',
		last_seen: '2024-04-19T01:34:57.000000Z',
		created_at: '2024-03-24T23:38:55.000000Z',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		identity: identities['159'],
		issue_name: 'InactiveIdentity',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **jacqueline.thomas@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '1bac714f-729c-4395-84c6-554986aa70aa',
		last_seen: '2024-04-18T22:10:26.000000Z',
		created_at: '2024-03-17T04:59:29.000000Z',
		identity: identities['160'],
		issue_name: 'InactiveIdentity',
		issue_source: 'GOOGLE_WORKSPACE',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		description:
			'\nThe Google Workspace user associated with **dennis.young@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '2080b744-ec0b-4a25-b757-446b65014ae3',
		last_seen: '2024-04-17T12:10:21.000000Z',
		created_at: '2024-03-28T11:35:09.000000Z',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		identity: identities['161'],
		issue_name: 'InactiveIdentity',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **jennifer.clark@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 1,
		id: '49a3255c-4a15-4463-9ca1-e5a6e3a44c01',
		last_seen: '2024-04-19T11:21:24.000000Z',
		created_at: '2024-03-17T19:51:52.000000Z',
		identity: identities['162'],
		issue_name: 'InactiveIdentity',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **brittany.martinez@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'OPEN',
	},
	{
		priority: 1,
		id: '5aaf3f67-7060-4fbb-b23e-64377a3d8f34',
		last_seen: '2024-04-18T14:50:33.000000Z',
		created_at: '2024-03-28T23:29:00.000000Z',
		identity: identities['163'],
		issue_name: 'InactiveIdentity',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **kristen.howard@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '06ef9184-4883-4463-bb65-8541f85d99e8',
		last_seen: '2024-04-15T04:24:02.000000Z',
		created_at: '2024-03-15T15:56:24.000000Z',
		identity: identities['164'],
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_name: 'MissingMFA',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user **james.smith@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: '0e33980e-4805-473a-9ee2-1851c5fb11ec',
		last_seen: '2024-04-18T22:17:53.000000Z',
		created_at: '2024-03-25T01:21:33.000000Z',
		identity: identities['165'],
		issue_name: 'MissingMFA',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user **shannon.chang@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: 'addc2395-11bf-422b-ab83-7f4aacc786f4',
		last_seen: '2024-04-18T04:32:54.000000Z',
		created_at: '2024-03-18T05:43:27.000000Z',
		identity: identities['166'],
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_name: 'MissingMFA',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user **william.vargas@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: 'b269e4a3-9723-4a42-978f-96b608e36916',
		last_seen: '2024-04-16T03:36:38.000000Z',
		created_at: '2024-03-28T17:44:33.000000Z',
		identity: identities['167'],
		issue_name: 'MissingMFA',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user **hector.middleton@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '4f59960d-b653-4d36-86f6-55a337f83bee',
		last_seen: '2024-04-18T22:32:30.000000Z',
		created_at: '2024-03-16T23:07:44.000000Z',
		identity: identities['168'],
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_name: 'MissingMFA',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user **gary.hansen@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '2561e969-d72f-4e36-9215-fe3d1db04b5d',
		last_seen: '2024-04-18T21:36:58.000000Z',
		created_at: '2024-03-30T19:56:33.000000Z',
		identity: identities['169'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'MissingMFA',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user **ms.shelby@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'a324cd48-70f2-4d01-813d-c4ff2ab994f8',
		last_seen: '2024-04-19T04:51:08.000000Z',
		created_at: '2024-03-23T04:42:40.000000Z',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		identity: identities['169'],
		issue_name: 'InactiveIdentity',
		issue_source: 'GOOGLE_WORKSPACE',
		description:
			'\nThe Google Workspace user associated with **ms.shelby@synergy.com** is inactive (over **90** days).\nThis inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '6d2d68c3-fd0e-47bd-b73d-103ff19e2a66',
		last_seen: '2024-04-21T11:46:25.262929Z',
		created_at: '2024-04-17T16:41:03.286117Z',
		identity: identities['170'],
		issue_name: 'EKSAccessKeyReferencedInPod',
		issue_source: 'AWS',
		description:
			'\nAWS access key **AKIA2ODYTOW1MU6FF1P4** belonging to the user **production-eks-user** is referenced in an EKS cluster workload via environment variables.\n \nAWS best practice it to rely on the recommended IAM Roles for Service Accounts (IRSA) or AWS Pod Identity.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '355052a8-7ba7-4a6e-8a13-36686c0dc5fd',
		last_seen: '2024-04-21T11:46:24.729699Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: identities['170'],
		issue_name: 'InactiveIdentity',
		issue_source: 'AWS',
		description:
			"\nThe IAM user **production-eks-user** has not been active.\nAn inactive AWS IAM user is an account that hasn't been used for a long time, posing security risks.\nThese accounts may have outdated permissions and are often overlooked in security audits, making them potential targets for unauthorized access.\nRegular review and deactivation of such accounts is crucial for maintaining robust AWS security, ensuring only necessary and active accounts have access.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '27308514-8222-44b4-8530-bff40fa47e87',
		last_seen: '2024-04-21T11:46:24.701238Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: identities['170'],
		issue_name: 'InactiveAccessKey',
		issue_source: 'AWS',
		description:
			'\nUnused access keys refer to the access key IDs that are associated with an AWS account but have not been used for a certain period.\nIf an access key has not been used for an extended period, it might be an indication that it is no longer needed.\nKeeping unused keys can pose a security risk, as they could potentially be exploited if they fall into the wrong hands.\n\n- Access key AKIA2ODYTOW1MU6FF1P4 was never used.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '302c9ebe-8d17-4204-a282-5e66dded7deb',
		last_seen: '2024-04-21T05:19:57.994696Z',
		identity: identities['79'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nThe secret **dwh-user-token** of type **kubernetes.io/service-account-token** that is related to the service account **dwh-user** in namespace **dwh-user-prd** has not been rotated for the past **128** days.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 4,
		id: '6e1efaef-a245-4fa0-aab4-46e324ac54b3',
		last_seen: '2024-04-25T06:52:05.507382Z',
		created_at: '2024-04-24T12:46:07.675066Z',
		identity: identities['171'],
		assignee: '9d440786-e929-4565-8498-6474c85b3a6b',
		issue_name: 'CrossAccountAccess',
		issue_source: 'AWS',
		description:
			'\nThe IAM role **OrganizationAccountAccessRole** in account **code-stream-prod** was found to have a trust relationship that permits access from an account which is classified as a lower-privileged or unauthorized account according to security policies. This configuration introduces a potential privilege escalation risk by allowing principals from the untrusted account to assume the IAM role in the affected account **code-stream-prod**. As a result, users or applications in the untrusted account may gain unintended or excessive access within the affected account, potentially compromising sensitive resources and actions. Immediate action is advised to remove this cross-account trust and restrict access to only authorized principals, in order to mitigate unauthorized access and maintain secure role-based boundaries.\n\nTrusted entities:\n- 241255784015 - dev-account - CRITICAL',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '4eacc720-7752-47a9-a9eb-f7bf1797d503',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'CloudtrailResourceCreatedByOffBoardedEmployee',
		issue_source: 'AWS',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		description:
			'\nAn AWS Access Key **AKIA2ODYTOW1MU6FF1P4** was created by a former employee **joseph.rogers@synergy.com**,\nfor another user identity **automation_serverless**.\nThe Access Key has not been rotated since the employee has left (last activity at **2024-01-05 | 07:45**).\nThis is a security risk, as the former employee may still have access to the account and its resources.',
		status: 'IN_PROGRESS',
		identity: identities['172'],
	},
	{
		priority: 2,
		id: '66f6d8ca-f7e3-4a20-9700-0e491338ed1c',
		last_seen: '2024-01-18T07:03:32.866131Z',
		identity: identities['172'],
		issue_name: 'CloudtrailIamUserUsedFromEndpoint',
		issue_source: 'AWS',
		description:
			'\nThe AWS IAM user **arn:aws:iam::623356180012:user/automation_serverless** access key has been used from employee endpoint.\nThis is a potential security risk, as the access key is saved on the endpoint and if the endpoint is compromised,\nthe access key can be used to access the AWS account.\nlast event time: 2024-04-08T12:26:19Z (UTC). Users that used the access key: **joseph.rogers@synergy.com** \n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '66f134aa-f7e3-4f20-9700-0e496338ed1c',
		last_seen: '2024-05-07T07:03:30.531547Z',
		identity: identities['172'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'AWS',
		description:
			'\nAWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.    \nNeglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise. \nRegular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.\n\nThe following keys were not rotated in the last **90** days:\n- Access key **AKIA2ODYTOW1MU6FF1P4** was not rotated for **826+** days.\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '4eacc720-7752-47a9-a9eb-f7bf1797d502',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'CloudtrailResourceCreatedByOffBoardedEmployee',
		issue_source: 'AWS',
		description:
			'\nAn AWS Access Key **AKIA0OASQOW1MU6AF1P4** was created by a former employee **joseph.rogers@synergy.com**,\nfor another user identity **jenkins**.\nThe Access Key has not been rotated since the employee has left (last activity at **2024-01-05 | 07:45**).\nThis is a security risk, as the former employee may still have access to the account and its resources.',
		status: 'OPEN',
		identity: identities['173'],
	},
	{
		priority: 3,
		id: '4eacc720-7752-47a9-a9eb-f7bf1797d501',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'CloudtrailResourceCreatedByOffBoardedEmployee',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'AWS',
		description:
			'\nAn AWS Access Key **AKIA3ODYTOW1MU6FA1P4** was created by a former employee **joseph.rogers@synergy.com**,\nfor another user identity **terraform**.\nThe Access Key has not been rotated since the employee has left (last activity at **2024-01-05 | 07:45**).\nThis is a security risk, as the former employee may still have access to the account and its resources.',
		status: 'IN_PROGRESS',
		identity: identities['174'],
	},
	{
		priority: 2,
		id: '4eacc720-7752-4111-a7eb-f7bf1797d501',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'SNOWFLAKE',
		description:
			"The user has not logged in since **2024-06-02 10:51:41**. Inactive users in Snowflake are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.",
		status: 'IN_PROGRESS',
		identity: snowflakeUserData['60000'],
	},
	{
		priority: 3,
		id: '4eacc511-7752-4111-a7eb-f7bf1797d501',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'MissingMFA',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'SNOWFLAKE',
		description:
			'The Snowflake user **eli.sampars@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.',
		status: 'IN_PROGRESS',
		identity: snowflakeUserData['60000'],
	},
	{
		priority: 3,
		id: '4eacc511-7752-4111-a7eb-f9bf1797d501',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'PasswordRotation',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'SNOWFLAKE',
		description:
			"The user didn't change their password over **360** days.\n" +
			'Unrotated passwords in Snowflake can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.',
		status: 'IN_PROGRESS',
		identity: snowflakeUserData['60000'],
	},
	{
		priority: 3,
		id: '4eac1760-7752-4111-a7eb-f7bf17d7d501',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'SNOWFLAKE',
		description:
			"The user has not logged in since **2023-12-02 13:50**. Inactive users in Snowflake are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.",
		status: 'OPEN',
		identity: snowflakeUserData['60001'],
	},
	{
		priority: 3,
		id: '4eac1760-7752-4111-a7eb-f7bf17d7d501',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'IdentityUsingWeakAuthentication',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'SNOWFLAKE',
		description:
			'The service account **dev-app-testing** uses password authentication and does not have an RSA public key configured for authentication.\n' +
			'Password-based authentication alone is considered a weak authentication method as it can be more easily compromised compared to public key authentication. The absence of an RSA public key increases the risk of unauthorized access to your Snowflake account. It is recommended to configure RSA public key authentication to enhance the security of the service account.',
		status: 'OPEN',
		identity: snowflakeUserData['60001'],
	},
	{
		priority: 2,
		id: '4eac1760-1113-4111-a7eb-f7bf17d7d501',
		created_at: '2024-05-01T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'SNOWFLAKE',
		description:
			"The user has not logged in since **2024-02-03 11:51:41**. Inactive users in Snowflake are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.",
		status: 'OPEN',
		identity: snowflakeUserData['60003'],
	},
	{
		priority: 3,
		id: '4eac1720-1113-4111-a7eb-f7bf17d7d501',
		created_at: '2024-05-01T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'UnFederatedIdentity',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'SNOWFLAKE',
		description:
			'A non-federated Snowflake user (**john.doe@synergy.com**) detected in the Snowflake instance, which\n' +
			"relies on traditional username and password authentication, diverging from Snowflake's security best practices.\n" +
			'Using users that are not federated with an IDP increases the risk of credential leakage and\n' +
			'makes access management more challenging.\n' +
			'It is recommended to utilize federated IDP roles for more secure and manageable access control.',
		status: 'OPEN',
		identity: snowflakeUserData['60003'],
	},
	{
		priority: 3,
		id: '4df2c511-7752-4111-a7eb-f7bf17d7d545',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'MissingMFA',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'SNOWFLAKE',
		description:
			'The Snowflake user **john.doe@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.',
		status: 'IN_PROGRESS',
		identity: snowflakeUserData['60003'],
	},
	{
		priority: 2,
		id: '13456760-1113-4111-a7eb-f7bf17d7d501',
		created_at: '2024-05-01T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'SNOWFLAKE',
		description:
			"The user has not logged in since **2024-02-03 11:51:41**. Inactive users in Snowflake are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.",
		status: 'OPEN',
		identity: snowflakeUserData['60004'],
	},
	{
		priority: 3,
		id: '13456760-1113-5111-a7eb-f7bf17d7d501',
		created_at: '2024-05-01T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'UnFederatedIdentity',
		assignee: null,
		issue_source: 'SNOWFLAKE',
		description:
			'A non-federated Snowflake user (**jane.smith@synergy.com**) detected in the Snowflake instance, which\n' +
			"relies on traditional username and password authentication, diverging from Snowflake's security best practices.\n" +
			'Using users that are not federated with an IDP increases the risk of credential leakage and\n' +
			'makes access management more challenging.\n' +
			'It is recommended to utilize federated IDP roles for more secure and manageable access control.',
		status: 'OPEN',
		identity: snowflakeUserData['60004'],
	},
	{
		priority: 3,
		id: '4df11111-7752-4111-a7eb-f7bf17d7d545',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'MissingMFA',
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_source: 'SNOWFLAKE',
		description:
			'The Snowflake user **john.doe@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.',
		status: 'IN_PROGRESS',
		identity: snowflakeUserData['60004'],
	},
	{
		priority: 2,
		id: '13252721-9713-1232-a7eb-f7bf17d7d501',
		created_at: '2024-05-01T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'SNOWFLAKE',
		description:
			"The user has not logged in since **2024-02-03 11:51:41**. Inactive users in Snowflake are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.",
		status: 'OPEN',
		identity: snowflakeUserData['60005'],
	},
	{
		priority: 3,
		id: '13252721-1113-5432-a7eb-f7bf17d7d122',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'MissingMFA',
		assignee: null,
		issue_source: 'SNOWFLAKE',
		description:
			'The Snowflake user **michael.jones@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.',
		status: 'OPEN',
		identity: snowflakeUserData['60005'],
	},
	{
		priority: 2,
		id: '13252721-1113-5432-a7eb-f7bf17d7d501',
		created_at: '2024-05-01T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'SNOWFLAKE',
		description:
			"The user has not logged in since **2024-02-03 11:51:41**. Inactive users in Snowflake are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.",
		status: 'OPEN',
		identity: snowflakeUserData['60007'],
	},
	{
		priority: 3,
		id: '4eac1760-7752-4111-a7eb-f7bf17d7d222',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'MissingMFA',
		assignee: null,
		issue_source: 'SNOWFLAKE',
		description:
			'The Snowflake user **david.brown@synergy.com** does not have MFA enabled.\nA user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password.',
		status: 'OPEN',
		identity: snowflakeUserData['60007'],
	},
	{
		priority: 3,
		id: '3a4832ce-e00e-4f34-b54a-e9e1a88e1a81',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'MissingNetworkPolicy',
		assignee: null,
		issue_source: 'SNOWFLAKE',
		description:
			'A service account used within Snowflake environment does not have a defined network policy. This absence of a network policy can lead to unauthorized access and potential security vulnerabilities. Ensuring that all service accounts have appropriately configured network policies is critical for maintaining a secure and compliant Snowflake setup.',
		status: 'OPEN',
		identity: snowflakeUserData['60001'],
	},
	{
		priority: 2,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d222',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **Prometheus Service Discovery (PSD) connection** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80000'],
	},
	{
		priority: 3,
		id: '1f4fb2bb-5ff7-4a41-988f-b13a194db0c7',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **Terraform SA** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80001'],
	},
	{
		priority: 2,
		id: '65929da2-9c00-4c5c-9f5c-655cf5d19ee6',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **Migration_svc** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80002'],
	},
	{
		priority: 1,
		id: '64fc8726-8230-40ac-9676-65af6cd84fef',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **sheets-api-access** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80003'],
	},
	{
		priority: 1,
		id: '64fc8726-8230-40ac-9676-65af6cd84fe2',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **synergy-db-backup-002** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80005'],
	},
	{
		priority: 1,
		id: '64fc8726-8230-40ac-9676-65af6cd84fe3',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **synergy-db-backup-003** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80007'],
	},
	{
		priority: 4,
		id: '64fc8726-8230-40ac-9676-65af6cd84fe1',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **synergy-app-server-001** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80004'],
	},
	{
		priority: 3,
		id: '64fc8726-8231-40ac-9676-65af6cd84fe9',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **synergy-app-server-002** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80006'],
	},
	{
		priority: 2,
		id: 'c55154fd-8b10-4afc-a22e-1a65c095449b',
		last_seen: '2024-04-07T14:38:50.127371',
		identity: gcpServiceAccountsData['80006'],
		issue_name: 'MultipleAccessKeys',
		issue_source: 'GCP',
		assignee: null,
		description:
			'The service account synergy-app-server-002 has multiple active access keys, which poses security risks and complicates\n' +
			'management. Having more than one active key increases the chance of mishandling, loss, or unauthorized access if a key\n' +
			'is compromised. It also makes tracking usage harder, leading to potential confusion and security gaps. Best practices\n' +
			'recommend limiting to a single active key per service account and regularly rotating it to enhance security and ensure\n' +
			'accountability.',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'c55154fd-8b10-4afc-a22e-1a65c095499a',
		last_seen: '2024-04-07T14:38:50.127371',
		identity: gcpServiceAccountsData['80004'],
		issue_name: 'AccessKeyNotRotated',
		issue_source: 'GCP',
		assignee: null,
		description:
			'GCP service account key rotation is the practice of regularly updating the private keys associated with service\n' +
			'accounts in Google Cloud Platform (GCP). Neglecting to rotate these keys increases security risks,\n' +
			'as older, unchanged keys are more vulnerable to compromise. Regular key rotation reduces the likelihood of\n' +
			'unauthorized access to GCP services, helping to protect against data breaches and security incidents.\n' +
			'The following service account keys were not rotated in the last **90** days:\n' +
			'- projects/synergy-prod-app/serviceAccounts/app-server@synergy-prod-app.iam.gserviceaccount.com/keys/15305e77c9761d7b4a076b57234cbb7987535801\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'c55154fd-8b10-4afc-a22e-1a65c095499b',
		last_seen: '2024-04-07T14:38:50.127371',
		identity: gcpServiceAccountsData['80004'],
		issue_name: 'MultipleAccessKeys',
		issue_source: 'GCP',
		assignee: null,
		description:
			'The service account synergy-app-server-001 has multiple active access keys, which poses security risks and complicates\n' +
			'management. Having more than one active key increases the chance of mishandling, loss, or unauthorized access if a key\n' +
			'is compromised. It also makes tracking usage harder, leading to potential confusion and security gaps. Best practices\n' +
			'recommend limiting to a single active key per service account and regularly rotating it to enhance security and ensure\n' +
			'accountability.',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'c77774fd-8b10-4afc-a66e-1a65c095499b',
		last_seen: '2024-01-18T07:03:32.866131Z',
		identity: gcpServiceAccountsData['80004'],
		assignee: null,
		issue_name: 'GCPServiceAccountUsedFromEndpoint',
		issue_source: 'GCP',
		description:
			'\nThe GCP Service Account **synergy-app-server-001** has been used from employee endpoint.\n' +
			'This is a potential security risk, as the access credentials are saved on the endpoint and if the endpoint is compromised,\n' +
			'the credentials could be used to access the GCP account.\n' +
			'last event time: 2024-01-18T07:03:32.86Z(UTC).\n',
		status: 'OPEN',
	},
	{
		priority: 2,
		id: 'd7e3f9a1-6d3a-4e4d-9f7d-1c2b3a4d5e8f',
		last_seen: '2024-01-18T07:03:32.866131Z',
		identity: gcpServiceAccountsData['80006'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'GCPServiceAccountUsedFromEndpoint',
		issue_source: 'GCP',
		description:
			'\nThe GCP Service Account **synergy-app-server-002** has been used from employee endpoint.\n' +
			'This is a potential security risk, as the access credentials are saved on the endpoint and if the endpoint is compromised,\n' +
			'the credentials could be used to access the GCP account.\n' +
			'last event time: 2024-01-18T07:03:32.86Z(UTC).\n',
		status: 'OPEN',
	},
	{
		priority: 3,
		id: 'd7e3f9a1-6d3a-4e4d-9f7d-1c2b3a4d8e8f',
		last_seen: '2024-04-21T11:46:25.262929Z',
		created_at: '2024-04-17T16:41:03.286117Z',
		identity: gcpServiceAccountsData['80004'],
		issue_name: 'ServiceAccountKeyReferencedInPod',
		issue_source: 'GCP',
		description:
			'\nGCP key **66d2e6de073940bd53b20e3a44622ac6e7f3fdaa** belonging to the Service Account **synergy-app-server-001** is referenced in an EKS cluster workload via environment variables.\n' +
			' \nGCP best practice it to rely on the recommended Roles for Service Accounts or GCP Pod Identity.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '4eac1760-7111-4131-a5eb-f1bf17a7d213',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **Prometheus Service Discovery (PSD) connection** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80008'],
	},
	{
		priority: 2,
		id: '4eac1760-7112-4131-a5eb-f1bf17a7d211',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **Prometheus Service Discovery (PSD) connection** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80009'],
	},
	{
		priority: 1,
		id: '4eac1760-7113-4131-a5eb-f1bf17a7d212',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **backup-service** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80010'],
	},
	{
		priority: 1,
		id: '4eac1760-7114-4131-a5eb-f1bf17a7d240',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **api-gateway-service** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80011'],
	},
	{
		priority: 1,
		id: '4eac1760-7114-4131-a5eb-f1bf17a7d241',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **monitoring-service** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80012'],
	},
	{
		priority: 1,
		id: '4eac1760-7114-4131-a5eb-f1bf17a7d242',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **ci-cd-service** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80013'],
	},
	{
		priority: 2,
		id: '4eac1760-7114-4131-a5eb-f1bf17a7d243',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GCP',
		description:
			'The service account **analytics-service** is inactive. \nInactivity in service accounts, defined by prolonged non-use, represents a significant security vulnerability. \nThese dormant accounts might possess obsolete permissions, frequently escaping notice during security evaluations, \nthus becoming prime targets for illicit access. To fortify GCP security, it is imperative to conduct regular audits and \ndeactivate these inactive accounts, ensuring access is restricted solely to active and essential service accounts.',
		status: 'OPEN',
		identity: gcpServiceAccountsData['80014'],
	},
	{
		priority: 3,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d200',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GITHUB',
		description:
			'The GitHub user **gabrielabeard** has not contributed to the codebase for **over 90 days**. This inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.',
		status: 'OPEN',
		identity: githubUserData['40000'],
	},
	{
		priority: 2,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d201',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GITHUB',
		description:
			'The GitHub user **David-Brown** has not contributed to the codebase for **over 90 days**. This inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.',
		status: 'OPEN',
		identity: githubUserData['40001'],
	},
	{
		priority: 2,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d202',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GITHUB',
		description:
			'The GitHub user **eliSampars** has not contributed to the codebase for **over 90 days**. This inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.',
		status: 'OPEN',
		identity: githubUserData['40002'],
	},
	{
		priority: 2,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d203',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GITHUB',
		description:
			'The GitHub user **David-Brown** has not contributed to the codebase for **over 90 days**. This inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.',
		status: 'OPEN',
		identity: githubUserData['40003'],
	},
	{
		priority: 2,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d204',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GITHUB',
		description:
			'The GitHub user **elizabeth_coleman** has not contributed to the codebase for **over 90 days**. This inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.',
		status: 'OPEN',
		identity: githubUserData['40004'],
	},
	{
		priority: 2,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d205',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GITHUB',
		description:
			'The GitHub user **michaeljones** has not contributed to the codebase for **over 90 days**. This inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.',
		status: 'OPEN',
		identity: githubUserData['40005'],
	},
	{
		priority: 2,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d206',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GITHUB',
		description:
			'The GitHub user **jane-smith** has not contributed to the codebase for **over 90 days**. This inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.',
		status: 'OPEN',
		identity: githubUserData['40006'],
	},
	{
		priority: 2,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d207',
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'GITHUB',
		description:
			'The GitHub user **shelby** has not contributed to the codebase for **over 90 days**. This inactivity could lead to unnecessary costs and security risks. We advise regular review and management of such accounts.',
		status: 'OPEN',
		identity: githubUserData['40007'],
	},
	{
		priority: 3,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d300',
		last_seen: '2024-04-08T07:03:32.866131Z',
		identity: githubUserData['40008'],
		assignee: null,
		issue_name: 'PersonalAccessKeyUsedFromEndpoint',
		issue_source: 'GITHUB',
		description:
			"\nThe user **wvargas** has personal access tokens that are able to access the company's GitHub environment, and are being used from unknown endpoints. \n",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d301',
		last_seen: '2024-04-08T07:03:32.866131Z',
		identity: githubUserData['40005'],
		assignee: null,
		issue_name: 'InactivePersonalAccessTokens',
		issue_source: 'GITHUB',
		description:
			"\nThe user **michaeljones** has personal access tokens that are able to access the company's GitHub environment. \n",
		status: 'OPEN',
	},
	{
		priority: 3,
		id: '4eac1760-7712-4131-a5eb-f1bf17a7d302',
		last_seen: '2024-04-08T07:03:32.866131Z',
		identity: githubUserData['40009'],
		assignee: null,
		issue_name: 'InactiveOutsideCollaborators',
		issue_source: 'GITHUB',
		description:
			"\nThe user **AlexJohnson** has access to the company's GitHub environment as an outside collaborator and has not contributed to the codebase for **over 90 days**. This inactivity of an outside collaborator could lead to unnecessary security risks. We advise regular review and management of such accounts.\n",
		status: 'OPEN',
	},
	{
		priority: 4,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: IssueName.InactiveIdentity,
		assignee: null,
		issue_source: 'ENTRA_ID',
		description: `The Entra ID service principal associated with **prod-data-access** has been inactive for over **240** days.
Prolonged inactivity can result in unnecessary costs and potential security vulnerabilities. We recommend regularly reviewing and managing inactive service principals to maintain optimal security and cost efficiency.`,
		status: 'OPEN',
		identity: entraIdServicePrincipalsData['90000'],
	},
	{
		priority: 3,
		id: 'ebe7e1aa-ac6b-475c-be41-40ab0c3a2714',
		last_seen: '2024-04-21T11:46:25.262929Z',
		created_at: '2024-04-17T16:41:03.286117Z',
		identity: entraIdServicePrincipalsData['90001'],
		issue_name: IssueName.ClientSecretReferencedInPod,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'\nKey **prodKey1 - 13411ade-4d71-b654-a5ba-9912fabfa8d1** belonging to the service principal **production-aks-principal** is referenced in an AKS cluster workload via environment variables.\n \nAzure best practice is to avoid keeping credentials in environment variables.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '6f311446-df53-4af2-ba7c-b707a462294b',
		last_seen: '2024-04-21T11:46:24.729699Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: entraIdServicePrincipalsData['90001'],
		issue_name: IssueName.InactiveIdentity,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'The Entra ID service principal associated with **production-aks-user** has been inactive for over **137** days.\n' +
			'Prolonged inactivity can result in unnecessary costs and potential security vulnerabilities. We recommend regularly reviewing and managing inactive service principals to maintain optimal security and cost efficiency.',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: '3b71e5a3-ea38-4032-9088-9b8d91d3c07e',
		last_seen: '2024-04-21T11:46:24.701238Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: entraIdServicePrincipalsData['90001'],
		issue_name: IssueName.InactiveCredential,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'The Entra ID service principal associated with **production-aks-user** is utilizing credentials that have been inactive for at least **90** days.\n' +
			'Inactive credentials should be deleted to prevent unauthorized access in case of compromise, and minimize the attack surface.\n' +
			'\n' +
			'The service principal is currently utilizing the following inactive credentials:\n' +
			' - 13411ade-4d71-b654-a5ba-9912fabfa8d1\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 4,
		id: 'a49ec0c3-c46d-4318-ba01-5e30c7221046',
		last_seen: '2024-04-25T06:52:05.507382Z',
		created_at: '2024-04-24T12:46:07.675066Z',
		identity: entraIdServicePrincipalsData['90002'],
		issue_name: IssueName.AzureCrossAccountAccess,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'Service principal in lower sensitivity tenant **sandboxsynergy** (e2b45ca1-aaeb-4811-88ae-812481b38d79) has a role assignment in same-or-higher sensitivity tenant **Synergy** (a87aac58-4dd7-4b28-a0d4-97acacd5285f) in a way that can lead to privilege escalation if the service principal in tenant sandboxsynergy is compromised, possibly allowing an attacker to access and modify resources in tenant Synergy.',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '08143bb8-eeff-4966-9ad7-f1641d6535ac',
		last_seen: '2024-04-21T11:46:24.729699Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: entraIdServicePrincipalsData['10000'],
		issue_name: IssueName.InactiveIdentity,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'The Entra ID service principal associated with **skyhawk-security** has been inactive for over **90** days.\n' +
			'Prolonged inactivity can result in unnecessary costs and potential security vulnerabilities. We recommend regularly reviewing and managing inactive service principals to maintain optimal security and cost efficiency.',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: 'a620c3e5-8766-4546-9208-7a950b3d4024',
		last_seen: '2024-04-21T11:46:24.729699Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: entraIdServicePrincipalsData['10001'],
		issue_name: IssueName.InactiveIdentity,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'The Entra ID service principal associated with **sailpoint-azure** has been inactive for over **90** days.\n' +
			'Prolonged inactivity can result in unnecessary costs and potential security vulnerabilities. We recommend regularly reviewing and managing inactive service principals to maintain optimal security and cost efficiency.',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '9855c466-e57f-45ea-bb23-891e75afcbef',
		last_seen: '2024-04-21T11:46:24.729699Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: entraIdServicePrincipalsData['10002'],
		issue_name: IssueName.InactiveIdentity,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'The Entra ID service principal associated with **zapier-azure** has been inactive for over **90** days.\n' +
			'Prolonged inactivity can result in unnecessary costs and potential security vulnerabilities. We recommend regularly reviewing and managing inactive service principals to maintain optimal security and cost efficiency.',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '9ce7a3a1-eb0d-4618-abbb-eacbb61847d2',
		last_seen: '2024-04-21T11:46:24.729699Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: entraIdServicePrincipalsData['10003'],
		issue_name: IssueName.InactiveIdentity,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'The Entra ID service principal associated with **zendesk-azure** has been inactive for over **90** days.\n' +
			'Prolonged inactivity can result in unnecessary costs and potential security vulnerabilities. We recommend regularly reviewing and managing inactive service principals to maintain optimal security and cost efficiency.',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: '475119fa-b0c3-47bb-bf9d-c4fb958b1721',
		last_seen: '2024-04-21T11:46:24.729699Z',
		created_at: '2024-03-20T07:57:19.183680Z',
		identity: entraIdServicePrincipalsData['10004'],
		issue_name: IssueName.InactiveIdentity,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'The Entra ID service principal associated with **splunk-sp** has been inactive for over **90** days.\n' +
			'Prolonged inactivity can result in unnecessary costs and potential security vulnerabilities. We recommend regularly reviewing and managing inactive service principals to maintain optimal security and cost efficiency.',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: '2024-01-18T07:03:32.866131Z',
		identity: entraIdServicePrincipalsData['90003'],
		issue_name: IssueName.ClientSecretUsedFromEndpoint,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'\nClient secret with ID **Password uploaded on Tue Mar 12 2023 - 4a075312-b654-4d71-a2be-86acf8bfa8a4** associated to the service principal **ff8595c0-1cd2-45f6-8b0a-8c2e1e2f2805** has been used from employee endpoint.\nThis is a potential security risk, as the client secret is saved on the endpoint and if the endpoint is compromised,\nthe client secret can be used to access the AZURE account.\nlast event time: 2024-04-08T12:26:19Z (UTC). Users that used the access key:dan@synergy.com, alice@synergy.com, bob@synergy.com \n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: IssueName.CloudtrailResourceCreatedByOffBoardedEmployee,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'An Azure Client Secret **Password uploaded on Tue Mar 12 2023 - 4a075312-b654-4d71-a2be-86acf8bfa8a4**, associated to the service principal **ff8595c0-1cd2-45f6-8b0a-8c2e1e2f2805**, was created by a former employee: **lucas.grant@synergy.com**. The client secret has not been rotated since the employee has left. This is a security risk, as the former employee may still have access to the account and its resources.',
		status: 'IN_PROGRESS',
		identity: entraIdServicePrincipalsData['90003'],
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-01-18T07:03:30.531547Z',
		identity: entraIdServicePrincipalsData['90003'],
		issue_name: IssueName.AccessKeyNotRotated,
		issue_source: ResourceType.ENTRA_ID,
		description:
			'The Entra ID service principal associated with **lucasgrant** is utilizing client secrets that have not been rotated for an extended period.\n' +
			'Failure to regularly rotate client secrets can lead to potential security vulnerabilities and increase the risk of unauthorized access. We strongly recommend implementing a regular rotation policy to ensure the ongoing security of your resources.\n' +
			'\n' +
			'The service principal is currently utilizing the following unrotated client secrets:\n' +
			' - 4a075312-b654-4d71-a2be-86acf8bfa8a4',
		status: 'OPEN',
	},
	{
		priority: 4,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: IssueName.InactiveIdentity,
		issue_source: 'ENTRA_ID',
		description:
			'The Entra ID service principal associated with **lucasgrant** has been inactive for over **150** days.\n' +
			'Prolonged inactivity can result in unnecessary costs and potential security vulnerabilities. We recommend regularly reviewing and managing inactive service principals to maintain optimal security and cost efficiency.',
		status: 'OPEN',
		identity: entraIdServicePrincipalsData['90003'],
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: '2024-04-07T07:58:07.273036Z',
		identity: entraIdServicePrincipalsData['90004'],
		issue_name: IssueName.SharedAccount,
		issue_source: 'AWS',
		description:
			'The user assigned managed identity **synappid@synergy.onmicrosoft.com** seems to be a shared identity. It contains high privileges, including AD built-in role User Administrator and Azure built-in role Contributor, and is used by many Virtual Machines and Functions which perform different tasks. Furthermore, the managed identity is over privileged, due to the fact that only the following services are used via the role: **Storage Blob Data**, **Virtual Machines** and **Container Storage**.',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-04-08T07:03:32.866131Z',
		identity: githubUserData['40005'],
		assignee: '0297ecfa-701f-471e-bec3-19a28ff8fdd3',
		issue_name: 'PartiallyOffBoardedUsers',
		issue_source: 'GITHUB',
		description:
			'\nThe user **synergy/michaeljones** is partially off-boarded. A partially off-boarded employee refers to an individual who has begun, but not completed, the exit process from an organization. This can pose security and operational risks due to potential access to sensitive information or systems.\n',
		status: 'OPEN',
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: '2024-04-11T12:23:48.316278Z',
		identity: azureIdentitiesData['90005'],
		issue_name: IssueName.UnFederatedIdentity,
		issue_source: ResourceType.AZURE_RDS_POSTGRES,
		description:
			'An unfederated PostgreSQL role (**analytics_role**) detected in the Azure Database for PostgreSQL instance, which does not leverage Entra ID integration for authentication. This approach, relying on traditional username and password authentication, diverges from Azure security best practices. Using roles that are not associated with Entra ID increases the risk of credential leakage and makes access management more challenging. It is recommended to utilize Entra ID authentication for more secure and manageable access control.',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: awsIamUserData['175'],
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **lloyd-olson** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: awsIamUserData['176'],
		issue_name: 'MissingMFA',
		issue_source: 'AWS',
		description:
			'\nThe IAM User **dean-davis** does not have MFA enabled.\nAn AWS IAM User without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as it lacks an extra verification layer beyond the username and password. \nThis increases the risk of security breaches within AWS.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['300'],
		issue_name: 'MissingMFA',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user **jason.nickelson@synergy.com** does not have MFA enabled. A user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['301'],
		issue_name: 'MissingMFA',
		issue_source: 'ENTRA_ID',
		description:
			'\nThe Entra ID user **dante.walker@synergy.com** does not have MFA enabled. A user without MFA (Multi-Factor Authentication) is more vulnerable to unauthorized access, as MFA adds a crucial layer of security beyond just a password\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: '5f64084d-15e4-4d76-be76-159a38cfdc13',
		issue_source: 'DATABRICKS',
		description:
			"The user has not logged in since **2024-06-02 10:51:41**. Inactive users in Databricks are accounts that haven't shown activity for a prolonged period, posing security risks and potentially consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended.",
		status: 'IN_PROGRESS',
		identity: databricksUserData['70000'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactivePersonalAccessTokens',
		assignee: 'null',
		issue_source: 'DATABRICKS',
		description:
			'The identity has an unused Personal Access Token. Unnecessary credentials expand the attack surface and may lead to security compromises.',
		status: 'IN_PROGRESS',
		identity: databricksUserData['70000'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'The service principal is not active. Unnecessary service principals expose data and resources for potential attacks. This potential exposure can be avoided by deletion of the service principal or limiting the access to resources.',
		status: 'OPEN',
		identity: databricksServicePrincipalData['80000'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'ServiceAccountWithoutNetworkPolicy',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'A service account used within Databricks environment does not have a defined network policy. This absence of a network policy can lead to unauthorized access and potential security vulnerabilities. Ensuring that all service accounts have appropriately configured network policies is critical for maintaining a secure and compliant Databricks setup.',
		status: 'OPEN',
		identity: databricksServicePrincipalData['80000'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'MultipleAccessTokens',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'There is an identity with admin privileges that has multiple personal access tokens. If any of these tokens are compromised, it could lead to serious damage. It is advisable to exercise caution when using an identity with such high privileges and to avoid generating multiple high-privilege tokens.',
		status: 'OPEN',
		identity: databricksUserData['70001'],
	},
	{
		priority: 4,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-10T11:06:25.589697Z',
		issue_name: 'AccessTokenEmbeddedInCode',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'A personal access token has been found embedded in a GitHub repository. Exposing personal access tokens in source code repositories can lead to unauthorized access and potential security breaches. It is crucial to remove the token from the repository as soon as possible. It is also recommended to rotate the key. Consider using a secret manager to avoid potential exposure of secrets.',
		status: 'OPEN',
		identity: databricksUserData['70001'],
	},
	{
		priority: 4,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-10T11:06:25.589697Z',
		issue_name: 'IDPBypass',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			"The user has personal access tokens that are able to access the company's Databricks environment without IDP",
		status: 'OPEN',
		identity: databricksUserData['70001'],
	},
	{
		priority: 1,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-10T11:06:25.589697Z',
		issue_name: 'PermanentToken',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'A token with no lifetime limit can pose significant security risks. Tokens should have a defined expiration date to minimize the risk of misuse if they are ever compromised. Permanent tokens can lead to unauthorized access over an extended period.',
		status: 'OPEN',
		identity: databricksUserData['70001'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-10T11:06:25.589697Z',
		issue_name: 'ServicePrincipalCreatedByOffboardedEmployee',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'The service principal created by an off-boarded employee can pose a security risk. This service principal may still have access to sensitive data and resources, leading to potential unauthorized access.',
		status: 'OPEN',
		identity: databricksServicePrincipalData['80000'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-10T11:06:25.589697Z',
		issue_name: 'ServicePrincipalUsedFromEmployeesEndpoint',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			"Using a service principal from an employee's endpoint increases the risk of credential exposure by spreading credentials to an unmanaged location. This can lead to unauthorized access if the employee's device is compromised.",
		status: 'OPEN',
		identity: databricksServicePrincipalData['80000'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'ServiceAccountWithoutNetworkPolicy',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'A service account used within Databricks environment does not have a defined network policy. This absence of a network policy can lead to unauthorized access and potential security vulnerabilities. Ensuring that all service accounts have appropriately configured network policies is critical for maintaining a secure and compliant Databricks setup.',
		status: 'OPEN',
		identity: databricksServicePrincipalData['80001'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'ServiceAccountWithoutNetworkPolicy',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'A service account used within Databricks environment does not have a defined network policy. This absence of a network policy can lead to unauthorized access and potential security vulnerabilities. Ensuring that all service accounts have appropriately configured network policies is critical for maintaining a secure and compliant Databricks setup.',
		status: 'OPEN',
		identity: databricksServicePrincipalData['80002'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-05-06T11:06:25.589697Z',
		last_seen: '2024-05-06T11:06:25.589697Z',
		issue_name: 'ServiceAccountWithoutNetworkPolicy',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'A service account used within Databricks environment does not have a defined network policy. This absence of a network policy can lead to unauthorized access and potential security vulnerabilities. Ensuring that all service accounts have appropriately configured network policies is critical for maintaining a secure and compliant Databricks setup.',
		status: 'OPEN',
		identity: databricksServicePrincipalData['80003'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-11T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'The service principal is not active. Unnecessary service principals expose data and resources for potential attacks. This potential exposure can be avoided by deletion of the service principal or limiting the access to resources.',
		status: 'OPEN',
		identity: databricksUserData['70002'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-11T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'The service principal is not active. Unnecessary service principals expose data and resources for potential attacks. This potential exposure can be avoided by deletion of the service principal or limiting the access to resources.',
		status: 'OPEN',
		identity: databricksUserData['70003'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-11T11:06:25.589697Z',
		issue_name: 'InactiveIdentity',
		assignee: null,
		issue_source: 'DATABRICKS',
		description:
			'The service principal is not active. Unnecessary service principals expose data and resources for potential attacks. This potential exposure can be avoided by deletion of the service principal or limiting the access to resources.',
		status: 'OPEN',
		identity: databricksUserData['70004'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-08-29T11:06:25.589697Z',
		last_seen: '2024-08-29T11:06:25.589697Z',
		issue_name: 'AccessKeyNotRotated',
		assignee: null,
		issue_source: 'AWS',
		description: `
AWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.
Neglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise.
Regular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.

The following key was not rotated in the last **90** days:
- Access key **AKIAZTLFMVR9X3PQRSTU** was not rotated for **545+** days.
`,
		status: 'OPEN',
		identity: identities['177'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-08-29T11:06:25.589697Z',
		last_seen: '2024-08-29T11:06:25.589697Z',
		issue_name: 'MultipleAccessKeys',
		assignee: null,
		issue_source: 'AWS',
		description:
			'\nThe IAM User **Splunk_Access** has multiple active Access Keys.\nHaving Multiple Active AWS IAM User Access Keys refers to the practice where a single IAM (Identity and Access Management) user has more than one active access key at the same time. This scenario can pose security risks and complicate key management. Multiple active keys per user increase the chances of keys being mishandled, lost, or forgotten, leading to a higher risk of unauthorized access if a key is compromised. It also makes tracking which key is used for what purpose more difficult, potentially leading to confusion and security gaps. Best practices recommend limiting users to a single active access key and rotating it regularly. This approach simplifies management, enhances security by reducing the attack surface, and ensures accountability and traceability of actions performed with each key.\n',

		status: 'OPEN',
		identity: identities['177'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-08-29T11:06:25.589697Z',
		last_seen: '2024-08-29T11:06:25.589697Z',
		issue_name: 'InactiveAccessKey',
		assignee: null,
		issue_source: 'AWS',
		description:
			'Unused access keys refer to the access key IDs that are associated with an AWS account but have not been used for a certain period.\nIf an access key has not been used for an extended period, it might be an indication that it is no longer needed.\nKeeping unused keys can pose a security risk, as they could potentially be exploited if they fall into the wrong hands.\n\n- Access key **AKIAZTLFMVR7K4LMNOPQ** has never been used since its creation on **2022-02-21**.\n',
		status: 'OPEN',
		identity: identities['177'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-10T11:06:25.589697Z',
		issue_name: 'UserUsedFromAttackOrPentestingFramework',
		assignee: null,
		issue_source: 'AWS',
		description: `
The AWS IAM user **arn:aws:iam::818837914182:user/Splunk_Access** was detected being used with the known attack / pen-testing framework: **ScoutSuite**.

This behavior occurred during a 1 hour period:
  • Start: **2024-07-10 03:54:38Z**
  • End:   **2024-07-10 04:16:05Z**

Origin IP: **64.155.128.247**

This could indicate possible compromise of the user's access key: **AKIAZTLFMVR9X3PQRSTU**.

Please investigate this activity immediately and rotate the access key if unauthorized use is suspected.
`,
		status: 'OPEN',
		identity: identities['177'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-08-29T11:06:25.589697Z',
		last_seen: '2024-08-29T11:06:25.589697Z',
		issue_name: 'AccessKeyNotRotated',
		assignee: null,
		issue_source: 'AWS',
		description: `
AWS Access Key Rotation is the practice of regularly updating access key IDs and secret access keys for AWS accounts.
Neglecting to rotate these keys increases security risks, as older, unchanged keys are more susceptible to compromise.
Regular key rotation reduces the likelihood of unauthorized access to AWS services, helping to protect against data breaches and security incidents.

The following key was not rotated in the last **90** days:
- Access key **AKIAZTLFMVR9X3PQRSTF** was not rotated for **545+** days.
`,
		status: 'OPEN',
		identity: identities['178'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-08-29T11:06:25.589697Z',
		last_seen: '2024-08-29T11:06:25.589697Z',
		issue_name: 'MultipleAccessKeys',
		assignee: null,
		issue_source: 'AWS',
		description:
			'\nThe IAM User **tableau-bkup-rstr** has multiple active Access Keys.\nHaving Multiple Active AWS IAM User Access Keys refers to the practice where a single IAM (Identity and Access Management) user has more than one active access key at the same time. This scenario can pose security risks and complicate key management. Multiple active keys per user increase the chances of keys being mishandled, lost, or forgotten, leading to a higher risk of unauthorized access if a key is compromised. It also makes tracking which key is used for what purpose more difficult, potentially leading to confusion and security gaps. Best practices recommend limiting users to a single active access key and rotating it regularly. This approach simplifies management, enhances security by reducing the attack surface, and ensures accountability and traceability of actions performed with each key.\n',

		status: 'OPEN',
		identity: identities['178'],
	},
	{
		priority: 2,
		id: generateUUID(),
		created_at: '2024-08-29T11:06:25.589697Z',
		last_seen: '2024-08-29T11:06:25.589697Z',
		issue_name: 'InactiveAccessKey',
		assignee: null,
		issue_source: 'AWS',
		description:
			'Unused access keys refer to the access key IDs that are associated with an AWS account but have not been used for a certain period.\nIf an access key has not been used for an extended period, it might be an indication that it is no longer needed.\nKeeping unused keys can pose a security risk, as they could potentially be exploited if they fall into the wrong hands.\n\n- Access key **AKIAZTLFMVR7K4LMNOPF** has never been used since its creation on **2022-02-21**.\n',
		status: 'OPEN',
		identity: identities['178'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-07-10T11:06:25.589697Z',
		last_seen: '2024-07-10T11:06:25.589697Z',
		issue_name: 'UserUsedFromAttackOrPentestingFramework',
		assignee: null,
		issue_source: 'AWS',
		description: `
The AWS IAM user **arn:aws:iam::818837914182:user/tableau-bkup-rstr** was detected being used with the known attack / pen-testing framework: **cloudfox**.

This behavior occurred during a 1 hour period:
  • Start: **2024-07-10 03:54:38Z**
  • End:   **2024-07-10 04:16:05Z**

Origin IP: **64.155.128.247**

This could indicate possible compromise of the user's access key: **AKIAZTLFMVR9X3PQRSTF**.

Please investigate this activity immediately and rotate the access key if unauthorized use is suspected.
`,
		status: 'OPEN',
		identity: identities['178'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-08-24T11:06:25.589697Z',
		last_seen: '2024-08-24T11:06:25.589697Z',
		issue_name: 'HighRiskSignInForCloudAdmin',
		assignee: null,
		issue_source: 'OKTA',
		description: `
A **high-risk login** was reported by Okta into **elizabeth.coleman@synergy.com**'s account
on 2024-08-24 07:09:06Z from IP 109.76.160.39, with the following risk factors:

- **New Device**: Mac OS X
- **New City**: Lewisham (United Kingdom)

If the login was done by an attacker, it may pose **high organizational risk** as this
user has **admin permissions** (via role prodSuperUser) to a **critical-priority AWS
account** (871744574088 - production).
`,
		status: 'OPEN',
		identity: identities['97'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-08-24T11:06:25.589697Z',
		last_seen: '2024-08-24T11:06:25.589697Z',
		issue_name: 'HighRiskSignInForCloudAdmin',
		assignee: null,
		issue_source: 'OKTA',
		description: `
A **high-risk login** was reported by Okta into **john@synergy.security**'s account
on 2024-08-24 07:09:06Z from IP 109.76.160.39, with the following risk factors:

- **New Device**: Mac OS X
- **New City**: Lewisham (United Kingdom)

If the login was done by an attacker, it may pose **high organizational risk** as this
user has **admin permissions** (via role prodSuperUser) to a **critical-priority AWS
account** (871744574088 - production).
`,
		status: 'OPEN',
		identity: identities['75'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-08-24T11:06:25.589697Z',
		last_seen: '2024-08-24T11:06:25.589697Z',
		issue_name: 'HighRiskSignInForCloudAdmin',
		assignee: null,
		issue_source: 'OKTA',
		description: `
A **high-risk login** was reported by Okta into **jennifer@synergy.security**'s account
on 2024-08-24 07:09:06Z from IP 109.76.160.39, with the following risk factors:

- **New Device**: Mac OS X
- **New City**: Lewisham (United Kingdom)

If the login was done by an attacker, it may pose **high organizational risk** as this
user has **admin permissions** (via role prodSuperUser) to a **critical-priority AWS
account** (871744574088 - production).
`,
		status: 'OPEN',
		identity: identities['76'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-08-24T11:06:25.589697Z',
		last_seen: '2024-08-24T11:06:25.589697Z',
		issue_name: 'HighRiskSignInForCloudAdmin',
		assignee: null,
		issue_source: 'OKTA',
		description: `
A **high-risk login** was reported by Okta into **linda@synergy.security**'s account
on 2024-08-24 07:09:06Z from IP 109.76.160.39, with the following risk factors:

- **New Device**: Mac OS X
- **New City**: Lewisham (United Kingdom)

If the login was done by an attacker, it may pose **high organizational risk** as this
user has **admin permissions** (via role prodSuperUser) to a **critical-priority AWS
account** (871744574088 - production).
`,
		status: 'OPEN',
		identity: identities['77'],
	},
	{
		priority: 3,
		id: generateUUID(),
		created_at: '2024-08-24T11:06:25.589697Z',
		last_seen: '2024-08-24T11:06:25.589697Z',
		issue_name: 'HighRiskSignInForCloudAdmin',
		assignee: null,
		issue_source: 'OKTA',
		description: `
A **high-risk login** was reported by Okta into **robert@synergy.security**'s account
on 2024-08-24 07:09:06Z from IP 109.76.160.39, with the following risk factors:

- **New Device**: Mac OS X
- **New City**: Lewisham (United Kingdom)

If the login was done by an attacker, it may pose **high organizational risk** as this
user has **admin permissions** (via role prodSuperUser) to a **critical-priority AWS
account** (871744574088 - production).
`,
		status: 'OPEN',
		identity: identities['78'],
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: '2024-04-20T05:08:24.000000Z',
		created_at: '2024-03-16T10:07:45.000000Z',
		identity: identities['302'],
		issue_name: 'PasswordRotation',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_source: 'ENTRA_ID',
		description:
			"\nThe user didn't change their password for **391** days.\nUnrotated passwords in Entra id can lead to security vulnerabilities, as unchanged passwords over time are more prone to being compromised. Regular password updates are recommended for enhanced security.\n",
		status: 'OPEN',
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: '2024-08-02T05:08:24.000000Z',
		created_at: '2024-08-02T10:07:45.000000Z',
		identity: identities['302'],
		issue_name: 'HighRiskSignInForCloudAdmin',
		assignee: 'c64fdf58-2d67-4fd8-9012-733e533e8342',
		issue_source: 'ENTRA_ID',
		description: `
**A high-risk login was reported by Entra ID** into **alex.sigdag@synergy.com**'s account on **2024-08-02 12:38:11Z** from IP **192.240.106.189**, with the following risk factors:

• **Anonymous IP address** - indicates sign-ins from an anonymous IP address (for example, Tor browser or anonymous VPN)

If the login was done by an attacker, it may pose **high organizational risk** as this user has **admin permissions** (via role **prodSuperUser**) to a **critical-priority AWS account** (**871744574088 - production**).
`,
		status: 'OPEN',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_1']],
		issue_name: 'InactiveIdentity',
		issue_source: 'SALESFORCE',
		description: `The user has not logged in since 2024-07-22 13:01:08
Inactive users in Salesforce are accounts that haven't shown activity for an extended period, potentially posing security risks and consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended to maintain system security and optimize license usage.`,
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_2']],
		issue_name: 'InactiveIdentity',
		issue_source: 'SALESFORCE',
		description: `The user has not logged in since 2024-03-22 11:09:08
Inactive users in Salesforce are accounts that haven't shown activity for an extended period, potentially posing security risks and consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended to maintain system security and optimize license usage.`,
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_2']],
		issue_name: 'UnFederatedIdentity',
		issue_source: 'SALESFORCE',
		description: `A non-federated Salesforce user (alex.jones@synergy.com) detected in the Salesforce instance, which relies on traditional username and password authentication, diverging from Salesforce's security best practices. Using accounts that are not federated with an Identity Provider (IDP) increases the risk of credential leakage and complicates access management. It is recommended to utilize federated IDP roles for more secure and manageable access control.`,
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_3']],
		issue_name: 'UnFederatedIdentity',
		issue_source: 'SALESFORCE',
		description: `A non-federated Salesforce user (sam.green@synergy.com) detected in the Salesforce instance, which relies on traditional username and password authentication, diverging from Salesforce's security best practices. Using accounts that are not federated with an Identity Provider (IDP) increases the risk of credential leakage and complicates access management. It is recommended to utilize federated IDP roles for more secure and manageable access control.`,
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_5']],
		issue_name: 'UnFederatedIdentity',
		issue_source: 'SALESFORCE',
		description: `A non-federated Salesforce user (ben.tar@synergy.com) detected in the Salesforce instance, which relies on traditional username and password authentication, diverging from Salesforce's security best practices. Using accounts that are not federated with an Identity Provider (IDP) increases the risk of credential leakage and complicates access management. It is recommended to utilize federated IDP roles for more secure and manageable access control.`,
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_5']],
		issue_name: 'InactiveIdentity',
		issue_source: 'SALESFORCE',
		description: `The user has not logged in since 2024-03-22 11:09:08
Inactive users in Salesforce are accounts that haven't shown activity for an extended period, potentially posing security risks and consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended to maintain system security and optimize license usage.`,
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_6']],
		issue_name: 'UnFederatedIdentity',
		issue_source: 'SALESFORCE',
		description: `A non-federated Salesforce user (ben.tar@synergy.com) detected in the Salesforce instance, which relies on traditional username and password authentication, diverging from Salesforce's security best practices. Using accounts that are not federated with an Identity Provider (IDP) increases the risk of credential leakage and complicates access management. It is recommended to utilize federated IDP roles for more secure and manageable access control.`,
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_7']],
		issue_name: 'InactiveIdentity',
		issue_source: 'SALESFORCE',
		description: `The user has not logged in since 2024-03-22 11:09:08
Inactive users in Salesforce are accounts that haven't shown activity for an extended period, potentially posing security risks and consuming unnecessary licenses. Regular review and deactivation of such accounts is recommended to maintain system security and optimize license usage.`,
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_8']],
		issue_name: 'UnFederatedIdentity',
		issue_source: 'SALESFORCE',
		description:
			"\nA non-federated Salesforce user (**derek.lee@synergy.com**) detected in the Salesforce instance, which\nrelies on traditional username and password authentication, diverging from Salesforce's security best practices.\nUsing users that are not federated with an Identity Provider (IdP) increases the risk of credential compromise and\ncomplicates access management.\nIt is recommended to implement Single Sign-On (SSO) with a federated IdP for enhanced security and streamlined user management.\n",
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_11']],
		issue_name: 'UnFederatedIdentity',
		issue_source: 'SALESFORCE',
		description:
			"\nA non-federated Salesforce user (**nathen.rod@synergy.com**) detected in the Salesforce instance, which\nrelies on traditional username and password authentication, diverging from Salesforce's security best practices.\nUsing users that are not federated with an Identity Provider (IdP) increases the risk of credential compromise and\ncomplicates access management.\nIt is recommended to implement Single Sign-On (SSO) with a federated IdP for enhanced security and streamlined user management.\n",
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: '2024-11-06T07:04:33.367046Z',
		created_at: '2024-10-14T12:06:24.525618Z',
		identity: salesforceUserData[salesforceUserUuids['SALESFORCE_USER_ID_12']],
		issue_name: 'UnFederatedIdentity',
		issue_source: 'SALESFORCE',
		description:
			"\nA non-federated Salesforce user (**ben.der@synergy.com**) detected in the Salesforce instance, which\nrelies on traditional username and password authentication, diverging from Salesforce's security best practices.\nUsing users that are not federated with an Identity Provider (IdP) increases the risk of credential compromise and\ncomplicates access management.\nIt is recommended to implement Single Sign-On (SSO) with a federated IdP for enhanced security and streamlined user management.\n",
		status: 'OPEN',
		assignee: null,
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['311'],
		issue_name: 'InactiveIdentity',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			"\nThe Active Directory user **alex.sigdag** has not logged in within the last 90 days.\nAn inactive AD user is an account that hasn't authenticated to the domain for an extended period, posing security risks.\nThese dormant accounts often retain their permissions and group memberships but may belong to former employees or deprecated service accounts. Such accounts are prime targets for compromise and privilege escalation attacks.\nRegular audit and deprovisioning of inactive accounts is crucial for:\n- Reducing the attack surface of your Active Directory environment\n- Ensuring compliance with security policies\n- Maintaining accurate access control\n- Preventing unauthorized access through abandoned accounts\n\nBest practice is to promptly disable accounts after 90 days of inactivity and review their access privileges.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['311'],
		issue_name: 'PermanentPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **alex.sigdag** has a password configured to never expire.\nPasswords set to never expire bypass standard password rotation policies and can remain unchanged indefinitely.\nThis configuration increases the risk of credential compromise as the same password could be used for extended periods, potentially after employee departure or role changes.\nRegular password rotation is a critical security control that:\n- Limits the impact of compromised credentials\n- Ensures passwords meet current complexity requirements\n- Helps maintain compliance with security standards\n- Reduces the risk of unauthorized access through shared or stolen passwords\n\nBest practice is to enforce password expiration policies for all user accounts unless specifically required for service accounts with proper controls.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['311'],
		issue_name: 'UnrotatedPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **alex.sigdag** has not changed their password in over 90 days.\nUnrotated passwords pose a significant security risk as they may be compromised through various means including phishing, keylogging, or credential stuffing attacks.\nOlder passwords are more likely to have been exposed in data breaches or shared inappropriately over time.\nRegular password rotation helps:\n- Minimize the window of opportunity for attackers\n- Ensure credentials remain fresh and secure\n- Maintain compliance with security best practices\n- Prevent access using previously compromised passwords\n\nBest practice is to enforce password changes every 90 days and implement notifications for upcoming password expirations.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['316'],
		issue_name: 'InactiveIdentity',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			"\nThe Active Directory user **george.brown** has not logged in within the last 90 days.\nAn inactive AD user is an account that hasn't authenticated to the domain for an extended period, posing security risks.\nThese dormant accounts often retain their permissions and group memberships but may belong to former employees or deprecated service accounts. Such accounts are prime targets for compromise and privilege escalation attacks.\nRegular audit and deprovisioning of inactive accounts is crucial for:\n- Reducing the attack surface of your Active Directory environment\n- Ensuring compliance with security policies\n- Maintaining accurate access control\n- Preventing unauthorized access through abandoned accounts\n\nBest practice is to promptly disable accounts after 90 days of inactivity and review their access privileges.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['316'],
		issue_name: 'PermanentPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **george.brown** has a password configured to never expire.\nPasswords set to never expire bypass standard password rotation policies and can remain unchanged indefinitely.\nThis configuration increases the risk of credential compromise as the same password could be used for extended periods, potentially after employee departure or role changes.\nRegular password rotation is a critical security control that:\n- Limits the impact of compromised credentials\n- Ensures passwords meet current complexity requirements\n- Helps maintain compliance with security standards\n- Reduces the risk of unauthorized access through shared or stolen passwords\n\nBest practice is to enforce password expiration policies for all user accounts unless specifically required for service accounts with proper controls.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 4,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['316'],
		issue_name: 'UnrotatedPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **george.brown** has not changed their password in over 90 days.\nUnrotated passwords pose a significant security risk as they may be compromised through various means including phishing, keylogging, or credential stuffing attacks.\nOlder passwords are more likely to have been exposed in data breaches or shared inappropriately over time.\nRegular password rotation helps:\n- Minimize the window of opportunity for attackers\n- Ensure credentials remain fresh and secure\n- Maintain compliance with security best practices\n- Prevent access using previously compromised passwords\n\nBest practice is to enforce password changes every 90 days and implement notifications for upcoming password expirations.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['312'],
		issue_name: 'InactiveIdentity',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			"\nThe Active Directory user **joseph.villanueva** has not logged in within the last 90 days.\nAn inactive AD user is an account that hasn't authenticated to the domain for an extended period, posing security risks.\nThese dormant accounts often retain their permissions and group memberships but may belong to former employees or deprecated service accounts. Such accounts are prime targets for compromise and privilege escalation attacks.\nRegular audit and deprovisioning of inactive accounts is crucial for:\n- Reducing the attack surface of your Active Directory environment\n- Ensuring compliance with security policies\n- Maintaining accurate access control\n- Preventing unauthorized access through abandoned accounts\n\nBest practice is to promptly disable accounts after 90 days of inactivity and review their access privileges.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['312'],
		issue_name: 'PermanentPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **joseph.villanueva** has a password configured to never expire.\nPasswords set to never expire bypass standard password rotation policies and can remain unchanged indefinitely.\nThis configuration increases the risk of credential compromise as the same password could be used for extended periods, potentially after employee departure or role changes.\nRegular password rotation is a critical security control that:\n- Limits the impact of compromised credentials\n- Ensures passwords meet current complexity requirements\n- Helps maintain compliance with security standards\n- Reduces the risk of unauthorized access through shared or stolen passwords\n\nBest practice is to enforce password expiration policies for all user accounts unless specifically required for service accounts with proper controls.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 3,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['312'],
		issue_name: 'UnrotatedPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **joseph.villanueva** has not changed their password in over 90 days.\nUnrotated passwords pose a significant security risk as they may be compromised through various means including phishing, keylogging, or credential stuffing attacks.\nOlder passwords are more likely to have been exposed in data breaches or shared inappropriately over time.\nRegular password rotation helps:\n- Minimize the window of opportunity for attackers\n- Ensure credentials remain fresh and secure\n- Maintain compliance with security best practices\n- Prevent access using previously compromised passwords\n\nBest practice is to enforce password changes every 90 days and implement notifications for upcoming password expirations.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['313'],
		issue_name: 'InactiveIdentity',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			"\nThe Active Directory user **elastic-backup-svc$** has not logged in within the last 90 days.\nAn inactive AD user is an account that hasn't authenticated to the domain for an extended period, posing security risks.\nThese dormant accounts often retain their permissions and group memberships but may belong to former employees or deprecated service accounts. Such accounts are prime targets for compromise and privilege escalation attacks.\nRegular audit and deprovisioning of inactive accounts is crucial for:\n- Reducing the attack surface of your Active Directory environment\n- Ensuring compliance with security policies\n- Maintaining accurate access control\n- Preventing unauthorized access through abandoned accounts\n\nBest practice is to promptly disable accounts after 90 days of inactivity and review their access privileges.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['313'],
		issue_name: 'PermanentPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **elastic-backup-svc$** has a password configured to never expire.\nPasswords set to never expire bypass standard password rotation policies and can remain unchanged indefinitely.\nThis configuration increases the risk of credential compromise as the same password could be used for extended periods, potentially after employee departure or role changes.\nRegular password rotation is a critical security control that:\n- Limits the impact of compromised credentials\n- Ensures passwords meet current complexity requirements\n- Helps maintain compliance with security standards\n- Reduces the risk of unauthorized access through shared or stolen passwords\n\nBest practice is to enforce password expiration policies for all user accounts unless specifically required for service accounts with proper controls.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['313'],
		issue_name: 'UnrotatedPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **elastic-backup-svc$** has not changed their password in over 90 days.\nUnrotated passwords pose a significant security risk as they may be compromised through various means including phishing, keylogging, or credential stuffing attacks.\nOlder passwords are more likely to have been exposed in data breaches or shared inappropriately over time.\nRegular password rotation helps:\n- Minimize the window of opportunity for attackers\n- Ensure credentials remain fresh and secure\n- Maintain compliance with security best practices\n- Prevent access using previously compromised passwords\n\nBest practice is to enforce password changes every 90 days and implement notifications for upcoming password expirations.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['314'],
		issue_name: 'InactiveIdentity',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			"\nThe Active Directory user **svc-mailboxscanner3$** has not logged in within the last 90 days.\nAn inactive AD user is an account that hasn't authenticated to the domain for an extended period, posing security risks.\nThese dormant accounts often retain their permissions and group memberships but may belong to former employees or deprecated service accounts. Such accounts are prime targets for compromise and privilege escalation attacks.\nRegular audit and deprovisioning of inactive accounts is crucial for:\n- Reducing the attack surface of your Active Directory environment\n- Ensuring compliance with security policies\n- Maintaining accurate access control\n- Preventing unauthorized access through abandoned accounts\n\nBest practice is to promptly disable accounts after 90 days of inactivity and review their access privileges.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['314'],
		issue_name: 'PermanentPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **svc-mailboxscanner3$** has a password configured to never expire.\nPasswords set to never expire bypass standard password rotation policies and can remain unchanged indefinitely.\nThis configuration increases the risk of credential compromise as the same password could be used for extended periods, potentially after employee departure or role changes.\nRegular password rotation is a critical security control that:\n- Limits the impact of compromised credentials\n- Ensures passwords meet current complexity requirements\n- Helps maintain compliance with security standards\n- Reduces the risk of unauthorized access through shared or stolen passwords\n\nBest practice is to enforce password expiration policies for all user accounts unless specifically required for service accounts with proper controls.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['314'],
		issue_name: 'UnrotatedPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **svc-mailboxscanner3$** has not changed their password in over 90 days.\nUnrotated passwords pose a significant security risk as they may be compromised through various means including phishing, keylogging, or credential stuffing attacks.\nOlder passwords are more likely to have been exposed in data breaches or shared inappropriately over time.\nRegular password rotation helps:\n- Minimize the window of opportunity for attackers\n- Ensure credentials remain fresh and secure\n- Maintain compliance with security best practices\n- Prevent access using previously compromised passwords\n\nBest practice is to enforce password changes every 90 days and implement notifications for upcoming password expirations.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['315'],
		issue_name: 'InactiveIdentity',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			"\nThe Active Directory user **AUDIT-LOG-UPLOAD$$** has not logged in within the last 90 days.\nAn inactive AD user is an account that hasn't authenticated to the domain for an extended period, posing security risks.\nThese dormant accounts often retain their permissions and group memberships but may belong to former employees or deprecated service accounts. Such accounts are prime targets for compromise and privilege escalation attacks.\nRegular audit and deprovisioning of inactive accounts is crucial for:\n- Reducing the attack surface of your Active Directory environment\n- Ensuring compliance with security policies\n- Maintaining accurate access control\n- Preventing unauthorized access through abandoned accounts\n\nBest practice is to promptly disable accounts after 90 days of inactivity and review their access privileges.\n",
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['315'],
		issue_name: 'PermanentPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **AUDIT-LOG-UPLOAD$** has a password configured to never expire.\nPasswords set to never expire bypass standard password rotation policies and can remain unchanged indefinitely.\nThis configuration increases the risk of credential compromise as the same password could be used for extended periods, potentially after employee departure or role changes.\nRegular password rotation is a critical security control that:\n- Limits the impact of compromised credentials\n- Ensures passwords meet current complexity requirements\n- Helps maintain compliance with security standards\n- Reduces the risk of unauthorized access through shared or stolen passwords\n\nBest practice is to enforce password expiration policies for all user accounts unless specifically required for service accounts with proper controls.\n',
		status: 'IN_PROGRESS',
	},
	{
		priority: 2,
		id: generateUUID(),
		last_seen: getRecentlyCreatedDate(),
		identity: identities['315'],
		issue_name: 'UnrotatedPassword',
		issue_source: 'ACTIVE_DIRECTORY',
		description:
			'\nThe Active Directory user **AUDIT-LOG-UPLOAD$** has not changed their password in over 90 days.\nUnrotated passwords pose a significant security risk as they may be compromised through various means including phishing, keylogging, or credential stuffing attacks.\nOlder passwords are more likely to have been exposed in data breaches or shared inappropriately over time.\nRegular password rotation helps:\n- Minimize the window of opportunity for attackers\n- Ensure credentials remain fresh and secure\n- Maintain compliance with security best practices\n- Prevent access using previously compromised passwords\n\nBest practice is to enforce password changes every 90 days and implement notifications for upcoming password expirations.\n',
		status: 'IN_PROGRESS',
	},
]
