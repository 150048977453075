import { Node, NodeProps } from '@xyflow/react'
import { ServerDatabricksPersonalAccessToken } from '../../../../schemas/identities/databricks/databricksUserSchema.ts'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'

export type DatabricksPATNodeType = Node<{ pat: ServerDatabricksPersonalAccessToken }, 'pat'>
type DatabricksPATNodeProps = NodeProps<DatabricksPATNodeType>

export const DatabricksPATNode = ({ data: { pat } }: DatabricksPATNodeProps) => (
	<BaseCredentialsNode
		label="Personal Access Token"
		name={{ text: pat?.token_id || 'Unknown personal access token' }}
	/>
)
