// adGroupsData.ts
import { StandardGroupSids } from './adConstants'

export const adGroupsData = {
	DOMAIN_ADMINS: {
		object_identifier: StandardGroupSids.DOMAIN_ADMINS,
		properties: {
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			high_value: true,
			name: 'DOMAIN ADMINS@SYNERGY.COM',
			distinguished_name: 'CN=DOMAIN ADMINS,CN=USERS,DC=SYNERGY,DC=COM',
			admin_count: true,
			description: 'Designated administrators of the domain',
			sam_account_name: 'Domain Admins',
			when_created: '2017-07-31T00:33:27.721732Z',
		},
		members: [
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1001', // JASON_NICKELSON
				object_type: 'User',
			},
		],
		aces: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_SID,
				principal_type: 'Group',
			},
			{
				right_name: 'WriteOwner',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_SID,
				principal_type: 'Group',
			},
			{
				right_name: 'AllExtendedRights',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_SID,
				principal_type: 'Group',
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_SID,
				principal_type: 'Group',
			},
		],
		is_deleted: false,
		is_acl_protected: true,
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'ADMIN',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	DNS_ADMINS: {
		object_identifier: StandardGroupSids.DNS_ADMINS,
		properties: {
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			high_value: true,
			name: 'DNS ADMINS@SYNERGY.COM',
			distinguished_name: 'CN=DNS ADMINS,CN=USERS,DC=SYNERGY,DC=COM',
			admin_count: false,
			description: 'DNS Administrators Group',
			sam_account_name: 'DnsAdmins',
			when_created: '2017-07-31T00:33:27.721732Z',
		},
		members: [
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1002', // DANTE_WALKER
				object_type: 'User',
			},
		],
		aces: [
			{
				right_name: 'GenericAll',
				is_inherited: false,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-512',
				principal_type: 'Group',
			},
			{
				right_name: 'GenericAll',
				is_inherited: false,
				principal_sid: 'TOKEN.COM-S-1-5-32-548',
				principal_type: 'Group',
			},
			{
				right_name: 'GenericAll',
				is_inherited: true,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-519',
				principal_type: 'Group',
			},
			{
				right_name: 'GenericWrite',
				is_inherited: true,
				principal_sid: 'S-1-5-21-4283586694-1852547664-606598350-1242',
				principal_type: 'Group',
			},
		],
		is_deleted: false,
		is_acl_protected: false,
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'POSSIBLE_PRIVILEGE_ESCALATION',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	SQL_WRITE_ACCESS: {
		object_identifier: StandardGroupSids.SQL_WRITE_ACCESS,
		properties: {
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			high_value: false,
			name: 'SQL WRITE ACCESS@SYNERGY.COM',
			distinguished_name: 'CN=SQL WRITE ACCESS,CN=USERS,DC=SYNERGY,DC=COM',
			admin_count: false,
			description: 'Members have write access to SQL databases',
			sam_account_name: 'SQL_Write_Access',
			when_created: '2017-07-31T00:33:27.721732Z',
		},
		members: [
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1003', // ALEX_SIGDAG
				object_type: 'User',
			},
		],
		aces: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_ADMINS,
				principal_type: 'Group',
			},
		],
		is_deleted: false,
		is_acl_protected: false,
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	PRM_LOG_ADMINS: {
		object_identifier: StandardGroupSids.PRM_LOG_ADMINS,
		properties: {
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			high_value: false,
			name: 'PRM_LOG_ADMINS@SYNERGY.COM',
			distinguished_name: 'CN=PRM_LOG_ADMINS,CN=USERS,DC=SYNERGY,DC=COM',
			admin_count: false,
			description: 'Members have admin access to PRM logs',
			sam_account_name: 'PRM_LOG_ADMINS',
			when_created: '2017-07-31T00:33:27.721732Z',
		},
		members: [
			{
				object_identifier: 'S-1-5-21-3623811015-3361044348-30300820-1109', // elastic-backup-svc
				object_type: 'User',
			},
		],
		aces: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: 'S-1-5-21-3623811015-3361044348-30300820-1199',
				principal_type: 'Group',
			},
			{
				right_name: 'WriteDacl',
				is_inherited: false,
				principal_sid: 'S-1-5-21-3623811015-3361044348-30300820-1199',
				principal_type: 'Group',
			},
		],
		is_deleted: false,
		is_acl_protected: false,
		tags: [
			{
				id: 'f63545ec-be29-4850-8a79-bae51ed74d2a',
				name: 'DATA_ACCESS',
				impact: 3,
				source: 'TOKEN',
				probability: null,
				related_resource_type: 'ACTIVE_DIRECTORY',
			},
		],
	},

	DOMAIN_USERS: {
		object_identifier: StandardGroupSids.DOMAIN_USERS,
		properties: {
			domain: 'SYNERGY.COM',
			domain_sid: 'S-1-5-21-3623811015-3361044348-30300820',
			high_value: false,
			name: 'DOMAIN USERS@SYNERGY.COM',
			distinguished_name: 'CN=DOMAIN USERS,CN=USERS,DC=SYNERGY,DC=COM',
			admin_count: false,
			description: 'All domain users',
			sam_account_name: 'Domain Users',
			when_created: '2017-07-31T00:33:27.721732Z',
		},
		members: [], // All users are implicitly members
		aces: [
			{
				right_name: 'GenericWrite',
				is_inherited: false,
				principal_sid: StandardGroupSids.DOMAIN_ADMINS,
				principal_type: 'Group',
			},
		],
		is_deleted: false,
		is_acl_protected: false,
	},
}
