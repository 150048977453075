import { JiraField } from './schemas/jiraSchemas.ts'
import { Rule } from 'antd/lib/form'

export const generateJiraDescription = (text: string) => {
	return {
		type: 'doc',
		version: 1,
		content: [
			{
				type: 'paragraph',
				content: [
					{
						type: 'text',
						text: text,
					},
					{
						type: 'text',
						text: '\n\nLink to issue',
						marks: [
							{
								type: 'link',
								attrs: {
									href: window.location.href,
								},
							},
						],
					},
				],
			},
		],
	}
}

export const generateJiraItemRules = (jiraField: JiraField): Rule[] => {
	return [
		{
			required: jiraField.required,
			message: `Please provide ${jiraField.name.toLowerCase()}!`,
		},
	]
}
