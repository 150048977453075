export enum priorityColors {
	PRIORITY_NOT_CALCULATED_COLOR = '#7C7C7C',
	PRIORITY_NOT_CALCULATED_COLOR_LIGHT = '#FAFAFA',
	PRIORITY_LOW_COLOR = '#4C94FF',
	PRIORITY_LOW_COLOR_LIGHT = '#EDF4FF',
	PRIORITY_MEDIUM_COLOR = '#FF6C1A',
	PRIORITY_MEDIUM_COLOR_LIGHT = '#FFF4E5',
	PRIORITY_HIGH_COLOR = '#FF0000',
	PRIORITY_HIGH_COLOR_LIGHT = '#FFEDED',
	PRIORITY_CRITICAL_COLOR = '#C70018',
	PRIORITY_CRITICAL_COLOR_LIGHT = '#FFEEF0',
}
