import { createRootRoute, createRoute } from '@tanstack/react-router'
import { ClientIssuesSearchUrlSchema, FindingsPageSearchSchema } from '../schemas/issue.ts'
import { Findings } from './Findings/Findings.tsx'
import { Metrics } from './Metrics/Metrics.tsx'
import { Logout } from './Logout.tsx'
import { App } from '../App.tsx'
import { Identities } from './Identities/Identities.tsx'
import { IdentitiesPageSearchSchema, IdentityPageSearchSchema } from '../schemas/identity.ts'

import { IdentitiesContextProvider } from './Identities/context/IdentitiesContext.tsx'
import { IdentityPage } from './Identity/IdentityPage.tsx'
import { SwaggerComponent } from './Api/SwaggerComponent.tsx'
import { Settings, SettingsSearchSchema } from './Settings/Settings.tsx'
import { RoutePaths } from './RoutePaths.tsx'
import { NavigateToBase } from './NavigateToBase.tsx'
import { SlackIntegration } from './SlackIntegration.tsx'
import { Integrations } from './Integrations/Integrations.tsx'
import { DemoItdrFindings } from '../mirage/itdr/DemoItdrFindings.tsx'
import { isDemo } from '../utils/demoUtils.ts'
import { SlackIntegrationsSearchSchema } from '../schemas/integrations/integrations.ts'
import { IntegrationsSearchSchema } from '../schemas/integrations/integrations.ts'

export const rootRoute = createRootRoute({
	component: () => <App />,
})

export const indexRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: RoutePaths.Index,
	component: NavigateToBase,
})

export const anyOtherRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: RoutePaths.Any,
	component: NavigateToBase,
})

export const findingsRoute = createRoute({
	getParentRoute: () => rootRoute,
	validateSearch: FindingsPageSearchSchema,
	path: RoutePaths.Findings,
	component: () => <Findings />,
})

export const apiRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: RoutePaths.Swagger,
	component: () => <SwaggerComponent />,
})

export const metricsRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: RoutePaths.Metrics,
	component: () => <Metrics />,
})

export const logoutRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: RoutePaths.Logout,
	component: () => <Logout />,
})

export const identitiesRoute = createRoute({
	getParentRoute: () => rootRoute,
	validateSearch: IdentitiesPageSearchSchema,
	path: RoutePaths.Identities,
	component: () => (
		<IdentitiesContextProvider>
			<Identities />
		</IdentitiesContextProvider>
	),
})

export const identityRoute = createRoute({
	getParentRoute: () => rootRoute,
	validateSearch: IdentityPageSearchSchema,
	path: RoutePaths.Identity,
	component: IdentityPage,
})

export const settingsRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: RoutePaths.Settings,
	validateSearch: SettingsSearchSchema,
	component: () => <Settings />,
})

export const slackIntegrationRoute = createRoute({
	getParentRoute: () => rootRoute,
	validateSearch: SlackIntegrationsSearchSchema,
	path: RoutePaths.SlackIntegration,
	component: () => <SlackIntegration />,
})

export const integrationsRoute = createRoute({
	getParentRoute: () => rootRoute,
	validateSearch: IntegrationsSearchSchema,
	path: RoutePaths.Integrations,
	component: () => <Integrations />,
})

export const itdrRoute = createRoute({
	getParentRoute: () => rootRoute,
	validateSearch: ClientIssuesSearchUrlSchema,
	path: RoutePaths.Itdr,
	component: () => (isDemo ? <DemoItdrFindings /> : <NavigateToBase />),
})
