import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent, IRowNode } from '@ag-grid-community/core'
import { ServerAzureVirtualMachine } from '../../../../../schemas/identities/azure/demoAzureVirtualMachine.ts'
import { capitalizeFirstLetter } from '../../../../../utils.ts'

export const DemoAzureVirtualMachineTable: React.FunctionComponent<{
	azureVirtualMachines: ServerAzureVirtualMachine[] | null
}> = ({ azureVirtualMachines }) => {
	const gridRef = useRef<AgGridReact>(null)
	const columnDefs = useMemo<ColDef<ServerAzureVirtualMachine>[]>(() => {
		return [
			{ field: 'name' },
			{
				field: 'status',
				headerName: 'State',
				flex: 0.75,
				cellRenderer: (params: { data?: ServerAzureVirtualMachine; node: IRowNode }) => {
					return <div>{capitalizeFirstLetter(params?.data?.status)}</div>
				},
			},
			{ field: 'resource_group', headerName: 'Resource Group' },
			{ field: 'location' },
			{
				field: 'tenant',
				headerName: 'Tenant',
				cellRenderer: (params: { data?: ServerAzureVirtualMachine; node: IRowNode }) => {
					return <div>{`${params?.data?.tenant.tenant_name} - ${params?.data?.tenant.tenant_id}`}</div>
				},
			},
			{
				field: 'subscription',
				headerName: 'Subscription',
				cellRenderer: (params: { data?: ServerAzureVirtualMachine; node: IRowNode }) => {
					return (
						<div>{`${params?.data?.subscription.subscription_name} - ${params?.data?.subscription.subscription_id}`}</div>
					)
				},
			},
			{ field: 'public_ip', headerName: 'Public IP' },
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent<ServerAzureVirtualMachine>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine overflow-x-auto'}
				ref={gridRef}
				rowData={azureVirtualMachines}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
