import { Col, Divider, Row, Tooltip } from 'antd'
import { capitalizeFirstLetter, formatDate, formatRelativeDateText } from '../../../utils'
import React, { useEffect, useState } from 'react'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import { IssueSourceIcon } from '../../common/IssueSourceIcon'
import { ServerIdentity } from '../../../schemas/identity'
import { ServerAwsEc2Instance } from '../../../schemas/identities/awsEc2InstanceSchema.ts'
import { AwsIamRoleContext } from './AwsIamRoleContext.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { AccountPriorityNameTag } from '../../common/AccountPriorityNameTag.tsx'

export const AwsEc2InstanceIdentity: React.FunctionComponent<{
	identity?: ServerIdentity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContextAmount] = useState(0)
	const awsEc2Instance: ServerAwsEc2Instance | null = identity?.aws_ec2_instance ?? null
	const accountPriority = awsEc2Instance?.aws_account?.priority

	useEffect(() => {
		calcAmountOfContext()
	}, [identity])

	const calcAmountOfContext = () => {
		// Logic to calculate the context amount for EC2 Instance
		let amount = 0 // Replace with actual calculation
		if (awsEc2Instance?.aws_iam_role_details_xc) amount++
		setContextAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Instance ID" value={identity?.literal} span={1} />
				<BaseIdentityDetail label="Region" value={identity?.aws_ec2_instance?.region} span={1} />
				{identity?.aws_ec2_instance?.state && (
					<BaseIdentityDetail
						label="State"
						value={capitalizeFirstLetter(identity?.aws_ec2_instance?.state)}
						span={1}
					/>
				)}
				{identity?.aws_ec2_instance?.instance_type && (
					<BaseIdentityDetail
						label="Instance Type"
						value={identity?.aws_ec2_instance?.instance_type}
						span={1}
					/>
				)}
				<BaseIdentityDetail label="Account" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.account_literal}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.account_literal_friendly_name ?? identity?.account_literal}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{accountPriority && (
					<BaseIdentityDetail label="Account Priority" span={1}>
						<AccountPriorityNameTag priority={accountPriority} />
					</BaseIdentityDetail>
				)}
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.last_activity, true)}
					tooltipTitle={formatDate(identity?.last_activity)}
					span={1}
				/>
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Col className="gap-3 space-y-4">
							<Row className="text-gray-900">Context ({contextAmount})</Row>
							{awsEc2Instance?.aws_iam_role_details_xc && (
								<AwsIamRoleContext awsIamRolesXc={[awsEc2Instance?.aws_iam_role_details_xc]} />
							)}
						</Col>
					</div>
				)}
			</div>
		</>
	)
}
