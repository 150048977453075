import { Server } from 'miragejs/server'
import { environments } from '../data/environments.ts'
import { Account } from '../../schemas/environments/accounts.ts'

export function mirageGetAccounts(server: Server) {
	server.get('/accounts', (): Account[] => {
		return environments
	})
}

export function mirageUpdateAccounts(server: Server) {
	server.put('/accounts', () => null)
}
