import { Collapse } from 'antd'
import React from 'react'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { ResourceType } from '../../../../schemas/issue.ts'
import { ServerDatabricksOAuthToken } from '../../../../schemas/identities/databricks/ServerDatabricksServicePrincipalSchema.ts'
import { DatabricksOAuthTokensTable } from '../../../../tables/DatabricksOAuthTokensTable.tsx'

export const DatabricksOAuthTokensContext: React.FunctionComponent<{
	databricksSpOauth?: ServerDatabricksOAuthToken[] | null
}> = ({ databricksSpOauth }) => {
	return (
		<Collapse
			expandIconPosition={'end'}
			items={[
				{
					key: 'OAuthTokens',
					label: (
						<div className="flex items-center">
							<IssueSourceIcon source={ResourceType.DATABRICKS} />
							<div>OAuth secrets</div>
						</div>
					),
					children: <DatabricksOAuthTokensTable data={databricksSpOauth ?? []} />,
				},
			]}
		/>
	)
}
