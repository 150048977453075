import { useAuth as useFronteggAuth } from '@frontegg/react-hooks/auth'
import { useSlackUrlBuilder } from '../api/integrations'
import { useEffect } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from './RoutePaths'
import { IntegrationResultStatus } from '../schemas/integrations/integrations'

export const SlackIntegration = () => {
	const { user: fronteggUser } = useFronteggAuth()
	const { data: slackUrl } = useSlackUrlBuilder(fronteggUser?.accessToken)
	const search = useSearch({ from: RoutePaths.SlackIntegration })
	const navigate = useNavigate()
	// Keep track of whether we've redirected to Slack to avoid infinite loops

	useEffect(() => {
		const slackParam = search.integrationStatus

		// If we have a slack-integration parameter, redirect to integrations page
		if (slackParam) {
			navigate({
				to: RoutePaths.Integrations,
				search: {
					integrationType: 'slack',
					integrationStatus: slackParam as IntegrationResultStatus,
					...(slackParam === 'success' ? { openModal: 'slack' } : {}),
				},
			})
			return
		}

		// If we have a slackUrl and no slack-integration parameter, proceed with OAuth
		if (slackUrl) {
			// Clean up any existing URL parameters before redirecting to Slack
			const cleanUrl = window.location.origin + window.location.pathname
			window.history.replaceState({}, '', cleanUrl)
			// Prevent redirect loops by checking if we're already at Slack's URL
			if (!window.location.href.startsWith('https://slack.com')) {
				window.location.href = slackUrl
			}
		}
	}, [slackUrl, search, navigate])

	return null
}
