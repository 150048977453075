import { Button, Form, Input, Typography } from 'antd'
import React from 'react'
import InsightIcon from '../../../../assets/insight_icon_16.svg?react'
import {
	IntegrationTestConnectionRequest,
	IntegrationTestConnectionResponse,
} from '../../../../schemas/integrations/integrations'
import { IntegrationConfig, IntegrationFormData } from '../../types/integrationConfig'

const { Title, Link } = Typography

interface IntegrationFormProps {
	integration: IntegrationConfig
	onSubmit: (formData: IntegrationFormData) => void
	onTestConnection: (request: IntegrationTestConnectionRequest) => Promise<IntegrationTestConnectionResponse>
	onClose: () => void
	isConnecting: boolean
	isTestingConnection: boolean
}

export const IntegrationForm: React.FC<IntegrationFormProps> = ({
	integration,
	onSubmit,
	onTestConnection,
	onClose,
	isConnecting,
	isTestingConnection,
}) => {
	const [form] = Form.useForm<IntegrationFormData>()
	const [testResult, setTestResult] = React.useState<'success' | 'error' | null>(null)

	const handleTestConnection = () => {
		void form.validateFields().then((values) => {
			const testConnectionRequest = integration.createTestConnectionPayload(values)

			void onTestConnection(testConnectionRequest)
				.then((result) => {
					setTestResult(result.success ? 'success' : 'error')
				})
				.catch(() => {
					setTestResult('error')
				})
		})
	}

	const handleGuideDownload = () => {
		const link = document.createElement('a')
		link.href = integration.guideFileName
		link.download = `${integration.key}-integration-guide.pdf`
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	return (
		<>
			<div className="flex items-center mb-4">
				{integration.logo && <integration.logo className="w-8 h-8 mr-3" />}
				<Title level={4} className="!m-0 text-gray-900">
					Login to your {integration.name} account
				</Title>
			</div>

			<p className="text-sm text-gray-600 mb-6">
				Please visit the{' '}
				<Link onClick={handleGuideDownload} className="text-purple-600 hover:text-purple-700 cursor-pointer">
					Integration Guide
				</Link>{' '}
				for further information
			</p>

			<Form form={form} layout="vertical" onFinish={onSubmit} initialValues={{ name: '' }}>
				<Form.Item
					name="name"
					label="Environment Name"
					className="mb-4"
					rules={[{ required: true, message: 'Please input the Environment Name!' }]}
				>
					<Input
						disabled={isConnecting || isTestingConnection}
						className="h-8 rounded-lg border-gray-300 hover:border-purple-200 focus:border-purple-200 focus:shadow-purple-100"
					/>
				</Form.Item>

				{integration.formFields.map((field) => (
					<Form.Item
						key={field.name}
						name={field.name}
						label={field.label}
						className="mb-4"
						rules={[{ required: field.required, message: `Please input the ${field.label}!` }]}
					>
						<Input
							type={field.type}
							placeholder={field.placeholder}
							disabled={isConnecting || isTestingConnection}
							className="h-8 rounded-lg border-gray-300 hover:border-purple-200 focus:border-purple-200 focus:shadow-purple-100"
						/>
					</Form.Item>
				))}

				<div className="flex items-center gap-2 mb-4">
					<Button
						type="default"
						onClick={handleTestConnection}
						disabled={isConnecting}
						loading={isTestingConnection}
						icon={<InsightIcon className="w-4 h-4" />}
						className="h-7 px-2 text-gray-700 border-gray-300"
					>
						Test Connection
					</Button>
					{testResult && (
						<span className={`text-sm ${testResult === 'success' ? 'text-green-500' : 'text-red-500'}`}>
							{testResult === 'success' ? 'Connection Succeed' : 'Connection Failed'}
						</span>
					)}
				</div>

				<div className="flex justify-between pt-4 border-t border-gray-300">
					<Button onClick={onClose} className="h-8 px-4 text-gray-700">
						Cancel
					</Button>
					<Button
						type="primary"
						htmlType="submit"
						disabled={testResult !== 'success'}
						loading={isConnecting}
						className="h-8 px-4 min-w-[86px] bg-zinc-900 border-zinc-900 hover:bg-zinc-800 hover:border-zinc-800 disabled:bg-gray-200 disabled:border-gray-200"
					>
						Connect
					</Button>
				</div>
			</Form>
		</>
	)
}
