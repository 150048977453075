import { Server } from 'miragejs/server'
import { AppSchema } from '../schemas.ts'
import { getAllNotifications, updateNotificationStatus } from '../mirageORM.ts'
import { ServerNotification } from '../../schemas/notifications.ts'

export function mirageNotifications(server: Server) {
	server.get('/notifications', (schema: AppSchema) => {
		return getAllNotifications(schema)
	})

	server.put('/notifications', (schema: AppSchema, request) => {
		const notifications = JSON.parse(request.requestBody) as ServerNotification[]

		return notifications.map((notification) => {
			return updateNotificationStatus(schema, notification.playbook_id, notification.status)
		})
	})
}
