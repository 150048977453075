import { z } from 'zod'
import { PriorityTypeEnum } from '../issue.ts'

export const ServerFindingByPrioritySchema = z.object({
	priority: z.nativeEnum(PriorityTypeEnum),
	priorityNumber: z.number().nullish(),
	count: z.number(),
	disabled: z.boolean().nullish(),
})

export type ServerFindingByPriority = z.infer<typeof ServerFindingByPrioritySchema>
