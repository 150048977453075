import { Dispatch, SetStateAction } from 'react'
import { Tooltip } from 'antd'
import { ControlButton } from '@xyflow/react'

type CompactModeControlButtonProps = {
	isCompact: boolean
	setIsCompact: Dispatch<SetStateAction<boolean>>
}

export const CompactModeControlButton = ({ isCompact, setIsCompact }: CompactModeControlButtonProps) => {
	return (
		<Tooltip title={`${isCompact ? 'Disable' : 'Enable'} compact mode`}>
			<div>
				<ControlButton
					onClick={() => {
						setIsCompact((currentIsCompact) => !currentIsCompact)
					}}
					className={isCompact ? '!bg-blue-300' : undefined}
				>
					<span>C</span>
				</ControlButton>
			</div>
		</Tooltip>
	)
}
