import { FronteggUser } from '../../../services/auth/fronteggApi.ts'
import React, { useMemo } from 'react'
import { FronteggUserAvatar } from '../../common/FronteggUserAvatar.tsx'
import { Select } from 'antd'
import { useIssue, useUpdateIssue } from '../../../api/issues.ts'
import { useFronteggUsers } from '../../../services/auth'

const selectClassName = 'w-[240px]'

interface FronteggUserOption extends Partial<FronteggUser> {
	label: React.ReactNode
	value: string
}

const filterAssigneeOption = (input: string, option?: FronteggUserOption): boolean => {
	const lowerInput = input.toLowerCase()
	return (
		option?.email?.toLowerCase().includes(lowerInput) || option?.name?.toLowerCase().includes(lowerInput) || false
	)
}

export const SelectAssignee = ({ issueId }: { issueId: string }) => {
	const { mutate: updateIssue, isLoading: isIssueUpdating } = useUpdateIssue()
	const { data: issue, isLoading: isIssueLoading, isError: isIssueError } = useIssue(issueId)
	const { data: fronteggUsers, isLoading: isFronteggUsersLoading, isError: isFronteggUsersError } = useFronteggUsers()
	const options: FronteggUserOption[] = useMemo(
		() =>
			fronteggUsers
				? Object.values(fronteggUsers).map((fronteggUser) => ({
						...fronteggUser,
						value: fronteggUser.id,
						label: <FronteggUserAvatar user={fronteggUser} compact />,
					}))
				: [],
		[fronteggUsers],
	)

	if (isFronteggUsersError || isIssueError) {
		return null
	}

	if (isFronteggUsersLoading || isIssueLoading) {
		return <Select disabled loading className={selectClassName} placeholder="Loading..." />
	}

	const value: FronteggUserOption | null = issue.assignee
		? options.find((option) => option.value === issue.assignee) || {
				value: issue.assignee,
				label: <FronteggUserAvatar compact />,
			}
		: null

	return (
		<Select
			className={selectClassName}
			showSearch
			placeholder="Select assignee"
			labelInValue
			loading={isIssueUpdating}
			disabled={isIssueUpdating}
			onChange={(newValue?: FronteggUserOption | null) => {
				updateIssue({ issueId, issueUpdate: { assignee: newValue?.value ?? null } })
			}}
			value={value}
			filterOption={filterAssigneeOption}
			options={options}
			allowClear
		/>
	)
}
