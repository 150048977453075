import { Tooltip } from 'antd'
import { ReactNode } from 'react'

interface CollapseLabelProps {
	icon: ReactNode
	title: string
	tooltipTitle?: string
}

export const CollapseLabel = ({ icon, title, tooltipTitle }: CollapseLabelProps) => {
	const contentNode = (
		<span className="flex gap-2 items-center">
			{icon}
			{title}
		</span>
	)

	if (!tooltipTitle) {
		return contentNode
	}

	return (
		<Tooltip placement="topLeft" title={tooltipTitle}>
			{contentNode}
		</Tooltip>
	)
}
