import { z } from 'zod'
import { DateSchema } from '../../common.ts'

export const ServerEntraIdUserXcSchema = z
	.object({
		mail: z.string().nullish(),
		given_name: z.string().nullish(),
		job_title: z.string().nullish(),
		user_principal_name: z.string().nullish(),
		entra_user_id: z.string().nullish(),
		last_activity: DateSchema.nullish(),
		password_last_changed: z.string().nullish(),
		account_enabled: z.boolean().nullish(),
	})
	.nullish()

export type ServerEntraIdUserXc = z.infer<typeof ServerEntraIdUserXcSchema>
