import React from 'react'
import { Modal, Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { CollaborationIntegrationConfig } from '../../types/integrationConfig'
import { SlackIntegrationModalContent } from './SlackIntegrationModalContent'
import { IntegrationType } from '../../../../jiraIntegration/schemas/common'
import SlackModalHeaderIcon from '../../../../assets/IntegrationPage/SlackIntegrationModalContent/SlackIntegrationModalHeaderIcon.svg?react'
import CollaborationIntegrationModalFooterTestConnectionIcon from '../../../../assets/IntegrationPage//CollaborationIntegrationModalFooterTestConnectionIcon.svg?react'

interface IntegrationModalProps {
	integration: CollaborationIntegrationConfig | null
	integrationType: IntegrationType
	isModalVisible: boolean
	onClose: () => void
	onTestConnection: () => Promise<void>
	isConnecting: boolean
	isTestingConnection: boolean
	testConnectionStatus: 'success' | 'error' | null
}

export const CollaborationsIntegrationModal: React.FC<IntegrationModalProps> = ({
	integration,
	integrationType,
	isModalVisible,
	onClose,
	onTestConnection,
	isConnecting,
	isTestingConnection,
	testConnectionStatus,
}) => {
	if (!integration) return null

	const renderModalContent = () => {
		if (integrationType === IntegrationType.SLACK) {
			return <SlackIntegrationModalContent />
		}

		return <></>
	}

	const modalTitle = (
		<div className="flex items-center gap-2">
			{integrationType === IntegrationType.SLACK && (
				<>
					<SlackModalHeaderIcon />
					<span className="text-lg">Slack</span>
				</>
			)}
		</div>
	)

	const getTestConnectionButtonText = () => {
		if (isTestingConnection) return 'Testing Connection...'
		if (testConnectionStatus === 'success') return 'Test Successful'
		if (testConnectionStatus === 'error') return 'Test Failed'
		return 'Test Connection'
	}

	const modalFooter = (
		<div className="flex justify-end space-x-3 px-2 pt-4 border-t border-neutral-200">
			<Button
				type="default"
				onClick={() => void onTestConnection()}
				disabled={isConnecting || isTestingConnection}
				loading={isTestingConnection}
				icon={<CollaborationIntegrationModalFooterTestConnectionIcon className="w-4 h-4" />}
				className={`px-2 text-gray-700 border-gray-300 ${
					testConnectionStatus === 'success'
						? 'text-green-600'
						: testConnectionStatus === 'error'
							? 'text-red-600'
							: ''
				}`}
			>
				{getTestConnectionButtonText()}
			</Button>
			<Button type="primary" onClick={onClose} className="bg-[#383840] hover:bg-[#383840]/90">
				Done
			</Button>
		</div>
	)

	return (
		<Modal
			open={isModalVisible}
			onCancel={onClose}
			footer={modalFooter}
			width={1000}
			className="integrationModal"
			destroyOnClose={true}
			centered
			title={modalTitle}
			closeIcon={<CloseOutlined />}
		>
			{renderModalContent()}
		</Modal>
	)
}
