import { useCallback, useState } from 'react'
import { Button, Modal } from 'antd'
import ExportIcon from '../../assets/export_icon_20.svg?react'
import { useIdentitiesContext } from './context/IdentitiesProvider.tsx'
import { useEntitlementToPaginatedInventory } from '../../services/auth/hooks.ts'
import { handleExportingPaginatedDataToCsv } from './paginatedInventoryUtils.ts'
import { getCsvFileName } from '../../utils.ts'
import { useIdentitiesExportingLimit } from '../../api/identities.ts'
import { formatNumber } from '../../utils/numberUtils.ts'

export const IdentitiesExport = () => {
	const { gridRef, gridReady } = useIdentitiesContext()
	const { isEntitled: isEntitledToPaginatedTable } = useEntitlementToPaginatedInventory()
	const { data: maxIdentitiesToExport, isLoading: isLoadingMaxIdentitiesToExport } =
		useIdentitiesExportingLimit(isEntitledToPaginatedTable)
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

	const exportPaginatedDataToCsv = useCallback(() => {
		handleExportingPaginatedDataToCsv(gridRef?.current?.api)
		setIsModalOpen(false)
	}, [])

	const handleExportClicked = useCallback(() => {
		if (isEntitledToPaginatedTable) {
			const totalRowCount = gridRef?.current?.api?.paginationGetRowCount() || 0
			if (totalRowCount > Number(maxIdentitiesToExport)) {
				setIsModalOpen(true)
			} else {
				exportPaginatedDataToCsv()
			}
		} else {
			gridRef?.current?.api?.exportDataAsCsv({
				fileName: getCsvFileName('identities'),
			})
		}
	}, [isEntitledToPaginatedTable, maxIdentitiesToExport])

	const handleModalClose = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<Button
				icon={<ExportIcon className="inline-block" />}
				className="bg-white text-gray-500 px-2 flex items-start"
				onClick={handleExportClicked}
				loading={!gridReady || isLoadingMaxIdentitiesToExport}
			>
				Export CSV
			</Button>

			<Modal
				centered
				open={isModalOpen}
				width={530}
				onCancel={handleModalClose}
				onClose={handleModalClose}
				title={'Confirm Export'}
				key="identities-export-modal"
				footer={[
					<div key="identities-export-modal-footer" className="flex justify-end">
						<Button
							onClick={handleModalClose}
							className="bg-white text-gray-500 px-2 flex items-start mr-2"
						>
							Cancel
						</Button>
						<Button className="bg-black text-white" onClick={exportPaginatedDataToCsv}>
							Export
						</Button>
					</div>,
				]}
			>
				<div className={'flex justify-start py-3 whitespace-pre-line'}>
					{`The export limit is ${formatNumber(Number(maxIdentitiesToExport))} items. You’re trying to export ${gridRef?.current?.api?.paginationGetRowCount()} items.
					Refine your filters or click “Export” to download the first ${formatNumber(Number(maxIdentitiesToExport))} items.`}
				</div>
			</Modal>
		</>
	)
}
