import React, { useEffect, useState } from 'react'

const ReactQueryDevtools = React.lazy(() =>
	import('@tanstack/react-query-devtools').then((res) => ({
		default: res.ReactQueryDevtools,
	})),
)

const TanstackRouterDevtools = React.lazy(() =>
	import('@tanstack/router-devtools').then((res) => ({
		default: res.TanStackRouterDevtools,
	})),
)

export const Devtools: React.FC = () => {
	const [isVisible, setIsVisible] = useState(true)
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.shiftKey && event.code === 'KeyD') {
				setIsVisible((currentIsVisible) => !currentIsVisible)
			}
		}

		window.addEventListener('keydown', handleKeyDown)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	if (!isVisible) {
		return null
	}

	return (
		<>
			<ReactQueryDevtools position="bottom-right"></ReactQueryDevtools>
			<TanstackRouterDevtools toggleButtonProps={{ className: 'mr-16' }} position="bottom-right" />
		</>
	)
}
