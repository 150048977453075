import { Layout } from 'antd'
import MetricsIcon from '../../assets/metrics_icon_20.svg?react'

export const MetricsHeader = () => {
	const headerStyle = {
		boxShadow: '0px 0px 10px 0px #0000001A',
	}

	return (
		<Layout.Header
			style={headerStyle}
			className="rounded-tl-md bg-neutral-100 rounded-tr-md z-10 flex flex-row items-center px-6"
		>
			<div>
				<MetricsIcon width={16} height={16} />
			</div>
			<div className="pl-2 font-medium text-xl">Dashboard</div>
		</Layout.Header>
	)
}
