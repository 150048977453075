import React, { useEffect, useState } from 'react'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { BaseIdentityDetail } from '../baseIdentities/BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { formatDate } from '../../../../utils.ts'
import { Col, Divider, Row, Space, Tag, Tooltip } from 'antd'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { ServerEntraIdServicePrincipal } from '../../../../schemas/identities/entraId/entraIdServicePrincipalSchema.ts'
import { EntraIdSpAuthenticationCredential } from './EntraIdSpAuthenticationCredential.tsx'
import { normalizeCredentials } from '../../../../schemas/identities/entraId/entraIdApplicationXc.ts'
import { AzureRoleAssignmentsContext } from '../azure/azureRBAC/AzureRoleAssignmentsContext.tsx'
import { EntraIdChangeLogsContext } from './EntraIdChangeLogsContext.tsx'
import { AzureUsageLogsContent } from '../azure/AzureUsageLogsContent.tsx'

export const EntraIdServicePrincipalIdentity: React.FunctionComponent<{
	identity?: ServerIdentity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContextAmount] = useState(0)
	const entraIdServicePrincipal: ServerEntraIdServicePrincipal | null | undefined =
		identity?.entra_id_service_principal

	useEffect(() => {
		calcAmountOfContext()
	}, [entraIdServicePrincipal])

	const calcAmountOfContext = () => {
		let amount = 0
		if (entraIdServicePrincipal?.azure_role_assignments_xc?.length) amount++
		if (entraIdServicePrincipal?.change_logs?.length) amount++
		if (entraIdServicePrincipal?.usage_logs?.length) amount++
		// Add other cross-context checks here if needed in the future
		setContextAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail
					label="Service Principal Id"
					value={entraIdServicePrincipal?.service_principal_id}
					span={1}
				/>
				<BaseIdentityDetail
					label="Service Principal Type"
					value={entraIdServicePrincipal?.service_principal_type}
					span={1}
				/>
				{entraIdServicePrincipal?.display_name ? (
					<BaseIdentityDetail label="Name" value={entraIdServicePrincipal?.display_name} span={1} />
				) : null}
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Environment" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.account_literal}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.account_literal_friendly_name ?? identity?.account_literal}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Application Id" value={entraIdServicePrincipal?.app_id} span={1} />
				{entraIdServicePrincipal?.account_enabled !== null ? (
					<BaseIdentityDetail label="Account status" span={1}>
						<Tag>
							<div className="flex items-center">
								<div
									className={`${entraIdServicePrincipal?.account_enabled ? 'text-green-700' : 'text-gray-700'} text font-medium`}
								>
									{entraIdServicePrincipal?.account_enabled ? 'Enabled' : 'Disabled'}
								</div>
							</div>
						</Tag>
					</BaseIdentityDetail>
				) : null}

				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
				{normalizeCredentials(
					entraIdServicePrincipal?.entra_id_application_xc?.key_credentials || [],
					entraIdServicePrincipal?.entra_id_application_xc?.password_credentials || [],
				).map((credential, index) => (
					<BaseIdentityDetail key={credential.keyId} label={`Credential ${index + 1}`} span={1}>
						<EntraIdSpAuthenticationCredential credential={credential} />
					</BaseIdentityDetail>
				))}
			</div>
			<div className="w-full">
				{contextAmount > 0 && (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdServicePrincipal?.azure_role_assignments_xc &&
									entraIdServicePrincipal?.azure_role_assignments_xc?.length > 0 && (
										<AzureRoleAssignmentsContext
											azureRoleAssignmentsXc={entraIdServicePrincipal.azure_role_assignments_xc}
										/>
									)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{identity &&
									entraIdServicePrincipal?.change_logs &&
									entraIdServicePrincipal?.change_logs?.length > 0 && (
										<EntraIdChangeLogsContext
											changeLogs={entraIdServicePrincipal?.change_logs || []}
											identity={identity}
										/>
									)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdServicePrincipal?.usage_logs &&
									entraIdServicePrincipal?.usage_logs?.length > 0 && (
										<AzureUsageLogsContent
											usageLogs={entraIdServicePrincipal?.usage_logs}
											identity={identity!}
										/>
									)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
