import { MiniMapNodeProps } from '@xyflow/react'

export const MiniMapNode = ({ x, y, width, height, className, color, shapeRendering }: MiniMapNodeProps) => (
	<circle
		width={width}
		height={height}
		className={className}
		shapeRendering={shapeRendering}
		cx={x}
		cy={y}
		r="50"
		style={{ fill: color }}
	/>
)
