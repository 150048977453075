import * as React from "react";
const SvgElasticIcon = (props) => /* @__PURE__ */ React.createElement("svg", { width: "800px", height: "800px", viewBox: "0 0 32 32", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "file_type_elastic"), /* @__PURE__ */ React.createElement("path", { d: "M12.761,13.89l6.644,3.027,6.7-5.874a7.017,7.017,0,0,0,.141-1.475,7.484,7.484,0,0,0-13.66-4.233L11.466,11.12Z", style: {
  fill: "#fed10a"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.886,20.919a7.262,7.262,0,0,0-.141,1.5,7.514,7.514,0,0,0,13.724,4.22l1.1-5.759L19.1,18.059l-6.67-3.04Z", style: {
  fill: "#24bbb1"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.848,9.426,10.4,10.5l1-5.169A3.594,3.594,0,0,0,5.848,9.426", style: {
  fill: "#ef5098"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.45,10.517a5.016,5.016,0,0,0-.218,9.453L11.62,14.2l-1.167-2.5Z", style: {
  fill: "#17a8e0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M20.624,26.639a3.589,3.589,0,0,0,5.541-4.092l-4.541-1.065Z", style: {
  fill: "#93c83e"
} }), /* @__PURE__ */ React.createElement("path", { d: "M21.547,20.29l5,1.167A5.016,5.016,0,0,0,26.768,12l-6.541,5.733Z", style: {
  fill: "#0779a1"
} }));
export default SvgElasticIcon;
