import { JiraField, JiraFieldComponent } from '../schemas/jiraSchemas.ts'
import { JiraAttachmentField } from './JiraAttachmentField.tsx'
import { JiraSelectField } from './JiraSelectField.tsx'

export const JiraArrayField: JiraFieldComponent = (props) => {
	const field: JiraField | undefined = props.field
	if (!field) {
		return null
	}
	if (field.schema.items === 'attachment') {
		return <JiraAttachmentField {...props} field={field} />
	}
	return <JiraSelectField {...props} field={field} />
}
