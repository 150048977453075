import { Node, NodeProps } from '@xyflow/react'
import { GcpServiceAccountKey } from '../../../../schemas/identities/gcp/gcpServiceAccountSchema.ts'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'

export type GcpAccessKeyNodeType = Node<{ accessKey: GcpServiceAccountKey }, 'gcpAccessKey'>
type GcpAccessKeyNodeProps = NodeProps<GcpAccessKeyNodeType>

export const GcpSaAccessKeyNode = ({ data: { accessKey } }: GcpAccessKeyNodeProps) => (
	<BaseCredentialsNode label="Access Key" name={{ text: accessKey.accessKeyId }} />
)
