import { z } from 'zod'
import { EnvironmentType } from '../envType.ts'

export const ServerEnvTypeIdentitiesCountSchema = z.object({
	total_count: z.number(),
	admin_count: z.number(),
	env_type: z.nativeEnum(EnvironmentType),
})

export type ServerEnvTypeIdentitiesCount = z.infer<typeof ServerEnvTypeIdentitiesCountSchema>

export enum OtherEnvironmentType {
	OTHER = 'Other',
}

export type ServerEnvTypeOrOther = EnvironmentType | OtherEnvironmentType
export type ServerEnvTypeOrOtherIdentitiesCount = Omit<ServerEnvTypeIdentitiesCount, 'env_type'> & {
	env_type: ServerEnvTypeOrOther
}
