import { RoutePaths } from '../../RoutePaths.tsx'
import { getQueryFilterFromTableFilterModel } from '../findingsTableUtils.ts'
import { useSearch } from '@tanstack/react-router'
import { useMemo } from 'react'
import { TableFilterEntry } from '../../../schemas/tableFilter.ts'
import { PaginatedFindingsPageSearch } from '../../../schemas/issue.ts'
import { usePaginatedIssuesStats } from '../../../api/issues.ts'
import { FindingsStatsPosture } from './FindingsStatsPaginatedPosture.tsx'
import { FindingsStatsPaginatedEnvsDistribution } from './FindingsStatsPaginatedEnvsDistribution.tsx'
import { FindingsStatsItem } from './FindingsStatsItem.tsx'
import { FindingsStatsCount } from '../FindingsStatsCount.tsx'

export const FindingsStatsPaginated = () => {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { issueFilter, lens, tab } = useSearch({ from: RoutePaths.Findings }) as PaginatedFindingsPageSearch
	const query = useMemo(
		() => ({
			filter: getQueryFilterFromTableFilterModel((issueFilter || {}) as Record<string, TableFilterEntry>),
		}),
		[issueFilter],
	)

	const statsQuery = usePaginatedIssuesStats(query, tab, lens, false, true)
	const { data: issuesStats } = statsQuery

	const totalCount = useMemo(() => {
		if (!issuesStats) {
			return 0
		}

		return issuesStats.total_count
	}, [issuesStats])

	return (
		<div
			className="py-4 px-6 flex flex-row gap-2 justify-between min-h-36"
			style={{
				backgroundColor: '#FFFFFF',
				border: '2px solid #FFFFFF',
				boxShadow: '0px 0px 10px 0px #0000001A',
			}}
		>
			<FindingsStatsItem title="Findings" statsQuery={statsQuery}>
				<FindingsStatsCount count={totalCount} />
			</FindingsStatsItem>
			<FindingsStatsPaginatedEnvsDistribution />
			<FindingsStatsPosture statsQuery={statsQuery} />
		</div>
	)
}
