import { useMemo, useCallback } from 'react'
import { ColDef } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { ConfigProvider, Switch } from 'antd'
import {
	ServerNotification,
	NotificationChannel,
	NotificationChannelMap,
	NotificationStatus,
} from '../../../schemas/notifications'
import RuleParameters from '../../../assets/notifications/notificationsTableRuleParams.svg?react'
import NotificationsSlackDestinationIcon from '../../../assets/notifications/notificationsSlackDestinationIcon.svg?react'
import NotificationsAddNewIntegrationWireframe from '../../../assets/notifications/NotificationsAddNewIntegrationWireframe.svg?react'
import NotificationAddNewIntegrationBtn from '../../../assets/notifications/notificationAddNewIntegrationBtn.svg?react'

type NotificationsConfigTableProps = {
	isEditing: boolean
	isFetching: boolean
	notifications: ServerNotification[]
	searchText: string
	onToggleNotification: (notification: ServerNotification) => void
	onNavigateToSlackIntegration: () => void
}

const NotificationChannelIcon = ({ value }: { value: NotificationChannel | undefined }) => (
	<div className="flex items-center">
		{value === NotificationChannel.SLACK ? <NotificationsSlackDestinationIcon /> : <></>}
	</div>
)

export const NotificationsConfigTable = ({
	isEditing,
	isFetching,
	notifications,
	searchText,
	onToggleNotification,
	onNavigateToSlackIntegration,
}: NotificationsConfigTableProps) => {
	const columns = useMemo(
		(): ColDef<ServerNotification>[] => [
			{
				headerName: 'Rule Name',
				flex: 0.7,
				sort: 'asc',
				sortIndex: 0,
				field: 'name',
				// TODO: This needs to be dynamic once we allow setting the rule name in the UI
				cellRenderer: ({ data }: { data: ServerNotification }) => (
					<div className="flex items-center h-full">
						<span className="text-zinc-500">{data.name}</span>
					</div>
				),
			},
			{
				headerName: 'Description',
				flex: 1,
				field: 'description',
				// TODO: This needs to be dynamic once we allow setting the description in the UI
				cellRenderer: ({ data }: { data: ServerNotification }) => (
					<span className="text-zinc-500 flex items-center h-full">{data.description}</span>
				),
			},
			{
				headerName: 'Rule Parameters',
				flex: 0.8,
				field: 'trigger_json',
				// TODO: This needs to be dynamic once we have a better way to display the rule parameters from the trigger_json
				cellRenderer: () => (
					<span className="flex items-center h-full">
						<div className="flex items-center">
							<RuleParameters />
						</div>
					</span>
				),
			},
			{
				field: 'integration_type',
				headerName: 'Messaging App',
				flex: 0.4,
				valueGetter: ({ data }) => NotificationChannelMap[data?.integration_type ?? NotificationChannel.SLACK],
				cellRenderer: ({ data }: { data: ServerNotification }) => (
					<span className="flex items-center h-full">
						<NotificationChannelIcon value={data.integration_type ?? undefined} />
					</span>
				),
			},
			{
				field: 'integration_data',
				headerName: 'Destination',
				flex: 0.7,
				valueGetter: ({ data }) => NotificationChannelMap[data?.integration_type ?? NotificationChannel.SLACK],
				cellRenderer: ({ data }: { data: ServerNotification }) => (
					<span className="flex items-center h-full text-zinc-500">
						{/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
						{data.integration_data?.incoming_webhook?.channel ?? ''}
					</span>
				),
			},
			{
				headerName: 'Enabled',
				type: 'rightAligned',
				flex: 0.4,
				field: 'status',
				cellRenderer: ({ value, data }: { value: NotificationStatus; data: ServerNotification }) => (
					<ConfigProvider
						theme={{
							token: {
								colorPrimary: '#7C987D',
							},
						}}
					>
						<Switch
							checked={value === NotificationStatus.ENABLED}
							onChange={() => {
								onToggleNotification(data)
							}}
							loading={isFetching}
							disabled={!isEditing}
						/>
					</ConfigProvider>
				),
			},
		],
		[isEditing, isFetching],
	)

	const NoRowsOverlay = useCallback(() => {
		return (
			// We need to set pointer-events-auto to make the button clickable as ag-grid sets pointer-events-none by default
			<div className="flex flex-col items-center justify-center h-full gap-8 pointer-events-auto">
				<div className="scale-110">
					<NotificationsAddNewIntegrationWireframe />
				</div>
				<div className="flex flex-col items-center text-center">
					<p className="font-bold text-lg mb-2">No connection yet</p>
					<p className="text-zinc-500">
						Add Token app to any of your channels,
						<br />
						And start taking control of your non-human identities
					</p>
				</div>
				<NotificationAddNewIntegrationBtn onClick={onNavigateToSlackIntegration} className="cursor-pointer" />
			</div>
		)
	}, [onNavigateToSlackIntegration])

	return (
		<AgGridReact
			className={'ag-theme-alpine h-full w-full overflow-x-auto'}
			rowData={notifications}
			columnDefs={columns}
			quickFilterText={searchText}
			getRowId={({ data }) => data.playbook_id}
			rowHeight={50}
			noRowsOverlayComponent={NoRowsOverlay}
		/>
	)
}
