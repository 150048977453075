import { Layout, Tabs } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import { useEntitlementToSettingsPage } from '../../services/auth/hooks.ts'
import { IssueConfig } from './IssueConfig/IssueConfig.tsx'
import { ComponentProps, useMemo } from 'react'
import { isDemo } from '../../utils/demoUtils.ts'
import { IntegrationsConfig } from './IntegrationsConfig/IntegrationsConfig.tsx'
import { z } from 'zod'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { AccountConfig } from './AccountConfig/AccountConfig.tsx'
import { NavigateToBase } from '../NavigateToBase.tsx'
import { RoutePaths } from '../RoutePaths.tsx'
import { NotificationsConfig } from './NotificationsConfig/NotificationsConfig.tsx'

enum SettingsTabNames {
	ISSUE_CONFIG = 'issueConfig',
	INTEGRATIONS = 'integrations',
	ACCOUNT_CONFIG = 'accountConfig',
	NOTIFICATIONS = 'notifications',
}

type SettingsTab = Required<ComponentProps<typeof Tabs>>['items'][number] & { key: SettingsTabNames }
const defaultSettingsTab: SettingsTabNames = SettingsTabNames.ISSUE_CONFIG
export const SettingsSearchSchema = z.object({
	tab: z.nativeEnum(SettingsTabNames).catch(defaultSettingsTab),
})

export const Settings = () => {
	const { isEntitled: isEntitledToSettingsPage } = useEntitlementToSettingsPage()
	const { tab } = useSearch({ from: RoutePaths.Settings })
	const navigate = useNavigate({ from: RoutePaths.Settings })
	const tabItems = useMemo(() => {
		const items: SettingsTab[] = [
			{
				key: SettingsTabNames.ISSUE_CONFIG,
				label: 'Issue Configuration',
			},
			{ key: SettingsTabNames.ACCOUNT_CONFIG, label: 'Account Configuration' },
			{ key: SettingsTabNames.NOTIFICATIONS, label: 'Notifications' },
		]

		if (isDemo) {
			items.push({ key: SettingsTabNames.INTEGRATIONS, label: 'Integrations' })
		}

		return items
	}, [])

	if (!isEntitledToSettingsPage) {
		return <NavigateToBase />
	}

	const onTabChange = (newTab: string) => {
		void navigate({ search: (prev) => ({ ...prev, tab: newTab as SettingsTabNames }) })
	}

	return (
		<Layout className="h-full rounded-md relative overflow-x-hidden">
			<Layout.Header
				className="rounded-t-md px-6 pt-3 h-auto"
				style={{
					backgroundColor: 'rgba(255, 255, 255, 0.7)',
					boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
				}}
			>
				<div className="flex gap-2 text-xl items-center">
					<SettingOutlined />
					<span>Settings</span>
				</div>
				<div className="flex justify-center">
					<Tabs tabBarStyle={{ marginBottom: 0 }} onChange={onTabChange} items={tabItems} activeKey={tab} />
				</div>
			</Layout.Header>
			<Layout.Content className="px-6 pt-4 h-full overflow-y-auto">
				{tab === SettingsTabNames.ISSUE_CONFIG && <IssueConfig />}
				{tab === SettingsTabNames.ACCOUNT_CONFIG && <AccountConfig />}
				{tab === SettingsTabNames.INTEGRATIONS && <IntegrationsConfig />}
				{tab === SettingsTabNames.NOTIFICATIONS && <NotificationsConfig />}
			</Layout.Content>
		</Layout>
	)
}
