import { Layout } from 'antd'
import cx from 'classnames'
import { MetricsStatsHeader } from './MetricsStatsHeader/MetricsStatsHeader.tsx'
import { MetricsHeader } from './MetricsHeader.tsx'
import { MonitoringIdentities } from './MonitoringIdentities.tsx'
import { IdentitiesByPriorityDistributionStackedBarChart } from '../../charts/IdentitiesByPriorityDistributionStackedBarChart.tsx'
import { TopFindings } from './TopFindings.tsx'
import { TopInsights } from './TopInsights.tsx'
import { MostWantedIdentities } from './MostWantedIdentities.tsx'
import { FindingsDistribution } from './FindingsDistribution.tsx'
import { LifecycleDistribution } from './LifecycleDistribution.tsx'
import { MonitoredIdentitiesChartV2 } from './MontioredIdentitiesChartV2.tsx'
import { ResolvedVsUnresolvedFindingsChartV2 } from '../../charts/ResolvedVsUnresolvedFindingsChartV2.tsx'
import { useEntitlementToMetricsPageV2 } from '../../services/auth/hooks.ts'
import { IdentitiesWithIssueEnvTypeDistribution } from './IdentitiesWithIssueEnvTypeDistribution.tsx'
import { IssueName } from '../../schemas/issue.ts'

const MetricsWidgetsContainer = ({
	className,
	children,
	layout,
}: {
	className?: string
	children: React.ReactNode
	layout: 'grid' | 'flex'
}) => {
	const containerClassName = cx(
		'bg-white p-4 rounded-lg min-h-0',
		{
			'grid grid-cols-12 gap-6 auto-rows-min overflow-y-auto': layout === 'grid',
			'flex flex-col gap-4': layout === 'flex',
		},
		className,
	)

	const style = {
		boxShadow: '0px 0px 20px 0px #8B90AC1A',
	}

	return (
		<div className={containerClassName} style={style}>
			{children}
		</div>
	)
}

const MetricsBody = () => {
	return (
		<MetricsWidgetsContainer layout="grid" className="m-4">
			<MonitoringIdentities />
			<MostWantedIdentities />
			<IdentitiesByPriorityDistributionStackedBarChart />
			<FindingsDistribution />
			<TopFindings />
			<TopInsights />
		</MetricsWidgetsContainer>
	)
}

const MetricsBodyV2 = () => {
	return (
		<div className="m-4 min-h-0 h-full grid grid-cols-4 gap-4">
			<MetricsWidgetsContainer layout="grid" className="col-span-3">
				<IdentitiesWithIssueEnvTypeDistribution
					issueName={IssueName.InactiveIdentity}
					title="Inactive Identities"
				/>
				<IdentitiesWithIssueEnvTypeDistribution
					issueName={IssueName.UnFederatedIdentity}
					title="Unfederated Identities"
				/>
				<MonitoredIdentitiesChartV2 />
				<LifecycleDistribution />
				<FindingsDistribution />
				<ResolvedVsUnresolvedFindingsChartV2 size="large" />
			</MetricsWidgetsContainer>
			<MetricsWidgetsContainer layout="flex" className="col-span-1">
				<MostWantedIdentities isTop5 />
				<TopInsights growContainer />
			</MetricsWidgetsContainer>
		</div>
	)
}

export const Metrics = () => {
	const { isEntitled: isEntitledToMetricsPageV2 } = useEntitlementToMetricsPageV2()

	return (
		<Layout className="h-full rounded-md">
			<MetricsHeader />
			<Layout.Content className="flex flex-col">
				<MetricsStatsHeader />
				{isEntitledToMetricsPageV2 ? <MetricsBodyV2 /> : <MetricsBody />}
			</Layout.Content>
		</Layout>
	)
}
