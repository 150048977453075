import { z } from 'zod'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'
import { ServerAwsAccountSchema } from './awsAccountSchema.ts'

export const ServerAwsEnvironmentSchema = ServerAbstractEnvironmentModelSchema.extend({
	org_id: z.string().nullish(),
	external_id: z.string().uuid(),
	aws_accounts: z.array(ServerAwsAccountSchema),
})

export type ServerAwsEnvironment = z.infer<typeof ServerAwsEnvironmentSchema>
