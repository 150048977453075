import { PriorityType } from '../../../schemas/issue.ts'
import { Select } from 'antd'
import React from 'react'
import { PrioritySelectOption } from './PrioritySelectOption.tsx'

type PriorityOptionType = { value: PriorityType | 0; label: React.ReactNode }
const selectPriorityOptions: PriorityOptionType[] = [
	{
		value: PriorityType.CRITICAL,
		label: <PrioritySelectOption priority={PriorityType.CRITICAL} />,
	},
	{
		value: PriorityType.HIGH,
		label: <PrioritySelectOption priority={PriorityType.HIGH} />,
	},
	{
		value: PriorityType.MEDIUM,
		label: <PrioritySelectOption priority={PriorityType.MEDIUM} />,
	},
	{
		value: PriorityType.LOW,
		label: <PrioritySelectOption priority={PriorityType.LOW} />,
	},
	{
		value: 0,
		label: <PrioritySelectOption priority={0} />,
	},
]

type SelectPriorityProps = {
	priority: PriorityType | 0
	onChange: (newPriority: PriorityType | null) => void
	disabled: boolean
	loading: boolean
}

export const SelectPriority = ({ priority, onChange, disabled, loading }: SelectPriorityProps) => (
	<div className="flex items-center h-full">
		{disabled ? (
			<PrioritySelectOption priority={priority} withBorder />
		) : (
			<Select
				placeholder="Select assignee"
				className="w-[200px]"
				loading={loading}
				onChange={(newPriority: PriorityType | 0) => {
					onChange(newPriority || null)
				}}
				value={priority ?? 0}
				options={selectPriorityOptions}
			/>
		)}
	</div>
)
