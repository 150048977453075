export const getActorArnFriendlyName = (actorArn?: string) => {
	if (!actorArn) return ''

	if (actorArn.endsWith(':root')) return 'root'

	// Get the last part of the ARN
	const actorArnParts = actorArn.split('/')
	if (actorArnParts === undefined) return actorArn
	return actorArnParts[actorArnParts.length - 1]
}
