import { Tooltip } from 'antd'
import { useActiveSidePanelKeysContext } from '../../ActiveSidePanelKeysContext'
import { ServerIdentity, IdentitySource } from '../../../../schemas/identity'
import { NodeBadge } from './NodeBadge'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors'
import IdentityTagIcon from '../../../../assets/identityTagIcons/identity_tag_icon_16.svg?react'

interface TagsNodeIndicatorProps {
	identity: ServerIdentity
}

export const TagsNodeIndicator = ({ identity }: TagsNodeIndicatorProps) => {
	const { setActiveSidePanelKeys } = useActiveSidePanelKeysContext()

	const hasTags =
		(identity.source === IdentitySource.AWS_IAM_USER &&
			!!identity.aws_iam_user?.aws_iam_user_details_xc?.Tags?.length) ||
		(identity.source === IdentitySource.AWS_IAM_ROLE &&
			!!identity.aws_iam_role?.aws_iam_role_details_xc?.Tags?.length)

	if (!hasTags) {
		return null
	}

	const badgeClassName = cx(
		nodeColorClassNames.identity.badgeBg,
		nodeColorClassNames.identity.badgeBorder,
		nodeColorClassNames.identity.badgeText,
	)

	const handleClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		setActiveSidePanelKeys(['Tags'])
	}

	return (
		<Tooltip title="View Tags">
			<NodeBadge className={badgeClassName} style={{ left: 30 }} onClick={handleClick}>
				<IdentityTagIcon style={{ height: 18, width: 18 }} />
			</NodeBadge>
		</Tooltip>
	)
}
