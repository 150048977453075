import { ServerIdentity } from '../../../schemas/identity.ts'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../common/IssueSourceIcon.tsx'
import { Divider, Row, Space, Tooltip } from 'antd'
import { AccountPriorityNameTag } from '../../common/AccountPriorityNameTag.tsx'
import { formatDate } from '../../../utils.ts'
import React, { useEffect, useState } from 'react'
import { ServerAwsKeyPair } from '../../../schemas/identities/awsKeyPairSchema.ts'
import { AwsIamRoleContext } from './AwsIamRoleContext.tsx'
import { AwsEc2InstanceContext } from './AwsEc2InstanceContext.tsx'

export const AwsKeyPairIdentity: React.FunctionComponent<{
	identity?: ServerIdentity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const awsKeyPair: ServerAwsKeyPair | null = identity?.aws_key_pair ?? null
	const accountPriority = awsKeyPair?.aws_account?.priority
	const [contextAmount, setContextAmount] = useState(0)

	useEffect(() => {
		calcAmountOfContext()
	}, [identity])

	const calcAmountOfContext = () => {
		let amount = 0
		if (awsKeyPair?.aws_iam_role_details_xc?.length ?? 0 > 0) amount++
		if (awsKeyPair?.aws_ec2_instances_xc?.length ?? 0 > 0) amount++
		setContextAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Key Pair Name" value={awsKeyPair?.name} span={2} />
				<BaseIdentityDetail label={'Key Pair Fingerprint'} value={awsKeyPair?.key_fingerprint} span={2} />
				<BaseIdentityDetail label="Key Pair Id" value={identity?.literal} span={1} />
				<BaseIdentityDetail label="Region" value={awsKeyPair?.region} span={1} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Account" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.account_literal}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.account_literal_friendly_name ?? identity?.account_literal}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{accountPriority && (
					<BaseIdentityDetail label="Account Priority" span={1}>
						<AccountPriorityNameTag priority={accountPriority} />
					</BaseIdentityDetail>
				)}
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Row className="text-gray-900">Context ({contextAmount})</Row>
						{awsKeyPair?.aws_ec2_instances_xc && awsKeyPair.aws_ec2_instances_xc.length > 0 ? (
							<Row className="mt-2">
								<Space className="w-full" direction="vertical">
									<AwsEc2InstanceContext awsEc2InstancesXc={awsKeyPair.aws_ec2_instances_xc} />
								</Space>
							</Row>
						) : null}
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{awsKeyPair?.aws_iam_role_details_xc &&
									awsKeyPair?.aws_iam_role_details_xc.length > 0 && (
										<AwsIamRoleContext awsIamRolesXc={awsKeyPair?.aws_iam_role_details_xc} />
									)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
