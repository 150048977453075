import React from 'react'
import { Divider, Layout, Typography, notification as antdToast } from 'antd'
import { NavigateToBase } from '../NavigateToBase'
import { useEntitlementToIntegrationsPage } from '../../services/auth/hooks'
import { EnvironmentIntegrations } from './components/EnvironmentIntegrations/EnvironmentIntegrations'
import { CollaborationIntegrations } from './components/CollaborationIntegrations/CollaborationIntegrations'
import { useEffect, useRef, useState } from 'react'
import { ApiOutlined } from '@ant-design/icons'
import { useSearch, useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../RoutePaths'
import { IntegrationType } from '../../jiraIntegration/schemas/common'
import { IntegrationResultStatus } from '../../schemas/integrations/integrations'
const { Title, Paragraph } = Typography

enum ModalType {
	ENVIRONMENT = 'environment',
	COLLABORATION = 'collaboration',
}

const IntegrationsHeader: React.FC = () => {
	return (
		<div className="w-full h-12 bg-zinc-100 border border-zinc-200 rounded-lg flex items-center pl-5">
			<div className="flex items-center gap-2 text-zinc-700">
				<ApiOutlined className="text-base" />
				<span className="text-xl font-medium">Integrations</span>
			</div>
		</div>
	)
}

export const Integrations: React.FC = () => {
	const { isEntitled } = useEntitlementToIntegrationsPage()
	const search = useSearch({ from: RoutePaths.Integrations })
	const navigate = useNavigate()
	const [toastApi, antdToastContextHolder] = antdToast.useNotification()
	const [openSpecificModal, setOpenSpecificModal] = useState<IntegrationType | undefined>(undefined)
	const processedParams = useRef(new Set<string>())
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [activeModalType, setActiveModalType] = useState<ModalType | undefined>(undefined)

	useEffect(() => {
		// Handle Slack integration status notifications
		// This effect runs when redirected back from Slack OAuth flow
		// It shows appropriate toast messages based on the integration result
		// and cleans up the URL parameters afterwards to prevent duplicate toasts
		// This can be removed once we have a more robust way to handle OAuth flows for all integrations
		const { integrationType, openModal, integrationStatus } = search

		if (integrationType === 'slack' && integrationStatus && !processedParams.current.has('slack')) {
			processedParams.current.add('slack')

			if (integrationStatus === IntegrationResultStatus.SUCCESS) {
				toastApi.success({
					message: 'Success',
					description: 'Slack integration successfully configured',
					duration: 4,
				})
			} else if (integrationStatus === IntegrationResultStatus.FAIL) {
				toastApi.error({
					message: 'Error',
					description: 'Could not configure Slack integration. Please try again.',
					duration: 4,
				})
			} else if (integrationStatus === IntegrationResultStatus.EXISTS) {
				toastApi.info({
					message: 'Info',
					description: 'Slack integration already configured',
					duration: 4,
				})
			}
			if (openModal) {
				setOpenSpecificModal(openModal === 'slack' ? IntegrationType.SLACK : undefined)
				setActiveModalType(ModalType.COLLABORATION)
				setIsModalVisible(true)
			}

			// Clean up URL parameters
			navigate({
				to: RoutePaths.Integrations,
				search: {},
			})
		}
	}, [search, toastApi, navigate])

	if (!isEntitled) {
		return <NavigateToBase />
	}

	return (
		<Layout className="h-full rounded-md">
			{antdToastContextHolder}
			<Layout.Content className="px-6 pt-4">
				<div className="flex flex-col gap-5">
					<IntegrationsHeader />
					<div className="flex flex-col w-full bg-white border border-zinc-100 rounded-lg p-6">
						<div>
							<Title level={5}>Environment Integrations</Title>
							<Paragraph className="text-zinc-500">
								Connect your environment to your tools to enable seamless communication and
								collaboration.
							</Paragraph>
							<div className="w-full bg-white px-1">
								<EnvironmentIntegrations
									isModalVisible={isModalVisible && activeModalType === ModalType.ENVIRONMENT}
									setIsModalVisible={(visible) => {
										setIsModalVisible(visible)
										setActiveModalType(visible ? ModalType.ENVIRONMENT : undefined)
									}}
								/>
							</div>
						</div>
						<Divider />
						<div>
							<Title level={5}>Collaboration Tools</Title>
							<Paragraph className="text-zinc-500">
								Connect your collaboration tools to your environment to enable seamless communication
								and collaboration.
							</Paragraph>
							<div className="w-full bg-white px-1">
								<CollaborationIntegrations
									openSpecificModal={openSpecificModal}
									isModalVisible={isModalVisible && activeModalType === ModalType.COLLABORATION}
									setIsModalVisible={(visible) => {
										setIsModalVisible(visible)
										setActiveModalType(visible ? ModalType.COLLABORATION : undefined)
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Layout.Content>
		</Layout>
	)
}
