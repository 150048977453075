import { z } from 'zod'
import { TagSchema } from '../../tags.ts'

// EntraIdRoleAssignment schema
export const EntraIdRoleAssignmentSchema = z.object({
	id: z.string(),
	directoryScopeId: z.string().optional(),
	principalId: z.string().optional(),
	roleDefinitionId: z.string().optional(),
})

export type EntraIdRoleAssignment = z.infer<typeof EntraIdRoleAssignmentSchema>

// EntraIdRoleDefinition schema
export const EntraIdRoleDefinitionSchema = z.object({
	id: z.string(),
	description: z.string(),
	displayName: z.string().optional(),
	inheritsPermissionsFrom: z.array(z.unknown()).optional(),
	isBuiltIn: z.boolean().optional(),
	isEnabled: z.boolean().optional(),
	resourceScopes: z.array(z.unknown()).optional(),
	rolePermissions: z.array(z.unknown()).optional(),
	templateId: z.string().optional(),
})

export type EntraIdRoleDefinition = z.infer<typeof EntraIdRoleDefinitionSchema>

// DetailedEntraIdRoleAssignment schema
export const DetailedEntraIdRoleAssignmentSchema = z.object({
	roleAssignment: EntraIdRoleAssignmentSchema,
	roleDefinition: EntraIdRoleDefinitionSchema,
	tags: TagSchema.array().nullish(),
})

export type DetailedEntraIdRoleAssignment = z.infer<typeof DetailedEntraIdRoleAssignmentSchema>
