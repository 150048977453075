import { type FC } from 'react'
import { Tooltip } from 'antd'
import cx from 'classnames'

interface Props {
	owners: string[]
	maxVisible?: number
}

const getDisplayText = (identifier: string): string => {
	if (identifier.toLowerCase().startsWith('arn:')) {
		if (identifier.endsWith(':root')) {
			return 'R'
		}
		return (identifier.split('/').pop() ?? '').slice(0, 2).toUpperCase()
	}

	return identifier.slice(0, 2).toUpperCase()
}

export const OverlappingOwnershipTags: FC<Props> = ({ owners, maxVisible = 3 }) => {
	if (!owners.length) return null

	const visibleTags = owners.slice(0, maxVisible)
	const remainingOwners = owners.slice(maxVisible)
	const remainingCount = remainingOwners.length
	const tagClasses = cx(
		'flex items-center justify-center',
		'w-6 h-8',
		'border shadow rounded',
		'bg-blue-50 text-blue-600 border-blue-100',
		'text-xs',
		'transition-transform hover:scale-105 hover:z-10',
	)
	return (
		<div className={'flex items-center gap-1'}>
			<div className="flex gap-1 px-1">
				{visibleTags.map((owner, index) => (
					<Tooltip key={`${owner}-${index}`} title={owner}>
						<div className={tagClasses}>{getDisplayText(owner)}</div>
					</Tooltip>
				))}
			</div>

			{remainingCount > 0 && (
				<Tooltip
					title={
						<div className="flex flex-col gap-1">
							{remainingOwners.map((owner, index) => (
								<span key={index}>{owner}</span>
							))}
						</div>
					}
				>
					<span className="text-gray-400 text-sm ml-0.5 hover:text-gray-600">+{remainingCount}</span>
				</Tooltip>
			)}
		</div>
	)
}
