import { useContext } from 'react'
import { IdentitiesContext, IdentitiesContextState } from './IdentitiesContext'

export const useIdentitiesContext = (): IdentitiesContextState => {
	const context = useContext(IdentitiesContext)
	if (context === null) {
		throw new Error('useIdentitiesContext must be used within an IdentitiesProvider')
	}
	return context
}
