import { z } from 'zod'
import { DateSchema } from '../../common'
import { ServerDatabricksRoleSchema } from './ServerDatabricksRoleSchema.ts'
import { ServerAwsIamChangeLogSchema } from '../awsIamChangeLogSchema.ts'
import { ServerKubernetesResourceXcSchema } from '../kubernetes/kubernetesResourceXcSchema.ts'
import { ServerEndpointAccessXcSchema } from '../endpointAccessXcSchema.ts'
import { ServerDatabricksWorkspaceSchema } from './ServerDatabricksWorkspaceSchema.ts'

export const ServerDatabricksOAuthTokenSchema = z
	.object({
		token_id: z.string(),
		created_at: DateSchema,
		last_used: DateSchema.nullish(),
	})
	.nullable()

export type ServerDatabricksOAuthToken = z.infer<typeof ServerDatabricksOAuthTokenSchema>

export const ServerDatabricksServicePrincipalSchema = z
	.object({
		sp_id: z.string(),
		sp_name: z.string(),
		created_at: DateSchema,
		updated_at: DateSchema,
		roles: z.array(ServerDatabricksRoleSchema).nullable(),
		workspaces: z.array(ServerDatabricksWorkspaceSchema).nullable(),
		oauth_tokens: z.array(ServerDatabricksOAuthTokenSchema).nullable(),
		change_logs: z.array(ServerAwsIamChangeLogSchema).nullish(),
		kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(),
		endpoint_access_xc: z.array(ServerEndpointAccessXcSchema).nullish(),
	})
	.nullable()

export type ServerDatabricksServicePrincipal = z.infer<typeof ServerDatabricksServicePrincipalSchema>
