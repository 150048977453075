import { useEffect, useState, useCallback } from 'react'
import { Button, Input, notification as antdToast, Skeleton } from 'antd'
import { EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import { NotificationsConfigTable } from './NotificationsConfigTable'
import { useNotifications, useUpdateNotifications } from '../../../api/notifications'
import { NotificationStatus, ServerNotification } from '../../../schemas/notifications'
import { keyBy } from 'lodash'
import { NotificationsConfigConfirmationModal } from './NotificationsConfigConfirmationModal.tsx'
import { useNavigate } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'

export const NotificationsConfig = () => {
	const [searchText, setSearchText] = useState('')
	const [isEditing, setIsEditing] = useState(false)
	// We use a inner state to store the notifications because we need to compare the current state with the server state
	// to determine which notifications have changed.
	const [notifications, setNotifications] = useState<ServerNotification[]>([])
	// We use a inner state to store the changed notification ids because we need to determine which notifications have changed.
	// We could have used the notifications state and compare the current state with the server state, but this would require
	// a deep comparison of the objects which is not necessary.
	// Using a inner state is clearer and easier to understand.
	const [changedNotificationIds, setChangedNotificationIds] = useState(new Set<string>())
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [toastApi, antdToastContextHolder] = antdToast.useNotification()
	const navigate = useNavigate({ from: RoutePaths.Settings })

	const {
		data: serverNotifications,
		isLoading: isLoadingNotifications,
		isError: isErrorNotifications,
		isFetching: isFetchingNotifications,
	} = useNotifications()

	const { mutate: mutateNotifications, isLoading: isUpdatingNotifications } = useUpdateNotifications()

	useEffect(() => {
		if (serverNotifications) {
			setNotifications([...serverNotifications])
		}
	}, [serverNotifications])

	useEffect(() => {
		if (!serverNotifications) {
			return
		}

		const serverNotificationsById = keyBy(serverNotifications, 'playbook_id')
		const newChangedNotificationIds = new Set<string>()
		notifications.forEach((notification) => {
			if (
				notification.status !== serverNotificationsById[notification.playbook_id].status ||
				notification.integration_type !== serverNotificationsById[notification.playbook_id].integration_type
			) {
				newChangedNotificationIds.add(notification.playbook_id)
			}
		})

		setChangedNotificationIds(newChangedNotificationIds)
	}, [notifications])

	const areActionsDisabled = useCallback(() => {
		// This is a useCallback because it is used in the disabled prop of the actions buttons and we want to avoid
		// unnecessary re-renders.
		return (
			isFetchingNotifications || isUpdatingNotifications || isLoadingNotifications || !serverNotifications?.length
		)
	}, [isFetchingNotifications, isUpdatingNotifications, isLoadingNotifications, serverNotifications])

	if (isLoadingNotifications) {
		return <Skeleton active />
	}

	if (isErrorNotifications) {
		return <div className="flex justify-center items-center h-screen">Could not load data, please try again</div>
	}

	const onClickEditButton = () => {
		if (!isEditing) {
			setIsEditing(true)
		} else {
			setIsModalOpen(true)
		}
	}

	const onToggleNotification = (notificationToToggle: ServerNotification) => {
		setNotifications((currentNotifications) =>
			currentNotifications.map((currentNotification) => {
				if (currentNotification.playbook_id === notificationToToggle.playbook_id) {
					return {
						...currentNotification,
						status:
							notificationToToggle.status === NotificationStatus.ENABLED
								? NotificationStatus.DISABLED
								: NotificationStatus.ENABLED,
					}
				}
				return currentNotification
			}),
		)
	}

	const onClickCancel = () => {
		setIsEditing(false)
		if (serverNotifications) {
			setNotifications(serverNotifications)
		}
	}

	const onSuccess = () => {
		toastApi.success({ message: 'Successfully updated notification configuration' })
		setIsModalOpen(false)
		setIsEditing(false)
	}

	const onError = () => {
		toastApi.error({
			message: 'Error',
			description: 'Could not update notification configuration. Please try again.',
			duration: 0,
		})
		setIsModalOpen(false)
	}

	const onSaveConfirm = () => {
		void mutateNotifications(
			notifications.filter((notification) => changedNotificationIds.has(notification.playbook_id)),
			{
				onSuccess,
				onError,
			},
		)
	}

	const onNavigateToSlackIntegration = () => {
		navigate({ to: RoutePaths.SlackIntegration })
	}

	return (
		<div className="flex flex-col gap-2 h-full">
			{antdToastContextHolder}
			<div className="flex justify-between items-center">
				<span className="text-lg text-neutral-800">Notifications Configuration</span>
				<div className="flex gap-2">
					<Input
						value={searchText}
						size="small"
						onChange={(e) => {
							setSearchText(e.target.value)
						}}
						allowClear={true}
						placeholder="Search"
						className="mr-4"
						disabled={areActionsDisabled()}
						prefix={<SearchOutlined />}
					/>
					{isEditing && (
						<Button onClick={onClickCancel} loading={isUpdatingNotifications || isFetchingNotifications}>
							Cancel
						</Button>
					)}
					<Button
						disabled={areActionsDisabled() || (isEditing && !changedNotificationIds.size)}
						onClick={onClickEditButton}
						loading={isUpdatingNotifications || isFetchingNotifications}
						type="primary"
						className="bg-black"
						icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
					>
						{isEditing ? 'Apply' : 'Edit'}
					</Button>
				</div>
			</div>
			<NotificationsConfigTable
				isEditing={isEditing}
				isFetching={isFetchingNotifications}
				notifications={notifications}
				searchText={searchText}
				onToggleNotification={onToggleNotification}
				onNavigateToSlackIntegration={onNavigateToSlackIntegration}
			/>
			<NotificationsConfigConfirmationModal
				isOpen={isModalOpen}
				changedNotificationIds={changedNotificationIds}
				onCancel={() => setIsModalOpen(false)}
				isUpdating={isUpdatingNotifications}
				onConfirm={onSaveConfirm}
				serverNotifications={serverNotifications}
				notifications={notifications}
			/>
		</div>
	)
}
