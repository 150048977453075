import { AgChartsReact } from 'ag-charts-react'
import { AgChartOptions } from 'ag-charts-enterprise'
import { noDataOverlay, noDataOverlayComponent } from './helpers.tsx'
import { useMemo } from 'react'
import { PriorityType, PriorityTypeMap } from '../schemas/issue.ts'
import { getPriorityColor, getPriorityColorWithOpacity } from '../tables/helpers.ts'
import { EnvironmentTypeMap } from '../schemas/envType.ts'
import { useFindingsDistByEnv } from '../api/metrics.ts'
import { Skeleton } from 'antd'
import { themeColors } from '../utils/colorUtils.ts'

interface FindingDistEnv {
	amount: number
	envType: string
	maxPriority: number
}

export const FindingsDistributionByEnvironmentTreeMapChart = () => {
	const { data: findingsDistByEnv, isLoading } = useFindingsDistByEnv()

	const normalizedData = useMemo(() => {
		if (!findingsDistByEnv) return []
		return findingsDistByEnv?.map((f) => ({
			amount: f.amount,
			envType: EnvironmentTypeMap[f.env_type as keyof typeof EnvironmentTypeMap],
			maxPriority: PriorityType[f.max_priority] as number,
		}))
	}, [findingsDistByEnv])

	const colorRangeNormalized = useMemo(() => {
		if (!normalizedData) return []
		const colors: string[] = []
		const colorNums: number[] = []
		for (const f of normalizedData) {
			const maxPriority = f.maxPriority
			if (maxPriority === -1) continue

			const colorNumExists = colorNums.find((c) => c === maxPriority)
			if (!colorNumExists) {
				colorNums.push(maxPriority)
			}
		}
		for (const colorNum of colorNums.sort((a, b) => a - b)) {
			colors.push(getPriorityColorWithOpacity(colorNum))
		}
		return colors
	}, [normalizedData])

	if (isLoading) {
		return <Skeleton active />
	}

	const options: AgChartOptions = {
		data: normalizedData?.length
			? [
					{
						children: normalizedData,
					},
				]
			: undefined,
		background: {
			fill: themeColors.neutral[100],
		},
		series: [
			{
				group: {
					fill: themeColors.neutral[100],
					stroke: themeColors.neutral[100],
				},
				type: 'treemap',
				labelKey: 'envType',
				tooltip: {
					renderer: (params) => {
						const datum = params.datum as FindingDistEnv
						if (!datum) {
							return { title: 'No data', content: 'No details available' }
						}

						// Check if maxPriorityKey is a valid key of PriorityTypeMap
						const priorityValue =
							typeof datum.maxPriority === 'number'
								? PriorityTypeMap[datum.maxPriority as PriorityType]
								: null
						if (!priorityValue) {
							return {}
						}

						return {
							title: `${datum.envType}`,
							content: `Amount: ${datum.amount}<br>Priority: ${priorityValue}`,
							backgroundColor: getPriorityColor(datum.maxPriority as unknown as PriorityType),
						}
					},
				},
				secondaryLabelKey: 'amount',
				sizeName: 'Amount',
				sizeKey: 'amount',
				colorName: 'Priority',
				colorKey: 'maxPriority',
				colorRange: colorRangeNormalized,
				tile: {
					strokeWidth: 1,
					stroke: '#E5E7EB',
					gap: 8,
					label: {
						fontSize: 24,
						spacing: 8,
					},
					secondaryLabel: {
						formatter: (params) => `${params.value}`,
						fontSize: 16,
					},
				},
			},
		],
		gradientLegend: {
			enabled: false,
		},
		overlays: {
			noData: {
				renderer: noDataOverlay,
			},
		},
	}

	return normalizedData?.length ? (
		<AgChartsReact options={options} />
	) : (
		<div className="flex justify-center items-center h-full">{noDataOverlayComponent()}</div>
	)
}
