import { Modal } from 'antd'
import { IssueImpactProbabilityWithCount, IssueNameMap, ResourceTypeMap } from '../../../schemas/issue.ts'
import { IssueSourceIcon } from '../../../components/common/IssueSourceIcon.tsx'
import { ArrowRightOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { keyBy } from 'lodash'
import { PrioritySelectOption } from './PrioritySelectOption.tsx'
import { IssueIcon } from '../../../components/common/IssueIcon.tsx'

const EnabledDisabledText = ({ isEnabled }: { isEnabled: boolean }) => {
	const className = cx({ 'text-green-800': isEnabled, 'text-red-800': !isEnabled })
	return <span className={className}>{isEnabled ? 'Enabled' : 'Disabled'}</span>
}

const IssueConfigChangeRow = ({
	issueType,
	serverIssueType,
}: {
	issueType: IssueImpactProbabilityWithCount
	serverIssueType: IssueImpactProbabilityWithCount
}) => {
	const enabledChanged = issueType.ignore_issue !== serverIssueType.ignore_issue
	const priorityChanged = issueType.priority !== serverIssueType.priority
	return (
		<div className="flex flex-col gap-2 py-3">
			<div className="flex gap-3 items-center">
				<div className="flex gap-1 items-center">
					<IssueIcon name={issueType.issue_name} />
					<span className="text-neutral-700">{IssueNameMap[issueType.issue_name]}</span>
				</div>
				<div className="flex gap-1 items-center">
					<IssueSourceIcon source={issueType.issue_source} size={20} />
					<span className="text-neutral-700">{ResourceTypeMap[issueType.issue_source]}</span>
				</div>
			</div>
			<div className="flex items-center divide-x-2">
				{enabledChanged && (
					<div className={cx('flex items-center gap-1', { 'pr-4': priorityChanged })}>
						<EnabledDisabledText isEnabled={!serverIssueType.ignore_issue} />
						<ArrowRightOutlined />
						<EnabledDisabledText isEnabled={!issueType.ignore_issue} />
					</div>
				)}
				{priorityChanged && (
					<div className={cx('flex items-center gap-1', { 'pl-4': enabledChanged })}>
						<span className="text-zinc-500 semibold">Priority:</span>
						<PrioritySelectOption priority={serverIssueType.priority ?? 0} withBorder />
						<ArrowRightOutlined />
						<PrioritySelectOption priority={issueType.priority ?? 0} withBorder />
					</div>
				)}
			</div>
		</div>
	)
}

type IssueConfigConfirmationModalProps = {
	isOpen: boolean
	issueTypes: IssueImpactProbabilityWithCount[]
	changedIssueTypeIds: Set<string>
	onCancel: () => void
	onConfirm: () => void
	isUpdating?: boolean
	serverIssueTypes?: IssueImpactProbabilityWithCount[]
}

export const IssueConfigConfirmationModal = ({
	isOpen,
	issueTypes,
	changedIssueTypeIds,
	onConfirm,
	onCancel,
	isUpdating,
	serverIssueTypes,
}: IssueConfigConfirmationModalProps) => {
	const [serverIssueTypesById, setServerIssueTypesById] = useState<Record<string, IssueImpactProbabilityWithCount>>(
		serverIssueTypes ? keyBy(serverIssueTypes, 'id') : {},
	)
	useEffect(() => {
		if (serverIssueTypes) {
			setServerIssueTypesById(keyBy(serverIssueTypes, 'id'))
		}
	}, [serverIssueTypes])
	return (
		<Modal
			width="42%"
			okButtonProps={{ className: 'bg-black', loading: isUpdating }}
			open={isOpen}
			title="Save Changes"
			okType="primary"
			okText="Save"
			onCancel={onCancel}
			onOk={onConfirm}
		>
			<div>Are you sure you want to save the following changes?</div>
			<div className="overflow-y-auto max-h-[50vh] divide-y pr-2">
				{issueTypes
					.filter((issueType) => changedIssueTypeIds.has(issueType.id))
					.map((issueType) => (
						<IssueConfigChangeRow
							issueType={issueType}
							key={issueType.id}
							serverIssueType={serverIssueTypesById[issueType.id]}
						/>
					))}
			</div>
		</Modal>
	)
}
