import React, { useEffect, useState } from 'react'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { ServerEntraIdUser } from '../../../../schemas/identities/entraId/entraIdUserSchema.ts'
import { BaseIdentityDetail } from '../baseIdentities/BaseIdentityDetail.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'
import { AwsIamRoleContext } from '../AwsIamRoleContext.tsx'
import { Col, Divider, Row, Space, Tag, Tooltip } from 'antd'
import { EnvironmentType } from '../../../../schemas/envType.ts'
import { GcpProjectsContext } from '../gcp/GcpProjectsContext.tsx'
import { AzureRoleAssignmentsContext } from '../azure/azureRBAC/AzureRoleAssignmentsContext.tsx'
import { EntraIdChangeLogsContext } from './EntraIdChangeLogsContext.tsx'
import { AzureUsageLogsContext } from '../azure/AzureUsageLogsContext.tsx'

export const EntraIdUserIdentity: React.FunctionComponent<{
	identity?: ServerIdentity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContentAmount] = useState(0)
	const entraIdUser: ServerEntraIdUser | null | undefined = identity?.entra_id_user
	useEffect(() => {
		calcAmountOfContext()
	})

	const calcAmountOfContext = () => {
		let amount = 0
		if (entraIdUser?.aws_iam_roles_xc?.length) amount++
		if (entraIdUser?.gcp_projects_xc?.length) amount++
		if (entraIdUser?.azure_role_assignments_xc?.length) amount++
		if (entraIdUser?.change_logs?.length) amount++
		if (entraIdUser?.usage_logs?.length) amount++
		setContentAmount(amount)
	}
	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				{entraIdUser?.mail ? <BaseIdentityDetail label="Email" value={entraIdUser?.mail} span={1} /> : null}
				<BaseIdentityDetail label="Entra User Id" value={entraIdUser?.entra_user_id} span={1} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>

				<BaseIdentityDetail label="Environment" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.account_literal}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.account_literal_friendly_name ?? identity?.account_literal}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{entraIdUser?.given_name ? (
					<BaseIdentityDetail label="Name" value={entraIdUser?.given_name} span={1} />
				) : null}
				<BaseIdentityDetail label="User Principal Name" value={entraIdUser?.user_principal_name} span={1} />
				{entraIdUser?.job_title ? (
					<BaseIdentityDetail label="Job Title" value={entraIdUser?.job_title} span={1} />
				) : null}
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
				{entraIdUser?.last_activity ? (
					<BaseIdentityDetail
						label="Last Login"
						value={formatRelativeDateText(entraIdUser?.last_activity, true)}
						tooltipTitle={formatDate(entraIdUser?.last_activity)}
						span={1}
					/>
				) : null}
				{entraIdUser?.password_last_changed ? (
					<BaseIdentityDetail
						label="Last Password Change"
						value={formatRelativeDateText(entraIdUser?.password_last_changed, true)}
						tooltipTitle={formatDate(entraIdUser?.password_last_changed)}
						span={1}
					/>
				) : null}
				{entraIdUser?.account_enabled !== null ? (
					<BaseIdentityDetail label="Account status" span={1}>
						<Tag>
							<div className="flex items-center">
								<div
									className={`${entraIdUser?.account_enabled ? 'text-green-700' : 'text-gray-700'} text font-medium`}
								>
									{entraIdUser?.account_enabled ? 'Enabled' : 'Disabled'}
								</div>
							</div>
						</Tag>
					</BaseIdentityDetail>
				) : null}
			</div>
			<div className="w-full">
				{contextAmount > 0 && (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdUser?.aws_iam_roles_xc && entraIdUser?.aws_iam_roles_xc.length > 0 && (
									<AwsIamRoleContext awsIamRolesXc={entraIdUser?.aws_iam_roles_xc} />
								)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdUser?.gcp_projects_xc && entraIdUser?.gcp_projects_xc?.length > 0 && (
									<GcpProjectsContext gcpProjectsXc={entraIdUser?.gcp_projects_xc} />
								)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{entraIdUser?.azure_role_assignments_xc &&
									entraIdUser?.azure_role_assignments_xc?.length > 0 && (
										<AzureRoleAssignmentsContext
											azureRoleAssignmentsXc={entraIdUser.azure_role_assignments_xc}
										/>
									)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{identity && entraIdUser?.change_logs && entraIdUser?.change_logs?.length > 0 && (
									<EntraIdChangeLogsContext
										changeLogs={entraIdUser.change_logs}
										identity={identity}
									/>
								)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{identity && entraIdUser?.usage_logs && entraIdUser?.usage_logs?.length > 0 && (
									<AzureUsageLogsContext usageLogs={entraIdUser.usage_logs} identity={identity} />
								)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
