import { z } from 'zod'

export const ServerAzureUsageLogSchema = z.object({
	caller: z.string(), // Caller field (lowercased in processing)
	association: z.string().nullable(), // Association, nullable
	ip: z.string(), // IP Address field
	day: z.string(), // Date in string format
	operations: z.array(z.string()), // Array of operations performed
	operations_count: z.number(), // Count of operations performed
})

export type ServerAzureUsageLog = z.infer<typeof ServerAzureUsageLogSchema>
