import { z } from 'zod'
import { ServerKubernetesResourceAccessKeysSchema } from './kubernetesResourceAccessKeysSchema.ts'

export const ServerKubernetesResourceXcSchema = z.object({
	api_version: z.string().nullish(),
	metadata: z.record(z.string(), z.unknown()).nullish(),
	kind: z.string().nullish(),
	spec: z.record(z.string(), z.unknown()).nullish(),
	status: z.any().nullish(),
	pod_to_iam_access_keys: z.array(ServerKubernetesResourceAccessKeysSchema).nullish(),
	cluster_literal: z.string().nullish(),
	rules: z.array(z.unknown()).nullish(),
	subjects: z.array(z.unknown()).nullish(),
	role_ref: z.record(z.string(), z.unknown()).nullish(),
})

export type ServerKubernetesResourceXc = z.infer<typeof ServerKubernetesResourceXcSchema>
