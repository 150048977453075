import { Node, NodeProps } from '@xyflow/react'
import { TeamOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { ServerDetailedAzureRoleAssignment } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { BaseNode } from '../../common/baseNodes/BaseNode.tsx'
import { NodeGroupBadge } from '../../common/NodeGroupBadge.tsx'
import { useHighlightedNodesContext } from '../../../HighlightedNodesContext.tsx'

export type EnrichedAzureRolesNodeType = Node<{ roles: ServerDetailedAzureRoleAssignment[] }, 'enrichedAzureRoles'>
type AzureRolesNodeProps = NodeProps<EnrichedAzureRolesNodeType>

export const EnrichedAzureRolesNode = ({ data: { roles } }: AzureRolesNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((highlightedNode) => highlightedNode.type === 'enrichedAzureRole')
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	const groupBadgeTooltipContent = roles.map((role) => (
		<span key={`${role.role_definition.id}${role.role_assignment.id}`}>
			{role.role_definition.role_name || 'Unknown role'}
		</span>
	))

	return (
		<BaseNode label="Azure Roles">
			<NodeGroupBadge items={roles} nodeColorType="permission" tooltipContent={groupBadgeTooltipContent} />
			<div className={iconWrapperClassName}>
				<TeamOutlined />
			</div>
		</BaseNode>
	)
}
