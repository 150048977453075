import { z } from 'zod'
import { DateSchema } from '../../common.ts'
import { TagSchema } from '../../tags.ts'

export const ServerSnowflakeRoleXcSchema = z.object({
	created_on: DateSchema,
	name: z.string(),
	is_default: z.string(),
	is_current: z.string(),
	is_inherited: z.string(),
	assigned_to_users: z.number(),
	granted_to_roles: z.number(),
	granted_roles: z.number(),
	owner: z.string(),
	comment: z.string(),
	direct_user_assignment: z.boolean(),
	tags: z.array(TagSchema).nullish(),
})

export type ServerSnowflakeRoleXc = z.infer<typeof ServerSnowflakeRoleXcSchema>
