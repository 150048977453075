import { AdminPortal, useEntitlements, useLoginWithRedirect, useRolesState } from '@frontegg/react'
import { useAuth as useFronteggAuth, useAuthActions as useFronteggAuthActions } from '@frontegg/react-hooks/auth'
import { useQuery } from '@tanstack/react-query'
import { getFronteggUsers } from './fronteggApi.ts'
import { keyBy } from 'lodash'
import { FeatureFlags } from './featureFlags.ts'
import { useMemo } from 'react'
import { isDemo } from '../../utils/demoUtils.ts'

export const useAuth = () => {
	const { isAuthenticated, isLoading, user: fronteggUser, profileState, tenantsState } = useFronteggAuth()
	const { roles: fronteggRoles, permissions: fronteggPermissions } = useRolesState()

	// We should map these to our models
	const user = fronteggUser!
	const roles = fronteggRoles
	const permissions = fronteggPermissions
	const activeTenant = tenantsState.activeTenant
	const isAdmin = !!user.roles.find((role) => role.name === 'Admin')
	const isImpersonating = user.act !== undefined

	return {
		isAuthenticated,
		isLoading,
		user,
		roles,
		permissions,
		profileState,
		tenantsState,
		activeTenant,
		isAdmin,
		isImpersonating,
	}
}

export const useAuthActions = () => {
	const { logout, switchTenant } = useFronteggAuthActions()
	const loginWithRedirect = useLoginWithRedirect()
	const showAdminPortal = () => AdminPortal.show()

	return { logout, loginWithRedirect, showAdminPortal, switchTenant }
}

export const useFronteggUsers = () => {
	const { user, activeTenant } = useAuth()
	const tenantId = activeTenant?.tenantId
	const queryKey = ['FronteggUsers', user.id, tenantId]
	const queryFn = () => {
		return getFronteggUsers(user.accessToken, tenantId)
	}

	return useQuery(queryKey, queryFn, { select: (data) => keyBy(data, 'id'), staleTime: Infinity })
}

export const useEntitlementToIdentityPage = () => {
	return useEntitlements({ featureKey: FeatureFlags.IDENTITY_PAGE })
}

export const useEntitlementToShowJiraButton = () => {
	return useEntitlements({ featureKey: FeatureFlags.SHOW_JIRA_BUTTON })
}

export const useEntitlementToShowNewUsageTable = () => {
	return useEntitlements({ featureKey: FeatureFlags.NEW_USAGE_TABLE })
}

export const useEntitlementToIntegrationsPage = () => {
	return useEntitlements({ featureKey: FeatureFlags.INTEGRATIONS_PAGE })
}

export const useEntitlementToMetricsPageV2 = () => {
	const { isEntitled: isEntitledByFeatureFlag } = useEntitlements({ featureKey: FeatureFlags.METRICS_PAGE_V2 })
	const value: { isEntitled: boolean } = useMemo(
		() => ({ isEntitled: isDemo || isEntitledByFeatureFlag }),
		[isEntitledByFeatureFlag],
	)

	return value
}

export const useEntitlementToSettingsPage = () => {
	const { isEntitled } = useEntitlements({ featureKey: FeatureFlags.SETTINGS_PAGE })
	const { isAdmin } = useAuth()

	const value: { isEntitled: boolean } = useMemo(() => ({ isEntitled: isEntitled && isAdmin }), [isEntitled, isAdmin])
	return value
}

export const useEntitlementToPaginatedInventory = () => {
	const { isEntitled: isEntitledByFeatureFlag } = useEntitlements({ featureKey: FeatureFlags.PAGINATED_INVENTORY })
	const value: { isEntitled: boolean } = useMemo(
		() => ({ isEntitled: isEntitledByFeatureFlag }),
		[isEntitledByFeatureFlag],
	)

	return value
}

export const useEntitlementToSwagger = () => {
	const { isEntitled: isEntitledByFeatureFlag } = useEntitlements({ featureKey: FeatureFlags.SHOW_SWAGGER_API })
	const value: { isEntitled: boolean } = useMemo(
		() => ({ isEntitled: isEntitledByFeatureFlag }),
		[isEntitledByFeatureFlag],
	)

	return value
}
export const useEntitlementToOwnersColumn = () => {
	return useEntitlements({ featureKey: FeatureFlags.SHOW_OWNERS_COLUMN })
}

export const useEntitlementToInventoryFilterChips = () => {
	const { isEntitled: isEntitledByFeatureFlag } = useEntitlements({ featureKey: FeatureFlags.INVENTORY_FILTER_CHIPS })
	const { isEntitled: isEntitledToPaginatedInventory } = useEntitlementToPaginatedInventory()
	const value: {
		isEntitled: boolean
	} = useMemo(
		() => ({ isEntitled: isEntitledByFeatureFlag && isEntitledToPaginatedInventory }),
		[isEntitledByFeatureFlag, isEntitledToPaginatedInventory],
	)

	return value
}
