export const kubernetesResourcesXc1 = [
	{
		api_version: null,
		kind: 'DaemonSet',
		metadata: {
			uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
			name: 'my-daemonset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'deprecated.daemonset.template.generation': '1',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"DaemonSet","metadata":{"annotations":{},"name":"my-daemonset","namespace":"production"},"spec":{"selector":{"matchLabels":{"name":"my-daemonset"}},"template":{"metadata":{"labels":{"name":"my-daemonset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-daemonset-container","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:53:42+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-daemonset-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:revisionHistoryLimit': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:deprecated.daemonset.template.generation': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:45+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:numberReady': {},
							'f:numberAvailable': {},
							'f:observedGeneration': {},
							'f:currentNumberScheduled': {},
							'f:desiredNumberScheduled': {},
							'f:updatedNumberScheduled': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754187',
			creation_timestamp: '2024-03-11 08:53:42+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			selector: {
				match_labels: {
					name: 'my-daemonset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-daemonset-container',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-daemonset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: 0,
					max_unavailable: 1,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
		},
		status: {
			conditions: null,
			number_ready: 2,
			collision_count: null,
			number_available: 2,
			number_unavailable: null,
			number_misscheduled: 0,
			observed_generation: 1,
			current_number_scheduled: 2,
			desired_number_scheduled: 2,
			updated_number_scheduled: 2,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-daemonset-m2d6j',
				container_name: 'my-daemonset-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
						kind: 'DaemonSet',
						name: 'my-daemonset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'StatefulSet',
		metadata: {
			uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
			name: 'my-statefulset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"StatefulSet","metadata":{"annotations":{},"name":"my-statefulset","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"name":"my-statefulset"}},"serviceName":"my-statefulset","template":{"metadata":{"labels":{"name":"my-statefulset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-statefulset","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:50:09+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-statefulset"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:serviceName': {},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:partition': {},
								},
							},
							'f:podManagementPolicy': {},
							'f:revisionHistoryLimit': {},
							'f:persistentVolumeClaimRetentionPolicy': {
								'.': {},
								'f:whenScaled': {},
								'f:whenDeleted': {},
							},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 09:03:21+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:readyReplicas': {},
							'f:collisionCount': {},
							'f:updateRevision': {},
							'f:currentReplicas': {},
							'f:currentRevision': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41700785',
			creation_timestamp: '2024-03-11 08:50:09+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			ordinals: null,
			replicas: 1,
			selector: {
				match_labels: {
					name: 'my-statefulset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-statefulset',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-statefulset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			service_name: 'my-statefulset',
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					partition: 0,
					max_unavailable: null,
				},
			},
			min_ready_seconds: null,
			pod_management_policy: 'OrderedReady',
			revision_history_limit: 10,
			volume_claim_templates: null,
			persistent_volume_claim_retention_policy: {
				when_scaled: 'Retain',
				when_deleted: 'Retain',
			},
		},
		status: {
			replicas: 1,
			conditions: null,
			ready_replicas: 1,
			collision_count: 0,
			update_revision: 'my-statefulset-76b644766c',
			current_replicas: 1,
			current_revision: 'my-statefulset-76b644766c',
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-statefulset-0',
				container_name: 'my-statefulset',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
						kind: 'StatefulSet',
						name: 'my-statefulset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			name: 'ubuntu-deployment',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 7,
			annotations: {
				'deployment.kubernetes.io/revision': '7',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"ubuntu-deployment","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"test","value":"AKIA2ODYTOW1MU6FF1P4"},{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"production-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-25 16:08:59+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"test"}': {
													'.': {},
													'f:name': {},
													'f:value': {},
												},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-21 03:00:40+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41862200',
			creation_timestamp: '2023-10-05 09:15:30+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'test',
									value: 'AKIA2ODYTOW1MU6FF1P4',
									value_from: null,
								},
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "ubuntu-deployment-85688f7765" has successfully progressed.',
					last_update_time: '2024-03-25 16:09:01+00:00',
					last_transition_time: '2023-10-12 12:11:57+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-21 03:00:40+00:00',
					last_transition_time: '2024-04-21 03:00:40+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 7,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: null,
				pod_name: 'ubuntu-deployment-85688f7765-hfj8f',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: null,
				pod_owner_references: [
					{
						uid: '50da299b-47f4-4e28-a810-5ceccd0cde20',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-85688f7765',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: true,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
			name: 'ubuntu-deployment-helm',
			labels: {
				'app.kubernetes.io/managed-by': 'Helm',
			},
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'meta.helm.sh/release-name': 'my-first-helm',
				'meta.helm.sh/release-namespace': 'production',
				'deployment.kubernetes.io/revision': '1',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 14:42:39+00:00',
					manager: 'helm',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:labels': {
								'.': {},
								'f:app.kubernetes.io/managed-by': {},
							},
							'f:annotations': {
								'.': {},
								'f:meta.helm.sh/release-name': {},
								'f:meta.helm.sh/release-namespace': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:52+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754213',
			creation_timestamp: '2024-03-11 14:42:39+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu-helm',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu-helm',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "ubuntu-deployment-helm-775fd6dd66" has successfully progressed.',
					last_update_time: '2024-03-11 14:46:41+00:00',
					last_transition_time: '2024-03-11 14:42:39+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-20 14:59:52+00:00',
					last_transition_time: '2024-04-20 14:59:52+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'ubuntu-deployment-helm-775fd6dd66-v5tw8',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '1e0d46dc-d809-42bd-9e37-404ed4b7da67',
						kind: 'ReplicaSet',
						name: 'ubuntu-deployment-helm-775fd6dd66',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
]

export const kubernetesResourcesXc2 = [
	{
		api_version: null,
		kind: 'DaemonSet',
		metadata: {
			uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
			name: 'my-daemonset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'deprecated.daemonset.template.generation': '1',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"DaemonSet","metadata":{"annotations":{},"name":"my-daemonset","namespace":"production"},"spec":{"selector":{"matchLabels":{"name":"my-daemonset"}},"template":{"metadata":{"labels":{"name":"my-daemonset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-daemonset-container","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:53:42+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-daemonset-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:revisionHistoryLimit': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:deprecated.daemonset.template.generation': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:45+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:numberReady': {},
							'f:numberAvailable': {},
							'f:observedGeneration': {},
							'f:currentNumberScheduled': {},
							'f:desiredNumberScheduled': {},
							'f:updatedNumberScheduled': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754187',
			creation_timestamp: '2024-03-11 08:53:42+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			selector: {
				match_labels: {
					name: 'my-daemonset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-daemonset-container',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-daemonset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: 0,
					max_unavailable: 1,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
		},
		status: {
			conditions: null,
			number_ready: 2,
			collision_count: null,
			number_available: 2,
			number_unavailable: null,
			number_misscheduled: 0,
			observed_generation: 1,
			current_number_scheduled: 2,
			desired_number_scheduled: 2,
			updated_number_scheduled: 2,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-daemonset-m2d6j',
				container_name: 'my-daemonset-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
						kind: 'DaemonSet',
						name: 'my-daemonset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'StatefulSet',
		metadata: {
			uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
			name: 'my-statefulset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"StatefulSet","metadata":{"annotations":{},"name":"my-statefulset","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"name":"my-statefulset"}},"serviceName":"my-statefulset","template":{"metadata":{"labels":{"name":"my-statefulset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-statefulset","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:50:09+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-statefulset"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:serviceName': {},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:partition': {},
								},
							},
							'f:podManagementPolicy': {},
							'f:revisionHistoryLimit': {},
							'f:persistentVolumeClaimRetentionPolicy': {
								'.': {},
								'f:whenScaled': {},
								'f:whenDeleted': {},
							},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 09:03:21+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:readyReplicas': {},
							'f:collisionCount': {},
							'f:updateRevision': {},
							'f:currentReplicas': {},
							'f:currentRevision': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41700785',
			creation_timestamp: '2024-03-11 08:50:09+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			ordinals: null,
			replicas: 1,
			selector: {
				match_labels: {
					name: 'my-statefulset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-statefulset',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-statefulset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			service_name: 'my-statefulset',
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					partition: 0,
					max_unavailable: null,
				},
			},
			min_ready_seconds: null,
			pod_management_policy: 'OrderedReady',
			revision_history_limit: 10,
			volume_claim_templates: null,
			persistent_volume_claim_retention_policy: {
				when_scaled: 'Retain',
				when_deleted: 'Retain',
			},
		},
		status: {
			replicas: 1,
			conditions: null,
			ready_replicas: 1,
			collision_count: 0,
			update_revision: 'my-statefulset-76b644766c',
			current_replicas: 1,
			current_revision: 'my-statefulset-76b644766c',
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-statefulset-0',
				container_name: 'my-statefulset',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
						kind: 'StatefulSet',
						name: 'my-statefulset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			name: 'test-deployment',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 7,
			annotations: {
				'deployment.kubernetes.io/revision': '7',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"test-deployment","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"test","value":"AKIA2ODYTOW1MU6FF1P4"},{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"production-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-25 16:08:59+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"test"}': {
													'.': {},
													'f:name': {},
													'f:value': {},
												},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-21 03:00:40+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41862200',
			creation_timestamp: '2023-10-05 09:15:30+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'test',
									value: 'AKIA2ODYTOW1MU6FF1P4',
									value_from: null,
								},
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "test-deployment-85688f7765" has successfully progressed.',
					last_update_time: '2024-03-25 16:09:01+00:00',
					last_transition_time: '2023-10-12 12:11:57+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-21 03:00:40+00:00',
					last_transition_time: '2024-04-21 03:00:40+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 7,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: null,
				pod_name: 'test-deployment-85688f7765-hfj8f',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: null,
				pod_owner_references: [
					{
						uid: '50da299b-47f4-4e28-a810-5ceccd0cde20',
						kind: 'ReplicaSet',
						name: 'test-deployment-85688f7765',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: true,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
			name: 'app-deployment-helm',
			labels: {
				'app.kubernetes.io/managed-by': 'Helm',
			},
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'meta.helm.sh/release-name': 'my-first-helm',
				'meta.helm.sh/release-namespace': 'production',
				'deployment.kubernetes.io/revision': '1',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 14:42:39+00:00',
					manager: 'helm',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:labels': {
								'.': {},
								'f:app.kubernetes.io/managed-by': {},
							},
							'f:annotations': {
								'.': {},
								'f:meta.helm.sh/release-name': {},
								'f:meta.helm.sh/release-namespace': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:52+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754213',
			creation_timestamp: '2024-03-11 14:42:39+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu-helm',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu-helm',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "app-deployment-helm-775fd6dd66" has successfully progressed.',
					last_update_time: '2024-03-11 14:46:41+00:00',
					last_transition_time: '2024-03-11 14:42:39+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-20 14:59:52+00:00',
					last_transition_time: '2024-04-20 14:59:52+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'app-deployment-helm-775fd6dd66-v5tw8',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '1e0d46dc-d809-42bd-9e37-404ed4b7da67',
						kind: 'ReplicaSet',
						name: 'app-deployment-helm-775fd6dd66',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
]

export const kubernetesResourcesXc3 = [
	{
		api_version: null,
		kind: 'DaemonSet',
		metadata: {
			uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
			name: 'my-daemonset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'deprecated.daemonset.template.generation': '1',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"DaemonSet","metadata":{"annotations":{},"name":"my-daemonset","namespace":"production"},"spec":{"selector":{"matchLabels":{"name":"my-daemonset"}},"template":{"metadata":{"labels":{"name":"my-daemonset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-daemonset-container","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:53:42+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-daemonset-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:revisionHistoryLimit': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:deprecated.daemonset.template.generation': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:45+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:numberReady': {},
							'f:numberAvailable': {},
							'f:observedGeneration': {},
							'f:currentNumberScheduled': {},
							'f:desiredNumberScheduled': {},
							'f:updatedNumberScheduled': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754187',
			creation_timestamp: '2024-03-11 08:53:42+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			selector: {
				match_labels: {
					name: 'my-daemonset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-daemonset-container',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-daemonset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: 0,
					max_unavailable: 1,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
		},
		status: {
			conditions: null,
			number_ready: 2,
			collision_count: null,
			number_available: 2,
			number_unavailable: null,
			number_misscheduled: 0,
			observed_generation: 1,
			current_number_scheduled: 2,
			desired_number_scheduled: 2,
			updated_number_scheduled: 2,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-daemonset-m2d6j',
				container_name: 'my-daemonset-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
						kind: 'DaemonSet',
						name: 'my-daemonset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'StatefulSet',
		metadata: {
			uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
			name: 'my-statefulset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"StatefulSet","metadata":{"annotations":{},"name":"my-statefulset","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"name":"my-statefulset"}},"serviceName":"my-statefulset","template":{"metadata":{"labels":{"name":"my-statefulset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-statefulset","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:50:09+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-statefulset"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:serviceName': {},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:partition': {},
								},
							},
							'f:podManagementPolicy': {},
							'f:revisionHistoryLimit': {},
							'f:persistentVolumeClaimRetentionPolicy': {
								'.': {},
								'f:whenScaled': {},
								'f:whenDeleted': {},
							},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 09:03:21+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:readyReplicas': {},
							'f:collisionCount': {},
							'f:updateRevision': {},
							'f:currentReplicas': {},
							'f:currentRevision': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41700785',
			creation_timestamp: '2024-03-11 08:50:09+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			ordinals: null,
			replicas: 1,
			selector: {
				match_labels: {
					name: 'my-statefulset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-statefulset',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-statefulset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			service_name: 'my-statefulset',
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					partition: 0,
					max_unavailable: null,
				},
			},
			min_ready_seconds: null,
			pod_management_policy: 'OrderedReady',
			revision_history_limit: 10,
			volume_claim_templates: null,
			persistent_volume_claim_retention_policy: {
				when_scaled: 'Retain',
				when_deleted: 'Retain',
			},
		},
		status: {
			replicas: 1,
			conditions: null,
			ready_replicas: 1,
			collision_count: 0,
			update_revision: 'my-statefulset-76b644766c',
			current_replicas: 1,
			current_revision: 'my-statefulset-76b644766c',
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-statefulset-0',
				container_name: 'my-statefulset',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
						kind: 'StatefulSet',
						name: 'my-statefulset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			name: 'payment-deployment',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 7,
			annotations: {
				'deployment.kubernetes.io/revision': '7',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"payment-deployment","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"test","value":"AKIA2ODYTOW1MU6FF1P4"},{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"production-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-25 16:08:59+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"test"}': {
													'.': {},
													'f:name': {},
													'f:value': {},
												},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-21 03:00:40+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41862200',
			creation_timestamp: '2023-10-05 09:15:30+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'test',
									value: 'AKIA2ODYTOW1MU6FF1P4',
									value_from: null,
								},
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "payment-deployment-85688f7765" has successfully progressed.',
					last_update_time: '2024-03-25 16:09:01+00:00',
					last_transition_time: '2023-10-12 12:11:57+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-21 03:00:40+00:00',
					last_transition_time: '2024-04-21 03:00:40+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 7,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: null,
				pod_name: 'payment-deployment-85688f7765-hfj8f',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: null,
				pod_owner_references: [
					{
						uid: '50da299b-47f4-4e28-a810-5ceccd0cde20',
						kind: 'ReplicaSet',
						name: 'payment-deployment-85688f7765',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: true,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
			name: 'clearing-deployment-helm',
			labels: {
				'app.kubernetes.io/managed-by': 'Helm',
			},
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'meta.helm.sh/release-name': 'my-first-helm',
				'meta.helm.sh/release-namespace': 'production',
				'deployment.kubernetes.io/revision': '1',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 14:42:39+00:00',
					manager: 'helm',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:labels': {
								'.': {},
								'f:app.kubernetes.io/managed-by': {},
							},
							'f:annotations': {
								'.': {},
								'f:meta.helm.sh/release-name': {},
								'f:meta.helm.sh/release-namespace': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:52+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754213',
			creation_timestamp: '2024-03-11 14:42:39+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu-helm',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu-helm',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "clearing-deployment-helm-775fd6dd66" has successfully progressed.',
					last_update_time: '2024-03-11 14:46:41+00:00',
					last_transition_time: '2024-03-11 14:42:39+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-20 14:59:52+00:00',
					last_transition_time: '2024-04-20 14:59:52+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'clearing-deployment-helm-775fd6dd66-v5tw8',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '1e0d46dc-d809-42bd-9e37-404ed4b7da67',
						kind: 'ReplicaSet',
						name: 'clearing-deployment-helm-775fd6dd66',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
			name: 'scheduler',
			labels: {
				'app.kubernetes.io/managed-by': 'Helm',
			},
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'meta.helm.sh/release-name': 'my-first-helm',
				'meta.helm.sh/release-namespace': 'production',
				'deployment.kubernetes.io/revision': '1',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 14:42:39+00:00',
					manager: 'helm',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:labels': {
								'.': {},
								'f:app.kubernetes.io/managed-by': {},
							},
							'f:annotations': {
								'.': {},
								'f:meta.helm.sh/release-name': {},
								'f:meta.helm.sh/release-namespace': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:52+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754213',
			creation_timestamp: '2024-03-11 14:42:39+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu-helm',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu-helm',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "scheduler-775fd6dd66" has successfully progressed.',
					last_update_time: '2024-03-11 14:46:41+00:00',
					last_transition_time: '2024-03-11 14:42:39+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-20 14:59:52+00:00',
					last_transition_time: '2024-04-20 14:59:52+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'scheduler-775fd6dd66-v5tw8',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '1e0d46dc-d809-42bd-9e37-404ed4b7da67',
						kind: 'ReplicaSet',
						name: 'scheduler-775fd6dd66',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
]

export const kubernetesResourcesXc4 = [
	{
		api_version: null,
		kind: 'DaemonSet',
		metadata: {
			uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
			name: 'my-daemonset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'deprecated.daemonset.template.generation': '1',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"DaemonSet","metadata":{"annotations":{},"name":"my-daemonset","namespace":"production"},"spec":{"selector":{"matchLabels":{"name":"my-daemonset"}},"template":{"metadata":{"labels":{"name":"my-daemonset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-daemonset-container","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:53:42+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-daemonset-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:revisionHistoryLimit': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:deprecated.daemonset.template.generation': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:45+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:numberReady': {},
							'f:numberAvailable': {},
							'f:observedGeneration': {},
							'f:currentNumberScheduled': {},
							'f:desiredNumberScheduled': {},
							'f:updatedNumberScheduled': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754187',
			creation_timestamp: '2024-03-11 08:53:42+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			selector: {
				match_labels: {
					name: 'my-daemonset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-daemonset-container',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-daemonset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: 0,
					max_unavailable: 1,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
		},
		status: {
			conditions: null,
			number_ready: 2,
			collision_count: null,
			number_available: 2,
			number_unavailable: null,
			number_misscheduled: 0,
			observed_generation: 1,
			current_number_scheduled: 2,
			desired_number_scheduled: 2,
			updated_number_scheduled: 2,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-daemonset-m2d6j',
				container_name: 'my-daemonset-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
						kind: 'DaemonSet',
						name: 'my-daemonset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'StatefulSet',
		metadata: {
			uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
			name: 'my-statefulset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"StatefulSet","metadata":{"annotations":{},"name":"my-statefulset","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"name":"my-statefulset"}},"serviceName":"my-statefulset","template":{"metadata":{"labels":{"name":"my-statefulset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-statefulset","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:50:09+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-statefulset"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:serviceName': {},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:partition': {},
								},
							},
							'f:podManagementPolicy': {},
							'f:revisionHistoryLimit': {},
							'f:persistentVolumeClaimRetentionPolicy': {
								'.': {},
								'f:whenScaled': {},
								'f:whenDeleted': {},
							},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 09:03:21+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:readyReplicas': {},
							'f:collisionCount': {},
							'f:updateRevision': {},
							'f:currentReplicas': {},
							'f:currentRevision': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41700785',
			creation_timestamp: '2024-03-11 08:50:09+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			ordinals: null,
			replicas: 1,
			selector: {
				match_labels: {
					name: 'my-statefulset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-statefulset',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-statefulset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			service_name: 'my-statefulset',
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					partition: 0,
					max_unavailable: null,
				},
			},
			min_ready_seconds: null,
			pod_management_policy: 'OrderedReady',
			revision_history_limit: 10,
			volume_claim_templates: null,
			persistent_volume_claim_retention_policy: {
				when_scaled: 'Retain',
				when_deleted: 'Retain',
			},
		},
		status: {
			replicas: 1,
			conditions: null,
			ready_replicas: 1,
			collision_count: 0,
			update_revision: 'my-statefulset-76b644766c',
			current_replicas: 1,
			current_revision: 'my-statefulset-76b644766c',
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-statefulset-0',
				container_name: 'my-statefulset',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
						kind: 'StatefulSet',
						name: 'my-statefulset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			name: 'prometheus-deployment',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 7,
			annotations: {
				'deployment.kubernetes.io/revision': '7',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"prometheus-deployment","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"test","value":"AKIA2ODYTOW1MU6FF1P4"},{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"production-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-25 16:08:59+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"test"}': {
													'.': {},
													'f:name': {},
													'f:value': {},
												},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-21 03:00:40+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41862200',
			creation_timestamp: '2023-10-05 09:15:30+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'test',
									value: 'AKIA2ODYTOW1MU6FF1P4',
									value_from: null,
								},
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "prometheus-deployment-85688f7765" has successfully progressed.',
					last_update_time: '2024-03-25 16:09:01+00:00',
					last_transition_time: '2023-10-12 12:11:57+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-21 03:00:40+00:00',
					last_transition_time: '2024-04-21 03:00:40+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 7,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: null,
				pod_name: 'prometheus-deployment-85688f7765-hfj8f',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: null,
				pod_owner_references: [
					{
						uid: '50da299b-47f4-4e28-a810-5ceccd0cde20',
						kind: 'ReplicaSet',
						name: 'prometheus-deployment-85688f7765',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: true,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
			name: 'grafana-deployment-helm',
			labels: {
				'app.kubernetes.io/managed-by': 'Helm',
			},
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'meta.helm.sh/release-name': 'my-first-helm',
				'meta.helm.sh/release-namespace': 'production',
				'deployment.kubernetes.io/revision': '1',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 14:42:39+00:00',
					manager: 'helm',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:labels': {
								'.': {},
								'f:app.kubernetes.io/managed-by': {},
							},
							'f:annotations': {
								'.': {},
								'f:meta.helm.sh/release-name': {},
								'f:meta.helm.sh/release-namespace': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:52+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754213',
			creation_timestamp: '2024-03-11 14:42:39+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu-helm',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu-helm',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "grafana-deployment-helm-775fd6dd66" has successfully progressed.',
					last_update_time: '2024-03-11 14:46:41+00:00',
					last_transition_time: '2024-03-11 14:42:39+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-20 14:59:52+00:00',
					last_transition_time: '2024-04-20 14:59:52+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'grafana-deployment-helm-775fd6dd66-v5tw8',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '1e0d46dc-d809-42bd-9e37-404ed4b7da67',
						kind: 'ReplicaSet',
						name: 'grafana-deployment-helm-775fd6dd66',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
			name: 'jenkins-deployment-helm',
			labels: {
				'app.kubernetes.io/managed-by': 'Helm',
			},
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'meta.helm.sh/release-name': 'my-first-helm',
				'meta.helm.sh/release-namespace': 'production',
				'deployment.kubernetes.io/revision': '1',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 14:42:39+00:00',
					manager: 'helm',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:labels': {
								'.': {},
								'f:app.kubernetes.io/managed-by': {},
							},
							'f:annotations': {
								'.': {},
								'f:meta.helm.sh/release-name': {},
								'f:meta.helm.sh/release-namespace': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:52+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754213',
			creation_timestamp: '2024-03-11 14:42:39+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu-helm',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu-helm',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "jenkins-deployment-helm-775fd6dd66" has successfully progressed.',
					last_update_time: '2024-03-11 14:46:41+00:00',
					last_transition_time: '2024-03-11 14:42:39+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-20 14:59:52+00:00',
					last_transition_time: '2024-04-20 14:59:52+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'jenkins-deployment-helm-775fd6dd66-v5tw8',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '1e0d46dc-d809-42bd-9e37-404ed4b7da67',
						kind: 'ReplicaSet',
						name: 'jenkins-deployment-helm-775fd6dd66',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'arn:aws:eks:us-east-1:710369796047:cluster/production',
	},
]

export const kubernetesResourcesXc5 = [
	{
		api_version: null,
		kind: 'DaemonSet',
		metadata: {
			uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
			name: 'my-daemonset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'deprecated.daemonset.template.generation': '1',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"DaemonSet","metadata":{"annotations":{},"name":"my-daemonset","namespace":"production"},"spec":{"selector":{"matchLabels":{"name":"my-daemonset"}},"template":{"metadata":{"labels":{"name":"my-daemonset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-daemonset-container","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:53:42+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-daemonset-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:revisionHistoryLimit': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:deprecated.daemonset.template.generation': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:45+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:numberReady': {},
							'f:numberAvailable': {},
							'f:observedGeneration': {},
							'f:currentNumberScheduled': {},
							'f:desiredNumberScheduled': {},
							'f:updatedNumberScheduled': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754187',
			creation_timestamp: '2024-03-11 08:53:42+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			selector: {
				match_labels: {
					name: 'my-daemonset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-daemonset-container',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-daemonset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: 0,
					max_unavailable: 1,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
		},
		status: {
			conditions: null,
			number_ready: 2,
			collision_count: null,
			number_available: 2,
			number_unavailable: null,
			number_misscheduled: 0,
			observed_generation: 1,
			current_number_scheduled: 2,
			desired_number_scheduled: 2,
			updated_number_scheduled: 2,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-daemonset-m2d6j',
				container_name: 'my-daemonset-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '0ef2f502-2e36-4de1-9038-5c2119888310',
						kind: 'DaemonSet',
						name: 'my-daemonset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'Microsoft.ContainerService/managedClusters/production',
	},
	{
		api_version: null,
		kind: 'StatefulSet',
		metadata: {
			uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
			name: 'my-statefulset',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"StatefulSet","metadata":{"annotations":{},"name":"my-statefulset","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"name":"my-statefulset"}},"serviceName":"my-statefulset","template":{"metadata":{"labels":{"name":"my-statefulset"}},"spec":{"automountServiceAccountToken":true,"containers":[{"command":["sleep","123456"],"env":[{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}}],"image":"ubuntu","name":"my-statefulset","resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}}}],"serviceAccountName":"token-service-account"}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 08:50:09+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:template': {
								'f:spec': {
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"my-statefulset"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:automountServiceAccountToken': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:name': {},
									},
								},
							},
							'f:serviceName': {},
							'f:updateStrategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:partition': {},
								},
							},
							'f:podManagementPolicy': {},
							'f:revisionHistoryLimit': {},
							'f:persistentVolumeClaimRetentionPolicy': {
								'.': {},
								'f:whenScaled': {},
								'f:whenDeleted': {},
							},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 09:03:21+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:readyReplicas': {},
							'f:collisionCount': {},
							'f:updateRevision': {},
							'f:currentReplicas': {},
							'f:currentRevision': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41700785',
			creation_timestamp: '2024-03-11 08:50:09+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			ordinals: null,
			replicas: 1,
			selector: {
				match_labels: {
					name: 'my-statefulset',
				},
				match_expressions: null,
			},
			template: {
				spec: {
					os: null,
					volumes: null,
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'my-statefulset',
							image: 'ubuntu',
							ports: null,
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: null,
							liveness_probe: null,
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: true,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						name: 'my-statefulset',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			service_name: 'my-statefulset',
			update_strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					partition: 0,
					max_unavailable: null,
				},
			},
			min_ready_seconds: null,
			pod_management_policy: 'OrderedReady',
			revision_history_limit: 10,
			volume_claim_templates: null,
			persistent_volume_claim_retention_policy: {
				when_scaled: 'Retain',
				when_deleted: 'Retain',
			},
		},
		status: {
			replicas: 1,
			conditions: null,
			ready_replicas: 1,
			collision_count: 0,
			update_revision: 'my-statefulset-76b644766c',
			current_replicas: 1,
			current_revision: 'my-statefulset-76b644766c',
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'my-statefulset-0',
				container_name: 'my-statefulset',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '87b978ae-001c-46f2-9c39-97ceecb06881',
						kind: 'StatefulSet',
						name: 'my-statefulset',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'Microsoft.ContainerService/managedClusters/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'bbe55ee4-ae7c-438b-a19c-b773534c9a65',
			name: 'prometheus-deployment',
			labels: null,
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 7,
			annotations: {
				'deployment.kubernetes.io/revision': '7',
				'kubectl.kubernetes.io/last-applied-configuration':
					'{"apiVersion":"apps/v1","kind":"Deployment","metadata":{"annotations":{},"name":"prometheus-deployment","namespace":"production"},"spec":{"replicas":1,"selector":{"matchLabels":{"app":"ubuntu"}},"template":{"metadata":{"labels":{"app":"ubuntu"}},"spec":{"containers":[{"command":["sleep","123456"],"env":[{"name":"test","value":"AKIA2ODYTOW1MU6FF1P4"},{"name":"ACCESS_KEY_ID","valueFrom":{"secretKeyRef":{"key":"access-key","name":"awssm-secret"}}},{"name":"ACCESS_KEY_SECRET","valueFrom":{"secretKeyRef":{"key":"secret-access-key","name":"awssm-secret"}}},{"name":"MY_SECRET_CLUSTER_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-cluster-external-secret"}}},{"name":"MY_SECRET_EXTERNAL","valueFrom":{"secretKeyRef":{"key":"hello","name":"my-external-secret"}}},{"name":"MY_SECRET","valueFrom":{"secretKeyRef":{"key":"hello","name":"production-eks-secret"}}}],"image":"ubuntu","livenessProbe":{"exec":{"command":["ls","/tmp"]},"initialDelaySeconds":5,"periodSeconds":5},"name":"ubuntu-container","ports":[{"containerPort":8888}],"resources":{"limits":{"cpu":"500m","memory":"512Mi"},"requests":{"cpu":"250m","memory":"256Mi"}},"volumeMounts":[{"mountPath":"/mnt/secrets-store","name":"server-secrets","readOnly":true}]}],"serviceAccountName":"token-service-account","volumes":[{"csi":{"driver":"secrets-store.csi.k8s.io","readOnly":true,"volumeAttributes":{"secretProviderClass":"aws-secrets"}},"name":"server-secrets"}]}}}}\n',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-25 16:08:59+00:00',
					manager: 'kubectl-client-side-apply',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"test"}': {
													'.': {},
													'f:name': {},
													'f:value': {},
												},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:annotations': {
								'.': {},
								'f:kubectl.kubernetes.io/last-applied-configuration': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-21 03:00:40+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41862200',
			creation_timestamp: '2023-10-05 09:15:30+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'test',
									value: 'AKIA2ODYTOW1MU6FF1P4',
									value_from: null,
								},
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "prometheus-deployment-85688f7765" has successfully progressed.',
					last_update_time: '2024-03-25 16:09:01+00:00',
					last_transition_time: '2023-10-12 12:11:57+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-21 03:00:40+00:00',
					last_transition_time: '2024-04-21 03:00:40+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 7,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: null,
				pod_name: 'prometheus-deployment-85688f7765-hfj8f',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: null,
				pod_owner_references: [
					{
						uid: '50da299b-47f4-4e28-a810-5ceccd0cde20',
						kind: 'ReplicaSet',
						name: 'prometheus-deployment-85688f7765',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: true,
			},
		],
		cluster_literal: 'Microsoft.ContainerService/managedClusters/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
			name: 'grafana-deployment-helm',
			labels: {
				'app.kubernetes.io/managed-by': 'Helm',
			},
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'meta.helm.sh/release-name': 'my-first-helm',
				'meta.helm.sh/release-namespace': 'production',
				'deployment.kubernetes.io/revision': '1',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 14:42:39+00:00',
					manager: 'helm',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:labels': {
								'.': {},
								'f:app.kubernetes.io/managed-by': {},
							},
							'f:annotations': {
								'.': {},
								'f:meta.helm.sh/release-name': {},
								'f:meta.helm.sh/release-namespace': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:52+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754213',
			creation_timestamp: '2024-03-11 14:42:39+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu-helm',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu-helm',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "grafana-deployment-helm-775fd6dd66" has successfully progressed.',
					last_update_time: '2024-03-11 14:46:41+00:00',
					last_transition_time: '2024-03-11 14:42:39+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-20 14:59:52+00:00',
					last_transition_time: '2024-04-20 14:59:52+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'grafana-deployment-helm-775fd6dd66-v5tw8',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '1e0d46dc-d809-42bd-9e37-404ed4b7da67',
						kind: 'ReplicaSet',
						name: 'grafana-deployment-helm-775fd6dd66',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'Microsoft.ContainerService/managedClusters/production',
	},
	{
		api_version: null,
		kind: 'Deployment',
		metadata: {
			uid: 'b1774f48-8f92-466d-ac86-9b82d34d24d9',
			name: 'jenkins-deployment-helm',
			labels: {
				'app.kubernetes.io/managed-by': 'Helm',
			},
			namespace: 'production',
			self_link: null,
			finalizers: null,
			generation: 1,
			annotations: {
				'meta.helm.sh/release-name': 'my-first-helm',
				'meta.helm.sh/release-namespace': 'production',
				'deployment.kubernetes.io/revision': '1',
			},
			generate_name: null,
			managed_fields: [
				{
					time: '2024-03-11 14:42:39+00:00',
					manager: 'helm',
					fields_v1: {
						'f:spec': {
							'f:replicas': {},
							'f:selector': {},
							'f:strategy': {
								'f:type': {},
								'f:rollingUpdate': {
									'.': {},
									'f:maxSurge': {},
									'f:maxUnavailable': {},
								},
							},
							'f:template': {
								'f:spec': {
									'f:volumes': {
										'.': {},
										'k:{"name":"server-secrets"}': {
											'.': {},
											'f:csi': {
												'.': {},
												'f:driver': {},
												'f:readOnly': {},
												'f:volumeAttributes': {
													'.': {},
													'f:secretProviderClass': {},
												},
											},
											'f:name': {},
										},
									},
									'f:dnsPolicy': {},
									'f:containers': {
										'k:{"name":"ubuntu-container"}': {
											'.': {},
											'f:env': {
												'.': {},
												'k:{"name":"MY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_ID"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"ACCESS_KEY_SECRET"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
												'k:{"name":"MY_SECRET_CLUSTER_EXTERNAL"}': {
													'.': {},
													'f:name': {},
													'f:valueFrom': {
														'.': {},
														'f:secretKeyRef': {},
													},
												},
											},
											'f:name': {},
											'f:image': {},
											'f:ports': {
												'.': {},
												'k:{"containerPort":8888,"protocol":"TCP"}': {
													'.': {},
													'f:protocol': {},
													'f:containerPort': {},
												},
											},
											'f:command': {},
											'f:resources': {
												'.': {},
												'f:limits': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
												'f:requests': {
													'.': {},
													'f:cpu': {},
													'f:memory': {},
												},
											},
											'f:volumeMounts': {
												'.': {},
												'k:{"mountPath":"/mnt/secrets-store"}': {
													'.': {},
													'f:name': {},
													'f:readOnly': {},
													'f:mountPath': {},
												},
											},
											'f:livenessProbe': {
												'.': {},
												'f:exec': {
													'.': {},
													'f:command': {},
												},
												'f:periodSeconds': {},
												'f:timeoutSeconds': {},
												'f:failureThreshold': {},
												'f:successThreshold': {},
												'f:initialDelaySeconds': {},
											},
											'f:imagePullPolicy': {},
											'f:terminationMessagePath': {},
											'f:terminationMessagePolicy': {},
										},
									},
									'f:restartPolicy': {},
									'f:schedulerName': {},
									'f:serviceAccount': {},
									'f:securityContext': {},
									'f:serviceAccountName': {},
									'f:terminationGracePeriodSeconds': {},
								},
								'f:metadata': {
									'f:labels': {
										'.': {},
										'f:app': {},
									},
								},
							},
							'f:revisionHistoryLimit': {},
							'f:progressDeadlineSeconds': {},
						},
						'f:metadata': {
							'f:labels': {
								'.': {},
								'f:app.kubernetes.io/managed-by': {},
							},
							'f:annotations': {
								'.': {},
								'f:meta.helm.sh/release-name': {},
								'f:meta.helm.sh/release-namespace': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: null,
				},
				{
					time: '2024-04-20 14:59:52+00:00',
					manager: 'kube-controller-manager',
					fields_v1: {
						'f:status': {
							'f:replicas': {},
							'f:conditions': {
								'.': {},
								'k:{"type":"Available"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
								'k:{"type":"Progressing"}': {
									'.': {},
									'f:type': {},
									'f:reason': {},
									'f:status': {},
									'f:message': {},
									'f:lastUpdateTime': {},
									'f:lastTransitionTime': {},
								},
							},
							'f:readyReplicas': {},
							'f:updatedReplicas': {},
							'f:availableReplicas': {},
							'f:observedGeneration': {},
						},
						'f:metadata': {
							'f:annotations': {
								'f:deployment.kubernetes.io/revision': {},
							},
						},
					},
					operation: 'Update',
					api_version: 'apps/v1',
					fields_type: 'FieldsV1',
					subresource: 'status',
				},
			],
			owner_references: null,
			resource_version: '41754213',
			creation_timestamp: '2024-03-11 14:42:39+00:00',
			deletion_timestamp: null,
			deletion_grace_period_seconds: null,
		},
		spec: {
			paused: null,
			replicas: 1,
			selector: {
				match_labels: {
					app: 'ubuntu-helm',
				},
				match_expressions: null,
			},
			strategy: {
				type: 'RollingUpdate',
				rolling_update: {
					max_surge: '25%',
					max_unavailable: '25%',
				},
			},
			template: {
				spec: {
					os: null,
					volumes: [
						{
							fc: null,
							csi: {
								driver: 'secrets-store.csi.k8s.io',
								fs_type: null,
								read_only: true,
								volume_attributes: {
									secretProviderClass: 'aws-secrets',
								},
								node_publish_secret_ref: null,
							},
							nfs: null,
							rbd: null,
							name: 'server-secrets',
							iscsi: null,
							cephfs: null,
							cinder: null,
							secret: null,
							flocker: null,
							quobyte: null,
							git_repo: null,
							scale_io: null,
							empty_dir: null,
							ephemeral: null,
							glusterfs: null,
							host_path: null,
							projected: null,
							storageos: null,
							azure_disk: null,
							azure_file: null,
							config_map: null,
							flex_volume: null,
							downward_api: null,
							vsphere_volume: null,
							portworx_volume: null,
							gce_persistent_disk: null,
							photon_persistent_disk: null,
							aws_elastic_block_store: null,
							persistent_volume_claim: null,
						},
					],
					affinity: null,
					host_ipc: null,
					host_pid: null,
					hostname: null,
					overhead: null,
					priority: null,
					node_name: null,
					subdomain: null,
					containers: [
						{
							env: [
								{
									name: 'ACCESS_KEY_ID',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'ACCESS_KEY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'secret-access-key',
											name: 'awssm-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_CLUSTER_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-cluster-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET_EXTERNAL',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'my-external-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
								{
									name: 'MY_SECRET',
									value: null,
									value_from: {
										field_ref: null,
										secret_key_ref: {
											key: 'hello',
											name: 'production-eks-secret',
											optional: null,
										},
										config_map_key_ref: null,
										resource_field_ref: null,
									},
								},
							],
							tty: null,
							args: null,
							name: 'ubuntu-container',
							image: 'ubuntu',
							ports: [
								{
									name: null,
									host_ip: null,
									protocol: 'TCP',
									host_port: null,
									container_port: 8888,
								},
							],
							stdin: null,
							command: ['sleep', '123456'],
							env_from: null,
							lifecycle: null,
							resources: {
								claims: null,
								limits: {
									cpu: '500m',
									memory: '512Mi',
								},
								requests: {
									cpu: '250m',
									memory: '256Mi',
								},
							},
							stdin_once: null,
							working_dir: null,
							resize_policy: null,
							startup_probe: null,
							volume_mounts: [
								{
									name: 'server-secrets',
									sub_path: null,
									read_only: true,
									mount_path: '/mnt/secrets-store',
									sub_path_expr: null,
									mount_propagation: null,
								},
							],
							liveness_probe: {
								grpc: null,
								_exec: {
									command: ['ls', '/tmp'],
								},
								http_get: null,
								tcp_socket: null,
								period_seconds: 5,
								timeout_seconds: 1,
								failure_threshold: 3,
								success_threshold: 1,
								initial_delay_seconds: 5,
								termination_grace_period_seconds: null,
							},
							restart_policy: null,
							volume_devices: null,
							readiness_probe: null,
							security_context: null,
							image_pull_policy: 'Always',
							termination_message_path: '/dev/termination-log',
							termination_message_policy: 'File',
						},
					],
					dns_config: null,
					dns_policy: 'ClusterFirst',
					host_users: null,
					tolerations: null,
					host_aliases: null,
					host_network: null,
					node_selector: null,
					restart_policy: 'Always',
					scheduler_name: 'default-scheduler',
					init_containers: null,
					readiness_gates: null,
					resource_claims: null,
					service_account: 'token-service-account',
					scheduling_gates: null,
					security_context: {
						sysctls: null,
						fs_group: null,
						run_as_user: null,
						run_as_group: null,
						run_as_non_root: null,
						seccomp_profile: null,
						windows_options: null,
						se_linux_options: null,
						supplemental_groups: null,
						fs_group_change_policy: null,
					},
					preemption_policy: null,
					image_pull_secrets: null,
					runtime_class_name: null,
					priority_class_name: null,
					enable_service_links: null,
					ephemeral_containers: null,
					service_account_name: 'token-service-account',
					set_hostname_as_fqdn: null,
					active_deadline_seconds: null,
					share_process_namespace: null,
					topology_spread_constraints: null,
					automount_service_account_token: null,
					termination_grace_period_seconds: 30,
				},
				metadata: {
					uid: null,
					name: null,
					labels: {
						app: 'ubuntu-helm',
					},
					namespace: null,
					self_link: null,
					finalizers: null,
					generation: null,
					annotations: null,
					generate_name: null,
					managed_fields: null,
					owner_references: null,
					resource_version: null,
					creation_timestamp: null,
					deletion_timestamp: null,
					deletion_grace_period_seconds: null,
				},
			},
			min_ready_seconds: null,
			revision_history_limit: 10,
			progress_deadline_seconds: 600,
		},
		status: {
			replicas: 1,
			conditions: [
				{
					type: 'Progressing',
					reason: 'NewReplicaSetAvailable',
					status: 'True',
					message: 'ReplicaSet "jenkins-deployment-helm-775fd6dd66" has successfully progressed.',
					last_update_time: '2024-03-11 14:46:41+00:00',
					last_transition_time: '2024-03-11 14:42:39+00:00',
				},
				{
					type: 'Available',
					reason: 'MinimumReplicasAvailable',
					status: 'True',
					message: 'Deployment has minimum availability.',
					last_update_time: '2024-04-20 14:59:52+00:00',
					last_transition_time: '2024-04-20 14:59:52+00:00',
				},
			],
			ready_replicas: 1,
			collision_count: null,
			updated_replicas: 1,
			available_replicas: 1,
			observed_generation: 1,
			unavailable_replicas: null,
		},
		secrets: [],
		image_pull_secrets: [],
		automount_service_account_token: null,
		binary_data: null,
		data: null,
		pod_to_iam_access_keys: [
			{
				secret_name: 'awssm-secret',
				pod_name: 'jenkins-deployment-helm-775fd6dd66-v5tw8',
				container_name: 'ubuntu-container',
				access_key_id: 'AKIA2ODYTOW1MU6FF1P4',
				secret_key: 'access-key',
				pod_owner_references: [
					{
						uid: '1e0d46dc-d809-42bd-9e37-404ed4b7da67',
						kind: 'ReplicaSet',
						name: 'jenkins-deployment-helm-775fd6dd66',
						controller: true,
						api_version: 'apps/v1',
						block_owner_deletion: true,
					},
				],
				is_non_secret_env: false,
			},
		],
		cluster_literal: 'Microsoft.ContainerService/managedClusters/production',
	},
]

export const endpointAccessXc = [
	{
		device_name: "Gabriela's iPhone",
		device_type: 'Mobile - iOS',
		status: 'Active',
		endpoint_protection: false,
		ip_address: '125.17.154.214',
		first_seen: '2024-03-11 14:42:39+00:00',
		last_seen: '2024-06-08 21:04:33+00:00',
	},
	{
		device_name: "Gabriela's Dell",
		device_type: 'Desktop - Windows',
		status: 'Active',
		endpoint_protection: true,
		ip_address: '125.25.42.145',
		first_seen: '2024-02-23 08:40:08+00:00',
		last_seen: '2024-06-09 11:13:52+00:00',
	},
	{
		device_name: 'TAU Library',
		device_type: 'Desktop - Windows',
		status: 'Inactive',
		endpoint_protection: false,
		ip_address: '125.31.14.241',
		first_seen: '2024-05-12 09:14:13+00:00',
		last_seen: '2024-05-12 10:37:22+00:00',
	},
]
