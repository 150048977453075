import { generateUUID } from '../utils.ts'
import { policyTags } from './awsIamUserData.ts'
import { MockDemoTag } from '../../schemas/tags.ts'

export const ADMIN_ROLE = {
	role_id: 'role123',
	role_name: 'Admin',
	description: 'Administrator role with full access',
	created_at: '2023-01-01T00:00:00Z',
	updated_at: '2023-06-01T12:00:00Z',
	tags: [policyTags.ADMIN],
	permissions: [
		{
			permission_id: 'perm123',
			role_id: 'role123',
			resource: 'databricks-cluster',
			action: 'create',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
		},
		{
			permission_id: 'perm124',
			role_id: 'role123',
			resource: 'databricks-cluster',
			action: 'delete',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
		},
	],
}

export const READ_ONLY_ROLE = {
	role_id: generateUUID(),
	role_name: 'Read Only',
	description: 'Read only role with limited access',
	created_at: '2023-01-01T00:00:00Z',
	updated_at: '2023-06-01T12:00:00Z',
	tags: [policyTags.DATA_ACCESS],
	permissions: [
		{
			permission_id: 'perm123',
			role_id: 'role123',
			resource: 'databricks-cluster',
			action: 'create',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
		},
		{
			permission_id: 'perm124',
			role_id: 'role123',
			resource: 'databricks-cluster',
			action: 'delete',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
		},
	],
}

export const DATABRICKS_WORKSPACE_1 = {
	name: 'Dev Workspace',
	status: 'active',
	bucket_name: 'dev-bucket',
	credential_name: 'DevRole',
	metastore: 'https://metastore.devworkspace.com',
}

export const DATABRICKS_WORKSPACE_2 = {
	name: 'Test Workspace',
	status: 'inactive',
	bucket_name: 'test-bucket',
	credential_name: 'TestRole',
	metastore: 'https://metastore.testworkspace.com',
}

// Add these workspaces to the existing data structure where needed
export const DATABRICKS_ACCOUNT_1 = 'f18c0764-ee5b-4ae0-81ea-250fc4a8e7b1'
export const ACCOUNT_LITERAL_FRIENDLY_NAME = 'Synergy Databricks Account'
const USER_ID_1 = '40ded881-837c-4c00-9785-102339934732'
const USER_ID_2 = 'a59fc34d-849c-4f8c-8409-353ce8bbb4fd'
const USER_ID_3 = 'bafbd807-3146-44e7-b2ae-4d65eeda58b0'
const USER_ID_4 = '9fe33b7a-caad-4b2e-b1cc-a47656d7fb38'
const USER_ID_5 = 'c658f157-87b7-4b85-8b4d-c3f75bf7e0c0'

export const databricksUserData = {
	'70000': {
		id: USER_ID_1,
		related_sub_envs: [],
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		environment_id: '232b11a4-6069-40e9-9f6a-eb66614df1bf',
		literal: '98547',
		literal_friendly_name: 'eli.sampars@synergy.com',
		created_at: '2024-02-02T06:05:32.949000Z',
		updated_at: '2024-06-17T13:57:43.884507Z',
		last_activity: '2024-01-02T10:51:41.037000Z',
		env_type: 'DATABRICKS',
		type: 'HUMAN',
		source: 'DATABRICKS_USER',
		issues: [],
		tags: [policyTags.ADMIN],
		mock_demo_tags: [MockDemoTag.OVER_PRIVILEGED_IDENTITY],
		identity_tag_list: [],
		account_literal: DATABRICKS_ACCOUNT_1,
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		affected_environments: null,
		databricks_user_id: '8d46c3ab-ff68-4ca4-ac5b-946fce48da9a',
		databricks_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: USER_ID_1,
			user_id: '12345',
			username: 'johndoe',
			admin: true,
			email: 'johndoe@example.com',
			first_name: 'John',
			last_name: 'Doe',
			status: 'active',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
			roles: [ADMIN_ROLE],
			personal_access_tokens: [
				{
					token_id: 'dapi1234567890abcdef1234567890abcdef',
					created_at: '2023-01-01T00:00:00Z',
					last_used: '2024-01-01T00:00:00Z',
					expires_at: '2024-10-01T00:00:00Z',
				},
			],
			workspaces: [DATABRICKS_WORKSPACE_1],
			github_repos_xc: [],
		},
	},
	'70001': {
		id: USER_ID_2,
		related_sub_envs: [],
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		environment_id: '232b11a4-6069-40e9-9f6a-eb66614df1bf',
		literal: '98548',
		literal_friendly_name: 'alice.jones@synergy.com',
		created_at: '2024-02-02T06:05:32.949000Z',
		updated_at: '2024-06-17T13:57:43.884507Z',
		last_activity: '2024-01-02T10:51:41.037000Z',
		env_type: 'DATABRICKS',
		type: 'HUMAN',
		source: 'DATABRICKS_USER',
		issues: [],
		tags: [policyTags.DATA_ACCESS],
		mock_demo_tags: [],
		identity_tag_list: [],
		account_literal: DATABRICKS_ACCOUNT_1,
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		affected_environments: null,
		databricks_user_id: '8d46c3ab-ff68-4ca4-ac5b-946fce48da9a',
		databricks_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: USER_ID_2,
			user_id: '12346',
			username: 'alicejones',
			admin: true,
			email: 'alicejones@example.com',
			first_name: 'Alice',
			last_name: 'Jones',
			status: 'active',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
			roles: [READ_ONLY_ROLE],
			personal_access_tokens: [
				{
					token_id: 'dapi567890abcdef1234567890abcdef12',
					created_at: '2023-01-15T00:00:00Z',
					last_used: '2024-01-15T00:00:00Z',
					expires_at: '2024-10-15T00:00:00Z',
				},
				{
					token_id: 'dapi67890abcdef1234567890abcdef1234',
					created_at: '2023-02-15T00:00:00Z',
					last_used: '2024-02-15T00:00:00Z',
					expires_at: '2024-11-15T00:00:00Z',
				},
				{
					token_id: 'dapi7890abcdef1234567890abcdef123456',
					created_at: '2023-03-15T00:00:00Z',
					last_used: '2024-02-15T00:00:00Z',
					expires_at: '2024-11-15T00:00:00Z',
				},
				{
					token_id: 'dapi890abcdef1234567890abcdef1234567',
					created_at: '2023-04-15T00:00:00Z',
					last_used: '2024-03-15T00:00:00Z',
					expires_at: null,
				},
			],
			workspaces: [DATABRICKS_WORKSPACE_1],
			github_repos_xc: [
				{
					name: 'dashboard',
					permission: 'ADMIN',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'ADMIN',
						},
						{
							source_type: 'Repository',
							source_name: 'dashboard',
							role_name: 'admin',
							permission: 'ADMIN',
						},
						{
							source_type: 'Team',
							source_name: 'frontend-team',
							role_name: null,
							permission: 'READ',
						},
					],
				},
				{
					name: 'demo-repository',
					permission: 'WRITE',
					permission_sources: [
						{
							source_name: 'synergistic-sciences',
							source_type: 'Organization',
							role_name: null,
							permission: 'WRITE',
						},
					],
				},
			],
		},
	},
	'70002': {
		id: USER_ID_3,
		related_sub_envs: [],
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		environment_id: '232b11a4-6069-40e9-9f6a-eb66614df1bf',
		literal: '98549',
		literal_friendly_name: 'bob.smith@synergy.com',
		created_at: '2024-02-03T06:05:32.949000Z',
		updated_at: '2024-06-18T13:57:43.884507Z',
		last_activity: '2024-01-03T10:51:41.037000Z',
		env_type: 'DATABRICKS',
		type: 'HUMAN',
		source: 'DATABRICKS_USER',
		issues: [],
		tags: [policyTags.DATA_ACCESS],
		mock_demo_tags: [],
		identity_tag_list: [],
		account_literal: DATABRICKS_ACCOUNT_1,
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		affected_environments: null,
		databricks_user_id: '8d46c3ab-ff68-4ca4-ac5b-946fce48da9b',
		databricks_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: USER_ID_3,
			user_id: '12347',
			username: 'bobsmith',
			admin: false,
			email: 'bobsmith@example.com',
			first_name: 'Bob',
			last_name: 'Smith',
			status: 'active',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
			roles: [READ_ONLY_ROLE],
			personal_access_tokens: [],
			workspaces: [DATABRICKS_WORKSPACE_1],
			github_repos_xc: [],
		},
	},
	'70003': {
		id: USER_ID_4,
		related_sub_envs: [],
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		environment_id: '232b11a4-6069-40e9-9f6a-eb66614df1bf',
		literal: '98550',
		literal_friendly_name: 'carol.white@synergy.com',
		created_at: '2024-02-04T06:05:32.949000Z',
		updated_at: '2024-06-19T13:57:43.884507Z',
		last_activity: '2024-01-04T10:51:41.037000Z',
		env_type: 'DATABRICKS',
		type: 'HUMAN',
		source: 'DATABRICKS_USER',
		issues: [],
		tags: [policyTags.DATA_ACCESS],
		mock_demo_tags: [],
		identity_tag_list: [],
		account_literal: DATABRICKS_ACCOUNT_1,
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		affected_environments: null,
		databricks_user_id: '8d46c3ab-ff68-4ca4-ac5b-946fce48da9c',
		databricks_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: USER_ID_4,
			user_id: '12348',
			username: 'carolwhite',
			admin: false,
			email: 'carolwhite@example.com',
			first_name: 'Carol',
			last_name: 'White',
			status: 'active',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
			roles: [READ_ONLY_ROLE],
			personal_access_tokens: [],
			workspaces: [DATABRICKS_WORKSPACE_1],
			github_repos_xc: [],
		},
	},
	'70004': {
		id: USER_ID_5,
		related_sub_envs: [],
		customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
		environment_id: '232b11a4-6069-40e9-9f6a-eb66614df1bf',
		literal: '98551',
		literal_friendly_name: 'david.brown@synergy.com',
		created_at: '2024-02-05T06:05:32.949000Z',
		updated_at: '2024-06-20T13:57:43.884507Z',
		last_activity: '2024-01-05T10:51:41.037000Z',
		env_type: 'DATABRICKS',
		type: 'HUMAN',
		source: 'DATABRICKS_USER',
		issues: [],
		tags: [policyTags.DATA_ACCESS],
		mock_demo_tags: [],
		identity_tag_list: [],
		account_literal: DATABRICKS_ACCOUNT_1,
		account_literal_friendly_name: ACCOUNT_LITERAL_FRIENDLY_NAME,
		affected_environments: null,
		databricks_user_id: '8d46c3ab-ff68-4ca4-ac5b-946fce48da9d',
		databricks_user: {
			customer_id: '5836f9b9-37a2-4ab4-8d13-d7c4b310629e',
			id: USER_ID_5,
			user_id: '12349',
			username: 'davidbrown',
			admin: false,
			email: 'davidbrown@example.com',
			first_name: 'David',
			last_name: 'Brown',
			status: 'inactive',
			created_at: '2023-01-01T00:00:00Z',
			updated_at: '2023-06-01T12:00:00Z',
			roles: [READ_ONLY_ROLE],
			personal_access_tokens: [],
			workspaces: [DATABRICKS_WORKSPACE_1],
			github_repos_xc: [],
		},
	},
}
