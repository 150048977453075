import { ServerKubernetesResourceAccessKeys } from '../../../../schemas/identities/kubernetes/kubernetesResourceAccessKeysSchema.ts'

export const AccessKeyTooltip = (props: { accessKey: ServerKubernetesResourceAccessKeys }) => {
	return (
		<div className="flex flex-col">
			<span className="flex text-sm text-gray-300">Pod Name: {props.accessKey?.pod_name}</span>
			<span className="flex text-sm text-gray-300">Container Name: {props.accessKey?.container_name}</span>
			<span className="flex text-sm text-gray-300">Secret Key: {props.accessKey?.secret_key}</span>
			<span className="flex text-sm text-gray-300">Secret Name: {props.accessKey?.secret_name}</span>
		</div>
	)
}
