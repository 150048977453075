import { Collapse } from 'antd'
import { ServerGcpPrincipalInfoXc } from '../../../../schemas/identities/gcp/gcpProjectXcSchema.ts'
import React from 'react'
import { IdentitySource } from '../../../../schemas/identity.ts'
import { ContextPanelHeader } from '../ContextPanelHeader.tsx'
import { GcpProjectsTable } from '../../../../tables/gcp/GcpProjectsTable.tsx'

export const GcpProjectsContext: React.FunctionComponent<{ gcpProjectsXc?: ServerGcpPrincipalInfoXc[] | null }> = ({
	gcpProjectsXc,
}) => {
	if (!gcpProjectsXc?.length) {
		return null
	}
	return (
		<Collapse expandIconPosition={'end'}>
			<Collapse.Panel
				header={<ContextPanelHeader source={IdentitySource.GCP_SERVICE_ACCOUNT} />}
				key={IdentitySource.GCP_SERVICE_ACCOUNT}
			>
				<GcpProjectsTable gcpProjectsXc={gcpProjectsXc} />
			</Collapse.Panel>
		</Collapse>
	)
}
