import cx from 'classnames'
import { useIdentityStats } from '../../../api/identities.ts'
import { Skeleton, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

type IdentitiesStatsItemProps = {
	statsQuery: ReturnType<typeof useIdentityStats>
	children: React.ReactNode
	title: string
}

export const IdentitiesStatsItem = ({ statsQuery, children, title }: IdentitiesStatsItemProps) => {
	const { isLoading: isIdentityStatsLoading, isFetching: isIdentityStatsFetching } = statsQuery

	const contentAreaClassName = cx('flex gap-6 grow items-center transition-opacity', {
		'opacity-50': isIdentityStatsFetching,
	})

	return (
		<div className="flex grow flex-col justify-between">
			<div className="flex gap-4 items-center text-base text-zinc-500">
				<span>{title}</span>
				<Spin
					className="text-zinc-600"
					indicator={<LoadingOutlined />}
					spinning={isIdentityStatsFetching && !isIdentityStatsLoading}
				/>
			</div>
			<Skeleton paragraph={{ rows: 1 }} loading={isIdentityStatsLoading} active>
				<div className={contentAreaClassName}>{children}</div>
			</Skeleton>
		</div>
	)
}
