import axios from 'axios'
import { settings } from '../env-settings.ts'

export const api = axios.create({
	baseURL: settings.viteBackendUrl,
})

export function updateAccessToken(token: string) {
	api.defaults.headers.Authorization = `Bearer ${token}`
}
