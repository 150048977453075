import { z } from 'zod'
import { DateSchema } from '../common'
import { ServerGoogleWorkspaceUserEmailsSchema } from './googleWorkspaceUserSchema.ts'

export const ServerGoogleWorkspaceUserXcSchema = z.object({
	id: z.string().nullish(),
	kind: z.string().nullish(),
	etag: z.string().nullish(),
	primaryEmail: z.string().nullish(),
	name: z
		.object({
			giveName: z.string().nullish(),
			familyName: z.string().nullish(),
			fullName: z.string().nullish(),
		})
		.nullish(),
	isAdmin: z.boolean().nullish(),
	isDelegatedAdmin: z.boolean().nullish(),
	lastLoginTime: DateSchema.nullish(),
	creationTime: DateSchema.nullish(),
	agreedToTerms: z.boolean().nullish(),
	suspended: z.boolean().nullish(),
	archived: z.boolean().nullish(),
	changePasswordAtNextLogin: z.boolean().nullish(),
	ipWhitelisted: z.boolean().nullish(),
	emails: z.array(ServerGoogleWorkspaceUserEmailsSchema).nullish(),
	customerId: z.string().nullish(),
	orgUnitPath: z.string().nullish(),
	isMailboxSetup: z.boolean().nullish(),
	isEnforcedIn2Sv: z.boolean().nullish(),
	isEnrolledIn2Sv: z.boolean().nullish(),
	includeInGlobalAddressList: z.boolean().nullish(),
	recoveryEmail: z.string().nullish(),
	recoveryPhone: z.string().nullish(),
})

export type ServerGoogleWorkspaceUserXc = z.infer<typeof ServerGoogleWorkspaceUserXcSchema>
