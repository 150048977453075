import LogRocket from 'logrocket'
import { settings } from '../../env-settings.ts'
import * as Sentry from '@sentry/react'
import { isDev } from '../../utils/devUtils.ts'

export const initSentry = () => {
	if (!isDev && settings.viteSentryDsn) {
		Sentry.init({
			dsn: settings.viteSentryDsn,
			environment: settings.mode,
			tracePropagationTargets: [
				/^https:\/\/api\.dev\.token\.security\/api/,
				/^https:\/\/api\.app\.token\.security\/api/,
			],
			integrations: [
				new Sentry.BrowserTracing(), //   new Sentry.Replay(),
			], // Performance Monitoring
			tracesSampleRate: 1.0, // Capture 100% of the transactions
			// Session Replay
			// replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			// replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		})
		LogRocket.getSessionURL((sessionURL) => {
			Sentry.configureScope((scope) => {
				scope.setExtra('sessionURL', sessionURL)
			})
		})

		const defaultErrorHandler = console.error
		console.error = (...args: unknown[]) => {
			Sentry.captureException(args)
			defaultErrorHandler.apply(console, args)
		}
	}
}
