import { z } from 'zod'
import { TagSchema } from '../../tags.ts'

export const ServerAdAceSchema = z.object({
	right_name: z.string(),
	is_inherited: z.boolean(),
	principal_sid: z.string(),
	principal_type: z.string(),
	tags: z.array(TagSchema).nullish(),
})

export type ServerAdAce = z.infer<typeof ServerAdAceSchema>
