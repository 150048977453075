import { Collapse } from 'antd'
import React from 'react'
import { ServerSnowflakeRoleXc } from '../../../../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { ResourceType } from '../../../../schemas/issue.ts'
import { SnowflakeRolesTable } from '../../../../tables/SnowflakeRolesTable.tsx'

export const SnowflakeRolesContext: React.FunctionComponent<{
	snowflakeRoleDetailsXc?: ServerSnowflakeRoleXc[] | null
}> = ({ snowflakeRoleDetailsXc }) => {
	return (
		<Collapse
			expandIconPosition={'end'}
			items={[
				{
					key: 'Roles',
					label: (
						<div className="flex items-center">
							<IssueSourceIcon source={ResourceType.SNOWFLAKE} />
							<div>Roles</div>
						</div>
					),
					children: <SnowflakeRolesTable data={snowflakeRoleDetailsXc ?? []} />,
				},
			]}
		/>
	)
}
