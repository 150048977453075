import { NodeProps, Node } from '@xyflow/react'
import { GithubTokenTypeMap, ServerGithubUserToken } from '../../../../schemas/identities/githubUserSchema.ts'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode.tsx'

export type GithubUserTokenNodeType = Node<{ accessToken: ServerGithubUserToken }, 'githubUserToken'>
type GithubUserTokenNodeProps = NodeProps<GithubUserTokenNodeType>

export const GithubUserTokenNode = ({ data: { accessToken } }: GithubUserTokenNodeProps) => (
	<BaseCredentialsNode label={GithubTokenTypeMap[accessToken.type]} name={{ text: accessToken.id.toString() }} />
)
