import { z } from 'zod'
import { PriorityTypeEnum } from '../issue.ts'
import { EnvironmentType } from '../envType.ts'

export const ServerFindingDistEnvSchema = z.object({
	env_type: z.nativeEnum(EnvironmentType).nullish(),
	amount: z.number(),
	max_priority: z.nativeEnum(PriorityTypeEnum),
})

export type ServerFindingDistEnv = z.infer<typeof ServerFindingDistEnvSchema>
