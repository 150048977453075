import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import type { z } from 'zod'
import { BaseEntraIdServicePrincipalSchema } from '../../schemas/identities/entraId/entraIdServicePrincipalSchema'

type BaseEntraIdServicePrincipal = z.infer<typeof BaseEntraIdServicePrincipalSchema>

export const EntraIdServicePrincipalTable: React.FunctionComponent<{ data: BaseEntraIdServicePrincipal[] }> = ({
	data,
}) => {
	const gridRef = useRef<AgGridReact<BaseEntraIdServicePrincipal>>(null)

	const onGridReady = useCallback((_params: GridReadyEvent<BaseEntraIdServicePrincipal>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<BaseEntraIdServicePrincipal>[]>(
		() => [
			{ field: 'display_name', headerName: 'Display Name' },
			{ field: 'service_principal_id', headerName: 'Service Principal ID' },
			{ field: 'app_id', headerName: 'App ID' },
			{
				field: 'account_enabled',
				headerName: 'Account Enabled',
				cellRenderer: (params: { data?: BaseEntraIdServicePrincipal }) => {
					return <div>{params.data?.account_enabled ? 'Enabled' : 'Disabled'}</div>
				},
			},
		],
		[],
	)

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	return (
		<div className="w-full h-full">
			<AgGridReact
				className={'ag-theme-alpine w-full h-full overflow-x-auto'}
				ref={gridRef}
				rowData={data}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				detailRowAutoHeight={true}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
