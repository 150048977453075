import { ServerIssueUpdate, ServerIssueUpdateSchema } from './../schemas/issues/issueUpdate'
import {
	ClientIssueSearch,
	EnvWithLiteral,
	EnvWithLiteralSchema,
	IssueImpactProbabilityWithCount,
	IssueImpactProbabilityWithCountSchema,
	IssueName,
	IssuesPageLens,
	IssuesTabName,
	IssueTablePaginatedRow,
	ServerIssue,
	ServerIssueIdentityEnvironment,
	ServerIssueSchema,
	ServerIssuesQueryResponse,
	ServerIssuesQueryResponseSchema,
	ServerIssuesStatsSchema,
	ServerIssueStats,
	ServerPaginatedIssuesStats,
	ServerPaginatedIssuesStatsSchema,
} from '../schemas/issue'
import { api } from './api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QueryParameters } from '../schemas/query.ts'
import { queryClient } from './queryClient.ts'
import { identityKeys } from './identities.ts'

const issuesApi = {
	async search(search?: ClientIssueSearch): Promise<ServerIssue[]> {
		try {
			const { data: issues } = await api.post<ServerIssue[]>(`/api/issues/search`, search)
			return issues.map((issue) => ServerIssueSchema.parse(issue))
		} catch (error) {
			console.error(error)
			return []
		}
	},
	async stats(): Promise<ServerIssueStats[]> {
		try {
			const { data: issuesStats } = await api.get<ServerIssueStats[]>('/api/issues/stats')
			return issuesStats.map((issue) => ServerIssuesStatsSchema.parse(issue))
		} catch (error) {
			console.error(error)
			return []
		}
	},
	async byId(issueId: string): Promise<ServerIssue> {
		const { data: issue } = await api.get<ServerIssue>(`/api/issues/${issueId}`)
		try {
			return ServerIssueSchema.parse(issue)
		} catch (e) {
			console.dir(e)
			throw e
		}
	},
	async update(issueId: string, issueUpdate: ServerIssueUpdate): Promise<ServerIssue> {
		const { data: issue } = await api.put<ServerIssue>(
			`/api/issues/${issueId}`,
			ServerIssueUpdateSchema.parse(issueUpdate),
		)

		return ServerIssueSchema.parse(issue)
	},
	async types(): Promise<IssueImpactProbabilityWithCount[]> {
		const { data: issueTypes } = await api.get<IssueImpactProbabilityWithCount[]>(`/api/issues/types`)
		return issueTypes.map((type) => IssueImpactProbabilityWithCountSchema.parse(type))
	},
	async updateIssueTypes(issueTypes: IssueImpactProbabilityWithCount[]): Promise<void> {
		await api.put(`/api/issues/types`, issueTypes)
	},
	async getPaginatedStats(
		query: QueryParameters,
		lens?: IssuesPageLens | IssueName,
		tab?: IssuesTabName,
	): Promise<ServerPaginatedIssuesStats> {
		const { data } = await api.post<ServerPaginatedIssuesStats>(`/api/issues/query-stats`, {
			query,
			lens,
			tab,
		})
		return ServerPaginatedIssuesStatsSchema.parse(data)
	},
	async getIssuesExportingLimit(): Promise<number> {
		const { data } = await api.get<number>(`/api/issues/export-limit`)
		return data
	},
	async queryIssuesForExport(
		query: QueryParameters,
		lens?: IssuesPageLens | IssueName,
		tab?: IssuesTabName,
	): Promise<ServerIssuesQueryResponse> {
		const { data } = await api.post<ServerIssuesQueryResponse>(`/api/issues/export`, {
			query,
			lens,
			tab,
		})
		return ServerIssuesQueryResponseSchema.parse(data)
	},
	async getIssueSources(tab?: IssuesTabName, lens?: IssuesPageLens | IssueName): Promise<EnvWithLiteral[]> {
		const { data } = await api.post<EnvWithLiteral[]>('/api/issues/issue-sources', { tab, lens })
		return data.map((env) => EnvWithLiteralSchema.parse(env))
	},
}

const issueKeys = {
	issueSearches: () => ['issueSearch'] as const,
	issueSearch: (search?: ClientIssueSearch) => [...issueKeys.issueSearches(), search] as const,
	issueStats: () => ['issueStats'] as const,
	issue: (issueId: string) => ['issue', issueId] as const,
	updateIssue: () => ['updateIssue'] as const,
	issueTypes: () => ['types'] as const,
	updateIssueTypes: () => ['updateIssueTypes'] as const,
	queryIssues: (query: QueryParameters, lens?: IssuesPageLens | IssueName, tab?: IssuesTabName) =>
		['queryIssues', query, lens, tab] as const,
	issuesNames: (query: QueryParameters, tab?: IssuesTabName, lens?: IssuesPageLens | IssueName) =>
		['issuesNames', query, tab, lens] as const,
	issuesAssignees: (query: QueryParameters, tab?: IssuesTabName, lens?: IssuesPageLens | IssueName) =>
		['issuesAssignees', query, tab, lens] as const,
	issuesIdentityAccounts: (query: QueryParameters, tab?: IssuesTabName, lens?: IssuesPageLens | IssueName) =>
		['issuesIdentityAccounts', query, tab, lens] as const,
}

export async function fetchIssuesQueryForExport(
	query: QueryParameters,
	lens?: IssuesPageLens | IssueName,
	tab?: IssuesTabName,
): Promise<ServerIssuesQueryResponse> {
	return queryClient.fetchQuery({
		queryKey: ['queryIssuesForExport', query, lens, tab],
		queryFn: () => issuesApi.queryIssuesForExport(query, lens, tab),
		staleTime: 1 * 60 * 1000, // 1 minute
	})
}

export function useIssuesExportingLimit() {
	return useQuery({
		queryKey: ['queryIssuesExportingLimit'],
		queryFn: () => issuesApi.getIssuesExportingLimit(),
	})
}

export function useIssueSearch(search?: ClientIssueSearch) {
	if (['ALL'].includes(search?.issueName?.toUpperCase() as string)) {
		delete search?.issueName
	}

	return useQuery({
		queryKey: issueKeys.issueSearch(search),
		queryFn: () => issuesApi.search(search),
	})
}

export function useIssuesStats() {
	return useQuery({
		queryKey: issueKeys.issueStats(),
		queryFn: () => issuesApi.stats(),
	})
}

export function useGetIssueSources(tab?: IssuesTabName, lens?: IssuesPageLens | IssueName) {
	return useQuery({
		queryKey: ['issueSources', tab, lens],
		queryFn: () => issuesApi.getIssueSources(tab, lens),
	})
}

export function useIssue(issueId: string) {
	return useQuery({
		queryKey: issueKeys.issue(issueId),
		queryFn: () => issuesApi.byId(issueId),
		enabled: !!issueId,
	})
}

export function useUpdateIssue() {
	const queryClient = useQueryClient()

	return useMutation({
		mutationKey: issueKeys.updateIssue(),
		mutationFn: ({ issueId, issueUpdate }: { issueId: string; issueUpdate: ServerIssueUpdate }) =>
			issuesApi.update(issueId, issueUpdate),
		onSuccess: (data) => {
			queryClient.setQueryData(issueKeys.issue(data.id), data)
			void queryClient.invalidateQueries({ queryKey: issueKeys.issueSearches(), refetchType: 'active' })
			void queryClient.invalidateQueries({ queryKey: issueKeys.issueStats(), refetchType: 'active' })
			if (data.identity?.id) {
				void queryClient.invalidateQueries({
					queryKey: identityKeys.identityById(data.identity.id),
					refetchType: 'active',
				})
			}
		},
	})
}

export function useIssueTypes() {
	return useQuery({
		queryKey: issueKeys.issueTypes(),
		queryFn: () => issuesApi.types(),
		staleTime: Infinity,
		cacheTime: Infinity,
	})
}

export function useUpdateIssueTypes() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationKey: issueKeys.updateIssueTypes(),
		mutationFn: async (issueTypes: IssueImpactProbabilityWithCount[]) =>
			await issuesApi.updateIssueTypes(issueTypes),
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: issueKeys.issueTypes(), refetchType: 'active' })
		},
	})
}

async function queryIssues(
	query: QueryParameters,
	lens?: IssuesPageLens | IssueName,
	tab?: IssuesTabName,
): Promise<ServerIssuesQueryResponse> {
	const { data } = await api.post<IssueTablePaginatedRow>(`/api/issues/query`, {
		query: query,
		lens: lens,
		tab: tab,
	})
	return ServerIssuesQueryResponseSchema.parse(data)
}

export function usePaginatedIssuesStats(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
	disabled?: boolean,
	keepPreviousData?: boolean,
) {
	return useQuery({
		queryKey: ['issuesPaginatedStats', query, tab, lens],
		queryFn: () => issuesApi.getPaginatedStats(query, lens, tab),
		enabled: !disabled,
		staleTime: 5 * 60 * 1000, // 5 minutes
		keepPreviousData,
	})
}

export function fetchIssuesQuery(
	query: QueryParameters,
	lens?: IssuesPageLens | IssueName,
	tab?: IssuesTabName,
): Promise<ServerIssuesQueryResponse> {
	return queryClient.fetchQuery({
		queryKey: issueKeys.queryIssues(query, lens, tab),
		queryFn: () => {
			return queryIssues(query, lens, tab)
		},
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIssueNames(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
): Promise<Array<IssueName>> {
	const { data } = await api.post<Array<IssueName>>(`/api/issues/names`, {
		query,
		tab,
		lens,
	})
	return data
}

export async function fetchIssueNames(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
): Promise<Array<IssueName>> {
	return queryClient.fetchQuery({
		queryKey: issueKeys.issuesNames(query, tab, lens),
		queryFn: () => getIssueNames(query, tab, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIssuesIdentityAccounts(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
): Promise<Array<ServerIssueIdentityEnvironment>> {
	const { data } = await api.post<Array<ServerIssueIdentityEnvironment>>(`/api/issues/identities-accounts`, {
		query,
		tab,
		lens,
	})
	return data
}

export async function fetchIssuesIdentityAccounts(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
): Promise<Array<ServerIssueIdentityEnvironment>> {
	return queryClient.fetchQuery({
		queryKey: issueKeys.issuesIdentityAccounts(query, tab, lens),
		queryFn: () => getIssuesIdentityAccounts(query, tab, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

async function getIssuesAssignees(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
): Promise<Array<string | null>> {
	const { data } = await api.post<Array<string | null>>(`/api/issues/assignees`, {
		query,
		tab,
		lens,
	})
	return data
}

export async function fetchIssuesAssignees(
	query: QueryParameters,
	tab?: IssuesTabName,
	lens?: IssuesPageLens | IssueName,
): Promise<Array<string | null>> {
	return queryClient.fetchQuery({
		queryKey: issueKeys.issuesAssignees(query, tab, lens),
		queryFn: () => getIssuesAssignees(query, tab, lens),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}
