export enum IntegrationStatus {
	INACTIVE = 'inactive',
	ACTIVE = 'active',
}

export interface IntegrationState {
	status: IntegrationStatus
	environmentCount: number
}

export type IntegrationsState = Record<string, IntegrationState>
