import { z } from 'zod'

export const ServerDistributionDataSchema = z.object({
	less_than_one_month: z.number(),
	one_to_three_months: z.number(),
	three_to_six_months: z.number(),
	six_to_twelve_months: z.number(),
	more_than_twelve_months: z.number(),
})
export type ServerDistributionData = z.infer<typeof ServerDistributionDataSchema>
