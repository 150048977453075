import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { type z } from 'zod'
import { BaseAzureDevopsServicePrincipalSchema } from '../../../schemas/identities/azure/azureDevops/azureDevopsServicePrincipalSchema.ts'

type AzureDevopsServicePrincipal = z.infer<typeof BaseAzureDevopsServicePrincipalSchema>

const AzureDevopsServicePrincipalsTable: React.FC<{ data: AzureDevopsServicePrincipal[] }> = ({ data }) => {
	const gridRef = useRef<AgGridReact<AzureDevopsServicePrincipal>>(null)

	const onGridReady = useCallback((_params: GridReadyEvent<AzureDevopsServicePrincipal>) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	const columnDefs = useMemo<ColDef<AzureDevopsServicePrincipal>[]>(
		() => [
			{
				field: 'display_name',
				headerName: 'Display Name',
				flex: 2,
			},
			{
				field: 'principal_name',
				headerName: 'Principal Name',
				flex: 2,
			},
			{
				field: 'application_id',
				headerName: 'Application ID',
				flex: 1.5,
			},

			{
				field: 'descriptor',
				headerName: 'Descriptor',
				flex: 1.5,
			},
			{
				field: 'meta_type',
				headerName: 'Meta Type',
				flex: 1,
			},
		],
		[],
	)

	const defaultColDef = useMemo<ColDef<AzureDevopsServicePrincipal>>(
		() => ({
			sortable: true,
			filter: true,
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
			},
		}),
		[],
	)

	return (
		<div className="w-full">
			<AgGridReact<AzureDevopsServicePrincipal>
				ref={gridRef}
				className="ag-theme-alpine"
				rowData={data}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				rowHeight={40}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}

export default AzureDevopsServicePrincipalsTable
