import { Node, NodeProps } from '@xyflow/react'
import { FileTextOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../graphUtils/nodeColors.ts'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext.tsx'
import { BaseNode } from '../common/baseNodes/BaseNode.tsx'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'
import { ServerSalesforcePermissionSet } from '../../../../schemas/identities/salesforce/salesforcePermissionSetSchema.ts'
import { RiskNodeTagIcons } from '../common/RiskNodeTagIcons.tsx'
import { ServerTag } from '../../../../schemas/tags.ts'

export type SalesforcePermissionSetsNodeType = Node<
	{ permissionSets: ServerSalesforcePermissionSet[] },
	'salesforcePermissionSets'
>
type SalesforcePermissionSetsNodeProps = NodeProps<SalesforcePermissionSetsNodeType>

export const SalesforcePermissionSetsNode = ({ data: { permissionSets } }: SalesforcePermissionSetsNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = permissionSets.some((permissionSet) =>
		highlightedNodes.some((node) => node.type === 'salesforcePermissionSet' && node.id === permissionSet.id),
	)

	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
		{
			[nodeColorClassNames.permission.bg]: !isHighlighted,
			[nodeColorClassNames.permission.bgHighlighted]: isHighlighted,
		},
	)

	const badgeTooltipContent = permissionSets.map((permissionSet) => (
		<span key={permissionSet.id}>{permissionSet.name}</span>
	))

	const uniqueTags = permissionSets.reduce((tags, permissionSet) => {
		permissionSet.tags?.forEach((tag) => {
			if (!tags.find((t) => t.name === tag.name)) {
				tags.push(tag)
			}
		})
		return tags
	}, [] as ServerTag[])

	return (
		<BaseNode label="Permission Sets">
			<NodeGroupBadge
				items={permissionSets}
				nodeColorType="permission"
				tooltipContent={badgeTooltipContent}
				positionOnLeft
			/>
			<RiskNodeTagIcons tags={uniqueTags} />
			<div className={iconWrapperClassName}>
				<FileTextOutlined />
			</div>
		</BaseNode>
	)
}
