import { CombinedAwsIamRolePolicy } from '../../schemas/identities/awsIamRoleXcSchema.ts'
import { JsonViewer } from '../../components/common/JsonViewer.tsx'
import { ColDef } from '@ag-grid-community/core'
import { removeNulls } from '../../utils.ts'
import { ServerTag, Tag, TagsDisplayNameMap } from '../../schemas/tags.ts'
import { TagIcons } from '../../components/common/TagIcons.tsx'

export const combinedAwsIamRolePolicyColumnDefs: ColDef<CombinedAwsIamRolePolicy>[] = [
	{ field: 'PolicyName' },
	{
		headerName: 'Type',
		valueGetter: (params: { data?: CombinedAwsIamRolePolicy }) => {
			const group = `${params.data?.GroupName ? ` (Group ${params.data?.GroupName})` : ''}`
			if (params.data?.IsAttached) {
				return `Attached${group}`
			} else if (params.data?.IsPermissionBoundary) {
				return `Permission Boundary${group}`
			} else {
				return `Inline${group}`
			}
		},
	},
	{
		headerName: 'Risk',
		cellRenderer: (params: { data?: CombinedAwsIamRolePolicy }) => {
			return <TagIcons tags={params.data?.RiskTags?.map((tag) => tag.name)} />
		},
		valueGetter: (params: { data?: CombinedAwsIamRolePolicy }) => {
			return params.data?.RiskTags?.map((tag: ServerTag): string => TagsDisplayNameMap[tag.name as Tag])
		},
	},
	{
		resizable: false,
		headerName: 'Document',
		filter: false,
		sortable: false,
		cellRenderer: (params: { data?: CombinedAwsIamRolePolicy }) => {
			if (params.data?.PolicyInfo) {
				return (
					<JsonViewer
						data={removeNulls(params.data.PolicyInfo as Record<string, never>['policyVersionList'])}
						title={params.data.PolicyName!}
					/>
				)
			} else if (params.data?.PolicyDocument) {
				return (
					<JsonViewer data={removeNulls(params.data?.PolicyDocument)} title={params.data?.PolicyName ?? ''} />
				)
			} else {
				const href = params.data?.PolicyArn?.startsWith('arn:aws:iam::aws:policy/')
					? `https://docs.aws.amazon.com/aws-managed-policy/latest/reference/${params.data?.PolicyName}.html`
					: `https://console.aws.amazon.com/iam/home?region=us-east-1#/policies/arn:aws:iam::aws:policy/${params.data?.PolicyName}`
				return (
					<a className="underline cursor-pointer text-blue-600" target={'_blank'} href={href}>
						Open In AWS
					</a>
				)
			}
		},
	},
]
