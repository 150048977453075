import { Button, Col, Layout, Row, Skeleton, Tabs, Tooltip } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'
import { IdentitiesTable } from '../../tables/identities/IdentitiesTable.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { IdentitiesSideBar } from './IdentitiesSideBar/IdentitiesSideBar.tsx'
import {
	ServerIdentitiesTableRow,
	SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES,
	inventoryUrlQuickSearchFilterParameter,
	IdentityTabName,
	IdentitiesPageSearch,
} from '../../schemas/identity.ts'
import { IssueDrawer } from '../../components/drawers/issueDrawer'
import { IRowNode, PaginationChangedEvent, RowClickedEvent, FilterChangedEvent } from '@ag-grid-community/core'
import ClearFilterIcon from '../../assets/clear_filter_icon_20.svg?react'
import IdentitiesIcon from '../../assets/identities_not_clicked_icon_20.svg?react'
import { PriorityType } from '../../schemas/issue.ts'
import { IdentitiesStats } from './stats/IdentitiesStats.tsx'
import { useIdentitiesContext } from './context/IdentitiesProvider.tsx'
import { useSearchIdentities } from '../../api/identities.ts'
import { useEntitlementToIdentityPage } from '../../services/auth'
import { isEmpty, isEqual, omit, capitalize, difference } from 'lodash'
import { RoutePaths } from '../RoutePaths.tsx'
import { useEntitlementToPaginatedInventory } from '../../services/auth/hooks.ts'
import { IdentitiesTablePaginated } from './IdentitiesTablePaginated.tsx'
import { IdentitiesQuickSearch } from './IdentitiesQuickSearch.tsx'
import { IdentitiesStatsPaginated } from './stats/IdentitiesStatsPaginated.tsx'
import { refreshDynamicFilterValues } from './paginatedInventoryUtils.ts'
import { TableFilterEntry, TableTextFilterEntry } from '../../schemas/tableFilter.ts'
import { IdentitiesExport } from './IdentitiesExport.tsx'
import { values } from 'lodash'
import { FilterView } from './FilterView/FilterView.tsx'

const defaultPageSize = 100

const TabToTooltip: Partial<Record<IdentityTabName, string>> = {
	[IdentityTabName.MANAGED]: 'Managed Identities',
	[IdentityTabName.DELETED]: 'Deleted Identities',
}

export const Identities = () => {
	const navigate = useNavigate({ from: RoutePaths.Identities })
	const search = useSearch({ from: RoutePaths.Identities })
	const { isEntitled: isEntitledToIdentityPage } = useEntitlementToIdentityPage()
	const { isEntitled: isEntitledToPaginatedTable } = useEntitlementToPaginatedInventory()
	const { gridRef, setGridReady, setSearchText, setStatsRefreshHack } = useIdentitiesContext()
	const { data: identitiesData, isLoading: isIdentitiesLoading } = useSearchIdentities(
		search.lens,
		isEntitledToPaginatedTable,
	)

	const tabItems = useMemo(
		() =>
			values(IdentityTabName).map((tabName) => ({
				key: tabName,
				label: <Tooltip title={TabToTooltip[tabName]}>{capitalize(tabName)}</Tooltip>,
			})),
		[],
	)

	const onTabChange = (activeKey: string) => {
		void navigate({
			to: RoutePaths.Identities,
			search: (prev) => {
				const newSearch = { ...prev } as IdentitiesPageSearch
				delete newSearch.identityFilter
				delete newSearch.pagination
				newSearch.tab = activeKey as IdentityTabName
				return newSearch
			},
		})
	}

	const onIdentityClicked = (row: RowClickedEvent<ServerIdentitiesTableRow>) => {
		const selection = window.getSelection()?.toString()
		if (selection?.length && selection.length > 0) {
			return
		}

		if (row.node.group) return

		// Navigate to single identity page if possible
		if (
			isEntitledToIdentityPage &&
			row.data?.source &&
			SUPPORTED_IDENTITY_PAGE_SOURCE_TYPES.includes(row.data.source)
		) {
			void navigate({
				to: RoutePaths.Identity,
				params: { identityId: row.data.id },
				...(isEmpty(search) ? undefined : { search: { identitiesPage: search } }),
			})
			return
		}

		// Check if there are any issues and sort them by priority descending
		const highestPriorityIssueId =
			row.data?.issues?.sort((a, b) => {
				return (b.priority || PriorityType.NOT_CALCULATED) - (a.priority || PriorityType.NOT_CALCULATED)
			})[0]?.id ?? '' // Use the ID of the issue with the highest priority, or default to empty string

		void navigate({
			search: (prev) => ({ ...prev, issueId: highestPriorityIssueId }),
		})
	}

	const onIdentitiesTableFilterChanged = useCallback(
		(e: FilterChangedEvent<ServerIdentitiesTableRow>) => {
			const changedColumnId = e.columns.length === 1 ? e.columns[0].getId() : undefined
			refreshDynamicFilterValues(gridRef.current?.api, changedColumnId)

			// For the non-paginated table, we need a way to signal a stats refresh when the filter changes.
			//  This should be removed once we only support the paginated table
			setStatsRefreshHack((currentValue) => currentValue + 1)

			const identityFilter = gridRef?.current?.api?.getFilterModel()
			// Synchronize filter changes from the table to the URL
			void navigate({
				search: (prev) => {
					const newSearch = { ...prev }
					if (isEmpty(identityFilter)) {
						delete newSearch.identityFilter
					} else {
						// We need to maintain the order of existing filters so that the filter chips will remain in
						//  the same order and not shuffle around. When we get the filter model from the grid API the
						//  order of filters there is the order of the columns in the table, and not the order in which
						//  the filters were applied.
						const newIdentityFilter = { ...newSearch.identityFilter }
						// First, go over existing filters and remove/update them according to the new table filter
						//  model.
						const existingFilterFields = Object.keys(newIdentityFilter)
						existingFilterFields.forEach((filterField) => {
							if (filterField in identityFilter) {
								newIdentityFilter[filterField] = identityFilter[filterField] as TableFilterEntry
							} else {
								// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
								delete newIdentityFilter[filterField]
							}
						})

						// Now add new filter fields from the table filter model.
						const newFilterFields = difference(Object.keys(identityFilter), existingFilterFields)
						newFilterFields.forEach((filterField) => {
							newIdentityFilter[filterField] = identityFilter[filterField] as TableFilterEntry
						})

						newSearch.identityFilter = newIdentityFilter
					}

					// When a filter change we need to reset the page to the first page (0)
					if (isEntitledToPaginatedTable && newSearch.pagination) {
						if (newSearch.pagination.pageSize === defaultPageSize) {
							delete newSearch.pagination
						} else {
							newSearch.pagination = { pageSize: newSearch.pagination.pageSize }
						}
					}

					return newSearch
				},
			})
		},
		[isEntitledToPaginatedTable],
	)

	// An effect to match the internal table filter to the one in the URL
	useEffect(() => {
		const currentFilterModel = gridRef?.current?.api?.getFilterModel()
		// When the internal table filter changes we set the URL to match it, causing this effect to re-run. To avoid a
		//  loop, we only set the internal filter to the URL if it is different from the one that is already set in the
		//  table
		if (isEqual(currentFilterModel, search.identityFilter)) {
			return
		}

		gridRef?.current?.api?.setFilterModel(search.identityFilter ?? null)
	}, [search.identityFilter])

	const onPaginationChanged = useCallback((event: PaginationChangedEvent<ServerIdentitiesTableRow>) => {
		if (!event.newPage && !event.newPageSize) {
			return
		}

		const page = event.api.paginationGetCurrentPage()
		const pageSize = event.api.paginationGetPageSize()
		void navigate({
			search: (prev) => {
				if (page === 0 && pageSize === defaultPageSize) {
					return omit(prev, 'pagination')
				} else {
					return { ...prev, pagination: { page, pageSize } }
				}
			},
		})
	}, [])

	// An effect to match the internal table pagination to the one in the URL
	useEffect(() => {
		const currentPage = gridRef?.current?.api?.paginationGetCurrentPage() ?? 0
		const currentPageSize = gridRef?.current?.api?.getGridOption('paginationPageSize') ?? defaultPageSize
		const currentSearchPage = search.pagination?.page ?? 0
		const currentSearchPageSize = search.pagination?.pageSize ?? defaultPageSize
		// When the internal table pagination changes we set the URL to match it, causing this effect to re-run. To
		//  avoid a loop, we only set the internal pagination to the URL if it is different from the one that is already
		//  set in the table
		if (currentPageSize !== currentSearchPageSize) {
			gridRef?.current?.api?.setGridOption('paginationPageSize', currentSearchPageSize)
		}
		if (currentPage !== currentSearchPage) {
			gridRef?.current?.api?.paginationGoToPage(search.pagination?.page ?? 0)
		}
	}, [search.pagination?.page, search.pagination?.pageSize])

	const onGridReady = useCallback(() => {
		gridRef?.current?.api?.setFilterModel(search.identityFilter ?? null)
		const filterSearchText = search.identityFilter?.[inventoryUrlQuickSearchFilterParameter] as TableTextFilterEntry
		setSearchText(filterSearchText?.filter ?? '')
	}, [search.identityFilter])

	const onGridPreDestroyed = useCallback(() => {
		setGridReady(false)
	}, [])

	const onFirstDataRendered = useCallback(() => {
		if (search.issueId) {
			gridRef?.current?.api?.forEachNode((node: IRowNode<ServerIdentitiesTableRow>) => {
				if (node.data?.issues?.some((issue) => issue.id === search.issueId)) {
					node.setSelected(true)
					gridRef?.current?.api?.ensureNodeVisible(node, 'middle')
				}
			})
		}

		gridRef?.current?.api?.sizeColumnsToFit()

		if (isEntitledToIdentityPage) {
			if (search.pagination) {
				gridRef?.current?.api?.setGridOption('paginationPageSize', search.pagination.pageSize)
				gridRef?.current?.api?.paginationGoToPage(search.pagination.page ?? 0)
			}

			// Pre-fetch dynamic filter values
			refreshDynamicFilterValues(gridRef?.current?.api)
		}

		setGridReady(true)
	}, [isEntitledToIdentityPage, search.issueId, search.pagination])

	const clearFilters = useCallback(() => {
		void navigate({
			search: (prev) => {
				const newSearch = { ...prev }
				delete newSearch.identityFilter
				return newSearch
			},
		})
	}, [])

	return (
		<Layout className="h-full rounded-md">
			<IdentitiesSideBar />
			<Layout.Content className="overflow-y-clip overflow-x-auto">
				<div className="h-full flex flex-col relative overflow-hidden">
					<div
						style={{
							backgroundColor: '#FFFFFF',
							border: '2px solid #FFFFFF',
							boxShadow: '0px 0px 10px 0px #0000001A',
						}}
						className="mb-2 rounded"
					>
						<div
							style={{
								border: '2px solid #FFFFFF',
								boxShadow: '0px 0px 10px 0px #0000001A',
							}}
							className="flex flexrow"
						>
							<div className="flex flex-row items-center p-2">
								<IdentitiesIcon />
								<span className="text-2xl font-semibold pl-1">Inventory</span>
							</div>
							{isEntitledToPaginatedTable && (
								<div className="flex w-5/6 justify-center items-center content-center">
									<Tabs
										className="full-width-tabs"
										defaultActiveKey={IdentityTabName.MANAGED}
										activeKey={search.tab}
										onChange={onTabChange}
										size="large"
										tabBarStyle={{
											marginBottom: '0px',
										}}
										items={tabItems}
									/>
								</div>
							)}
						</div>
						{isEntitledToPaginatedTable ? <IdentitiesStatsPaginated /> : <IdentitiesStats />}
					</div>
					{isIdentitiesLoading && !isEntitledToPaginatedTable ? (
						<Skeleton className="m-2" loading={isIdentitiesLoading} active />
					) : (
						<>
							<Row className="px-2 items-center justify-between space-x-2 flex-nowrap">
								<Col className="flex flex-row items-center space-x-2 shrink-0">
									<IdentitiesQuickSearch />
									<Tooltip placement="topLeft" title="Clear filters">
										<Button
											icon={<ClearFilterIcon />}
											className="bg-white text-gray-500 !p-1"
											onClick={clearFilters}
										/>
									</Tooltip>
								</Col>
								<Col className="grow overflow-hidden">
									<FilterView />
								</Col>
								<Col className="shrink-0">
									<IdentitiesExport />
								</Col>
							</Row>
							<Row className="h-full w-full flex">
								{isEntitledToPaginatedTable ? (
									<IdentitiesTablePaginated
										onIdentityClicked={onIdentityClicked}
										onIdentityTableFilterChanged={onIdentitiesTableFilterChanged}
										onGridReady={onGridReady}
										onGridPreDestroyed={onGridPreDestroyed}
										onFirstDataRendered={onFirstDataRendered}
										onPaginationChanged={onPaginationChanged}
									/>
								) : (
									<IdentitiesTable
										identitiesTableData={identitiesData}
										onIdentityClicked={onIdentityClicked}
										onIdentityTableFilterChanged={onIdentitiesTableFilterChanged}
										onGridReady={onGridReady}
										onGridPreDestroyed={onGridPreDestroyed}
										onFirstDataRendered={onFirstDataRendered}
									/>
								)}
							</Row>
						</>
					)}

					<IssueDrawer route={RoutePaths.Identities} />
				</div>
			</Layout.Content>
		</Layout>
	)
}
