import { ServerIdentity, IdentitySource } from '../../../schemas/identity.ts'
import { ServerAwsTag } from '../../../schemas/identities/awsPoliciesSchema.ts'
import { AwsTagsTable } from '../../../tables/aws/AwsTagsTable.tsx'

type SidePanelTagsProps = {
	identity: ServerIdentity
}

export const SidePanelTags = ({ identity }: SidePanelTagsProps) => {
	if (identity.source === IdentitySource.AWS_IAM_USER || identity.source === IdentitySource.AWS_IAM_ROLE) {
		const tags: ServerAwsTag[] =
			identity.aws_iam_user?.aws_iam_user_details_xc?.Tags ||
			identity.aws_iam_role?.aws_iam_role_details_xc?.Tags ||
			[]

		if (!tags.length) {
			return 'No tags data'
		}

		return <AwsTagsTable tags={tags} />
	}

	return `Tags are not supported for ${identity.source}`
}
