import { RowClassParams } from '@ag-grid-community/core'
import { PriorityType, IssueTablePaginatedRow, ServerIssue } from '../schemas/issue.ts'
import { priorityColors } from '../schemas/colors.ts'
import { ServerIdentitiesTableRow } from '../schemas/identity.ts'
import { AccountPriorityEnum } from '../schemas/environments/accountPriorityEnum.ts'
import { getIssuesMaxPriority } from '../utils/issueUtils.ts'

export const getPriorityColor = (priority?: PriorityType | null): string => {
	switch (priority) {
		case PriorityType.NOT_CALCULATED:
			return priorityColors.PRIORITY_NOT_CALCULATED_COLOR
		case PriorityType.LOW:
			return priorityColors.PRIORITY_LOW_COLOR
		case PriorityType.MEDIUM:
			return priorityColors.PRIORITY_MEDIUM_COLOR
		case PriorityType.HIGH:
			return priorityColors.PRIORITY_HIGH_COLOR
		case PriorityType.CRITICAL:
			return priorityColors.PRIORITY_CRITICAL_COLOR
		default:
			return '#FFFFFF'
	}
}

export const getPriorityColorWithOpacity = (priority?: PriorityType | null): string => {
	return `${getPriorityColor(priority)}9F`
}

export const getPriorityColorLight = (priority?: PriorityType | null): string => {
	switch (priority) {
		case PriorityType.NOT_CALCULATED:
			return priorityColors.PRIORITY_NOT_CALCULATED_COLOR_LIGHT
		case PriorityType.LOW:
			return priorityColors.PRIORITY_LOW_COLOR_LIGHT
		case PriorityType.MEDIUM:
			return priorityColors.PRIORITY_MEDIUM_COLOR_LIGHT
		case PriorityType.HIGH:
			return priorityColors.PRIORITY_HIGH_COLOR_LIGHT
		case PriorityType.CRITICAL:
			return priorityColors.PRIORITY_CRITICAL_COLOR_LIGHT
		default:
			return '#FFFFFF'
	}
}

export const getAccountPriorityColor = (priority?: AccountPriorityEnum | null): string => {
	switch (priority) {
		case AccountPriorityEnum.NONE:
			return priorityColors.PRIORITY_NOT_CALCULATED_COLOR
		case AccountPriorityEnum.UNKNOWN:
			return priorityColors.PRIORITY_NOT_CALCULATED_COLOR
		case AccountPriorityEnum.LOW:
			return priorityColors.PRIORITY_LOW_COLOR
		case AccountPriorityEnum.MEDIUM:
			return priorityColors.PRIORITY_MEDIUM_COLOR
		case AccountPriorityEnum.HIGH:
			return priorityColors.PRIORITY_HIGH_COLOR
		case AccountPriorityEnum.CRITICAL:
			return priorityColors.PRIORITY_CRITICAL_COLOR
		default:
			return '#FFFFFF'
	}
}

export const getAccountPriorityColorLight = (priority?: AccountPriorityEnum | null): string => {
	switch (priority) {
		case AccountPriorityEnum.NONE:
			return priorityColors.PRIORITY_NOT_CALCULATED_COLOR_LIGHT
		case AccountPriorityEnum.UNKNOWN:
			return priorityColors.PRIORITY_NOT_CALCULATED_COLOR_LIGHT
		case AccountPriorityEnum.LOW:
			return priorityColors.PRIORITY_LOW_COLOR_LIGHT
		case AccountPriorityEnum.MEDIUM:
			return priorityColors.PRIORITY_MEDIUM_COLOR_LIGHT
		case AccountPriorityEnum.HIGH:
			return priorityColors.PRIORITY_HIGH_COLOR_LIGHT
		case AccountPriorityEnum.CRITICAL:
			return priorityColors.PRIORITY_CRITICAL_COLOR_LIGHT
		default:
			return '#FFFFFF'
	}
}

export const getIssueStyle = (priority?: PriorityType | null) => {
	const color = getPriorityColor(priority)
	return { boxShadow: `4px 0px 0px 0px ${color} inset`, borderRadius: '4px 0px 0px 4px' }
}

export const getIssuesTableRowStyle = (
	params: RowClassParams<ServerIssue> | RowClassParams<IssueTablePaginatedRow>,
) => {
	return getIssueStyle(params.data?.priority)
}

export const getIdentitiesTableRowStyle = (params: RowClassParams<ServerIdentitiesTableRow>) => {
	if (!params.data) {
		return
	}

	const maxPriority = params.data.max_priority ?? getIssuesMaxPriority(params.data.issues)
	return getIssueStyle(maxPriority)
}
