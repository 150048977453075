import { Server } from 'miragejs/server'
import { Response } from 'miragejs'
import {
	ClientIssueSearchSchema,
	IssueImpactProbabilityWithCount,
	ServerIssue,
	ServerIssueStats,
} from '../../schemas/issue.ts'
import { compareIssues, filterIssues, countIssueStats } from './utils.ts'
import { AppSchema } from '../schemas.ts'
import { getAllIssues, getAllIssueTypes, getIssueById } from '../mirageORM.ts'

export function mirageById(server: Server) {
	server.get('/issues/:id', (schema: AppSchema, request): ServerIssue | Response => {
		const serverIssue = getIssueById(schema, request.params.id)
		if (!serverIssue) {
			return new Response(404, {}, { message: 'Issue not found' })
		}
		return serverIssue
	})
}

export function mirageSearch(server: Server) {
	server.post('/issues/search', (schema, request): ServerIssue[] => {
		return filterIssues(getAllIssues(schema), ClientIssueSearchSchema.parse(JSON.parse(request.requestBody))).sort(
			compareIssues,
		)
	})
}

export function mirageStats(server: Server) {
	server.get('/issues/stats', (schema): ServerIssueStats[] => {
		return countIssueStats(getAllIssues(schema))
	})
}

export function mirageIssueTypes(server: Server) {
	server.get('/issues/types', (schema): IssueImpactProbabilityWithCount[] => {
		return getAllIssueTypes(schema)
	})
}

export function mirageUpdateIssueTypes(server: Server) {
	server.put('/issues/types', () => null)
}
