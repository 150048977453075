export enum RoutePaths {
	Any = '*',
	Index = '/',
	Findings = '/findings',
	Identities = '/identities',
	Metrics = '/metrics',
	Logout = '/logout',
	Identity = '/identities/$identityId',
	Swagger = '/swagger',
	Settings = '/settings',
	Integrations = '/integrations',
	SlackIntegration = '/slack-integration',
	Itdr = '/itdr',
}

export type PathWithIssueIdSearch = RoutePaths.Findings | RoutePaths.Identities | RoutePaths.Identity | RoutePaths.Itdr
