import z from 'zod'
import { DateSchema } from '../common.ts'
import { ServerAwsTagSchema } from './awsPoliciesSchema.ts'
import { ServerAwsEc2InstanceXcSchema } from './awsEc2InstanceXcSchema.ts'
import { ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { ServerAwsAccountSchema } from '../environments/awsAccountSchema.ts'

export const ServerAwsKeyPairSchema = z.object({
	name: z.string(),
	environment_id: z.string(),
	sub_env_id: z.string(),
	key_pair_id: z.string(),
	region: z.string(),

	last_activity: DateSchema.nullish(),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),

	tags: z.array(ServerAwsTagSchema),
	key_fingerprint: z.string().nullish(),
	key_type: z.union([z.literal('ed25519'), z.literal('rsa')]).nullish(),
	public_key: z.string().nullish(),
	aws_account: ServerAwsAccountSchema.nullish(),

	aws_ec2_instances_xc: z.array(ServerAwsEc2InstanceXcSchema),
	aws_iam_role_details_xc: z.array(ServerAwsIamRoleXcSchema),
})

export type ServerAwsKeyPair = z.infer<typeof ServerAwsKeyPairSchema>
