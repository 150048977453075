import ReactDOM from 'react-dom/client'
import './styles/index.css'
import { AppRouter } from './routes/Router.tsx'
import { StrictMode } from 'react'
import { settings } from './env-settings.ts'
import { ModuleRegistry } from '@ag-grid-community/core'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { AgCharts } from 'ag-charts-enterprise'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { CsvExportModule } from '@ag-grid-community/csv-export'
import makeMirageServer from './mirage/server.ts'
import { isDemo } from './utils/demoUtils.ts'

AgCharts.setLicenseKey(settings.viteAgChartLicense!)
LicenseManager.setLicenseKey(settings.viteAgGridLicense!)
ModuleRegistry.registerModules([
	ClientSideRowModelModule,
	MasterDetailModule,
	SetFilterModule,
	ColumnsToolPanelModule,
	FiltersToolPanelModule,
	StatusBarModule,
	ServerSideRowModelModule,
	CsvExportModule,
])

if (isDemo) {
	makeMirageServer()
}

ReactDOM.createRoot(document.getElementById('root')!).render(
	<StrictMode>
		<AppRouter />
	</StrictMode>,
)
