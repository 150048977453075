import { Node, NodeProps } from '@xyflow/react'
import { BaseCredentialsNode } from '../../common/baseNodes/BaseCredentialsNode.tsx'
import { ServerAzureDevopsPat } from '../../../../../schemas/identities/azure/azureDevops/azureDevopsPatSchema.ts'
import { useHighlightedNodesContext } from '../../../HighlightedNodesContext.tsx'

export type AzureDevopsPatNodeType = Node<{ pat: ServerAzureDevopsPat }, 'azureDevopsPat'>
type AzureDevopsPatNodeProps = NodeProps<AzureDevopsPatNodeType>

export const AzureDevopsPatNode = ({ data: { pat } }: AzureDevopsPatNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find(
		(highlightedNode) => highlightedNode.type === 'azureDevopsPat' && highlightedNode.id === pat.access_id,
	)

	return (
		<BaseCredentialsNode
			label="Personal Access Token"
			name={{
				text: pat.display_name,
			}}
			isHighlighted={isHighlighted}
		/>
	)
}
