import { MutableRefObject, useCallback, useRef, useState } from 'react'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { Button, Col, Layout, Row, Tabs, Tooltip } from 'antd'
import { FindingsTablePaginated } from './FindingsTablePaginated.tsx'
import { isDemo } from '../../utils/demoUtils.ts'
import FindingsIcon from '../../assets/findings_not_clicked_icon_20.svg?react'
import { IssueDrawer } from '../../components/drawers/issueDrawer'
import { RoutePaths } from '../RoutePaths.tsx'
import { MenuInfo } from 'rc-menu/lib/interface'
import {
	IssueName,
	IssuesPageLens,
	IssuesTabName,
	IssueTablePaginatedRow,
	PaginatedFindingsPageSearch,
	PaginatedIssueFilter,
} from '../../schemas/issue.ts'
import { AgGridReact } from '@ag-grid-community/react'
import { FindingsSideBarPaginatedMenu } from './FindingsSideBarPaginated.tsx'
import { FindingsStatsPaginated } from './stats/FindingsStatsPaginated.tsx'
import { FindingsQuickSearch } from './FindingsQuickSearch.tsx'
import ClearFilterIcon from '../../assets/clear_filter_icon_20.svg?react'
import { FindingsExport } from './FindingsExport.tsx'

const { Content } = Layout

const TabToTooltip: Partial<Record<IssuesTabName, string>> = {
	[IssuesTabName.OPEN]: 'Open and in-progress issues',
	[IssuesTabName.RESOLVED]: 'Resolved issues',
	[IssuesTabName.SUPPRESSED]: 'Ignored and false-positive issues',
	[IssuesTabName.ALL]: 'All issues',
}

export const FindingsPaginated = () => {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { tab } = useSearch({ from: RoutePaths.Findings }) as PaginatedFindingsPageSearch
	const navigate = useNavigate({ from: RoutePaths.Findings })
	const gridRef: MutableRefObject<AgGridReact<IssueTablePaginatedRow> | null> = useRef(null)

	const [tabItems] = useState(() =>
		Object.values(IssuesTabName).map((tabName) => ({
			key: tabName,
			label: TabToTooltip[tabName] ? <Tooltip title={TabToTooltip[tabName]}>{tabName}</Tooltip> : tabName,
			children: null,
		})),
	)

	const onTabChange = (activeKey: string) => {
		void navigate({
			to: RoutePaths.Findings,
			search: (prev) => {
				const newSearch = { ...prev } as PaginatedFindingsPageSearch
				delete newSearch.issueFilter
				delete newSearch.pagination
				newSearch.tab = activeKey as IssuesTabName
				return newSearch
			},
		})
	}

	const onFindingsSideBarMenuClick = (e: MenuInfo) => {
		if (Object.values(IssuesPageLens).includes(e.key as IssuesPageLens)) {
			const newLens = e.key as IssuesPageLens
			void navigate({
				search: () => {
					return { lens: newLens, tab: IssuesTabName.OPEN }
				},
			})
		} else if (Object.values(IssueName).includes(e.key as IssueName)) {
			const issueName = e.key as IssueName
			void navigate({
				search: (prev) => {
					const newIssueFilter = { ...(prev.issueFilter as PaginatedIssueFilter) }
					// eslint-disable-next-line camelcase
					newIssueFilter.issue_name = {
						filterType: 'set',
						values: [issueName],
					}
					return { issueFilter: newIssueFilter, tab: IssuesTabName.OPEN }
				},
			})
		} else {
			void navigate({
				search: () => {
					return {}
				},
			})
		}
	}

	const clearFilters = useCallback(() => {
		void navigate({
			search: (prev) => {
				const newSearch = { ...prev }
				delete newSearch.issueFilter
				return newSearch
			},
		})
	}, [])

	return (
		<Layout className="h-full rounded-md">
			<FindingsSideBarPaginatedMenu onFindingsSideBarMenuClick={onFindingsSideBarMenuClick} gridRef={gridRef} />
			<Content className="overflow-y-clip">
				<div className="h-full flex flex-col relative overflow-hidden">
					<div className="mb-2 rounded border-2 border-white shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)]">
						<div
							className="flex flexrow border-white shadow-[0px_0px_10px_0px_rgba(0,0,0,0.1)]"
							style={{
								backgroundColor: '#FFFFFF',
								border: '2px solid #FFFFFF',
								boxShadow: '0px 0px 10px 0px #0000001A',
							}}
						>
							<div className="flex flex-row items-center p-2">
								<FindingsIcon />
								<span className="text-2xl font-semibold pl-1 whitespace-nowrap">{`${isDemo ? 'Security Posture' : 'Findings'}`}</span>
							</div>
							<div className="flex w-5/6 justify-center items-center content-center">
								<Tabs
									className="full-width-tabs"
									defaultActiveKey="Open"
									activeKey={tab}
									onChange={onTabChange}
									size="large"
									tabBarStyle={{
										marginBottom: '0px',
									}}
									items={tabItems}
								/>
							</div>
						</div>
						<FindingsStatsPaginated />
					</div>
					<Row className="px-2 items-center justify-between">
						<Col className="flex flex-row items-center gap-2">
							<FindingsQuickSearch />
							<Tooltip placement="topLeft" title="Clear filters">
								<Button
									icon={<ClearFilterIcon />}
									className="text-gray-500 !p-1"
									onClick={clearFilters}
								/>
							</Tooltip>
						</Col>
						<Col>
							<FindingsExport gridRef={gridRef} />
						</Col>
					</Row>
					<Row className="h-full w-full flex">
						<FindingsTablePaginated gridRef={gridRef} />
					</Row>
					<IssueDrawer route={RoutePaths.Findings} />
				</div>
			</Content>
		</Layout>
	)
}
