import { z } from 'zod'
import { DateSchema } from '../common'
import { ServerAwsIamUserSchema } from './awsIamUserSchema.ts'
import { ServerAwsAccountSchema } from '../environments/awsAccountSchema.ts'

export const ServerAwsIamGroupSchema = z.object({
	name: z.string(),
	arn: z.string(),
	created_at: DateSchema,
	updated_at: DateSchema.nullish(),
	last_activity: DateSchema.nullish(),
	group_id: z.string(),
	// aws_iam_group_details_xc: ServerAwsIamUserPermissionXcSchema.nullish(),
	aws_iam_users_xc: z.array(ServerAwsIamUserSchema).nullish(),
	aws_account: ServerAwsAccountSchema.nullish(),
})

export type ServerAwsIamGroup = z.infer<typeof ServerAwsIamGroupSchema>
