import { IssuesPageLens } from '../../schemas/issue.ts'
import ResourcesIcon from '../../assets/resources.svg?react'
import AssignedToMeIcon from '../../assets/assigned_to_me_icon.svg?react'
import FindingsIcon from '../../assets/findings_not_clicked_icon_20.svg?react'
import AwsIcon from '../../assets/aws_icon.svg?react'
import GcpIcon from '../../assets/gcp_icon_20.svg?react'
import AzureIcon from '../../assets/azure_icon.svg?react'
import OktaIcon from '../../assets/okta_icon_no_color.svg?react'
import JumpcloudIcon from '../../assets/jumpcloud_icon_16.svg?react'
import EntraIdIcon from '../../assets/entra_id_icon_16.svg?react'
import GoogleWorkspaceIcon from '../../assets/google_workspace_logo_16.svg?react'
import JiraIcon from '../../jiraIntegration/assets/jira_logo.svg?react'
import AdminTagIcon from '../../assets/identityTagIcons/admin_tag_icon_16.svg?react'
import CredentialsExposureTagIcon from '../../assets/identityTagIcons/credentials_exposure_tag_icon_16.svg?react'
import ResourceExposureTagIcon from '../../assets/identityTagIcons/resource_exposure_tag_icon_16.svg?react'
import DataAccessTagIcon from '../../assets/identityTagIcons/data_access_tag_icon_16.svg?react'
import PrivilegeEscalationTagIcon from '../../assets/identityTagIcons/privilege_escalation_tag_icon_16.svg?react'

export const IssuesPageLensMap: Record<
	IssuesPageLens,
	{
		icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
		title: string
	}
> = {
	[IssuesPageLens.ALL]: { icon: ResourcesIcon, title: 'All' },
	[IssuesPageLens.ASSIGNED_TO_ME]: { icon: AssignedToMeIcon, title: 'Assigned To Me' },
	[IssuesPageLens.ISSUES]: { icon: FindingsIcon, title: 'Issues' },
	[IssuesPageLens.AWS]: { icon: AwsIcon, title: 'AWS' },
	[IssuesPageLens.GCP]: { icon: GcpIcon, title: 'GCP' },
	[IssuesPageLens.AZURE]: { icon: AzureIcon, title: 'Azure' },
	[IssuesPageLens.OKTA]: { icon: OktaIcon, title: 'Okta' },
	[IssuesPageLens.ENTRA_ID]: { icon: EntraIdIcon, title: 'Entra ID' },
	[IssuesPageLens.JUMPCLOUD]: { icon: JumpcloudIcon, title: 'Jumpcloud' },
	[IssuesPageLens.GOOGLE_WORKSPACE]: { icon: GoogleWorkspaceIcon, title: 'Google Workspace' },
	[IssuesPageLens.JIRA]: { icon: JiraIcon, title: 'Jira' },
	[IssuesPageLens.ADMIN]: { icon: AdminTagIcon, title: 'Admin' },
	[IssuesPageLens.CREDENTIAL_EXPOSURE]: { icon: CredentialsExposureTagIcon, title: 'Credential Exposure' },
	[IssuesPageLens.RESOURCE_EXPOSURE]: { icon: ResourceExposureTagIcon, title: 'Resource Exposure' },
	[IssuesPageLens.DATA_ACCESS]: { icon: DataAccessTagIcon, title: 'Data Access' },
	[IssuesPageLens.POSSIBLE_PRIVILEGE_ESCALATION]: { icon: PrivilegeEscalationTagIcon, title: 'Privilege Escalation' },
}
