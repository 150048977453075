import React from 'react'

export const CollapsibleItemLabel: React.FunctionComponent<{
	label: string
	icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}> = ({ label, icon }) => {
	const Icon = icon
	return (
		<div className={`flex items-center`}>
			<Icon style={{ width: '28px', height: '28px', margin: '0 8px 0 0' }} />
			<div>{label}</div>
		</div>
	)
}
