import { ServerIdentity } from '../../../schemas/identity.ts'
import { getCombinedAwsIamRolePolicy } from '../../../utils/awsIdentityUtils.ts'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { IamPermissionsTable } from '../../../tables/IamPermissionsTable.tsx'
import { useHighlightedNodesContext } from '../HighlightedNodesContext.tsx'
import { CellMouseOverEvent } from '@ag-grid-community/core'
import { CombinedAwsIamRolePolicy } from '../../../schemas/identities/awsIamRoleXcSchema.ts'
import { GcpProjectsTable } from '../../../tables/gcp/GcpProjectsTable.tsx'
import { DatabricksRolesTable } from '../../../tables/DatabricksRolesTable.tsx'
import { LastSelectedNode } from '../IdentityPageBody.tsx'
import { EntraIDRolesTable } from '../../../tables/entraId/EntraIDRolesTable.tsx'
import { SnowflakeRolesTable } from '../../../tables/SnowflakeRolesTable.tsx'
import type { ServerSnowflakeRoleXc } from '../../../schemas/identities/snowflake/snowflakeRoleXcSchema.ts'
import { GithubAppPermissionsTable } from '../../../tables/github/GithubAppPermissionsTable.tsx'
import { SalesforcePermissionSetsTable } from '../../../tables/salesforce/SalesforcePermissionSetsTable.tsx'
import { SalesforceProfileTable } from '../../../tables/salesforce/SalesforceProfileTable.tsx'
import { Collapse, CollapseProps } from 'antd'
import { CollapsibleItemLabel } from '../../../components/common/CollaplsibleItemLabel.tsx'
import SalesforceIcon from '../../../assets/salesforce_icon.svg?react'
import { SalesforceConnectedMetadataTable } from '../../../tables/salesforce/SalesforceConnectedMetadataTable.tsx'
import { AdACEsTable } from '../../../tables/activeDirectory/AdACEsTable.tsx'
import AdIcon from '../../../assets/active_directory_icon_16.svg?react'
import { AdGroupsTable } from '../../../tables/activeDirectory/AdGroupsTable.tsx'

export type PermissionsCollapseItemKeys =
	| 'salesforceProfile'
	| 'salesforcePermissionSet'
	| 'salesforceConnectedAppMetadata'
	| 'adGroups'
	| 'adAces'

type PermissionsCollapseItem = Required<CollapseProps>['items'][number] & {
	key: PermissionsCollapseItemKeys
}

type SidePanelPermissionsProps = {
	identity: ServerIdentity
	lastSelectedNode?: LastSelectedNode | null
	activePermissionsKeys: PermissionsCollapseItemKeys[]
	setActivePermissionsKeys: Dispatch<SetStateAction<PermissionsCollapseItemKeys[]>>
}

export const SidePanelPermissions = ({
	identity,
	lastSelectedNode,
	activePermissionsKeys,
	setActivePermissionsKeys,
}: SidePanelPermissionsProps) => {
	const awsPolicies = useMemo(
		() =>
			getCombinedAwsIamRolePolicy(
				identity.aws_iam_user?.aws_iam_user_details_xc,
				identity.aws_iam_role?.aws_iam_role_details_xc ||
					identity.aws_key_pair?.aws_iam_role_details_xc ||
					identity.aws_ec2_instance?.aws_iam_role_details_xc,
				identity.aws_iam_role?.permission_boundary,
				identity.aws_iam_user?.permission_boundary,
			),
		[identity],
	)
	const { setHighlightedNodes } = useHighlightedNodesContext()

	const onCellMouseOut = () => {
		setHighlightedNodes([])
	}
	const gcpRolesXc = identity.gcp_service_account?.service_account_projects_roles_xc
	const databricksRoles = identity?.databricks_service_principal?.roles || identity?.databricks_user?.roles
	const entraIdRoles =
		identity.entra_id_user?.entra_id_role_assignments ||
		identity.entra_id_service_principal?.entra_id_role_assignments
	const snowflakeRoles = identity.snowflake_user?.snowflake_roles_xc
	const githubAppPermissions = identity.github_app_installation?.permissions
	const salesforcePermissionSets = identity.salesforce_user?.permission_sets
	const salesforceProfile = identity.salesforce_user?.profile
	const salesforceConnectedAppOAuthMetadata = identity.salesforce_connected_application?.app_metadata
	const adUser = identity.active_directory_user
	const adGroups = adUser?.group_memberships?.map((membership) => membership.group_data) || []
	const adAces = adUser?.aces || []

	const salesforceItems: PermissionsCollapseItem[] = useMemo(() => {
		const items: PermissionsCollapseItem[] = []

		if (salesforceProfile) {
			items.push({
				label: <CollapsibleItemLabel label="Salesforce Profile" icon={SalesforceIcon} />,
				headerClass: '!items-center',
				key: 'salesforceProfile',
				children: (
					<SalesforceProfileTable
						data={salesforceProfile}
						lastSelectedProfile={
							lastSelectedNode?.type === 'salesforceProfile' ? lastSelectedNode.id : undefined
						}
					/>
				),
			})
		}

		if (salesforcePermissionSets?.length) {
			items.push({
				label: <CollapsibleItemLabel label="Salesforce Permission Sets" icon={SalesforceIcon} />,
				headerClass: '!items-center',
				key: 'salesforcePermissionSet',
				children: (
					<SalesforcePermissionSetsTable
						data={salesforcePermissionSets}
						lastSelectedPermissionSet={
							lastSelectedNode?.type === 'salesforcePermissionSet' ? lastSelectedNode.id : undefined
						}
					/>
				),
			})
		}

		if (salesforceConnectedAppOAuthMetadata) {
			items.push({
				label: <CollapsibleItemLabel label="Salesforce Connected Application Metadata" icon={SalesforceIcon} />,
				headerClass: '!items-center',
				key: 'salesforceConnectedAppMetadata',
				children: (
					<SalesforceConnectedMetadataTable
						data={salesforceConnectedAppOAuthMetadata}
						lastSelectedMetadata={
							lastSelectedNode?.type === 'salesforceConnectedAppMetadata'
								? lastSelectedNode.id
								: undefined
						}
					/>
				),
			})
		}

		return items
	}, [salesforceProfile, salesforcePermissionSets, salesforceConnectedAppOAuthMetadata, lastSelectedNode])

	const adItems: PermissionsCollapseItem[] = useMemo(() => {
		const items: PermissionsCollapseItem[] = []

		if (adGroups.length > 0) {
			items.push({
				label: <CollapsibleItemLabel label="Active Directory Groups" icon={AdIcon} />,
				headerClass: '!items-center',
				key: 'adGroups',
				children: (
					<AdGroupsTable
						groups={adGroups}
						lastSelectedGroup={lastSelectedNode?.type === 'adGroup' ? lastSelectedNode.id : undefined}
					/>
				),
			})
		}

		if (adAces.length > 0) {
			items.push({
				label: <CollapsibleItemLabel label="Active Directory ACEs" icon={AdIcon} />,
				headerClass: '!items-center',
				key: 'adAces',
				children: <AdACEsTable aces={adAces} />,
			})
		}

		return items
	}, [adGroups, adAces, lastSelectedNode])

	const onPermissionsCollapseChange = useCallback(
		(key: string | string[]) => {
			const keyList = Array.isArray(key)
				? (key as PermissionsCollapseItemKeys[])
				: [key as PermissionsCollapseItemKeys]
			setActivePermissionsKeys(keyList)
		},
		[setActivePermissionsKeys],
	)

	if (
		!awsPolicies?.length &&
		!gcpRolesXc?.length &&
		!databricksRoles?.length &&
		!entraIdRoles?.length &&
		!snowflakeRoles?.length &&
		!githubAppPermissions?.length &&
		!salesforcePermissionSets?.length &&
		!salesforceProfile &&
		!salesforceConnectedAppOAuthMetadata &&
		!adGroups.length &&
		!adAces.length
	) {
		return (
			<div className="flex gap-1">
				<span>No permissions found for identity</span>
				<span className="font-semibold">{identity.literal_friendly_name || identity.literal}</span>
			</div>
		)
	}

	if (gcpRolesXc?.length) {
		return <GcpProjectsTable gcpProjectsXc={gcpRolesXc} />
	}

	if (databricksRoles?.length) {
		return <DatabricksRolesTable data={databricksRoles} />
	}

	if (entraIdRoles?.length) {
		return (
			<EntraIDRolesTable
				data={entraIdRoles}
				lastSelectedRole={lastSelectedNode?.type === 'detailedEntraIdRole' ? lastSelectedNode.id : undefined}
			/>
		)
	}

	if (githubAppPermissions?.length) {
		return <GithubAppPermissionsTable data={githubAppPermissions} />
	}

	if (snowflakeRoles?.length) {
		return (
			<SnowflakeRolesTable
				data={snowflakeRoles}
				lastSelectedRole={lastSelectedNode?.type === 'snowflakeRole' ? lastSelectedNode.id : undefined}
				onCellMouseOver={(event: CellMouseOverEvent<ServerSnowflakeRoleXc>) => {
					setHighlightedNodes([{ type: 'snowflakeRole', id: event.node.id! }])
				}}
				onCellMouseOut={onCellMouseOut}
			/>
		)
	}

	if (salesforcePermissionSets?.length || salesforceProfile || salesforceConnectedAppOAuthMetadata) {
		return (
			<Collapse
				onChange={onPermissionsCollapseChange}
				activeKey={activePermissionsKeys}
				items={salesforceItems}
				style={{ backgroundColor: 'white' }}
				expandIconPosition={'end'}
			/>
		)
	}

	if (adGroups.length || adAces.length) {
		return (
			<Collapse
				onChange={onPermissionsCollapseChange}
				activeKey={activePermissionsKeys}
				items={adItems}
				style={{ backgroundColor: 'white' }}
				expandIconPosition={'end'}
			/>
		)
	}

	return (
		<IamPermissionsTable
			data={awsPolicies}
			lastSelectedPolicyId={lastSelectedNode?.type === 'awsPolicy' ? lastSelectedNode.id : undefined}
			onCellMouseOver={(event: CellMouseOverEvent<CombinedAwsIamRolePolicy>) => {
				setHighlightedNodes([{ type: 'awsPolicy', id: event.node.id! }])
			}}
			onCellMouseOut={onCellMouseOut}
		/>
	)
}
