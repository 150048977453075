import { Edge } from '@xyflow/react'
import { IssueName } from '../../../../schemas/issue.ts'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { AccessKeyNodeType } from './AccessKeyNode.tsx'
import { AwsPolicyNodeType } from './AwsPolicyNode.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import {
	aggregateAwsIamChangeLogs,
	getCombinedAwsIamRolePolicy,
	getPolicyUniqueKey,
} from '../../../../utils/awsIdentityUtils.ts'
import { Ec2InstancesNodeType } from './Ec2InstancesNode.tsx'
import { KubernetesResourcesNodeType } from '../common/KubernetesResourcesNode.tsx'
import { ServerKubernetesResourceXc } from '../../../../schemas/identities/kubernetes/kubernetesResourceXcSchema.ts'
import { OktaUserNodeType } from '../okta/OktaUserNode.tsx'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { ServerAwsIamChangeLog } from '../../../../schemas/identities/awsIamChangeLogSchema.ts'
import { OwnershipNodeType } from '../common/OwnershipNode.tsx'
import { getActorArnFriendlyName } from '../../../../components/drawers/identities/utils.tsx'
import { ServerAwsIamRoleXc } from '../../../../schemas/identities/awsIamRoleXcSchema.ts'
import { AwsRoleNodeType } from './AwsRoleNode.tsx'
import { Ec2InstanceKeyPairNodeType } from './Ec2InstanceKeyPairNode.tsx'

const nodeLogicalTypeToColumnId = {
	ec2AndK8s: 0,
	generalIssue: 1,
	identity: 2,
	accessKey: 3,
	accessKeyIssue: 4,
	keyPair: 5,
	role: 6,
	policy: 7,
}

const ownerNodeRowIndex = 0
const ownerNodeId = `${nodeLogicalTypeToColumnId.identity}-${ownerNodeRowIndex}`
const identityNodeRowIndex = 1
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`
const oktaUserNodeRowIndex = 2
const oktaUserNodeId = `${nodeLogicalTypeToColumnId.identity}-${oktaUserNodeRowIndex}`

const accessKeyIssueNames: IssueName[] = [
	IssueName.AccessKeyNotRotated,
	IssueName.InactiveAccessKey,
	IssueName.MultipleAccessKeys,
	IssueName.EKSAccessKeyReferencedInPod,
	IssueName.AccessKeyCreatedByOffboardedEmployee,
	IssueName.UserUsedFromAttackOrPentestingFramework,
	IssueName.ServiceAccountSuspiciousBehavior,
]

export const getAwsNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<
		BareNodeType<IdentityNodeType> | BareNodeType<OktaUserNodeType> | BareNodeType<OwnershipNodeType>
	> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const changeLogs: ServerAwsIamChangeLog[] =
		identity.aws_iam_user?.change_logs || identity.aws_iam_role?.change_logs || []
	if (changeLogs?.length) {
		const aggregatedChangeLogs = aggregateAwsIamChangeLogs(changeLogs)
		identityNodes.unshift({
			type: 'ownership',
			data: {
				owners: aggregatedChangeLogs.map((aggChangeLog) => ({
					id: aggChangeLog.actorArn,
					name: getActorArnFriendlyName(aggChangeLog.actorArn),
				})),
			},
			id: ownerNodeId,
		})

		edges.push(
			getEdge({
				source: ownerNodeId,
				target: identityNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	if (identity.aws_iam_user?.okta_user_xc?.length) {
		// TODO: We currently use only the first okta user as a node, fix if need rises, requires proper UI design
		const details = identity.aws_iam_user.okta_user_xc[0]
		const displayName = details.profile?.displayName || details.profile?.email
		identityNodes.push({
			type: 'oktaUser',
			data: { oktaUser: { type: 'Okta User', displayName } },
			id: oktaUserNodeId,
		})

		edges.push(
			getEdge({
				source: identityNodeId,
				target: oktaUserNodeId,
				sourceHandle: 'bottom',
				targetHandle: 'top',
				animated: true,
			}),
		)
	}

	const accessKeyIssueNodes: BareNodeType<IssueNodeType>[] = []
	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []
	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		if (issue.issue_name && accessKeyIssueNames.includes(issue.issue_name)) {
			accessKeyIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.accessKeyIssue}-${accessKeyIssueNodes.length}`,
			})
		} else {
			generalIssueNodes.push({
				type: 'issue',
				data: { issue },
				id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
			})
		}
	})

	const accessKeyNodes: BareNodeType<AccessKeyNodeType>[] =
		identity.aws_iam_user?.aws_iam_access_keys_xc?.map((accessKey, index) => ({
			type: 'accessKey',
			data: { accessKey },
			id: `${nodeLogicalTypeToColumnId.accessKey}-${index}`,
		})) || []

	const policies = getCombinedAwsIamRolePolicy(
		identity.aws_iam_user?.aws_iam_user_details_xc,
		identity.aws_iam_role?.aws_iam_role_details_xc,
		identity.aws_iam_role?.permission_boundary,
		identity.aws_iam_user?.permission_boundary,
	)

	const policyNodes: BareNodeType<AwsPolicyNodeType>[] = policies.map((policy, index) => ({
		type: 'awsPolicy',
		data: { policy },
		id: `${nodeLogicalTypeToColumnId.policy}-${index}`,
	}))

	const ec2AndK8sNodes: Array<BareNodeType<Ec2InstancesNodeType> | BareNodeType<KubernetesResourcesNodeType>> = []
	if (identity.aws_iam_role?.aws_ec2_instances_xc?.length) {
		ec2AndK8sNodes.push({
			type: 'ec2Instances',
			data: { instances: identity.aws_iam_role.aws_ec2_instances_xc },
			id: `${nodeLogicalTypeToColumnId.ec2AndK8s}-${ec2AndK8sNodes.length}`,
		})
	} else if (identity.aws_key_pair?.aws_ec2_instances_xc?.length) {
		ec2AndK8sNodes.push({
			type: 'ec2Instances',
			data: { instances: identity.aws_key_pair.aws_ec2_instances_xc },
			id: `${nodeLogicalTypeToColumnId.ec2AndK8s}-${ec2AndK8sNodes.length}`,
		})
	}

	const kubernetesResources: ServerKubernetesResourceXc[] =
		identity.aws_iam_user?.kubernetes_resources_xc || identity.aws_iam_role?.kubernetes_resources_xc || []
	if (kubernetesResources.length) {
		ec2AndK8sNodes.push({
			type: 'kubernetesResources',
			data: { resources: kubernetesResources },
			id: `${nodeLogicalTypeToColumnId.ec2AndK8s}-${ec2AndK8sNodes.length}`,
		})
	}

	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	accessKeyNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessKey}-${index}`
		edges.push(getEdge({ source: source, target: identityNodeId }))
	})

	const keyPairNodes: BareNodeType<Ec2InstanceKeyPairNodeType>[] = []
	const keyPair = identity.aws_ec2_instance?.key_pair ?? null
	if (keyPair) {
		const keyPairNodeId = `${nodeLogicalTypeToColumnId.keyPair}-0`
		keyPairNodes.push({
			type: 'keyPair',
			data: { keyPair },
			id: keyPairNodeId,
		})
		edges.push(getEdge({ source: keyPairNodeId, target: identityNodeId }))
	}

	// Connect every access key issue node to either:
	//  - All access key nodes, if the issue is for multiple access keys.
	//  - A single access key node, if the issue is for a specific key.
	//  - The identity node if there is no relevant access key node.
	accessKeyIssueNodes.forEach((issueNode, index) => {
		const source = `${nodeLogicalTypeToColumnId.accessKeyIssue}-${index}`
		// There are no access key nodes - connect the issue node to the identity node.
		if (!accessKeyNodes.length) {
			edges.push(getEdge({ source: source, target: identityNodeId }))
			return
		}

		// There are access keys and the issue is multiple access keys - connect the issue to all access key nodes.
		if (issueNode.data.issue.issue_name === IssueName.MultipleAccessKeys) {
			accessKeyNodes.forEach((_, accessKeyIndex) => {
				const target = `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyIndex}`
				edges.push(getEdge({ source, target }))
			})
		} else {
			// There are access keys and the issue node should connect to one of them - try and find the relevant
			//  access key node. If it is not found (e.g. the access key was already deleted) - connect the issue node
			//  to the identity node.
			const accessKeyNodeIndex = accessKeyNodes.findIndex(
				(accessKeyNode) =>
					!!accessKeyNode.data.accessKey?.access_key_id &&
					issueNode.data.issue.description?.includes(accessKeyNode.data.accessKey.access_key_id),
			)
			const target =
				accessKeyNodeIndex === -1
					? identityNodeId
					: `${nodeLogicalTypeToColumnId.accessKey}-${accessKeyNodeIndex}`
			edges.push(getEdge({ source, target }))
		}
	})

	// Handle aws key pair base identity - multiple roles and policies possible
	const roleNodes: BareNodeType<AwsRoleNodeType>[] = []
	const policyToRoleNodes: BareNodeType<AwsPolicyNodeType>[] = []
	if (identity.aws_key_pair?.aws_iam_role_details_xc || identity.aws_ec2_instance?.aws_iam_role_details_xc) {
		const roleDetails = identity.aws_key_pair?.aws_iam_role_details_xc
			? identity.aws_key_pair?.aws_iam_role_details_xc
			: [identity.aws_ec2_instance?.aws_iam_role_details_xc as ServerAwsIamRoleXc]

		roleDetails.forEach((role) => {
			roleNodes.push({
				type: 'awsIamRole',
				data: { awsIamRoleXc: role },
				id: `${nodeLogicalTypeToColumnId.role}-${roleNodes.length}`,
			})

			// Create policy nodes and edges to the role
			const policies = getCombinedAwsIamRolePolicy(null, role)
			policies.forEach((policy) => {
				if (
					!policyToRoleNodes.find(
						(node) => getPolicyUniqueKey(node.data.policy) === getPolicyUniqueKey(policy),
					)
				)
					policyToRoleNodes.push({
						type: 'awsPolicy',
						data: { policy },
						id: `${nodeLogicalTypeToColumnId.policy}-${policyToRoleNodes.length}`,
					})
				const index = policyToRoleNodes.findIndex(
					(node) => getPolicyUniqueKey(node.data.policy) === getPolicyUniqueKey(policy),
				)
				// Add edge between role and policy based on direction
				edges.push(
					getEdge({
						source: `${nodeLogicalTypeToColumnId.role}-${roleNodes.length - 1}`,
						target: `${nodeLogicalTypeToColumnId.policy}-${index}`,
					}),
				)
			})
		})
		// Connect roles to identity node
		roleNodes.forEach((_, index) => {
			const target = `${nodeLogicalTypeToColumnId.role}-${index}`
			edges.push(getEdge({ source: identityNodeId, target }))
		})
	}

	policyNodes.forEach((_, index) => {
		const target = `${nodeLogicalTypeToColumnId.policy}-${index}`
		edges.push(getEdge({ source: identityNodeId, target }))
	})

	ec2AndK8sNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.ec2AndK8s}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	// Each item represents a column in the graph. Order in the array will be the order in the graph (left->right)
	return [
		[
			{ yPosition: 'top', nodes: ec2AndK8sNodes },
			{ yPosition: 'center', nodes: [...accessKeyIssueNodes, ...generalIssueNodes] },
			{
				yPosition: generalIssueNodes.length > 0 ? 'top' : 'center',
				nodes: accessKeyNodes.length > 0 ? accessKeyNodes : keyPairNodes,
			},
			{ yPosition: 'center', nodes: identityNodes },
			{ yPosition: 'center', nodes: roleNodes },
			{ yPosition: 'center', nodes: policyToRoleNodes.length > 0 ? policyToRoleNodes : policyNodes },
		],
		edges,
	]
}
