import { Tag, Tooltip } from 'antd'
import React from 'react'
import { formatDate } from '../../../../utils'
import { EntraIdCommonAuthenticationCredential } from '../../../../schemas/identities/entraId/entraIdApplicationXc'

export const EntraIdSpAuthenticationCredential: React.FunctionComponent<{
	credential?: EntraIdCommonAuthenticationCredential
	onOpenChange?: (visible: boolean) => void
}> = ({ credential, onOpenChange }) => {
	const now = new Date()
	const isActive = credential?.endDateTime && new Date(credential.endDateTime) > now

	return (
		<div className="flex items-end truncate">
			<Tooltip
				onOpenChange={onOpenChange}
				overlayInnerStyle={{
					width: 'fit-content',
				}}
				title={
					<div className="flex flex-col w-full">
						<span className="flex text-sm text-gray-300">Display Name: {credential?.displayName}</span>
						<span className="flex text-sm text-gray-300">Key ID: {credential?.keyId}</span>
						<span className="flex text-sm text-gray-300">
							Start Date: {formatDate(credential?.startDateTime)}
						</span>
						<span className="flex text-sm text-gray-300">
							End Date: {formatDate(credential?.endDateTime)}
						</span>
						<span className="flex text-sm text-gray-300">
							Last Used: {formatDate(credential?.lastSignIn)}
						</span>
						<span className="flex text-sm text-gray-300">Type: {credential?.type}</span>
					</div>
				}
			>
				<div className="flex items-center">
					<Tag className={`pl-1 text text-sm ${isActive ? 'text-green-700' : 'text-red-700'}`}>
						{credential?.keyId}
					</Tag>
				</div>
			</Tooltip>
		</div>
	)
}
