import { useIdentityStats } from '../../../api/identities.ts'
import { Tooltip } from 'antd'
import { useCallback, useMemo } from 'react'
import { DonutChartWithCustomLegend } from '../../../components/common/DonutChartWithCustomLegend.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RoutePaths } from '../../RoutePaths.tsx'
import { isEmpty } from 'lodash'
import { Tag, TagsColorsMap, TagsDisplayNameMap } from '../../../schemas/tags.ts'
import { tagsIconsMap } from '../../../components/common/IdentityTag/common.tsx'
import { TableSetFilterEntry } from '../../../schemas/tableFilter.ts'
import { IdentitiesStatsCount } from './IdentitiesStatsCount.tsx'
import { IdentitiesStatsItem } from './IdentitiesStatsItem.tsx'

const seriesColorResolver = (risk: Tag): string => TagsColorsMap[risk]
const seriesTooltipTitleResolver = (risk: Tag): string => TagsDisplayNameMap[risk]
const legendTitleResolver = (risk: Tag): React.ReactNode => {
	const { icon: Icon } = tagsIconsMap[risk]
	return (
		<Tooltip title={seriesTooltipTitleResolver(risk)}>
			<span>
				<Icon className="text-base h-5 w-5 flex justify-center" />
			</span>
		</Tooltip>
	)
}

export type IdentitiesStatsRisksProps = {
	statsQuery: ReturnType<typeof useIdentityStats>
}

export const IdentitiesStatsRisks = ({ statsQuery }: IdentitiesStatsRisksProps) => {
	const { identityFilter } = useSearch({ from: RoutePaths.Identities })
	const { data: identityStats } = statsQuery
	const navigate = useNavigate({ from: RoutePaths.Identities })

	const activeRisks: Tag[] = useMemo(() => {
		if (!identityFilter?.tags_names) {
			return []
		}

		return (identityFilter.tags_names as TableSetFilterEntry).values as Tag[]
	}, [identityFilter])

	const data = useMemo(() => {
		if (!identityStats?.count_by_risk) {
			return []
		}

		return Object.values(Tag).map((tag) => ({
			key: tag,
			count: identityStats.count_by_risk[tag] ?? 0,
		}))
	}, [identityStats])

	const setActiveRisks = useCallback(
		(activeRisksParam: (currentActiveRisks: Tag[]) => Tag[]) => {
			const newActiveRisks = activeRisksParam(activeRisks)
			void navigate({
				search: (prevSearch) => {
					const newIdentityFilter = { ...prevSearch.identityFilter }
					if (isEmpty(newActiveRisks)) {
						delete newIdentityFilter.tags_names
					} else {
						// eslint-disable-next-line camelcase
						newIdentityFilter.tags_names = { filterType: 'set', values: newActiveRisks }
					}

					const newSearch = { ...prevSearch }
					if (isEmpty(newIdentityFilter)) {
						delete newSearch.identityFilter
					} else {
						newSearch.identityFilter = newIdentityFilter
					}

					return newSearch
				},
			})
		},
		[activeRisks, navigate],
	)

	const totalCount = useMemo(() => {
		if (!identityStats) {
			return 0
		}

		return identityStats.risky_count
	}, [identityStats])

	return (
		<IdentitiesStatsItem title="Identities at Risk" statsQuery={statsQuery}>
			<IdentitiesStatsCount count={totalCount} />
			<div className="flex items-center gap-2">
				<DonutChartWithCustomLegend
					activeKeys={activeRisks}
					data={data}
					legendTitleResolver={legendTitleResolver}
					seriesColorResolver={seriesColorResolver}
					seriesTooltipTitleResolver={seriesTooltipTitleResolver}
					setActiveKeys={setActiveRisks}
					dimensions={80}
					legendBorders
				/>
			</div>
		</IdentitiesStatsItem>
	)
}
