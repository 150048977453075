import { z } from 'zod'
import { AccountPriorityEnum } from './accountPriorityEnum.ts'
import { ResourceType } from '../issue.ts'

export const AccountSchema = z.object({
	id: z.string(),
	name: z.string(),
	environment_type: z.nativeEnum(ResourceType),
	identity_count: z.number(),
	priority: z.nativeEnum(AccountPriorityEnum),
	created_at: z.string(),
	updated_at: z.string(),
	customer_id: z.string(),
})

export type Account = z.infer<typeof AccountSchema>
