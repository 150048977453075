import { Col, Divider, Row, Space, Tag } from 'antd'
import { ServerOktaUser } from '../../../schemas/identities/oktaUserSchema.ts'
import { formatDate, formatRelativeDateText } from '../../../utils'
import { AwsIamRoleContext } from './AwsIamRoleContext'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import React, { useEffect, useState } from 'react'
import { AwsIamUserContext } from './AwsIamUserContext'
import { IdentityTypeTag } from '../../common/IdentityTypeTag'
import { IssueSourceIcon } from '../../common/IssueSourceIcon'
import { ServerIdentity } from '../../../schemas/identity'
import { GoogleWorkspaceUserContext } from './GoogleWorkspaceUserContext.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'

export const OktaUserIdentity: React.FunctionComponent<{
	identity?: ServerIdentity | null
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const [contextAmount, setContentAmount] = useState(0)
	const oktaUser: ServerOktaUser | null | undefined = identity?.okta_user
	useEffect(() => {
		calcAmountOfContext()
	})

	const calcAmountOfContext = () => {
		let amount = 0
		if (oktaUser?.aws_iam_roles_xc?.length) amount++
		if (oktaUser?.aws_iam_users_xc?.length) amount++
		if (oktaUser?.google_workspace_user_xc) amount++
		setContentAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				{oktaUser?.user_status ? (
					<BaseIdentityDetail label="Status" span={1}>
						<Tag>
							<div className="flex items-center">
								<div className="text-gray-800 text font-medium">{oktaUser?.user_status}</div>
							</div>
						</Tag>
					</BaseIdentityDetail>
				) : null}
				<BaseIdentityDetail label="Email" value={oktaUser?.email} span={1} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Environment" span={1}>
					<IssueSourceIcon source={envType} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Name" value={oktaUser?.name} span={1} />
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.last_activity, true)}
					tooltipTitle={formatDate(identity?.last_activity)}
					span={1}
				/>{' '}
				<BaseIdentityDetail label="Activated At" value={formatDate(oktaUser?.activated)} span={1} />
				<BaseIdentityDetail label="Password Changed" value={formatDate(oktaUser?.password_changed)} span={1} />
				<BaseIdentityDetail label="Status" value={oktaUser?.user_status} span={1} />
			</div>
			<div className="w-full">
				{contextAmount > 0 && (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{oktaUser?.aws_iam_roles_xc && oktaUser?.aws_iam_roles_xc.length > 0 && (
									<AwsIamRoleContext awsIamRolesXc={oktaUser?.aws_iam_roles_xc} />
								)}
								{oktaUser?.aws_iam_users_xc && oktaUser?.aws_iam_users_xc.length > 0 && (
									<AwsIamUserContext awsIamUsersXc={oktaUser?.aws_iam_users_xc} />
								)}
								{oktaUser?.google_workspace_user_xc && (
									<GoogleWorkspaceUserContext
										googleWorkspaceUserXc={[oktaUser?.google_workspace_user_xc]}
									/>
								)}
							</Space>
						</Row>
					</div>
				)}
			</div>
		</>
	)
}
