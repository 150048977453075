import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, GridReadyEvent } from '@ag-grid-community/core'
import { load } from 'js-yaml'

type AwsAuthUser = {
	userarn: string
	username: string
	groups: string[]
}

type AwsAuthRole = {
	rolearn: string
	username: string
	groups: string[]
}

type AuthTableItem = (AwsAuthUser | AwsAuthRole) & {
	arn: string
	type: string
}

export const KubernetesAwsAuthTable: React.FunctionComponent<{
	awsAuthConfigMapData: { mapRoles: string; mapUsers: string }
}> = ({ awsAuthConfigMapData }) => {
	const mapRolesJson: AwsAuthRole[] = load(awsAuthConfigMapData.mapRoles) as AwsAuthRole[]
	const mapUsersJson: AwsAuthUser[] = load(awsAuthConfigMapData.mapUsers) as AwsAuthUser[]

	const combinedData: AuthTableItem[] = [
		...mapUsersJson.map((item) => ({ ...item, arn: item.userarn, type: 'User' })),
		...mapRolesJson.map((item) => ({ ...item, arn: item.rolearn, type: 'Role' })),
	]

	const gridRef = useRef<AgGridReact<AuthTableItem>>(null)
	const columnDefs = useMemo<ColDef<AuthTableItem>[]>(() => {
		return [
			{
				field: 'type',
				headerName: 'Type',
				flex: 0.5,
			},
			{
				field: 'username',
				headerName: 'Username',
				flex: 1,
			},
			{
				field: 'arn',
				headerName: 'ARN',
				flex: 1,
			},
			{
				field: 'groups',
				headerName: 'Groups',
				flex: 1,
				cellRenderer: (params: { value: string[] }) => params.value.join(', '),
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback((_params: GridReadyEvent) => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine overflow-x-auto'}
				ref={gridRef}
				rowData={combinedData}
				rowHeight={54}
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
