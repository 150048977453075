import { Edge } from '@xyflow/react'
import { IdentityNodeType } from '../common/IdentityNode.tsx'
import { IssueNodeType } from '../common/IssueNode.tsx'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { getEdge } from '../graphUtils/nodesAndEdges.ts'
import { issuePrioritySorter } from '../../../../utils/issueUtils.ts'
import { BareNodesColumnsType, BareNodeType } from '../identityGraphTypes.ts'
import { processAwsRoles } from '../aws/crossAwsGraphUtils.ts'

const nodeLogicalTypeToColumnId = {
	generalIssue: 0,
	identity: 1,
	awsAccount: 2,
	awsRoles: 3,
	awsPolicy: 4,
}
const identityNodeRowIndex = 0
const identityNodeId = `${nodeLogicalTypeToColumnId.identity}-${identityNodeRowIndex}`

export const getOktaNodesAndEdges = (identity: ServerIdentity): [BareNodesColumnsType[], Edge[]] => {
	const edges: Edge[] = []
	const identityNodes: Array<BareNodeType<IdentityNodeType>> = [
		{
			type: 'identity',
			data: { identity },
			id: identityNodeId,
		},
	]

	const generalIssueNodes: BareNodeType<IssueNodeType>[] = []

	identity.issues?.toSorted(issuePrioritySorter)?.forEach((issue) => {
		generalIssueNodes.push({
			type: 'issue',
			data: { issue },
			id: `${nodeLogicalTypeToColumnId.generalIssue}-${generalIssueNodes.length}`,
		})
	})
	const { awsRolesNodes, awsAccountNodes, policyNodes, awsEdges } = processAwsRoles(
		identityNodeId,
		identity.okta_user?.aws_iam_roles_xc ?? null,
		nodeLogicalTypeToColumnId,
	)
	edges.push(...awsEdges)

	// -------------------- Edges --------------------
	generalIssueNodes.forEach((_, index) => {
		const source = `${nodeLogicalTypeToColumnId.generalIssue}-${index}`
		edges.push(getEdge({ source, target: identityNodeId }))
	})

	const nodes: BareNodesColumnsType[] = [
		{ yPosition: 'center', nodes: generalIssueNodes },
		{ yPosition: 'center', nodes: identityNodes },
		{ yPosition: 'center', nodes: awsAccountNodes },
		{ yPosition: 'center', nodes: awsRolesNodes },
		{ yPosition: 'center', nodes: policyNodes },
	]
	return [nodes, edges]
}
