import { z } from 'zod'
import { ServerAzureDevopsPatSchema } from './azureDevopsPatSchema.ts'
import { ServerEntraIdUserXcSchema } from '../../entraId/entraIdUserXcSchema.ts'

export const BaseAzureDevopsUserSchema = z.object({
	directory_alias: z.string().nullish(),
	domain: z.string(),
	principal_name: z.string(),
	mail_address: z.string(),
	origin: z.string(),
	origin_id: z.string(),
	display_name: z.string(),
	descriptor: z.string(),
	personal_access_tokens: z.array(ServerAzureDevopsPatSchema).default([]),
})

export const ServerAzureDevopsUserSchema = BaseAzureDevopsUserSchema.extend({
	// We use ServerEntraIdUserXcSchema even though the backed uses ServerEntraIdUserSchema.
	entra_id_user: z.lazy(() => ServerEntraIdUserXcSchema.nullish()),
})
