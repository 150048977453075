import { FronteggProvider, useAuth as FrontEggAuth, useAuthActions, useLoginWithRedirect } from '@frontegg/react'
import React, { useLayoutEffect } from 'react'
import { initSentry } from '../sentry/sentry'
import { Loading } from '../../components/common/Loading'
import { InitLogRocket } from '../logrocket/logrocket'
import { updateAccessToken } from '../../api/api'

const AuthContext = ({ children }: { children: React.ReactNode }) => {
	const { isAuthenticated, user, tenantsState } = FrontEggAuth()
	const isImpersonating = user?.act !== undefined
	const { loadRolesAndPermissions } = useAuthActions()
	const loginWithRedirect = useLoginWithRedirect()

	useLayoutEffect(() => {
		// NOTE: this is for debugging purposes. Do not remove.
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, no-extra-semi
		;(window as any).$user = user

		if (!isAuthenticated) {
			loginWithRedirect()
		} else {
			loadRolesAndPermissions()
			if (user && tenantsState?.activeTenant) {
				updateAccessToken(user.accessToken)
				InitLogRocket(user, tenantsState.activeTenant, isImpersonating)
				initSentry()
			}
		}
	}, [isAuthenticated, loginWithRedirect, loadRolesAndPermissions, user, tenantsState])

	return !isAuthenticated ? <Loading /> : <>{children}</>
}

interface AuthProviderProps {
	baseUrl: string
	clientId: string
	children?: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, baseUrl, clientId }) => {
	return (
		<FronteggProvider
			contextOptions={{
				baseUrl,
				clientId,
			}}
			entitlementsOptions={{ enabled: true }}
			hostedLoginBox={true}
			authOptions={{
				keepSessionAlive: true,
			}}
		>
			<AuthContext>{children}</AuthContext>
		</FronteggProvider>
	)
}
