import { Node, NodeProps } from '@xyflow/react'
import { SafetyCertificateOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { BaseNode } from '../../common/baseNodes/BaseNode.tsx'
import { ServerAzureKeyVault } from '../../../../../schemas/identities/azure/azureKeyVault/azureKeyVault.ts'

export type AzureKeyVaultNodeType = Node<{ keyVault: ServerAzureKeyVault }, 'azureKeyVault'>
type AzureKeyVaultNodeProps = NodeProps<AzureKeyVaultNodeType>

export const AzureKeyVaultNode = ({ data: { keyVault } }: AzureKeyVaultNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.dependencies.bgHover} ${nodeColorClassNames.dependencies.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)

	return (
		<BaseNode label="Azure Key Vault" name={{ text: keyVault.name || 'Unknown Key Vault' }}>
			<div className={iconWrapperClassName}>
				<SafetyCertificateOutlined />
			</div>
		</BaseNode>
	)
}
