import { PriorityBar } from './PriorityBar.tsx'
import { useCallback, useMemo } from 'react'
import {
	PaginatedFindingsPageSearch,
	PaginatedIssueFilter,
	PriorityType,
	PriorityTypeEnum,
	PriorityTypeNameToNumber,
} from '../../../schemas/issue.ts'
import { RoutePaths } from '../../RoutePaths.tsx'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { isEmpty } from 'lodash'
import { TableSetFilterEntry } from '../../../schemas/tableFilter.ts'
import { FindingsStatsItem } from './FindingsStatsItem.tsx'
import { usePaginatedIssuesStats } from '../../../api/issues.ts'

type FindingsStatsPostureProps = {
	statsQuery: ReturnType<typeof usePaginatedIssuesStats>
}

export const FindingsStatsPosture = ({ statsQuery }: FindingsStatsPostureProps) => {
	const { data: issuesStats } = statsQuery
	const navigate = useNavigate({ from: RoutePaths.Findings })
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
	const { issueFilter: issuesFilter } = useSearch({ from: RoutePaths.Findings }) as PaginatedFindingsPageSearch

	const findingsByPriority = useMemo(() => {
		if (!issuesStats) return []
		return [PriorityType.CRITICAL, PriorityType.HIGH, PriorityType.MEDIUM, PriorityType.LOW].map((priority) => {
			let disabled = false
			if (
				issuesFilter?.priority &&
				(issuesFilter.priority as TableSetFilterEntry).values.length &&
				!(issuesFilter.priority as TableSetFilterEntry).values.includes(priority.toString())
			) {
				disabled = true
			}

			return {
				priorityNumber: priority,
				priority: PriorityType[priority] as PriorityTypeEnum,
				count: issuesStats.count_by_priority[priority] ?? 0,
				disabled,
			}
		})
	}, [issuesStats])

	const filterByPostureScore = useCallback((priority: string) => {
		const priorityString = `${PriorityTypeNameToNumber[priority as PriorityTypeEnum]}`
		void navigate({
			search: (prevSearch) => {
				const newIssueFilter = { ...(prevSearch.issueFilter as PaginatedIssueFilter) }
				let currentPriorities = (newIssueFilter?.priority as TableSetFilterEntry | undefined)?.values ?? []
				if (currentPriorities.includes(priorityString)) {
					currentPriorities = currentPriorities.filter(
						(currentPriority) => currentPriority !== priorityString,
					)
				} else {
					currentPriorities = [...currentPriorities, priorityString]
				}
				if (isEmpty(currentPriorities)) {
					delete newIssueFilter.priority
				} else {
					newIssueFilter.priority = { filterType: 'set', values: currentPriorities as string[] }
				}

				const newSearch = { ...prevSearch }
				if (isEmpty(newIssueFilter)) {
					delete newSearch.issueFilter
				} else {
					newSearch.issueFilter = newIssueFilter
				}

				return newSearch
			},
		})
	}, [])

	return (
		<FindingsStatsItem title="Posture" statsQuery={statsQuery}>
			<PriorityBar
				findingsByPriority={findingsByPriority}
				onPriorityNameTagClick={filterByPostureScore}
				keepZeroValues
			/>
		</FindingsStatsItem>
	)
}
