import { ZodEffects, z } from 'zod'
import camelcaseKeys from 'camelcase-keys'
import { CamelCase } from 'type-fest'

// https://github.com/colinhacks/zod/issues/486
export const camelizeSchema = <T extends z.ZodTypeAny>(
	zod: T,
): ZodEffects<z.ZodTypeAny, CamelCasedPropertiesDeep<T['_output']>> =>
	zod.transform((val) => camelcaseKeys(val, { deep: true }) as CamelCasedPropertiesDeep<T>)

export interface CamelCaseOptions {
	preserveConsecutiveUppercase?: boolean
}

// https://github.com/colinhacks/zod/issues/486#issuecomment-1634329740
export type CamelCasedPropertiesDeep<
	Value,
	Options extends CamelCaseOptions = { preserveConsecutiveUppercase: true },
	// eslint-disable-next-line @typescript-eslint/ban-types
> = Value extends Function | Date | RegExp // skip js primitives
	? Value
	: Value extends (infer U)[]
		? CamelCasedPropertiesDeep<U, Options>[]
		: Value extends Set<infer U>
			? Set<CamelCasedPropertiesDeep<U, Options>>
			: {
					[K in keyof Value as CamelCase<K, Options>]: CamelCasedPropertiesDeep<Value[K], Options>
				}
