import { z } from 'zod'
import { DateSchema } from '../../../common.ts'
import { ServerLifetimeActionSchema } from './commonSchemas.ts'
import { ServerAzureKeyVaultSchema } from './azureKeyVault.ts'
import { ServerEntraIdChangeLogSchema } from '../../entraId/entraIdChangeLogSchema.ts'

export const ServerCertificatePolicySchema = z.object({
	created_at: DateSchema,
	updated_at: DateSchema.nullable(),
	expires_in: z.string().nullable().optional(),
	key_type: z.string().nullable(),
	key_size: z.number().int().nullable(),
	key_curve_name: z.string().nullable(),
	issuer_name: z.string().nullable(),
	reuse_key: z.boolean().nullable(),
	exportable: z.boolean(),
	subject_name: z.string().nullable(),
	lifetime_actions: z.array(ServerLifetimeActionSchema).nullable(),
	validity_in_months: z.number().int().nullable(),
})

export const ServerAzureKVCertificateSchema = z.object({
	name: z.string(),
	certificate_id: z.string(),
	created_at: DateSchema,
	updated_at: DateSchema.nullable(),
	expiration_date: DateSchema.nullable(),
	start_date: DateSchema.nullable(),
	is_enabled: z.boolean(),
	tags: z.record(z.string(), z.string()).nullable(),
	vault_name: z.string(),
	vault_id: z.string(),
	key_vault: ServerAzureKeyVaultSchema.nullable(),
	subscription_id: z.string().uuid(),
	cert_policy: ServerCertificatePolicySchema.nullable(),
	change_logs: z.array(ServerEntraIdChangeLogSchema).nullish(),
})

export type ServerAzureKVCertificate = z.infer<typeof ServerAzureKVCertificateSchema>
