import { z } from 'zod'
import { DateSchema } from '../../common'
import { ServerDatabricksRoleSchema } from './ServerDatabricksRoleSchema.ts'
import { ServerDatabricksWorkspaceSchema } from './ServerDatabricksWorkspaceSchema.ts'
import { ServerGithubRepositorySchema } from '../githubUserSchema.ts'

export const ServerDatabricksPersonalAccessTokenSchema = z
	.object({
		token_id: z.string(),
		created_at: DateSchema,
		last_used: DateSchema.nullish(),
		expires_at: DateSchema.nullish(),
	})
	.nullable()

export type ServerDatabricksPersonalAccessToken = z.infer<typeof ServerDatabricksPersonalAccessTokenSchema>

export const ServerDatabricksUserSchema = z
	.object({
		user_id: z.string(),
		username: z.string(),
		email: z.string(),
		first_name: z.string(),
		last_name: z.string(),
		status: z.string(),
		admin: z.boolean(),
		created_at: DateSchema,
		updated_at: DateSchema,
		roles: z.array(ServerDatabricksRoleSchema).nullable(),
		personal_access_tokens: z.array(ServerDatabricksPersonalAccessTokenSchema).nullable(),
		workspaces: z.array(ServerDatabricksWorkspaceSchema).nullable(),
		github_repos_xc: z.array(ServerGithubRepositorySchema).nullable(),
	})
	.nullable()

export type ServerDatabricksUser = z.infer<typeof ServerDatabricksUserSchema>
