import { EnvironmentType } from '../../../../schemas/envType'
import { ServerIdentity } from '../../../../schemas/identity'
import React, { useMemo } from 'react'
import { BaseIdentityDetail } from '../baseIdentities/BaseIdentityDetail.tsx'
import { IssueSourceIcon } from '../../../common/IssueSourceIcon.tsx'
import { IdentityTypeTag } from '../../../common/IdentityTypeTag.tsx'
import { formatDate, formatRelativeDateText } from '../../../../utils.ts'
import { Col, Divider, Row, Space } from 'antd'
import { DatabricksRolesContext } from './DatabricksRolesContext.tsx'
import { ServerDatabricksServicePrincipal } from '../../../../schemas/identities/databricks/ServerDatabricksServicePrincipalSchema.ts'
import { DatabricksOAuthTokensContext } from './DatabricksOauthTokensContext.tsx'
import { AwsIamUserChangeLogsContext } from '../AwsIamUserChangeLogsContext.tsx'
import { KubernetesResourceContext } from '../kubernetes/KubernetesResourceContext.tsx'
import { EndpointAccessContext } from '../EndpointAccessContext.tsx'

export const DatabricksServicePrincipalIdentity: React.FunctionComponent<{
	identity?: ServerIdentity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const databricksServicePrincipal: ServerDatabricksServicePrincipal | null =
		identity?.databricks_service_principal ?? null
	const contextAmount = useMemo<number>(() => {
		let count = 0
		if (databricksServicePrincipal?.roles?.length ?? 0 > 0) count++
		if (databricksServicePrincipal?.oauth_tokens?.length ?? 0 > 0) count++
		if (databricksServicePrincipal?.change_logs?.length ?? 0 > 0) count++
		if (databricksServicePrincipal?.kubernetes_resources_xc?.length ?? 0 > 0) count++
		if (databricksServicePrincipal?.endpoint_access_xc?.length ?? 0 > 0) count++
		return count
	}, [databricksServicePrincipal])
	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="ID" value={databricksServicePrincipal?.sp_id} span={1} />
				<BaseIdentityDetail label="Name" value={databricksServicePrincipal?.sp_name} span={1} />

				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Environment" span={1}>
					<IssueSourceIcon source={envType} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.last_activity, true)}
					tooltipTitle={formatDate(identity?.last_activity)}
					span={1}
				/>
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Row>
							<Col>
								<div className="text-gray-900">Context ({contextAmount})</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{databricksServicePrincipal?.roles && (
									<DatabricksRolesContext databricksRoles={databricksServicePrincipal?.roles} />
								)}
							</Space>
						</Row>
						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								{databricksServicePrincipal?.oauth_tokens && (
									<DatabricksOAuthTokensContext
										databricksSpOauth={databricksServicePrincipal?.oauth_tokens}
									/>
								)}
							</Space>
						</Row>

						<Row className="mt-2">
							<Space className="w-full" direction="vertical">
								<AwsIamUserChangeLogsContext
									environmentId={''}
									subEnvironmentId={''}
									identityArn={''}
									changeLogs={databricksServicePrincipal?.change_logs ?? []}
								/>
							</Space>
						</Row>
						{databricksServicePrincipal?.kubernetes_resources_xc &&
							databricksServicePrincipal?.kubernetes_resources_xc.length > 0 && (
								<Row className="mt-2">
									<Space className="w-full" direction="vertical">
										<KubernetesResourceContext
											kubernetesResourcesXc={databricksServicePrincipal?.kubernetes_resources_xc}
										/>
									</Space>
								</Row>
							)}
						{databricksServicePrincipal?.endpoint_access_xc &&
							databricksServicePrincipal?.endpoint_access_xc.length > 0 && (
								<Row className="mt-2">
									<Space className="w-full" direction="vertical">
										<EndpointAccessContext
											endpointAccessXc={databricksServicePrincipal?.endpoint_access_xc}
										/>
									</Space>
								</Row>
							)}
					</div>
				)}
			</div>
		</>
	)
}
