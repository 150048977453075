import { z } from 'zod'
import { DateSchema } from '../../common.ts'

// MfaEnrollment schema
const MfaEnrollmentSchema = z.object({
	totpStatus: z.string().nullish(),
	webAuthnStatus: z.string().nullish(),
	pushStatus: z.string().nullish(),
	overallStatus: z.string().nullish(),
})

// JumpcloudUserMFA schema
const JumpcloudUserMFASchema = z.object({
	configured: z.boolean().nullish(),
	exclusion: z.boolean().nullish(),
	exclusionUntil: DateSchema.nullish(),
})

export const JumpcloudUserXcSchema = z.object({
	accountLocked: z.boolean().nullish(),
	activated: z.boolean().nullish(),
	addresses: z.array(z.unknown()).nullish(),
	allowPublicKey: z.boolean().nullish(),
	attributes: z.array(z.unknown()).nullish(),
	badLoginAttempts: z.number().int().nullish(),
	company: z.string().nullish(),
	created: DateSchema.nullish(),
	department: z.string().nullish(),
	description: z.string().nullish(),
	displayname: z.string().nullish(),
	email: z.string().nullish(),
	employeeIdentifier: z.string().nullish(),
	employeeType: z.string().nullish(),
	enableManagedUid: z.boolean().nullish(),
	enableUserPortalMultifactor: z.boolean().nullish(),
	externalDn: z.string().nullish(),
	externalSourceType: z.string().nullish(),
	externallyManaged: z.boolean().nullish(),
	firstname: z.string().nullish(),
	id: z.string().nullish(),
	jobTitle: z.string().nullish(),
	lastname: z.string().nullish(),
	ldapBindingUser: z.boolean().nullish(),
	location: z.string().nullish(),
	mfa: JumpcloudUserMFASchema.nullish(),
	middlename: z.string().nullish(),
	organization: z.string().nullish(),
	passwordExpirationDate: DateSchema.nullish(),
	passwordExpired: z.boolean().nullish(),
	passwordNeverExpires: z.boolean().nullish(),
	passwordlessSudo: z.boolean().nullish(),
	phoneNumbers: z.array(z.unknown()).nullish(),
	publicKey: z.string().nullish(),
	relationships: z.record(z.unknown()).nullish(),
	sambaServiceUser: z.boolean().nullish(),
	sshKeys: z.array(z.unknown()).nullish(),
	sudo: z.boolean().nullish(),
	suspended: z.boolean().nullish(),
	tags: z.array(z.unknown()).nullish(),
	totpEnabled: z.boolean().nullish(),
	unixGuid: z.number().int().nullish(),
	unixUid: z.number().int().nullish(),
	username: z.string().nullish(),
	passwordLastRotated: DateSchema.nullish(),
	mfaEnrollment: MfaEnrollmentSchema.nullish(),
})

export type JumpcloudUserXc = z.infer<typeof JumpcloudUserXcSchema>
