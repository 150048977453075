import React, { useMemo, useState } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, RowClickedEvent } from '@ag-grid-community/core'
import { Tooltip } from 'antd'
import { ServerIssue } from '../../schemas/issue.ts'
import { IssueNameTag } from '../../components/common/IssueNameTag.tsx'
import { IssueSourceIcon } from '../../components/common/IssueSourceIcon.tsx'
import { formatDate } from '../../utils.ts'
import { PriorityNameTag } from '../../components/common/PriorityNameTag.tsx'
import { IssueStatusTag } from '../../components/common/IssueStatusTag.tsx'
import { getIssuesTableRowStyle } from '../helpers.ts'
import { LogRocketTrackEvent, trackEvent } from '../../services/logrocket/logrocket.ts'
import { useNavigate } from '@tanstack/react-router'
import { PathWithIssueIdSearch } from '../../routes/RoutePaths.tsx'

export const RelatedFindingsTable: React.FC<{
	issuesTableData: ServerIssue[] | undefined
	route: PathWithIssueIdSearch
}> = ({ issuesTableData, route }) => {
	const navigate = useNavigate({ from: route })
	const [columnDefs] = useState<ColDef<ServerIssue>[]>([
		{
			headerName: 'Issue Name',
			field: 'issue_name',
			flex: 1.9,
			cellRenderer: (params: { data?: ServerIssue }) => {
				return <IssueNameTag name={params.data?.issue_name} />
			},
		},
		{
			headerName: 'Status',
			field: 'status',
			flex: 1,
			cellRenderer: (params: { data?: ServerIssue }) => {
				return <IssueStatusTag issue={params.data} />
			},
		},
		{
			headerName: 'Environment',
			field: 'identity.account_literal',
			flex: 1.2,
			cellRenderer: (params: { data?: ServerIssue }) => {
				return (
					<>
						<IssueSourceIcon source={params.data?.issue_source} />
						<Tooltip placement="bottomLeft" title={params.data?.identity?.account_literal}>
							<div className="ml-2 truncate">{params.data?.identity?.account_literal}</div>
						</Tooltip>
					</>
				)
			},
		},
		{
			headerName: 'Priority',
			field: 'priority',
			flex: 0.9,
			cellRenderer: (params: { data?: ServerIssue }) => {
				return <PriorityNameTag priority={params.data?.priority} />
			},
		},
		{
			headerName: 'Issue Last Seen',
			field: 'last_seen',
			flex: 1,
			cellRenderer: (params: { data?: ServerIssue }) => {
				return <div className="text-gray-400">{formatDate(params.data?.last_seen)}</div>
			},
		},
	])

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
		}),
		[],
	)

	const onRowClicked = (row: RowClickedEvent<ServerIssue>) => {
		const selection = window.getSelection()?.toString()
		if (selection?.length && selection.length > 0) {
			return
		}
		const issueName = row.data?.issue_name ?? undefined
		trackEvent(LogRocketTrackEvent.IssueDrawerOpenedFromRelatedFindingsTable, { issueName })
		void navigate({
			search: (prev) => ({ ...prev, issueId: row.data?.id }),
			params: (prev) => prev,
		})
	}

	return (
		<AgGridReact
			getRowStyle={getIssuesTableRowStyle}
			className={'ag-theme-alpine h-full w-full overflow-y-clip overflow-x-auto'}
			rowHeight={54}
			rowData={issuesTableData}
			columnDefs={columnDefs}
			defaultColDef={defaultColDef}
			onRowClicked={onRowClicked}
			overlayLoadingTemplate={'Loading...'}
			suppressDragLeaveHidesColumns={true}
			overlayNoRowsTemplate={'No data'}
			rowSelection={'single'}
			enableCellTextSelection
		/>
	)
}
