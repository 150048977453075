import React, { useCallback, useMemo, useRef } from 'react'
import { AgGridReact } from '@ag-grid-community/react'
import { ColDef, IRowNode } from '@ag-grid-community/core'
import { removeNulls } from '../utils.ts'
import { JsonViewer } from '../components/common/JsonViewer.tsx'
import { ServerKubernetesServiceAccount } from '../schemas/identities/kubernetesResourceSchema.ts'

export const KubernetesServiceAccountsTable: React.FunctionComponent<{
	serviceAccounts: ServerKubernetesServiceAccount[]
}> = ({ serviceAccounts }) => {
	const gridRef = useRef<AgGridReact<ServerKubernetesServiceAccount>>(null)
	const columnDefs = useMemo<ColDef<ServerKubernetesServiceAccount>[]>(() => {
		return [
			{
				field: 'metadata.name',
				headerName: 'Service Account Name',
				flex: 0.7,
			},
			{
				field: 'metadata.namespace',
				headerName: 'Namespace',
				flex: 0.7,
			},
			{
				field: 'metadata',
				cellRenderer: (params: { data?: ServerKubernetesServiceAccount; node: IRowNode }) => {
					return (
						<JsonViewer
							data={params.data?.metadata ? removeNulls(params.data?.metadata) : {}}
							title="Metadata"
						/>
					)
				},
				valueFormatter: (params) => JSON.stringify(params.value),
				flex: 0.7,
			},
		]
	}, [])

	const defaultColDef = useMemo(() => {
		return {
			sortable: true,
			resizable: true,
			filter: true,
			filterParams: {
				defaultToNothingSelected: true,
			},
			cellStyle: () => ({
				display: 'flex',
				alignItems: 'center',
			}),
			flex: 1,
		}
	}, [])

	const onGridReady = useCallback(() => {
		gridRef?.current?.api.updateGridOptions({ domLayout: 'autoHeight' })
	}, [])

	return (
		<div className="w-full h-full iam-roles-table">
			<AgGridReact
				className={'ag-theme-alpine overflow-x-auto'}
				ref={gridRef}
				rowData={serviceAccounts}
				rowHeight={54}
				masterDetail={true}
				columnDefs={columnDefs}
				detailRowHeight={200}
				defaultColDef={defaultColDef}
				onGridReady={onGridReady}
				enableCellTextSelection
			/>
		</div>
	)
}
