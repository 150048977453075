import { z } from 'zod'

export const ServerKubernetesResourceAccessKeysSchema = z
	.object({
		pod_name: z.string().nullish(),
		secret_key: z.string().nullish(),
		secret_name: z.string().nullish(),
		access_key_id: z.string().nullish(),
		container_name: z.string().nullish(),
		is_non_secret_env: z.boolean().nullish(),
		pod_owner_references: z
			.array(
				z.object({
					uid: z.string().nullish(),
					kind: z.string().nullish(),
					name: z.string().nullish(),
					controller: z.boolean().nullish(),
					api_version: z.string().nullish(),
					block_owner_deletion: z.boolean().nullish(),
				}),
			)
			.nullish(),
	})
	.nullish()

export type ServerKubernetesResourceAccessKeys = z.infer<typeof ServerKubernetesResourceAccessKeysSchema>
