import { z } from 'zod'
import { DateSchema } from '../../common.ts'
import { TagSchema } from '../../tags.ts'

export const ServerSalesforcePermissionSetSchema = z.object({
	id: z.string(),
	name: z.string(),
	label: z.string(),
	attributes: z.record(z.string(), z.unknown()),
	license_id: z.string().nullable(),
	profile_id: z.string().nullable(),
	is_owned_by_profile: z.boolean(),
	is_custom: z.boolean(),
	description: z.string().nullable(),
	created_date: DateSchema,
	created_by_id: z.string(),
	last_modified_date: DateSchema,
	last_modified_by_id: z.string(),
	system_modstamp: DateSchema,
	namespace_prefix: z.string().nullable(),
	has_activation_required: z.boolean(),
	permission_set_group_id: z.string().nullable(),
	type: z.string().nullable(),
	permissions: z.record(z.string(), z.boolean()).default({}),
	tags: z.array(TagSchema).nullish(),
})

export type ServerSalesforcePermissionSet = z.infer<typeof ServerSalesforcePermissionSetSchema>
