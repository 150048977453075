import { Node, NodeProps } from '@xyflow/react'
import { GroupOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { ServerAzureManagementGroup } from '../../../../../schemas/identities/entraId/azureRoleAssignmentsXc.ts'
import { BaseNode } from '../../common/baseNodes/BaseNode.tsx'
import { nodeColorClassNames } from '../../graphUtils/nodeColors.ts'
import { NodeGroupBadge } from '../../common/NodeGroupBadge.tsx'

export type AzureManagementGroupsNodeType = Node<
	{ managementGroups: ServerAzureManagementGroup[] },
	'azureManagementGroups'
>
type AzureManagementGroupsNodeProps = NodeProps<AzureManagementGroupsNodeType>

export const AzureManagementGroupsNode = ({ data: { managementGroups } }: AzureManagementGroupsNodeProps) => {
	const iconWrapperClassName = cx(
		`${nodeColorClassNames.permission.bgHover} ${nodeColorClassNames.permission.bg} w-[50px] h-[50px] text-white rounded-full flex justify-center items-center text-2xl transition-colors duration-300`,
	)

	const badgeTooltipContent = managementGroups.map((managementGroup, index) => (
		<span key={managementGroup.id || index}>{managementGroup.display_name || 'Unnamed management group'}</span>
	))

	return (
		<BaseNode label="Azure Management Groups">
			<NodeGroupBadge items={managementGroups} nodeColorType="permission" tooltipContent={badgeTooltipContent} />
			<div className={iconWrapperClassName}>
				<GroupOutlined />
			</div>
		</BaseNode>
	)
}
