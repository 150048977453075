import { Collapse } from 'antd'
import React from 'react'
import UserIcon from '../../../../assets/user_icon_20.svg?react'
import { EntraIdChangeLogsContent } from './EntraIdChangeLogsContent.tsx'
import { ServerEntraIdChangeLog } from '../../../../schemas/identities/entraId/entraIdChangeLogSchema.ts'
import { ServerIdentity } from '../../../../schemas/identity.ts'

export const EntraIdChangeLogsContext: React.FunctionComponent<{
	changeLogs: ServerEntraIdChangeLog[]
	identity: ServerIdentity
}> = ({ changeLogs, identity }) => (
	<Collapse
		expandIconPosition={'end'}
		items={[
			{
				key: 'EntraId service principal change logs',
				label: (
					<div className="flex items-center">
						<UserIcon className="h-7 mr-2" />
						Ownership - Creator and Maintainers
					</div>
				),
				children: <EntraIdChangeLogsContent changeLogs={changeLogs} identity={identity} />,
			},
		]}
	/>
)
