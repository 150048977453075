import React from 'react'
import { Tag } from 'antd'

import { IdentityTypeWithIcon } from './IdentityTypeWithIcon.tsx'

export const IdentityTypeTag: React.FunctionComponent<{
	type?: string | null
}> = ({ type }) => (
	<Tag className="items-center content-center font-normal w-[110px]">
		<IdentityTypeWithIcon type={type} />
	</Tag>
)
