/* eslint-disable camelcase */

import Salesforce from '../../../assets/salesforce_icon.svg?react'
import SalesforceGuide from '../../../assets/integrationGuides/salesforce-integration-guide.pdf'
import { EnvironmentCreateRequest } from '../../../schemas/environments/environments'
import { EnvironmentType } from '../../../schemas/envType'
import { IntegrationTestConnectionRequest } from '../../../schemas/integrations/integrations'
import { IntegrationConfig, IntegrationFormData } from '../types/integrationConfig'

export const salesforceConfig: IntegrationConfig = {
	key: 'salesforce',
	name: 'Salesforce',
	description:
		'Link Token Security to your Salesforce account to map cloud identities across your cloud and SaaS applications within the Salesforce platform.',
	logo: Salesforce,
	category: 'SaaS',
	guideFileName: SalesforceGuide,
	formFields: [
		{
			name: 'domain',
			label: 'Domain',
			type: 'text',
			required: true,
			placeholder: 'https://[ORG_NAME].my.salesforce.com',
		},
		{ name: 'consumerKey', label: 'Consumer Key', type: 'password', required: true },
		{ name: 'consumerSecret', label: 'Consumer Secret', type: 'password', required: true },
	],
	createTestConnectionPayload: (formData: IntegrationFormData): IntegrationTestConnectionRequest => ({
		environment_type: EnvironmentType.SALESFORCE,
		config: {
			domain: formData.domain as string,
			consumer_key: formData.consumerKey as string,
			consumer_secret: formData.consumerSecret as string,
		},
	}),
	createEnvironmentPayload: (formData: IntegrationFormData): EnvironmentCreateRequest => ({
		name: formData.name as string,
		environment_type: EnvironmentType.SALESFORCE,
		config: {
			domain: formData.domain as string,
			consumer_key: formData.consumerKey as string,
			consumer_secret: formData.consumerSecret as string,
		},
	}),
}
