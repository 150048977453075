const slackNotification = [
	{
		playbook_id: '943c7b19-3d3a-407b-becb-287b0f2572c4',
		name: 'High or Critical Issues',
		description: 'Alerts on any High or Critical new findings in my env',
		action_type: 'SEND_MESSAGE',
		status: 'ENABLED',
		trigger_json: {
			filter: {
				AND: [
					{
						op: 'gt',
						field: 'created_at',
						value: 'now - 1 days',
					},
					{
						op: 'gt',
						field: 'priority',
						value: '2',
					},
				],
			},
		},
		integration_id: 'ddbdf897-fb8e-4f26-a27e-89c81737d4cf',
		integration_type: 'SLACK',
		integration_name: 'slack',
		integration_data: {
			ok: true,
			team: {
				id: 'T03MYNJH3HC',
				name: 'Token Security',
			},
			scope: 'channels:read,chat:write,im:write,incoming-webhook,users:read,users:read.email,files:write,links:write,links.embed:write,chat:write.public,reactions:write',
			app_id: 'A07M0G33QKW',
			enterprise: null,
			token_type: 'bot',
			authed_user: {
				id: 'U06R1V969JN',
			},
			bot_user_id: 'U07LXUH1K1R',
			access_token: 'xoxb-550-122-11',
			incoming_webhook: {
				url: 'https://hooks.slack.com/services/T03MYNJH3HC/1/TCloZOF6oCZrhkfgYXx0aq6i',
				channel: '#slack-token-integration',
				channel_id: '1',
				configuration_url: 'https://token-security.slack.com/services/1',
			},
			is_enterprise_install: false,
		},
		system_created_at: '2024-10-13T14:36:52.225334Z',
		system_updated_at: '2024-11-26T15:03:38.015924Z',
	},
]

export const notifications = [...slackNotification]
