import { Collapse } from 'antd'
import React from 'react'
import UserIcon from '../../../../assets/user_icon_20.svg?react'
import { ServerIdentity } from '../../../../schemas/identity.ts'
import { ServerAzureUsageLog } from '../../../../schemas/identities/entraId/azureUsageLogSchema.ts'
import { AzureUsageLogsContent } from './AzureUsageLogsContent.tsx'

export const AzureUsageLogsContext: React.FunctionComponent<{
	usageLogs: ServerAzureUsageLog[]
	identity: ServerIdentity
}> = ({ usageLogs, identity }) => (
	<Collapse
		expandIconPosition={'end'}
		items={[
			{
				key: 'Azure usage logs',
				label: (
					<div className="flex items-center">
						<UserIcon className="h-7 mr-2" />
						Usage Logs
					</div>
				),
				children: <AzureUsageLogsContent usageLogs={usageLogs} identity={identity} />,
			},
		]}
	/>
)
