import { Tag, Tooltip } from 'antd'
import React from 'react'
import { ServerAwsIamUserAccessKey } from '../../../../schemas/identities/awsIamUserSchema.ts'
import { formatDate } from '../../../../utils'

export const AwsIamUserDetailAccessKey: React.FunctionComponent<{
	accessKey?: ServerAwsIamUserAccessKey | null
	onOpenChange?: (visible: boolean) => void
}> = ({ accessKey, onOpenChange }) => {
	return (
		<div className="flex items-end truncate">
			<Tooltip
				onOpenChange={onOpenChange}
				title={
					<div className="flex flex-col">
						{accessKey?.is_active !== null ? (
							<span className="flex text-sm text-gray-300">
								Status: {accessKey?.is_active ? 'Active' : 'Inactive'}
							</span>
						) : null}
						<span className="flex text-sm text-gray-300">
							Last Used: {formatDate(accessKey?.last_used_date)}
						</span>
						<span className="flex text-sm text-gray-300">
							Last Rotated: {formatDate(accessKey?.last_rotated)}
						</span>
						<span className="flex text-sm text-gray-300">
							Last Used Region: {accessKey?.last_used_region}
						</span>
						<span className="flex text-sm text-gray-300">
							Last Used Service: {accessKey?.last_used_service}
						</span>
					</div>
				}
			>
				<div className="flex items-center">
					{accessKey?.is_active ? (
						<Tag className="pl-1 text-green-700 text text-sm">{accessKey?.access_key_id ?? 'Active'}</Tag>
					) : (
						<Tag className="pl-1 text-gray-700 text text-sm">{accessKey?.access_key_id ?? 'Inactive'}</Tag>
					)}
				</div>
			</Tooltip>
		</div>
	)
}
