import { IssueName as IssueNameEnum } from '../../schemas/issue'
import InactiveUserIcon from '../../assets/inactive_user_icon_20.svg?react'
import UnfederatedIdentityIcon from '../../assets/unfederated_identity_16.svg?react'
import MFAIcon from '../../assets/mfa_icon_20.svg?react'
import AccessKeysIcon from '../../assets/access_keys_icon_20.svg?react'
import PasswordIcon from '../../assets/password_icon_20.svg?react'
import CloudIcon from '../../assets/cloud_icon_20.svg?react'
import AbusedIdentityIcon from '../../assets/abused_identity_icon_20.svg?react'
import AdminRightsIcon from '../../assets/admin_rights_icon_20.svg?react'
import OffBoardedIcon from '../../assets/off_boarded_icon_20.svg?react'
import InsightIcon from '../../assets/insight_icon_20.svg?react'
import WarningGrayIcon from '../../assets/warning_gray_icon_20.svg?react'
import ServiceAccountIcon from '../../assets/service_account_icon_20.svg?react'
import WeakAuthenticationIcon from '../../assets/weak_authentication_icon_20.svg?react'
import IdpIcon from '../../assets/idp_icon_20.svg?react'
import React from 'react'

export const IssueIcon: React.FunctionComponent<{
	name?: string | null
	color?: string
	size?: number
	style?: React.CSSProperties
}> = ({ name, color = '#85858C', size = 20, style }) => {
	let IconComponent
	switch (name) {
		case IssueNameEnum.EmptyGroups:
		case IssueNameEnum.EKSUnassociatedServiceAccount:
		case IssueNameEnum.InactiveIdentity:
		case IssueNameEnum.InactiveOutsideCollaborators:
			IconComponent = InactiveUserIcon
			break
		case IssueNameEnum.EKSUnFederatedPod:
		case IssueNameEnum.UnFederatedIdentity:
			IconComponent = UnfederatedIdentityIcon
			break
		case IssueNameEnum.MissingMFA:
		case IssueNameEnum.RootUserMissingMFA:
			IconComponent = MFAIcon
			break
		case IssueNameEnum.EKSAccessKeyReferencedInPod:
		case IssueNameEnum.ServiceAccountKeyReferencedInPod:
		case IssueNameEnum.InactiveAccessKey:
		case IssueNameEnum.RootUserHasAccessKeys:
		case IssueNameEnum.MultipleAccessKeys:
		case IssueNameEnum.WeakFactor:
		case IssueNameEnum.AccessKeyNotRotated:
		case IssueNameEnum.OverextendedSecret:
		case IssueNameEnum.InactivePersonalAccessTokens:
		case IssueNameEnum.PersonalAccessKeyUsedFromEndpoint:
		case IssueNameEnum.ClientSecretReferencedInPod:
		case IssueNameEnum.InactiveClientSecret:
		case IssueNameEnum.ClientSecretNotRotated:
		case IssueNameEnum.MultipleAccessTokens:
		case IssueNameEnum.PermanentToken:
			IconComponent = AccessKeysIcon
			break
		case IssueNameEnum.CloudshellFullAccess:
			IconComponent = CloudIcon
			break
		case IssueNameEnum.SecondaryEmailsPossiblyCompromised:
		case IssueNameEnum.HibpPrimaryEmailBreach:
		case IssueNameEnum.HibpSecondaryEmailBreach:
		case IssueNameEnum.PrimaryEmailsPossiblyCompromised:
		case IssueNameEnum.CloudtrailIamUserUsedFromEndpoint:
		case IssueNameEnum.GCPServiceAccountUsedFromEndpoint:
		case IssueNameEnum.ClientSecretUsedFromEndpoint:
		case IssueNameEnum.ShadowAdmin:
			IconComponent = AbusedIdentityIcon
			break
		case IssueNameEnum.EC2KeyPairNotRotated:
		case IssueNameEnum.PasswordInactiveIdentity:
		case IssueNameEnum.PasswordRotation:
		case IssueNameEnum.ExpiredPassword:
			IconComponent = PasswordIcon
			break
		case IssueNameEnum.PartiallyOffBoardedUsers:
		case IssueNameEnum.Ec2InstanceCreatedByOffboardedEmployee:
		case IssueNameEnum.AccessKeyCreatedByOffboardedEmployee:
		case IssueNameEnum.KeyPairCreatedByOffboardedEmployee:
		case IssueNameEnum.CloudtrailResourceCreatedByOffBoardedEmployee:
		case IssueNameEnum.ServicePrincipalCreatedByOffboardedEmployee:
		case IssueNameEnum.ServicePrincipalUsedFromEmployeesEndpoint:
		case IssueNameEnum.ServiceAccountSuspiciousBehavior:
			IconComponent = OffBoardedIcon
			break
		case IssueNameEnum.RootUserIsActive:
			IconComponent = AdminRightsIcon
			break
		case IssueNameEnum.EC2IMDSv2NotRequired:
		case IssueNameEnum.EC2NoRoleAttached:
		case IssueNameEnum.SalesforceUserMultipleConnectedApps:
			IconComponent = InsightIcon
			break
		case IssueNameEnum.MissingNetworkPolicy:
		case IssueNameEnum.ServiceAccountWithoutNetworkPolicy:
			IconComponent = ServiceAccountIcon
			break
		case IssueNameEnum.EKSImdsAccessible:
			IconComponent = AbusedIdentityIcon
			break
		case IssueNameEnum.EKSSystemMastersBindingDetected:
		case IssueNameEnum.EKSClusterAdminRoleUsed:
			IconComponent = AdminRightsIcon
			break
		case IssueNameEnum.EKSPossiblePermissionEscalationDetection:
			IconComponent = PasswordIcon
			break
		case IssueNameEnum.IdentityUsingWeakAuthentication:
			IconComponent = WeakAuthenticationIcon
			break
		case IssueNameEnum.IDPBypass:
			IconComponent = AbusedIdentityIcon
			break
		case IssueNameEnum.AccessTokenEmbeddedInCode:
			IconComponent = IdpIcon
			break

		default:
			IconComponent = WarningGrayIcon
	}

	return <IconComponent style={{ color, width: size, height: size, ...style }} />
}
