import { Node, NodeProps } from '@xyflow/react'
import { BaseCredentialsNode } from '../common/baseNodes/BaseCredentialsNode'

interface SalesforceConsumerKey {
	id: string
}

export type SalesforceConsumerKeyNodeType = Node<{ consumerKey: SalesforceConsumerKey }, 'salesforceConsumerKey'>
type SalesforceConsumerKeyNodeProps = NodeProps<SalesforceConsumerKeyNodeType>

export const SalesforceConsumerKeyNode = ({ data: { consumerKey } }: SalesforceConsumerKeyNodeProps) => (
	<BaseCredentialsNode label="Salesforce Consumer Key" name={{ text: consumerKey.id }} />
)
