import { z } from 'zod'
import { DateSchema } from '../../schemas/common.ts'
import { ReactNode } from 'react'

import { JiraTicketSchema } from './jiraSchemas.ts'

export enum IntegrationType {
	JIRA = 'JIRA',
	SLACK = 'SLACK',
}

/* eslint-disable camelcase */
export const ServerIntegrationTicketInTokenDbSchema = z.object({
	id: z.string().uuid(),
	customer_id: z.string().uuid(),
	integration_id: z.string().uuid(),
	issue_id: z.string(),
	created_at: DateSchema,
	updated_at: DateSchema,
	data: JiraTicketSchema,
})
/* eslint-enable camelcase */
export type ServerIntegrationTicketInTokenDb = z.infer<typeof ServerIntegrationTicketInTokenDbSchema>

/* eslint-disable camelcase */
export const ServerIntegrationSchema = z.object({
	id: z.string().uuid(),
	customer_id: z.string().uuid(),
	name: z.string(),
	enabled: z.boolean(),
	integration_type: z.nativeEnum(IntegrationType),
	created_at: DateSchema.nullish(),
	updated_at: DateSchema.nullish(),
	data: z
		.object({
			base_url: z.string().optional(),
		})
		.passthrough(),
})
/* eslint-enable camelcase */
export type ServerIntegration = z.infer<typeof ServerIntegrationSchema>

export type JiraFormItem = {
	label: string
	rules: { required: boolean; message: string }[]
	component: ReactNode
	initialValue?: string | null
}
