import { z } from 'zod'

import { JiraFieldSchema, JiraIssueTypeSchema, JiraPaginationSchema, JiraProjectSchema } from './jiraSchemas.ts' // Base schema for pagination

export const JiraFetchProjectsResponseSchema = JiraPaginationSchema.extend({
	values: z.array(JiraProjectSchema),
})
export type JiraFetchProjectsResponse = z.infer<typeof JiraFetchProjectsResponseSchema>

export const JiraFetchIssueTypesResponseSchema = JiraPaginationSchema.extend({
	issueTypes: z.array(JiraIssueTypeSchema),
})
export type JiraFetchIssueTypeResponse = z.infer<typeof JiraFetchIssueTypesResponseSchema>

export const JiraFetchLabelsResponseSchema = JiraPaginationSchema.extend({
	values: z.array(z.string()),
})
export type JiraFetchLabelsResponse = z.infer<typeof JiraFetchLabelsResponseSchema>

export const JiraFetchFieldsResponseSchema = JiraPaginationSchema.extend({
	fields: z.array(JiraFieldSchema),
})
export type JiraFetchFieldsResponse = z.infer<typeof JiraFetchFieldsResponseSchema>
