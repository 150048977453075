import { z } from 'zod'
import { ServerAwsIamRoleXcSchema } from './awsIamRoleXcSchema.ts'
import { ServerAwsIamUserPermissionXcSchema } from './awsIamUserXcSchema.ts'
import { ServerAwsIamChangeLogSchema } from './awsIamChangeLogSchema.ts'
import { ServerKubernetesResourceXcSchema } from './kubernetes/kubernetesResourceXcSchema.ts'
import { ServerKubernetesResourceAccessKeysSchema } from './kubernetes/kubernetesResourceAccessKeysSchema.ts'

export const ServerKubernetesRoleSchema = z
	.object({
		kind: z.string(), // usually this will be null, but we set it to "Role" or "ClusterRole"
		// for the sake of consistency and not creating 2 different columns in the db
		metadata: z
			.object({
				uid: z.string(),
				name: z.string(),
				namespace: z.string().nullable(),
			})
			.passthrough(),
		rules: z.array(z.any()),
	})
	.passthrough()

export type ServerKubernetesRole = z.infer<typeof ServerKubernetesRoleSchema>

export const ServerKubernetesRoleBindingSchema = z
	.object({
		metadata: z
			.object({
				uid: z.string(),
				name: z.string(),
				namespace: z.string().nullable(),
			})
			.passthrough(),
		role_ref: z.object({
			api_group: z.string(),
			kind: z.string(),
			name: z.string(),
		}),
		subjects: z.array(
			z.object({
				api_group: z.string().nullable(),
				kind: z.string(),
				name: z.string(),
				namespace: z.string().nullable(),
			}),
		),
	})
	.passthrough()

export type ServerKubernetesRoleBinding = z.infer<typeof ServerKubernetesRoleBindingSchema>

export const ServerKubernetesServiceAccountSchema = z
	.object({
		metadata: z
			.object({
				uid: z.string(),
				name: z.string(),
				namespace: z.string().nullable(),
			})
			.passthrough(),
	})
	.passthrough()

export type ServerKubernetesServiceAccount = z.infer<typeof ServerKubernetesServiceAccountSchema>

export const ServerKubernetesResourceSchema = z
	.object({
		cluster_literal: z.string().nullish(),
		resource_literal: z.string().nullish(),
		resource_uid: z.string().nullish(),
		resource_metadata: z.record(z.string(), z.unknown()).nullish(),
		spec: z.record(z.string(), z.unknown()).nullish(),
		status: z.any().nullish(),
		name: z.string().nullish(),
		kind: z.string().nullish(),
		access_keys: ServerKubernetesResourceAccessKeysSchema,
		aws_iam_roles_xc: z.array(ServerAwsIamRoleXcSchema).nullish(),
		aws_iam_users_xc: z.array(ServerAwsIamUserPermissionXcSchema).nullish(),
		role_bindings: z.array(ServerKubernetesRoleBindingSchema).nullish(),
		roles: z.array(ServerKubernetesRoleSchema).nullish(),
		data: z.record(z.string(), z.unknown()).nullish(),
		rules: z.array(z.unknown()).nullish(),
		service_accounts: z.array(ServerKubernetesServiceAccountSchema).nullish(),
		change_logs: z.array(ServerAwsIamChangeLogSchema).nullish(),
		kubernetes_resources_xc: z.array(ServerKubernetesResourceXcSchema).nullish(),
	})
	.nullish()

export type ServerKubernetesResource = z.infer<typeof ServerKubernetesResourceSchema>
