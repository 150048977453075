import { Node, NodeProps } from '@xyflow/react'
import { EntraIDUser } from './EntraIDUserNode.tsx'
import { BaseIdentityNode } from '../common/baseNodes/BaseIdentityNode.tsx'
import { NodeGroupBadge } from '../common/NodeGroupBadge.tsx'

export type EntraIDUsersNodeType = Node<{ users: EntraIDUser[] }, 'entraIDUsers'>
type EntraIDUsersNodeProps = NodeProps<EntraIDUsersNodeType>

export const EntraIDUsersNode = ({ data: { users } }: EntraIDUsersNodeProps) => {
	const badgeTooltipContent = users.map((user) => <span key={user.objectId}>{user.principalName}</span>)
	return (
		<BaseIdentityNode label="Entra ID Users">
			<NodeGroupBadge items={users} nodeColorType="identity" tooltipContent={badgeTooltipContent} />
		</BaseIdentityNode>
	)
}
