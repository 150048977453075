import { z } from 'zod'
import { ServerAbstractEnvironmentModelSchema } from './serverAbstractEnvironmentSchema.ts'
import { AccountPriorityEnum } from './accountPriorityEnum.ts'

export const ServerGcpProjectSchema = ServerAbstractEnvironmentModelSchema.extend({
	gcp_environment_id: z.string().uuid().nullish(),
	project_id: z.string(),
	token_has_access: z.boolean().default(false),
	priority: z.nativeEnum(AccountPriorityEnum),
})

export type ServerGcpProject = z.infer<typeof ServerGcpProjectSchema>
