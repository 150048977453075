import { Col, Divider, Row, Space, Tooltip } from 'antd'
import { formatDate, formatRelativeDateText } from '../../../utils'
import SuccessIcon from '../../../assets/success_icon_20.svg?react'
import WarningIcon from '../../../assets/warning_icon_20.svg?react'
import React, { useEffect, useState } from 'react'
import { ServerAwsIamUser } from '../../../schemas/identities/awsIamUserSchema.ts'
import { BaseIdentityDetail } from './baseIdentities/BaseIdentityDetail'
import { IdentityTypeTag } from '../../common/IdentityTypeTag.tsx'
import { AwsIamUserDetailAccessKey } from './iamUser/AwsIamUserDetailAccessKey'
import { IssueSourceIcon } from '../../common/IssueSourceIcon.tsx'
import { ServerIdentity } from '../../../schemas/identity'
import { AwsIamRoleContext } from './AwsIamRoleContext'
import { AwsIamPermissionContext } from './AwsIamPermissionContext'
import { OktaUserContext } from './OktaUserContext.tsx'
import { EnvironmentType } from '../../../schemas/envType.ts'
import { KubernetesResourceContext } from './kubernetes/KubernetesResourceContext.tsx'
import { AccountPriorityNameTag } from '../../common/AccountPriorityNameTag.tsx'
import { AwsIamUserChangeLogsContext } from './AwsIamUserChangeLogsContext.tsx'
import { AwsUsageLogsContext } from './AwsUsageLogsContext.tsx'
import { AwsEc2InstanceContext } from './AwsEc2InstanceContext.tsx'
import { EndpointAccessContext } from './EndpointAccessContext.tsx'

export const AwsIamUserIdentity: React.FunctionComponent<{
	identity?: ServerIdentity
	envType?: EnvironmentType | null
}> = ({ identity, envType }) => {
	const awsIamUser: ServerAwsIamUser | null = identity?.aws_iam_user ?? null
	const [contextAmount, setContentAmount] = useState(0)
	useEffect(() => {
		calcAmountOfContext()
	})
	const accountPriority = awsIamUser?.aws_account?.priority

	const calcAmountOfContext = () => {
		let amount = 0
		if (awsIamUser?.aws_iam_roles_xc?.length ?? 0 > 0) amount++
		if (awsIamUser?.aws_ec2_instances_xc?.length ?? 0 > 0) amount++
		if (awsIamUser?.aws_iam_user_details_xc ?? 0 > 0) amount++
		if (awsIamUser?.okta_user_xc?.length ?? 0 > 0) amount++
		if (awsIamUser?.kubernetes_resources_xc?.length ?? 0 > 0) amount++
		if (awsIamUser?.change_logs?.length ?? 0 > 0) amount++
		if (awsIamUser?.usage_logs?.length ?? 0 > 0) amount++
		if (awsIamUser?.endpoint_access_xc?.length ?? 0 > 0) amount++
		setContentAmount(amount)
	}

	return (
		<>
			<div className="w-full grid grid-flow-row-dense grid-cols-4 gap-6">
				<BaseIdentityDetail label="Name" value={awsIamUser?.name} span={1} />
				<BaseIdentityDetail label="Created At" value={formatDate(identity?.created_at)} span={1} />
				<BaseIdentityDetail
					label="Last Activity"
					value={formatRelativeDateText(identity?.last_activity, true)}
					tooltipTitle={formatDate(identity?.last_activity)}
					span={1}
				/>
				<BaseIdentityDetail label="Updated At" value={formatDate(identity?.updated_at)} span={1} />
				<BaseIdentityDetail label="Arn" value={identity?.literal} span={2} />
				<BaseIdentityDetail label="Identity Type" span={1}>
					<IdentityTypeTag type={identity?.type} />
				</BaseIdentityDetail>
				<BaseIdentityDetail label="Account" span={1}>
					<div className="flex flex-row items-center space-x-2">
						<IssueSourceIcon source={envType} marginBottom={'-2px'} marginLeft={'-2px'} size={14} />
						<Tooltip placement="topLeft" title={identity?.account_literal}>
							<div className="font-normal text-gray-600 items-end truncate">
								{identity?.account_literal_friendly_name ?? identity?.account_literal}
							</div>
						</Tooltip>
					</div>
				</BaseIdentityDetail>
				{accountPriority && (
					<BaseIdentityDetail label="Account Priority" span={1}>
						<AccountPriorityNameTag priority={accountPriority} />
					</BaseIdentityDetail>
				)}

				{awsIamUser?.password_info?.enabled && (
					<BaseIdentityDetail label="Mfa" span={1}>
						{awsIamUser?.mfa_active && (
							<SuccessIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						)}
						{!awsIamUser?.mfa_active && (
							<WarningIcon className="flex" style={{ minWidth: 20, minHeight: 20 }} />
						)}
					</BaseIdentityDetail>
				)}
				{awsIamUser?.password_info?.enabled && (
					<>
						<BaseIdentityDetail label="Password Enabled" value="Yes" span={1} />
						<BaseIdentityDetail
							label="Password Last changed"
							value={formatRelativeDateText(awsIamUser?.password_info?.last_changed, true)}
							tooltipTitle={formatDate(awsIamUser?.password_info?.last_changed)}
							span={1}
						/>
						<BaseIdentityDetail
							label="Password Last Rotated"
							value={formatRelativeDateText(awsIamUser?.password_info?.last_rotated, true)}
							tooltipTitle={formatDate(awsIamUser?.password_info?.last_rotated)}
							span={1}
						/>
						<BaseIdentityDetail
							label="Password Next Rotation"
							value={formatRelativeDateText(awsIamUser?.password_info?.next_rotation, true)}
							tooltipTitle={formatDate(awsIamUser?.password_info?.next_rotation)}
							span={1}
						/>
					</>
				)}
				{(awsIamUser?.aws_iam_access_keys_xc?.[0] || awsIamUser?.access_key_1?.last_rotated) && (
					<BaseIdentityDetail label="Access Key">
						<AwsIamUserDetailAccessKey
							accessKey={awsIamUser?.aws_iam_access_keys_xc?.[0] ?? awsIamUser?.access_key_1}
						/>
					</BaseIdentityDetail>
				)}
				{(awsIamUser?.aws_iam_access_keys_xc?.[1] || awsIamUser?.access_key_2?.last_rotated) && (
					<BaseIdentityDetail label="Access Key">
						<AwsIamUserDetailAccessKey
							accessKey={awsIamUser?.aws_iam_access_keys_xc?.[1] ?? awsIamUser?.access_key_2}
						/>
					</BaseIdentityDetail>
				)}
			</div>
			<div className="w-full">
				{contextAmount === 0 ? null : (
					<div className="w-full">
						<Divider />
						<Col className="gap-3 space-y-4">
							<Row className="text-gray-900">Context ({contextAmount})</Row>
							{awsIamUser?.aws_iam_user_details_xc && (
								<AwsIamPermissionContext awsIamPermissionsXc={awsIamUser?.aws_iam_user_details_xc} />
							)}
							{awsIamUser?.aws_iam_roles_xc && awsIamUser?.aws_iam_roles_xc.length > 0 && (
								<AwsIamRoleContext awsIamRolesXc={awsIamUser?.aws_iam_roles_xc} />
							)}
							{awsIamUser?.aws_ec2_instances_xc && awsIamUser.aws_ec2_instances_xc.length > 0 ? (
								<Row className="mt-2">
									<Space className="w-full" direction="vertical">
										<AwsEc2InstanceContext awsEc2InstancesXc={awsIamUser.aws_ec2_instances_xc} />
									</Space>
								</Row>
							) : null}
							<AwsIamUserChangeLogsContext
								environmentId={identity?.environment_id ?? ''}
								subEnvironmentId={identity?.aws_iam_user?.aws_account?.id ?? ''}
								identityArn={identity?.aws_iam_user?.arn ?? ''}
								changeLogs={awsIamUser?.change_logs ?? []}
							/>

							<AwsUsageLogsContext
								environmentId={identity?.environment_id ?? ''}
								subEnvironmentId={awsIamUser?.aws_account?.id ?? ''}
								identityArn={awsIamUser?.arn ?? ''}
								usageLogs={awsIamUser?.usage_logs ?? []}
							/>

							{awsIamUser?.okta_user_xc && awsIamUser?.okta_user_xc.length > 0 && (
								<OktaUserContext oktaUserXc={awsIamUser?.okta_user_xc} />
							)}
							{awsIamUser?.kubernetes_resources_xc && awsIamUser?.kubernetes_resources_xc.length > 0 && (
								<KubernetesResourceContext
									kubernetesResourcesXc={awsIamUser?.kubernetes_resources_xc}
								/>
							)}
							{awsIamUser?.endpoint_access_xc && awsIamUser?.endpoint_access_xc.length > 0 && (
								<EndpointAccessContext endpointAccessXc={awsIamUser?.endpoint_access_xc} />
							)}
						</Col>
					</div>
				)}
			</div>
		</>
	)
}
