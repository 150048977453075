import { Node, NodeProps } from '@xyflow/react'
import { BaseNode } from './baseNodes/BaseNode'
import cx from 'classnames'
import { useHighlightedNodesContext } from '../../HighlightedNodesContext'
import { IdentitySource } from '../../../../schemas/identity'
import { ResourceType, ResourceTypeMap } from '../../../../schemas/issue.ts'
import { FunctionComponent, SVGProps } from 'react'

export type FederationNodeData = {
	identityId: string
	name: string
	Icon: FunctionComponent<SVGProps<SVGSVGElement>>
	resourceType: ResourceType
	identitySource: IdentitySource
}

export type FederationNodeType = Node<FederationNodeData, 'federation'>
type FederationNodeProps = NodeProps<FederationNodeType>

export const FederationNode = ({ data: { name, Icon, resourceType, identitySource } }: FederationNodeProps) => {
	const { highlightedNodes } = useHighlightedNodesContext()
	const isHighlighted = !!highlightedNodes.find((node) => node.type === 'federation')

	const iconWrapperClassName = cx(
		'w-[50px] h-[50px] flex justify-center items-center text-2xl bg-gray-50 rounded-full',
		'border-2 border-dashed border-gray-300',
		{
			'border-gray-400': isHighlighted,
		},
	)

	return (
		<BaseNode
			label={ResourceTypeMap[resourceType]}
			name={{ text: name, tooltipText: `Federated with ${identitySource}` }}
		>
			<div className={iconWrapperClassName}>
				<Icon width={24} height={24} />
			</div>
		</BaseNode>
	)
}
